import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import { useTemaMaterialitaStores } from './context/useTemaMaterialitaStores'
import { ITemaMaterialita, ITemaMaterialitaAnno } from '../../types'
import useOttieniPrivilegioModulo from '../../../../hooks/useOttieniPrivilegioModulo'

//Components
import { FaInfoCircle } from 'react-icons/fa'
import AggiungiButton from '../../../../components/common/AggiungiButton'
import TemaMaterialitaTile from './components/TemaMaterialita/TemaMaterialitaTile'
import TemaMaterialitaForm from './components/TemaMaterialita/TemaMaterialitaForm'

const ArchivioMaterialita = observer(() => {

    const privilegio = useOttieniPrivilegioModulo();
    const { materialita } = useTemaMaterialitaStores()
    const { t } = useTranslation()

    const { ui } = useStores();

    useEffect(() => {

        materialita.getListaTemaMaterialita()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        materialita.TemaMaterialitaCreato,
        materialita.TemaMaterialitaEliminato,
        materialita.TemaMaterialitaModificato,
    ])


    return (
        <div>
            <div className="mt-2">
                {
                    materialita.temiMaterialita && materialita.temiMaterialita.length > 0 ?
                        <div className="flex flex-col gap-2">
                            {materialita.temiMaterialita.map((tema: ITemaMaterialita) => (
                                <TemaMaterialitaTile tema={tema as ITemaMaterialitaAnno} key={tema.codice} />
                            )
                            )}
                        </div>
                        :
                        <h3 className="flex items-center justify-center gap-2 text-2xl my-8 text-text-light">
                            <FaInfoCircle /> {t('ModuloFattoriEsg.ArchivioMaterialita.nessunTemaPersente')}
                        </h3>
                }
            </div>

            {privilegio >= 2 &&
                <div className="w-full mt-2" onClick={() => ui.mountAsideModal(<TemaMaterialitaForm />)} >
                        <AggiungiButton testo={t('ModuloFattoriEsg.ArchivioMaterialita.creaMaterialita')} />
                </div>
            }
        </div>
    )
})

export default ArchivioMaterialita;


