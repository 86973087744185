export const en_modulo_notizie = {
    Definitions: {
        published: 'Published',
        draft: 'Drafts',
        archived: 'Archived',
        articleBody: 'Body of the article',
    },
    Actions: {
        publish: 'Publish',
        draft: 'Put it in the Drafts',
        archive: 'Archive',
        archiveConfirm: 'Are you sure that you want to archive this article',
        save: 'Save changes',
        create: 'Create the article',
        modify: 'Modify',
        cancel: 'Cancel',
        restore: 'Restore',
        delete: 'Permanently delete',
        deleteConfirm: 'Are you sure that you want to permanently delete this article',
        read: 'Read the complete article',
    },
    Menu: {
        published: 'Published articles list',
        draft: 'Draft articles list',
        archived: 'Archived articles list',
    },
    Archivio: {
        loading: 'Loading the articles\' list...',
        empty: 'There are no articles.',
        create: 'Create a new article',
    },
    Inputs: {
        titolo: {
            'required': 'The title is mandatory!',
            'placeholder': 'Title of the article',
        },
        sottotiolo: {
            'required': '',
            'placeholder': 'Subtitle',
        },
        feature: {
            'label': 'Feature this article.',
            'popup': 'Set whether to show this article as a featured article, this will allow you to always view it in preview compared to the others. NB: it is not possible to have more than one featured article, consequently if another exists, it will take its place.',
        },
        dashboard: {
            label: 'Visible in the user\'s dashboard.',
            popup: 'Set whether to show this article in each user\'s dashboard.',
        },
        login: {
            label: 'Visible in the login page.',
            popup: 'Set whether to show this article on the login page. It will be visible to unauthenticated users as well.',
        },
    },
    State: {
        published: 'Published at',
        created: 'Created at',
        archived: 'Archived at',
        modified: 'Modified at',
        featured: 'Featured',
    },
}