import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../hooks/useStores'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react-lite'

//Components
import TestoErrore from '../common/TestoErrore'
import { FaEnvelope } from 'react-icons/fa'
import InfoBox from '../common/InfoBox'

const CambiaEmailForm = observer(() => {
  const { t } = useTranslation();

  const { auth } = useStores()

  const { register, handleSubmit, setValue, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      email: ''
    }
  });

  useEffect(() => {
    setValue('email', auth.utente?.email as string)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.utente])

  const onSubmit = () => {
    console.log('submitted')
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="pointer-events-none opacity-50">

      <h3>{t('FormCambiaEmail.testoNonPossibileModificare')}</h3>

      <h6 className="text-xl mb-2 flex items-center gap-2"><FaEnvelope />{t('FormCambiaEmail.testCambioEmail')}</h6>
      <div className="w-full flex gap-1">
        <div className="form">
          <label htmlFor="">{t('common.eMail')}</label>
          <input {...register("email", { required: t('common.fornisciEmail')  })} placeholder={t('common.eMail')} type="email" />

          {errors.email && <TestoErrore errore={errors.email.message} />}
        </div>
      </div>

      {isSubmitSuccessful && <InfoBox messaggio={t('FormCambiaEmail.messagioInviatoeamil')} />}

      <div className="form">
        {isSubmitSuccessful ? <input type="submit" value={t('FormCambiaEmail.richiestaInviata')}  disabled /> : <input type="submit" value={t('FormCambiaEmail.richiediCambiaEmail')} />}
      </div>
    </form>
  )
})

export default CambiaEmailForm;