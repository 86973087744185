import React from 'react'

import { INotizia } from '../../../types'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const TileNotizia: React.FC<{ notizia: INotizia, logged: boolean, larghezzaFissa?: boolean }> =({ notizia, logged, larghezzaFissa }) => {

    const { t } = useTranslation()

    const modificatoreURL = logged ? '' : '/login'

    const urlDefinitivo = `notizia${modificatoreURL}/${notizia.id}`

    return (
        <div className={`bg-white p-5 shadow-2xl ${larghezzaFissa ? 'min-w-[300px] w-[300px]' : 'w-full'}`} >
            <div className="flex flex-col justify-between h-full">
                <div>
                  
                    <h2 className="text-2xl font-bold">{notizia.titolo}</h2>

                    {notizia.sotto_titolo && <h3 className="text-lg">{notizia.sotto_titolo}</h3>}
                </div>

                <Link className="block mt-4" to={urlDefinitivo}>
                    {t('ModuloNotizie.Actions.read')}
                </Link>

            </div>
        </div>
    )
}

export default TileNotizia;