import React, { useState, useEffect, } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { observer } from 'mobx-react-lite'
import { ITemaMaterialitaAnno } from '../../types'
import { Controller, useForm } from 'react-hook-form'
import { useModuloESGStores } from '../../context/useModuloESGStores';
import { getListaTemaMaterialita } from '../../pagineFattoriESG/UiModuloTemaMaterialita/rest/temamaterialita';

//Components
import InputField from '../../../../components/common/form/InputField'
import ErrorBox from '../../../../components/common//ErrorBox'
import InfoBox from '../../../../components/common//InfoBox'
import SpinnerButton from '../../../../components/common//SpinnerButton'
import Select from 'react-select'
import TestoErrore from '../../../../components/common/TestoErrore';

const TemaMaterialitaBilancioForm: React.FC<{ tema?: ITemaMaterialitaAnno, temiDaEscludere?: ITemaMaterialitaAnno[] }> = observer(({ tema, temiDaEscludere }) => {

  const { register, handleSubmit, watch, control, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      tema: tema ? tema.codice : '',
      descrizione_anno: tema ? tema.descrizione_anno : '',
      rilevanza_azienda_anno: tema ? tema.rilevanza_azienda_anno : '',
      rilevanza_stakeholder_anno: tema ? tema.rilevanza_stakeholder_anno : '',
    }
  });

  const { bilanci } = useModuloESGStores();
  const { t } = useTranslation();

  const onSubmit = () => {
    bilanci.collegaTemaMaterialitaBilancio(bilanci.bilancioAttivo?.anno as number, watch('tema'), watch('descrizione_anno'), watch('rilevanza_azienda_anno') as number, watch('rilevanza_stakeholder_anno') as number)
  }

  const [codiciTemiDaEscludere, setCodiciTemiDaEscludere] = useState<string[]>([])
  const [temiMaterialita, setTemiMaterialita] = useState<[]>([])

  //Ottiene lista codici temi da escludere dal dropdown
  useEffect(() => {
    if (temiDaEscludere) {
      setCodiciTemiDaEscludere(temiDaEscludere.map(tema => tema.codice))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getListaTemaMaterialita().then((res) => {
      setTemiMaterialita(res.data.data.filter((tema: ITemaMaterialitaAnno) => !codiciTemiDaEscludere.includes(tema.codice)))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    codiciTemiDaEscludere,
    bilanci.temaMaterialitaBilancioCollegato
  ])

  //Ristruttura dati per label MultiSelect
  temiMaterialita
    .map((tema: any) => {
      tema.value = tema.codice
      tema.label = tema.nome
    })


  return (
    <div>
      <div className="mb-4">
        <h2 className="text-2xl mb-2">
          {tema ?
            `${t('ModuloFattoriEsg.FormTemaMaterialitaBilancio.ModificaMaterilita') } ${tema.nome} ${t('ModuloFattoriEsg.FormTemaMaterialitaBilancio.applicatoAquestoBilancio') }`
            :
            `${t('ModuloFattoriEsg.FormTemaMaterialitaBilancio.applicaUnatema') } ${bilanci.bilancioAttivo?.anno}`
          }
        </h2>
        <p className="text-text-light">
          {tema ?
            `${t('ModuloFattoriEsg.FormTemaMaterialitaBilancio.modificaIDati') } `
            :
            `${t('ModuloFattoriEsg.FormTemaMaterialitaBilancio.scgliUnaTema') }`
          }
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>

        {!tema &&
          <div className="mb-2">
            <label htmlFor="">{t('ModuloFattoriEsg.FormTemaMaterialitaBilancio.temaDiMaterialita') }</label>
            <Controller
              name="tema"
              control={control}
              rules={{ required: t('ModuloFattoriEsg.FormTemaMaterialitaBilancio.scegliUnatemaMaterialita') } }
              render={({ field: { onChange } }) => (
                <Select
                  options={temiMaterialita}
                  //@ts-ignore
                  defaultValue={temiMaterialita.find((t: any) => t.value === temiMaterialita?.value)}
                  onChange={(e: any) => onChange(e.value)}
                />
              )}
            />

            {errors.tema && <TestoErrore errore={errors.tema.message} />}
          </div>
        }

        <InputField
          label={t('common.descrizione') }
          type="textarea"
          error={errors.descrizione_anno}
          form={register("descrizione_anno")}
          placeholder={t('common.descrizione') }
        />

        <div className="flex items-end gap-2">
          <InputField
            label={t('ModuloFattoriEsg.FormTemaMaterialitaBilancio.RilevanzaPerlazienda') }
            type="number"
            error={errors.rilevanza_azienda_anno}
            form={register("rilevanza_azienda_anno", { required: t('ModuloFattoriEsg.FormTemaMaterialitaBilancio.fornisciUnValore') , })}
            placeholder="0, 1, 2"
          />

          <InputField
            label={t('ModuloFattoriEsg.FormTemaMaterialitaBilancio.RilevanzaPerStakeholder') }
            type="number"
            error={errors.rilevanza_stakeholder_anno}
            form={register("rilevanza_stakeholder_anno", { required: t('ModuloFattoriEsg.FormTemaMaterialitaBilancio.fornisciValorePerStakeHolder'), })}
            placeholder="0, 1, 2"
          />
        </div>


        {/* Submit creazione fattore */}
        <div className="form pt-4">
          {!bilanci.collegandoTemaMaterialitaBilancio ? <input type="submit" value={`${tema ? `${t('ModuloFattoriEsg.FormTemaMaterialitaBilancio.modifciatemaDiMaterialita') }` : t('ModuloFattoriEsg.FormTemaMaterialitaBilancio.applicaTemaDiMaterialita') }`} /> : <SpinnerButton />}
        </div>

        {
          isSubmitSuccessful && !bilanci.collegandoTemaMaterialitaBilancio && (
            bilanci.collegaTemaMaterialitaBilancioError ?
              <div className="mb-2">
                <ErrorBox errore={bilanci.collegaTemaMaterialitaBilancioError.toString()} />
              </div>
              :
              <div className="mb-2">
                <InfoBox messaggio={`${t('ModuloFattoriEsg.FormTemaMaterialitaBilancio.applicatoSucesso') }`} />
              </div>
          )
        }

      </form>
    </div>
  )
})

export default TemaMaterialitaBilancioForm;