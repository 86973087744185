import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { observer } from 'mobx-react-lite'

//Components
import { FaInfoCircle, FaPlus, FaSlidersH } from 'react-icons/fa'
import ReactPaginate from 'react-paginate'

import { useStores } from '../../../../hooks/useStores'
import BarraRicercaGenerale from '../../../../components/common/BarraRicercaGenerale'
import GruppoTile from '../../gruppi/GruppoTile'
import { IGruppo } from '../../../../types'


const PannelloGruppiSenzaUtenti: React.FC<{ gruppo: IGruppo[] }> = observer(({gruppo}) => {
    const { gestioneUtenti } = useStores();
    const { t } = useTranslation();

    const styles = {
        cardsWrapper: "grid gap-3 xl:gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-6",
        btnFiltri: "text-white opacity-70  p-1 rounded-lg w-[120px] lg:w-[200px]",
    }
    const [arrayFiltri, setFiltri] = useState<boolean>(false)
    const chiudFiltri = () => {
        setFiltri(false)
        gestioneUtenti.getGruppi();
        setClickedButton("")
    }
    //Dati per paginazione gruppi senza utenti
    const [stringaDiRicercaGruppiSenzaUtenti, setStringaDiRicercaGruppiSenzaUtenti] = useState<string>('');
    const gruppiSenzaUtentiFiltrati = gruppo.filter((gruppo) => gruppo.nome.toLocaleLowerCase().includes(stringaDiRicercaGruppiSenzaUtenti.toLocaleLowerCase()) && gruppo.utenti?.length === 0);
    const [numeroPaginaGruppiSenzaUtenti, setNumeroPaginaGruppiSenzaUtenti] = useState(0);
    const gruppiSenzaUtentiPerPagina = 4;
    const pagineGruppiSenzaUtentiVisitate = numeroPaginaGruppiSenzaUtenti * gruppiSenzaUtentiPerPagina;
    const conteggioPaginaGruppiSenzaUtenti = Math.ceil(gruppiSenzaUtentiFiltrati.length / gruppiSenzaUtentiPerPagina);
    const cambioPaginaGruppiSenzaUtenti = ({ selected }: { selected: number }): void => {
        setNumeroPaginaGruppiSenzaUtenti(selected)
    };

    useEffect(() => {
        setNumeroPaginaGruppiSenzaUtenti(0)
    }, [stringaDiRicercaGruppiSenzaUtenti])

    const [clickedButton, setClickedButton] = useState("");

    const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>): any => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;
        setClickedButton(button.name);
    }

    const gruppiSenzaUtentiOrdinati = (testo: string) => {

        let gruppi = gruppiSenzaUtentiFiltrati

        if (testo === "az") {
            gruppi = gruppi
                .sort((a: IGruppo, b: IGruppo) => b.nome > a.nome ? 1 : -1)
        } else if (testo === "za") {
            gruppi = gruppi
                .sort((a: IGruppo, b: IGruppo) => b.nome > a.nome ? -1 : 1)
        } else if (testo = "resetta") {
            gruppi = gruppi
        }
        return gruppi.slice(pagineGruppiSenzaUtentiVisitate, pagineGruppiSenzaUtentiVisitate + gruppiSenzaUtentiPerPagina);
    }

    useEffect(() => {
        gestioneUtenti.getGruppi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        gestioneUtenti.creatingUtente,
        gestioneUtenti.utenteModificato,
        gestioneUtenti.utenteEliminato,
        gestioneUtenti.creatingGruppo,
        gestioneUtenti.gruppoModificato,
        gestioneUtenti.gruppoEliminato
    ])

    return (
        <div className='shadow-xl px-4 py-2'>
    

            <div className='lg:flex text-center justify-between mb-3 text-accent dark:text-dark-accent mt-6 border-[lightgray] border-b-2 py-2 '>
                <span className="text-lg lg:text-2xl font-bold uppercase">{t('ModuloGestioneUtenti.PannelloUtentiSenzaGruppo.gruppiSenzaUtenti')}</span>
                <BarraRicercaGenerale 
                    setStringaDiRicerca={setStringaDiRicercaGruppiSenzaUtenti} 
                    numeroRisultati={gruppiSenzaUtentiFiltrati.length}
                    mostraRisultati={true} />
            </div>
            <div className="flex items-center justify-end my-2">

                {arrayFiltri ?
                    <div className="cursor-pointer p-1 hover:bg-white rounded-sm rotate-45" onClick={() => chiudFiltri()}>
                        <FaPlus />
                    </div>
                    :
                    <div className="cursor-pointer p-1 hover:bg-white rounded-sm" onClick={() => setFiltri(true)} >
                        <FaSlidersH />
                    </div>
                }
            </div>

            {arrayFiltri &&
                <div className='grid grid-cols-3 gap-2 px-4 my-4 m-auto mostraFiltri'>
                    <div className='flex justify-center'>
                        <button onClick={buttonHandler} className={`${styles.btnFiltri} ${clickedButton === "az" ? "opacity-100" : ""}`} name="az"> Ordina per nome desc </button>
                    </div>
                    <div className='flex justify-center'>
                        <button onClick={buttonHandler} className={`${styles.btnFiltri} ${clickedButton === "za" ? "opacity-100" : ""}`} name="za"> Ordina per nome asc </button>
                    </div>
                    <div className='flex justify-center'>
                        <button onClick={buttonHandler} className={`${styles.btnFiltri}`} name="resetta"> Resetta filtro </button>
                    </div>
                </div>
            }

            {gruppiSenzaUtentiFiltrati.length > 0 ?

                (
                    <><div className="flex flex-col gap-5 mt-5 mb-5">
                        {
                            gruppiSenzaUtentiOrdinati(clickedButton)
                                .map((gruppo, i) => { return <GruppoTile key={i} gruppo={gruppo} /> })
                        }
                    </div>
                        {gruppo.filter((gruppo) => gruppo.utenti?.length === 0).length > 4 &&
                            <ReactPaginate
                                pageCount={conteggioPaginaGruppiSenzaUtenti}
                                forcePage={numeroPaginaGruppiSenzaUtenti}
                                nextLabel={'Next'}
                                onPageChange={cambioPaginaGruppiSenzaUtenti}
                                containerClassName={"paginationBttns"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"} />
                        }

                    </>
                )
                :
                (
                    <>
                        <p className="flex items-center gap-2 opacity-70 justify-center">
                            <FaInfoCircle /> <span className="text-lg">{t('ModuloGestioneUtenti.PannelloUtentiSenzaGruppo.nessunGruppoSenzaUtenti')}</span>
                        </p>
                    </>
                )

            }
        </div>

    )
}
)
export default PannelloGruppiSenzaUtenti