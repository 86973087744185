export const it_modulo_notizie = {
    Definitions: {
        published: 'Pubblicati',
        draft: 'Bozze',
        archived: 'Archivati',
        articleBody: 'Corpo dell\'articolo',
    },
    Actions: {
        publish: 'Pubblica',
        draft: 'Metti tra le bozze',
        archive: 'Archivia',
        archiveConfirm: 'Sei sicuro voler archiviare questo articolo',
        save: 'Salva le modifiche',
        create: 'Crea l\'articolo',
        modify: 'Modifica',
        cancel: 'Annulla',
        restore: 'Ripristina',
        delete: 'Elimina definitivamente',
        deleteConfirm: 'Sei sicuro voler eliminare questo articolo',
        read: 'Leggi l\'articolo completo',
    },
    Menu: {
        published: 'Lista articoli pubblicati',
        draft: 'Lista articoli in bozza',
        archived: 'Lista articoli archivati',
    },
    Archivio: {
        loading: 'Caricando la lista degli articoli...',
        empty: 'Non è presente nessun articolo.',
        create: 'Scrivi un nuovo articolo',
    },
    Inputs: {
        titolo: {
            'required': 'Il titolo e obbligatiorio!',
            'placeholder': 'Titolo dell\'articolo',
        },
        sottotitolo: {
            'required': '',
            'placeholder': 'Subtitle',
        },
        feature: {
            label: 'Metti questo articolo in evidenza.',
            popup: 'Stabilisci se mostrare questo articolo come articolo in evidenza, questo permetterà di visualizzarlo sempre in anteprima rispetto agli altri. NB: non è possibile avere più di un articolo in evidenza, conseguentemente se ne esiste un altro, questo prenderà il suo posto.',
        },
        dashboard: {
            label: 'Visibile nella dashboard degli utenti.',
            popup: 'Stabilisci se mostrare questo articolo nella dashboard di ogni utente.',
        },
        login: {
            label: 'Visibile nella pagina di login.',
            popup: 'Stabilisci se mostrare questo articolo nella pagina di login. Sarà visibile anche agli utenti non autenticati.',
        },
    },
    State: {
        published: 'Pubblicato il',
        created: 'Creato il',
        archived: 'Archiviato il',
        modified: 'Modificato il',
        featured: 'In evidenza',
    },
}