import { observable, action, makeAutoObservable, runInAction } from 'mobx'
import { IBilancio, IBusinessUnitNelBilancio, IFattoreESG, IGruppoNelBilancio, IObiettivoAgenda2030, ITemaMaterialitaAnno } from '../types'
import {
    getBilanci,
    getFattoreBilancio,
    getTemaBilancio,
    getObiettivoAgenda2030Bilancio,
    getBusinessUnitBilancio,
    getGruppoBilancio,
    getBilancio,
    creaBilancio,
    modificaBilancio,
    eliminaBilancio,
    collegaFattoreBilancioBusinessUnit,
    collegaTemaMaterialitaBilancio,
    scollegaTemaMaterialitaBilancio,
    collegaTemaMaterialitaFattoreBilancio
} from '../rest/bilanci'
import creaAlberaturaFattoriEsg from '../script/creaAlberaturaFattoriEsg'

export class BilanciStore {

    bilanci: IBilancio[] = []

    //Bilancio attivo
    gettingBilancioAttivo: boolean = false
    bilancioAttivo: IBilancio | null = null

    //Fattore nel bilancio attivo
    fattoreBilancioAttivo: IFattoreESG | null = null
    gettingFattoreBilancioAttivo: boolean = false

    //Tema nel bilancio attivo
    temaBilancioAttivo: ITemaMaterialitaAnno | null = null
    gettingTemaBilancioAttivo: boolean = false

    //Obiettivo agenda 2030 nel bilancio attivo
    obiettivoAgenda2030BilancioAttivo: IObiettivoAgenda2030 | null = null
    gettingObiettivoAgenda2030BilancioAttivo: boolean = false

    //Buwiness unit nel bilancio attivo
    businessUnitBilancioAttivo: IBusinessUnitNelBilancio | null = null
    gettingBusinessUnitBilancioAttivo: boolean = false


    //Gruppo nel bilancio attivo
    gruppoBilancioAttivo: IGruppoNelBilancio | null = null
    gettingGruppoBilancioAttivo: boolean = false

    //Gestione bilanci
    creatingBilancio = false
    creatingBilancioError: string = ''
    bilancioCreato = false

    editingBilancio = false
    modificaBilancioError: string = ''
    bilancioModificato = false

    deletingBilancio: boolean = false
    eliminaBilancioError: string = ''
    bilancioEliminato: boolean = false

    //Collegamento di un fattore al bilancio
    collegandoFattoreBilancioBusinessUnit: boolean = false
    collegaFattoreBilancioBusinessUnitError: string = ''
    collegaFattoreBilancioBusinessUnitSuccess: string = ''
    fattoreBilancioBusinessUnitCollegato: boolean = false

    //Collegamento di un tema di materialità a un bilancio
    collegandoTemaMaterialitaBilancio: boolean = false
    collegaTemaMaterialitaBilancioError: string = ''
    collegaTemaMaterialitaBilancioSuccess: string = ''
    temaMaterialitaBilancioCollegato: boolean = false

    scollegandoTemaMaterialitaBilancio: boolean = false
    scollegaTemaMaterialitaBilancioError: string = ''
    scollegaTemaMaterialitaBilancioSuccess: string = ''
    temaMaterialitaBilancioScollegato: boolean = false

    //Collegamento dei temi di materialità ai fattori nel bilancio
    collegandoTemiMaterialitaFattoreBilancio: boolean = false
    collegaTemiMaterialitaFattoreBilancioError: string = ''
    collegaTemiMaterialitaFattoreBilancioSuccess: string = ''
    temiMaterialitaFattoreBilancioCollegati: boolean = false

    constructor() {
        makeAutoObservable(this, {
            //Header Modal
            bilanci: observable,

            gettingBilancioAttivo: observable,
            bilancioAttivo: observable,

            fattoreBilancioAttivo: observable,
            gettingFattoreBilancioAttivo: observable,
            getFattoreBilancio: action,

            //Tema nel bilancio attivo
            temaBilancioAttivo: observable,
            gettingTemaBilancioAttivo: observable,
            getTemaBilancio: action,

            //Obiettivo agenda 2030 nel bilancio attivo
            obiettivoAgenda2030BilancioAttivo: observable,
            gettingObiettivoAgenda2030BilancioAttivo: observable,
            getObiettivoAgenda2030Bilancio: action,

            //Buwiness unit nel bilancio attivo
            businessUnitBilancioAttivo: observable,
            gettingBusinessUnitBilancioAttivo: observable,
            getBusinessUnitBilancio: action,


            //Gruppo nel bilancio attivo
            gruppoBilancioAttivo: observable,
            gettingGruppoBilancioAttivo: observable,
            getGruppoBilancioAttivo: action,

            getBilanci: action,

            //Gestione bilanci
            creatingBilancio: observable,
            creatingBilancioError: observable,
            bilancioCreato: observable,

            creaBilancio: action,
            editingBilancio: observable,
            modificaBilancioError: observable,
            bilancioModificato: observable,
            modificaBilancio: action,

            deletingBilancio: observable,
            eliminaBilancioError: observable,
            bilancioEliminato: observable,
            eliminaBilancio: action,

            //Collegamento di un fattore al bilancio
            collegandoFattoreBilancioBusinessUnit: observable,
            collegaFattoreBilancioBusinessUnitError: observable,
            collegaFattoreBilancioBusinessUnitSuccess: observable,
            fattoreBilancioBusinessUnitCollegato: observable,
            collegaFattoreBilancioBusinessUnit: action,

            //Collegamento di un tema di materialità a un bilancio
            collegandoTemaMaterialitaBilancio: observable,
            collegaTemaMaterialitaBilancioError: observable,
            collegaTemaMaterialitaBilancioSuccess: observable,
            temaMaterialitaBilancioCollegato: observable,
            collegaTemaMaterialitaBilancio: action,

            scollegandoTemaMaterialitaBilancio: observable,
            scollegaTemaMaterialitaBilancioError: observable,
            scollegaTemaMaterialitaBilancioSuccess: observable,
            temaMaterialitaBilancioScollegato: observable,
            scollegaTemaMaterialitaBilancio: action,

            //Collegamento dei temi di materialità ai fattori nel bilancio
            collegandoTemiMaterialitaFattoreBilancio: observable,
            collegaTemiMaterialitaFattoreBilancioError: observable,
            collegaTemiMaterialitaFattoreBilancioSuccess: observable,
            temiMaterialitaFattoreBilancioCollegati: observable,
            collegaTemaMaterialitaFattoreBilancio: action
        })
    }

    //lista bilanci
    getBilanci = () => {
        getBilanci().then((res) => {
            runInAction(() => { this.bilanci = res.data.data })
        }).catch((err) => {
            console.log(err.data.data)
        })
    }

    //singolo bilancio
    getBilancio = (anno: number) => {

        this.gettingBilancioAttivo = true

        getBilancio(anno).then((res) => {

            runInAction(() => {

                this.bilancioAttivo = res.data.data


                if (this.bilancioAttivo?.fattori_esg) {

                    this.bilancioAttivo.alberatura_fattori_esg = []

                    this.bilancioAttivo.alberatura_fattori_esg = creaAlberaturaFattoriEsg(this.bilancioAttivo.fattori_esg as IFattoreESG[], true);

                }


            })

        }).catch((err) => {

            console.log(err.data.data)

        }).finally(() => {

            runInAction(() => this.gettingBilancioAttivo = false)
        })
    }

    //singolo bilancio
    getFattoreBilancio = async (anno: number, codice: string) => {

        this.gettingFattoreBilancioAttivo = true

        await getFattoreBilancio(anno, codice).then((res) => {

            runInAction(() => {

                this.fattoreBilancioAttivo = res.data.data

                if (this.fattoreBilancioAttivo) {
                    this.fattoreBilancioAttivo.alberatura_sotto_fattori = creaAlberaturaFattoriEsg(this.fattoreBilancioAttivo.sotto_fattori)
                }

            })

        }).catch((err) => {

            this.fattoreBilancioAttivo = null
            console.log(err.data.data)

        }).finally(() => {

            runInAction(() => this.gettingFattoreBilancioAttivo = false)
        })
    }

    //singolo bilancio
    getTemaBilancio = async (anno: number, codice: string) => {

        this.gettingTemaBilancioAttivo = true

        await getTemaBilancio(anno, codice).then((res) => {

            runInAction(() => { this.temaBilancioAttivo = res.data.data })

        }).catch((err) => {

            this.temaBilancioAttivo = null

        }).finally(() => {

            runInAction(() => this.gettingTemaBilancioAttivo = false)
        })
    }

    //singolo obiettivo agenda 2030
    getObiettivoAgenda2030Bilancio = async (anno: number, id: number) => {

        this.gettingObiettivoAgenda2030BilancioAttivo = true

        await getObiettivoAgenda2030Bilancio(anno, id).then((res) => {

            runInAction(() => { this.obiettivoAgenda2030BilancioAttivo = res.data.data })

        }).catch((err) => {

            this.obiettivoAgenda2030BilancioAttivo = null

        }).finally(() => {

            runInAction(() => this.gettingObiettivoAgenda2030BilancioAttivo = false)
        })
    }

    
    //singola business unit agenda 2030
    getBusinessUnitBilancio = async (anno: number, nome: string) => {

        this.gettingBusinessUnitBilancioAttivo = true

        await getBusinessUnitBilancio(anno, nome).then((res) => {

            runInAction(() => { this.businessUnitBilancioAttivo = res.data.data })

        }).catch((err) => {

            this.businessUnitBilancioAttivo = null

        }).finally(() => {

            runInAction(() => this.gettingBusinessUnitBilancioAttivo = false)
        })
    }

    //singola business unit agenda 2030
    getGruppoBilancioAttivo = async (anno: number, codice: string) => {

        this.gettingGruppoBilancioAttivo = true

        await getGruppoBilancio(anno, codice).then((res) => {

            runInAction(() => { this.gruppoBilancioAttivo = res.data.data })

        }).catch((err) => {

            this.gruppoBilancioAttivo = null

        }).finally(() => {

            runInAction(() => this.gettingGruppoBilancioAttivo = false)
        })
    }


    //crea bilancio
    creaBilancio = async (bilancio: IBilancio) => {

        runInAction(() => this.creatingBilancio = true)

        await creaBilancio(bilancio).then(() => {

            runInAction(() => this.creatingBilancioError = '')
        }).catch((err) => {

            runInAction(() => this.creatingBilancioError = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {
            runInAction(() => this.creatingBilancio = false)
            runInAction(() => this.bilancioCreato = !this.bilancioCreato)
        })
    }

    //crea bilancio
    modificaBilancio = async (bilancio: IBilancio) => {

        runInAction(() => this.editingBilancio = true)

        await modificaBilancio(bilancio).then(() => {

            runInAction(() => this.modificaBilancioError = '')

        }).catch((err) => {
            runInAction(() => this.modificaBilancioError = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {
            runInAction(() => this.editingBilancio = false)
            runInAction(() => this.bilancioModificato = !this.bilancioModificato)
        })
    }

    //elimina bilancio
    eliminaBilancio = async (anno: number) => {

        runInAction(() => this.deletingBilancio = true)

        await eliminaBilancio(anno).then(() => {

            runInAction(() => this.eliminaBilancioError = '')

        }).catch((err) => {

            console.log(err.data)
            runInAction(() => this.eliminaBilancioError = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {
            runInAction(() => this.deletingBilancio = false)
            runInAction(() => this.bilancioEliminato = !this.bilancioEliminato)
        })
    }

    collegaFattoreBilancioBusinessUnit = async (anno: number, fattore: string, business_units: Set<string>) => {

        runInAction(() => this.collegandoFattoreBilancioBusinessUnit = true)

        await collegaFattoreBilancioBusinessUnit(anno, fattore, business_units).then(() => {

            runInAction(() => this.collegaFattoreBilancioBusinessUnitError = '')
            runInAction(() => this.collegaFattoreBilancioBusinessUnitSuccess = 'Business unit collegate con successo')

        }).catch((err) => {

            console.log(err.data)
            runInAction(() => this.collegaFattoreBilancioBusinessUnitSuccess = '')
            runInAction(() => this.collegaFattoreBilancioBusinessUnitError = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {

            runInAction(() => this.collegandoFattoreBilancioBusinessUnit = false)
            runInAction(() => this.fattoreBilancioBusinessUnitCollegato = !this.fattoreBilancioBusinessUnitCollegato)

        })

    }

    collegaTemaMaterialitaBilancio = async (bilancio_anno: number, temaMaterialitaCodice: string, descrizioneAnno: string, rilevanza_azienda: number, rilevanza_stakeholder: number) => {
        runInAction(() => this.collegandoTemaMaterialitaBilancio = true)

        await collegaTemaMaterialitaBilancio(bilancio_anno, temaMaterialitaCodice, descrizioneAnno, rilevanza_azienda, rilevanza_stakeholder).then(() => {

            runInAction(() => this.collegaTemaMaterialitaBilancioError = '')

        }).catch((err) => {

            console.log(err.data)
            runInAction(() => this.collegaTemaMaterialitaBilancioError = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {
            runInAction(() => this.collegandoTemaMaterialitaBilancio = false)
            runInAction(() => this.temaMaterialitaBilancioCollegato = !this.temaMaterialitaBilancioCollegato)
        })
    }

    scollegaTemaMaterialitaBilancio = async (bilancio_anno: number, temaMaterialitaCodice: string,) => {
        runInAction(() => this.scollegandoTemaMaterialitaBilancio = true)

        await scollegaTemaMaterialitaBilancio(bilancio_anno, temaMaterialitaCodice).then(() => {

            runInAction(() => this.scollegaTemaMaterialitaBilancioError = '')

        }).catch((err) => {

            console.log(err.data)
            runInAction(() => this.scollegaTemaMaterialitaBilancioError = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {
            runInAction(() => this.scollegandoTemaMaterialitaBilancio = false)
            runInAction(() => this.temaMaterialitaBilancioScollegato = !this.temaMaterialitaBilancioScollegato)
        })
    }

    collegaTemaMaterialitaFattoreBilancio = async (bilancio_anno: number, fattore_codice: string, temiMaterialita: ITemaMaterialitaAnno[]) => {
        runInAction(() => this.collegandoTemiMaterialitaFattoreBilancio = true)

        await collegaTemaMaterialitaFattoreBilancio(bilancio_anno, fattore_codice, temiMaterialita).then(() => {

            runInAction(() => this.collegaTemiMaterialitaFattoreBilancioError = '')

        }).catch((err) => {

            runInAction(() => this.collegaTemiMaterialitaFattoreBilancioError = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {
            runInAction(() => this.collegandoTemiMaterialitaFattoreBilancio = false)
            runInAction(() => this.temiMaterialitaFattoreBilancioCollegati = !this.temiMaterialitaFattoreBilancioCollegati)
        })
    }
}
