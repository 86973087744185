import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { IBusinessUnit } from '../../types';
import useOttieniPrivilegioModulo from '../../../../hooks/useOttieniPrivilegioModulo';
import { useStores } from '../../../../hooks/useStores';
import { observer } from 'mobx-react';
import { UseModuloBusinessUnitsStore } from '../../context/UseModuloBusinessUnitsStore';

//Components
import { Link } from 'react-router-dom';
import { FaEllipsisV, FaMap } from 'react-icons/fa';
import BusinessUnitContextMenu from './BusinessUnitContextMenu';
import BottoneAggiungiBusinessUnit from './BottoneAggiungiBusinessUnit';
import BusinessUnitGruppi from './../BusinessUnitGruppi';

const BusinessUnitTile: React.FC<{ businessUnit: IBusinessUnit, primo?: boolean, ultimo?: boolean, link: string }> = observer(({ businessUnit, primo, ultimo, link = '' }) => {

  const privilegio = useOttieniPrivilegioModulo()
  const { t } = useTranslation();

  const { ui } = useStores()
  const { canvas_business_units } = UseModuloBusinessUnitsStore()

  const [contextMenu, setContextMenu] = useState(false);
  const [numSottoFattori, setNumSottoFattori] = useState(0);

  useEffect(() => {
    businessUnit.sotto_business_unit && setNumSottoFattori(businessUnit.sotto_business_unit.length)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleContextMenu = () => {
    //Il timeout permette di far comparire il context menu dopo che l'utente ha cliccato
    setTimeout(() => {
      setContextMenu(!contextMenu)
    }, 1)
  }

  document.addEventListener('click', () => {
    if (contextMenu) {
      setContextMenu(false)
    }
  })

  return (
    <div className="flex flex-col items-center gap-[24px] relative left-0">

      {primo && <div className="bg-lightgray-d dark:bg-darkgray-d absolute left-0 w-[calc(50%-1px)] top-[-20px] h-[15px]" />}
      {ultimo && privilegio < 2 && <div className="bg-lightgray-d dark:bg-darkgray-d absolute right-0 w-[calc(50%-1px)] top-[-20px] h-[15px]" />}

      <div className="bg-white dark:bg-darkgray p-4 flex items-start justify-between w-[250px] h-[150px] relative">

        <div>
          <div className="flex items-center gap-2">
            <Link className="dark:text-dark-text hover:underline" to={link ? link + businessUnit.nome : businessUnit.nome}>
              <h2 className="font-bold text-lg">
                {businessUnit?.nome.substring(0, 14)}{businessUnit?.nome.length > 14 && '...'}
              </h2>
            </Link>
          </div>

          <br />

          {(businessUnit.categoria || businessUnit.longitudine) &&
            <div className="absolute bottom-0 right-0 flex gap-[3px]">
              {businessUnit.categoria &&
                <p className="bg-accent dark:bg-dark-accent p-1 px-2 text-white text-sm text-xs">
                  {businessUnit.categoria}
                </p>
              }
              {businessUnit.longitudine && businessUnit.latitudine &&
                <p
                  className="bg-accent dark:bg-dark-accent p-1 px-2 text-white text-sm text-xs flex items-center justify-center cursor-pointer"
                  onMouseEnter={() => ui.mountFollowMouse(<p>{t('ModuloBusinessUnit.TileBusinessUnit.geoLocalizzataIn')} {businessUnit.paese}</p>)}
                  onMouseLeave={() => ui.unmountFollowMouse()}
                  onClick={() => canvas_business_units.settaModalitaVista('mappa', businessUnit.latitudine, businessUnit.longitudine)}
                >
                  <FaMap />
                </p>
              }
            </div>
          }

          <div className="bg-lightgray dark:bg-darkgray-dd p-2 rounded-full text-xs hover:bg-lightgray-d"
            onMouseEnter={() => ui.mountFollowMouse(<BusinessUnitGruppi businessUnit={businessUnit} />)}
            onMouseLeave={() => ui.unmountFollowMouse()}
          >
            {businessUnit.gruppi.length} {businessUnit.gruppi.length === 1 ? t('ModuloBusinessUnit.TileBusinessUnit.ruoloAttivo') : t('ModuloBusinessUnit.TileBusinessUnit.ruoliAttivi')}
          </div>

        </div>

        <div className="flex gap-2 md:gap-4 items-center">
          {privilegio >= 3 &&
            <div className="cursor-pointer relative p-1" onClick={() => toggleContextMenu()}>
              <FaEllipsisV className="hover:opacity-70" />

              {contextMenu && <BusinessUnitContextMenu businessUnit={businessUnit as IBusinessUnit} />}
            </div>
          }
        </div>

        {
          (privilegio >= 2 ||
          (businessUnit.sotto_business_unit && businessUnit.sotto_business_unit.length > 0)) &&
          <div className="bg-accent dark:bg-dark-accent w-[2px] h-[12px] absolute bottom-[-12px] mx-auto left-[calc(50%-1px)]" />
        }

      </div>

      {
        businessUnit.sotto_business_unit && businessUnit.sotto_business_unit.length > 0 &&
        <div className="bg-accent dark:bg-dark-accent w-full h-[2px] absolute top-[162px] mx-auto "></div>
      }

      <div className="flex items-start gap-6">
        {/* Se nella business unit sono presenti altre unita queste vengono caricate */}
        {
          businessUnit.sotto_business_unit && businessUnit.sotto_business_unit.length > 0 &&

          businessUnit.sotto_business_unit.map((businessUnit: IBusinessUnit, i: number) => (
            <SottoBusinessUnitTile
              key={businessUnit.nome}
              businessUnit={businessUnit}
              primo={i === 0 ? true : false}
              ultimo={i === (numSottoFattori - 1) ? true : false}
            />
          ))


        }
        {privilegio >= 2 && <BottoneAggiungiBusinessUnit businessUnit={businessUnit} />}
      </div>

    </div>
  )
})

const SottoBusinessUnitTile: React.FC<{ businessUnit: IBusinessUnit, primo?: boolean, ultimo?: boolean, link?: string }> = ({ businessUnit, primo, ultimo, link = '' }) => {
  return (
    <div className="w-full flex items-stretch justify-center relative">
      <div className="bg-accent dark:bg-dark-accent w-[2px] h-[12px] absolute top-[-12px]"></div>
      <BusinessUnitTile businessUnit={businessUnit} primo={primo} ultimo={ultimo} link={link} />
    </div>
  )
}

export default BusinessUnitTile;
