import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { ITemaMaterialita } from '../../types'
import { observer } from 'mobx-react-lite'
import { Link, useParams } from 'react-router-dom'
import { useStores } from '../../../../hooks/useStores'
import InformativaTemaMaterialita from './InformativaTemaMaterialita'

//Components
import { FaPencilAlt, FaAngleLeft } from 'react-icons/fa'
import { useTemaMaterialitaStores } from './context/useTemaMaterialitaStores'
import TemaMaterialitaForm from './components/TemaMaterialita/TemaMaterialitaForm'
import TemaMaterialitaBilancioTile from './components/TemaMaterialita/TemaMaterialitaBilancioTile'

const SchedaTemaMaterialita = observer(() => {

  const { ui } = useStores()
  const { t } = useTranslation()
  const { materialita } = useTemaMaterialitaStores()

  const { tema_materialita_codice } = useParams()

  useEffect(() => {
    materialita.getTemaMaterialita(tema_materialita_codice as string)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    materialita.TemaMaterialitaModificato
  ])

  return (
    <div>

      <div className="flex items-center gap-2">
        <Link
          className="grow bg-accent text-white flex items-center justify-start gap-2 p-2 dark:bg-dark-accent"
          to={`/app/modulo/materialita`}>
          <FaAngleLeft />
          <span>{t('ModuloFattoriEsg.SchedaTemaMaterialita.tornaAllaLista')}</span>
        </Link>
        <div
          onClick={() => ui.mountAsideModal(<TemaMaterialitaForm tema={materialita.TemaMaterialitaAttivo as ITemaMaterialita} />)}
          className="bg-white dark:bg-darkgray-dd p-2 flex items-center justify-center"
        >
          <FaPencilAlt className="cursor-pointer hover:text-accent" />
        </div>
      </div>

      <div className="my-2">
        {materialita.TemaMaterialitaAttivo &&
          <>
            <InformativaTemaMaterialita temaMaterialita={materialita.TemaMaterialitaAttivo as ITemaMaterialita} />

            {materialita.TemaMaterialitaAttivo?.bilanci.length > 0 &&
              <div className="mt-4 flex flex-col gap-2">
                {materialita.TemaMaterialitaAttivo?.bilanci.map((bilancio) => <TemaMaterialitaBilancioTile bilancio={bilancio} />)}
              </div>
            }
          </>
        }
      </div>


    </div>
  )
})

export default SchedaTemaMaterialita;