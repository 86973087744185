import { observable, action, makeAutoObservable, runInAction } from 'mobx'

import {
  getBusinessUnitCategoriaLista,
  creaBusinessUnitCategoria,
  modificaBusinessUnitCategoria,
  eliminaBusinessUnitCategoria,
  
} from '../rest/business_unit_categoria'

import { IBusinessUnitCategoria } from '../../types'

export class BusinessUnitCategoriaStore {

  businessUnitCategorie: IBusinessUnitCategoria[] = []

  creatingBusinessUnitCategoria: boolean = false
  creatingBusinessUnitCategoriaError: string = ''
  businessUnitCategoriaCreata: boolean = false

  editingBusinessUnitCategoria: boolean = false
  modificaBusinessUnitCategoriaError: string = ''
  businessUnitCategoriaModificata: boolean = false

  deletingBusinessUnitCategoria: boolean = false
  eliminaBusinessUnitCategoriaError: string = ''
  businessUnitCategoriaEliminata: boolean = false


  constructor() {
    makeAutoObservable(this, {

      businessUnitCategorie: observable,
      getBusinessUnitCategoriaLista: action,

      //Crea businessUnitCategoria
      creaBusinessUnitCategoria: action,
      creatingBusinessUnitCategoria: observable,
      creatingBusinessUnitCategoriaError: observable,
      businessUnitCategoriaCreata: observable,

      //Modifica businessUnitCategoria
      modificaBusinessUnitCategoria: action,
      editingBusinessUnitCategoria: observable,
      modificaBusinessUnitCategoriaError: observable,
      businessUnitCategoriaModificata: observable,

      //Elimina businessUnitCategoria
      eliminaBusinessUnitCategoria: action,
      deletingBusinessUnitCategoria: observable,
      eliminaBusinessUnitCategoriaError: observable,
      businessUnitCategoriaEliminata: observable,

    })
  }

  async getBusinessUnitCategoriaLista() {
    await getBusinessUnitCategoriaLista().then(res => {

      runInAction(() => this.businessUnitCategorie = res.data.data)

    }).catch(err => {

      console.log(err)

    })
  }

  //Crea un businessUnitCategoria
  async creaBusinessUnitCategoria(businessUnitCategoria: IBusinessUnitCategoria) {

    this.creatingBusinessUnitCategoria = true

    await creaBusinessUnitCategoria(businessUnitCategoria).then(res => {

      runInAction(() => this.creatingBusinessUnitCategoriaError = '')

    }).catch(err => {

      runInAction(() => this.creatingBusinessUnitCategoriaError = err.response.data.message ? err.response.data.message : 'Errore generico')

    }).finally(() => {

      runInAction(() => this.creatingBusinessUnitCategoria = false)

      runInAction(() => this.businessUnitCategoriaCreata = !this.businessUnitCategoriaCreata)

    })
  }


  async modificaBusinessUnitCategoria(businessUnitCategoria: IBusinessUnitCategoria,) {

    this.editingBusinessUnitCategoria = true

    await modificaBusinessUnitCategoria(businessUnitCategoria).then(res => {

      runInAction(() => this.modificaBusinessUnitCategoriaError = '')

    }).catch(err => {

      runInAction(() => this.modificaBusinessUnitCategoriaError = err.response.data.message ? err.response.data.message : 'Errore generico')

    }).finally(() => {

      runInAction(() => this.editingBusinessUnitCategoria = false)

      runInAction(() => this.businessUnitCategoriaModificata = !this.businessUnitCategoriaModificata)

    })
  }

  async toggleAttivazionebusinessUnitCategoria(businessUnitCategoria: IBusinessUnitCategoria) {

    this.editingBusinessUnitCategoria = true

    await modificaBusinessUnitCategoria(businessUnitCategoria).then(res => {

      runInAction(() => this.modificaBusinessUnitCategoriaError = '')

    }).catch(err => {

      runInAction(() => this.modificaBusinessUnitCategoriaError = err.response.data.message ? err.response.data.message : 'Errore generico')

    }).finally(() => {

      runInAction(() => this.editingBusinessUnitCategoria = false)

      runInAction(() => this.businessUnitCategoriaModificata = !this.businessUnitCategoriaModificata)

    })
  }

  async eliminaBusinessUnitCategoria(nome: string) {

    this.deletingBusinessUnitCategoria = true

    await eliminaBusinessUnitCategoria(nome).then(res => {

      runInAction(() => this.eliminaBusinessUnitCategoriaError = '')

    }).catch(err => {

      runInAction(() => this.eliminaBusinessUnitCategoriaError = err.response.data.message ? err.response.data.message : 'Errore generico')

      console.log(this.eliminaBusinessUnitCategoriaError)

    }).finally(() => {

      runInAction(() => this.deletingBusinessUnitCategoria = false)

      runInAction(() => this.businessUnitCategoriaEliminata = !this.businessUnitCategoriaEliminata)
    }
    )
  }

  

}