import React, { useEffect, useState } from 'react'
import { useModuloESGStores } from '../../context/useModuloESGStores'
import RuotaObiettiviAgenda2030 from '../../pagineFattoriESG/UiModuloAgenda2030/components/RuotaObiettiviAgenda2030'
import { getListaObiettiviAgenda2030 } from '../../pagineFattoriESG/UiModuloAgenda2030/rest/agenda_2030'
import { IObiettivoAgenda2030 } from '../../types'
import { observer } from 'mobx-react-lite'

const SchedaBilancioObiettiviAgenda2030 = observer(() => {
  const { bilanci } = useModuloESGStores()
  const [listaObiettivi, setListaObiettivi] = useState<IObiettivoAgenda2030[]>([])

  useEffect(() => {
    getListaObiettiviAgenda2030().then((res) => {
      setListaObiettivi(res.data.data)
    })
  }, [])

  return (
    <div className="flex flex-col gap-2">
      <div>
        <p className="text-center text-xl pt-6">{bilanci.bilancioAttivo?.obiettivi_agenda2030.length} su {listaObiettivi.length} obiettivi dell'agenda 2030 vengono presi in considerazione in questo bilancio</p>
      </div>
      <RuotaObiettiviAgenda2030
        obiettivi={listaObiettivi}
        obiettiviAttivi={bilanci.bilancioAttivo?.obiettivi_agenda2030}
      />
    </div>
  )
})

export default SchedaBilancioObiettiviAgenda2030;