import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { observer } from 'mobx-react-lite'
import { ITemaMaterialita, ITemaMaterialitaAnno } from '../../types'

//Components
import TextLoader from '../../../../components/common/TextLoader';
import { FaBook } from 'react-icons/fa';

const InformativaTemaMaterialita: React.FC<{ temaMaterialita: ITemaMaterialita | ITemaMaterialitaAnno }> = observer(({ temaMaterialita }) => {
  const { t } = useTranslation()

  return (
    <div className="grow flex flex-col" >
      <h2
        className="rounded-sm text-white p-2 flex items-center gap-1"
        style={{ backgroundColor: temaMaterialita?.colore ? temaMaterialita.colore : '#333333' }}
      >
        <FaBook />
        <span>{t('ModuloFattoriEsg.InformativeFattoriEsg.SchedaInformativa')} <span className="font-bold">"{temaMaterialita?.nome ? temaMaterialita.nome : <span className="mx-1"><TextLoader width={30} /></span>}"</span></span>
      </h2>
      <div className="p-4 bg-white dark:bg-darkgray-dd shadow-xl grow">
        <div className="flex items-center justify-between gap-2">
          <div>
            <p className="font-bold text-xs text-accent dark:text-dark-accent">{temaMaterialita ? temaMaterialita.codice : <TextLoader width={100} />}</p>
            <h2 className="font-bold text-xl">{temaMaterialita ? `${temaMaterialita?.nome} ` : <TextLoader width={200} />}</h2>
          </div>
        </div>

        <hr className="my-2 border border-lightgray-d dark:border-darkgray-d" />

        <p>{temaMaterialita ? `${temaMaterialita.descrizione ? temaMaterialita.descrizione : t('ModuloFattoriEsg.InformativaMaterialita.nessunDescrizioneDisponibile')}` : <TextLoader />}</p>
      </div>
    </div>
  )
})

export default InformativaTemaMaterialita;
