import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { IBusinessUnitRuolo } from '../../types'
import { useStores } from '../../../../hooks/useStores'
import { observer } from 'mobx-react-lite'


//Components
import Dialogue from '../../../../components/common/Dialogue'
import BusinessUnitRuoloForm from './BusinessUnitRuoloForm'
import useOttieniPrivilegioModulo from '../../../../hooks/useOttieniPrivilegioModulo'
import { UseModuloBusinessUnitRuoliStore } from '../context/UseModuloBusinessUnitRuoliStore'

const BusinessUnitRuoloContextMenu : React.FC<{ BusinessUnitRuolo: IBusinessUnitRuolo }> = observer(({ BusinessUnitRuolo }) => {

    const privilegio = useOttieniPrivilegioModulo();
    const { t } = useTranslation();
    const { ui } = useStores();
    const { business_unit_ruolo } = UseModuloBusinessUnitRuoliStore();

    const handleEliminaRuolo = () => {

        business_unit_ruolo.eliminaBusinessUnitRuolo(BusinessUnitRuolo.valore as number).then(() => {

            if (business_unit_ruolo.eliminaBusinessUnitRuoloError) {

                ui.mountDialogue(<Dialogue testo={business_unit_ruolo.eliminaBusinessUnitRuoloError} />)

            } else {

                ui.mountDialogue(<Dialogue testo={t('ModuloBusinessUnit.BusinessUnitRuoloContextMenu.ruoloEliminatoSucesso')} />)

            }

        });

    }

    return (
        <div className="absolute shadow-lg border border-lightgray-dd bg-white dark:bg-black p-3 right-0 dark:border-0">
            <ul className="whitespace-nowrap flex flex-col gap-2">
                {privilegio >= 3 && <li onClick={() => ui.mountAsideModal(<BusinessUnitRuoloForm ruolo={BusinessUnitRuolo} />)}>{t('ModuloBusinessUnit.BusinessUnitRuoloContextMenu.modificaRuolo')}</li>}
                {privilegio >= 4 &&
                    <li
                        className="text-red dark:text-dark-red"
                        onClick={() => ui.mountDialogue(
                            <Dialogue
                                testo={`${t('ModuloBusinessUnit.BusinessUnitRuoloContextMenu.eliminaRuolo')} ${BusinessUnitRuolo.nome}?`}
                                primaryCta={`${t('ModuloBusinessUnit.BusinessUnitRuoloContextMenu.eliminaIlRuolo')} ${BusinessUnitRuolo.nome}`}
                                primaryCtaColor="bg-red dark:bg-dark-red"
                                secondaryCta={t('common.annulla')}
                                secondaryCtaColor="bg-white dark:bg-darkgray-dd !text-text dark:!text-dark-text"
                                onConfirm={() => handleEliminaRuolo()}
                            />)}
                    >
                        {t('ModuloBusinessUnit.BusinessUnitRuoloContextMenu.eliminaRuolo')}
                    </li>
                }
            </ul>
        </div>
    )
})

export default BusinessUnitRuoloContextMenu;