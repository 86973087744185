import axios from 'axios'
import { config } from '../../../config'
import { IUnitaMisura } from '../../../types'

//Riceve lista privilegi senza parent
export const getUnitaMisuraLista = () => {
    return axios.get(`${config.API_URI}unita_misura`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceve singolo unitaMisura
export const getUnitaMIsura = (codice: string) => {
    return axios.get(`${config.API_URI}unita_misura/${codice}`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Crea un unitaMisura
export const creaUnitaMisura = (unitaMisura: IUnitaMisura) => {
    return axios.post(`${config.API_URI}unita_misura`, {
        codice: unitaMisura.codice,
        descrizione: unitaMisura.descrizione,
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Modifica un unitaMisura
export const modificaUnitaMisura = (unitaMisura: IUnitaMisura) => {
    return axios.put(`${config.API_URI}unita_misura/${unitaMisura.codice_originale ? unitaMisura.codice_originale : unitaMisura.codice}`, {
        codice: unitaMisura.codice,
        descrizione: unitaMisura.descrizione,
        codice_originale: unitaMisura.codice_originale,
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Elimina un unitaMisura
export const eliminaUnitaMisura = (codice: string) => {
    return axios.delete(`${config.API_URI}unita_misura/${codice}`, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}


