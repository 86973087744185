import { observable, action, makeAutoObservable, runInAction } from 'mobx'
import { IModulo } from '../types'
// import { modules } from '../modules'
import { getModulo } from '../moduli/gestioneModuli/rest/gestioneModuli'

export class ModuliStore {
    moduloAttivo: IModulo | null = null
    gettingModuloAttivo: boolean = false

    moduliInstallati: string[] = []

    constructor() {
        makeAutoObservable(this, {
            moduloAttivo: observable,
            gettingModuloAttivo: observable,
            getModulo: action,

            moduliInstallati: observable,
        })
    }

   
    getModulo = (anno: string) => {

        this.gettingModuloAttivo = true

        getModulo(anno).then((res) => {

            runInAction(() => this.moduloAttivo = res.data.data )

        }).catch((err) => {

            runInAction(() => this.moduloAttivo = null)

            if(err.response.status === 403) {
                window.location.href = '/app'
            }

        }).finally(() => {

            runInAction(() => this.gettingModuloAttivo = false)
        })
    }


}