import React from 'react'

//Data
import { observer } from 'mobx-react-lite';
import { useModuloESGStores } from '../../context/useModuloESGStores';
import { IFattoreESG } from '../../types';
import { IBusinessUnit } from '../../../UiModuloBusinessUnit/types';

//Components
import IndicatoreTabellaForm from '../../components/indicatori/IndicatoreTabellaForm';

const SchedaTabella = observer(() => {

    const { bilanci } = useModuloESGStores()

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4">
                {bilanci.fattoreBilancioAttivo?.business_units.map((businessUnit : IBusinessUnit) => {
                    return (
                        <IndicatoreTabellaForm tabella={bilanci.fattoreBilancioAttivo as IFattoreESG} business_unit_nome={businessUnit.nome} />
                    )
                })}
            </div>
        </div>
    )
})

export default SchedaTabella;