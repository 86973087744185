export const en_modulo_gestione_privilegi = {
    Archivio: {
        passaGestioneModuli: 'Go to modules management',
        nessunoPrivilegio: 'No privilege available',
        aggiungiNuovoPrivilegio: 'Add new privilege',
    },

    Form: {
        modificaPrivilegi: 'Edit privilege',
        creaPrivilegi: 'Create new privilege',
        modificaTesto: 'Edit privilege data',
        modificaTestoCompleto: 'Associate groups to allow users in them to access features.',
        Priorita: 'Priority',
        placeholderPriorita: '0, 3, 10',
        almenoUno: 'It must be more than 1',
        placeholderNome: 'Visualization, editing, elimination',
        messaggiPrivilegioCreatoSucesso: 'Privilege successfully created',
        privilegiCrea: 'Create privilege',
    },

    ContextMenu: {
        privilegioEliminato: 'Privilege successfully deleted',
        modificaPrivilegio: 'Edit privilege',
        eliminaPrivilegio: 'Delete privilege',
        messaggioConfirmazione: 'Are you sure you want to delete this privilege?',
        messsagioElinimaPrivilegio: 'Delete privilege',
    },
}