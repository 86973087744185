import React, { useEffect, useState } from 'react'
import { IBusinessUnit, IGruppoBusinessUnit, IBusinessUnitRuolo } from '../types';
import { getRuoli } from '../UiModuloBusinessUnitRuoli/rest/BusinessUnitRuoli';
import { FaAngleDoubleDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'

const BusinessUnitGruppi: React.FC<{ businessUnit: IBusinessUnit }> = ({ businessUnit }) => {
    const { t } = useTranslation();
    
    const [BusinessUnitRuoli, setBusinessUnitRuoli] = useState<IBusinessUnitRuolo[]>()    

    useEffect(() => {
        getRuoli().then((res) => {
            setBusinessUnitRuoli(res.data.data)
        })
    }, [])

    return (
        <div className="flex flex-col gap-2">
            {businessUnit.gruppi.length > 0 ?
                businessUnit.gruppi.map((gruppo: IGruppoBusinessUnit) => {
                    return (
                        <div className="bg-lightgray p-2 rounded-sm">
                            <span>{gruppo.nome}</span>
                            <div className="flex gap-1 items-center">
                            {t('ModuloBusinessUnit.ModuloBusinessUnitGruppi.ruolo')} {BusinessUnitRuoli?.filter(ruolo => ruolo.valore === gruppo.ruolo_valore)[0].nome}
                                {businessUnit.nome !== gruppo.business_unit_nome &&
                                    <FaAngleDoubleDown size={10} />
                                }
                            </div>
                        </div>
                    )
                })
                :
                <div> {t('ModuloBusinessUnit.ModuloBusinessUnitGruppi.nessunoGruppoCollegato')}</div>
            }
        </div>
    )
}

export default BusinessUnitGruppi;
