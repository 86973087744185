import axios from 'axios'
import { config } from '../../../../config'
import { IBusinessUnitRuolo } from '../../types'

//Riceve lista Ruoli senza parent
export const getRuoli = () => {
    return axios.get(`${config.API_URI}business_unit_ruoli`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceve singolo BusinessUnitRuolo
export const getBusinessUnitRuolo = (valore: number) => {
    return axios.get(`${config.API_URI}business_unit_ruoli/${valore}`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Crea un BusinessUnitRuolo
export const creaBusinessUnitRuolo = (BusinessUnitRuolo: IBusinessUnitRuolo) => {
    return axios.post(`${config.API_URI}business_unit_ruoli`, {
        valore: BusinessUnitRuolo.valore,
        nome: BusinessUnitRuolo.nome,
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Modifica un BusinessUnitRuolo
export const modificaBusinessUnitRuolo = (BusinessUnitRuolo: IBusinessUnitRuolo) => {
    return axios.put(`${config.API_URI}business_unit_ruoli/${BusinessUnitRuolo.valore_originale ?? BusinessUnitRuolo.valore}`, {
        valore: BusinessUnitRuolo.valore,
        nome: BusinessUnitRuolo.nome,
        valore_originale: BusinessUnitRuolo.valore_originale,
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Elimina un BusinessUnitRuolo
export const eliminaBusinessUnitRuolo = (valore: number) => {
    return axios.delete(`${config.API_URI}business_unit_ruoli/${valore}`, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}