import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStores } from '../../../../hooks/useStores'
import { useModuloESGStores } from '../../context/useModuloESGStores'
import { IBilancio } from '../../types'
import { observer } from 'mobx-react-lite'

//Components
import BilanciForm from './BilanciForm'
import Dialogue from '../../../../components/common/Dialogue'
import useOttieniPrivilegioModulo from '../../../../hooks/useOttieniPrivilegioModulo'

const BilancioContextMenu: React.FC<{ bilancio: IBilancio }> = observer(({ bilancio }) => {
  const { t } = useTranslation();

  const privilegio = useOttieniPrivilegioModulo()
  const { ui } = useStores()
  const { bilanci } = useModuloESGStores()

  const handleEliminaBilancio = () => {
    bilanci.eliminaBilancio(bilancio.anno).then(() => {

      if (bilanci.eliminaBilancioError) {

        ui.mountDialogue(<Dialogue testo={bilanci.eliminaBilancioError} />)

      } else {

        ui.mountDialogue(<Dialogue testo={t('ModuloFattoriEsg.BilancioContextMenu.eliminatoSucesso')} />)

      }

    });
  }

  return (
    <div className="absolute shadow-lg border border-lightgray-dd bg-white dark:bg-black p-3 right-0 dark:border-0">
      <ul className="whitespace-nowrap flex flex-col gap-2">
        {privilegio >= 3 && <li onClick={() => ui.mountAsideModal(<BilanciForm bilancio={bilancio} />)}>{t('ModuloFattoriEsg.BilancioContextMenu.modificaBilancio')}</li>}
        {privilegio >= 4 &&
          <li
            className="text-red dark:text-dark-red"
            onClick={() => ui.mountDialogue(
              <Dialogue
                testo={`${t('ModuloFattoriEsg.BilancioContextMenu.eliminaBilacio')} ${bilancio.anno}? ${t('ModuloFattoriEsg.BilancioContextMenu.eliminaBilancioTesto')}`}
                primaryCta={`${t('ModuloFattoriEsg.BilancioContextMenu.eliminaBilancioDelAnno')} ${bilancio.anno}`}
                primaryCtaColor="bg-red dark:bg-dark-red"
                secondaryCta={t('common.annulla')}
                secondaryCtaColor="bg-white dark:bg-darkgray-dd !text-text dark:!text-dark-text"
                onConfirm={() => handleEliminaBilancio()}
              />)}
          >
            {t('ModuloFattoriEsg.BilancioContextMenu.eliminaBilancioButtone')}
          </li>
        }
      </ul>
    </div>
  )
})

export default BilancioContextMenu;
