import React from 'react'

//Data
import { useStores } from '../../hooks/useStores'
import { observer } from 'mobx-react-lite'

//Components
import HeaderModal from './HeaderModal';
import { FaAngleDown, FaAngleUp, FaSearch } from 'react-icons/fa'
import { CgMenuGridO, CgClose } from 'react-icons/cg'
import BarraRicerca from './BarraRicerca'
import Ricerca from './Ricerca'
import NetworkError from './NetworkError'
import { useTranslation } from 'react-i18next';

export const Header = observer(() => {

    const { t } = useTranslation()
    const { auth, ui } = useStores();

    const styles = {
        header: "border-b border-lightgray-dd dark:border-darkgray-dd  px-2 md:px-4 min-h-[70px] flex items-center justify-between w-full bg-lightgray dark:bg-darkgray z-10 max-w-screen",
        searchbar: "focus:outline-none bg-[rgba(0,0,0,0)] text-text-light dark:text-dark-text-light w-full md_w-auto",
        mobileIcons: "block md:hidden flex gap-2 items-center",
        user: "flex gap-2 items-center px-2 py-1 rounded-md cursor-pointer hover:bg-lightgray-d dark:hover:bg-darkgray-d relative",
    }

    return (
        <>
            {auth.networkError && <NetworkError />}
            <header className={styles.header}>

                {/* search box */}
                <BarraRicerca />

                {/* Header */}
                {!ui.searchBarMobile && (
                    <>
                        {/* mobile icons */}
                        <div className={styles.mobileIcons}>
                            {ui.mainNavMobile ?
                                <CgClose className="cursor-pointer" size={18} onClick={() => ui.toggleMainNavMobile()} />
                                :
                                <CgMenuGridO className="cursor-pointer" size={18} onClick={() => ui.toggleMainNavMobile()} />
                            }

                            <FaSearch onClick={() => ui.toggleSearchBarMobile()} className="cursor-pointer" />
                        </div>

                        <div className="flex gap-2 items-center">

                            <div className="relative">
                                <div id="header-modal" onClick={() => ui.toggleHeaderModal()} className={styles.user}>
                                    <p>{t('common.ciao')}, {auth.utente && auth.utente?.nome.substring(0, 13)}{auth.utente && auth.utente.nome?.length > 13 && '...'}</p>

                                    {ui.headerModal ? <FaAngleUp /> : <FaAngleDown />}


                                </div>

                                {ui.headerModal && <HeaderModal />}

                            </div>

                        </div>
                    </>
                )}
            </header>

            {ui.ricerca && <Ricerca />}
        </>
    )
})