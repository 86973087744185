import { observable, action, makeAutoObservable, runInAction } from 'mobx'
import {
  creaRichiesta,
  getRichieste,
  gestisciRichiesta,
} from '../rest/gestioneRichieste'

import {
  getUtentiSenzaGruppo,
  getUtenti,
  creaUtente,
  modificaUtente,
  eliminaUtente,
  reinviareEmailVerifica,
  aggiornaContatti,
} from '../rest/gestioneUtenti'

import {
  creaGruppo,
  getGruppi,
  modificaGruppo,
  eliminaGruppo,

} from '../rest/gestioneGruppi'
import { IUtente, IRichiesta, IGruppo, ILingua } from '../../../types'
import { Watch } from 'typescript'

export class GestioneUtentiStore {

  //Richieste
  richieste: IRichiesta[] = []

  creatingRichiesta: boolean = false
  creatingRichiestaError: string = ''
  richiestaCreata: boolean = false

  editingRichiesta: boolean = false
  modificaRichiestaError: string = ''
  richiestaModificata: boolean = false

  //users
  utenti: IUtente[] = []
  utenti_senza_gruppo: IUtente[] = []

  creatingUtente: boolean = false
  creatingUtenteError: string = ''

  editingUtente: boolean = false
  modificaUtenteError: string = ''
  utenteModificato: boolean = false

  deletingUtente: boolean = false
  eliminaUtenteError: string = ''
  utenteEliminato: boolean = false

  reinviandoEmail: boolean = false
  reinviareEmailError: string = ''
  emailInviata: boolean = false

  modificandoContatti: boolean = false
  modificaContattiError: string = ''
  Contattimodificati: boolean = false

  //Gruppi
  gruppi: IGruppo[] = []

  creatingGruppo: boolean = false
  creatingGruppoError: string = ''

  editingGruppo: boolean = false
  modificaGruppoError: string = ''
  gruppoModificato: boolean = false

  deletingGruppo: boolean = false
  eliminaGruppoError: string = ''
  gruppoEliminato: boolean = false

 

  constructor() {
    makeAutoObservable(this, {
      //Richieste
      richieste: observable,
      getRichieste: action,

      creatingRichiesta: observable,
      creatingRichiestaError: observable,
      richiestaCreata: observable,

      //Utenti
      utenti: observable,
      utenti_senza_gruppo: observable,
      getUtenti: action,
      getUtentiSenzaGruppo: action,

      //Crea utente
      creaUtente: action,
      creatingUtente: observable,
      creatingUtenteError: observable,

      //Modifica utente
      modificaUtente: action,
      editingUtente: observable,
      modificaUtenteError: observable,
      utenteModificato: observable,

      //Elimina utente
      eliminaUtente: action,
      deletingUtente: observable,
      eliminaUtenteError: observable,
      utenteEliminato: observable,

      //Gruppi
      gruppi: observable,

      //Crea gruppo
      creaGruppo: action,
      creatingGruppo: observable,
      creatingGruppoError: observable,

      //Modifica gruppo
      modificaGruppo: action,
      editingGruppo: observable,
      modificaGruppoError: observable,
      gruppoModificato: observable,

      //Elimina gruppo
      eliminaGruppo: action,
      deletingGruppo: observable,
      eliminaGruppoError: observable,
      gruppoEliminato: observable,

      //Reinviare Email Verifica
      reinviareEmailVerifica: action,
      reinviandoEmail: observable,
      reinviareEmailError: observable,
      emailInviata: observable,

      //modifica Email utente
      aggiornaContatti: action,
      modificandoContatti: observable,
      modificaContattiError: observable,
      Contattimodificati: observable,

    })
  }

  //Riceve lista richieste
  async getRichieste() {
    await getRichieste().then(res => {

      runInAction(() => this.richieste = res.data.data)

    }).catch(err => {

      console.log(err)

    })
  }

  //Crea una richiesta
  async creaRichiesta(richiesta: IRichiesta) {

    this.creatingRichiesta = true

    await creaRichiesta(richiesta).then(res => {

      runInAction(() => this.richiestaCreata = true)
      runInAction(() => this.creatingRichiestaError = '')

    }).catch(err => {

      console.log(err.response.data.message)

      runInAction(() => this.creatingRichiestaError = err.response.data.message ? err.response.data.message : 'Errore generico')

    }).finally(() => {

      runInAction(() => this.creatingRichiesta = false)

    })
  }

  //Respinge richiesta utente
  async gestisciRichiesta(richiesta: IRichiesta, messaggio: string, accettata: boolean) {

    runInAction(() => this.editingRichiesta = true)

    await gestisciRichiesta(richiesta.id, messaggio, accettata).then(res => {

      runInAction(() => this.modificaRichiestaError = '')

    }).catch(err => {

      runInAction(() => this.modificaRichiestaError = err.response.data.message ? err.response.data.message : 'Errore generico')

    }).finally(() => {

      runInAction(() => this.editingRichiesta = false)

      runInAction(() => this.richiestaModificata = true)

    })
  }

  //Riceve lista utenti
  async getUtenti() {
    await getUtenti().then(res => {
      runInAction(() => this.utenti = res.data.data)
    }).catch(err => {
      console.log(err)
    })

  }

  //Riceve lista utenti senza gruppo
  async getUtentiSenzaGruppo() {
    await getUtentiSenzaGruppo().then(res => {
      runInAction(() => this.utenti_senza_gruppo = res.data.data)
    }).catch(err => {
      console.log(err)
    })
  }

  //Crea un utente
  async creaUtente(utente: IUtente) {

    this.creatingUtente = true

    let id_gruppi: string[] = []

    utente.gruppi?.forEach((gruppo: IGruppo) => {
      id_gruppi.push(gruppo.codice)
    })

    await creaUtente(utente, id_gruppi).then(res => {

      runInAction(() => this.creatingUtenteError = '')

    }).catch(err => {

      runInAction(() => this.creatingUtenteError = err.response.data.message ? err.response.data.message : 'Errore generico')

    }).finally(() => {

      runInAction(() => this.creatingUtente = false)

    })
  }

  //Modifica un utente
  async modificaUtente(utente: IUtente) {

    this.editingUtente = true

    let id_gruppi: string[] = []

    utente.gruppi?.forEach((gruppo: IGruppo) => {
      id_gruppi.push(gruppo.codice)
    })

    await modificaUtente(utente, id_gruppi).then(res => {

      runInAction(() => this.modificaUtenteError = '')

    }).catch(err => {

      runInAction(() => this.modificaUtenteError = err.response.data.message ? err.response.data.message : 'Errore generico')

    }).finally(() => {

      runInAction(() => this.editingUtente = false)

      runInAction(() => this.utenteModificato = !this.utenteModificato)

    })
  }

  async eliminaUtente(id: string) {

    this.deletingUtente = true

    await eliminaUtente(id).then(res => {

      runInAction(() => this.eliminaUtenteError = '')

    }).catch(err => {

      runInAction(() => this.eliminaUtenteError = err.response.data.message ? err.response.data.message : 'Errore generico')

      console.log(this.eliminaUtenteError)

    }).finally(() => {

      runInAction(() => this.deletingUtente = false)

      runInAction(() => this.utenteEliminato = !this.utenteEliminato)

    }
    )
  }

  //Riceve lista gruppi
  async getGruppi() {
    await getGruppi().then(res => {
      runInAction(() => this.gruppi = res.data.data)
    }).catch(err => {
      console.log(err)
    })
  }

  //Crea un gruppo
  async creaGruppo(gruppo: IGruppo, lingua: string) {

    this.creatingGruppo = true

    //Estrapola id degli utenti
    const idUtenti: Array<number> = []

    gruppo.utenti?.forEach(utente => idUtenti.push(utente['id']))

    await creaGruppo(gruppo, idUtenti,lingua).then(() => {

      runInAction(() => this.creatingGruppoError = '')

    }).catch(err => {

      runInAction(() => this.creatingGruppoError = err.response.data.message ? err.response.data.message : 'Errore generico')

    }).finally(() => {

      runInAction(() => this.creatingGruppo = false)

    })
  }

  async modificaGruppo(gruppo: IGruppo) {

    this.editingGruppo = true

    //Estrapola id degli utenti
    let idUtenti: never[] = []

    if (gruppo.utenti) {
      idUtenti = gruppo.utenti.map(utente => {
        return utente['id']
      })
    }

    await modificaGruppo(gruppo, idUtenti).then(res => {

      runInAction(() => this.modificaGruppoError = '')

    }).catch(err => {

      runInAction(() => this.modificaGruppoError = err.response.data.message ? err.response.data.message : 'Errore generico')

    }).finally(() => {

      runInAction(() => this.editingGruppo = false)

      runInAction(() => this.gruppoModificato = !this.gruppoModificato)

    })
  }

  async eliminaGruppo(id: string) {

    this.deletingGruppo = true

    await eliminaGruppo(id).then(res => {

      runInAction(() => this.eliminaGruppoError = '')

    }).catch(err => {

      runInAction(() => this.eliminaGruppoError = err.response.data.message ? err.response.data.message : 'Errore generico')

      console.log(this.eliminaGruppoError)

    }).finally(() => {

      runInAction(() => this.deletingGruppo = false)

      runInAction(() => this.gruppoEliminato = !this.gruppoEliminato)
    }
    )
  }



  async reinviareEmailVerifica(id: string) {

    this.reinviandoEmail = true
    
    await reinviareEmailVerifica(id).then(res => {

      runInAction(() => this.reinviareEmailError = '')

    }).catch(err => {

      runInAction(() => this.reinviareEmailError = err.response.data.message ? err.response.data.message : 'Errore generico')

      console.log(this.reinviareEmailError)

    }).finally(() => {

      runInAction(() => this.reinviandoEmail = false)
      runInAction(() => this.emailInviata = !this.emailInviata) 

    }
    )
  }

  async aggiornaContatti(utente: any) {

    this.modificandoContatti = true

    await aggiornaContatti(utente.id, utente.emailmodify).then(res => {

      runInAction(() => this.modificaContattiError = '')

    }).catch(err => {

      runInAction(() => this.modificaContattiError = err.response.data.message ? err.response.data.message : 'Errore generico')

      console.log(this.modificaContattiError)

    }).finally(() => {

      runInAction(() => this.modificandoContatti = false)

    }
    )
  }

}