import React from 'react'

//Data
import { observer } from 'mobx-react'
import { IObiettivoAgenda2030 } from '../../../types'
import { config } from '../../../../../config'
import { useModuloAgenda2030Stores } from '../context/useModuloAgenda2030Stores'

//Components
import ObiettivoAgenda2030Square from '../components/ObiettivoAgenda2030Square'

const ListaObiettiviAgenda2030: React.FC = observer(() => {

    const { agenda_2030 } = useModuloAgenda2030Stores()

    return (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:lg:grid-cols-6">

            <img
                src={`${config.BACKEND_URI}icone_obiettivi_2030/obiettivi_sviluppo_sostenibile_intro.png`}
                alt="Obiettivi Agenda 2030"
            />

            {agenda_2030.obiettiviAgenda2030.map((obiettivo: IObiettivoAgenda2030) => (
                <ObiettivoAgenda2030Square obiettivo={obiettivo as IObiettivoAgenda2030} key={obiettivo.id} />
            )
            )}
        </div>
    )
})

export default ListaObiettiviAgenda2030;
