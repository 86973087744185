import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useForm, Controller } from 'react-hook-form'
import { UseModuloBusinessUnitsStore } from '../context/UseModuloBusinessUnitsStore'
import { IBusinessUnit } from '../types'
import { observer } from 'mobx-react-lite'

//Components
import InputField from '../../../components/common/form/InputField'
import Select from 'react-select'
import ErrorBox from '../../../components/common/ErrorBox'
import InfoBox from '../../../components/common/InfoBox'
import SpinnerButton from '../../../components/common/SpinnerButton'
import { FaMap, FaPlus } from 'react-icons/fa'
import { getBusinessUnitCategoriaLista } from '../UiModuloBusinessUnitCategorie/rest/business_unit_categoria'
import { decodificaIndirizzo } from '../rest/business_units'
import Spinner from '../../../components/common/Spinner'
import { get } from '../../../rest/crud_generica'

const BusinessUnitsForm: React.FC<{ businessUnit?: IBusinessUnit, nomePadre?: string }> = observer(({ businessUnit, nomePadre }) => {

    const { t } = useTranslation();
    const [mostraIndirizzo, setMostraIndirizzo] = useState<boolean>(false)
    const [categorie, setCategorie] = useState<[]>([])
    const [paesi, setPaesi] = useState<[]>([])

    const [indirizziPossibili, setIndirizziPossibili] = useState<[]>()
    const [indirizzoCercato, setIndirizzoCercato] = useState<boolean>(false)
    const [gettingDecodificaIndirizzo, setGettingDecodificaIndirizzo] = useState<boolean>(false)
    const [decodificaIndirizzoError, setDecodificaIndirizzoError] = useState<string>('')
    const [indirizzoAttivo, setIndirizzoAttivo] = useState<any>()

    useEffect(() => {
        if (businessUnit && (businessUnit.civico || businessUnit.via || businessUnit.cap || businessUnit.citta || businessUnit.paese)) {
            setMostraIndirizzo(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { business_units } = UseModuloBusinessUnitsStore()

    const { register, handleSubmit, watch, setValue, control, formState: { errors, isSubmitSuccessful } } = useForm({
        defaultValues: {
            nome_originale: businessUnit ? businessUnit.nome : '',
            nome: businessUnit ? businessUnit.nome : '',
            descrizione: businessUnit ? businessUnit.descrizione : '',
            civico: businessUnit ? businessUnit.civico : '',
            via: businessUnit ? businessUnit.via : '',
            cap: businessUnit ? businessUnit.cap : '',
            citta: businessUnit ? businessUnit.citta : '',
            paese: businessUnit ? businessUnit.paese : '',
            categoria: businessUnit ? businessUnit.categoria : '',
            latitudine: businessUnit ? businessUnit.latitudine : '',
            longitudine: businessUnit ? businessUnit.longitudine : '',
            nome_padre: businessUnit ? businessUnit.nome_padre : (nomePadre ? nomePadre : ''),
        }
    });

    const onSubmit = () => {
        if (businessUnit) {
            business_units.modificaBusinessUnit(watch('nome_originale'), watch() as IBusinessUnit)
        } else {
            business_units.creaBusinessUnit(watch() as IBusinessUnit)
        }
    }


    //Setta le categorie
    useEffect(() => {
        getBusinessUnitCategoriaLista().then((res) => {
            setCategorie(res.data.data)

        })
    }, [])

    //setta i paesi
    useEffect(() => {
        get('paesi').then((res) => {
            setPaesi(res.data.data)
        })
    }, [])

    //Ristruttura dati per label MultiSelect
    categorie.map((categoria: any) => {
        categoria.value = categoria.nome
        categoria.label = categoria.nome
    })

    //Ristruttura dati per label MultiSelect
    paesi.map((paese: any) => {
        paese.value = paese.nome
        paese.label = paese.nome
    })

    //Effettua chiamata decodifica indirizzo
    const handleDecodificaIndirizzo = (civico: string, via: string, cap: string, citta: string, paese: string) => {

        setIndirizzoCercato(true)
        setGettingDecodificaIndirizzo(true)
        setDecodificaIndirizzoError('')

        decodificaIndirizzo(civico, via, cap, citta, paese).then((res) => {

            setIndirizziPossibili(res.data.data.features.filter((f: any) => f.relevance >= 0.56))

        }).catch(err => {

            setIndirizzoCercato(false)
            setDecodificaIndirizzoError(t('ModuloBusinessUnit.FormBusinessUnit.verificatoErrore'))

        }).finally(() => {

            setGettingDecodificaIndirizzo(false)
        })
    }

    const handleSetIndirizzoAttivo = (indirizzo: string) => {
        setIndirizzoAttivo(indirizzo)
    }

    useEffect(() => {
        indirizzoAttivo && indirizzoAttivo.context.forEach((row: any) => {
            if (row.id.split('.')[0] === 'postcode') {
                setValue('cap', row.text)
            }
            if (row.id.split('.')[0] === 'place') {
                setValue('citta', row.text)
            }
            if (row.id.split('.')[0] === 'country') {
                setValue('paese', row.text)
            }

            setValue('latitudine', indirizzoAttivo.geometry.coordinates[1])
            setValue('longitudine', indirizzoAttivo.geometry.coordinates[0])
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indirizzoAttivo])

    return (
        <div>
            <div className="mb-4">
                <h2 className="text-2xl mb-2">
                    {businessUnit ?
                        `${t('ModuloBusinessUnit.FormBusinessUnit.modificaBusinessUnit')} ${businessUnit.nome}`
                        :
                        `${t('ModuloBusinessUnit.FormBusinessUnit.creaBusinessUnit')}${nomePadre ? `${t('ModuloBusinessUnit.FormBusinessUnit.figliaDi')} ${nomePadre}` : ''}`
                    }
                </h2>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>

                <InputField
                    label={t('common.nome')}
                    placeholder={t('ModuloBusinessUnit.FormBusinessUnit.placeholderBusinessUnit')}
                    type="text"
                    error={errors.nome}
                    form={register("nome", { required: t('common.fornisciNome') })}
                />

                <InputField
                    label={t('common.descrizione')}
                    placeholder={t('ModuloBusinessUnit.FormBusinessUnit.placeHolderDescrizione')}
                    type="textarea"
                    error={errors.descrizione}
                    form={register("descrizione")}
                />

                <div className="form">
                    <label>{t('common.categoria')}</label>
                    <Controller
                        name="categoria"
                        control={control}
                        render={({ field: { onChange } }) => (
                            <Select
                                options={categorie}
                                defaultValue={categorie.find((t: any) => t.value === businessUnit?.categoria)}
                                onChange={(e: any) => onChange(e.value)}
                            />
                        )}
                    />
                </div>

                {
                    mostraIndirizzo &&
                    <div className="p-3 bg-lightgray dark:bg-darkgray-d rounded-sm my-2">
                        <div className="flex gap-2 items-start">
                            <InputField
                                label={t('ModuloBusinessUnit.FormBusinessUnit.via')}
                                placeholder="Garibaldi"
                                type="text"
                                error={errors.via}
                                form={register("via")}
                            />

                            <div className="w-[80px]">
                                <InputField
                                    label={t('ModuloBusinessUnit.FormBusinessUnit.civico')}
                                    placeholder="16"
                                    type="text"
                                    error={errors.civico}
                                    form={register("civico")}
                                />
                            </div>

                        </div>

                        <InputField
                            label={t('ModuloBusinessUnit.FormBusinessUnit.cap')}
                            placeholder="00420"
                            type="number"
                            error={errors.cap}
                            form={register("cap")}
                        />

                        <InputField
                            label={t('ModuloBusinessUnit.FormBusinessUnit.citta')}
                            placeholder="Roma"
                            type="text"
                            error={errors.citta}
                            form={register("citta")}
                        />

                        <div className="form">
                            <label>{t('common.paese')}</label>
                            <Controller
                                name="paese"
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <Select
                                        options={paesi}
                                        defaultValue={paesi.find((t: any) => t.value === businessUnit?.paese)}
                                        onChange={(e: any) => onChange(e.value)}
                                    />
                                )}
                            />
                        </div>

                        {(watch('via') || watch('civico') || watch('citta') || watch('paese') || watch('cap')) &&
                            <>
                                {
                                    gettingDecodificaIndirizzo ?
                                        <div className="w-full p-2 border border-accent dark:border-dark-accent flex items-center justify-center gap-2 text-accent rounded-sm opacity-80">
                                            <span>{t('ModuloBusinessUnit.FormBusinessUnit.cercandoIndrizzio')}</span>
                                        </div>
                                        :
                                        <div
                                            onClick={() => handleDecodificaIndirizzo(watch('civico'), watch('via'), watch('cap'), watch('citta'), watch('paese'))}
                                            className="w-full p-2 border border-accent dark:border-dark-accent flex items-center justify-center gap-2 text-accent rounded-sm cursor-pointer hover:text-white hover:bg-accent dark:hover:bg-dark-accent">
                                            <span>{t('ModuloBusinessUnit.FormBusinessUnit.geolocalizza')}</span> <FaMap />
                                        </div>
                                }
                            </>
                        }

                        {indirizzoCercato && !gettingDecodificaIndirizzo &&
                            <div className="flex flex-col gap-2 mt-2">
                                {indirizziPossibili && indirizziPossibili.length >= 0 ?
                                    indirizziPossibili.map((indirizzo: any, i: number) => {
                                        return (
                                            <div
                                                className={
                                                    `p-2 rounded-sm w-full cursor-pointer border border-lightgray-dd hover:border-accent
                                                    ${indirizzo && indirizzoAttivo && indirizzo.id === indirizzoAttivo.id && 'bg-accent dark:bg-dark-accent text-white'}
                                                `}
                                                onClick={() => handleSetIndirizzoAttivo(indirizzo)}
                                                key={i}
                                            >
                                                {indirizzo.place_name}
                                                <div className="mt-2 flex gap-2">
                                                    <p className="text-xs">{t('ModuloBusinessUnit.FormBusinessUnit.long')} {indirizzo.geometry.coordinates[0]}</p>
                                                    <p className="text-xs">{t('ModuloBusinessUnit.FormBusinessUnit.lat')} {indirizzo.geometry.coordinates[1]}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <>
                                        {
                                            gettingDecodificaIndirizzo ?
                                                <Spinner colore="blue" />
                                                :
                                                <>
                                                    {!decodificaIndirizzoError &&
                                                        <div>{t('ModuloBusinessUnit.FormBusinessUnit.nessunoIndrizoTrovato')}</div>
                                                    }
                                                </>
                                        }
                                    </>
                                }
                            </div>
                        }

                        {decodificaIndirizzoError &&
                            <div className="my-1">
                                <ErrorBox errore={decodificaIndirizzoError} />
                            </div>
                        }
                    </div>
                }

                {
                    !mostraIndirizzo &&
                    <div
                        className="bg-white text-accent w-full p-2 mb-2 rounded-sm text-center cursor-pointer flex items-center justify-center"
                        onClick={() => setMostraIndirizzo(!mostraIndirizzo)}>
                        {mostraIndirizzo ? t('ModuloBusinessUnit.FormBusinessUnit.anullaIndrizzo') :
                            <span className="flex items-center gap-2">
                                {t('ModuloBusinessUnit.FormBusinessUnit.aggiungiIndrizzo')}
                                <FaPlus />
                            </span>
                        }
                    </div>
                }

                {/* Submit modifica business unit */}
                {businessUnit &&

                    <>
                        <div className="form">
                            <div>
                                {!business_units.editingBusinessUnit ? <input type="submit" value={t('common.salvaModifica')} /> : <SpinnerButton />}
                            </div>
                        </div>

                        {
                            isSubmitSuccessful && !business_units.editingBusinessUnit && (

                                business_units.modificaBusinessUnitError
                                    ?
                                    <div className="my-2">
                                        <ErrorBox errore={business_units.modificaBusinessUnitError} />
                                    </div>
                                    :
                                    <div className="my-2">
                                        <InfoBox messaggio={t('common.modificaSalvato')} />
                                    </div>

                            )
                        }

                    </>
                }

                {/* Submit creazione business unit */}
                {!businessUnit &&

                    <>
                        {
                            (isSubmitSuccessful && !business_units.creaBusinessUnitError && !business_units.creatingBusinessUnit) ?
                                <InfoBox messaggio={t('ModuloBusinessUnit.FormBusinessUnit.businessunitCreatoSucesso')} />
                                :
                                <div className="form pt-4">
                                    {!business_units.creatingBusinessUnit ? <input type="submit" value={t('ModuloBusinessUnit.FormBusinessUnit.creaBusinessUnitButtone')} /> : <SpinnerButton />}
                                </div>
                        }

                        {
                            business_units.creaBusinessUnitError &&
                            <div className="mb-2">
                                <ErrorBox errore={business_units.creaBusinessUnitError.toString()} />
                            </div>
                        }

                    </>
                }

            </form>
        </div>
    )
})

export default BusinessUnitsForm;
