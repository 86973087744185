import React from 'react'
import { AggiornamentoContattiForm } from '../components/auth/AggiornamentoContattiForm'
import { useTranslation } from 'react-i18next'
export default function CompletaAccount() {
  const { t } = useTranslation();
  return (
    <div className="w-full">

      <h1 className="text-4xl text-text">{t('paginaAggiornaContatti.titolo')}</h1>
      <p className="text-text-light mt-3 mb-5">
      {t('paginaAggiornaContatti.sottotitolo')}
      </p>

      <AggiornamentoContattiForm />

    </div>
  )
}