import { runInAction } from 'mobx'
import { useEffect } from 'react'
import { useStores } from './useStores'

export default function useUiResize() {

  const { ui } = useStores()

  //Triggera al resize
  window.addEventListener('resize', (e) => {
    resettaUiResponsive();
  })

  //Triggera on mount
  useEffect(() => {
    resettaUiResponsive();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resettaUiResponsive = () => {
    //Imposta la larghezza intera, se disattivata, quando le dimensioni sono inferiori a 768 
    if (window.innerWidth < 768 && !ui.mainNavFull) {
      runInAction(() => ui.mainNavFull = true)
    }

    //Rimuove lo stato di mobile, se attivo, ogni volta che le dimensioni sono superiori a 768
    if (window.innerWidth > 768 && ui.mainNavMobile) {
      runInAction(() => ui.mainNavMobile = false)
    }
  }

}