import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../../hooks/useStores'
import { useModuloESGStores } from '../context/useModuloESGStores'
import { IFattoreESG } from '../types'
import { observer } from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../../hooks/useOttieniPrivilegioModulo'

//Components
import { FaInfoCircle } from 'react-icons/fa'
import AggiungiButton from '../../../components/common/AggiungiButton'
import FattoreESGTile from '../components/fattoriESG/StandardESGTile'
import FattoreESGForm from '../components/fattoriESG/FattoreESGForm'

const ArchivioFattoriESG = observer(() => {

    const privilegio = useOttieniPrivilegioModulo();
    const { fattori_esg } = useModuloESGStores();
    const { t } = useTranslation()

    const { ui } = useStores();

    useEffect(() => {

        fattori_esg.getStandardESG()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        fattori_esg.fattoreESGCreato,
        fattori_esg.fattoreESGEliminato,
        fattori_esg.fattoreESGModificato,
    ])


    return (
        <div>
            <div className="mt-2">
                {
                    fattori_esg.standardESG && fattori_esg.standardESG.length > 0 ?
                        <div className="flex flex-col gap-2">
                            {fattori_esg.standardESG && fattori_esg.standardESG.map((fattore: IFattoreESG) => (
                                <FattoreESGTile fattore={fattore} key={fattore.codice} />
                            )
                            )}
                        </div>
                        :
                        <h3 className="flex items-center justify-center gap-2 text-2xl my-8 text-text-light">
                            <FaInfoCircle /> {t('ModuloFattoriEsg.ArchivioStandardEsg.nessunStandardPresente')}
                        </h3>
                }
            </div>

            {privilegio >= 2 &&
                <div className="w-full mt-2" onClick={() => ui.mountAsideModal(<FattoreESGForm tipo_fattore="standard" />)} >
                    <AggiungiButton testo={t('ModuloFattoriEsg.ArchivioStandardEsg.creaNuovoStandard')} />
                </div>
            }
        </div>
    )
})

export default ArchivioFattoriESG;