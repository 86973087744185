import React from 'react'
import { Link } from 'react-router-dom'
import { FaAngleLeft } from 'react-icons/fa'

export default function TerminiCondizioni() {
  return (
    <div className="termini-condizioni">
      <h1 className="text-xl font-bold">Informativa dati e termini d'uso di Ipè</h1>
      <p>Ipè raccoglie alcuni Dati Personali dei propri Utenti.</p>

      <h3 className="text-lg font-bold">Titolare del Trattamento dei Dati</h3>
      <p>
        <b>IMQ eAmbiente s.r.l.</b> <br />

        VEGA Parco Scientifico e Tecnologico <br />
        Torre Hammon <br />
        Via delle Industrie, 5 Marghera (Venezia)
      </p>

      <p>
        <b>Indirizzo email del Titolare</b>: contabilita@eambientegroup.com
      </p>

      <h3 className="text-lg font-bold">Tipologie di dati raccolti</h3>

      <p>
        Fra i dati personali raccolti da Ipè, in modo esclusivamente autonomo senza software di terze parti, ci sono i cookie tecnici, i dati personali inseriti dagli Utenti e lo storico delle attività degli Utenti durante l'utilizzo di Ipè.
      </p>

      <p>Dettagli completi su ciascuna tipologia di dati raccolti sono forniti nelle sezioni dedicate di questa privacy policy o mediante specifici testi informativi visualizzati prima della raccolta dei dati stessi.</p>
      <p>I Dati personali possono essere liberamente forniti dall'Utente o, nel caso di dati di, raccolti automaticamente durante l'uso di Ipè.</p>
      <p>Se non diversamente specificato, tutti i dati richiesti da Ipè sono obbligatori. Se l’Utente rifiuta di comunicarli, potrebbe essere impossibile per IMQ eAmbiente fornire il servizio software Ipè. Nei casi in cui su Ipè alcuni dati siano indicati come facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali dati, senza che ciò abbia alcuna conseguenza sulla disponibilità del servizio o sulla sua operatività.</p>
      <p>Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori, sono incoraggiati a contattare il Titolare.
        <br />
        L’eventuale utilizzo di Cookie - o di altri strumenti di tracciamento - da parte di Ipè, ha la finalità di fornire il Servizio richiesto dall'Utente, oltre alle ulteriori finalità descritte nel presente documento.
      </p>

      <p>
        L'Utente si assume la responsabilità dei Dati Personali di terzi ottenuti, pubblicati o condivisi mediante Ipè e garantisce di avere il diritto di comunicarli o diffonderli, liberando il Titolare da qualsiasi responsabilità verso terzi.
      </p>


      <h3 className="text-lg font-bold">Modalità e luogo del trattamento dei Dati raccolti</h3>
      <br />
      <h6 className="font-bold">Modalità di trattamento:</h6>
      <p>
        Il Titolare adotta le opportune misure di sicurezza volte ad impedire l’accesso, la divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali.
        Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate. Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti in IMQ eAmbiente (personale amministrativo, commerciale, legali, amministratori di sistema) e soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del Trattamento da parte del Titolare.
        <br />
        L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del Trattamento
      </p>

      <h6 className="font-bold">Base giuridica del trattamento:</h6>
      <p>Il Titolare tratta Dati Personali relativi all’Utente in caso sussista una delle seguenti condizioni:</p>
      <p>L’Utente ha prestato il consenso per una o più finalità specifiche; Nota: in alcuni ordinamenti il Titolare può essere autorizzato a trattare Dati Personali senza che debba sussistere il consenso dell’Utente o un’altra delle basi giuridiche specificate di seguito, fino a quando l’Utente non si opponga (“opt-out”) a tale trattamento. Ciò non è tuttavia applicabile qualora il trattamento di Dati Personali sia regolato dalla legislazione europea in materia di protezione dei Dati Personali;</p>
      <p>Il trattamento è necessario all'esecuzione di un contratto con l’Utente e/o all'esecuzione di misure precontrattuali;</p>
      <p>Il trattamento è necessario per adempiere un obbligo legale al quale è soggetto il Titolare;</p>
      <p>Il trattamento è necessario per l'esecuzione di un compito di interesse pubblico o per l'esercizio di pubblici poteri di cui è investito il Titolare;</p>
      <p>Il trattamento è necessario per il perseguimento del legittimo interesse del Titolare o di terzi.</p>
      <p>È comunque sempre possibile richiedere al Titolare di chiarire la concreta base giuridica di ciascun trattamento ed in particolare di specificare se il trattamento sia basato sulla legge, previsto da un contratto o necessario per concludere un contratto.</p>

      <h6 className="font-bold">Luogo:</h6>
      <p>I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate. Per ulteriori informazioni, contatta il Titolare.</p>
      <p>I Dati Personali dell’Utente potrebbero essere trasferiti in un paese diverso da quello in cui l’Utente si trova.</p>
      <p>L’Utente ha diritto a ottenere informazioni in merito alla base giuridica del trasferimento di Dati al di fuori dell’Unione Europea o ad un’organizzazione internazionale di diritto internazionale pubblico o costituita da due o più paesi, come ad esempio l’ONU, nonché in merito alle misure di sicurezza adottate dal Titolare per proteggere i Dati.</p>

      <h6 className="font-bold">Periodo di conservazione:</h6>
      <p>I Dati sono trattati e conservati per il tempo richiesto dalle finalità per le quali sono stati raccolti.</p>

      <p>Pertanto:</p>
      <p>I Dati Personali raccolti per scopi collegati all’esecuzione di un contratto tra il Titolare e l’Utente saranno trattenuti sino a quando sia completata l’esecuzione di tale contratto.</p>
      <p>I Dati Personali raccolti per finalità riconducibili all’interesse legittimo del Titolare saranno trattenuti sino al soddisfacimento di tale interesse. L’Utente può ottenere ulteriori informazioni in merito all’interesse legittimo perseguito dal Titolare nelle relative sezioni di questo documento o contattando il Titolare.</p>

      <p>Quando il trattamento è basato sul consenso dell’Utente, il Titolare può conservare i Dati Personali più a lungo sino a quando detto consenso non venga revocato. Inoltre, il Titolare potrebbe essere obbligato a conservare i Dati Personali per un periodo più lungo in ottemperanza ad un obbligo di legge o per ordine di un’autorità.</p>

      <p>Al termine del periodo di conservazione i Dati Personali saranno cancellati. Pertanto, allo spirare di tale termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilità dei Dati non potranno più essere esercitati.</p>

      <h3 className="text-lg font-bold">Finalità del Trattamento dei Dati raccolti</h3>
      <p>I Dati dell’Utente sono raccolti per consentire al Titolare di fornire il Servizio, adempiere agli obblighi di legge, rispondere a richieste o azioni esecutive, tutelare i propri diritti ed interessi (o quelli di Utenti o di terze parti), individuare eventuali attività dolose o fraudolente, nonché per le seguenti finalità: Statistica.</p>
      <p>Per ottenere informazioni dettagliate sulle finalità del trattamento e sui Dati Personali trattati per ciascuna finalità, l’Utente può fare riferimento alla sezione “Dettagli sul trattamento dei Dati Personali”.</p>
      
      <h3 className="text-lg font-bold">Diritti dell’Utente</h3>
      <p>Gli Utenti possono esercitare determinati diritti con riferimento ai Dati trattati dal Titolare.</p>
      <p>In particolare, l’Utente ha il diritto di:</p>

      <ul>
        <li><b>Revocare il consenso in ogni momento.</b> L’Utente può revocare il consenso al trattamento dei propri Dati Personali precedentemente espresso.</li>
        <li><b>Opporsi al trattamento dei propri Dati.</b> L’Utente può opporsi al trattamento dei propri Dati quando esso avviene su una base giuridica diversa dal consenso. Ulteriori dettagli sul diritto di opposizione sono indicati nella sezione sottostante.</li>
        <li><b>Accedere ai propri Dati.</b> L’Utente ha diritto ad ottenere informazioni sui Dati trattati dal Titolare, su determinati aspetti del trattamento ed a ricevere una copia dei Dati trattati.</li>
        <li><b>Verificare e chiedere la rettificazione.</b> L’Utente può verificare la correttezza dei propri Dati e richiederne l’aggiornamento o la correzione.</li>
        <li><b>Ottenere la limitazione del trattamento.</b> Quando ricorrono determinate condizioni, l’Utente può richiedere la limitazione del trattamento dei propri Dati. In tal caso il Titolare non tratterà i Dati per alcun altro scopo se non la loro conservazione.</li>
        <li><b>Ottenere la cancellazione o rimozione dei propri Dati Personali.</b> Quando ricorrono determinate condizioni, l’Utente può richiedere la cancellazione dei propri Dati da parte del Titolare.</li>
        <li><b>Ricevere i propri Dati o farli trasferire ad altro titolare.</b> L’Utente ha diritto di ricevere i propri Dati in formato strutturato, di uso comune e leggibile da dispositivo automatico e, ove tecnicamente fattibile, di ottenerne il trasferimento senza ostacoli ad un altro titolare. Questa disposizione è applicabile quando i Dati sono trattati con strumenti automatizzati ed il trattamento è basato sul consenso dell’Utente, su un contratto di cui l’Utente è parte o su misure contrattuali ad esso connesse.</li>
        <li><b>Proporre reclamo.</b> L’Utente può proporre un reclamo all’autorità di controllo della protezione dei dati personali competente o agire in sede giudiziale.</li>
      </ul>
      <br />
      <h6 className="font-bold">Dettagli sul diritto di opposizione:</h6>
      <p>Quando i Dati Personali sono trattati nell’interesse pubblico, nell’esercizio di pubblici poteri di cui è investito il Titolare oppure per perseguire un interesse legittimo del Titolare, gli Utenti hanno diritto ad opporsi al trattamento per motivi connessi alla loro situazione particolare.</p>
      <p>Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalità di marketing diretto, possono opporsi al trattamento senza fornire alcuna motivazione. Per scoprire se il Titolare tratti dati con finalità di marketing diretto gli Utenti possono fare riferimento alle rispettive sezioni di questo documento.</p>

      <h6 className="font-bold">Come esercitare i diritti:</h6>
      <p>Per esercitare i diritti dell’Utente, gli Utenti possono indirizzare una richiesta agli estremi di contatto del Titolare indicati in questo documento. Le richieste sono depositate a titolo gratuito e evase dal Titolare nel più breve tempo possibile, in ogni caso entro un mese.</p>

      <h3 className="text-lg font-bold">Ulteriori informazioni sul trattamento</h3>
      
      <br />
      <h6 className="font-bold">Difesa in giudizio</h6>
      <p>I Dati Personali dell’Utente possono essere utilizzati da parte del Titolare in giudizio o nelle fasi preparatorie alla sua eventuale instaurazione per la difesa da abusi nell'utilizzo di Ipè o dei Servizi connessi da parte dell’Utente.</p>
      <p>L’Utente dichiara di essere consapevole che il Titolare potrebbe essere obbligato a rivelare i Dati per ordine delle autorità pubbliche.</p>
      
      <br />
      <h6 className="font-bold">Informative specifiche</h6>
      <p>Su richiesta dell’Utente, in aggiunta alle informazioni contenute in questa privacy policy, Ipè potrebbe fornire all'Utente delle informative aggiuntive e contestuali riguardanti Servizi specifici, o la raccolta ed il trattamento di Dati Personali.</p>

      <h6 className="font-bold">Log di sistema e manutenzione</h6>
      <p>Per necessità legate al funzionamento ed alla manutenzione, Ipè e gli eventuali servizi terzi da essa utilizzati potrebbero raccogliere log di sistema, ossia file che registrano le interazioni e che possono contenere anche Dati Personali, quali l’indirizzo IP Utente.</p>

      <h6 className="font-bold">Informazioni non contenute in questa policy</h6>
      <p>Ulteriori informazioni in relazione al trattamento dei Dati Personali potranno essere richieste in qualsiasi momento al Titolare del Trattamento utilizzando gli estremi di contatto.</p>

      <h6 className="font-bold">Risposta alle richieste “Do Not Track”</h6>
      <p>Ipè non supporta le richieste “Do Not Track”.</p>
      <p>Per scoprire se gli eventuali servizi di terze parti utilizzati le supportino, l'Utente è invitato a consultare le rispettive privacy policy.</p>

      <h6 className="font-bold">Modifiche a questa privacy policy</h6>
      <p>Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy in qualunque momento notificandolo agli Utenti su questa pagina e, se possibile, su Ipè nonché, qualora tecnicamente e legalmente fattibile, inviando una notifica agli Utenti attraverso uno degli estremi di contatto di cui è in possesso. Si prega dunque di consultare con frequenza questa pagina, facendo riferimento alla data di ultima modifica indicata in fondo.</p>
      <p>Qualora le modifiche interessino trattamenti la cui base giuridica è il consenso, il Titolare provvederà a raccogliere nuovamente il consenso dell’Utente, se necessario.</p>
      
      <Link to="/" className="block mt-4 flex items-center gap-1">
        <FaAngleLeft />
        <span>Torna al login</span>
      </Link>
    </div>
  )
}
