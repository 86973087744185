import React from 'react'
import { IIndicatore } from '../../types'


const IndicatoreTestualeFattoreBilancio: React.FC<{ indicatore: IIndicatore }> = ({ indicatore }) => {
    return (
        <div className="bg-white dark:bg-darkgray-dd p-4 shadow-xl rounded-md">
            <span className="text-xs font-bold text-accent dark:text-dark-accent mb-1">{indicatore.bilancio_anno}</span>
            <h2 className="font-bold text-lg">{indicatore.valore_testuale}</h2>
            <p>{indicatore.note}</p>
        </div>
    )
}

export default IndicatoreTestualeFattoreBilancio;