import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useForm } from 'react-hook-form'
import { useStores } from '../../hooks/useStores'
import { IPrivilegio } from '../../types'
import { observer } from 'mobx-react-lite'

//Components
import InfoBox from '../../components/common/InfoBox'
import ErrorBox from '../../components/common/ErrorBox'
import SpinnerButton from '../../components/common/SpinnerButton'
import InputField from '../../components/common/form/InputField'

const PrivilegiForm: React.FC<{ privilegio?: IPrivilegio }> = observer(({ privilegio }) => {
  const { t } = useTranslation();

  const { gestionePrivilegi } = useStores()

  //Istanzia Hook Form
  const { watch, register, setValue, handleSubmit, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      valore: privilegio ? privilegio.valore : '',
      valore_originale: privilegio ? privilegio.valore : '',
      nome: privilegio ? privilegio.nome : ''
    }
  })

  //Gestisce submit
  const onSubmit = () => {
    privilegio ?

      gestionePrivilegi.modificaPrivilegio(watch() as IPrivilegio).then(() => {
        setValue('valore_originale', watch('valore') as string)
      })
      :

      gestionePrivilegi.creaPrivilegio(watch() as IPrivilegio)

  }


  return (
    <div>

      <div className="mb-4">
        <h2 className="text-2xl mb-2">
          {privilegio ?
            `${t('ModuloGestionePrivilegi.Form.modificaPrivilegi')} ${privilegio.nome}`
            :
            t('ModuloGestionePrivilegi.Form.creaPrivilegi')
          }
        </h2>
        <p className="text-text-light">
          {privilegio ?
            `${t('ModuloGestionePrivilegi.Form.modificaTesto')} ${privilegio.nome}, ${t('ModuloGestionePrivilegi.Form.modificaTestoCompleto')} `
            :
            t('ModuloGestionePrivilegi.Form.creaPrivilegi')
          }
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>

        <InputField
          label={t('ModuloGestionePrivilegi.Form.Priorita')}
          classi="valore"
          placeholder={t('ModuloGestionePrivilegi.Form.placeholderPriorita')}
          type="number"
          error={errors.valore}
          form={register("valore", { required: t('common.fornisciValore'), min: { value: 1, message: t('common.almenoUno') } })}
        />

        <InputField
          label={t('common.nome')}
          classi="nome"
          placeholder={t('ModuloGestionePrivilegi.Form.placeholderNome')}
          type="text"
          error={errors.nome}
          form={register("nome", { required: t('common.fornisciNome') })}
        />

        <div className="form">

          {/* Submit modifica privilegio */}
          {privilegio &&

            <>
              <div className="form">
                <div>
                  {!gestionePrivilegi.editingPrivilegio ? <input type="submit" value={t('common.salvaModifica')} /> : <SpinnerButton />}
                </div>
              </div>

              {
                isSubmitSuccessful && !gestionePrivilegi.editingPrivilegio && (

                  gestionePrivilegi.modificaPrivilegioError
                    ?
                    <div className="my-2">
                      <ErrorBox errore={gestionePrivilegi.modificaPrivilegioError} />
                    </div>
                    :
                    <div className="my-2">
                      <InfoBox messaggio={t('common.modificaSalvato')} />
                    </div>

                )
              }

            </>
          }

          {/* Submit creazione privilegio */}
          {!privilegio &&

            <>
              {
                (isSubmitSuccessful && !gestionePrivilegi.creatingPrivilegioError && !gestionePrivilegi.creatingPrivilegio) ?
                  <InfoBox messaggio={t('ModuloGestionePrivilegi.Form.messaggiPrivilegioCreatoSucesso')}id={"crea-privilegio-success"} />
                  :
                  <div className="form pt-4">
                    {!gestionePrivilegi.creatingPrivilegio ? <input type="submit" value={t('ModuloGestionePrivilegi.Form.privilegiCrea')} id="crea-privilegio-submit" /> : <SpinnerButton />}
                  </div>
              }

              {
                gestionePrivilegi.creatingPrivilegioError &&
                <div className="mb-2">
                  <ErrorBox errore={gestionePrivilegi.creatingPrivilegioError.toString()} />
                </div>
              }

            </>
          }


        </div>

      </form>
    </div>
  )
})

export default PrivilegiForm;