import axios from 'axios'
import { config } from '../../../../../config'

//Riceve lista bilanci
export const getBilanciRendicontazione = () => {
    return axios.get(`${config.API_URI}lista_bilanci_utente_business_unit_fattori`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceve singolo bilanci
export const getBilancioRendicontazione = (bilancio_anno: string) => {
    return axios.get(`${config.API_URI}lista_business_unit_utente_per_bilancio/${bilancio_anno}`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceve singola business unit nel bilancio
export const getBilancioBusinessUnitRendicontazione = (bilancio_anno: string, business_unit_nome: string) => {
    return axios.get(`${config.API_URI}lista_fattori_per_business_unit_utente_bilancio/${bilancio_anno}/${business_unit_nome}`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}