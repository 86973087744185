import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../../../../hooks/useStores'
import { useModuloESGStores } from '../../../context/useModuloESGStores'
import { IFattoreESG } from '../../../types'
import { getStandardESG } from '../../../rest/fattori_esg'
import { collegaFattore } from '../../../rest/bilanci'

//Components
import { FaPlus } from 'react-icons/fa'
import Dialogue from '../../../../../components/common/Dialogue'
import BottoneCanvasGenericoAzione from '../common/BottoneCanvasGenericoAzione'
import BottoneCanvasGenerico from '../common/BottoneCanvasGenerico'
import Spinner from '../../../../../components/common/Spinner'

const BottoneAttribuisciStandard: React.FC<{ testo?: string }> = ({ testo }) => {
    
    const { ui } = useStores()
    const { t } = useTranslation();
    const { bilanci } = useModuloESGStores()
    
    //Stabilisce se mostrare la lista di standard attribuibili
    const [scegliStandard, setScegliStandard] = useState<boolean>(false)

    //Lista di tutti i fattori senza parent
    const [gettingListaStandard, setGettingListaStandard] = useState<boolean>(false)
    const [listaStandard, setlistaStandard] = useState<IFattoreESG[]>([])

    //Lista di tutti gli standard già attribuiti
    const [codiciStandardAttribuiti, setCodiciStandardAttribuiti] = useState<string[]>([])

    useEffect(() => {

        setCodiciStandardAttribuiti([])

        bilanci.bilancioAttivo?.fattori_esg.forEach((fattore) => {
            if (fattore.tipo === 'standard') {
                setCodiciStandardAttribuiti(codiciStandardAttribuiti => [...codiciStandardAttribuiti, fattore.codice])
            }
        })

    }, [bilanci.bilancioAttivo])


    useEffect(() => {

    setGettingListaStandard(true)

        getStandardESG().then((res) => {
            setlistaStandard(res.data.data)
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setGettingListaStandard(false)
        })

    }, [])

    const handleScegliStandard = () => {
        if (!scegliStandard) {
            setScegliStandard(true)
        }
    }


    //Attribuisce uno standard al bilancio
    const handleAttribuisciStandard = (fattore_codice: string) => {

        collegaFattore(bilanci.bilancioAttivo?.anno as number, fattore_codice as string).then(() => {

            ui.mountDialogue(<Dialogue testo={t('ModuloFattoriEsg.BottoneAttribuisciStandard.standardAttribuito')} />)

        }).catch(() => {

            ui.mountDialogue(<Dialogue testo={t('ModuloFattoriEsg.BottoneAttribuisciStandard.verficatoUnErrore')} />)

        }).finally(() => {

            bilanci.getBilancio(bilanci.bilancioAttivo?.anno as number)

        })

    }

    return (
        <div className="w-[250px]" onClick={() => handleScegliStandard()}>
            {
                scegliStandard ?
                    <BottoneCanvasGenerico effettoHover={false}>
                                <div className="flex flex-col gap-2 items-center justify-center w-full">
                                    {gettingListaStandard ?
                                        <Spinner colore="#002e5a" />
                                        :
                                        <>
                                            <p>{t('ModuloFattoriEsg.BottoneAttribuisciStandard.scegliUnStandard')}:</p>
                                            {
                                                listaStandard && listaStandard.length > 0 ?
                                                    listaStandard
                                                    .filter(fattore => !codiciStandardAttribuiti.includes(fattore.codice))
                                                    .map((fattore: IFattoreESG) => (
                                                        <div className="w-full" key={fattore.codice} onClick={() => ui.mountDialogue(
                                                            <Dialogue
                                                                testo={`${t('ModuloFattoriEsg.BottoneAttribuisciStandard.proseguandoAttribuirai')} ${fattore.nome} ${t('ModuloFattoriEsg.BottoneAttribuisciStandard.bilancioDel')} ${bilanci.bilancioAttivo?.anno}`}
                                                                primaryCta={t('common.conferma')}
                                                                secondaryCtaColor="bg-red"
                                                                onConfirm={() => handleAttribuisciStandard(fattore.codice)}
                                                            />)
                                                        }>
                                                            <BottoneCanvasGenericoAzione tipo="azione" testo={fattore.nome} icona="FaPlus" />
                                                        </div>
                                                    ))
                                                    :
                                                    <p>{t('ModuloFattoriEsg.BottoneAttribuisciStandard.nessunStandardTrovato')}</p>
                                            }

                                            <div className="w-full" onClick={() => setScegliStandard(false)}>
                                                <BottoneCanvasGenericoAzione tipo="annulla" testo={t('common.annulla')} />
                                            </div>
                                        </>
                                    }
                                </div>
                    </BottoneCanvasGenerico>
                    :
                    <BottoneCanvasGenerico>
                            {testo && <span className="mr-2">{testo}</span>} <FaPlus />
                    </BottoneCanvasGenerico>
            }

        </div>
    )
}

export default BottoneAttribuisciStandard;
