import React, { useState, useEffect, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useForm, Controller } from 'react-hook-form'
import { useStores } from '../../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import { IGruppo, ILingua } from '../../../types'
import { MultiSelect } from "react-multi-select-component"
import { getUtenti } from '../rest/gestioneUtenti'
import { getUtentiGruppo } from '../rest/gestioneGruppi'
import { IUtenteForm } from '../../../types'
import { config } from '../../../config'

//Components
import TestoErrore from '../../../components/common/TestoErrore'
import ErrorBox from '../../../components/common/ErrorBox'
import InfoBox from '../../../components/common/InfoBox'
import SpinnerButton from '../../../components/common/SpinnerButton'
import InputField from '../../../components/common/form/InputField'
import { getLinguaLista } from '../../gestioneLingue/rest/gestioneLingue'

const ModificaGruppoForm: React.FC<{ gruppo: IGruppo }> = observer(({ gruppo }) => {
  const { t } = useTranslation();

  const { gestioneUtenti ,auth} = useStores();
  const [linguaAttiva, setLinguaAttiva] = useState<ILingua>();
  const [listaLingue, setListaLingue] = useState<ILingua[]>();
  
  useEffect(() => {
    getLinguaLista().then((res) => {
      setListaLingue(res.data.data);
    });
  }, []);
  useEffect(() => {
    if (auth.utente && listaLingue && listaLingue.length > 0) {
      setLinguaAttiva(
        listaLingue.find(
          (lingua: ILingua) => lingua.codice === auth.utente?.lingua
        )
      );
    }
  }, [listaLingue]);
  const [visibilitaListaLingue, setVisibilitaListaLingue] =
  useState<boolean>(false);
  //Istanzia Hook Form
  const { watch, register, handleSubmit, control, setValue, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      'codice_originale': gruppo.codice,
      'codice': gruppo.codice,
      'nome': gruppo.nome,
      'descrizione': gruppo.descrizione,
      'utenti': []
    }
  });
  const handleLinguaAttiva = (lingua: ILingua) => {
    setLinguaAttiva(lingua);
    setVisibilitaListaLingue(false);
  };
  //Gestisce submit
  const onSubmit = () => {
    gestioneUtenti.modificaGruppo(watch() as IGruppo).then(() => {
      setValue('codice_originale', watch('codice'))
    })
  }

  //Lista di tutti gli utenti
  const [utenti, setUtenti] = useState<any>([])

  //Lista degli utenti pre-selezionati
  const [utentiDefault, setUtentiDefault] = useState<any>([])

  //Lista degli utenti selezionati nel MultiSelect
  const [utentiSelezionati, setUtentiSelezionati] = useState([]);

  useEffect(() => {

    //Ottiene lista di tutti gli utenti
    getUtenti().then(res => {
      setUtenti(res.data.data)
    })

    //Ottiene lista degli utenti già esistenti nel gruppo
    getUtentiGruppo(gruppo.codice).then(res => {
      setUtentiDefault(res.data.data)
    })

  }, [gruppo.codice])


  //Presetta gli utenti già esistenti nel gruppo
  useEffect(() => {

    //Setta MultiSelect
    setUtentiSelezionati(utentiDefault)

    //Setta Hook Form
    setValue('utenti', utentiDefault)

  }, [utentiDefault, setValue])


  //Ristruttura dati per label MultiSelect
  utenti.forEach((utente: IUtenteForm) => {
    utente.value = utente.id
    utente.label = `${utente.nome} ${utente.cognome}`
  })

  utentiDefault.forEach((utente: IUtenteForm) => {
    utente.value = utente.id
    utente.label = `${utente.nome} ${utente.cognome}`
  })

  return (
    <div>
      <div className="mb-4">
        <h2 className="text-2xl mb-2">{t('ModuloGestioneUtenti.ModificaGruppo.testoModifica')} {gruppo.nome}</h2>
        <p className="text-text-light">{t('ModuloGestioneUtenti.FormAggiungiUtentiGruppo.testoAggiungiUtenti')}</p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>

        <input type="hidden" {...register('utenti')} />
        <div className="form">
          <label htmlFor="">{t('common.nome')}</label>
          <input autoFocus {...register("nome", { required: t('common.fornisciNome') })} placeholder={t('common.nome')} type="text" />

          {errors.nome && <TestoErrore errore={errors.nome.message} />}
        </div>

        <div className="form">
          <label htmlFor="">{t('common.descrizione')}</label>
          <input {...register("descrizione", { required: t('common.fornisciDescrizione') })} placeholder={t('common.descrizione')} type="text" />

          {errors.descrizione && <TestoErrore errore={errors.descrizione.message} />}
        </div>

        <div className="form">
          <label htmlFor="">{t('ModuloGestioneUtenti.FormAggiungiUtentiGruppo.utenti')}</label>
          <Controller
            control={control}
            name="utenti"
            render={({
              field: { onChange },
            }) => (
              <MultiSelect
                className="dark:text-black"
                options={utenti}
                value={utentiSelezionati}
                onChange={(e: SetStateAction<never[]>) => { setUtentiSelezionati(e); onChange(e) }}
                labelledBy={t('ModuloGestioneUtenti.FormAggiungiUtentiGruppo.selezioneUtenti')}
                overrideStrings={{
                  selectSomeItems:t('ModuloGestioneUtenti.FormAggiungiUtentiGruppo.selezioneUtenti'),
                  allItemsAreSelected: t('ModuloGestioneUtenti.FormAggiungiUtentiGruppo.tuttiSelezionati'),
                  selectAll: t('ModuloGestioneUtenti.FormAggiungiUtentiGruppo.selezioneTutti'),
                  search: t('common.cerca'),
                }}
              />
            )}
          />
        </div>

        <div className="form">
          <div>
            {!gestioneUtenti.editingGruppo ? <input type="submit" value={t('common.salvaModifica')} /> : <SpinnerButton />}
          </div>
        </div>

        {
          isSubmitSuccessful && !gestioneUtenti.editingGruppo && (

            gestioneUtenti.modificaGruppoError
              ?
              <div className="my-2">
                <ErrorBox errore={gestioneUtenti.modificaGruppoError} />
              </div>
              :
              <div className="my-2">
                <InfoBox messaggio={t('common.modificaSalvato')} />
              </div>

          )
        }

      </form>
    </div>
  )
})

export default ModificaGruppoForm;