import { IAttivita } from '../../../types';

import { creaStringStandardPerAttivita } from '../../../hooks/attivita/creaStringStandardPerAttivita';

export default function factoryAttivitaBusinessUnitCategorie(attivita: IAttivita) {

    let codiceInterpretato = '';

    switch (attivita.route) {

        case 'BusinessUnitCategorie.crea_business_unit_categoria':
            attivita.user_name ?
                codiceInterpretato = `${attivita.user_name} ha <span class="azione bg-accent dark:bg-dark-accent">creato</span> la categoria di business unit ${JSON.parse(attivita.request_corpo)?.nome}`
                :
                codiceInterpretato = `Hai <span class="azione bg-accent dark:bg-dark-accent">creato</span> la categoria di business unit ${JSON.parse(attivita.request_corpo)?.nome}`
            break;

        case 'BusinessUnitCategorie.modifica_business_unit_categoria':
            attivita.user_name ?
                codiceInterpretato = `${attivita.user_name} ha <span class="azione bg-orange dark:bg-dark-orange">modificato</span> la categoria di business unit ${JSON.parse(attivita.request_corpo)?.nome}`
                :
                codiceInterpretato = `Hai <span class="azione bg-orange dark:bg-dark-orange">modificato</span> la categoria di business unit ${JSON.parse(attivita.request_corpo)?.nome}`
            break;

        case 'BusinessUnitCategorie.elimina_business_unit_categoria':
            attivita.user_name ?
                codiceInterpretato = `${attivita.user_name} ha <span class="azione bg-red dark:bg-dark-red">eliminato</span> la categoria di business unit ${JSON.parse(attivita.request_corpo)?.nome}`
                :
                codiceInterpretato = `Hai <span class="azione bg-red dark:bg-dark-red">eliminato</span> la categoria di business unit ${JSON.parse(attivita.request_corpo)?.nome}`
            break;

        default:
            codiceInterpretato = creaStringStandardPerAttivita(attivita)
    }

    return codiceInterpretato;

}