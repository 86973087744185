import React, { useEffect } from 'react'

//Data
import { useModuloESGStores } from '../../context/useModuloESGStores'
import { observer } from 'mobx-react-lite'

//Components
import { UseModuloBusinessUnitsStore } from '../../../UiModuloBusinessUnit/context/UseModuloBusinessUnitsStore'
import { runInAction } from 'mobx'
import CanvasBusinessUnitsBilancio from '../../components/CanvasBusinessUnitsBilancio/CanvasBusinessUnitsBilancio'

const SchedaBilancioBusinessUnits = observer(() => {

  const { bilanci } = useModuloESGStores()
  const { canvas_business_units } = UseModuloBusinessUnitsStore()

  useEffect(() => {
    runInAction(() => canvas_business_units.modalitaVista = 'minificata')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div>
        <p className="my-4 text-xl">Sono presenti {bilanci.bilancioAttivo?.business_units.length} business unit associate ad almeno un fattore in questo bilancio</p>
      </div>
      {bilanci.bilancioAttivo?.business_units &&
        <CanvasBusinessUnitsBilancio
          businessUnits={bilanci.bilancioAttivo?.business_units.filter(bu => bu.nome_padre === null)}
        />
      }
    </div>
  )
})

export default SchedaBilancioBusinessUnits;