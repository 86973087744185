import axios from 'axios'
import { config } from '../../../config'
import { IGruppo } from '../../../types'

//Riceve lista gruppi
export const getGruppi = () => {
    return axios.get(`${config.API_URI}gruppi`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceve lista utenti in un gruppo
export const getUtentiGruppo = (codice: string) => {
    return axios.get(`${config.API_URI}gruppo/${codice}/utenti`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}`,
 } })
}

//Crea un gruppo
export const creaGruppo = (gruppo: IGruppo, idUtenti: Array<number>,lingua: string) => {
    return axios.post(`${config.API_URI}gruppo`, {
        codice: gruppo.codice,
        nome: gruppo.nome,
        descrizione: gruppo.descrizione,
        utenti: idUtenti
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
            lingua: lingua,
        }
    })
}


//Modifica un gruppo
export const modificaGruppo = (gruppo: IGruppo, idUtenti: Array<number>) => {
    return axios.put(`${config.API_URI}gruppo/${gruppo.codice_originale ?? gruppo.codice}`, {
        codice: gruppo.codice,
        codice_originale: gruppo.codice_originale,
        nome: gruppo.nome,
        descrizione: gruppo.descrizione,
        utenti: idUtenti
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Elimina un gruppo
export const eliminaGruppo = (codice: string) => {
    return axios.delete(`${config.API_URI}gruppo/${codice}`, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}