import React from 'react'
import { ImpostaNuovaPasswordForm } from '../components/auth/ImpostaNuovaPasswordForm'
import { useTranslation } from 'react-i18next'

export default function ImpostaNuovaPassword() {
  const { t } = useTranslation();
  return (
    <div>
      <h1 className="text-4xl text-text mb-5">{t('PaginaImpostaPassword.impostaNuovaPassword')}</h1>
      <ImpostaNuovaPasswordForm />
    </div>
  )
}
