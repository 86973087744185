import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

//Data
import { Controller, useForm } from "react-hook-form";
import { useStores } from "../../hooks/useStores";
import { ILingua, IModulo } from "../../types";
import { observer } from "mobx-react-lite";
import { config } from "../../config";
import useListaIcone from "../../hooks/useListaIcone";
import { getAllModuli, getModulo } from "./rest/gestioneModuli";
import TraduzioniForm from "../../components/common/form/TraduzioniForm";
//Components
import InfoBox from "../../components/common/InfoBox";
import ErrorBox from "../../components/common/ErrorBox";
import SpinnerButton from "../../components/common/SpinnerButton";
import InputField from "../../components/common/form/InputField";
import TestoErrore from "../../components/common/TestoErrore";
import { getLinguaLista } from "../gestioneLingue/rest/gestioneLingue";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const ModuloForm: React.FC<{ modulo?: IModulo }> = observer(({ modulo }) => {
  const quillForm = useRef(null);

  const { auth } = useStores();

  const [moduloAttivo, setModuloAttivo] = useState<IModulo | null>(
    modulo ? modulo : null
  );

  const [listaLingue, setListaLingue] = useState<ILingua[]>();
  const [linguaAttiva, setLinguaAttiva] = useState<ILingua>();
  const [visibilitaListaLingue, setVisibilitaListaLingue] =
    useState<boolean>(false);

  useEffect(() => {
    getLinguaLista().then((res) => {
      setListaLingue(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (auth.utente && listaLingue && listaLingue.length > 0) {
      setLinguaAttiva(
        listaLingue.find(
          (lingua: ILingua) => lingua.codice === auth.utente?.lingua
        )
      );
    }
  }, [listaLingue]);

  useEffect(() => {
    if (modulo) {
      getModulo(modulo.codice, linguaAttiva?.codice).then((res) => {
        setModuloAttivo(res.data.data);
      });
    }
  }, [linguaAttiva]);

  useEffect(() => {
    if (moduloAttivo) {
      setValue("nome", moduloAttivo.nome);
      setValue("descrizione", moduloAttivo.descrizione);
      setValue("descrizione_estesa", moduloAttivo.descrizione_estesa);

      let editor = document.getElementsByClassName("ql-editor")[0];

      editor.innerHTML = watch("descrizione_estesa") as string;
    }
  }, [moduloAttivo]);

  const { t } = useTranslation();

  const { gestioneModuli } = useStores();

  const { listaIcone } = useListaIcone();

  const [listaModuli, setListaModuli] = useState<IModulo[]>([]);

  useEffect(() => {
    getAllModuli().then((res) => {
      setListaModuli(res.data.data);
    });
  }, []);

  //Istanzia Hook Form
  const {
    watch,
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      codice_originale: modulo ? modulo.codice : "",
      codice: modulo ? modulo.codice : "",
      nome: modulo ? modulo.nome : "",
      descrizione: modulo ? modulo.descrizione : "",
      descrizione_estesa: modulo ? modulo.descrizione_estesa : "",
      icona: modulo ? modulo.icona : "",
      gruppi: modulo ? modulo.gruppi : "",
      attivo: modulo ? !modulo.attivo : "",
      codice_padre: modulo ? modulo.codice_padre : "",
      visibile_navbar: modulo ? modulo.visibile_navbar : false,
      visibile_header: modulo ? modulo.visibile_header : false,
    },
  });

  //Gestisce submit
  const onSubmit = () => {
    if (linguaAttiva && linguaAttiva.codice) {
      modulo
        ? gestioneModuli
          .modificaModulo(watch() as IModulo, linguaAttiva.codice)
          .then(() => {
            setValue("codice_originale", watch("codice"));
          })
        : gestioneModuli.creaModulo(watch() as IModulo, linguaAttiva.codice);
    }
  };

  const handleLinguaAttiva = (lingua: ILingua) => {
    setLinguaAttiva(lingua);
    setVisibilitaListaLingue(false);
  };

  return (
    <div>
      <div className="mb-4">
        <h2 className="text-2xl mb-2">
          {modulo
            ? `${t("ModuloGestioneModuli.Form.modificaModulo")} ${modulo.nome}`
            : t("ModuloGestioneModuli.Form.testoCrea")}
        </h2>
        <p className="text-text-light">
          {modulo
            ? `${t("ModuloGestioneModuli.Form.testoModifica")} ${modulo.nome
            },${t("ModuloGestioneModuli.Form.testModificaCompleto")} `
            : t("ModuloGestioneModuli.Form.testoCrea")}
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          label={t("common.codice")}
          classi="codice"
          placeholder={t("ModuloGestioneModuli.Form.testoPlaceholderCodice")}
          type="text"
          error={errors.codice}
          form={register("codice", {
            required: t("common.fornisciCodice"),
            pattern: {
              value: config.REGEX_SNAKECASE,
              message:
                "Non può contenere spazi, lettere maiuscole o caratteri speciali",
            },
          })}
        />

        <div className="form">
          <label htmlFor="">{t("ModuloGestioneModuli.Form.icona")}</label>
          <select {...register("icona", { required: "Scegli un'icona" })}>
            <option value="" key="0" selected>
              {t("ModuloGestioneModuli.Form.selezioneIcona")}
            </option>
            {listaIcone.map((icona) => {
              return (
                <option key={icona} value={icona}>
                  {icona}
                </option>
              );
            })}
          </select>
        </div>
        {/* Errore field */}
        {errors.icona && <TestoErrore errore={errors.icona.message} />}

        <div className="form">
          <label htmlFor="">
            {t("ModuloGestioneModuli.Form.moduloGenitore")}
          </label>
          <select {...register("codice_padre")}>
            {modulo && modulo.codice_padre ? (
              <>
                <option value="" key="0">
                  {t("common.nessuno")}
                </option>
                {listaModuli
                  .filter(
                    (modulo_item) => modulo_item.codice === modulo.codice_padre
                  )
                  .map((modulo_item) => {
                    return (
                      <option
                        selected
                        key={modulo_item.codice}
                        value={modulo_item.codice}
                      >
                        {modulo_item.nome}
                      </option>
                    );
                  })}
                {listaModuli
                  .filter(
                    (modulo_item) => modulo_item.codice !== modulo.codice_padre
                  )
                  .map((modulo_item) => {
                    return (
                      <option
                        key={modulo_item.codice}
                        value={modulo_item.codice}
                      >
                        {modulo_item.nome}
                      </option>
                    );
                  })}
              </>
            ) : (
              <>
                <option value="" key="0" selected>
                  {t("common.nessuno")}
                </option>
                {listaModuli.map((modulo_item) => {
                  return (
                    <option key={modulo_item.codice} value={modulo_item.codice}>
                      {modulo_item.nome}
                    </option>
                  );
                })}
              </>
            )}
          </select>
        </div>

        {/* Errore field */}
        {errors.codice_padre && (
          <TestoErrore errore={errors.codice_padre.message} />
        )}

        {!modulo && (
          <>
            <div className="form checkbox">
              <label htmlFor="">
                {t("ModuloGestioneModuli.Form.visibileNavBar")}
              </label>
              <input {...register("visibile_navbar")} type="checkbox" />
            </div>

            <div className="form checkbox">
              <label htmlFor="">
                {t("ModuloGestioneModuli.Form.visibileHeader")}
              </label>
              <input {...register("visibile_header")} type="checkbox" />
            </div>
          </>
        )}

        <div className="form checkbox">
          <label htmlFor="">
            {t("ModuloGestioneModuli.Form.disattivaModulo")}
          </label>
          <input {...register("attivo")} type="checkbox" />
        </div>

        <TraduzioniForm>
          {modulo && listaLingue && listaLingue?.length > 1 && (
            <div className="my-2">
              {visibilitaListaLingue ? (
                <div className="flex items-center">
                  {listaLingue?.map((lingua) => (
                    <div
                      className={`cursor-pointer p-2 bg-lighhtgray-d ${linguaAttiva?.codice === lingua.codice &&
                        "pointer-events-none bg-accent"
                        }`}
                      onClick={() => handleLinguaAttiva(lingua)}
                    >
                      <span className={`fi fi-${lingua.bandiera}`} />
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <p>
                    {t("ModuloGestioneModuli.Form.modificandoLinguaIn")}{" "}
                    {linguaAttiva?.nome}
                  </p>
                  {!visibilitaListaLingue && (
                    <div
                      className="cursor-pointer text-accent dark:text-dark-accent"
                      onClick={() =>
                        setVisibilitaListaLingue(!visibilitaListaLingue)
                      }
                    >
                      {t("common.cambiaLingua")}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          <InputField
            label={t("common.nome")}
            classi="nome"
            placeholder={t("ModuloGestioneModuli.Form.testoPlaceholderNome")}
            type="text"
            error={errors.nome}
            form={register("nome", { required: t("common.fornisciNome") })}
          />

          <InputField
            label={t("common.descrizione")}
            classi="descrizione"
            placeholder={t(
              "ModuloGestioneModuli.Form.testoPlaceholderDescrizione"
            )}
            type="text"
            error={errors.descrizione}
            form={register("descrizione", {
              required: t("common.fornisciDescrizione"),
            })}
          />

          <div className="form w-full">
            <label htmlFor="">{t("common.descrizioneEstesa")}</label>
            <Controller
              name="descrizione_estesa"
              control={control}
              render={({ field: { onChange } }) => (
                <ReactQuill
                  className="quill-form"
                  ref={quillForm}
                  theme="snow"
                  defaultValue={watch("descrizione_estesa")}
                  onChange={(e: string) => onChange(e)}
                />
              )}
            />
          </div>
        </TraduzioniForm>
        <div className="form">
          {/* Submit modifica modulo */}
          {modulo && (
            <>
              <div className="form">
                <div>
                  {!gestioneModuli.editingModulo ? (
                    <input type="submit" value="Salva modifiche" />
                  ) : (
                    <SpinnerButton />
                  )}
                </div>
              </div>

              {isSubmitSuccessful &&
                !gestioneModuli.editingModulo &&
                (gestioneModuli.modificaModuloError ? (
                  <div className="my-2">
                    <ErrorBox errore={gestioneModuli.modificaModuloError} />
                  </div>
                ) : (
                  <div className="my-2">
                    <InfoBox messaggio={t("common.modificaSalvato")} />
                  </div>
                ))}
            </>
          )}

          {/* Submit creazione modulo */}
          {!modulo && (
            <>
              {isSubmitSuccessful &&
                !gestioneModuli.creatingModuloError &&
                !gestioneModuli.creatingModulo ? (
                <InfoBox
                  messaggio={t(
                    "ModuloGestioneModuli.Form.messagioCreatoSucesso"
                  )}
                  id={"crea-modulo-success"}
                />
              ) : (
                <div className="form pt-4">
                  {!gestioneModuli.creatingModulo ? (
                    <input
                      type="submit"
                      value={t("ModuloGestioneModuli.Form.creaModulo")}
                      id="crea-modulo-submit"
                    />
                  ) : (
                    <SpinnerButton />
                  )}
                </div>
              )}

              {gestioneModuli.creatingModuloError && (
                <div className="mb-2">
                  <ErrorBox
                    errore={gestioneModuli.creatingModuloError.toString()}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </form>
    </div>
  );
});

export default ModuloForm;
