import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { it } from './locales/it'
import { en } from './locales/en'

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'it',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      it: {
        translation: it
      },
      en: {
        translation: en
      }
    }
  });

export default i18n;