import React from 'react'
import { useTranslation } from 'react-i18next'
//Data
import { useForm } from 'react-hook-form'
import { config } from '../../config'

//Components
import TestoErrore from '../common/TestoErrore'
import { CgPassword } from 'react-icons/cg'
import { useStores } from '../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import SpinnerButton from '../common/SpinnerButton'
import ErrorBox from '../common/ErrorBox'

const CambiaPasswordForm = observer(() => {
  const { t } = useTranslation();

  const { auth } = useStores();

  const { register, handleSubmit, watch, setError, formState: { errors, isSubmitSuccessful } } = useForm();

  const onSubmit = () => {

    //Controlla RegEx Password
    if (!watch('nuovaPassword').match(config.PASSWORD_REGEX)) {

      setError('nuovaPassword', { type: "custom", message: t('common.passwordRegex') })

      //Controlla matching password
    } else if (watch('nuovaPassword') !== watch('ripetiNuovaPassword')) {

      setError('ripetiNuovaPassword', { type: "custom", message: t('FormCompleteAccount.passwordNOnCoincidono') })

    } else {
      auth.aggiornaPassword(watch('vecchiaPassword') as string, watch('nuovaPassword'))
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h6 className="text-xl mb-2 flex items-center gap-2"><CgPassword /> {t('FormCambiaPassword.testoCambiaPassword')}</h6>
      <div className="w-full">
        <div className="form">
          <label htmlFor="">{t('FormCambiaPassword.passwordAttuale')}</label>
          <input {...register("vecchiaPassword", { required: t('FormCambiaPassword.inserisciPasswordAttulae') })} placeholder={t('FormCambiaPassword.passwordAttuale')} type="password" />

          {errors.vecchiaPassword && <TestoErrore errore={errors.vecchiaPassword.message} />}
        </div>

        <div className="flex gap-2">
          <div className="form">
            <label htmlFor="">{t('common.nuovaPassword')}</label>
            <input {...register("nuovaPassword", { required: t('FormCambiaPassword.inserisciNuovaPassword')})} placeholder={t('common.nuovaPassword')} type="password" />

            {errors.nuovaPassword && <TestoErrore errore={errors.nuovaPassword.message} />}
          </div>

          <div className="form">
            <label htmlFor="">{t('FormCambiaPassword.ripetiNuovaPassword')}</label>
            <input {...register("ripetiNuovaPassword", { required: t('FormCambiaPassword.ripetiNuovaPassword') })} placeholder={t('FormCambiaPassword.ripetiNuovaPassword')} type="password" />

            {errors.ripetiNuovaPassword && <TestoErrore errore={errors.ripetiNuovaPassword.message} />}
          </div>
        </div>

      </div>

      <div className="form">

        {
          isSubmitSuccessful && !auth.settingPasswordError && !auth.settingPassword
            ?
            <input type="submit" value={t('FormCambiaPassword.passwordAggiornata')} disabled />
            :
            !auth.settingPassword ? <input type="submit" value={t('FormCambiaPassword.aggiornaPassword')} /> : <SpinnerButton />
        }

        {auth.settingPasswordError &&
          <div className="mt-2">
            <ErrorBox errore={auth.settingPasswordError} />
          </div>
        }

      </div>
    </form>
  )
})

export default CambiaPasswordForm