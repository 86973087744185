import React, { useEffect, useState } from "react"
import { IGruppo } from "../../../types"
import { useTranslation } from 'react-i18next'


const PanoramicaGestioneGruppiGruppi: React.FC<{ gruppo : IGruppo[] }> = ({ gruppo }) => {
    const { t } = useTranslation();

    return (
        <ul className='text-sm lg:font-lg lg:ml-6'>
        <li className='font-extrabold text-sm lg:text-lg mb-0.5 uppercase'>{t('ModuloGestioneUtenti.PannelloUtenti.gruppi')}</li>
        <li className='font-bold text-sm lg:text-lg'>
            {
                gruppo.filter((gruppo) => gruppo.utenti?.length === 0).length
            }
            <span className='mx-1 text-xs lg:text-base'>
                {t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.senzaUtenti')}
            </span>
        </li> 
        <li className='font-bold text-sm lg:text-lg'>
            {
                gruppo.filter((gruppo) => gruppo.utenti?.length != 0 && gruppo.utenti?.length === 1).length
            }
            <span className='mx-1 text-xs lg:text-base'>
                {t('ModuloGestioneUtenti.PannelloUtentiSenzaGruppo.gruppiConUnSoloUtente')}
            </span>
        </li>
        <li className='font-bold text-sm lg:text-lg'>
            {
                gruppo.filter((gruppo) => gruppo.utenti?.length && gruppo.utenti?.length < 5).length

            }
            <span className='mx-1 text-xs lg:text-base'>
                {
                    t('ModuloGestioneUtenti.PannelloUtentiSenzaGruppo.gruppiConMenoDiCinqueUtenti')
                }
            </span>
        </li>
        <li className='font-bold text-sm lg:text-lg'>
            {
                gruppo.filter((gruppo) => gruppo.utenti?.length && gruppo.utenti?.length < 10).length

            }
            <span className='mx-1 text-xs lg:text-base'>{t('ModuloGestioneUtenti.PannelloUtentiSenzaGruppo.gruppiConMenoDiDieciUtenti')}</span>
        </li>
        <li className='font-bold text-sm lg:text-lg'>
            {
                gruppo.filter((gruppo) => gruppo.utenti?.length && gruppo.utenti?.length > 10).length

            }
            <span className='mx-1 text-xs lg:text-base'>{t('ModuloGestioneUtenti.PannelloUtentiSenzaGruppo.gruppiConOltreDieciUtenti')}</span>
        </li>
    </ul>
    )
}

export default PanoramicaGestioneGruppiGruppi;
