import { IFattoreESG } from "../types";

const CreaListaFlatFattoriEsg = (alberatura_fattori: IFattoreESG[]) => {
    
    let fattoriFlat : IFattoreESG[] = []

    const creaLista = (fattore: IFattoreESG) => {
        let nuovo_fattori_flat : IFattoreESG = fattore
                
        if(fattore.sotto_fattori.length > 0 ) {
            fattore.sotto_fattori.forEach(sotto_fattore => creaLista(sotto_fattore) )
        }

        fattoriFlat.push(nuovo_fattori_flat)
    }

    alberatura_fattori.forEach(standard => {
        creaLista(standard)
    })
        
    return fattoriFlat;

}

export default CreaListaFlatFattoriEsg;