export const config = {
    BACKEND_URI: process.env.REACT_APP_API_URI,
    API_URI: process.env.REACT_APP_API_URI + 'api/',
    MAPBOX_API_TOKEN: process.env.REACT_APP_MAPBOX_API_TOKEN,
    //Almeno un numero
    //Almeno una minuscola
    //Almeno una maiuscola
    //Almeno un carattere speciale
    //Da 12 a 63 caratteri
    PASSWORD_REGEX: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#£$%^&_*:;\-.])[a-zA-Z0-9!@#£$%^&_*:;\-.]{12,63}$/,
    REGEX_TELEFONO: /^[0-9\s\-\+\(\)\\.]{10,18}$/,
    REGEX_SNAKECASE: /^[a-z0-9_]*$/,
}