import axios from 'axios'
import { config } from '../../../config'
import { IBilancio, IIndicatore, ITemaMaterialitaAnno } from '../types'

//Riceve lista bilanci
export const getBilanci = () => {
    return axios.get(`${config.API_URI}bilanci`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceve singolo bilancio
export const getBilancio = (anno: number) => {
    return axios.get(`${config.API_URI}bilancio/${anno}`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Crea bilancio
export const creaBilancio = (bilancio: IBilancio) => {
    return axios.post(`${config.API_URI}bilancio`, {
        anno: bilancio.anno,
        descrizione: bilancio.descrizione,
    },
        {
            headers:
                { authorization: `Bearer ${localStorage.getItem('token')}` }
        })
}

//Modifica bilancio
export const modificaBilancio = (bilancio: IBilancio) => {
    return axios.put(`${config.API_URI}bilancio/${bilancio.anno_originale ? bilancio.anno_originale : bilancio.anno}`, {
        anno: bilancio.anno,
        descrizione: bilancio.descrizione,
        anno_originale: bilancio.anno_originale,
    },
        {
            headers:
                { authorization: `Bearer ${localStorage.getItem('token')}` }
        })
}

//Elimina bilancio
export const eliminaBilancio = (anno: number) => {
    return axios.delete(`${config.API_URI}bilancio/${anno}`, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Riceve item fattore bilancio
export const getFattoreBilancio = (bilancio_anno: number, fattore_codice: string) => {
    return axios.get(`${config.API_URI}bilancio/${bilancio_anno}/esg/${fattore_codice}`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceve item tema bilancio
export const getTemaBilancio = (bilancio_anno: number, tema_codice: string) => {
    return axios.get(`${config.API_URI}bilancio/${bilancio_anno}/materialita/${tema_codice}`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceve item obiettivo agenda2030 bilancio
export const getObiettivoAgenda2030Bilancio = (bilancio_anno: number, obiettivo_id: number) => {
    return axios.get(`${config.API_URI}bilancio/${bilancio_anno}/obiettivo_agenda2030/${obiettivo_id}`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceve item bu bilancio
export const getBusinessUnitBilancio = (bilancio_anno: number, business_unit_nome: string) => {
    return axios.get(`${config.API_URI}bilancio/${bilancio_anno}/business_unit/${business_unit_nome}`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceve item gruppo bilancio
export const getGruppoBilancio = (bilancio_anno: number, gruppo_codice: string) => {
    return axios.get(`${config.API_URI}bilancio/${bilancio_anno}/gruppo/${gruppo_codice}`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}
//Collega un fattore
export const collegaFattore = (bilancio_anno: number, fattore_codice: string) => {
    return axios.post(`${config.API_URI}bilancio/${bilancio_anno}/esg/${fattore_codice}/collegamento`, {}, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Scollega un fattore
export const scollegaFattore = (bilancio_anno: number, fattore_codice: string) => {
    return axios.delete(`${config.API_URI}bilancio/${bilancio_anno}/esg/${fattore_codice}/collegamento`, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Collega fattore, bilancio, business unit
export const collegaFattoreBilancioBusinessUnit = (bilancio_anno: number, fattore_codice: string, business_units: Set<string>) => {
    return axios.post(`${config.API_URI}bilancio/${bilancio_anno}/esg/${fattore_codice}/business_units/collegamento`, {
        business_units: business_units
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Collega un indicatore
export const collegaIndicatore = (indicatore: IIndicatore, bilancio_anno: number, fattore_codice1: string, business_unit: string, fattore_codice2?: string,) => {

    let url = ''

    if (fattore_codice2) {
        url = `${config.API_URI}bilancio/${bilancio_anno}/business_unit/${business_unit}/esg/indicatore/${fattore_codice1}/${fattore_codice2}`
    } else {
        url = `${config.API_URI}bilancio/${bilancio_anno}/business_unit/${business_unit}/esg/indicatore/${fattore_codice1}`
    }

    return axios.post(url, {
        valore_testuale: indicatore?.valore_testuale,
        valore_numerico: indicatore?.valore_numerico,
        valore_booleano: indicatore?.valore_booleano,
        valore_risposta_multipla: indicatore?.valore_risposta_multipla,
        unita_misura: indicatore?.unita_misura,
        note: indicatore?.note,
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Modifica un indicatore
export const modificaIndicatore = (indicatore: IIndicatore, bilancio_anno: number, business_unit_nome: string, fattore_codice1: string, fattore_codice2?: string,) => {

    let url = ''

    if (fattore_codice2) {
        url = `${config.API_URI}bilancio/${bilancio_anno}/business_unit/${business_unit_nome}/esg/indicatore/${fattore_codice1}/${fattore_codice2}`
    } else {
        url = `${config.API_URI}bilancio/${bilancio_anno}/business_unit/${business_unit_nome}/esg/indicatore/${fattore_codice1}`
    }

    return axios.put(url, {
        valore_testuale: indicatore?.valore_testuale,
        valore_numerico: indicatore?.valore_numerico,
        valore_booleano: indicatore?.valore_booleano,
        valore_risposta_multipla: indicatore?.valore_risposta_multipla,
        unita_misura: indicatore?.unita_misura,
        note: indicatore?.note,
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Valida un indicatore
export const validaIndicatore = (bilancio_anno: number, business_unit_nome: string, fattore_codice1: string, fattore_codice2?: string,) => {

    let url = ''

    if (fattore_codice2) {
        url = `${config.API_URI}bilancio/${bilancio_anno}/business_unit/${business_unit_nome}/esg/valida/indicatore/${fattore_codice1}/${fattore_codice2}`
    } else {
        url = `${config.API_URI}bilancio/${bilancio_anno}/business_unit/${business_unit_nome}/esg/valida/indicatore/${fattore_codice1}`
    }

    return axios.put(url, null, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Valida un indicatore
export const invalidaIndicatore = (bilancio_anno: number, business_unit_nome: string, fattore_codice1: string, fattore_codice2?: string,) => {

    let url = ''

    if (fattore_codice2) {
        url = `${config.API_URI}bilancio/${bilancio_anno}/business_unit/${business_unit_nome}/esg/valida/indicatore/${fattore_codice1}/${fattore_codice2}`
    } else {
        url = `${config.API_URI}bilancio/${bilancio_anno}/business_unit/${business_unit_nome}/esg/valida/indicatore/${fattore_codice1}`
    }

    return axios.delete(url, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Scollega un indicatore
export const eliminaIndicatore = (bilancio_anno: number, business_unit_nome: string, fattore_codice1: string, fattore_codice2?: string) => {

    let url = ''

    if (fattore_codice2) {
        url = `${config.API_URI}bilancio/${bilancio_anno}/business_unit/${business_unit_nome}/esg/indicatore/${fattore_codice1}/${fattore_codice2}`
    } else {
        url = `${config.API_URI}bilancio/${bilancio_anno}/business_unit/${business_unit_nome}/esg/indicatore/${fattore_codice1}`
    }

    return axios.delete(url, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Collega una materialita a bilancio
export const collegaTemaMaterialitaBilancio = (bilancio_anno: number, temaMaterialitaCodice: string, descrizioneAnno: string, rilevanza_azienda: number, rilevanza_stakeholder: number) => {
    return axios.put(`${config.API_URI}bilancio/${bilancio_anno}/materialita/${temaMaterialitaCodice}/collega`, {
        tema_materialita: temaMaterialitaCodice,
        descrizione: descrizioneAnno,
        rilevanza_azienda: rilevanza_azienda,
        rilevanza_stakeholder: rilevanza_stakeholder,
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Scollega una materialita a bilancio
export const scollegaTemaMaterialitaBilancio = (bilancio_anno: number, temaMaterialitaCodice: string) => {
    return axios.delete(`${config.API_URI}bilancio/${bilancio_anno}/materialita/${temaMaterialitaCodice}/collega`, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Collega temi di materialità a un fattore nel bilancio
export const collegaTemaMaterialitaFattoreBilancio = (bilancio_anno: number, fattore_codice: string, temiMaterialita: ITemaMaterialitaAnno[]) => {
    return axios.put(`${config.API_URI}bilancio/${bilancio_anno}/materialita/fattore_esg/${fattore_codice}/collega`, {
        bilancio: bilancio_anno,
        fattore_esg: fattore_codice,
        temi_materialita: temiMaterialita,
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}