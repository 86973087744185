import React from "react";

import { useTranslation } from 'react-i18next'

const TraduzioniForm: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation()

  return (
    <div>
      <h4 className="font-bold mb-2">{t('common.contenutiMultilingua')}</h4>
      <div className="bg-lightgray-d dark:bg-darkgray-d p-3 rounded-md">{children}</div>
    </div>
  );
};

export default TraduzioniForm;
