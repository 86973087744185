import React from 'react'

const TestoErrore: React.FC<{ errore: any }> = ({ errore }) => {

  const styles = {
    box: "text-xs mt-2 text-red dark:text-white dark:bg-red dark:p-1 rounded-sm"
  }

  return (
    <div className={styles.box}>
      {errore}
    </div>
  )
}

export default TestoErrore;