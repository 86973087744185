import { observable, action, makeAutoObservable, runInAction } from 'mobx'
import axios from 'axios'
import { config } from '../config'
import { IUtente, IModulo, IAzure, IGoogle } from '../types'
import { getModuliUtente } from '../moduli/gestioneModuli/rest/gestioneModuli'
import creaAlberoModuli from '../hooks/creaAlberoModuli'
import { get } from '../rest/crud_generica'
export class AuthStore {

    //Definisce stato iniziale
    utente: null | IUtente = null
    getUtenteError: string = '';
    gettingUtente: boolean = false;

    //Azure
    Azure: null | IAzure = null
    getAzureError: string = '';
    gettingAzure: boolean = false;

    //Google
    Google: null | IGoogle = null
    getGoogleError: string = '';
    gettingGoogle: boolean = false;

    //Definisce la struttura dei moduli a cui ha accesso l'utente
    moduliUtente: IModulo[] = []

    //Array plain di tutti i codici dei moduli a cui un utente ha accesso
    codiciModuli: string[] = []
    AzureLoginUtente: string[] = []
    //Completa account
    emailVerificata: boolean = false;
    emailVerificataError: string = '';
    gettingVerificaEmail: boolean = false;

    primaPasswordSettata: boolean = false;
    primaPasswordSettataError: string = '';
    settingPrimaPassword: boolean = false;

    //Richiedi una nuova password
    passwordRichiesta: boolean = false;
    passwordRichiestaError: string = '';
    settingPasswordRichiesta: boolean = false;

    //Imposta una nuova password
    nuovaPasswordSettata: boolean = false;
    nuovaPasswordSettataError: string = '';

    //Aggiorna la password
    settingPassword: boolean = false;
    settingPasswordError: string = '';

    //Aggiorna la lingua
    settingLingua: boolean = false;
    settingLinguaError: string = '';

    //UI errore di rete
    networkError: boolean = false;

    //Definisce actions e observables
    constructor() {
        makeAutoObservable(this, {
            //observable
            utente: observable,
            getUtenteError: observable,
            gettingUtente: observable,

            Azure: observable,
            getAzureError: observable,
            gettingAzure: observable,

            moduliUtente: observable,
            codiciModuli: observable,

            emailVerificata: observable,
            emailVerificataError: observable,
            gettingVerificaEmail: observable,

            primaPasswordSettata: observable,
            primaPasswordSettataError: observable,
            settingPrimaPassword: observable,

            passwordRichiesta: observable,
            passwordRichiestaError: observable,
            settingPasswordRichiesta: observable,

            nuovaPasswordSettata: observable,
            nuovaPasswordSettataError: observable,

            settingPassword: observable,
            settingPasswordError: observable,

            settingLingua: observable,
            settingLinguaError: observable,

            networkError: observable,

            //actions
            login: action,
            logout: action,
            userFromStore: action,

            settaModuliUtente: action,

            verificaEmail: action,

            richiediNuovaPassword: action,

            settaNuovaPassword: action,

            settaLingua: action,

            toggleBoxBenvenuto: action
        })
    }

    //Effettua il login
    login = (utente: IUtente, lingua: string) => {

        //Setta stato get user
        this.gettingUtente = true

        //Richiede token
        axios.get(`${config.BACKEND_URI}sanctum/csrf-cookie`).then(res => {

            //Effettua login
            axios.post(`${config.API_URI}login`, { email: utente.email, password: utente.password },
                {
                    headers: {
                        lingua: lingua
                    }
                }
            ).then(res => {

                //Aggiorna lo stato
                runInAction(() => this.utente = res.data.utente)

                //Setta un item nel local storage
                localStorage.setItem("utente", JSON.stringify(res.data.user));

                //Setta cookie jwt
                localStorage.setItem("token", res.data.token);

                //Rimuove errori
                runInAction(() => this.getUtenteError = '')

                //Ripristina stato get user
                runInAction(() => this.gettingUtente = false)

                //redirect in app
                window.location.href = '/app'
            }).catch(err => {
                //Setta errore nello stato
                runInAction(() => this.getUtenteError = err.response.data.message ? err.response.data.message : 'Errore generico')

                //Ripristina stato get user
                runInAction(() => this.gettingUtente = false)
            });
        })
    }

    //Effettua il logout
    logout = () => {
        axios.post(`${config.API_URI}logout`, { withCredentuals: true }).then((res) => {

        }).catch((err => {

        })).finally(() => {

            //Elimino cookie xsrf
            document.cookie = `XSRF-TOKEN=; Max-Age=-99999999;`;

            //Svuota lo stato
            runInAction(() => this.utente = null)

            //Rimuove item dal localstorage
            localStorage.clear()

            //Effettua un redirect
            window.location.href = "/"


        })
    }

    //Popola lo store dell'utente se trova un item nel local storage
    userFromStore = () => {
        if (localStorage.getItem("utente")) {
            this.utente = JSON.parse(localStorage.getItem("utente") as string)
        }
    }

    //Setta l'alberatura dei moduli a cui un utente ha accesso per il menu
    settaModuliUtente = () => {

        getModuliUtente().then(res => {

            const moduliFlat: IModulo[] = res.data.data

            runInAction(() => {
                this.moduliUtente = creaAlberoModuli(moduliFlat)
                this.codiciModuli = moduliFlat.map(modulo => modulo.codice)
            })

        }).catch(err => {

            console.log(err)

        })
    }

    settaLingua = async (lingua: string) => {
        this.settingLingua = true;

        await axios.put(`${config.API_URI}user/modifica_lingua`, {
            lingua: lingua
        },
            {
                headers: {
                    authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }
        ).then(() => {

            this.userUpdate()

            this.settingLinguaError = '';

        }).catch((err) => {

            this.settingLinguaError = err.response.data.message ? err.response.data.message : 'Errore generico'

        }).finally(() => {

            this.settingLingua = false;

        })

    }

    //Aggiorna il localstorage con i nuovi dati
    userUpdate = async () => {
        await axios.get(
            `${config.API_URI}user`,
            {
                headers: {
                    authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }
        ).then((res) => {

            //Aggiorna lo storage
            localStorage.setItem('utente', JSON.stringify(res.data.data))

            //Rimuove ui errore network
            runInAction(() => this.networkError = false)

        }).catch((err) => {

            console.log(err)

            //Imposta ui errore network
            runInAction(() => this.networkError = true)

        }).finally(() => {

            //Aggiorna lo stato
            this.userFromStore();
        })
    }

    //Verifica l'email
    verificaEmail = (codice: string) => {

        runInAction(() => this.gettingVerificaEmail = true)

        axios.get(`${config.API_URI}user/verifica_email/${codice}`).then((res) => {

            runInAction(() => this.emailVerificata = true)
            runInAction(() => this.emailVerificataError = '')

        }).catch((err) => {

            runInAction(() => this.emailVerificata = false)
            runInAction(() => this.emailVerificataError = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {

            runInAction(() => this.gettingVerificaEmail = false)
        })
    }

    //Setta la prima password
    settaPrimaPassword = (codice: string, password: string) => {

        this.settingPrimaPassword = true

        axios.put(`${config.API_URI}user/crea_password/${codice}`, { password: password }).then((res) => {

            runInAction(() => {
                this.primaPasswordSettata = true
                this.primaPasswordSettataError = ''
            })


        }).catch((err) => {

            runInAction(() => this.primaPasswordSettataError = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {
            this.settingPrimaPassword = false
        })
    }


    //Invia richiesta nuova password
    richiediNuovaPassword = (email: string, lingua: string) => {
        runInAction(() => this.settingPasswordRichiesta = true)
        axios.post(`${config.API_URI}password`, { email: email },
            {
                headers: {
                    lingua: lingua
                }
            }
        ).then((res) => {

            runInAction(() => this.passwordRichiesta = true)
            runInAction(() => this.passwordRichiestaError = '')

        }).catch((err) => {

            runInAction(() => this.passwordRichiestaError = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {

            runInAction(() => this.settingPasswordRichiesta = false)
        })
    }

    //Setta la nuova password
    settaNuovaPassword = (codice: string, password: string) => {
        axios.put(`${config.API_URI}password/${codice}`, { password: password }).then((res) => {

            runInAction(() => this.nuovaPasswordSettata = true)
            runInAction(() => this.nuovaPasswordSettataError = '')

        }).catch((err) => {

            runInAction(() => this.nuovaPasswordSettataError = err.response.data.message ? err.response.data.message : 'Errore generico')

        })
    }

    //Attiva o disattiva il welcome box
    toggleBoxBenvenuto = () => {
        axios.put(
            `${config.API_URI}user/benvenuto`,
            {},
            {
                headers: {
                    authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }
        ).then(() => {
            this.userUpdate()
        }).catch((err) => {
            console.log(err)
        })
    }

    //Setta la nuova password
    aggiornaPassword = (vecchia_password: string, nuova_password: string) => {
        this.settingPassword = true

        axios.put(
            `${config.API_URI}user/password`,
            {
                vecchia_password: vecchia_password,
                nuova_password: nuova_password
            },
            {
                headers: {
                    authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }
        ).then((res) => {

            runInAction(() => this.settingPasswordError = '')

        }).catch((err) => {

            runInAction(() => this.settingPasswordError = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {
            this.settingPassword = false
        })
    }

    //per fare login con azure 
    AzureLogin = async () => {

        this.gettingAzure = true

        get('azure/login').then((res) => {

            runInAction(() => this.gettingAzure = true)
            window.location.replace(res.data);
        })
            .catch((err) => {

                runInAction(() => {
                    this.getAzureError = err.response.data.message ? err.response.data.message : 'Errore generico'
                })
            })
            .finally(() => {
                this.gettingAzure = false
            })
    }

    //per fare login con google 
    GoogleLogin = async () => {

        this.gettingGoogle = true

        get('google/login').then((res) => {

            runInAction(() => this.gettingGoogle = true)
            window.location.replace(res.data);
        })
            .catch((err) => {

                runInAction(() => {
                    this.getGoogleError = err.response.data.message ? err.response.data.message : 'Errore generico'
                })
            })
            .finally(() => {
                this.gettingGoogle = false
            })
    }
}