import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BilancioTile from '../../UiModuloFattoriESG/components/bilanci/BilancioTile'
import { getBilanci } from '../../UiModuloFattoriESG/rest/bilanci'
import { IBilancio } from '../../UiModuloFattoriESG/types'

export default function ListaBilanciDashboard() {
    const { t } = useTranslation();

    const [bilanci, setBilanci] = useState<IBilancio[]>([])

    useEffect(() => {
        getBilanci().then((res) => {
            setBilanci(res.data.data)
        })
    }, [])

    return (
        <div className="my-4">
            {
                bilanci.length > 0 ?
                <div className="flex flex-col gap-2">
                    {bilanci.map((bilancio : IBilancio) => {
                        return (
                            <BilancioTile key={bilancio.anno} bilancio={bilancio} modifica={false} />
                        )
                    })}
                </div>
            :
                <div>{t('ModuloDashboard.Archivio.nessunBilancioPresente')}</div>
            }
        </div>
    )
}
