import React from 'react'

const TextLoader : React.FC<{width?: number}> = ({width}) => {
  return (
    <span
    style={{ backgroundImage: 'url(/imgs/loadshader.jpg)', width: width ? width : '100%' }}
    className="text-[rgba(0,0,0,0)] text-loader my-[2px] inline-block">
        load
    </span>
  )
}

export default TextLoader;
