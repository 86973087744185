import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../../hooks/useStores'
import { IFattoreESG } from '../../types'

//Components
import { Link } from 'react-router-dom'
import { FaLink } from 'react-icons/fa'

const IndicatoreTile: React.FC<{ fattore: IFattoreESG }> = observer(({ fattore }) => {

    const { moduli } = useStores()
    const { t } = useTranslation()
    
    return (
        <div className="flex flex-col md:flex-row items-center justify-between bg-white dark:bg-black p-2 shadow-xl p-4">
            <Link to={`/app/modulo/${moduli.moduloAttivo?.codice}/${fattore.bilancio}/fattori_esg/${fattore.codice}`}>
                <div className="hover:underline">
                    <p className="text-xs font-bold text-accent dark:text-dark-accent">{fattore.codice}</p>
                    <h3 className="text-xl text-text dark:text-white">{fattore.nome_esteso}</h3>
                    <p className="mt-2 text-text dark:text-dark-text">{fattore.descrizione}</p>
                </div>
            </Link>

            <div className="flex flex-col gap-2">

                {fattore.tipo === 'tabella' &&
                    <Link to={`/app/modulo/${moduli.moduloAttivo?.codice}/${fattore.bilancio}/fattori_esg/${fattore.codice}`} className="flex flex-col gap-2">
                        <button className="p-2 text-white rounded-sm flex items-center gap-2">
                            <FaLink />
                            <span>{ t('common.vaiAllaTabella') }</span>
                        </button>
                    </Link>
                }

            </div>
        </div>
    )
})

export default IndicatoreTile;