import React, { useEffect, useState } from 'react'

//Data
import { useStores } from '../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import { IModulo } from '../../types'
import { useLocation } from 'react-router-dom'

//Components
import { Link } from 'react-router-dom'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import IconaDinamica from '../common/IconaDinamica'
import TextLoader from './TextLoader'

const Menu = observer(() => {

  const { auth, gestioneModuli, gestionePrivilegi } = useStores()

  useEffect(() => {
    auth.settaModuliUtente();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gestioneModuli.creatingModulo,
    gestioneModuli.moduloModificato,
    gestioneModuli.moduloEliminato,
    gestioneModuli.moduliOrdinati,
    gestionePrivilegi.privilegioAttribuito,
    gestionePrivilegi.privilegioEliminato,
    gestionePrivilegi.privilegioModificato,
  ])

  return (
    <div className="max-h-full">
      <ul className="flex flex-col gap-2">
        {
          auth.moduliUtente && auth.moduliUtente.length > 0 ?
            <>
              {
                auth.moduliUtente.filter(modulo => modulo.visibile_navbar)
                  .map((modulo: IModulo) => (
                    <li key={modulo.codice}>
                      <MenuLinkModulo modulo={modulo} />
                    </li>
                  ))
              }
            </>
            :
            <>
              <li className="text-lg">
                <TextLoader />
              </li>
              <li className="text-lg">
                <TextLoader />
              </li>
              <li className="text-lg">
                <TextLoader />
              </li>
            </>
        }

      </ul>
    </div>
  )
})


const MenuLinkModulo: React.FC<{ modulo: IModulo }> = observer(({ modulo }) => {

  const { ui, moduli } = useStores()

  const [aperto, setAperto] = useState(false)

  const location = useLocation()

  const [selezionato, setSelezionato] = useState(false)

  //Setta lo stato di selezionato per evidenzare la pagina corrente
  useEffect(() => {

    moduli.moduloAttivo?.codice === modulo.codice ? setSelezionato(true) : setSelezionato(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, moduli.moduloAttivo?.codice])

  //Apre di default tutti i dropdown dei moduli quando in modalità sidebar stretta
  useEffect(() => {
    if (!ui.mainNavFull) {
      setAperto(true)
    }
  }, [ui.mainNavFull])

  const styles = {
    link: `
    block text-text-light dark:text-dark-text-light flex items-center
    p-2 rounded-md gap-2 hover:bg-lightgray dark:hover:bg-darkgray grow
    ${ui.mainNavFull ? 'justify-start' : 'justify-center'}
    ${selezionato && 'font-bold text-text dark:text-dark-text bg-lightgray dark:bg-darkgray'}
    `
  }

  return (
    <>
      <div className="w-full flex items-stretch justify-between gap-1">
        {/* Voce link */}
        <Link className={styles.link} to={`/app/modulo/${modulo.codice}`}>
          <div className="text-accent dark:text-dark-accent">
            <IconaDinamica nome={modulo.icona} />
          </div>
          {ui.mainNavFull && <p>{modulo.nome}</p>}
        </Link>

        {/* Se esistono sottomoduli istanzia dropdown */}
        {modulo.sotto_moduli && modulo.sotto_moduli.filter(modulo => modulo.visibile_navbar).length > 0 && ui.mainNavFull &&
          <span onClick={() => setAperto(!aperto)} className="hover:bg-lightgray dark:hover:bg-darkgray dark:text-dark-accent rounded-md cursor-pointer flex items-center justify-center w-5">
            {aperto ? <FaAngleUp /> : <FaAngleDown />}
          </span>
        }

      </div>

      {/* Dropdown ricursivo */}
      {modulo.sotto_moduli && modulo.sotto_moduli.filter(modulo => modulo.visibile_navbar).length > 0 && aperto &&
        <ul className="bg-[rgba(0,0,0,0.03)] dark:bg-[rgba(255,255,255,0.03)] p-1 rounded-md flex flex-col gap-1">
          {
            modulo.sotto_moduli
              .filter(modulo => modulo.visibile_navbar)
              .map(modulo => (
                <SottoMenuLinkModulo key={modulo.codice} modulo={modulo} />
              ))
          }
        </ul>

      }

    </>
  )
})


const SottoMenuLinkModulo: React.FC<{ modulo: IModulo }> = observer(({ modulo }) => {
  return (
    <MenuLinkModulo modulo={modulo} />
  )
});

export default Menu;