import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../hooks/useStores';
import { IDialogue } from '../../types';
import { useTranslation } from 'react-i18next';

const Dialogue: React.FC<IDialogue> = observer(({ onConfirm, titolo, testo, primaryCta, secondaryCta, primaryCtaColor, secondaryCtaColor }) => {

    const { t } = useTranslation()
    
    const { ui } = useStores();

    const styles = {
        wrapper: "w-screen h-screen fixed top-0 flex items-center justify-center z-[1000]",
        background: "sfondo-dialogue-animato bg-black bg-opacity-20 absolute top-0 left-0 right-0 bottom-0",
        box: "dialogue-animato bg-white dark:bg-darkgray-dd dark:text-white p-4 min-w-[250px] max-w-[350px] relative rounded-sm shadow-xl"
    }

    const eseguiConfirm = () => {
        onConfirm && onConfirm()
        ui.unmountDialogue()
    }

    const secCta = useRef(null)

    //Setta il focus sulla secondary cta se non esiste una primary
    useEffect(() => {

        if (!primaryCta) {
            //@ts-ignore
            secCta.current.focus()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={styles.wrapper}>
            <div className={styles.background} onClick={() => ui.unmountDialogue()}></div>

            <div className={styles.box}>

                <h3 className="text-lg">
                    {titolo}
                </h3>

                <p>{testo}</p>

                <div className={`flex items-center justify-end gap-2 mt-4`}>

                    <button ref={secCta} className={`p-2 capitalize ${secondaryCtaColor} text-white rounded-sm`} onClick={() => ui.unmountDialogue()}>
                        {secondaryCta ? secondaryCta : t('common.chiudi')}
                    </button>

                    {primaryCta &&
                        <button autoFocus className={`p-2 capitalize ${primaryCtaColor} text-white rounded-sm`} onClick={() => eseguiConfirm()}>
                            {primaryCta}
                        </button>
                    }
                </div>

            </div>
        </div>
    )
})

export default Dialogue;