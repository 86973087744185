import React from 'react'

//Data
import { useStores } from '../../../../../hooks/useStores';
import { prioritaFattoreRendicontabile } from '../../../../../hooks/prioritaFattoreRendicontabile';
import { IFattoreRendicontabile } from '../../../types';

//Components
import RendicontazioneIndicatoreForm from './RendicontazioneIndicatoreForm';
import { FaPlus } from 'react-icons/fa';
import { observer } from 'mobx-react';

const BottoneCreaIndicatoreRendicontazioneIndicatoreTabellaForm: React.FC<{
  bilancio_anno: number,
  business_unit_nome: string,
  fattore1: IFattoreRendicontabile,
  fattore2: IFattoreRendicontabile,
  tabella?: IFattoreRendicontabile,
  ruolo: number,
}> = observer(({
  bilancio_anno,
  business_unit_nome,
  fattore1,
  fattore2,
  tabella,
  ruolo,
}) => {

  const { ui } = useStores()

  return (
    <div
      className="cursor-pointer hover:opacity-70 w-full h-full text-accent dark:text-dark-accent flex items-center justify-center"
      onClick={() => ui.mountFollowMouseModal(
        <RendicontazioneIndicatoreForm
          bilancio_anno={bilancio_anno}
          business_unit={business_unit_nome}
          fattore1={fattore1}
          fattore2={fattore2}
          ruolo={ruolo}
          fattore_tipo_indicatore={prioritaFattoreRendicontabile([fattore1, fattore2, tabella as IFattoreRendicontabile])?.tipo_indicatore}
          unita_misura_predefinita={prioritaFattoreRendicontabile([fattore1, fattore2, tabella as IFattoreRendicontabile])?.unita_misura ? prioritaFattoreRendicontabile([fattore1, fattore2, tabella as IFattoreRendicontabile])?.unita_misura : ''}
          risposte_multiple_predefinite={prioritaFattoreRendicontabile([fattore1, fattore2, tabella as IFattoreRendicontabile])?.risposte_multiple ? prioritaFattoreRendicontabile([fattore1, fattore2, tabella as IFattoreRendicontabile])?.risposte_multiple : []}
          numero_risposte_multiple_possibili={prioritaFattoreRendicontabile([fattore1, fattore2, tabella as IFattoreRendicontabile])?.num_risposte_multiple ? prioritaFattoreRendicontabile([fattore1, fattore2, tabella as IFattoreRendicontabile])?.num_risposte_multiple : 0}
        />)}>
      <FaPlus />
    </div>
  )
})

export default BottoneCreaIndicatoreRendicontazioneIndicatoreTabellaForm;