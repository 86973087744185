import React from 'react'

//Data
import { IObiettivoAgenda2030 } from '../../../types'
import useOttieniPrivilegioModulo from '../../../../../hooks/useOttieniPrivilegioModulo'
import { useStores } from '../../../../../hooks/useStores'
import { config } from '../../../../../config'

//Components
import ObiettivoAgenda2030ContextMenu from './ObiettivoAgenda2030ContextMenu'
import { FaEllipsisV } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const ObiettivoAgenda2030Square: React.FC<{ obiettivo: IObiettivoAgenda2030, altezza?: number, context?: boolean }> = ({ obiettivo, altezza, context= true }) => {

  const { moduli } = useStores()
  const privilegio = useOttieniPrivilegioModulo()
  const [contextMenu, setContextMenu] = React.useState(false)


  const toggleContextMenu = () => {
    //Il timeout permette di far comparire il context menu dopo che l'utente ha cliccato
    setTimeout(() => {
      setContextMenu(!contextMenu)
    }, 1)
  }

  document.addEventListener('click', () => {
    if (contextMenu) {
      setContextMenu(false)
    }
  }, { once: true })

  return (
    <div
      className="aspect-square relative flex grow hover:brightness-125 duration-200"
      style={{ backgroundColor: obiettivo.colore, height: altezza ? altezza : 'auto' }}
    >
      <Link
        to={`/app/modulo/${moduli.moduloAttivo?.codice}/${obiettivo.id}`}
        className="text-white dark:text-white p-4"
      >
        <div className="flex flex-col justify-between h-full">
          <div className="flex gap-2">
            <p className="text-2xl font-bold">{obiettivo.id}</p>
            <p className="text-lg">{obiettivo.nome}</p>
          </div>

          {obiettivo.immagine &&
            <div className="flex-grow overflow-hidden flex items-end justify-center">
              <img
                src={`${config.BACKEND_URI}icone_obiettivi_2030/${obiettivo.immagine}`}
                alt={obiettivo.descrizione}
                className="max-h-[80%] w-auto"
              />
            </div>
          }
        </div>

      </Link>
      {
        privilegio >= 3 && context &&
        <div className="absolute top-0 right-0 bg-accent">
          <div className="cursor-pointer relative p-1" onClick={() => toggleContextMenu()}>
            <FaEllipsisV className="hover:opacity-70 text-white" />

            {contextMenu && <ObiettivoAgenda2030ContextMenu obiettivo={obiettivo} />}

          </div>
        </div>
      }
    </div>
  )
}

export default ObiettivoAgenda2030Square;
