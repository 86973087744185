import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { observer } from 'mobx-react-lite'

//Components
import { FaInfoCircle, FaPlus, FaSlidersH } from 'react-icons/fa'
import ReactPaginate from 'react-paginate'
import TileUtente from '../../UtenteTile'

import { useStores } from '../../../../hooks/useStores'
import BarraRicercaGenerale from '../../../../components/common/BarraRicercaGenerale'
import { IUtente } from '../../../../types'

export const PannelloUtentiDaVerificare = observer(() => {
    const { t } = useTranslation();
    const { gestioneUtenti } = useStores();
    const styles = {
        cardsWrapper: "grid gap-3 xl:gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-5",
        requestsButton: "cursor-pointer hover:text-accent rounded-sm p-2 hover:bg-lightgray-d flex gap-4 items-center",
        headerPannello: "flex items-center justify-between flex-col md:flex-row my-5",
        buttonRichiesteGestite: `relative flex md:block justify-center  ${gestioneUtenti.richieste.filter(richiesta => richiesta.gestita === true).length < 1 && 'opacity-70 pointer-events-none'}`,
        btnFiltri: "text-white opacity-70 p-1 rounded-lg w-[200px]",
    }

    const [ordinati, setOrdinati] = useState("");
    const buttonOrder = (event: React.MouseEvent<HTMLButtonElement>): any => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;
        setOrdinati(button.name);
    }
    const [stringaDiRicercaDaVerificare, setStringaDiRicercaDaVerificare] = useState<string>('');
    const [arrayFiltri, setFiltri] = useState<boolean>(false)
    const chiudFiltri = () => {
        setFiltri(false)
        setOrdinati("")
        setUtenteDaVerificare(utentiDaVerificare);
    }
    const [utenteDaVerificare, setUtenteDaVerificare] = useState<IUtente[]>([]);
    const utentiDaVerificare = gestioneUtenti.utenti.filter((utente) => utente.is_verified === false);
    const utentiDaVerificareFiltrati = utentiDaVerificare.filter((richiesta) => richiesta.nome.toLocaleLowerCase().includes(stringaDiRicercaDaVerificare.toLocaleLowerCase()) || richiesta.email.toLocaleLowerCase().includes(stringaDiRicercaDaVerificare.toLocaleLowerCase()));

    //Dati per paginazione utenti disabilitati
    const [numeroPaginaDaVerificare, setNumeroPaginaDaVerificare] = useState(0);
    const utentiDaVerificarePerPagina = 4;
    const pagineDaVerificareVisitate = numeroPaginaDaVerificare * utentiDaVerificarePerPagina;

    useEffect(() => {
        setNumeroPaginaDaVerificare(0);
    }, [stringaDiRicercaDaVerificare])

    useEffect(() => {
        setUtenteDaVerificare(utentiDaVerificare)
    })

    useEffect(() => {
        if (ordinati === "za") {
            setUtenteDaVerificare(utenteDaVerificare.sort((a: IUtente, b: IUtente) => b.nome > a.nome ? -1 : 1))
        }
        if (ordinati === "az") {
            setUtenteDaVerificare(utenteDaVerificare.sort((a: IUtente, b: IUtente) => b.nome > a.nome ? 1 : -1))
        }
    }, [ordinati])
    
    const conteggioPaginaDaVerificare = Math.ceil( utentiDaVerificareFiltrati.length/utentiDaVerificarePerPagina);
    const cambioPaginaDaVerificare = ({ selected }: { selected: number }): void => {
        setNumeroPaginaDaVerificare(selected)
    };

    const utentiDaVerificareOrdinati = (testo: string) => {
        let utenti = utentiDaVerificareFiltrati
        if (testo === "az") {
            utenti = utenti
                .sort((a: IUtente, b: IUtente) => b.nome > a.nome ? 1 : -1)
        } else if (testo === "za") {
            utenti = utenti
                .sort((a: IUtente, b: IUtente) => b.nome > a.nome ? -1 : 1)
        }
        return utenti.slice(pagineDaVerificareVisitate, pagineDaVerificareVisitate + utentiDaVerificarePerPagina)
            ;
    }   

    useEffect(() => {
        gestioneUtenti.getUtenti();
        gestioneUtenti.getUtentiSenzaGruppo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        gestioneUtenti.creatingUtente,
        gestioneUtenti.utenteModificato,
        gestioneUtenti.utenteEliminato,
        gestioneUtenti.creatingGruppo,
        gestioneUtenti.gruppoModificato,
        gestioneUtenti.gruppoEliminato
    ])
    return (
        <div className='shadow-xl px-4 py-2'>
            <div className="lg:flex text-center justify-between mb-3 text-accent dark:text-dark-accent mt-6 border-[lightgray] border-b-2 py-2">

                <span className="text-lg lg:text-2xl font-bold uppercase">{utenteDaVerificare.length}  {t('ModuloGestioneUtenti.PannelloUtenti.utenti')} {t('ModuloGestioneUtenti.PannelloUtenti.daVerificare')}</span>

                <BarraRicercaGenerale 
                    setStringaDiRicerca={setStringaDiRicercaDaVerificare}
                    numeroRisultati={utentiDaVerificareFiltrati.length}
                    mostraRisultati={true} />
            </div>
            <div className="flex items-center justify-end my-2">

                {arrayFiltri ?
                    <div className="cursor-pointer p-1 hover:bg-white rounded-sm rotate-45" onClick={() => chiudFiltri()}>
                        <FaPlus />
                    </div>
                    :
                    <div className="cursor-pointer p-1 hover:bg-white rounded-sm" onClick={() => setFiltri(true)} >
                        <FaSlidersH />
                    </div>
                }

            </div>
            {arrayFiltri &&
                <>
                    <div className='grid grid-cols-3 gap-2 px-4 my-4 m-auto mostraFiltri'>
                        <div className='flex justify-center'>
                            <button onClick={buttonOrder} className={`${styles.btnFiltri} ${ordinati === "az" ? "opacity-100" : ""}`} name="az"> Ordina per nome desc </button>
                        </div>
                        <div className='flex justify-center'>
                            <button onClick={buttonOrder} className={`${styles.btnFiltri} ${ordinati === "za" ? "opacity-100" : ""}`} name="za"> Ordina per nome asc </button>
                        </div>
                        <div className='flex justify-center'>
                            <button onClick={buttonOrder} className={`${styles.btnFiltri}`} name="resetta"> Resetta filtro </button>
                        </div>
                    </div>

                </>
            }
            <div>
                {
                    utenteDaVerificare.length > 0 ?
                        <><div className={styles.cardsWrapper}>
                            {utentiDaVerificareOrdinati(ordinati)
                                .map((utente, i) => { return <TileUtente boleanoMostrare={true} key={i} utente={utente} /> })}
                        </div>
                            {utenteDaVerificare.length >= 4 &&
                                <div className='mb-2'>
                                    <ReactPaginate
                                        pageCount={conteggioPaginaDaVerificare}
                                        forcePage={numeroPaginaDaVerificare}
                                        nextLabel={'Next'}
                                        onPageChange={cambioPaginaDaVerificare}
                                        containerClassName={"paginationBttns"}
                                        previousLinkClassName={"previousBttn"}
                                        nextLinkClassName={"nextBttn"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"} />
                                </div>
                            }
                        </>
                        :
                        <>
                            <p className="flex items-center gap-2 opacity-70 justify-center">
                                <FaInfoCircle /> <span className="text-lg">{t('ModuloGestioneUtenti.PannelloUtenti.nessunDaVerificare')}</span>
                            </p>
                        </>
                }
            </div>
        </div >

    )
}
)
export default PannelloUtentiDaVerificare