import { observable, action, makeAutoObservable, runInAction } from 'mobx'
import { getAttivitaUtente, getAttivitaUtenteFiltrabile } from '../rest/attivita'
import { IAttivita } from '../types'
import dayjs from 'dayjs'

export class AttivitaStore {
    listaAttivita: IAttivita[] = []
    listaAttivitaPanello: IAttivita[] = []
    listaCountAttivitaPanello: IAttivita[] = []
    gettingAttivita: boolean = false
    gettingAttivitaPanello: boolean = false
    limiteRaggiunto: boolean = false

    filtri: {
        'moduli': string[],
        'metodi': string[],
        'routes': string[]
    } = {
            'moduli': [],
            'metodi': [],
            'routes': []
        }
        filtriPanello: {
            'moduli': string[],
            'metodi': string[],
            'routes': string[],
            'utenti': string[],
            'gruppi': string[],
            'startDate' : string
            'endDate' : string
        } = {
                'moduli': [],
                'metodi': [],
                'routes': [],
                'utenti': [],
                'gruppi': [],
                'startDate': "",
                'endDate': "",
            }    

    stringaFiltri: string = ''
    arrayFiltri: string = ''

    constructor() {
        makeAutoObservable(this, {
            listaAttivita: observable,
            gettingAttivita: observable,
            filtri: observable,
            filtriPanello: observable,
            stringaFiltri: observable,
            arrayFiltri: observable,
            listaAttivitaPanello: observable,
            gettingAttivitaPanello: observable,

            getListaAttivita: action,
            getListaAttivitaPanello: action,
            ripristinaListaAttivita: action,
            attribuisciFiltri: action,
            costruisciStringaFiltri: action,
            costruisciArrayFiltri:action,
            ripristinaFiltri: action,
            ripristinaFiltriPanello: action
        })
    }

    getListaAttivita = async (offset: number, limit: number, filtri?: string) => {

        this.gettingAttivita = true

        getAttivitaUtente(offset, limit, filtri).then((res) => {

            //Aggiunge risultato alla lista delle attività già esistenti
            runInAction(() => this.listaAttivita = [...this.listaAttivita, ...res.data.data])

            if (limit) {
                if (res.data.data.length < limit) {
                    runInAction(() => this.limiteRaggiunto = true)
                }
            }

        }).finally(() => {

            runInAction(() => this.gettingAttivita = false)

        })
    }

    
    getListaAttivitaPanello = async (offset: number, limit: number, filtriPanello?: string) => {

        this.gettingAttivitaPanello = true

        getAttivitaUtenteFiltrabile(offset, limit, filtriPanello).then((res) => {
            //Aggiunge risultato alla lista delle attività già esistenti
            runInAction(() => this.listaAttivitaPanello = [...this.listaAttivitaPanello, ...res.data.data.attivita])
            if(offset ===0)
            runInAction(() => this.listaCountAttivitaPanello = [...this.listaCountAttivitaPanello, ...res.data.data.count])

            if (limit) {
                if (res.data.data.length < limit) {
                    runInAction(() => this.limiteRaggiunto = true)
                }
            }

        }).finally(() => {

            runInAction(() => this.gettingAttivitaPanello = false)

        })
    }
    ripristinaListaAttivita = async () => {
        this.listaAttivita = []
        this.limiteRaggiunto = false
    }
    ripristinaListaAttivitaPanello = async () => {
        this.listaAttivitaPanello = []
        this.listaCountAttivitaPanello = []
        this.limiteRaggiunto = false
    }

    attribuisciFiltri = async (tipoFiltro: 'metodo' | 'modulo', stringa: string) => {
        if (tipoFiltro === 'modulo') {
            if (this.filtri.moduli.includes(stringa)) {
                this.filtri.moduli = this.filtri.moduli.filter((item) => item !== stringa).map((item) => item)
            } else {
                this.filtri.moduli.push(stringa)
            }
        }

        if (tipoFiltro === 'metodo') {
            if (this.filtri.metodi.includes(stringa)) {
                this.filtri.metodi = this.filtri.metodi.filter((item) => item !== stringa).map((item) => item)
            } else {
                this.filtri.metodi.push(stringa)
            }
        }

        this.costruisciStringaFiltri()

    }

    attribuisciFiltriPanello = async (tipoFiltro: 'metodo' | 'modulo' | 'route' | 'utente' | 'gruppo' | 'startDate' | 'endDate' , stringaValori: string) => {
        if (tipoFiltro === 'modulo') {
            if (this.filtriPanello.moduli.includes(stringaValori)) {
                this.filtriPanello.moduli = this.filtriPanello.moduli.filter((item) => item !== stringaValori).map((item) => item)
            } else {
                this.filtriPanello.moduli.push(stringaValori)
            }
        }

        if (tipoFiltro === 'metodo') {
            if (this.filtriPanello.metodi.includes(stringaValori)) {
                this.filtriPanello.metodi = this.filtriPanello.metodi.filter((item) => item !== stringaValori).map((item) => item)
            } else {
                this.filtriPanello.metodi.push(stringaValori)
            }
        }
        if (tipoFiltro === 'route') {
            if (this.filtriPanello.routes.includes(stringaValori)) {
                this.filtriPanello.routes = this.filtriPanello.routes.filter((item) => item !== stringaValori).map((item) => item)
            } else {
                this.filtriPanello.routes.push(stringaValori)
            }
        }
        if (tipoFiltro === 'utente') {
            this.filtriPanello.utenti = [stringaValori]
        }
        if (tipoFiltro === 'gruppo') {
            this.filtriPanello.gruppi = [stringaValori]
        }
        if (tipoFiltro === 'startDate') {
            this.filtriPanello.startDate = dayjs( stringaValori).format('YYYY-MM-DD')
        }
        if (tipoFiltro === 'endDate') {
            this.filtriPanello.endDate = dayjs( stringaValori).format('YYYY-MM-DD')
        }

        this.costruisciArrayFiltri()

    }

    costruisciStringaFiltri = () => {

        this.stringaFiltri = ''

        if (this.filtri.metodi.length > 0) {
            this.stringaFiltri += `&metodi=${this.filtri.metodi.map(metodo => `${metodo}`)}`
        }

        if (this.filtri.moduli.length > 0) {
            this.stringaFiltri += `&moduli=${this.filtri.moduli.map(modulo => `${modulo}`)}`
        }
       
    }
    costruisciArrayFiltri = () => {

        this.arrayFiltri = ''

        if (this.filtriPanello.metodi.length > 0) {
            this.arrayFiltri +=  `&metodi=${this.filtriPanello.metodi.map(metodi => `${ metodi}`)}`
        }
        if (this.filtriPanello.moduli.length > 0) {
            this.arrayFiltri +=  `&moduli=${this.filtriPanello.moduli.map(modulo => `${modulo}`)}`
        }
        if (this.filtriPanello.routes.length > 0) {
            this.arrayFiltri +=  `&routes=${this.filtriPanello.routes.map(route => `${route}`)}`
        }
        if (this.filtriPanello.utenti.length > 0) {
            this.arrayFiltri +=  `&utenti=${this.filtriPanello.utenti.map(utente => `${utente}`)}`
        }
        if (this.filtriPanello.gruppi.length > 0) {
            this.arrayFiltri +=  `&gruppi=${this.filtriPanello.gruppi.map(gruppo => `${gruppo}`)}`
        }
        if (this.filtriPanello.startDate.length > 0) {
            this.arrayFiltri +=  `&startDate=${this.filtriPanello.startDate}`
        }
        if (this.filtriPanello.endDate.length > 0) {
            this.arrayFiltri +=  `&endDate=${this.filtriPanello.endDate}`
        }

    }

    ripristinaFiltri = () => {
        this.filtri = {
            'moduli': [],
            'metodi': [],
            'routes' :[]
        }
        this.stringaFiltri = ''
    }
    
    ripristinaFiltriPanello = () => {
        this.filtriPanello = {
            'moduli': [],
            'metodi': [],
            'routes': [],
            'utenti': [],
            'gruppi': [],
            'startDate':"",
            'endDate':"",
        }
        this.arrayFiltri = ''
    }
}