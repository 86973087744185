import React, { useEffect, useState, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import { useStores } from '../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import { INotizia, ILingua } from '../../types'
import { useTranslation } from 'react-i18next'

//Components
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FaAngleLeft, FaRocket, FaInfoCircle, FaRegEdit, FaSave } from 'react-icons/fa'
import SpinnerButton from '../../components/common/SpinnerButton'
import TraduzioniForm from "../../components/common/form/TraduzioniForm";
import { getLinguaLista } from "../gestioneLingue/rest/gestioneLingue";


const NotiziaForm = observer(() => {

    const { t, i18n } = useTranslation()
    const { notizia_id } = useParams()
    const { gestioneNotizie, auth, ui } = useStores()
    const quillForm = useRef(null);
    const navigate = useNavigate()
    const [listaLingue, setListaLingue] = useState<ILingua[]>();
    const [linguaAttiva, setLinguaAttiva] = useState<ILingua>();
    const [visibilitaListaLingue, setVisibilitaListaLingue] = useState<boolean>(false);

    useEffect(() => {
        getLinguaLista().then((res) => {
            setListaLingue(res.data.data);
        });
    }, []);

    useEffect(() => {
        if (auth.utente && listaLingue && listaLingue.length > 0) {
            setLinguaAttiva(
                listaLingue.find(
                    (lingua: ILingua) => lingua.codice === auth.utente?.lingua
                )
            );
        }
    }, [listaLingue]);
    
    useEffect(() => {
        gestioneNotizie.notizia = null
        notizia_id && gestioneNotizie.dettagli(notizia_id, linguaAttiva?.codice)
    }, [linguaAttiva])

    const { register, watch, control, handleSubmit, setValue, formState: { errors, isSubmitSuccessful } } = useForm()
   
    useEffect(() => {
        if (gestioneNotizie.notizia) {
            setValue('titolo', gestioneNotizie.notizia.titolo)
            setValue('sotto_titolo', gestioneNotizie.notizia.sotto_titolo)
            setValue('corpo', gestioneNotizie.notizia.corpo)
            setValue('in_evidenza', gestioneNotizie.notizia.in_evidenza)
            setValue('visibile_non_loggato', gestioneNotizie.notizia.visibile_non_loggato)
            setValue('visibile_in_dashboard', gestioneNotizie.notizia.visibile_in_dashboard)
            let editor = document.getElementsByClassName("ql-editor")[0];

            editor.innerHTML = watch("corpo") as string;

        }
    }, [gestioneNotizie.notizia])


    const onSubmit = () => {

        if (linguaAttiva && linguaAttiva.codice) {
            gestioneNotizie.notizia && notizia_id ?
                gestioneNotizie.modifica(notizia_id, watch() as INotizia, linguaAttiva.codice)
                :
                gestioneNotizie.crea(watch() as INotizia, linguaAttiva.codice)
        }
    }

    useEffect(() => {

        if (gestioneNotizie.notizia && gestioneNotizie.notizia.id) {
            navigate(gestioneNotizie.notizia.id.toString())
        }

    }, [gestioneNotizie.creata])

    const handlePubblicazione = () => {

        if (gestioneNotizie.notizia && notizia_id) {

            gestioneNotizie.modifica(notizia_id, watch() as INotizia, i18n.language).then(() => {
                gestioneNotizie.pubblica(notizia_id)
            })

        }

    }

    const handlePassaInBozza = () => {

        if (gestioneNotizie.notizia && notizia_id) {

            gestioneNotizie.modifica(notizia_id, watch() as INotizia, i18n.language).then(() => {
                gestioneNotizie.passaInBozza(notizia_id)
            })

        }

    }
    const handleLinguaAttiva = (lingua: ILingua) => {
        setLinguaAttiva(lingua);
        setVisibilitaListaLingue(false);
    };

    return (
        <div>
            <div className="flex items-center justify-between">
                <div className="cursor-pointer bg-lightgray-dd dar:bg-darkgray-dd py-1 px-2 mb-4 flex items-center gap-2" onClick={() => navigate(-1)}>
                    <FaAngleLeft />
                    <span>{t('Pagina404.tornaIndietro')}</span>
                </div>


                <div className="w-[200px]">
                    {
                        gestioneNotizie.notizia && (
                            gestioneNotizie.notizia.bozza ? (
                                gestioneNotizie.publishing ?
                                    <SpinnerButton />
                                    :
                                    <div
                                        onClick={() => handlePubblicazione()}
                                        className="cursor-pointer justify-center rounded-sm bg-accent dark:bg-dark-accent text-white font-bold p-2 flex gap-2 items-center"
                                    >
                                        <FaRocket />
                                        <span>{t('ModuloNotizie.Actions.publish')}</span>
                                    </div>
                            )
                                : (
                                    gestioneNotizie.drafting ?
                                        <SpinnerButton />
                                        :
                                        <div
                                            onClick={() => handlePassaInBozza()}
                                            className="text-center justify-center cursor-pointer rounded-sm bg-accent dark:bg-dark-accent text-white font-bold p-2 flex gap-2 items-center"
                                        >
                                            <FaRegEdit />
                                            <span>{t('ModuloNotizie.Actions.draft')}</span>
                                        </div>
                                )
                        )
                    }
                </div>
            </div >
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2" >
                <TraduzioniForm>
                    {gestioneNotizie.notizia && listaLingue && listaLingue?.length > 1 && (
                        <div className="my-2">
                            {visibilitaListaLingue ? (
                                <div className="flex items-center">
                                    {listaLingue?.map((lingua) => (
                                        <div
                                            className={`cursor-pointer p-2 bg-lighhtgray-d ${linguaAttiva?.codice === lingua.codice &&
                                                "pointer-events-none bg-accent"
                                                }`}
                                            onClick={() => handleLinguaAttiva(lingua)}
                                        >
                                            <span className={`fi fi-${lingua.bandiera}`} />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div>
                                    <p>
                                        {t("ModuloGestioneModuli.Form.modificandoLinguaIn")}{" "}
                                        {linguaAttiva?.nome}
                                    </p>
                                    {!visibilitaListaLingue && (
                                        <div
                                            className="cursor-pointer text-accent dark:text-dark-accent"
                                            onClick={() =>
                                                setVisibilitaListaLingue(!visibilitaListaLingue)
                                            }
                                        >
                                            {t("common.cambiaLingua")}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                    <input
                        className="py-2 text-2xl bg-[rgba(0,0,0,0)] block border-b-2 border-accent outline-none w-full "
                        {...register("titolo", { required: t('ModuloNotizie.Inputs.titolo.required') })}
                        placeholder={t('ModuloNotizie.Inputs.titolo.placeholder')}
                        type="text"
                        required

                    />

                    <input
                        className="py-2 text-lg bg-[rgba(0,0,0,0)] block border-b-2 border-accent outline-none w-full"
                        {...register("sotto_titolo")}
                        placeholder={t('ModuloNotizie.Inputs.sottotitolo.placeholder')}
                        type="textarea"

                    />

                    <div className="mt-4">
                        <label className="mt-4" htmlFor="">
                        {t('ModuloNotizie.Definitions.articleBody')}
                        </label>
                    </div>

                    <div className="mt-4">
                        <Controller
                            name="corpo"
                            control={control}
                            render={({ field: { onChange } }) => (
                                <ReactQuill
                                    className="quill-form"
                                    ref={quillForm}
                                    theme="snow"
                                    defaultValue={gestioneNotizie.notizia ? gestioneNotizie.notizia.corpo : ''}
                                    onChange={(e: string) => onChange(e)}
                                />
                            )}
                        />
                    </div>

                    <div className="flex flex-col my-4 gap-4 items-stretch">
                        <div
                            onMouseEnter={() => ui.mountFollowMouse(<p>{t('ModuloNotizie.Inputs.feature.popup')}</p>)}
                            onMouseLeave={() => ui.unmountFollowMouse()}
                            className="flex gap-2 items-center p-2 bg-lightgray-d shadow-xl">
                            <input
                                {...register("in_evidenza")}
                                type="checkbox"
                            />
                            <label htmlFor="">{t('ModuloNotizie.Inputs.feature.label')}</label>
                            <FaInfoCircle /> 
                        </div>

                        <div className="flex items-center gap-4">
                            <div
                                onMouseEnter={() => ui.mountFollowMouse(<p>{t('ModuloNotizie.Inputs.dashboard.popup')}</p>)}
                                onMouseLeave={() => ui.unmountFollowMouse()}
                                className="w-full flex gap-2 items-center p-2 bg-lightgray-d shadow-xl">
                                <input
                                    {...register("visibile_in_dashboard")}
                                    type="checkbox"
                                />
                                <label htmlFor="">{t('ModuloNotizie.Inputs.dashboard.label')}</label>
                                <FaInfoCircle /> 
                            </div>
                            <div
                                onMouseEnter={() => ui.mountFollowMouse(<p>{t('ModuloNotizie.Inputs.login.popup')}</p>)}
                                onMouseLeave={() => ui.unmountFollowMouse()}
                                className="w-full flex gap-2 items-center p-2 bg-lightgray-d shadow-xl"
                            >
                                <input
                                    {...register("visibile_non_loggato")}
                                    type="checkbox"
                                />
                                <label htmlFor="">{t('ModuloNotizie.Inputs.login.label')}</label>
                                <FaInfoCircle /> 
                            </div>
                        </div>
                    </div>

                </TraduzioniForm>
                <div className="flex justify-end mt-6">
                    <div className="flex flex-col gap-2 w-[200px]">

                        {
                            (gestioneNotizie.creating || gestioneNotizie.modifying) ?
                                <SpinnerButton />
                                :
                                <button className="text-center justify-center cursor-pointer rounded-sm bg-accent dark:bg-dark-accent text-white font-bold p-2 flex gap-2 items-center" >
                                    <FaSave /> {gestioneNotizie.notizia ? t('ModuloNotizie.Actions.save') : t('ModuloNotizie.Actions.create')}
                                </button>
                        }

                    </div>
                </div>

            </form>
        </div >


    )
})

export default NotiziaForm;