import { IFattoreESG, IFattoreRendicontabile, IIndicatore } from "../../../types";

/*
    ottiene una lista di indicatori data una lista
    di fattori e tabelle
*/
export const indicatoriFattori = (fattori: IFattoreRendicontabile[]) => {

    let indicatori: IIndicatore[] = [];

    fattori.forEach(fattore => {

        if (fattore.indicatori.length > 0) {
            fattore.indicatori.forEach(i => indicatori.push(i))
        }

    })

    const map = new Map(indicatori.map(i => [i.id, i]));
    //@ts-ignore
    const uniques = [...map.values()];

    return Array.from(uniques);
}