import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { observer } from 'mobx-react-lite'
import { IGruppo } from '../../../../types'

//Components
import { FaInfoCircle, FaPlus, FaSlidersH } from 'react-icons/fa'
import ReactPaginate from 'react-paginate'

import { useStores } from '../../../../hooks/useStores'
import BarraRicercaGenerale from '../../../../components/common/BarraRicercaGenerale'
import GruppoTile from '../../gruppi/GruppoTile'
import AggiungiGruppo from '../../gruppi/AggiungiGruppo'
import useOttieniPrivilegioModulo from '../../../../hooks/useOttieniPrivilegioModulo'


export const PannelloGruppiConUtenti: React.FC<{ gruppo: IGruppo[] }> = observer(({ gruppo }) => {
    const { gestioneUtenti } = useStores();
    const { t } = useTranslation();
    const privilegio = useOttieniPrivilegioModulo();

    const styles = {
        cardsWrapper: "grid gap-3 xl:gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-6",
        btnFiltri: " opacity-70 text-white p-1 rounded-lg w-[200px]",
    }
    const [arrayFiltri, setFiltri] = useState<boolean>(false)
    const chiudFiltri = () => {
        setFiltri(false)
        gestioneUtenti.getGruppi();
        setClickedButton("")
    }
    //Dati per paginazione gruppi con utenti
    const [stringaDiRicercaGruppiConUtenti, setStringaDiRicercaGruppiConUtenti] = useState<string>('');
    const gruppiConUtentiFiltrati = gruppo.filter((gruppo) => gruppo.nome.toLocaleLowerCase().includes(stringaDiRicercaGruppiConUtenti.toLocaleLowerCase()) && gruppo.utenti?.length != 0);
    const [numeroPaginaGruppiConUtenti, setNumeroPaginaGruppiConUtenti] = useState(0);
    const gruppiConUtentiPerPagina = 4;
    const pagineGruppiConUtentiVisitate = numeroPaginaGruppiConUtenti * gruppiConUtentiPerPagina;
    const conteggioPaginaGruppiConUtenti = Math.ceil(gruppiConUtentiFiltrati.length / gruppiConUtentiPerPagina);
    const cambioPaginaGruppiConUtenti = ({ selected }: { selected: number }): void => {
        setNumeroPaginaGruppiConUtenti(selected)
    };

    useEffect(() => {
        setNumeroPaginaGruppiConUtenti(0)
    }, [stringaDiRicercaGruppiConUtenti])

    const [clickedButton, setClickedButton] = useState("");

    const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>): any => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;
        setClickedButton(button.name);
    }

    const gruppiConUtentiOrdinati = (testo: string) => {
        let gruppi = gruppiConUtentiFiltrati

        if (testo === "min") {
            gruppi = gruppi
                .sort((a: IGruppo, b: IGruppo) => a.utenti?.length! - b.utenti?.length!)
        } else if (testo === "max") {
            gruppi = gruppi
                .sort((a: IGruppo, b: IGruppo) => b.utenti?.length! - a.utenti?.length!)
        } else if (testo === "az") {
            gruppi = gruppi
                .sort((a: IGruppo, b: IGruppo) => b.nome > a.nome ? 1 : -1)
        } else if (testo === "za") {
            gruppi = gruppi
                .sort((a: IGruppo, b: IGruppo) => b.nome > a.nome ? -1 : 1)
        } 
        return gruppi.slice(pagineGruppiConUtentiVisitate, pagineGruppiConUtentiVisitate + gruppiConUtentiPerPagina);
    }

    useEffect(() => {
        gestioneUtenti.getGruppi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        gestioneUtenti.creatingUtente,
        gestioneUtenti.utenteModificato,
        gestioneUtenti.utenteEliminato,
        gestioneUtenti.creatingGruppo,
        gestioneUtenti.gruppoModificato,
        gestioneUtenti.gruppoEliminato
    ])

    useEffect(() => {
        if(clickedButton === "resetta"){
            gestioneUtenti.getGruppi();
                
            }
    },[clickedButton])

    return (
        <div className='shadow-xl px-4 py-2'>

            <div className='lg:flex text-center justify-between mb-3 text-accent dark:text-dark-accent mt-6 border-[lightgray] border-b-2 py-2'>
                <span className="text-lg lg:text-2xl font-bold uppercase">{t('ModuloGestioneUtenti.PannelloUtentiSenzaGruppo.gruppiConUtenti')}</span>
                <BarraRicercaGenerale 
                    setStringaDiRicerca={setStringaDiRicercaGruppiConUtenti} 
                    numeroRisultati={gruppiConUtentiFiltrati.length}
                    mostraRisultati={true} />
            </div>
            <div className="flex items-center justify-end my-2">

                {arrayFiltri ?
                    <div className="cursor-pointer p-1 hover:bg-white rounded-sm rotate-45" onClick={() => chiudFiltri()}>
                        <FaPlus />
                    </div>
                    :
                    <div className="cursor-pointer p-1 hover:bg-white rounded-sm" onClick={() => setFiltri(true)} >
                        <FaSlidersH />
                    </div>
                }

            </div>
            {arrayFiltri &&
                <>
                    <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-2 mb-6 m-auto mostraFiltri'>
                        <div className=''>
                            <button onClick={buttonHandler} className={`${styles.btnFiltri} ${clickedButton === "min" ? "opacity-100" : ""}`} name="min"> Ordina per minimo utenti </button>
                        </div>
                        <div className=''>
                            <button onClick={buttonHandler} className={`${styles.btnFiltri} ${clickedButton === "max" ? "opacity-100" : ""}`} name="max"> Ordina per massimo utenti </button>
                        </div>
                        <div className=''>
                            <button onClick={buttonHandler} className={`${styles.btnFiltri} ${clickedButton === "az" ? "opacity-100" : ""}`} name="az"> Ordina per nome desc </button>
                        </div>
                        <div className=''>
                            <button onClick={buttonHandler} className={`${styles.btnFiltri} ${clickedButton === "za" ? "opacity-100" : ""}`} name="za"> Ordina per nome asc </button>
                        </div>
                        <div className=''>
                            <button onClick={buttonHandler} className={`${styles.btnFiltri}`} name="resetta"> Resetta filtro </button>
                        </div>
                    </div>

                </>
            }


            {gruppiConUtentiFiltrati.length > 0 ?
                (
                    <><div className="flex flex-col gap-5 mt-5 mb-12">
                        {
                            gruppiConUtentiOrdinati(clickedButton)
                                .map((gruppo, i) => { return <GruppoTile key={i} gruppo={gruppo} /> })
                        }
                    </div>
                        {gruppo.filter((gruppo) => gruppo.utenti?.length != 0).length > 4 &&
                            <ReactPaginate
                                pageCount={conteggioPaginaGruppiConUtenti}
                                forcePage={numeroPaginaGruppiConUtenti}
                                nextLabel={'Next'}
                                onPageChange={cambioPaginaGruppiConUtenti}
                                containerClassName={"paginationBttns"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"} />
                        }

                    </>

                )
                :
                (
                    <>
                        <p className="flex items-center gap-2 opacity-70 justify-center">
                            <FaInfoCircle /> <span className="text-lg">{t('ModuloGestioneUtenti.PannelloUtentiSenzaGruppo.nessunGruppoConUtenti')}</span>
                        </p>
                    </>
                )
            }
            { privilegio >= 2 && <AggiungiGruppo />}

        </div>

    )
}
)
export default PannelloGruppiConUtenti 