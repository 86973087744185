import axios from 'axios'
import { config } from '../../../../../config'
import { IObiettivoAgenda2030 } from '../../../types'

//Riceve lista standard
export const getListaObiettiviAgenda2030 = () => {
    return axios.get(`${config.API_URI}obiettivo_agenda2030`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceve singolo Materialita
export const getObiettivoAgenda2030 = (id: number) => {
    return axios.get(`${config.API_URI}obiettivo_agenda2030/${id}`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}
//Riceve info bilancio per un tema di Materialita
export const getBilancioObiettivoAgenda2030 = (id: string, bilancio_anno: string) => {
    return axios.get(`${config.API_URI}obiettivo_agenda2030/${id}/bilancio/${bilancio_anno}`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Crea Materialita
export const creaObiettivoAgenda2030 = (obiettivo: IObiettivoAgenda2030) => {
    return axios.post(`${config.API_URI}obiettivo_agenda2030`, {
        id: obiettivo.id,
        nome: obiettivo.nome,
        nome_esteso: obiettivo.nome_esteso,
        descrizione: obiettivo.descrizione,
        immagine: obiettivo.immagine,
        colore: obiettivo.colore,
    },
        {
            headers:
                { authorization: `Bearer ${localStorage.getItem('token')}` }
        })
}


//Modifica Materialita
export const modificaObiettivoAgenda2030 = (obiettivo: IObiettivoAgenda2030) => {

    return axios.put(`${config.API_URI}obiettivo_agenda2030/${obiettivo.id_originale ? obiettivo.id_originale : obiettivo.id}`, {
        id: obiettivo.id,
        nome: obiettivo.nome,
        nome_esteso: obiettivo.nome_esteso,
        descrizione: obiettivo.descrizione,
        immagine: obiettivo.immagine,
        colore: obiettivo.colore,
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Elimina Materialita
export const eliminaObiettivoAgenda2030 = (id: number) => {
    return axios.delete(`${config.API_URI}obiettivo_agenda2030/${id}`, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}
