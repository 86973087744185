export const it_modulo_gestione_privilegi = {

    Archivio: {
        passaGestioneModuli: 'Passa a gestione moduli',
        nessunoPrivilegio: 'Nessun privilegio presente',
        aggiungiNuovoPrivilegio: 'Aggiungi nuovo privilegio',
    },

    Form: {
        modificaPrivilegi: 'Modifica il privilegio',
        creaPrivilegi: 'Crea un nuovo privilegio',
        modificaTesto: 'Modifica i dati del privilegio',
        modificaTestoCompleto: 'associa dei gruppi per consentire agli utenti che ne fanno parte di accedere alle funzionalità.',
        Priorita: 'Priorità',
        placeholderPriorita: 'es. 0, 3, 10',
        almenoUno: 'Dev\'essere almeno 1',
        placeholderNome: 'es. visualizzazione, modifica, eliminazione',
        messaggiPrivilegioCreatoSucesso: 'Privilegio creato con successo',
        privilegiCrea: 'Crea privilegio',
    },

    ContextMenu: {
        privilegioEliminato: 'Privilegio eliminato con successo',
        modificaPrivilegio: 'Modifica privilegio',
        eliminaPrivilegio: 'Elimina privilegio',
        messaggioConfirmazione: 'Sei sicuro di voler eliminare il privilegio?',
        messsagioElinimaPrivilegio: 'Elimina il privilegio',
    },
}