import React, { useEffect, useState } from "react"
import { IUtente } from "../../../types"
import { UseTranslationResponse, useTranslation } from 'react-i18next'
import { useStores } from '../../../hooks/useStores'

const PanoramicaGestioneUtentiAccessiUtentieGruppi: React.FC<{ utente: IUtente[]}> = ({ utente}) => {
    const { t } = useTranslation();

    const { gestioneUtenti } = useStores();

    const accessoIpe = utente.filter((utente) => utente.oauth_tipo_utente === null);
    const accessoAzure = utente.filter((utente) => utente.oauth_tipo_utente === "azure");
    const accessoGoogle = utente.filter((utente) => utente.oauth_tipo_utente === "google");


    return (
        <><div className='flex flex-col'>
            <ul className='w-[40%] text-sm lg:font-lg self-end'>
                <li className='font-extrabold text-sm lg:text-lg mb-0.5 uppercase'> {gestioneUtenti.utenti.map((utente) => utente.oauth_tipo_utente).length} {t('ModuloGestioneUtenti.PannelloUtenti.accessi')} </li>
                <li className='font-bold text-sm lg:text-lg'>{accessoIpe.length} <span className='mx-0.5 text-xs lg:text-base'>{accessoIpe && "ipè"}</span> </li>
                <li className='font-bold text-sm lg:text-lg'>{accessoAzure.length} <span className='mx-0.5 text-xs lg:text-base'>{accessoAzure && "Azure"}</span> </li>
                <li className='font-bold text-sm lg:text-lg'>{accessoGoogle.length} <span className='mx-0.5 text-xs lg:text-base'>{accessoGoogle && "Google"}</span> </li>
            </ul>
        </div><div className='mb-2'>
                <ul className='w-[80%] lg:w-[60%] text-sm lg:font-lg m-auto'>
                    <li className='font-extrabold text-sm lg:text-lg mb-0.5 uppercase mr-3'>
                        {t('ModuloGestioneUtenti.PannelloUtenti.utentiE')} {t('ModuloGestioneUtenti.PannelloUtenti.gruppi')}
                    </li>
                    <li className='font-bold text-sm lg:text-lg'>
                        {gestioneUtenti.utenti_senza_gruppo.length}

                        {gestioneUtenti.utenti_senza_gruppo.length === 1 ?

                            <span className='mx-1 text-xs lg:text-base'>{t('ModuloGestioneUtenti.PannelloUtenti.utenteSenzaGruppi')}</span>
                            :
                            <span className='mx-1 text-xs lg:text-base'>{t('ModuloGestioneUtenti.PannelloUtenti.utentiSenzaGruppi')}</span>}

                    </li>

                    <li className='font-bold text-sm lg:text-lg'>
                        {utente.filter(utente => utente.gruppi.length > 1).length}
                        {utente.filter(utente => utente.gruppi.length > 1).length === 1 ?

                            <span className='mx-1 text-xs lg:text-base'>{t('ModuloGestioneUtenti.PannelloUtenti.utenteInPiuGruppi')}</span>
                            :
                            <span className='mx-1 text-xs lg:text-base'>{t('ModuloGestioneUtenti.PannelloUtenti.utentiInPiuGruppi')}</span>}
                    </li>
                </ul>
            </div></>
    )
}

export default PanoramicaGestioneUtentiAccessiUtentieGruppi;
