import React, { SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useForm, Controller } from 'react-hook-form'
import { useStores } from '../../hooks/useStores'
import ErrorBox from '../../components/common/ErrorBox'
import InfoBox from '../../components/common/InfoBox'
import { getGruppi } from './rest/gestioneGruppi'
import { observer } from 'mobx-react-lite'
import { IGruppoForm, IUtente } from '../../types'
import { config } from '../../config'
import { getLinguaLista } from '../gestioneLingue/rest/gestioneLingue'

//Components
import { MultiSelect } from 'react-multi-select-component'
import TestoErrore from '../../components/common/TestoErrore'
import SpinnerButton from '../../components/common/SpinnerButton'
import Select from 'react-select'

const ModificaUtenteForm: React.FC<{ utente: IUtente }> = observer(({ utente }) => {
  const { t } = useTranslation();

  const { gestioneUtenti } = useStores();

  //Istanzia Hook Form
  const { watch, register, handleSubmit, control, setValue, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      'id': utente.id,
      'nome': utente.nome,
      'cognome': utente.cognome,
      'azienda': utente.azienda,
      'email': utente.email,
      'telefono': utente.telefono,
      'disabilitato': utente.disabilitato,
      'lingua': utente.lingua,
      'gruppi': []
    }
  })

  const [lingue, setLingue] = useState<[]>([])

  useEffect(() => {
    getLinguaLista().then((res) => {
      setLingue(res.data.data)
    })
  }, [])

  lingue.map((lingua: any) => {
    lingua.value = lingua.codice
    lingua.label = lingua.nome
  })

  //Gestisce submit
  const onSubmit = () => {
    gestioneUtenti.modificaUtente(watch() as IUtente)
  }

  //Lista di tutti i gruppi
  const [gruppi, setGruppi] = useState<any>([])

  //Lista dei gruppi pre-selezionati
  const [gruppiDefault, setGruppiDefault] = useState<any>([])

  //Lista dei gruppo selezionati nel MultiSelect
  const [gruppiSelezionati, setGruppiSelezionati] = useState([]);

  useEffect(() => {

    //Ottiene lista di tutti i gruppi
    getGruppi().then(res => {
      setGruppi(res.data.data)
    })

    //Ottiene lista di tutti i gruppi di cui fa parte l'utente
    setGruppiDefault(utente.gruppi)

  }, [utente.gruppi])

  //Presetta i gruppi di cui fa già parte l'utente
  useEffect(() => {

    //Setta MultiSelect
    setGruppiSelezionati(gruppiDefault)

    //Setta Hook Form
    setValue('gruppi', gruppiDefault)

  }, [gruppiDefault, setValue])


  //Ristruttura dati per label MultiSelect
  gruppi.forEach((gruppo: IGruppoForm) => {
    gruppo.value = gruppo.codice
    gruppo.label = `${gruppo.nome}`
  })

  gruppiDefault.forEach((gruppo: IGruppoForm) => {
    gruppo.value = gruppo.codice
    gruppo.label = `${gruppo.nome}`
  })

  return (
    <div>

      <div className="mb-4">
        <h2 className="text-2xl mb-2">{t('ModuloGestioneUtenti.FormModificaUtente.modificaUtente')} {utente.nome}</h2>
        <p className="text-text-light">{t('ModuloGestioneUtenti.FormModificaUtente.modificaUtenteText')}{utente.nome} {t('ModuloGestioneUtenti.FormModificaUtente.testoModifica')}</p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="w-full flex gap-1">
          <div className="form">
            <label htmlFor="">{t('common.nome')}</label>
            <input autoFocus {...register("nome", { required: t('common.fornisciNome') })} placeholder={t('common.nome')} type="text" />

            {errors.nome && <TestoErrore errore={errors.nome.message} />}
          </div>

          <div className="form">
            <label htmlFor="">{t('common.cognome')}</label>
            <input {...register("cognome", { required: t('common.fornisciCognome') })} placeholder={t('common.cognome')}type="text" />

            {errors.cognome && <TestoErrore errore={errors.cognome.message} />}
          </div>
        </div>

        <div className="form">
          <label htmlFor="">{t('common.azienda')}</label>
          <input {...register("azienda")} placeholder={t('common.azienda')} type="text" />

          {errors.azienda && <TestoErrore errore={errors.azienda.message} />}
        </div>

        <div className="w-full flex gap-1">
          <div className="form opacity-50">
            <label htmlFor="">{t('common.eMail')}</label>
            <input disabled {...register("email", { required: t('common.fornisciEmail') })} placeholder={t('common.eMail')} type="email" />

            {errors.email && <TestoErrore errore={errors.email.message} />}
          </div>

          <div className="form opacity-50">
            <label htmlFor="">{t('common.telefono')}</label>
            <input disabled {...register("telefono", {  pattern: { value: config.REGEX_TELEFONO, message: t('common.telefonoMessagio') } })} placeholder={t('common.telefono')} type="text" />

            {errors.telefono && <TestoErrore errore={errors.telefono.message} />}
          </div>
        </div>

        <div className="form">
          <label>{t('common.lingua')}</label>
          <Controller
            name="lingua"
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                options={lingue}
                defaultValue={{
                  value: utente.lingua,
                  label: utente.lingua,
                }}
                onChange={(e: any) => onChange(e.value)}
              />
            )}
          />
        </div>
        
        <div className="form checkbox">
          <label htmlFor="">{t('ModuloGestioneUtenti.FormModificaUtente.disabilitaTemporaneamente')}</label>
          <input {...register("disabilitato")} type="checkbox" />
        </div>

        <div className="w-full">
          <div className="form">
            <label htmlFor="">{t('common.gruppi')}</label>
            <Controller
              control={control}
              name="gruppi"
              render={({
                field: { onChange },
              }) => (
                <MultiSelect
                  className="dark:text-black"
                  options={gruppi}
                  value={gruppiSelezionati}
                  onChange={(e: SetStateAction<never[]>) => { setGruppiSelezionati(e); onChange(e) }}
                  labelledBy={t('ModuloGestioneUtenti.FormCreaUtenti.selezioneGruppi')}
                  overrideStrings={{
                    selectSomeItems: t('ModuloGestioneUtenti.FormCreaUtenti.selezioneGruppi'),
                    allItemsAreSelected: t('ModuloGestioneUtenti.FormCreaUtenti.tuttiGruppiSelezionati'),
                    selectAll: t('ModuloGestioneUtenti.FormAggiungiUtentiGruppo.selezioneTutti'),
                    search: t('common.cerca'),
                  }}
                />
              )}
            />
          </div>
        </div>

        <div className="form">
          {!gestioneUtenti.editingUtente ?
            <input type="submit" value= {t('common.salvaModifica')} />
            :
            <SpinnerButton />
          }
        </div>

        {gestioneUtenti.modificaUtenteError && (
          <div className="my-2">
            <ErrorBox errore={gestioneUtenti.modificaUtenteError} />
          </div>
        )
        }

        {isSubmitSuccessful && !gestioneUtenti.modificaUtenteError && !gestioneUtenti.editingUtente && (
          <div className="my-2">
            <InfoBox messaggio={t('common.modificaSalvato')} />
          </div>
        )
        }

      </form>
    </div>
  )
})

export default ModificaUtenteForm;