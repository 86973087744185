import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FaInfoCircle } from 'react-icons/fa';
import { useStores } from '../../../../hooks/useStores';
import { IFattoreESG, IIndicatore } from '../../types';
import GraficoBarreTabellaBilanci from './GraficoBarreTabellaBilanci';

const IndicatoriSchedaTabellaESG: React.FC<{ tabella: IFattoreESG }> = ({ tabella }) => {

  const { t } = useTranslation()
  const { ui } = useStores()

  let indicatori_colonne = tabella.sotto_fattori.filter(sf => sf.tipo === 'colonna').map(s => s.indicatori_numerici).flat()

  const styles = {
    wrapper: `overflow-x-auto min-w-full max-w-[calc(100vw-20px)] md:max-w-[calc(100vw-300px)] canvas-body max-h-[1800px]
        ${ui.asideModal && 'md:max-w-[calc(100vw-700px)]'}`
  }

  const [righe, setRighe] = useState<IFattoreESG[]>([])
  const [colonne, setColonne] = useState<IFattoreESG[]>([])

  useEffect(() => {
    setRighe(tabella.sotto_fattori.filter(sf => sf.tipo === 'riga').sort((a, b) => (a.codice > b.codice) ? 1 : ((b.codice > a.codice) ? -1 : 0)))
    setColonne(tabella.sotto_fattori.filter(sf => sf.tipo === 'colonna').sort((a, b) => (a.codice > b.codice) ? 1 : ((b.codice > a.codice) ? -1 : 0)))
  }, [])

  return (
    <div className={styles.wrapper}>
      <table>

        <thead className="font-bold border-b">
          <tr>
            <th className="max-w-[300px] p-2 bg-lightgray-dd dark:bg-darkgray-dd border-r border-t border-l"></th>
            {colonne.map((colonna) => (
              <th key={colonna.codice} className="min-w-[300px] p-2 bg-lightgray-dd dark:bg-darkgray-dd border-r border-t">{colonna.nome}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {righe.map((riga) => (
            <tr key={riga.codice} className="font-bold border-b">
              <th className="max-w-[300px] font-bold p-2 bg-lightgray-dd dark:bg-darkgray-dd border-b border-l border-r">{riga.nome}</th>
              {
                colonne.map((colonna) => (
                  <th key={riga.codice + colonna.codice} className="min-w-[300px] border-b border-r p-2 bg-white dark:bg-darkgray">
                    <>
                      {
                        indicatori_colonne.filter(i => i?.collegamento.colonna === colonna.codice && i?.collegamento.riga === riga.codice).length > 0 ?
                          <GraficoBarreTabellaBilanci
                            indicatori={
                              indicatori_colonne
                                .filter(i => i?.collegamento.colonna === colonna.codice && i?.collegamento.riga === riga.codice) as IIndicatore[]
                            }
                            nome_colonna={colonna.nome}
                            nome_riga={riga.nome}
                          />
                        :
                          <div>
                            <p className="opacity-70 flex flex-col items-center gap-2 text-center mt-4">
                              <FaInfoCircle size={14} />
                              <span>{t('ModuloFattoriEsg.IndicatoriSchedaTabellaESG.NonRendicontato')}</span>
                            </p>
                          </div>
                    }
                    </>
                  </th>
                ))
              }
            </tr>
          ))}
        </tbody>


      </table>
    </div>
  )
}

export default IndicatoriSchedaTabellaESG;