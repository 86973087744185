import React, { useState, useEffect } from 'react'

import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const MenuArchivioNotizie: React.FC = () => {

    const { t } = useTranslation();

    const [titolo, setTitolo] = useState('')
    const location = useLocation()

    useEffect(() => {
        switch(location.pathname) {
            case '/app/modulo/notizie' :
                setTitolo(t('ModuloNotizie.Menu.published'))
                break;
            case '/app/modulo/notizie/bozze' :
                setTitolo(t('ModuloNotizie.Menu.draft'))
                break;
            case '/app/modulo/notizie/archivate' :
                setTitolo(t('ModuloNotizie.Menu.archived'))
                break;
        }
    }, [location.pathname])
    
    return (
        <div className="flex items-center gap-2 justify-between my-4">
            <div className="text-lg font-bold">
                {titolo}
            </div>
            <div className="flex items-center gap-2">
                <MenuItem attivo={location.pathname === '/app/modulo/notizie' && true} nome={t('ModuloNotizie.Definitions.published')} link="/app/modulo/notizie" />
                <MenuItem attivo={location.pathname === '/app/modulo/notizie/bozze' && true} nome={t('ModuloNotizie.Definitions.draft')} link="/app/modulo/notizie/bozze" />
                <MenuItem attivo={location.pathname === '/app/modulo/notizie/archivate' && true} nome={t('ModuloNotizie.Definitions.archived')} link="/app/modulo/notizie/archivate" />
            </div>
        </div>
    )
}

const MenuItem: React.FC<{ nome: string, link: string, attivo?: boolean }> = ({ nome, link, attivo = false }) => {
    return (
        <Link to={link}>
            <div className={`text-accent dark:text-dark-accent font-bold opacity-70 ${attivo && 'underline opacity-100'}`}>
                {nome}
            </div>
        </Link>
    )
}

export default MenuArchivioNotizie;