import React from 'react'

//Data
import { IFattoreESG } from '../../../../types'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

//Components
import HoverFattoriBilancio from '../../../../components/CanvasFattoriBilancio/BloccoFattore/HoverFattoriBilancio'
import { useStores } from '../../../../../../hooks/useStores'
import { useModuloESGStores } from '../../../../context/useModuloESGStores'
import { useModuloFattoriAgenda2030Stores } from '../../context/useModuloFattoriAgenda2030Stores'
import { config } from '../../../../../../config'

//Components
import Dialogue from '../../../../../../components/common/Dialogue'
import { FaPlus } from 'react-icons/fa'


const BloccoCanvasApplicaObiettiviFattori: React.FC<{ fattore: IFattoreESG }> = observer(({ fattore }) => {

  const { ui } = useStores()
  const { t } = useTranslation()
  const { fattori_esg } = useModuloESGStores()
  const { ui_canvas } = useModuloFattoriAgenda2030Stores()

  const styles = {
    wrapper: "flex items-stretch justify grow relative",
    tileFattore: `
    cursor-pointer relative left-2 ml-2 bg-white hover:bg-ligthgray hover:border-accent duration-100
    w-[150px] rounded-sm border border-lightgray-dd dark:bg-black dark:border-darkgray-dd
    ${Array.from(ui_canvas.fattoriSelezionati).includes(fattore.codice) && 'border-2 border-accent'}
    `,
    sotto_fattori: "w-3 h-3 flex items-center justify-center absolute right-[-8px] bg-white z-10 rounded-full border border-accent dark:border-dark-accent hover:text-white cursor-pointer text-accent hover:bg-accent",
    minifica: "z-[10] cursor-pointer absolute flex items-center justify-center text-white right-[-7px] top-[-7px] w-[14px] h-[14px] bg-accent dark:bg-dark-accent rounded-full"
  }

  const handleScollegaObiettivo = (obiettivo_id: number, obiettivo_nome: string) => {

    ui.mountDialogue(<Dialogue
      testo={`${t('ModuloFattoriEsg.CanvasBloccoApplicaObiettiviFattori.scollegareObiettivio')} ${obiettivo_nome} ${t('ModuloFattoriEsg.CanvasBloccoApplicaObiettiviFattori.dalFattore')} ${fattore.nome_esteso}?`}
      primaryCta={t('ModuloFattoriEsg.CanvasBloccoApplicaObiettiviFattori.conferma')}
      primaryCtaColor='bg-red dark:bg-dark-red'
      secondaryCta={t('common.annulla')}
      onConfirm={() => confermaScollegaObiettivo(obiettivo_id, obiettivo_nome)}
    />)

  }

  const confermaScollegaObiettivo = (obiettivo_id: number, obiettivo_nome: string) => {
    fattori_esg.scollegaFattoreESGObiettivi2030([fattore.codice], [obiettivo_id]).then(() => {
      ui.mountDialogue(<Dialogue
        testo={`${t('ModuloFattoriEsg.CanvasBloccoApplicaObiettiviFattori.obbietivo')} ${obiettivo_nome} ${t('ModuloFattoriEsg.CanvasBloccoApplicaObiettiviFattori.romosoSuccesso')} ${fattore.nome_esteso}`}
      />)
    })

    ui_canvas.ripristinaFattoriObiettivi()
  }

  return (
    <div className={`${styles.wrapper}`}>
      <div
        className="w-2 h-[2px] bg-accent dark:bg-dark-accent absolute top-1/2 left-2"
      />

      <div
        className={styles.tileFattore}
      >


        <div
          onMouseEnter={() => ui.mountFollowMouse(<HoverFattoriBilancio fattore={fattore} />)}
          onMouseLeave={() => ui.unmountFollowMouse()}
          onClick={() => ui_canvas.toggleFattoreSelezionato(fattore.codice)}
          className="h-full w-full"
        >

          {/* Metadati fattore */}
          <div className="flex justify-between items-start w-full gap-1 p-2">
            <div>
              <p className="text-[10px] font-bold text-accent dark:text-dark-accent">{fattore.nome}</p>
              <h5 className="text-xs dark:text-white">{fattore.nome_esteso}</h5>

              {fattore.obiettivi_agenda2030 && fattore.obiettivi_agenda2030.length > 0 &&
                <div className="flex flex-wrap gap-2 mt-4">
                  {
                    fattore.obiettivi_agenda2030.map(obiettivo => (
                      <div
                        onMouseEnter={() => ui.mountFollowMouse(
                          <div>
                            <p>Obiettivo agenda 2030:</p>
                            <p className="font-bold">
                              {obiettivo.nome}
                            </p>
                          </div>
                        )}
                        className="w-[30px] h-[30px] flex items-start justify-center text-white p-[6px] relative"
                        style={{ backgroundColor: obiettivo.colore }}
                      >
                        <div
                          onClick={() => handleScollegaObiettivo(obiettivo.id, obiettivo.nome)}
                          className="rotate-45 cursor-pointer hover:opacity-80 absolute top-[-5px] right-[-5px] text-red dark:text-dark-red"
                        >
                          <FaPlus size={14} />
                        </div>
                        <img
                          className="w-[20px] h-[20px]"
                          src={`${config.BACKEND_URI}icone_obiettivi_2030/${obiettivo.immagine}`}
                          alt={obiettivo.descrizione}
                        />
                      </div>
                    ))
                  }
                </div>
              }
            </div>
          </div>

        </div>
      </div>

      <div className="flex gap-1 flex-col grow">

        {
          fattore.sotto_fattori &&
          fattore.sotto_fattori.map((sotto_fattore: any) => (
            <SottoBloccoCanvasApplicaObiettiviFattori key={sotto_fattore.codice} fattore={sotto_fattore} />
          ))
        }

      </div>
    </div>
  )
})

const SottoBloccoCanvasApplicaObiettiviFattori: React.FC<{ fattore: IFattoreESG }> = ({ fattore }) => {
  return (
    <BloccoCanvasApplicaObiettiviFattori fattore={fattore} />
  )
}

export default BloccoCanvasApplicaObiettiviFattori;