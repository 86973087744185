import React from 'react'
import { useTranslation } from 'react-i18next'

export default function Footer() {

  const styles = {
    footer: "border-t border-lightgray-dd dark:border-darkgray-dd p-2 py-4 md:p-4 md:py-6 text-text-light dark:text-dark-text"
  }

  const { t } = useTranslation()
  
  return (
    <div className={styles.footer}>
      <p>
        <a className="dark:text-dark-accent" rel="noreferrer" href="https://ipe.eambiente.it/" target="_blank">Ipè </a>
        {t('common.prodottoDi')}
        <a className="dark:text-dark-accent" rel="noreferrer" href="https://eambientegroup.com/" target="_blank"> IMQ eAmbiente srl</a>
      </p>
    </div>
  )
}
