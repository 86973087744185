import React from 'react'
import { Link } from 'react-router-dom'
import { useStores } from '../../hooks/useStores'
import { SetTheme } from './SetTheme'
import { IModulo } from '../../types'
import { useTranslation } from 'react-i18next'

export default function HeaderModal() {

    const { t } = useTranslation()
    const styles = {
        headerModal: "bg-white dark:bg-darkgray-dd absolute p-3 rounded-md top-6 right-0 w-[200px] shadow-lg",
        headerModalArrow: "absolute top-[-8px] right-[8px] arrow-up border-b-[#fff] dark:border-b-darkgray-dd"
    }

    const { auth, ui } = useStores();

    document.addEventListener('click', () => {
        if (ui.headerModal) {
            ui.toggleHeaderModal()
        }
    }, { once: true })

    return (
        <div className={styles.headerModal}>

            <SetTheme />

            <hr className="border-lightgray dark:border-darkgray mt-4 mb-2" />

            <ul className="flex flex-col gap-2">
                <li>
                    <Link className="block" to="/app/attivita">{t('common.attivita')}</Link>
                </li>

                {
                    auth.moduliUtente && auth.moduliUtente.length > 0 && auth.moduliUtente
                        .filter(modulo => modulo.visibile_header)
                        .map((modulo: IModulo) => (
                            <li>
                                <Link id={`${modulo.codice}-link`} className="block" to={`/app/modulo/${modulo.codice}`}>{modulo.nome}</Link>
                            </li>
                        ))
                }

                <li>
                    <Link className="block" to="/app/profilo">{t('common.profilo')}</Link>
                </li>
            </ul>
            <hr className='border-lightgray-d my-2 dark:border-darkgray' />
            <ul className="flex flex-col gap-2">
                <li>
                    <div className="block text-text-light dark:text-dark-text-light cursor-pointer" onClick={() => auth.logout()}>Logout</div>
                </li>
            </ul>

            <div className={`${styles.headerModalArrow} border-b-[8px] border-b-white dark:border-b-darkgray-dd`}></div>
        </div>
    )
}
