import React, { useEffect, useState } from 'react'

import { useStores } from './../hooks/useStores'
import { INotizia } from '../types'
import { get } from '../rest/crud_generica'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { FaAngleLeft, FaRocket } from 'react-icons/fa'
import dayjs from 'dayjs'
import IpeLogo from '../components/common/IpeLogo'

const Notizia = observer(() => {
    const [notizia, setNotizia] = useState<INotizia>()
    const { t, i18n } = useTranslation()
    const { notizia_id } = useParams()
    const navigate = useNavigate()

    const { auth } = useStores();

    useEffect(() => {

        if (auth.utente) {
            get(`notizia/${notizia_id}`, {
                lingua: i18n.language
            }).then((res) => {
                setNotizia(res.data.data)
            })
        } else {
            get(`notizia/login/${notizia_id}`, {
                lingua: i18n.language
            }).then((res) => {
                setNotizia(res.data.data)
            })
        }

    }, [])

    return (
        <>
            
            <div className="bg-white p-5 shadow-2xl w-2/3 max-w-[900px] mx-auto min-h-screen my-4">
                <div className="cursor-pointer bg-lightgray-dd dar:bg-darkgray-dd py-1 px-2 mb-4 flex items-center gap-2 w-1/6" onClick={() => navigate(-1)}>
                    <FaAngleLeft />
                    <span>{t('Pagina404.tornaIndietro')}</span>
                </div>
                {notizia ?
                    <div className="flex flex-col justify-between h-full">
                        <div>
                            <h2 className="text-2xl font-bold mb-4 ">{notizia.titolo}</h2>

                            {notizia.sotto_titolo && <h3 className="text-lg mb-4 ">{notizia.sotto_titolo}:</h3>}
                        </div>
                        <div ><p className="text-lg mb-4 " dangerouslySetInnerHTML={{ __html: notizia.corpo }}></p>
                        </div>
                        <p className="text-right text-accent">{t('ModuloNotizie.State.published')}: {dayjs(notizia.published_at).locale('it').format(t('common.dateFormat'))}</p>

                    </div>
                     :
                     <div className="mb-5 flex flex-col items-center gap-3">
 
                     <IpeLogo width={40} />
             
                     <h1 className="text-5xl font-bold">{t('Pagina404.errore404')}</h1>
                     <p>{t('Pagina404.paginaNonEsiste')}</p>
                   </div>
                }
            </div>

        </>
    )

})

export default Notizia;