import { useEffect } from 'react'
import dayjs from 'dayjs'
import it from 'dayjs/locale/it'
import { changeLanguage } from 'i18next'
import { useStores } from './useStores'

const useSettaLingua = () => {

  const { auth } = useStores()

  useEffect(() => {

    changeLanguage(auth.utente?.lingua.toString())
    dayjs.locale(it)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.settaLingua])

}

export default useSettaLingua;