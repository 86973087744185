import React, { useState, useEffect } from 'react'

//Data
import { useStores } from '../../../../../hooks/useStores'
import { useModuloESGStores } from '../../../context/useModuloESGStores'
import { IFattoreESG } from '../../../types'
import { observer } from 'mobx-react-lite'

//Components
import { Link } from 'react-router-dom'
import HoverFattoriBilancio from './HoverFattoriBilancio'
import { FaAngleLeft } from 'react-icons/fa'


const BloccoFattoreMinificato: React.FC<{ fattore: IFattoreESG, loader: "bilanci" | "fattori_esg" }> = observer(({ fattore, loader }) => {

  const { ui, moduli } = useStores();

  const { ui_canvas } = useModuloESGStores();

  const styles = {
    wrapper: "flex items-stretch justify grow relative",
    tileFattore: `relative left-2 ml-2 bg-white hover:bg-ligthgray hover:border-accent duration-100 w-[150px] p-2 rounded-sm border border-lightgray-dd dark:bg-black dark:border-darkgray-dd`,
    sotto_fattori: "w-3 h-3 flex items-center justify-center absolute right-[-8px] bg-white z-10 rounded-full border border-accent dark:border-dark-accent hover:text-white cursor-pointer text-accent hover:bg-accent",
    minifica: "z-[10] cursor-pointer absolute flex items-center justify-center text-white right-[-7px] top-[-7px] w-[14px] h-[14px] bg-accent dark:bg-dark-accent rounded-full"
  }

  const [minificato, setMinificato] = useState<boolean>(true);

  const [link, setLink] = useState<string>('')

  useEffect(() => {
    if (loader === 'fattori_esg') {
      setLink(`/app/modulo/fattori_esg/${fattore.codice}`)
    } else if (loader === 'bilanci') {
      setLink(`/app/modulo/${moduli.moduloAttivo?.codice}/${fattore.bilancio}/fattori_esg/${fattore.codice}`)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`${styles.wrapper}`}>
      <div className="w-2 h-[2px] bg-accent dark:bg-dark-accent absolute top-1/2 left-2" />
      <div className={styles.tileFattore}>

        {
          !minificato &&
          <div onClick={() => setMinificato(true)} className={styles.minifica}>
            <FaAngleLeft size={10} />
          </div>
        }

        <Link to={link}
          onMouseEnter={() => ui.mountFollowMouse(<HoverFattoriBilancio fattore={fattore} />)}
          onMouseLeave={() => ui.unmountFollowMouse()}
        >

          {/* Metadati fattore */}
          <div className="flex justify-between items-start w-full gap-1">

            <div>
              {fattore.nome &&
                <p className="text-[10px] font-bold text-accent dark:text-dark-accent" dangerouslySetInnerHTML={
                  { __html: fattore.nome.replace(ui_canvas.stringa_ricerca, `<span class="text-xs bg-accent dark:bg-dark-accent text-white">${ui_canvas.stringa_ricerca}</span>`) }
                } />
              }

              {fattore.nome_esteso !== fattore.nome && fattore.nome_esteso &&
                <h5 className="text-xs dark:text-white" dangerouslySetInnerHTML={
                  { __html: fattore.nome_esteso.replace(ui_canvas.stringa_ricerca, `<span class="text-xs bg-accent dark:bg-dark-accent text-white">${ui_canvas.stringa_ricerca}</span>`) }
                } />
              }
            </div>

          </div>
        </Link>
      </div>

      <div className="flex gap-1 flex-col grow">

        {
          fattore.sotto_fattori &&

            minificato === false ?
            fattore.sotto_fattori.map((sotto_fattore: any) => (
              <SottoBloccoFattoreMinificato key={sotto_fattore.codice} fattore={sotto_fattore} loader={loader} />
            ))
            :
            fattore.sotto_fattori.length > 0 &&
            <div className="ml-4 h-full flex items-center relative" onClick={() => setMinificato(false)}>
              <MostraAltriFattori fattore={fattore} />
            </div>
        }

      </div>
    </div>
  )
})

const SottoBloccoFattoreMinificato: React.FC<{ fattore: IFattoreESG, loader: "bilanci" | "fattori_esg" }> = ({ fattore, loader }) => {
  return (
    <BloccoFattoreMinificato fattore={fattore} loader={loader} />
  )
}

const MostraAltriFattori: React.FC<{ fattore: IFattoreESG }> = observer(({ fattore }) => {
  return (
    <>
      <div className="w-2 h-[2px] bg-accent absolute top-1/2 left-[-12px]"></div>
      <div className="w-5 h-5 border border-accent rounded-full flex items-center justify-center cursor-pointer hover:bg-accent hover:text-white duration-100">
        <span className="text-xs text-center duration-100 font-bold">+{fattore.sotto_fattori.length}</span>
      </div>
    </>
  )
})

export default BloccoFattoreMinificato;