import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

//Data
import { useStores } from '../../../hooks/useStores'
import { IBusinessUnitCategoria } from '../types'
import { UseModuloBusinessUnitCategorieStore } from './context/UseModuloBusinessUnitCategorieStore'

//Components
import { FaInfoCircle } from 'react-icons/fa'
import AggiungiButton from '../../../components/common/AggiungiButton'
import useOttieniPrivilegioModulo from '../../../hooks/useOttieniPrivilegioModulo'
import BusinessUnitCategoriaTile from './components/BusinessUnitCategoriaTile'
import BusinessUnitCategoriaForm from './BusinessUnitCategoriaForm'

const ArchivioBusinessUnitCategorie = observer(() => {

  const privilegio = useOttieniPrivilegioModulo();
  const { t } = useTranslation();
  const { ui } = useStores();
  const { business_unit_categoria } = UseModuloBusinessUnitCategorieStore();

  useEffect(() => {
    business_unit_categoria.getBusinessUnitCategoriaLista();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    business_unit_categoria.businessUnitCategoriaEliminata,
    business_unit_categoria.businessUnitCategoriaModificata,
    business_unit_categoria.businessUnitCategoriaCreata,
  ]
  )

  return (

    <>
      {
        business_unit_categoria.businessUnitCategorie && business_unit_categoria.businessUnitCategorie.length === 0 &&
        <h3 className="flex items-center justify-center gap-2 text-2xl my-8 text-text-light">
          <FaInfoCircle /> {t('ModuloBusinessUnit.ArchivioBusinessUnitCategorie.nessunaCategoriaPresente')}
        </h3>
      }

      <div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
          {
            business_unit_categoria.businessUnitCategorie && business_unit_categoria.businessUnitCategorie.map((categoria: IBusinessUnitCategoria, i: number) => (
              <BusinessUnitCategoriaTile categoria={categoria} />
            ))
          }
        </div>
      </div>


      {privilegio >= 2 &&
        <div className="my-4" onClick={() => ui.mountAsideModal(<BusinessUnitCategoriaForm />)}>
          <AggiungiButton testo={t('ModuloBusinessUnit.ArchivioBusinessUnitCategorie.aggiungiNuovaCategoria')} />
        </div>
      }
    </>
  )
})
export default ArchivioBusinessUnitCategorie;