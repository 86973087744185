import { observable, action, makeAutoObservable } from 'mobx'

export class UiCanvasApplicaObiettiviAgenda2030Store {

    fattoriSelezionati: Set<string> = new Set()
    obiettiviAgenda2030Selezionati: Set<number> = new Set()

    constructor() {
        makeAutoObservable(this, {
            fattoriSelezionati: observable,
            toggleFattoreSelezionato: action,

            obiettiviAgenda2030Selezionati: observable,
            toggleObiettivoSelezionato: action,

            ripristinaFattoriObiettivi: action
        })
    }


    //Effettua toggle selezione di un fattore
    toggleFattoreSelezionato = (fattore_codice: string) => {

        if (Array.from(this.fattoriSelezionati).includes(fattore_codice)) {
            this.fattoriSelezionati.delete(fattore_codice)
        } else {
            this.fattoriSelezionati.add(fattore_codice)
        }

    }

    //Effettua toggle selezione di un obiettivo
    toggleObiettivoSelezionato = (obiettivo_id: number) => {

        if (Array.from(this.obiettiviAgenda2030Selezionati).includes(obiettivo_id)) {
            this.obiettiviAgenda2030Selezionati.delete(obiettivo_id)
        } else {
            this.obiettiviAgenda2030Selezionati.add(obiettivo_id)
        }

    }

    //ripristina lo stato di fattori e obiettivi
    ripristinaFattoriObiettivi = () => {
        this.fattoriSelezionati.clear()
        this.obiettiviAgenda2030Selezionati.clear()
    }
}