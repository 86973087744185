export const it_modulo_gestione_lingue = {
    ContextMenu: {
        linguaEliminata: 'Lingua eliminata con succeso',
        modificaLingua: 'Modifica Lingua',
        messagioElimina: 'Sei sicuro di voler eliminare questa lingua?',
        eliminaLingua: 'Elimina lingua',
    },

    Archivio: {
        nessunaLingua: 'Nessuna lingua presente',
        nuovaLingua: 'Aggiungi nuova lingua',
    },

    Form: {
        modificaLingua: 'Modifica lingua',
        creaLingua: 'Crea lingua',
        modificaLinguaTesto: 'Modifica i dati della lingua',
        placeholderCodice: 'es. it, en, ru',
        messaggiLinguaCreatoSucesso: 'Lingua creata con successo',
        defaultLingua: 'Utilizza come lingua di default',
        linguaDiDefault : 'Lingua di default',
        quandoUtenteRiciede  :' Quando un utente richiede un contenuto che non è stato tradotto nella sua lingua, verrà mostrato in',
        modificandoIn : ' Stai modificando i contenuti di questo oggetto in:',
        cambiaLingua : 'Cambia lingua',
    },
}