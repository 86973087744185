import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useModuloESGStores } from '../../context/useModuloESGStores'
import { observer } from 'mobx-react-lite'
import { useForm, Controller } from 'react-hook-form'
import { IIndicatore } from '../../types'
import {
    collegaIndicatore,
    eliminaIndicatore,
    modificaIndicatore,
    validaIndicatore,
    invalidaIndicatore
} from '../../rest/bilanci'
import { getUnitaMisuraLista } from '../../../gestioneUnitaMisura/rest/gestioneUnitaMisura'
import { IUnitaMisura } from '../../../../types'
import useOttieniPrivilegioModulo from '../../../../hooks/useOttieniPrivilegioModulo'

//Components
import Select from 'react-select'
import InfoBox from '../../../../components/common/InfoBox'
import ErrorBox from '../../../../components/common/ErrorBox'
import SpinnerButton from '../../../../components/common/SpinnerButton'
import InputField from '../../../../components/common/form/InputField'

const IndicatoreForm: React.FC<{
    indicatore?: IIndicatore,
    bilancio_anno: number,
    business_unit?: string,
    fattore_codice1?: string,
    fattore_codice2?: string,
    disabilitato?: boolean
}> = observer(({ indicatore, bilancio_anno, fattore_codice1, fattore_codice2, business_unit, disabilitato = false }) => {

    const { bilanci } = useModuloESGStores()
    const { t } = useTranslation();

    const privilegio = useOttieniPrivilegioModulo()

    const [listaUnitaMisura, setListaUnitaMisura] = useState<IUnitaMisura[]>([])
    const [tipoIndicatore, setTipoIndicatore] = useState<string>('numerico')
    const [datiIndicatore, setdatiIndicatore] = useState<object>()

    const [editingIndicatore, setEditingIndicatore] = useState<boolean>()
    const [editingIndicatoreError, setEditingIndicatoreError] = useState<string>('')

    const [creatingIndicatore, setCreatingIndicatore] = useState<boolean>()
    const [creatingIndicatoreError, setCreatingIndicatoreError] = useState<string>('')

    //Istanzia Hook Form
    const { watch, register, handleSubmit, control, formState: { errors, isSubmitSuccessful } } = useForm({
        defaultValues: {
            valore_testuale: indicatore?.valore_testuale,
            valore_numerico: indicatore?.valore_numerico as number * 1,
            unita_misura: indicatore?.unita_misura,
            note: indicatore?.note,
        }
    })

    //Gestisce submit
    const onSubmit = () => {

        if (indicatore) {

            setEditingIndicatore(true)

            modificaIndicatore(datiIndicatore as IIndicatore, bilancio_anno, business_unit as string, fattore_codice1 as string, fattore_codice2).then(() => {

                if (bilanci?.fattoreBilancioAttivo) {
                    bilanci.getFattoreBilancio(bilancio_anno, bilanci?.fattoreBilancioAttivo.codice as string)
                }

                setEditingIndicatoreError('')

            }).catch((err) => {

                err.response.data.message ? setEditingIndicatoreError(err.response.data.message) : setEditingIndicatoreError('Errore generico')

            }).finally(() => setEditingIndicatore(false))

        } else {

            setCreatingIndicatore(true)

            collegaIndicatore(datiIndicatore as IIndicatore, bilancio_anno, fattore_codice1 as string, business_unit as string, fattore_codice2).then(() => {

                if (bilanci?.fattoreBilancioAttivo) {
                    bilanci.getFattoreBilancio(bilancio_anno, bilanci?.fattoreBilancioAttivo.codice as string)
                }

                setCreatingIndicatoreError('')

            }).catch((err) => {

                err.response.data.message ? setCreatingIndicatoreError(err.response.data.message) : setCreatingIndicatoreError('Errore generico')

            }).finally(() => setCreatingIndicatore(false))

        }
    }

    const handleEliminaIndicatore = () => {
        setEditingIndicatore(true)

        if (bilanci?.fattoreBilancioAttivo) {
            bilanci.getFattoreBilancio(bilancio_anno, bilanci?.fattoreBilancioAttivo.codice as string)
        }

        eliminaIndicatore(bilancio_anno, business_unit as string, fattore_codice1 as string).finally(() => {
            setEditingIndicatore(false)
        })
    }

    const handleValidaIndicatore = () => {
        setEditingIndicatore(true)

        if (bilanci?.fattoreBilancioAttivo) {
            bilanci.getFattoreBilancio(bilancio_anno, bilanci?.fattoreBilancioAttivo.codice as string)
        }

        validaIndicatore(bilancio_anno, business_unit as string, fattore_codice1 as string).finally(() => {
            setEditingIndicatore(false)
        })
    }

    const handleInvalidaIndicatore = () => {
        setEditingIndicatore(true)

        if (bilanci?.fattoreBilancioAttivo) {
            bilanci.getFattoreBilancio(bilancio_anno, bilanci?.fattoreBilancioAttivo.codice as string)
        }

        invalidaIndicatore(bilancio_anno, business_unit as string, fattore_codice1 as string).finally(() => {
            setEditingIndicatore(false)
        })
    }

    //Cambia l'oggetto del payload in base al tipo di indicatore che l'utente sta creando
    useEffect(() => {

        if (tipoIndicatore === 'numerico') {
            setdatiIndicatore({
                valore_numerico: watch('valore_numerico'),
                unita_misura: watch('unita_misura'),
                note: watch('note'),
            })
        }

        if (tipoIndicatore === 'testuale') {
            setdatiIndicatore({
                valore_testuale: watch('valore_testuale'),
                note: watch('note'),
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipoIndicatore, watch('valore_numerico'), watch('unita_misura'), watch('note'), watch('valore_testuale')])

    const styles = {
        bottoneTipo: 'p-3 w-1/2 text-center bg-lightgray dark:bg-darkgray-d duration-200 cursor-pointer text-[rgba(0,0,0,0.6)] dark:text-[rgba(255,255,255,0.4)] flex items-center justify-center',
        bottoneTipoAttivo: 'bg-accent dark:bg-dark-accent !text-white'
    }

    //Setta le unità di misura
    useEffect(() => {
        getUnitaMisuraLista().then((res) => {
            setListaUnitaMisura(res.data.data)

        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //Presetta se l'indicatore è numerico o testuale
    useEffect(() => {
        if (indicatore && indicatore.valore_testuale) {
            setTipoIndicatore('testuale')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //Ristruttura dati per label MultiSelect
    listaUnitaMisura.map((unitaMisura: any) => {
        unitaMisura.value = unitaMisura.codice
        unitaMisura.label = unitaMisura.codice
    })


    return (
        <div className={`${disabilitato && 'opacity-80 pointer-events-none'}`}>

            {indicatore && indicatore.validato ?
                <>
                    {indicatore.validato &&
                        <div className="mb-4">
                            <InfoBox messaggio={ t('ModuloFattoriEsg.FormIndicatore.giaStatoValidato') } />
                        </div>
                    }

                    <div className="mb-4">
                        <h2 className="text-xl text-center">
                        { t('ModuloFattoriEsg.FormIndicatore.indicatore') } "<span className="font-bold text-xl">{fattore_codice1} {fattore_codice2}</span>" {t('ModuloFattoriEsg.FormIndicatore.nelBilancioDel')} {bilancio_anno} {t('ModuloFattoriEsg.FormIndicatore.perLaBusinessUnit')} {business_unit} {t('ModuloFattoriEsg.FormIndicatore.seguente')}
                        </h2>
                    </div>

                    <div className="border border-accent dark:border-dark-accent pointer-events-none opacity-70">
                        <div className="p-3 rounded-br-sm rounded-bl-sm">
                            {tipoIndicatore === 'numerico' &&
                                <div>
                                    <div className="flex gap-1 items-end">
                                        <InputField
                                            label={ t('common.valore') }
                                            classi="valore"
                                            placeholder={ t('ModuloFattoriEsg.FormIndicatore.placeholderValore') }
                                            type="number"
                                            form={register("valore_numerico")}
                                        />

                                        <div className="form max-w-[90px]">
                                            <Controller
                                                name="unita_misura"
                                                control={control}
                                                render={({ field: { onChange } }) => (
                                                    <Select
                                                        options={listaUnitaMisura}
                                                        defaultInputValue={indicatore?.unita_misura}
                                                        onChange={(e: any) => onChange(e.value)}
                                                        placeholder={ t('ModuloFattoriEsg.FormIndicatore.placeholderUm') }
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            {tipoIndicatore === 'testuale' &&
                                <>
                                    <InputField
                                        label={ t('ModuloFattoriEsg.FormIndicatore.Testo') }
                                        classi="testo"
                                        placeholder={ t('ModuloFattoriEsg.FormIndicatore.placeholderTesto') }
                                        type="textarea"
                                        error={errors.valore_testuale}
                                        form={register("valore_testuale")}
                                    />
                                </>
                            }

                            <div className="mt-4">
                                <InputField
                                    label={ t('ModuloFattoriEsg.FormIndicatore.note') }
                                    classi="note"
                                    placeholder={ t('ModuloFattoriEsg.FormIndicatore.placeholderNote') }
                                    type="textarea"
                                    error={errors.note}
                                    form={register("note")}
                                />
                            </div>
                        </div>


                    </div>

                    {(indicatore && privilegio >= 5) &&
                        <div className="mt-3" onClick={() => handleInvalidaIndicatore()}>
                            <button className="p-2 border border-red dark:border-dark-red w-full bg-[rgba(0,0,0,0)] text-red dark:text-dark-red hover:bg-red hover:dark-bg-dark-red hover:text-white">{ t('ModuloFattoriEsg.FormIndicatore.rimuviValidizioneIndicatori') }</button>
                        </div>
                    }
                </>
                :
                <>
                    {disabilitato &&
                        <div className="mb-4">
                            <InfoBox messaggio={t('ModuloFattoriEsg.FormIndicatore.nonHaiIlPrivellagio')} />
                        </div>
                    }

                    <div className="mb-4">
                        <h2 className="text-xl">
                        {t('ModuloFattoriEsg.FormIndicatore.attribuisciIndicatore')} "<span className="font-bold text-xl">{fattore_codice1} {fattore_codice2}</span>" {t('ModuloFattoriEsg.FormIndicatore.nelBilancioDel')} {bilancio_anno} {t('ModuloFattoriEsg.FormIndicatore.perLaBusinessUnit')} {business_unit}
                        </h2>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>


                        <div className="border border-accent dark:border-dark-accent">
                            <div className="flex items-stretch rounded-sm overflow-hidden border-b border-accent dark:border-dark-accent">

                                <div
                                    className={`${styles.bottoneTipo} ${tipoIndicatore === 'numerico' && styles.bottoneTipoAttivo}`}
                                    onClick={() => setTipoIndicatore('numerico')}>
                                    <span>{t('ModuloFattoriEsg.FormIndicatore.valoreNumerico')}</span>
                                </div>

                                <div
                                    className={`${styles.bottoneTipo} ${tipoIndicatore === 'testuale' && styles.bottoneTipoAttivo}`}
                                    onClick={() => setTipoIndicatore('testuale')}>
                                    <span>{t('ModuloFattoriEsg.FormIndicatore.valoreTestuale')}</span>
                                </div>

                            </div>

                            <div className="p-3 rounded-br-sm rounded-bl-sm">
                                {tipoIndicatore === 'numerico' &&
                                    <div>
                                        <div className="flex gap-1 items-end">
                                            <InputField
                                                label={t('common.valore')}
                                                classi="valore"
                                                placeholder={t('ModuloFattoriEsg.FormIndicatore.placeholderValore')}
                                                type="number"
                                                form={register("valore_numerico")}
                                            />

                                            <div className="form max-w-[90px]">
                                                <Controller
                                                    name="unita_misura"
                                                    control={control}
                                                    render={({ field: { onChange } }) => (
                                                        <Select
                                                            options={listaUnitaMisura}
                                                            defaultInputValue={indicatore?.unita_misura}
                                                            onChange={(e: any) => onChange(e.value)}
                                                            placeholder={t('ModuloFattoriEsg.FormIndicatore.placeholderUm')}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }

                                {tipoIndicatore === 'testuale' &&
                                    <>
                                        <InputField
                                            label={t('ModuloFattoriEsg.FormIndicatore.Testo')}
                                            classi="testo"
                                            placeholder={t('ModuloFattoriEsg.FormIndicatore.placeholderTesto')}
                                            type="textarea"
                                            error={errors.valore_testuale}
                                            form={register("valore_testuale")}
                                        />
                                    </>
                                }
                            </div>

                        </div>

                        <div className="mt-4">
                            <InputField
                                label={t('ModuloFattoriEsg.FormIndicatore.note')}
                                classi="note"
                                placeholder={t('ModuloFattoriEsg.FormIndicatore.placeholderNoteAltra')}
                                type="textarea"
                                error={errors.note}
                                form={register("note")}
                            />
                        </div>

                        <div className="form">
                            {/* Submit modifica indicatore */}
                            {indicatore &&

                                <>
                                    <div className="form">
                                        <div>
                                            {!editingIndicatore ? <input type="submit" value={t('common.salvaModifica')} /> : <SpinnerButton />}
                                        </div>
                                    </div>

                                    {
                                        isSubmitSuccessful && !editingIndicatore && (

                                            editingIndicatoreError
                                                ?
                                                <div className="my-2">
                                                    <ErrorBox errore={editingIndicatoreError} />
                                                </div>
                                                :
                                                <div className="my-2">
                                                    <InfoBox messaggio={t('common.modificaSalvato')} />
                                                </div>

                                        )
                                    }

                                </>
                            }

                            {/* Submit creazione indicatore */}
                            {!indicatore &&

                                <>
                                    {
                                        (isSubmitSuccessful && !creatingIndicatoreError && !creatingIndicatore) ?
                                            <InfoBox messaggio="Indicatore attribuito con successo" />
                                            :
                                            <div className="form pt-4">
                                            {!creatingIndicatore ? <input type="submit" value={t('common.salva')} className="capitalize" /> : <SpinnerButton />}
                                            </div>
                                    }

                                    {
                                        creatingIndicatoreError &&
                                        <div className="mb-2">
                                            <ErrorBox errore={creatingIndicatoreError.toString()} />
                                        </div>
                                    }

                                </>
                            }


                        </div>

                    </form>

                    {(indicatore && privilegio >= 4) &&
                        <>

                            <div className="mb-2" onClick={() => handleValidaIndicatore()}>
                                <button className="p-2 border border-green dark:border-dark-green w-full bg-[rgba(0,0,0,0)] text-green dark:text-dark-green hover:bg-green hover:dark-bg-dark-green hover:text-white">Valida indicatore</button>
                            </div>

                            <div onClick={() => handleEliminaIndicatore()}>
                                <button className="p-2 border border-red dark:border-dark-red w-full bg-[rgba(0,0,0,0)] text-red dark:text-dark-red hover:bg-red hover:dark-bg-dark-red hover:text-white">Elimina indicatore</button>
                            </div>

                        </>
                    }
                </>
            }

        </div>
    )
})

export default IndicatoreForm;