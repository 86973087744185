import React, { useEffect, useState } from 'react'

//Data
import { IBusinessUnitNelBilancio } from '../../types';
import { useStores } from '../../../../hooks/useStores';
import { observer } from 'mobx-react';

//Components
import { Link } from 'react-router-dom';
import BusinessUnitGruppi from '../../../UiModuloBusinessUnit/components/BusinessUnitGruppi';
import { useModuloESGStores } from '../../context/useModuloESGStores';
import { useTranslation } from 'react-i18next';

const BusinessUnitTileBilancio: React.FC<{ businessUnit: IBusinessUnitNelBilancio, primo?: boolean, ultimo?: boolean }> = observer(({ businessUnit, primo, ultimo }) => {

    const { t } = useTranslation() 
    const { ui } = useStores()
    const { bilanci } = useModuloESGStores()

    const [numSottoFattori, setNumSottoFattori] = useState(0);
    const [nomiBuAttive, setNomiBuAttive] = useState<string[]>([])

    useEffect(() => {
        bilanci.bilancioAttivo?.business_units.forEach((bu) => {

            setNomiBuAttive(prev => [...prev, bu.nome])
        })
                // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bilanci.bilancioAttivo])

    useEffect(() => {
        businessUnit.sotto_business_unit && setNumSottoFattori(businessUnit.sotto_business_unit.length)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className="flex flex-col items-center gap-[24px] relative left-0">

            {primo && <div className="bg-lightgray-d dark:bg-darkgray-d absolute left-0 w-[calc(50%-1px)] top-[-20px] h-[15px]" />}
            {ultimo && <div className="bg-lightgray-d dark:bg-darkgray-d absolute right-0 w-[calc(50%-1px)] top-[-20px] h-[15px]" />}

            <div className={`bg-white dark:bg-darkgray p-4 flex flex-col justify-between items-center w-[250px] h-[150px] relative ${!nomiBuAttive.includes(businessUnit.nome) && 'opacity-50 pointer-events-none' }`}>

                <div className="flex items-center gap-2">
                    <Link className="dark:text-dark-text hover:underline" to={businessUnit.nome}>
                        <h2 className="font-bold text-lg text-center">
                            {businessUnit?.nome.substring(0, 14)}{businessUnit?.nome.length > 14 && '...'}
                        </h2>
                    </Link>
                </div>

                {businessUnit.sotto_business_unit && businessUnit.sotto_business_unit.length > 0 &&
                    <div className="bg-accent dark:bg-dark-accent w-[2px] h-[12px] absolute bottom-[-12px] mx-auto left-[calc(50%-1px)]" />
                }
            </div>

            {
                businessUnit.sotto_business_unit && businessUnit.sotto_business_unit.length > 0 &&
                <div className="bg-accent dark:bg-dark-accent w-full h-[2px] absolute top-[162px] mx-auto"></div>
            }

            <div className="flex items-start gap-6">
                {/* Se nella business unit sono presenti altre unita queste vengono caricate */}
                {
                    businessUnit.sotto_business_unit && businessUnit.sotto_business_unit.length > 0 &&

                    businessUnit.sotto_business_unit.map((businessUnit: any, i: number) => (
                        <SottoBusinessUnitTileBilancio
                            key={businessUnit.nome}
                            businessUnit={businessUnit}
                            primo={i === 0 ? true : false}
                            ultimo={i === (numSottoFattori - 1) ? true : false}
                        />
                    ))


                }
            </div>

        </div>
    )
})

const SottoBusinessUnitTileBilancio: React.FC<{ businessUnit: IBusinessUnitNelBilancio, primo?: boolean, ultimo?: boolean }> = ({ businessUnit, primo, ultimo }) => {
    return (
        <div className="w-full flex items-stretch justify-center relative">
            <div className="bg-accent dark:bg-dark-accent w-[2px] h-[12px] absolute top-[-12px]"></div>
            <BusinessUnitTileBilancio businessUnit={businessUnit} primo={primo} ultimo={ultimo} />
        </div>
    )
}

export default BusinessUnitTileBilancio;
