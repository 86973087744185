import React, { useState, useEffect } from 'react'

//Data
import { IBusinessUnit } from '../../types';
import { observer } from 'mobx-react';

//Components
import { Link } from 'react-router-dom';

const BusinessUnitTileMinificata: React.FC<{ businessUnit: IBusinessUnit, primo?: boolean, ultimo?: boolean, link?: string }> = observer(({ businessUnit, primo, ultimo, link = '' }) => {

  const [contextMenu, setContextMenu] = useState(false);
  const [numSottoFattori, setNumSottoFattori] = useState(0);

  document.addEventListener('click', () => {
    if (contextMenu) {
      setContextMenu(false)
    }
  })

  useEffect(() => {
    businessUnit.sotto_business_unit && setNumSottoFattori(businessUnit.sotto_business_unit.length)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex flex-col items-center gap-[24px] relative left-0">

      {primo && <div className="bg-lightgray-d dark:bg-darkgray-d absolute left-0 w-[calc(50%-1px)] top-[-20px] h-[15px]" />}
      {ultimo && <div className="bg-lightgray-d dark:bg-darkgray-d absolute right-0 w-[calc(50%-1px)] top-[-20px] h-[15px]" />}

      <div className="bg-white dark:bg-darkgray-dd flex items-center justify-center w-[150px] h-[60px] relative">

        <Link className="dark:text-dark-text hover:underline font-bold" to={link ? link + businessUnit.nome : businessUnit.nome}>
          <h2>{businessUnit?.nome.substring(0, 14)}{businessUnit?.nome.length > 14 && '...'}</h2>
        </Link>
        {
          businessUnit.sotto_business_unit && businessUnit.sotto_business_unit.length > 0 &&
          <div className="bg-accent dark:bg-dark-accent w-[2px] h-[12px] absolute bottom-[-12px] mx-auto left-[calc(50%-1px)]" />
        }
      </div>

      {
        businessUnit.sotto_business_unit && businessUnit.sotto_business_unit.length > 0 &&
        <div className="bg-accent dark:bg-dark-accent w-full h-[2px] absolute top-[72px] mx-auto "></div>
      }


      <div className="flex items-start gap-2">
        {/* Se nella business unit sono presenti altre unita queste vengono caricate */}
        {
          businessUnit.sotto_business_unit && businessUnit.sotto_business_unit.length > 0 &&

          businessUnit.sotto_business_unit.map((businessUnit: IBusinessUnit, i: number) => (
            <SottoBusinessUnitTileMinificata
              key={businessUnit.nome}
              businessUnit={businessUnit}
              primo={i === 0 ? true : false}
              ultimo={i === (numSottoFattori - 1) ? true : false}
            />
          ))


        }
      </div>

    </div>
  )
})

const SottoBusinessUnitTileMinificata: React.FC<{ businessUnit: IBusinessUnit, primo?: boolean, ultimo?: boolean, link?: string }> = ({ businessUnit, primo, ultimo , link = ''}) => {
  return (
    <div className="w-full flex items-stretch justify-center relative">
      <div className="bg-accent dark:bg-dark-accent w-[2px] h-[12px] absolute top-[-12px]"></div>
      <BusinessUnitTileMinificata businessUnit={businessUnit} primo={primo} ultimo={ultimo} link={link} />
    </div>
  )
}

export default BusinessUnitTileMinificata;