import axios from 'axios'
import { useStores } from './useStores';

export default function useIntercettaUtenteNonAutenticato() {

    const { auth } = useStores();

    axios.interceptors.response.use(response => {

        return response;

    }, error => {
        
        if (error.response.status === 401) {
            auth.logout()
        } else {
            throw error;
        }    
    });

}
