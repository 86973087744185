import { IAttivita } from '../../types'
import facAttivitaFattoriESG from '../../moduli/UiModuloFattoriESG/factoryAttivitaFattoriESG'
import facAttivitaTemaMaterialita from '../../moduli/UiModuloFattoriESG/factoryAttivitaTemiMaterialita'
import facAttivitaAgenda2030 from '../../moduli/UiModuloFattoriESG/factoryAttivitaAgenda2030'
import facAttivitaBilancio from '../../moduli/UiModuloFattoriESG/factoryAttivitaBilancio'
import facAttivitaIndicatori from '../../moduli/UiModuloFattoriESG/factoryAttivitaIndicatori'
import facAttivitaRendicontazioneIndicatori from '../../moduli/UiModuloFattoriESG/factoryAttivitaRendicontazioneIndicatori'
import facAttivitaBusinessUnit from '../../moduli/UiModuloBusinessUnit/factoryAttivitaBusinessUnit'
import facAttivitaBusinessUnitRuoli from '../../moduli/UiModuloBusinessUnit/UiModuloBusinessUnitRuoli/factoryAttivitaBusinessUnitRuoli'
import facAttivitaBusinessUnitCategorie from '../../moduli/UiModuloBusinessUnit/UiModuloBusinessUnitCategorie/factoryAttivitaBusinessUnitCategorie'
import { creaStringStandardPerAttivita } from './creaStringStandardPerAttivita'

export default function interpretaAttivita(attivita: IAttivita) {

    let codiceInterpretato = ''
    let funzionePerInterpretare

    switch (attivita.route.split('.')[0] as string) {
        case 'FattoreESG':
        case 'FattoreESGAgenda2030':
            funzionePerInterpretare = facAttivitaFattoriESG
            break;
        case 'TemaMaterialita':
            funzionePerInterpretare = facAttivitaTemaMaterialita
            break;
        case 'Agenda2030':
            funzionePerInterpretare = facAttivitaAgenda2030
            break;
        case 'Bilanci':
        case 'FattoriBilanci':
        case 'FattoriBilanciIndicatori':
        case 'FattoriBilanciBusinessUnits':
        case 'BilanciMaterialita':
        case 'FattoriBilanciMaterialita':
            funzionePerInterpretare = facAttivitaBilancio
            break;
        case 'Indicatori':
            funzionePerInterpretare = facAttivitaIndicatori
            break;
        case 'RendicontazioneIndicatori':
            funzionePerInterpretare = facAttivitaRendicontazioneIndicatori
            break;
        case 'BusinessUnit':
            funzionePerInterpretare = facAttivitaBusinessUnit
            break;
        case 'BusinessUnitRuoli':
            funzionePerInterpretare = facAttivitaBusinessUnitRuoli
            break;
        case 'BusinessUnitCategorie':
            funzionePerInterpretare = facAttivitaBusinessUnitCategorie
            break;
        default:
            funzionePerInterpretare = creaStringStandardPerAttivita
    }

    codiceInterpretato = funzionePerInterpretare(attivita)

    return codiceInterpretato
}