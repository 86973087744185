import React from 'react'
import { useStores } from '../../hooks/useStores'
import { observer } from 'mobx-react-lite'

export const SetTheme = observer(() => {

  const { theme } = useStores();

  const toggleTheme = () => {
    if (theme.theme === 'dark') {
      theme.changeTheme('light')
    } else {
      theme.changeTheme('dark')
    }
  }

  return (
    <div>
      <div className="flex items-center justify-between mt-2" onClick={() => toggleTheme()}>
        <span>Dark mode</span>
        <div className="toggle-theme bg-lightgray-d dark:bg-darkgray-d">
          <div className={`${theme.theme === 'dark' && 'toggle-dark'} bg-accent dark:bg-dark-accent`}></div>
        </div>
      </div>
    </div>
  )
})