export const it_dashboard = {
    Archivio: {
        nessunBilancioPresente: 'Nessun bilancio presente',
    },

    Welcome: {
        benvenutiSuIpe: 'Benvenuti su Ipè!',
        testoIntroduczione: ' La piattaforma cloud sviluppata da IMQ eAmbiente dedicata ai bilanci di sostenibilità. Con Ipè puoi creare un bilancio di sostenibilità partendo da uno standard ESG, modificarlo in base alle esigenze, inserire indicatori per ogni fattore, analizzare e confrontare i dati. ',
        scopriPiuButtone: 'Scopri i moduli',
    },

    widgetAttivita: {
        attivitaRecenti: 'Attività recenti',
        vediTuttiAttivita: 'Vedi tutte le attività',
        nessunAttivita: 'Nessuna attività trovata per il tuo account',
    },

    Dashboard: {
        vaiAlBilancio: 'Vai ai bilanci',
        accediDiretamente: 'Accedi direttamente ai bilanci presenti in piattaforma.',
        suDiNoi: 'Su di noi',
        ipeTitolo: 'Ipè',
        ipeDescrizione: 'Scopri di più sulle potenzialità di Ipè e contattaci per maggiori informazioni.',
        imqeAmbienteTitolo: 'IMQ eAmbiente',
        imqeAmbienteDescrizione: 'Ridurre e gestire l’impatto ambientale è la nostra mission, affiancando imprese e territorio per trovare soluzioni tecniche e gestionali sostenibili sotto il profilo ambientale, sociale ed economico.',
        innovationLabTitolo: 'Innovation Lab',
        innovationLabDescrizione: 'Forniamo software e applicativi informatici da noi sviluppati per rappresentare in maniera dettagliata lo stato ambientale dei territori e delle imprese.',
    },
}