import { IFattoreRendicontabile } from "../moduli/UiModuloFattoriESG/types";


/* 
*  Ritorna il primo fattore che contiene un tipo indicatore,
*  utile per stabilire la priorità della tipologia di rendicontazione 
*  in una cella di una tabella, passando come parametri della funzione riga, colonna
*  e quindi tabella
*/
export const prioritaFattoreRendicontabile = (fattori: IFattoreRendicontabile[]): any => {

    let fattoreConTipo = null

    fattori.forEach((fattore: IFattoreRendicontabile) => {
        if (fattore.tipo_indicatore) {
            fattoreConTipo = fattore
        }
    });
    
    return fattoreConTipo;

}