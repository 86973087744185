import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'


//Data
import { useStores } from '../../../../../hooks/useStores'

//Components
import { FaPlus } from 'react-icons/fa'
import FattoreESGForm from '../../fattoriESG/FattoreESGForm'
import BottoneCanvasGenerico from '../common/BottoneCanvasGenerico'
import BottoneCanvasGenericoAzione from '../common/BottoneCanvasGenericoAzione'

const BottoneAggiungiFattore: React.FC<{ codice_padre?: string }> = ({ codice_padre }) => {


    const { ui } = useStores()
    const { t } = useTranslation();

    const [bottoniTipoFattore, setBottoniTipoFattore] = useState<boolean>(false)

    return (
        <div className="w-[250px]">
            {
                bottoniTipoFattore ?
                    <BottoneCanvasGenerico effettoHover={false}>
                        <div className="flex flex-col gap-1 w-full">

                            <div onClick={() => ui.mountAsideModal(
                                <FattoreESGForm
                                    tipo_fattore="categoria"
                                    codice_padre={codice_padre}
                                />
                            )}>
                                <BottoneCanvasGenericoAzione tipo="azione" testo={t('common.categoria')} icona="FaPlus" />
                            </div>

                            <div onClick={() => ui.mountAsideModal(
                                <FattoreESGForm
                                    tipo_fattore="fattore"
                                    codice_padre={codice_padre}
                                />
                            )}>
                                <BottoneCanvasGenericoAzione tipo="azione" testo={t('common.fattore')}  icona="FaPlus" />
                            </div>

                            <div onClick={() => ui.mountAsideModal(
                                <FattoreESGForm
                                    tipo_fattore="tabella"
                                    codice_padre={codice_padre}
                                />
                            )}>
                                <BottoneCanvasGenericoAzione tipo="azione" testo={t('common.tabella')}  icona="FaPlus" />
                            </div>

                            <div onClick={() => setBottoniTipoFattore(!bottoniTipoFattore)}>
                                <BottoneCanvasGenericoAzione tipo="annulla" testo={t('common.annulla')}  />
                            </div>

                        </div>
                    </BottoneCanvasGenerico>
                    :
                    <div onClick={() => setBottoniTipoFattore(!bottoniTipoFattore)}>
                        <BottoneCanvasGenerico>
                            <div className="flex gap-2 items-center">
                                <span>{t('common.aggiungi')}</span>
                                <FaPlus />
                            </div>
                        </BottoneCanvasGenerico>
                    </div>
            }
        </div>
    )
}

export default BottoneAggiungiFattore;
