import { IAttivita } from '../../types';

import { creaStringStandardPerAttivita } from '../../hooks/attivita/creaStringStandardPerAttivita';

export default function factoryAttivitaFattoriESG(attivita: IAttivita) {

    let codiceInterpretato = '';

    switch (attivita.route) {
        case ('FattoreESG.crea'):
            attivita.user_name?
            codiceInterpretato = `${attivita.user_name} ha <span class="azione bg-accent dark:bg-dark-accent">creato</span> il fattore <a href="/app/modulo/fattori_esg/${JSON.parse(attivita.request_corpo)?.codice}" target="_blank">${JSON.parse(attivita.request_corpo)?.nome}</a>`
            :
            codiceInterpretato = `Hai <span class="azione bg-accent dark:bg-dark-accent">creato</span> il fattore <a href="/app/modulo/fattori_esg/${JSON.parse(attivita.request_corpo)?.codice}" target="_blank">${JSON.parse(attivita.request_corpo)?.nome}</a>`
            break;

        case ('FattoreESG.modifica'):
            attivita.user_name?
            codiceInterpretato = `${attivita.user_name} ha <span class="azione bg-orange dark:bg-dark-orange">modifcato</span> il fattore <a href="/app/modulo/fattori_esg/${attivita.uri.split('/')[attivita.uri.split('/').length - 1]}" target="_blank">${attivita.uri.split('/')[attivita.uri.split('/').length - 1]}</a>`
            :
            codiceInterpretato = `Hai <span class="azione bg-orange dark:bg-dark-orange">modifcato</span> il fattore <a href="/app/modulo/fattori_esg/${attivita.uri.split('/')[attivita.uri.split('/').length - 1]}" target="_blank">${attivita.uri.split('/')[attivita.uri.split('/').length - 1]}</a>`
            break;

        case ('FattoreESG.elimina'):
            attivita.user_name?
            codiceInterpretato = `${attivita.user_name} ha <span class="azione bg-red dark:bg-dark-red">eliminato</span> il fattore ${attivita.uri.split('/')[attivita.uri.split('/').length - 1]}`
            :
            codiceInterpretato = `Hai <span class="azione bg-red dark:bg-dark-red">eliminato</span> il fattore ${attivita.uri.split('/')[attivita.uri.split('/').length - 1]}`
            break;

        case ('FattoreESGAgenda2030.collega'):
            attivita.user_name?
            codiceInterpretato = `${attivita.user_name} ha <span class="azione bg-orange dark:bg-dark-orange">collegato</span> ${JSON.parse(attivita.request_corpo)?.obiettivi.length} obiettivi dell'agenda 2030 a ${JSON.parse(attivita.request_corpo)?.fattori.length} fattori ESG`
            :
            codiceInterpretato = `Hai <span class="azione bg-orange dark:bg-dark-orange">collegato</span> ${JSON.parse(attivita.request_corpo)?.obiettivi.length} obiettivi dell'agenda 2030 a ${JSON.parse(attivita.request_corpo)?.fattori.length} fattori ESG`
            break;

        case ('FattoreESGAgenda2030.scollega'):
            attivita.user_name?
            codiceInterpretato = `${attivita.user_name} ha <span class="azione bg-red dark:bg-dark-red">scollegato</span> ${JSON.parse(attivita.request_corpo)?.obiettivi.length} obiettivi dell'agenda 2030 da${JSON.parse(attivita.request_corpo)?.fattori.length} fattori ESG`
            :
            codiceInterpretato = `Hai <span class="azione bg-red dark:bg-dark-red">scollegato</span> ${JSON.parse(attivita.request_corpo)?.obiettivi.length} obiettivi dell'agenda 2030 da${JSON.parse(attivita.request_corpo)?.fattori.length} fattori ESG`
            break;

        default:
            codiceInterpretato = creaStringStandardPerAttivita(attivita)
    }

    return codiceInterpretato;

}
