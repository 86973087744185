import React, { useEffect } from 'react'

//Data
import { useTranslation } from 'react-i18next'
import useOttieniPrivilegioModulo from '../../../hooks/useOttieniPrivilegioModulo'
import { useStores } from '../../../hooks/useStores'
import { IBusinessUnitRuolo } from '../types'
import { UseModuloBusinessUnitRuoliStore } from './context/UseModuloBusinessUnitRuoliStore'
import { observer } from 'mobx-react'

//Components
import BusinessUnitRuoloTile from './components/BusinessUnitRuoloTile'
import AggiungiButton from '../../../components/common/AggiungiButton'
import BusinessUnitRuoloForm from './components/BusinessUnitRuoloForm'
import { FaInfoCircle } from 'react-icons/fa'

const ArchivioBusinessUnitRuoli = observer(() => {

  const privilegio = useOttieniPrivilegioModulo();
  const { t } = useTranslation();
  const { ui } = useStores();
  const { business_unit_ruolo } = UseModuloBusinessUnitRuoliStore();

  useEffect(() => {
    business_unit_ruolo.getListaBusinessUnitRuoli()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    business_unit_ruolo.businessUnitRuoloCreato,
    business_unit_ruolo.businessUnitRuoloModificato,
    business_unit_ruolo.businessUnitRuoloEliminato
  ])

  return (
    <div>

      {
        business_unit_ruolo.listaBusinessUnitRuoli && business_unit_ruolo.listaBusinessUnitRuoli.length === 0 &&
        <h3 className="flex items-center justify-center gap-2 text-2xl my-8 text-text-light">
          <FaInfoCircle /> {t('ModuloBusinessUnit.ArchivioBusinessUnitRuoli.nessunoRuoloPresente')}
        </h3>
      }

      {
        business_unit_ruolo.listaBusinessUnitRuoli && business_unit_ruolo.listaBusinessUnitRuoli.length > 0 &&
        business_unit_ruolo.listaBusinessUnitRuoli.map((ruolo: IBusinessUnitRuolo) => {
          return (
            <BusinessUnitRuoloTile BusinessUnitRuolo={ruolo} />
          )
        })
      }

      {privilegio >= 2 &&
        <div className="my-4" onClick={() => ui.mountAsideModal(<BusinessUnitRuoloForm />)}>
          <AggiungiButton testo={t('ModuloBusinessUnit.ArchivioBusinessUnitRuoli.aggiungiRuolo')} />
        </div>
      }
    </div>
  )
})

export default ArchivioBusinessUnitRuoli;