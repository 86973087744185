import React, { useEffect, useState } from 'react'

//Data
// import { useStores } from '../../hooks/useStores';

//Components
import { useTranslation } from 'react-i18next';
import { BsPeople, BsClipboardPlus } from 'react-icons/bs';
import { VscRequestChanges } from 'react-icons/vsc';
import { BiUser } from 'react-icons/bi';

const PannelloPanoramicaTimeline: React.FC<{
    buttonHandler(event: React.MouseEvent<HTMLButtonElement>) : any,
    testo: string
}> = ({ buttonHandler, testo }) => {

    
    const { t } = useTranslation()

    return (

        <><div className='grid grid-cols-4 mb-4'>
            <div className={`flex flex-col rounded`}>
                <button onClick={buttonHandler} className="panoramicaButtons dark:bg-darkgray w-14 h-14 md:w-20 md:h-20 m-auto mb-4" name="richieste">
                    <VscRequestChanges className={`w-full h-full ${testo === "richieste" ? "panoramicaSvgActive" : "panoramicaSvg  dark:text-dark-text-light"}`} />
                </button>
                <div className={`w-full flex justify-center ${testo === "richieste" ? "panoramicaTimeLineActive" : "panoramicaTimeLine"}`}>
                    <span className={`self-center ${testo === "richieste" ? "panoramicaTimeLineDotsActive" : "panoramicaTimeLineDots"}`}></span>
                </div>
            </div>
            <div className={`flex flex-col rounded`}>
                <button onClick={buttonHandler} className="panoramicaButtons dark:bg-darkgray w-14 h-14 md:w-20 md:h-20 m-auto mb-4" name="utenti">
                    <BiUser className={`w-full h-full  ${testo === "utenti" ? "panoramicaSvgActive" : "panoramicaSvg dark:text-dark-text-light"}`} />
                </button>
                <div className={`w-full h-0 flex justify-center ${testo === "utenti" ? "panoramicaTimeLineActive" : "panoramicaTimeLine"} `}>
                    <span className={`self-center ${testo === "utenti" ? "panoramicaTimeLineDotsActive" : "panoramicaTimeLineDots"}`}></span>
                </div>
            </div>
            <div className={`flex flex-col rounded`}>
                <button onClick={buttonHandler} className="panoramicaButtons dark:bg-darkgray w-14 h-14 md:w-20 md:h-20 m-auto mb-4" name="gruppi">
                    <BsPeople className={`w-full h-full  ${testo === "gruppi" ? "panoramicaSvgActive" : "panoramicaSvg dark:text-dark-text-light"}`} />
                </button>
                <div className={`w-full h-0 flex justify-center ${testo === "gruppi" ? "panoramicaTimeLineActive" : "panoramicaTimeLine"} `}>
                    <span className={`self-center ${testo === "gruppi" ? "panoramicaTimeLineDotsActive" : "panoramicaTimeLineDots"}`}></span>
                </div>
            </div>
            <div className={`flex flex-col rounded `}>
                <button onClick={buttonHandler} className="panoramicaButtons dark:bg-darkgray w-14 h-14 md:w-20 md:h-20 m-auto mb-4" name="attivita">
                    <BsClipboardPlus className={`w-full h-full  ${testo === "attivita" ? "panoramicaSvgActive" : "panoramicaSvg dark:text-dark-text-light"}`} />
                </button>
                <div className={`w-full h-0 flex justify-center ${testo === "attivita" ? "panoramicaTimeLineActive" : "panoramicaTimeLine"} `}>
                    <span className={`self-center ${testo === "attivita" ? "panoramicaTimeLineDotsActive" : "panoramicaTimeLineDots"}`}></span>
                </div>
            </div>
        </div>

        <div className='grid grid-cols-4 mb-6'>
                <div className='flex justify-center'>
                    <p className={`${testo === "richieste" ? "font-black text-xl" : "font-black"}`}>{t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.richieste')}</p>
                </div>
                <div className='flex justify-center'>
                    <p className={`${testo === "utenti" ? "font-black text-xl" : "font-black"}`}>{t('ModuloGestioneUtenti.PannelloUtenti.utenti')}</p>
                </div>
                <div className='flex justify-center'>
                    <p className={`${testo === "gruppi" ? "font-black text-xl" : "font-black"}`}>{t('ModuloGestioneUtenti.PannelloUtenti.gruppi')}</p>
                </div>
                <div className='flex justify-center'>
                    <p className={`${testo === "attivita" ? "font-black text-xl" : "font-black"}`}>{t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.attivita')}</p>
                </div>
            </div>
            </>

    )
}
export default PannelloPanoramicaTimeline;