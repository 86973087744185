import React, { useEffect, useState } from "react"
import { IRichiesta } from "../../../types"
import { useTranslation } from 'react-i18next'
import dayjs from "dayjs"


const PanoramicaRichieste: React.FC<{ richiesta: IRichiesta[] }> = ({ richiesta }) => {
    const { t } = useTranslation();

    const numeroRichiesteRicevute = (richieste: IRichiesta[]) => {
        let now = dayjs();
        let now30 = dayjs().subtract(30, 'days');
        let now60 = dayjs().subtract(60, 'days');
        let now90 = dayjs().subtract(90, 'days');
        let filteredDate30: IRichiesta[] = richieste.filter(richiesta => now30 <= dayjs(richiesta.ricevuta_il) && dayjs(richiesta.ricevuta_il) <= now);
        let filteredDate60: IRichiesta[] = richieste.filter(richiesta => now60 <= dayjs(richiesta.ricevuta_il) && dayjs(richiesta.ricevuta_il) <= now);
        let filteredDate90: IRichiesta[] = richieste.filter(richiesta => now90 <= dayjs(richiesta.ricevuta_il) && dayjs(richiesta.ricevuta_il) <= now);
        return <><li className='font-bold text-sm lg:text-lg'>{filteredDate30.length}<span className='text-xs lg:text-base'> Negli ultimi 30 giorni</span></li>
            <li className='font-bold text-sm lg:text-lg'>{filteredDate60.length} <span className='text-xs lg:text-base'> Negli ultimi 60 giorni</span></li>
            <li className='font-bold text-sm lg:text-lg'>{filteredDate90.length} <span className='text-xs lg:text-base'> Negli ultimi 90 giorni</span></li>
        </>

        
    }

    return (
        <ul className='text-sm lg:font-lg'>
            <li className='font-extrabold text-sm lg:text-lg mb-0.5 uppercase'>
                {t('ModuloGestioneUtenti.PannelloRichieste.richiesteRicevute')}
            </li>
            {numeroRichiesteRicevute(richiesta)}
        </ul>
    )
}

export default PanoramicaRichieste;
