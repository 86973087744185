import React from 'react'

import { INotizia } from '../../../types'
import { useTranslation } from 'react-i18next'
import { useStores } from '../../../hooks/useStores';

import { Link } from 'react-router-dom'
import { FaLink } from 'react-icons/fa';

const TileNotiziaDashboard: React.FC<{ notizia: INotizia }> = ({ notizia }) => {

    const { t } = useTranslation();

    const { ui } = useStores()
    
    return (
        <div>
              <Link to={`/app/notizia/${notizia.id}`} className="overflow-hidden flex flex-row-reverse flex-col rounded-sm grow shadow-xl flex-1 text-text dark:text-dark-text">
                {notizia.in_evidenza &&
                
                    <div className={`bg-white dark:bg-black p-5 rounded-sm shadow-xl relative ${notizia.in_evidenza && 'border-4 border-accent dark:border-dark-accent'}`}>

                        <div className="py-1 px-1 text-white bg-accent dark:bg-dark-accent absolute top-0 right-0">
                            <p className="text-sm"
                                onMouseEnter={() => ui.mountFollowMouse(<p>{notizia.titolo}</p>)}
                                onMouseLeave={() => ui.unmountFollowMouse()}
                            >
                                {t('ModuloNotizie.State.featured')}
                            </p>
                        </div>
                        <div className="flex items-enter justify-between">
                            <h3 className="font-bold text-lg mb-2 text-accent dark:text-dark-accent">{notizia.titolo}</h3>
                            <FaLink />
                        </div>
                        {notizia.sotto_titolo && <p>{notizia.sotto_titolo}</p>}
                    </div>

                }{!notizia.in_evidenza &&

                    <div className="bg-white dark:bg-darkgray-dd p-5 grow">
                        <div className="flex items-enter justify-between">
                            <h3 className="font-bold text-lg mb-2 text-accent dark:text-dark-accent">{notizia.titolo}</h3>
                            <FaLink />
                        </div>
                        {notizia.sotto_titolo && <p>{notizia.sotto_titolo}</p>}
                    </div>

                }
            </Link>

        </div>
    )
}

export default TileNotiziaDashboard;