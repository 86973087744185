import './App.css';

//Data
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { observer } from 'mobx-react-lite'
import { useStores } from './hooks/useStores'
import CambioPagina from './hooks/CambioPagina'

//Hooks
import useSettaUtente from './hooks/useSettaUtente'
import useSettaLingua from './hooks/useSettaLingua'
import useSettaTema from './hooks/useSettaTema'
import useSettaDebug from './hooks/useSettaDebug'
import useSettaAxios from './hooks/useSettaAxios'
import useUiResize from './hooks/useUiResize'
import useIntercettaUtenteNonAutenticato from './hooks/useIntercettaUtenteNonAutenticato'

//Templates
import { Unlogged } from './pages/templates/Unlogged'
import { Logged } from './pages/templates/Logged'

//Autenticazione
import Login from './pages/Login'
import Signup from './pages/Signup'
import RichiediResetPassword from './pages/RichiediResetPassword'
import ImpostaNuovaPassword from './pages/ImpostaNuovaPassword'
import CompletaAccount from './pages/CompletaAccount'
import Notizia from './pages/Notizia';


//Misc
import Contatti from './pages/Contatti'
import Pagina404 from './pages/Pagina404'
import AzureAutenticazione from './pages/AzureAutenticazione'
import TerminiCondizioni from './pages/TerminiCondizioni'
import FollowMouse from './components/common/followMouse/FollowMouse'
import FollowMouseModal from './components/common/followMouse/FollowMouseModal';
import DebugModal from './components/common/DebugModal'

//App
import Attivita from './pages/Attivita'
import Profilo from './pages/Profilo'


//Moduli
import Modulo from './pages/Modulo'

import ArchivioAttivitaPanello from './moduli/amministrazioneUtentiAccessi/attivita/ArchivioAttivitaPanello'

import Dashboard from './moduli/UiModuloDashboard/Dashboard'
import ListaModuli from './moduli/UiModuloDashboard/ListaModuli'
import ArchivioNotizie from './moduli/notizie/ArchivioNotizie';
import NotiziaForm from './moduli/notizie/NotiziaForm';

import ArchivioBusinessUnits from './moduli/UiModuloBusinessUnit/ArchivioBusinessUnits'
import SchedaBusinessUnit from './moduli/UiModuloBusinessUnit/components/SchedaBusinessUnit'
import ArchivioBusinessUnitRuoli from './moduli/UiModuloBusinessUnit/UiModuloBusinessUnitRuoli/ArchivioBusinessUnitRuoli';
import ArchivioBusinessUnitCategorie from './moduli/UiModuloBusinessUnit/UiModuloBusinessUnitCategorie/ArchivioBusinessUnitCategorie';

import GestioneUtenti from './moduli/gestioneUtenti/GestioneUtenti'
import GestioneModuli from './moduli/gestioneModuli/GestioneModuli'
import GestionePrivilegi from './moduli/gestionePrivilegi/GestionePrivilegi'
import GestioneUnitaMisura from './moduli/gestioneUnitaMisura/GestioneUnitaMisura'
import GestioneLingue from './moduli/gestioneLingue/GestioneLingue'

//Modulo fattori ESG
import ArchivioStandardESG from './moduli/UiModuloFattoriESG/pagineFattoriESG/ArchivioStandardESG'
import SchedaFattoreESG from './moduli/UiModuloFattoriESG/pagineFattoriESG/SchedaFattoreESG'

//Modulo bilanci
import ArchivioBilanci from './moduli/UiModuloFattoriESG/pagineBilanci/ArchivioBilanci'

//Sezioni bilancio
import SchedaBilancio from './moduli/UiModuloFattoriESG/pagineBilanci/SchedaBilancio'
import SchedaBilancioDashboard from './moduli/UiModuloFattoriESG/pagineBilanci/SezioniSchedaBilancio/SchedaBilancioDashboard';
import SchedaBilancioFattoriESG from './moduli/UiModuloFattoriESG/pagineBilanci/SezioniSchedaBilancio/SchedaBilancioFattoriESG';
import SchedaBilancioTemi from './moduli/UiModuloFattoriESG/pagineBilanci/SezioniSchedaBilancio/SchedaBilancioTemi';
import SchedaBilancioObiettiviAgenda2030 from './moduli/UiModuloFattoriESG/pagineBilanci/SezioniSchedaBilancio/SchedaBilancioObiettiviAgenda2030';
import SchedaBilancioBusinessUnits from './moduli/UiModuloFattoriESG/pagineBilanci/SezioniSchedaBilancio/SchedaBilancioBusinessUnits';
import SchedaBilancioGruppi from './moduli/UiModuloFattoriESG/pagineBilanci/SezioniSchedaBilancio/SchedaBilancioGruppi';

//Schede Dettagli items nel bilancio
import DettagliFattoreESGNelBilancio from './moduli/UiModuloFattoriESG/pagineBilanci/DettagliFattoreESGNelBilancio'
import DettagliTemaMaterialitaNelBilancio from './moduli/UiModuloFattoriESG/pagineBilanci/DettagliTemaMaterialitaNelBilancio';
import DettagliGruppoNelBilancio from './moduli/UiModuloFattoriESG/pagineBilanci/DettagliGruppoNelBilancio';
import DettagliBusinessUnitNelBilancio from './moduli/UiModuloFattoriESG/pagineBilanci/DettagliBusinessUnitNelBilancio';
import DettagliObiettivoAgenda2030NelBilancio from './moduli/UiModuloFattoriESG/pagineBilanci/DettagliObiettivoAgenda2030NelBilancio';

//Archivi bilanci
import ArchivioBilanciFattori from './moduli/UiModuloFattoriESG/pagineBilanci/UiModuloBilanciFattori/ArchivioBilanciFattori';
import ArchivioBilanciFattoriBusinessUnits from './moduli/UiModuloFattoriESG/pagineBilanci/UiModuloBilanciFattoriBusinessUnits/ArchivioBilanciFattoriBusinessUnits';
import ArchivioRendicontazioneBilanci from './moduli/UiModuloFattoriESG/pagineBilanci/UiModuloRendicontazioneBilanci/pagine/ArchivioRendicontazioneBilancio';

//Rendicontazione
import SchedaRendicontazioneBilancio from './moduli/UiModuloFattoriESG/pagineBilanci/UiModuloRendicontazioneBilanci/pagine/SchedaRendicontazioneBilancio';
import SchedaRendicontazioneBilancioBusinessUnit from './moduli/UiModuloFattoriESG/pagineBilanci/UiModuloRendicontazioneBilanci/pagine/SchedaRendicontazioneBilancioBusinessUnit';
import ArchivioBilanciTemiMaterialita from './moduli/UiModuloFattoriESG/pagineBilanci/UiModuloBilanciTemiMaterialita/ArchivioBilanciTemiMaterialita';
import SchedaBilancioTemaMaterialita from './moduli/UiModuloFattoriESG/pagineFattoriESG/UiModuloTemaMaterialita/SchedaBilancioTemaMaterialita';

//Modulo temi di materialità
import SchedaTemaMaterialita from './moduli/UiModuloFattoriESG/pagineFattoriESG/UiModuloTemaMaterialita/SchedaTemaMaterialita'
import ArchivioMaterialita from './moduli/UiModuloFattoriESG/pagineFattoriESG/UiModuloTemaMaterialita/ArchivioMaterialita'

//Modulo agenda 2030
import ArchivioObiettiviAgenda2030 from './moduli/UiModuloFattoriESG/pagineFattoriESG/UiModuloAgenda2030/ArchivioObiettiviAgenda2030';
import SchedaObiettivoAgenda2030 from './moduli/UiModuloFattoriESG/pagineFattoriESG/UiModuloAgenda2030/SchedaObiettivoAgenda2030';
import Toast from './components/common/Toast';

import AggiornamentoContatti from './pages/AggiornamentoContatti';
import PannelloUtenti  from './moduli/gestioneUtenti/PannelloUtenti';
import PannelloRichieste from './moduli/gestioneUtenti/PannelloRichieste';
import { PannelloGruppi } from './moduli/gestioneUtenti/PannelloGruppi';
import PannelloPanoramicaUtenti from './moduli/gestioneUtenti/PannelloPanoramicaUtenti';

export const App = observer(() => {

  //Setta comportamento di default di axios
  useSettaAxios();

  //Se è presente un utente nello store popola lo stato
  useSettaUtente();

  //Setta la lingua di default
  useSettaLingua();

  //Setta tema se presente nel localstorage
  useSettaTema();

  //Setta debug mode se presente nel localstorage
  useSettaDebug();

  //Ascolta sul resizing e resetta l'ui su breakpoint
  useUiResize();

  //Effettura il logout quando le api ritornano 401
  useIntercettaUtenteNonAutenticato();

  const { theme, ui, moduli } = useStores();

  return (
    <div className={theme.theme}>
      <div className="bg-lightgray dark:bg-darkgray text-text dark:text-dark-text font-sans w-screen overflow-hidden">
        <BrowserRouter>
          <CambioPagina>
            <Routes>
              {/* unlogged user routes */}
              <Route path="/" element={<Unlogged />}>

                {/* autenticazione */}
                <Route index element={<Login />} />
                <Route path="signup" element={<Signup />} />
                <Route path="richiedi-reset-password" element={<RichiediResetPassword />} />
                <Route path="reimposta-password" element={<ImpostaNuovaPassword />} />
                <Route path="completa-account" element={<CompletaAccount />} />
                <Route path="aggiorna-contatti" element={<AggiornamentoContatti />} />

                {/* misc */}
                <Route path="contatti" element={<Contatti />} />
                <Route path="informativa-dati-termini-uso" element={<TerminiCondizioni />} />
              </Route>

              {/* Notizie visibili dalla pagina di login */}
              <Route path="notizia/login/:notizia_id" element={<Notizia />} />

              {/* logged user routes */}
              <Route path="app" element={<Logged />}>
                <Route path="modulo" element={<Modulo titolo={moduli.moduloAttivo?.nome} descrizione={moduli.moduloAttivo?.descrizione} />}>

                  <Route path="dashboard">
                    <Route index element={<Dashboard />} />
                    <Route path="lista-moduli" element={<ListaModuli />} />
                  </Route>

                  <Route path="notizie">
                    <Route index element={<ArchivioNotizie />} />
                    <Route path="bozze" element={<ArchivioNotizie />} />
                    <Route path="archivate" element={<ArchivioNotizie />} />
                    <Route path="crea">
                      <Route index element={<NotiziaForm />} />
                      <Route path=":notizia_id" element={<NotiziaForm />} />
                    </Route>
                  </Route>

                  <Route path="business_unit">
                    <Route index element={<ArchivioBusinessUnits />} />
                    <Route path=":business_unit_nome" element={<SchedaBusinessUnit />} />
                  </Route>

                  <Route path="business_unit_ruoli" element={<ArchivioBusinessUnitRuoli />} />

                  <Route path="business_unit_categorie" element={<ArchivioBusinessUnitCategorie />} />

                  <Route path="amministrazione_utenti_e_accessi" element={<PannelloPanoramicaUtenti />} />
                  <Route path="/app/modulo/gestione_utenti" element={<PannelloUtenti/>}/>
                  <Route path="/app/modulo/gestione_gruppi" element={<PannelloGruppi/>}/>
                  <Route path="/app/modulo/controllo_accessi" element={<PannelloRichieste/>}/>
                  <Route path="/app/modulo/monitoraggio_attivita" element={<ArchivioAttivitaPanello/>}/>

                  <Route path="gestione_privilegi" element={<GestionePrivilegi />} />
                  <Route path="gestione_moduli" element={<GestioneModuli />} />
                  <Route path="gestione_unita_misura" element={<GestioneUnitaMisura />} />
                  <Route path="gestione_lingue" element={<GestioneLingue />} />

                  <Route path="bilanci">
                    <Route index element={<ArchivioBilanci />} />
                    <Route path=":bilancio_anno" element={<SchedaBilancio />}>
                      <Route path="dashboard" element={<SchedaBilancioDashboard />} />
                      <Route path="fattori_esg" element={<SchedaBilancioFattoriESG />} />
                      <Route path="temi_materialita" element={<SchedaBilancioTemi />} />
                      <Route path="obiettivi_agenda2030" element={<SchedaBilancioObiettiviAgenda2030 />} />
                      <Route path="business_units" element={<SchedaBilancioBusinessUnits />} />
                      <Route path="gruppi" element={<SchedaBilancioGruppi />} />
                    </Route>

                    <Route path=":bilancio_anno/fattori_esg/:fattore_codice" element={<DettagliFattoreESGNelBilancio />} />
                    <Route path=":bilancio_anno/temi_materialita/:tema_codice" element={<DettagliTemaMaterialitaNelBilancio />} />
                    <Route path=":bilancio_anno/obiettivi_agenda2030/:obiettivo_id" element={<DettagliObiettivoAgenda2030NelBilancio />} />
                    <Route path=":bilancio_anno/business_units/:business_unit_nome" element={<DettagliBusinessUnitNelBilancio />} />
                    <Route path=":bilancio_anno/gruppi/:gruppo_codice" element={<DettagliGruppoNelBilancio />} />
                  </Route>

                  <Route path="bilanci_fattori">
                    <Route index element={<ArchivioBilanciFattori />} />
                    <Route path=":bilancio_anno" element={<SchedaBilancio />}>
                      <Route path="fattori_esg" element={<SchedaBilancioFattoriESG />} />
                      <Route path="temi_materialita" element={<SchedaBilancioTemi />} />
                      <Route path="obiettivi_agenda2030" element={<SchedaBilancioObiettiviAgenda2030 />} />
                      <Route path="business_units" element={<SchedaBilancioBusinessUnits />} />
                      <Route path="gruppi" element={<SchedaBilancioGruppi />} />
                    </Route>

                    <Route path=":bilancio_anno/fattori_esg/:fattore_codice" element={<DettagliFattoreESGNelBilancio />} />
                    <Route path=":bilancio_anno/temi_materialita/:tema_codice" element={<DettagliTemaMaterialitaNelBilancio />} />
                    <Route path=":bilancio_anno/obiettivi_agenda2030/:obiettivo_id" element={<DettagliObiettivoAgenda2030NelBilancio />} />
                    <Route path=":bilancio_anno/business_units/:business_unit_nome" element={<DettagliBusinessUnitNelBilancio />} />
                    <Route path=":bilancio_anno/gruppi/:gruppo_codice" element={<DettagliGruppoNelBilancio />} />
                  </Route>

                  <Route path="bilanci_fattori_business_units">
                    <Route index element={<ArchivioBilanciFattoriBusinessUnits />} />
                    <Route path=":bilancio_anno" element={<SchedaBilancio />}>
                      <Route path="fattori_esg" element={<SchedaBilancioFattoriESG />} />
                      <Route path="temi_materialita" element={<SchedaBilancioTemi />} />
                      <Route path="obiettivi_agenda2030" element={<SchedaBilancioObiettiviAgenda2030 />} />
                      <Route path="business_units" element={<SchedaBilancioBusinessUnits />} />
                      <Route path="gruppi" element={<SchedaBilancioGruppi />} />
                    </Route>

                    <Route path=":bilancio_anno/fattori_esg/:fattore_codice" element={<DettagliFattoreESGNelBilancio />} />
                    <Route path=":bilancio_anno/temi_materialita/:tema_codice" element={<DettagliTemaMaterialitaNelBilancio />} />
                    <Route path=":bilancio_anno/obiettivi_agenda2030/:obiettivo_id" element={<DettagliObiettivoAgenda2030NelBilancio />} />
                    <Route path=":bilancio_anno/business_units/:business_unit_nome" element={<DettagliBusinessUnitNelBilancio />} />
                    <Route path=":bilancio_anno/gruppi/:gruppo_codice" element={<DettagliGruppoNelBilancio />} />

                  </Route>

                  <Route path="bilanci_materialita">
                    <Route index element={<ArchivioBilanciTemiMaterialita />} />
                    <Route path=":bilancio_anno" element={<SchedaBilancio />}>
                      <Route path="fattori_esg" element={<SchedaBilancioFattoriESG />} />
                      <Route path="temi_materialita" element={<SchedaBilancioTemi />} />
                      <Route path="obiettivi_agenda2030" element={<SchedaBilancioObiettiviAgenda2030 />} />
                      <Route path="business_units" element={<SchedaBilancioBusinessUnits />} />
                      <Route path="gruppi" element={<SchedaBilancioGruppi />} />
                    </Route>

                    <Route path=":bilancio_anno/fattori_esg/:fattore_codice" element={<DettagliFattoreESGNelBilancio />} />
                    <Route path=":bilancio_anno/temi_materialita/:tema_codice" element={<DettagliTemaMaterialitaNelBilancio />} />
                    <Route path=":bilancio_anno/obiettivi_agenda2030/:obiettivo_id" element={<DettagliObiettivoAgenda2030NelBilancio />} />
                    <Route path=":bilancio_anno/business_units/:business_unit_nome" element={<DettagliBusinessUnitNelBilancio />} />
                    <Route path=":bilancio_anno/gruppi/:gruppo_codice" element={<DettagliGruppoNelBilancio />} />

                  </Route>

                  <Route path="rendicontazione_bilanci">
                    <Route index element={<ArchivioRendicontazioneBilanci />} />
                    <Route path=":bilancio_anno" element={<SchedaRendicontazioneBilancio />} >
                      <Route path=":business_unit_nome" element={<SchedaRendicontazioneBilancioBusinessUnit />} />
                    </Route>
                  </Route>

                  <Route path="fattori_esg">
                    <Route index element={<ArchivioStandardESG />} />
                    <Route path=":fattore_codice" element={<SchedaFattoreESG />} />
                  </Route>

                  <Route path="fattori_esg_agenda_2030">
                    <Route index element={<ArchivioStandardESG />} />
                    <Route path=":fattore_codice" element={<SchedaFattoreESG />} />
                  </Route>

                  <Route path="materialita">
                    <Route index element={<ArchivioMaterialita />} />
                    <Route path=":tema_materialita_codice" element={<SchedaTemaMaterialita />} />
                    <Route path=":tema_materialita_codice/bilancio/:bilancio_anno" element={<SchedaBilancioTemaMaterialita />} />
                  </Route>

                  <Route path="agenda_2030">
                    <Route index element={<ArchivioObiettiviAgenda2030 />} />
                    <Route path=":obiettivo_id" element={<SchedaObiettivoAgenda2030 />} />
                  </Route>


                
                </Route>

                {/* Static pages */}
                <Route path="attivita" element={<Attivita />} />
                <Route path="profilo" element={<Profilo />} />
                <Route path="notizia/:notizia_id" element={<Notizia />} />
              </Route>

              <Route path="*" element={<Pagina404 />} />
              <Route path="oauth_authentication" element={<AzureAutenticazione />} />

            </Routes>
          </CambioPagina>
        </BrowserRouter>
      </div>

      {ui.dialogue && ui.dialogueComponent}
      {ui.followMouse && <FollowMouse />}
      {ui.followMouseModal && <FollowMouseModal />}

      {ui.debug && <DebugModal />}

      {ui.toast && <Toast data={ui.toast} />}

    </div>
  );
})

export default App;