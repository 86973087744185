import axios from 'axios'
import { config } from '../../../config'
import { IPrivilegio } from '../../../types'

//Riceve lista privilegi senza parent
export const getPrivilegi = () => {
    return axios.get(`${config.API_URI}privilegi`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceve singolo privilegio
export const getPrivilegio = (valore: number) => {
    return axios.get(`${config.API_URI}privilegio/${valore}`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Crea un privilegio
export const creaPrivilegio = (privilegio: IPrivilegio) => {
    return axios.post(`${config.API_URI}privilegio`, {
        valore: privilegio.valore,
        nome: privilegio.nome,
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Modifica un privilegio
export const modificaPrivilegio = (privilegio: IPrivilegio) => {
    return axios.put(`${config.API_URI}privilegio/${privilegio.valore_originale ?? privilegio.valore}`, {
        valore: privilegio.valore,
        nome: privilegio.nome,
        valore_originale: privilegio.valore_originale,
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Elimina un privilegio
export const eliminaPrivilegio = (valore: number) => {
    return axios.delete(`${config.API_URI}privilegio/${valore}`, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Ottiene lista privilegi per modulo
export const getPrivilegiModulo = (modulo_codice: string) => {
    return axios.get(`${config.API_URI}privilegi/modulo/${modulo_codice}`, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Crea un privilegio
export const attribuisciPrivilegiModulo = (modulo_codice: string, privilegi: []) => {
    return axios.put(`${config.API_URI}privilegi/modulo/${modulo_codice}/attribuisci`, {
        privilegi: privilegi
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}