import React from 'react'

//Data
import { IObiettivoAgenda2030 } from '../../../types'
import useOttieniPrivilegioModulo from '../../../../../hooks/useOttieniPrivilegioModulo'
import { useStores } from '../../../../../hooks/useStores'

//Components
// import TemaMaterialitaContextMenu from './TemaMaterialitaContextMenu'
import { FaEllipsisV } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import ObiettivoAgenda2030ContextMenu from './ObiettivoAgenda2030ContextMenu'
import { config } from '../../../../../config'

const ObiettivoAgenda2030Tile: React.FC<{ obiettivo: IObiettivoAgenda2030, modifica?: boolean }> = ({ obiettivo, modifica = false }) => {

  const { moduli } = useStores()
  const privilegio = useOttieniPrivilegioModulo()
  const [contextMenu, setContextMenu] = React.useState(false)


  const toggleContextMenu = () => {
    //Il timeout permette di far comparire il context menu dopo che l'utente ha cliccato
    setTimeout(() => {
      setContextMenu(!contextMenu)
    }, 1)
  }

  document.addEventListener('click', () => {
    if (contextMenu) {
      setContextMenu(false)
    }
  }, { once: true })

  return (
    <div className="flex flex-col md:flex-row">

      <div
        className="p-5 flex items-center justify-center"
        style={{ backgroundColor: obiettivo.colore }}
      >
        <img
          className="max-w-[100px]"
          alt={obiettivo.descrizione}
          src={`${config.BACKEND_URI}icone_obiettivi_2030/${obiettivo.immagine}`}
        />
      </div>

      <div className="overflow-hidden bg-white dark:bg-darkgray-dd shadow-xl rounded-sm p-5">
        <div className="flex items-start justify-between">
          <div>
            <Link to={`/app/modulo/${moduli.moduloAttivo?.codice}/${obiettivo.id}`} className="flex items-center gap-2">
              <h2 className="font-bold hover:underline whitespace-pre-wrap text-xl">{obiettivo.nome}</h2>
            </Link>
          </div>


          {
            privilegio >= 3 && modifica &&
            <div className="cursor-pointer relative p-1" onClick={() => toggleContextMenu()}>
              <FaEllipsisV className="hover:opacity-70" />

              {contextMenu && <ObiettivoAgenda2030ContextMenu obiettivo={obiettivo} />}

            </div>
          }
        </div>

        <hr className="my-2 border border-lightgray-d" />
        <p>{obiettivo.descrizione}</p>
      </div>

    </div>
  )
}

export default ObiettivoAgenda2030Tile;
