import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaInfoCircle } from 'react-icons/fa'
import { IFattoreESG } from '../../types'
import GraficoBarreFattoreBilanci from './GraficoBarreFattoreBilanci'
import IndicatoreTestualeFattoreBilancio from './IndicatoreTestualeFattoreBilancio'

const IndicatoriSchedaFattoriESG: React.FC<{ fattore: IFattoreESG }> = ({ fattore }) => {
  const { t } = useTranslation();

  let listaUM: string[] = []

  if (fattore.indicatori_numerici) {
    listaUM = fattore.indicatori_numerici.map(ind => ind.unita_misura as string)
    listaUM = listaUM.filter((item, index) => listaUM.indexOf(item) === index)
  }

  return (
    <div className="flex flex-col gap-4">

      {fattore.indicatori_numerici && fattore.indicatori_numerici.length === 0 &&
        fattore.indicatori_testuali && fattore.indicatori_testuali.length === 0 &&
        <div>
          <p className="opacity-70 flex flex-col items-center gap-2 text-center mt-4">
            <FaInfoCircle size={20} />
            <span className="text-2xl">Questo fattore non è ancora stato rendicontato in nessun bilancio</span>
          </p>
        </div>
      }
      
      {/* Grafici indicatori testuali */}
      {fattore.indicatori_testuali && fattore.indicatori_testuali.length > 0 &&
        <div className="mt-4">
          <div>
            <h2 className="font-bold text-xl">
               {t('common.indicatoriTestuali') }
            </h2>
            {
              fattore.indicatori_testuali.map(indicatore => (
                <div className="mt-4">
                  <IndicatoreTestualeFattoreBilancio indicatore={indicatore} />
                </div>
              ))
            }
          </div>
        </div>
      }

      {/* Grafici indicatori numerici */}
      {fattore.indicatori_numerici && fattore.indicatori_numerici.length > 0 &&
        <div className="mt-4">
          <h2 className="font-bold text-xl">
          {t('common.indicatoriNumerici') }
          </h2>
          <div className="flex flex-col gap-2">
            {listaUM.map((UM) => (
              <div key={UM}>
                <GraficoBarreFattoreBilanci fattore={fattore} UM={UM} />
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  )
}

export default IndicatoriSchedaFattoriESG;
