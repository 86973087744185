import { it_modulo_gestione_lingue } from "../moduli/gestioneLingue/locales/it"
import { it_modulo_gestione_moduli } from "../moduli/gestioneModuli/locales/it"
import { it_modulo_gestione_privilegi } from "../moduli/gestionePrivilegi/locales/it"
import { it_modulo_gestione_unita_misura } from "../moduli/gestioneUnitaMisura/locales/it"
import { it_modulo_gestione_utenti } from "../moduli/gestioneUtenti/locales/it"
import { it_modulo_notizie } from "../moduli/notizie/locales/it"
import { it_business_unit } from "../moduli/UiModuloBusinessUnit/locales/it"
import { it_dashboard } from "../moduli/UiModuloDashboard/locales/it"
import { it_fattori_esg } from "../moduli/UiModuloFattoriESG/locales/it"

export const it = {

  common: {
    ciao: 'Ciao',
    salva: 'salva',
    modifica: 'Modifica',
    annulla: 'annulla',
    chiudi: 'chiudi',
    conferma: 'conferma',
    crea: 'crea',
    rimani: 'rimani',
    richiediAccesso: 'Richiedi l\'accesso',
    tornaLogin: 'Torna al login',
    password: 'Password',
    fornisciPassword: 'Fornisci una password',
    ripetiLaPassword: 'Ripeti la password',
    nuovaPassword: 'Nuova password',
    nome: 'Nome',
    fornisciNome: 'Fornisci un nome',
    cognome: 'Cognome',
    fornisciCognome: 'Fornisci un cognome',
    eMail: 'E-mail',
    fornisciEmail: 'Fornisci un\'e-mail',
    azienda: 'Azienda',
    specificaAzienda: 'Specifica l\'azienda',
    telefono: 'Telefono',
    fornisciTelefone: 'Fornisci un numero di telefono',
    telefonoMessagio: 'Dev\'essere un numero di telefono valido',
    login: 'Login',
    codice: 'Codice',
    fornisciCodice: 'Fornisci un codice',
    descrizione: 'Descrizione',
    descrizioneEstesa: 'Descrizione estesa',
    fornisciDescrizione: 'Fornisci una descrizione',
    attivo: 'attivo',
    disattivo: 'disattivo',
    valore: 'Valore',
    fornisciValore: 'Fornisci un valore',
    salvaModifica: 'Salva modifiche',
    modificaSalvato: 'Modifiche salvate con successo',
    elimina: 'Elimina',
    aggiungiGruppo: 'Aggiungi nuovo gruppo',
    cerca: 'Cerca',
    gruppo: 'Gruppo',
    gruppi: 'Gruppi',
    unitaMisura: 'Unità di misura',
    categoria: 'Categoria',
    categorie: 'Categorie',
    fattore: 'Fattore',
    fattori: 'Fattori',
    tabella: 'Tabella',
    tabelle: 'Tabelle',
    aggiungi: 'Aggiungi',
    filtraFattori: 'Filtra fattori',
    nomeEsteso: 'Nome esteso',
    tipo: 'Tipo',
    tipo_indicatore: 'Tipologia indicatore',
    indicatoriNumerici: 'Indicatori numerici',
    indicatoriTestuali: 'Indicatori testuali',
    vaiAllaTabella: 'Vai alla tabella',
    businessUnit: 'Business Unit',
    anno: 'Anno',
    perAnno: 'per anno',
    perBusinessUnit: 'per business unit',
    conUnitaMisura: 'con unità di misura',
    nascondi: 'Nascondi',
    mostra: 'Mostra',
    scegli: 'Scegli',
    bandiera: 'bandiera',
    prodottoDi: 'è un prodotto di',
    aggiungiNuovoBilancio: 'Aggiungi nuovo bilancio',
    statistiche: 'Statistiche',
    disclaimerModificheNonSalvate: 'Perderai eventuali modifiche non salvate, sei sicuro?',
    si: 'Si',
    no: 'No',
    lingua: 'Lingua',
    cambiaLingua: 'Cambia lingua',
    mostraTutti: 'Mostra tutti',
    nonRendicontati: 'Non rendicontati',
    rendicontati: 'Rendicontati',
    validati: 'Validati',
    statoRendicontazione: 'Stato rendicontazione',
    filtra: 'Filtra',
    selezionaValore: 'Seleziona un valore',
    nessunFattoreTrovato: 'Nessun fattore trovato',
    paese: 'Paese',
    a: 'a',
    quantita: 'Quantità',
    erroreGenerico: 'Errore generico',
    nessuna: 'Nessuna',
    profilo: 'Profilo',
    attivita: 'Attività',
    facoltativo: 'Facoltativo',
    scarica: 'Scarica',
    passwordRegex: 'La password deve essere minimo di 12 caratteri, avere un numero, una maiuscola, una minuscola e un carattere speciale.',
    valida: 'Valida',
    rimuoviValidazione: 'Rimuovi validazione',
    utenti:'Utenti',
    utente:'Utente',
    nuovaEmail:'Nuova E-mail',
    ripetiNuovaEmail:'Ripeti nuova E-mail',
    contenutiMultilingua: 'Questi contenuti possono essere creati e letti in più lingue.',
    dateFormat: 'DD MMMM, YYYY HH:mm',
  },

  tabellaInterattiva: {
    elementiPerPagina: 'Elementi per pagina',
    elementiDa: 'Elementi da',
    visualizzatiSu: 'visualizzati su',
    paginaSuccessiva: 'Pagina successiva',
    paginaPrecedente: 'Pagina precedente',
    filtraTabella: 'Filtra risultati',
    nessunDatoPresente: 'Nessun dato presente',
    mostraPiu: 'Mostra di più',
    mostraMeno: 'Mostra meno',
  }, 

  paginaLogin: {
    accessAccount: 'Accedi al tuo account',
    messaggioLoginGenerale: 'È bello rivederti su Ipè!',
    richiediAccesso: 'Richiedi l accesso',
    dimenticatoPassword: 'Hai dimenticato la password?'
  },

  paginaSignup: {
    messaggioTesta: 'Approviamo manualmente e verifichiamo l\'identità di tutti gli utenti che vogliono iscriversi al workspace di Ipè, inserisci i tuoi dati per richiedere l\'apertura del tuo account.',
    giaAccount: 'Hai già un account? ',
    effettuaLogin: 'Effettua il login',
  },

  PaginaCompleteAccount: {
    completeAccount: 'Completa il tuo account',
    richiestaAcettata: 'La tua richiesta di iscrizione al workspace di Ipè è stata accettata! Completa il tuo account con le informazioni richieste per accedere.',
  },
  
  paginaAggiornaContatti: {
    titolo: 'Conferma dei dati di contatto',
    sottotitolo: 'I tuoi dati di contatto sono stati verificati. Effettua il login con la tua nuova e-mail.',
  },

  PaginaContatti: {
    vegaParco: 'VEGA Parco Scientifico e Tecnologico',
    torreHammon: 'Torre Hammon',
    indirizzo: ' Via delle Industrie 5, Marghera (Venezia)',
    testoTel: 'Tel:',
    numero: '+39 041 5093820',
    testoEmail: 'E-mail:',
    email: 'info@eambientegroup.com',
  },

  PaginaImpostaPassword: {
    impostaNuovaPassword: 'Imposta una nuova password',
  },

  Pagina404: {
    errore404: 'Errore 404',
    paginaNonEsiste: 'Questa pagina non esiste',
    tornaIndietro: 'Torna indietro',
    tornaPaginaPricipale: 'Torna alla pagina principale',
  },

  PaginaProfilo: {
    titolo: 'Profilo',
    descrizione: 'Gestisci il tuo profilo personale e le informazioni pubbliche',
    abilitaDebug: 'Abilita debug',
    disabilitaDebug: 'Disabilita debug',
    abilitaBenvenuto: 'Abilita messaggio di benvenuto',
    disabilitaBenvenuto: 'Disabilita messaggio di benvenuto',
  },

  PaginaAttivita: {
    titolo: 'Attività',
    descrizione: 'Storico delle tue attività su Ipè',
    nessunaAttivitaTrovata: 'Nessuna attività trovata per il tuo account',
  },

  PaginaResetPassword: {
    richiediPassword: 'Richiedi reset password',
    mesaggioTesto: 'Puoi recuperare il tuo account facilmente, inserisci la tua e-mail e ti invieremo un link al quale accedere per reimpostare la tua password.',

  },

  FormCompleteAccount: {
    passwordNOnCoincidono: 'Le password non coincidono',
    emailVerificatoConSucesso: 'E-mail verificata con successo',
    ripetiLaPassword: 'Ripeti Password',
    mesaggioSucessco: 'Utente attivato con successo',
    vailalLogin: 'Vai al login',
    erroreNellImpostazione: 'Errore nell\'impostare la password',
    attivaAccediAccount: 'Attiva e accedi al tuo account',
  },

  FormImpostaNuovaPassword: {
    messaggioSucesso: 'Password reimpostata con successo',
  },

  FormIscrizione: {
    messaggioDati: 'Ho preso visione e acconsento al trattamento dei miei dati personali secondo ',
    testoLink: 'l\'informativa dati',
    messaggioSuccesso: 'Grazie per aver richiesto l\'accesso a Ipè, riceverai un\'e-mail quando il tuo account sarà abilitato da un amministratore',
    deviAcconsentire: 'Devi acconsentire al trattamento dati'
  },

  FormResetPassword: {
    messaggioInviaEmail: 'Ti abbiamo inviato un\'email con un link per reimpostare la tua password.',
    recuperaPassword: 'Recupera la password',
  },

  FormCambiaEmail: {
    testoNonPossibileModificare: 'Non è attualmente possibile modificare la propria e-mail.',
    testCambioEmail: 'Cambio e-mail',
    messagioInviatoeamil: 'Ti abbiamo inviato un\'e-mail al nuovo indirizzo con un link per confermare la modifica.',
    richiestaInviata: 'Richiesta inviata',
    richiediCambiaEmail: 'Richiedi cambio e-mail',
  },

  FormCambiaPassword: {
    testoCambiaPassword: 'Cambio password',
    passwordAttuale: 'Password attuale',
    inserisciPasswordAttulae: 'Inserisci la tua password attuale',
    inserisciNuovaPassword: 'Inserisci la nuova password',
    ripetiNuovaPassword: 'Ripeti nuova password',
    passwordAggiornata: 'Password aggiornata',
    aggiornaPassword: 'Aggiorna Password',
  },

  FormModificaProfilio: {
    messaggioAdmin: 'Solo gli amministratori possono modificare i dati personali degli utenti.',
    infoPersonali: 'Informazioni personali',
    dataSalvati: 'Dati salvati',
    salva: 'Salva',
  },

  FormModificaLingua: {
    titolo: 'Preferenze linguistiche',
    bottone: 'Aggiorna lingua',
  },

  ListaModuli: {
    listaDeiModuli: 'Lista dei moduli presenti in Ipè a cui hai accesso',
  },
  
  PaginaAzureAuthenticazione:{
    verificazione: 'Stiamo verificando le tue credenziali per effettuare l\'autenticazione.'
  },
  
  ModuloGestioneUtenti: it_modulo_gestione_utenti,
  ModuloGestioneModuli: it_modulo_gestione_moduli,
  ModuloGestionePrivilegi: it_modulo_gestione_privilegi,
  ModuloGestioneUnitaMisura: it_modulo_gestione_unita_misura,
  ModuloGestioneLingue: it_modulo_gestione_lingue,
  ModuloNotizie: it_modulo_notizie,
  ModuloDashboard: it_dashboard,
  ModuloBusinessUnit: it_business_unit,
  ModuloFattoriEsg: it_fattori_esg,
}