import { observable, action, makeAutoObservable, runInAction } from 'mobx'
import { INotizia } from '../types'
import {
    get,
    crea,
    modifica,
    elimina,
} from '../rest/crud_generica'

export class GestioneNotizieStore {

    lista: INotizia[] = []
    gettingLista: boolean = false
    listaError: string = ''
    listaOttenuta: boolean = false

    modifying: boolean = false
    modificaError: string = ''
    modificato: boolean = false

    creating: boolean = false
    creaError: string = ''
    creata: boolean = false

    trashing: boolean = false
    archiviaError: string = ''
    archiviata: boolean = false

    deleting: boolean = false
    eliminaError: string = ''
    eliminata: boolean = false

    recovering: boolean = false
    ripristinaError: string = ''
    ripristinata: boolean = false

    publishing: boolean = false
    pubblicaError: string = ''
    pubblicata: boolean = false

    drafting: boolean = false
    passaInBozzaError: string = ''
    passataInBozza: boolean = false

    notizia: INotizia | null = null
    getting: boolean = false
    dettagliError: string = ''

    constructor() {
        makeAutoObservable(this, {
            //Lista
            lista: observable,
            gettingLista: observable,
            listaError: observable,
            listaOttenuta: observable,

            getListaPubblicate: action,
            getListaBozze: action,
            getLista_archivate: action,

            //creazione
            creating: observable,
            creaError: observable,
            creata: observable,

            crea: action,

            //modificazione
            modifying: observable,
            modificaError: observable,
            modificato: observable,

            modifica: action,

            //archivia
            trashing: observable,
            archiviaError: observable,
            archiviata: observable,

            archivia: action,

            //Eliminazione
            deleting: observable,
            eliminaError: observable,
            eliminata: observable,

            elimina: action,

            //Ripristino
            recovering: observable,
            ripristinaError: observable,
            ripristinata: observable,

            ripristina: action,

            //Pubblicazione
            publishing: observable,
            pubblicaError: observable,
            pubblicata: observable,

            pubblica: action,

            //Pubblicazione
            drafting: observable,
            passaInBozzaError: observable,
            passataInBozza: observable,

            passaInBozza: action,

            //dettagli
            notizia: observable,
            getting: observable,
            dettagliError: observable,

            dettagli: action,
        })
    }


    crea = async (notizia: INotizia, lingua: string) => {

        this.creating = true

        crea(`notizia`, {
            ...notizia,
            bozza: true
        },
            {
                lingua: lingua
            }
        )
            .then((res) => {
                runInAction(() => {
                    this.creaError = ''
                    this.creata = !this.creata
                    this.notizia = res.data.data
                })

            })
            .catch((err) => {

                runInAction(() => {
                    this.creaError = err.response.data.message ? err.response.data.message : 'Errore generico'
                })

            })
            .finally(() => {
                runInAction(() => this.creating = false)
            })
    }

    modifica = async (id: string, notizia: INotizia, lingua: string) => {

        this.modifying = true

        modifica(
            `notizia/${id}`,
            notizia, {
            lingua: lingua
        })
            .then((res) => {

                runInAction(() => {
                    this.modificaError = ''
                    this.modificato = !this.modificato
                    this.notizia = res.data.data
                })

            })
            .catch((err) => {

                runInAction(() => {
                    this.modificaError = err.response.data.message ? err.response.data.message : 'Errore generico'
                })

            })
            .finally(() => {
                runInAction(() => this.modifying = false)
            })
    }


    getListaPubblicate = async () => {

        this.gettingLista = true

        get('notizie')
            .then((res) => {

                runInAction(() => {
                    this.lista = res.data.data
                    this.listaOttenuta = !this.listaOttenuta
                    this.listaError = ''
                })

            })
            .catch((err) => {

                runInAction(() => {
                    this.listaError = err.response.data.message ? err.response.data.message : 'Errore generico'
                })

            })
            .finally(() => {
                this.gettingLista = false
            })
    }

    getListaBozze = async () => {

        this.gettingLista = true

        get('notizie/bozze')
            .then((res) => {

                runInAction(() => {
                    this.lista = res.data.data
                    this.listaOttenuta = !this.listaOttenuta
                    this.listaError = ''
                })

            })
            .catch((err) => {

                runInAction(() => {
                    this.listaError = err.response.data.message ? err.response.data.message : 'Errore generico'
                })

            })
            .finally(() => {
                this.gettingLista = false
            })
    }

    getLista_archivate = async () => {

        this.gettingLista = true

        get('notizie/archivate')
            .then((res) => {

                runInAction(() => {
                    this.lista = res.data.data
                    this.listaOttenuta = !this.listaOttenuta
                    this.listaError = ''
                })

            })
            .catch((err) => {

                runInAction(() => {
                    this.listaError = err.response.data.message ? err.response.data.message : 'Errore generico'
                })

            })
            .finally(() => {
                this.gettingLista = false
            })
    }

    archivia = async (id: string) => {

        this.deleting = true

        elimina(`notizia/${id}/archivia`)
            .then(() => {

                runInAction(() => {
                    this.eliminaError = ''
                    this.eliminata = !this.eliminata
                })

            })
            .catch((err) => {

                runInAction(() => {
                    this.eliminaError = err.response.data.message ? err.response.data.message : 'Errore generico'
                })

            })
            .finally(() => {
                runInAction(() => this.deleting = false)
            })
    }

    elimina = async (id: string) => {

        this.deleting = true

        elimina(`notizia/${id}/elimina`)
            .then(() => {

                runInAction(() => {
                    this.eliminaError = ''
                    this.eliminata = !this.eliminata
                })

            })
            .catch((err) => {

                runInAction(() => {
                    this.eliminaError = err.response.data.message ? err.response.data.message : 'Errore generico'
                })

            })
            .finally(() => {
                runInAction(() => this.deleting = false)
            })
    }

    ripristina = async (id: string) => {

        this.deleting = true

        crea(`notizia/${id}/ripristina`)
            .then(() => {

                runInAction(() => {
                    this.eliminaError = ''
                    this.eliminata = !this.eliminata
                })

            })
            .catch((err) => {

                runInAction(() => {
                    this.eliminaError = err.response.data.message ? err.response.data.message : 'Errore generico'
                })

            })
            .finally(() => {
                runInAction(() => this.deleting = false)
            })
    }

    pubblica = async (id: string) => {

        this.publishing = true

        crea(`notizia/${id}/pubblica`)
            .then((res) => {

                runInAction(() => {
                    this.pubblicaError = ''
                    this.pubblicata = !this.pubblicata
                    this.notizia = res.data.data
                })

            })
            .catch((err) => {

                runInAction(() => {
                    this.pubblicaError = err.response.data.message ? err.response.data.message : 'Errore generico'
                })

            })
            .finally(() => {
                runInAction(() => this.publishing = false)
            })
    }

    passaInBozza = async (id: string) => {

        this.drafting = true

        crea(`notizia/${id}/bozza`)
            .then((res) => {

                runInAction(() => {
                    this.passaInBozzaError = ''
                    this.passataInBozza = !this.passataInBozza
                    this.notizia = res.data.data
                })

            })
            .catch((err) => {

                runInAction(() => {
                    this.passaInBozzaError = err.response.data.message ? err.response.data.message : 'Errore generico'
                })

            })
            .finally(() => {
                runInAction(() => this.drafting = false)
            })
    }
    
    dettagli = async (id: string,lingua?: string ) => {

        this.getting = true

        get(`notizia/${id}/dettagli`, {
            lingua: lingua
        })
            .then((res) => {

                runInAction(() => {
                    this.notizia = res.data.data
                    this.dettagliError = ''
                })

            })
            .catch((err) => {
                runInAction(() => {
                    this.dettagliError = err.response.data.message ? err.response.data.message : 'Errore generico'
                })

            })
            .finally(() => {
                runInAction(() => this.getting = false)
            })
    }

}