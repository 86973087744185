import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import IpeLogo from '../components/common/IpeLogo'
import { useStores } from '../hooks/useStores'
import { useTranslation } from 'react-i18next'

export default function Pagina404() {
  const { t } = useTranslation();

  const styles = {
    wrapper: "h-screen w-full flex flex-col items-center justify-center gap-2 text-center",
  }

  const navigate = useNavigate()

  const { auth } = useStores()

  return (
    <div className={styles.wrapper} >

      <div className="mb-5 flex flex-col items-center gap-3">

        <IpeLogo width={40} />

        <h1 className="text-5xl font-bold">{t('Pagina404.errore404')}</h1>
        <p>{t('Pagina404.paginaNonEsiste')}</p>
      </div>

      <div className="flex flex-col gap-2">

        {auth.utente &&
          <button onClick={() => navigate(-1)} className="bg-accent text-white p-2 rounded-sm">
            {t('Pagina404.tornaIndietro')}
          </button>
        }


        <Link to="/" className="bg-accent text-white p-2 rounded-sm">
          {t('Pagina404.tornaPaginaPricipale')}
        </Link>
      </div>

    </div>
  )
}
