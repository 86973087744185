import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../../../hooks/useStores'
import { useModuloESGStores } from '../../context/useModuloESGStores'
import { IFattoreESG } from '../../types'
import { observer } from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../../../hooks/useOttieniPrivilegioModulo'

//Components
import FattoreESGForm from './FattoreESGForm'
import Dialogue from '../../../../components/common/Dialogue'

const StandardESGContextMenu: React.FC<{ fattore: IFattoreESG }> = observer(({ fattore }) => {

  const { t } = useTranslation();
  const privilegio = useOttieniPrivilegioModulo()
  const { ui } = useStores()
  const { fattori_esg } = useModuloESGStores()

  const handleEliminaBilancio = () => {
    fattori_esg.eliminaFattoreESG(fattore.codice).then(() => {

      fattori_esg.eliminaFattoreESGError ?
        ui.mountDialogue(<Dialogue testo={fattori_esg.eliminaFattoreESGError} />)
        :
        ui.mountDialogue(<Dialogue testo={ t('ModuloFattoriEsg.standardEsgContextMenu.standardEliminatoSuccesso') } />)

    });
  }

  return (
    <div className="absolute shadow-lg border border-lightgray-dd bg-white dark:bg-black p-3 right-0 dark:border-0">
      <ul className="whitespace-nowrap flex flex-col gap-2">
        {privilegio >= 3 && <li onClick={() => ui.mountAsideModal(<FattoreESGForm tipo_fattore="standard" fattore={fattore} />)}>{t('ModuloFattoriEsg.standardEsgContextMenu.modificaStandard')}</li>}
        {privilegio >= 4 &&
          <li
            className="text-red dark:text-dark-red"
            onClick={() => ui.mountDialogue(
              <Dialogue
                testo={`${ t('ModuloFattoriEsg.standardEsgContextMenu.eliminaStandardEsg') } ${fattore.codice}? ${ t('ModuloFattoriEsg.standardEsgContextMenu.eliminaStandardEsgTesto') }`}
                primaryCta={`${ t('ModuloFattoriEsg.standardEsgContextMenu.eliminaLoStandard') } ${fattore.codice}`}
                primaryCtaColor="bg-red dark:bg-dark-red"
                secondaryCta={ t('common.annulla') }
                secondaryCtaColor="bg-white dark:bg-darkgray-dd !text-text dark:!text-dark-text"
                onConfirm={() => handleEliminaBilancio()}
              />)}
          >
           { t('ModuloFattoriEsg.standardEsgContextMenu.eliminaLoStandard') }
          </li>
        }
      </ul>
    </div>
  )
})

export default StandardESGContextMenu;
