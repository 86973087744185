import React from 'react'

//Data
import { useForm } from 'react-hook-form'
import { IBusinessUnitRuolo } from '../../types'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

//Components
import InfoBox from '../../../../components/common/InfoBox'
import ErrorBox from '../../../../components/common/ErrorBox'
import SpinnerButton from '../../../../components/common/SpinnerButton'
import InputField from '../../../../components/common/form/InputField'
import { UseModuloBusinessUnitRuoliStore } from '../context/UseModuloBusinessUnitRuoliStore'

const BusinessUnitRuoloForm: React.FC<{ ruolo?: IBusinessUnitRuolo }> = observer(({ ruolo }) => {
  const { t } = useTranslation();

  const { business_unit_ruolo } = UseModuloBusinessUnitRuoliStore()

  //Istanzia Hook Form
  const { watch, register, setValue, handleSubmit, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      valore: ruolo ? ruolo.valore : '',
      valore_originale: ruolo ? ruolo.valore : '',
      nome: ruolo ? ruolo.nome : ''
    }
  })

  //Gestisce submit
  const onSubmit = () => {
    ruolo ?

      business_unit_ruolo.modificaBusinessUnitRuolo(watch() as IBusinessUnitRuolo).then(() => {
        setValue('valore_originale', watch('valore') as string)
      })
      :

      business_unit_ruolo.creaBusinessUnitRuolo(watch() as IBusinessUnitRuolo)

  }


  return (
    <div>

      <div className="mb-4">
        <h2 className="text-2xl mb-2">
          {ruolo ?
            `${t('ModuloBusinessUnit.BusinessUnitRuoloForm.ModificaRuolo')} ${ruolo.nome}`
            :
            `${t('ModuloBusinessUnit.BusinessUnitRuoloForm.creaRuolo')}`
          }
        </h2>
        <p className="text-text-light">
          {ruolo ?
            `${t('ModuloBusinessUnit.BusinessUnitRuoloForm.modificaDatiDellaRuoli')} ${ruolo.nome}, ${t('ModuloBusinessUnit.BusinessUnitRuoloForm.messaggioRuoli')}`
            :
            `${t('ModuloBusinessUnit.BusinessUnitRuoloForm.creaRuolo')}`
          }
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>

        <InputField
          label={t('ModuloGestionePrivilegi.Form.Priorita')}
          classi="valore"
          placeholder={t('ModuloGestionePrivilegi.Form.placeholderPriorita')}
          type="number"
          error={errors.valore}
          form={register("valore", { required: t('common.fornisciValore'), min: { value: 1, message: t('ModuloGestionePrivilegi.Form.almenoUno') } })}
        />

        <InputField
          label={t('common.nome')}
          classi="nome"
          placeholder={t('ModuloBusinessUnit.BusinessUnitRuoloForm.placeholderNome')}
          type="text"
          error={errors.nome}
          form={register("nome", { required: t('common.fornisciNome')} )}
        />

        <div className="form">

          {/* Submit modifica ruolo */}
          {ruolo &&

            <>
              <div className="form">
                <div>
                  {!business_unit_ruolo.editingBusinessUnitRuolo ? <input type="submit" value={t('common.salvaModifica')} /> : <SpinnerButton />}
                </div>
              </div>

              {
                isSubmitSuccessful && !business_unit_ruolo.editingBusinessUnitRuolo && (

                  business_unit_ruolo.modificaBusinessUnitRuoloError
                    ?
                    <div className="my-2">
                      <ErrorBox errore={business_unit_ruolo.modificaBusinessUnitRuoloError} />
                    </div>
                    :
                    <div className="my-2">
                      <InfoBox messaggio={t('common.modificaSalvato')} />
                    </div>

                )
              }

            </>
          }

          {/* Submit creazione ruolo */}
          {!ruolo &&

            <>
              {
                (isSubmitSuccessful && !business_unit_ruolo.creaBusinessUnitRuoloError && !business_unit_ruolo.creatingBusinessUnitRuolo) ?
                  <InfoBox messaggio={t('ModuloBusinessUnit.BusinessUnitRuoloForm.ruoloCreatoSucesso')} />
                  :
                  <div className="form pt-4">
                    {!business_unit_ruolo.creatingBusinessUnitRuolo ? <input type="submit" value={t('ModuloBusinessUnit.BusinessUnitRuoloForm.creaRuoloButtone')} /> : <SpinnerButton />}
                  </div>
              }

              {
                business_unit_ruolo.creaBusinessUnitRuoloError &&
                <div className="mb-2">
                  <ErrorBox errore={business_unit_ruolo.creaBusinessUnitRuoloError.toString()} />
                </div>
              }

            </>
          }


        </div>

      </form>
    </div>
  )
})

export default BusinessUnitRuoloForm;