import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useForm } from 'react-hook-form'
import { useStores } from '../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import { IRichiesta } from '../../types'

//Components
import TestoErrore from '../../components/common/TestoErrore'
import InfoBox from '../../components/common/InfoBox'
import ErrorBox from '../../components/common/ErrorBox'
import SpinnerButton from '../../components/common/SpinnerButton'

const RifiutaUtenteForm: React.FC<{ richiesta: IRichiesta }> = observer(({ richiesta }) => {
    const { t } = useTranslation();
    const { gestioneUtenti } = useStores();

    const { watch, register, handleSubmit, formState: { errors, isSubmitSuccessful } } = useForm()

    const onSubmit = () => {
        gestioneUtenti.gestisciRichiesta(richiesta, watch('messaggio'), false)
    }

    return (
        <div>
            <div className="mb-4">
                <h2 className="text-2xl mb-2">{t('ModuloGestioneUtenti.FormRifiutaUtente.rifiutaRichesta')} {richiesta.nome}</h2>
                <p className="text-text-light">{t('ModuloGestioneUtenti.FormRifiutaUtente.rifiutaQuestaRichesta')}</p>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form">
                    <label htmlFor="">{t('ModuloGestioneUtenti.FormRifiutaUtente.ragioneDelRifiuta')}</label>
                    <input {...register("messaggio", { required: t('ModuloGestioneUtenti.FormRifiutaUtente.messaggioRagione') })} placeholder={t('ModuloGestioneUtenti.FormRifiutaUtente.ragioneDelRifiuta')} type="text" />

                    {errors.messaggio && <TestoErrore errore={errors.messaggio.message} />}
                </div>

                <div className="form">

                    {
                        (isSubmitSuccessful && !gestioneUtenti.modificaRichiestaError && !gestioneUtenti.editingRichiesta) ?
                            <InfoBox messaggio={t('ModuloGestioneUtenti.FormRifiutaUtente.statoRifuitato')} />
                            :
                            <div>
                                {!gestioneUtenti.editingRichiesta ? <input type="submit" value={t('ModuloGestioneUtenti.FormRifiutaUtente.confirmaRifuitato')} /> : <SpinnerButton />}
                            </div>
                    }

                    {
                        gestioneUtenti.modificaRichiestaError &&
                        <div className="mt-2">
                            <ErrorBox errore={gestioneUtenti.modificaRichiestaError.toString()} />
                        </div>
                    }
                </div>
            </form>
        </div>
    )
})

export default RifiutaUtenteForm;