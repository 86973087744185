import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../../hooks/useStores'
import { useModuloESGStores } from '../context/useModuloESGStores'
import { observer } from 'mobx-react-lite'

//Components
import { Link, Outlet, useParams } from 'react-router-dom'
import { FaAngleLeft, FaExternalLinkAlt } from 'react-icons/fa'
import TextLoader from '../../../components/common/TextLoader'
import NavbarSchedaBilancio from './SezioniSchedaBilancio/NavbarSchedaBilancio'

const SchedaBilancio = observer(() => {

    const { bilanci } = useModuloESGStores();
    const { t } = useTranslation();

    const { moduli } = useStores();

    const { bilancio_anno } = useParams()

    useEffect(() => {
        bilancio_anno &&
            bilanci.getBilancio(parseInt(bilancio_anno))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        bilancio_anno,
        bilanci.temaMaterialitaBilancioCollegato,
        bilanci.temaMaterialitaBilancioScollegato,
        bilanci.temiMaterialitaFattoreBilancioCollegati
    ])


    return (
        <div>
            <div className="mb-4">
                <Link to={`/app/modulo/${moduli.moduloAttivo?.codice}`} className="flex items-center gap-2 text-accent cursor-pointer dark:bg-dark-accent dark:text-white dark:p-2 ">
                    <FaAngleLeft size={15} />
                    <span>{t('ModuloFattoriEsg.SchedaBilancio.tornaAllArchivio') }</span>
                </Link>
            </div>

            <div className="my-6 text-center flex flex-col items-center">
                <h2 className="font-bold md:text-4xl">
                    {bilanci.gettingBilancioAttivo ?
                        <TextLoader width={300} />
                        :
                        <>{t('ModuloFattoriEsg.SchedaBilancio.BilancioSostenibilita') } {bilanci.bilancioAttivo?.anno}</>
                    }
                </h2>
                <p className="text-xl mt-2">
                    {bilanci.gettingBilancioAttivo ?
                        <TextLoader width={200} />
                        :
                        <>{bilanci.bilancioAttivo?.descrizione}</>
                    }
                </p>
                {moduli.moduloAttivo?.codice !== 'bilanci' && !bilanci.gettingBilancioAttivo &&
                    <Link
                        target="_blank"
                        to={`/app/modulo/bilanci/${bilanci.bilancioAttivo?.anno}`}
                        className="bg-accent dark:bg-dark-accent py-2 px-4 text-white block font-bold my-2 text-center rounded-full gap-2 flex items-center">
                        <span>{t('ModuloFattoriEsg.SchedaBilancio.vaiAllaSchedaCompleta')} {bilanci.bilancioAttivo?.anno}</span>
                        <FaExternalLinkAlt />
                    </Link>
                }
            </div>

            <div className='my-4'>
                <NavbarSchedaBilancio />
            </div>

            <Outlet />
        </div>
    )
})

export default SchedaBilancio;