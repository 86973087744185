export const it_modulo_gestione_unita_misura = {
    ContextMenu: {
        unitaMisuraEliminata: 'Unità di misura eliminata con successo',
        modificaUnitaMisura: 'Modifica unità di misura',
        messagioElimina: 'Sei sicuro di voler eliminare questa unità di misura?',
        eliminaUnitaMisura: 'Elimina unità di misura',
    },

    Archivio: {
        nessunaUnitaMisura: 'Nessuna unità di misura presente',
        nuovaUnitaMisura: 'Aggiungi nuova unità di misura',
    },

    Form: {
        modificaUnitaMisura: 'Modifica unità di misura',
        creaUnitaMisura: 'Crea una nuova unità di misura.',
        modificaUnitaMisuraTesto: 'Modifica i dati dell\'unità di misura',
        modificaUnitaMisuraTestoCompleto: '',
        placeholderCodice: '"es. €, $, km',
        CreaUnitaMisura: 'Crea unità di misura',
        messaggiUnitaMisuraCreatoSucesso: 'Unità di misura creata con successo',
    },
}