import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../hooks/useStores'

const FollowMouse = observer(() => {

  const { ui } = useStores();

  useEffect(() => {
    window.addEventListener('mousemove', (event) => {
      let followMouse = document.getElementById('follow-mouse')

      if (followMouse) {
        //@ts-ignore
        followMouse.style.left = event.clientX + 'px'
        //@ts-ignore
        followMouse.style.top = event.clientY + 'px'
      }
    })
  }, [])

  return (
    <div id="follow-mouse" className="fixed z-[1000] bg-white shadow-lg p-2 rounded-md dark:bg-darkgray-dd dark:border dark:border-darkgray">
      {ui.followMouseComponent}
    </div>
  )
})

export default FollowMouse;