import React, { useEffect, useState } from "react"
import { IUtente } from "../../../types"
import { UseTranslationResponse, useTranslation } from 'react-i18next'
import { useStores } from '../../../hooks/useStores'

const PanoramicaGestioneUtentiLingueUtenti: React.FC<{ utente: IUtente[]}> = ({ utente}) => {
    const { t } = useTranslation();

    const { gestioneUtenti, ui, gestioneLingue } = useStores();

    const accessoIpe = utente.map((utente) => utente.oauth_tipo_utente === null);

    useEffect(() => {
        gestioneLingue.getLinguaLista()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[] 
    )


    return (
        <><div className=''>
            <ul className='w-2/4 m-auto text-sm lg:font-lg'>
                <li className='font-extrabold text-sm lg:text-lg mb-0.5 uppercase'>{gestioneUtenti.utenti.length === 1 ? `${gestioneUtenti.utenti.length} ${t('ModuloGestioneUtenti.PannelloUtenti.utente')}` : `${gestioneUtenti.utenti.length} ${t('ModuloGestioneUtenti.PannelloUtenti.utenti')}`}</li>
                <li className='font-bold text-sm lg:text-lg'>
                    {gestioneUtenti.utenti.filter((utente) => utente.attivo === true).length}
                    <span className='mx-1 text-xs lg:text-base'>
                        {gestioneUtenti.utenti.filter((utente) => utente.attivo === true).length === 1 ?
                            t('ModuloGestioneUtenti.PannelloUtenti.attivo')
                            :
                            t('ModuloGestioneUtenti.PannelloUtenti.attivi')}
                    </span>
                </li>
                <li className='font-bold text-sm lg:text-lg'>
                    {gestioneUtenti.utenti.filter((utente) => utente.attivo === false).length}
                    <span className='mx-1 text-xs lg:text-base'>
                        {gestioneUtenti.utenti.filter((utente) => utente.attivo === false).length === 1 ?
                            t('ModuloGestioneUtenti.PannelloUtenti.inattivo')
                            :
                            t('ModuloGestioneUtenti.PannelloUtenti.inattivo')}
                    </span>
                </li>
                <li className='font-bold text-sm lg:text-lg'>
                    {gestioneUtenti.utenti.filter((utente) => utente.disabilitato === true).length}
                    <span className='mx-1 text-xs lg:text-base'>
                        {gestioneUtenti.utenti.filter((utente) => utente.disabilitato === true).length === 1 ?
                            t('ModuloGestioneUtenti.PannelloUtenti.disabilitato')
                            :
                            t('ModuloGestioneUtenti.PannelloUtenti.disabilitati')}
                    </span>
                </li>
                <li className='font-bold text-sm lg:text-lg'>
                    {gestioneUtenti.utenti.filter((utente) => utente.is_verified === false).length}
                    <span className='mx-1 text-xs lg:text-base'>{t('ModuloGestioneUtenti.PannelloUtenti.daVerificare')}</span>
                </li>
            </ul>
        </div><div className=''>
                <ul className='ml-3 text-sm lg:font-lg'>
                    <li className='font-extrabold text-sm lg:text-lg mb-0.5'>{gestioneLingue.lingue.length} LINGUE</li>
                    {gestioneLingue.lingue.map(lingua => {
                        const numeroUtenti = gestioneUtenti.utenti.filter(utente => utente.lingua === lingua.codice).length
                        const nomeLingua = lingua.nome
                        return <li className='font-bold text-sm lg:text-lg'> {numeroUtenti} <span className='mx-1 text-xs lg:text-base'>{nomeLingua} </span></li>
                    })}
                </ul>
            </div></>
    )
}

export default PanoramicaGestioneUtentiLingueUtenti;
