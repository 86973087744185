import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from "../../hooks/useStores"
import dayjs from "dayjs"
import { IRichiesta } from "../../types";

//Components
import CreaUtenteForm from "./CreaUtenteForm"
import RifiutaUtenteForm from "./RifiutaUtenteForm";
import { FaUserTie } from "react-icons/fa";

const TileRichiestaUtente: React.FC<{ richiesta: IRichiesta, bShow: boolean, rifiutata: boolean, accettata: boolean }> =
  ({ richiesta, bShow, rifiutata, accettata }) => {
    const { ui } = useStores();
    const { t } = useTranslation();

    const [classe, settaClasse] = useState<string>();

    useEffect(() => {
      if(!accettata && !rifiutata){
        settaClasse("bg-accent dark:bg-dark-accent p-4 text-white rounded-sm shadow-xl flex flex-col justify-between")
      } else if (!accettata && rifiutata){
        settaClasse("text-black dark:text-white border-4 border-red text-white shadow-xl  p-4 rounded-sm flex flex-col justify-between")
      }else if (accettata && !rifiutata){
        settaClasse("bg-accent dark:bg-dark-accent border-4 border-green text-white shadow-xl  p-4 rounded-sm flex flex-col justify-between")
      }
    },[])
     
    return (
      
      <div className={classe}>
        <div className="flex flex-col font-bold justify-center text-center mb-1">
          <p className="self-center mb-1"> <FaUserTie size={20} /></p>
          <h4 className="text-xl mb-1">{richiesta.nome} {richiesta.cognome}</h4>
          <p className="text-center mb-1"><span className={`scale-[1] fi fi-${richiesta.lingua} rounded-full`}
            onMouseEnter={() => ui.mountFollowMouse(<p className="text-xs">{richiesta.lingua}</p>)}
            onMouseLeave={() => ui.unmountFollowMouse()} /></p>
          <p className="text-xs mb-1">{t('ModuloGestioneUtenti.TileRichiestaUtente.richiestaRicevuta')} {dayjs(richiesta.ricevuta_il).locale('it').format('DD MMMM YYYY')}</p>
          <p className="mb-1">{richiesta.email}</p>
          <p className="mb-1">{richiesta.telefono}</p>
          <p className="mb-1">{richiesta.azienda}</p>
        </div>

        {bShow &&
          <div className="justify-self-end">

            <button
              className="block w-full p-2 rounded-sm bg-white text-accent font-bold dark:bg-white mb-1"
              onClick={() => ui.mountAsideModal(<CreaUtenteForm richiesta={richiesta} />)}
            >
              {t('ModuloGestioneUtenti.TileRichiestaUtente.acettataRichiesta')}
            </button>

            <button
              className="block w-full p-2 text-white richiesteRifiutaBtn"
              onClick={() => ui.mountAsideModal(<RifiutaUtenteForm richiesta={richiesta} />)}
            >
              {t('ModuloGestioneUtenti.TileRichiestaUtente.riffutataRichiesta')}
            </button>

          </div>
        }

      </div>
    )
  }

export default TileRichiestaUtente;