import React, { useEffect, useState } from 'react'

//data
import { useStores } from '../../../hooks/useStores'
import { IAttivita } from '../../../types'
import dayjs from 'dayjs'
import interpretaAttivita from '../../../hooks/attivita/interpretaAttivita'

//Components
import { FaInfo } from 'react-icons/fa'

const AttivitaTilePanello: React.FC<{ attivita: IAttivita }> = ({ attivita }) => {

    const [data, setData] = useState('')

    const { ui } = useStores()

    useEffect(() => {
        if (dayjs(new Date()).format('DDMMYYYY') === dayjs(attivita.created_at).format('DDMMYYYY')) {
            setData(dayjs(attivita.created_at).locale('it').format('Oggi, H:mm'))
        } else {
            setData(dayjs(attivita.created_at).locale('it').format('DD MMMM, H:mm'))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const styles = {
        'wrapper': `bg-white dark:bg-darkgray-dd p-[30px] rounded-sm shadow-xl
                    flex flex-col md:flex-row md:items-center justify-between gap-4 md:gap-2
                    border-b border-lightgray-dd`
    }
    return (
        <div className={`${styles.wrapper}`}>
            <div className="md:max-w-[66%]">
                <p className="testo-attivita" dangerouslySetInnerHTML={{
                    __html: interpretaAttivita(attivita)
                }}>
                </p>
            </div>
            <div className="opacity-70 flex md:flex-col justify-between gap-4 md:items-end">
                <span>{data}</span>
                <div
                    className="flex gap-2 items-center px-1 py-[3px] rounded-sm bg-lightgray-d dark:bg-darkgray cursor-default"
                    onMouseEnter={() => ui.mountFollowMouse(
                        <div className="max-w-[100px] flex flex-col gap-1">
                            <p className="text-xs break-words">uri: {attivita.uri}</p>
                        </div>
                    )}
                    onMouseLeave={() => ui.unmountFollowMouse()}
                >
                    <FaInfo size={8} />
                    <span className="text-xs">Modulo {attivita.modulo}</span>
                </div>
            </div>
        </div >
    )
}

export default AttivitaTilePanello;
