import { IAttivita } from '../../types';

import { creaStringStandardPerAttivita } from '../../hooks/attivita/creaStringStandardPerAttivita';

export default function factoryAttivitaIndicatori(attivita: IAttivita) {

    let codiceInterpretato = '';

    switch (attivita.route) {

        case 'Indicatori.crea':
            attivita.user_name?
            codiceInterpretato = `${attivita.user_name} ha <span class="azione bg-accent dark:bg-dark-accent">creato</span> un indicatore</a>`
            :
            codiceInterpretato = `Hai <span class="azione bg-accent dark:bg-dark-accent">creato</span> un indicatore</a>`
            break;

        case 'Indicatori.modifica':
            attivita.user_name?
            codiceInterpretato = `${attivita.user_name} ha <span class="azione bg-orange dark:bg-dark-orange">modificato</span> un indicatore</a>`
            :
            codiceInterpretato = `Hai <span class="azione bg-orange dark:bg-dark-orange">modificato</span> un indicatore</a>`
            break;

        case 'Indicatori.elimina':
            attivita.user_name?
            codiceInterpretato = `${attivita.user_name} ha <span class="azione bg-red dark:bg-dark-red">eliminato</span> un indicatore`
            :
            codiceInterpretato = `Hai <span class="azione bg-red dark:bg-dark-red">eliminato</span> un indicatore`
            break;

        case 'Indicatori.valida':
            attivita.user_name?
            codiceInterpretato = `${attivita.user_name} ha <span class="azione bg-accent dark:bg-dark-accent">validato</span> un indicatore`
            :
            codiceInterpretato = `Hai <span class="azione bg-accent dark:bg-dark-accent">validato</span> un indicatore`
            break;

        case 'Indicatori.invalida':
            attivita.user_name?
            codiceInterpretato = `${attivita.user_name} ha <span class="azione bg-red dark:bg-dark-red">rimosso la validazione</span> da un indicatore`
            :
            codiceInterpretato = `Hai <span class="azione bg-red dark:bg-dark-red">rimosso la validazione</span> da un indicatore`
            break;

        default:
            codiceInterpretato = creaStringStandardPerAttivita(attivita)

    }

    return codiceInterpretato;

}