import React, { useEffect } from 'react'

//Data
import { useForm } from 'react-hook-form'
import { config } from '../../config'
import { useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

//Components
import { FaLock } from 'react-icons/fa'
import { useStores } from '../../hooks/useStores'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TestoErrore from '../common/TestoErrore'
import ErrorBox from '../common/ErrorBox'
import InfoBox from '../common/InfoBox'
import SpinnerButton from '../common/SpinnerButton'


export const CompletaAccountForm = observer(() => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const { auth } = useStores();

  //Effettua redirect su login se non sono presenti i parametri query string necessari
  useEffect(() => {
    if (!searchParams.get("email") || !searchParams.get('codice')) {
      document.location.href = '/'
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Controlla se il codice è corretto al caricamento della pagina
  useEffect(() => {
    auth.verificaEmail(searchParams.get('codice') as string)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { register, handleSubmit, watch, setError, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      email: searchParams.get('email'),
      password: '',
      repeatPassword: '',
      numero: '',
      trattamento_dati: false
    }
  });

  const onSubmit = () => {
    //Controlla RegEx Password
    if (!watch('password').match(config.PASSWORD_REGEX)) {

      setError('password', { type: "custom", message: t('common.passwordRegex') })

      //Controlla matching password
    } else if (watch('password') !== watch('repeatPassword')) {

      setError('repeatPassword', { type: "custom", message: t('PaginaCompleteAccount.completeAccount') })

    } else {

      auth.settaPrimaPassword(searchParams.get('codice') as string, watch('password'))

    }
  }


  return (
    <>
      {
        !auth.gettingVerificaEmail &&
        <div className="mb-2">
          {
            auth.emailVerificata ?
              <InfoBox messaggio={t('FormCompleteAccount.emailVerificatoConSucesso')} />
              :
              <ErrorBox errore={auth.emailVerificataError} />
          }
        </div>
      }

      {
        !auth.gettingVerificaEmail &&
        <>
          <form onSubmit={handleSubmit(onSubmit)}>

            <div className="form relative">
              <label htmlFor="">E-mail</label>
              <input {...register("email", { required: t('common.fornisciEmail') })} placeholder={t('PaginaContatti.testoEmail')} type="email" disabled />
              <div>
                <FaLock className="absolute right-2 top-[39px] opacity-50" />
              </div>
            </div>

            <div className="form">
              <label htmlFor="">{t('common.fornisciPassword')}</label>
              <input  {...register("password", { required: t('common.fornisciPassword') })} placeholder={t('common.password')} type="password" />

              {errors.password && <TestoErrore errore={errors.password.message} />}
            </div>

            <div className="form">
              <label htmlFor="">{t('common.ripetiLaPassword')}</label>
              <input  {...register("repeatPassword", { required: t('common.ripetiLaPassword') })} placeholder={t('common.ripetiLaPassword')} type="password" />

              {errors.repeatPassword && <TestoErrore errore={errors.repeatPassword.message} />}
            </div>


            <div className="form flex gap-2 bg-lightgray p-3 mt-2 rounded-sm">
              <input {...register("trattamento_dati", { required: t('FormIscrizione.deviAcconsentire'), pattern: { value: config.REGEX_TELEFONO, message: 'Dev\'essere un numero di telefono valido' } })} placeholder="Telefono" type="checkbox" />
              <label htmlFor="">
                {t('FormIscrizione.messaggioDati')}
                <Link to="/informativa-dati-termini-uso">
                  {t('FormIscrizione.testoLink')}
                </Link>
              </label>
            </div>

            {errors.trattamento_dati && <TestoErrore errore={errors.trattamento_dati.message} />}

            <div className="form pt-4">

              {
                auth.primaPasswordSettata ?
                  <InfoBox messaggio={t('FormCompleteAccount.mesaggioSucessco')} />
                  :
                  !auth.settingPrimaPassword ? <input type="submit" value={t('FormCompleteAccount.attivaAccediAccount')} /> : <SpinnerButton />

              }

            </div>
            <div className="mt-3 text-center">
              <p>{t('paginaSignup.giaAccount')}<Link to="/">{t('paginaSignup.effettuaLogin')}</Link></p>
            </div>

          </form>

          {
            isSubmitSuccessful && !auth.settingPrimaPassword &&
            <>
              {
                auth.primaPasswordSettata ? (
                  <Link className="text-white bg-accent text-center w-full p-2 rounded-sm block" to="/">
                    {t('FormCompleteAccount.vailalLogin')}
                  </Link>
                ) : (
                  <ErrorBox errore={t('FormCompleteAccount.erroreNellImpostazione')} />
                )
              }
            </>
          }
        </>
      }

    </>
  )
})