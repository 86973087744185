import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { IBusinessUnit } from '../../types'
import { FaInfoCircle } from 'react-icons/fa'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../../hooks/useStores'
import { UseModuloBusinessUnitsStore } from '../../context/UseModuloBusinessUnitsStore'

//Components
import BusinessUnitTile from './BusinessUnitTile'
import BusinessUnitTileMinificata from './BusinessUnitTileMinificata'
import CanvasBusinessUnitHeader from './CanvasBusinessUnitHeader'
import MappaBusinessUnits from './MappaBusinessUnits'


const CanvasBusinessUnits: React.FC<{ businessUnits: IBusinessUnit[], sfondo?: boolean, header?: boolean, link?: string }> = observer(({ businessUnits, sfondo = true, header = true, link = '' }) => {

    const { ui } = useStores()
    const { t } = useTranslation();
    const { canvas_business_units } = UseModuloBusinessUnitsStore();

    const styles = {
        canvas: `min-w-full max-w-[calc(100vw-20px)] md:max-w-[calc(100vw-300px)] canvas-body max-h-[1800px]
        flex overflow-x-auto items-center justify-start
        ${sfondo && 'bg-lightgray-d dark:bg-darkgray-d border border-lightgray-dd dark:border-darkgray-dd p-4'}
        ${ui.asideModal && 'md:max-w-[calc(100vw-760px)]'}`,
    }

    return (
        <div>
            {
                header &&
                <div className="w-full">
                    <CanvasBusinessUnitHeader />
                </div>
            }

            {
                canvas_business_units.modalitaVista === 'mappa' ?
                    <MappaBusinessUnits businessUnits={businessUnits} />
                    :
                    <div className={styles.canvas}>
                        <div className="flex items-start justify-center grow gap-8">
                            {
                                businessUnits && businessUnits.length > 0 ?
                                    businessUnits.map((businessUnit: IBusinessUnit) => (
                                        <>
                                            {canvas_business_units.modalitaVista === 'default' ?
                                                <BusinessUnitTile key={businessUnit.nome} businessUnit={businessUnit} link={link} />
                                                :
                                                <BusinessUnitTileMinificata key={businessUnit.nome} businessUnit={businessUnit} />
                                            }
                                        </>
                                    ))
                                    :
                                    <div className="text-center opacity-70 text-xl flex items-center justify-center gap-2">
                                        <FaInfoCircle />
                                        <span>{t('ModuloBusinessUnit.CanvasBusinessUnit.nessunaBusinessUnitTrovatra')}</span>
                                    </div>
                            }
                        </div>
                    </div>
            }
        </div>
    )
})

export default CanvasBusinessUnits;