import React from 'react'
import { Link } from 'react-router-dom';
import { IFattoreESG } from '../../../../types'

const FattoreBilancioTemaTile: React.FC<{ fattore: IFattoreESG }> = ({ fattore }) => {
  return (
    <div className="bg-white dark:bg-darkgray-dd p-4 shadow-xl">
      <div>
        <span>
          {fattore.codice}
        </span>
      </div>
      <Link className="text-lg font-bold" to={`/app/modulo/fattori_esg/${fattore.codice}`}>
        <h2>{fattore.nome_esteso}</h2>
      </Link>
      {fattore.descrizione &&
        <>
          <hr className="my-4" />
          <p>{fattore.descrizione}</p>
        </>
      }
    </div>
  )
}

export default FattoreBilancioTemaTile;