import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { observer } from 'mobx-react-lite'
import { useForm } from 'react-hook-form'
import { IBusinessUnitCategoria } from '../types'
import { UseModuloBusinessUnitCategorieStore } from './context/UseModuloBusinessUnitCategorieStore'

//Components
import InputField from '../../../components/common/form/InputField'
import SpinnerButton from '../../../components/common/SpinnerButton'
import ErrorBox from '../../../components/common/ErrorBox'
import InfoBox from '../../../components/common/InfoBox'


const BusinessUnitCategoriaForm: React.FC<{ categoria?: IBusinessUnitCategoria }> = observer(({ categoria }) => {

    const { business_unit_categoria } = UseModuloBusinessUnitCategorieStore();
    const { t } = useTranslation();

    //Istanzia Hook Form
    const { watch, register, setValue, handleSubmit, formState: { errors, isSubmitSuccessful } } = useForm({
        defaultValues: {
            nome: categoria ? categoria.nome : '',
            nome_originale: categoria ? categoria.nome : '',
            descrizione: categoria ? categoria.descrizione : ''
        }
    })
    const onSubmit = () => {
        categoria ?

            business_unit_categoria.modificaBusinessUnitCategoria(watch() as IBusinessUnitCategoria).then(() => {
                setValue('nome_originale', watch('nome') as string)
            })
            :

            business_unit_categoria.creaBusinessUnitCategoria(watch() as IBusinessUnitCategoria)

    }

    return (
        <div>
            <div className="mb-4">
                <h2 className="text-2xl mb-2">
                    {categoria ?
                        `${t('ModuloBusinessUnit.FormBusinessUnitCategoria.modificaCategoria')} ${categoria.nome}`
                        :
                        `${t('ModuloBusinessUnit.FormBusinessUnitCategoria.creaNuovaCategoria')}`
                    }
                </h2>
                <p className="text-text-light">
                    {categoria ?
                         `${t('ModuloBusinessUnit.FormBusinessUnitCategoria.modificaDatiDellaCategoria')} ${categoria.nome},`
                        :
                        `${t('ModuloBusinessUnit.FormBusinessUnitCategoria.creaNuovaCategoria')}`
                    }
                </p>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>

                <InputField
                    label={t('common.nome')}
                    classi="nome"
                    placeholder={t('common.nome')}
                    type="text"
                    error={errors.nome}
                    form={register("nome", { required: t('common.fornisciNome'), })}
                />

                <InputField
                    label={t('common.descrizione')}
                    classi="descrizione"
                    placeholder={t('common.descrizione')}
                    type="text"
                    form={register("descrizione")}
                />

                <div className="form">
                    {/* Submit modifica categoria */}
                    {categoria &&

                        <>
                            <div className="form">
                                <div>
                                    {!business_unit_categoria.editingBusinessUnitCategoria ? <input type="submit" value={t('common.salvaModifica')} /> : <SpinnerButton />}
                                </div>
                            </div>

                            {
                                isSubmitSuccessful && !business_unit_categoria.editingBusinessUnitCategoria && (

                                    business_unit_categoria.modificaBusinessUnitCategoriaError
                                        ?
                                        <div className="my-2">
                                            <ErrorBox errore={business_unit_categoria.modificaBusinessUnitCategoriaError} />
                                        </div>
                                        :
                                        <div className="my-2">
                                            <InfoBox messaggio={t('common.modificaSalvato')} />
                                        </div>

                                )
                            }

                        </>
                    }
                    {/* Submit creazione categoria */}
                    {!categoria &&

                        <>
                            {
                                (isSubmitSuccessful && !business_unit_categoria.creatingBusinessUnitCategoriaError && !business_unit_categoria.creatingBusinessUnitCategoria) ?
                                    <InfoBox messaggio={t('ModuloBusinessUnit.FormBusinessUnitCategoria.categoriaCreatoSuccesso')} />
                                    :
                                    <div className="form pt-4">
                                        {!business_unit_categoria.creatingBusinessUnitCategoria ? <input type="submit" value={t('ModuloBusinessUnit.FormBusinessUnitCategoria.creaCategoriaButtone')} /> : <SpinnerButton />}
                                    </div>
                            }

                            {
                                business_unit_categoria.creatingBusinessUnitCategoriaError &&
                                <div className="mb-2">
                                    <ErrorBox errore={business_unit_categoria.creatingBusinessUnitCategoriaError.toString()} />
                                </div>
                            }

                        </>
                    }
                </div>
            </form>
        </div>
    )
})

export default BusinessUnitCategoriaForm;