import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { getListaBusinessUnits } from '../../../UiModuloBusinessUnit/rest/business_units';
import { IBusinessUnit } from '../../../UiModuloBusinessUnit/types';
import { UseModuloBusinessUnitsStore } from '../../../UiModuloBusinessUnit/context/UseModuloBusinessUnitsStore';
import { useModuloESGStores } from '../../context/useModuloESGStores';
import { observer } from 'mobx-react';

//Components
import CanvasAttribuisciBusinessUnits from './CanvasAttribuisciBusinessUnits';
import SpinnerButton from '../../../../components/common/SpinnerButton';
import ErrorBox from '../../../../components/common/ErrorBox';
import InfoBox from '../../../../components/common/InfoBox';
import { IFattoreESG } from '../../types';
import { runInAction } from 'mobx';
import { AiOutlineReload } from 'react-icons/ai';
import Spinner from '../../../../components/common/Spinner';

const FattoreBilancioBusinessUnitForm: React.FC<{ fattore: IFattoreESG, bilancio: number, businessUnits?: IBusinessUnit }> = observer(({ fattore, bilancio }) => {

  const [alberaturaBusinessUnits, setAlberaturaBusinessUnits] = useState<IBusinessUnit[]>([])
  const { t } = useTranslation();
  const [gettingAlberaturaBusinessUnits, setGettingAlberaturaBusinessUnits] = useState<boolean>(false)
  const { business_units } = UseModuloBusinessUnitsStore()
  const { canvas_bilanci_business_units, bilanci } = useModuloESGStores()

  const caricaBusinessUnits = () => {

    setGettingAlberaturaBusinessUnits(true);

    getListaBusinessUnits().then((res) => {
      canvas_bilanci_business_units.settaBusinessUnits(res.data.data)
      setAlberaturaBusinessUnits(res.data.data)
    }).finally(() => {
      setGettingAlberaturaBusinessUnits(false)
    })
  }

  useEffect(() => {
    caricaBusinessUnits()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    business_units.businessUnitCreata,
    business_units.businessUnitModificata,
    business_units.businessUnitEliminata,
  ])

  useEffect(() => {
    canvas_bilanci_business_units.businessUnitsAttive = new Set(fattore.business_units.map(bu => bu.nome))
    runInAction(() => bilanci.collegaFattoreBilancioBusinessUnitError = '')
    runInAction(() => bilanci.collegaFattoreBilancioBusinessUnitSuccess = '')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCollegaFAttoreBilancioBusinessUnit = () => {
    bilanci.getBilancio(bilanci.bilancioAttivo?.anno as number)
    bilanci.collegaFattoreBilancioBusinessUnit(bilancio, fattore.codice, canvas_bilanci_business_units.businessUnitsAttive)
  }

  return (
    <div>
      <div className="mb-4">
        <h2 className="text-2xl mb-2">{t('ModuloFattoriEsg.FormFattoreBilancioBusinessUnit.selezionaLebusinessUnit')} {fattore.codice} {t('ModuloFattoriEsg.FormFattoreBilancioBusinessUnit.perIlBilancio')} {bilancio}</h2>
        <p>{t('ModuloFattoriEsg.FormFattoreBilancioBusinessUnit.testoBusinessUnitSelezionato')}</p>
      </div>

      {!gettingAlberaturaBusinessUnits ?
        <div>
          {alberaturaBusinessUnits.length > 0 &&
            <div className="my-3">
              <div className="flex justify-end gap-3 flex-col md:flex-row items-start md:items-center">
                <div className="flex items-center gap-1">
                  <div className="border border-darkgray dark:border-lightgray-d bg-white dark:bg-darkgray-d w-3 h-3" />
                  <p>{t('ModuloFattoriEsg.FormFattoreBilancioBusinessUnit.nonSelezionata')}</p>
                </div>
                <div className="flex items-center gap-1">
                  <div className="bg-accent dark:bg-dark-accent w-3 h-3 dark:border dark:border-white" />
                  <p>{t('ModuloFattoriEsg.FormFattoreBilancioBusinessUnit.selezionata')}</p>
                </div>
                <div className="flex items-center gap-1">
                  <div className="bg-accent dark:bg-dark-accent w-3 h-3 opacity-70 dark:border dark:border-white" />
                  <p>{t('ModuloFattoriEsg.FormFattoreBilancioBusinessUnit.conAcessoEridato')}</p>
                </div>
              </div>
            </div>
          }

          <CanvasAttribuisciBusinessUnits businessUnits={alberaturaBusinessUnits} />

          <div
            className="flex items-center justify-center gap-2 p-2 cursor-pointer hover:opacity-70"
            onClick={() => caricaBusinessUnits()}
          >
            <AiOutlineReload size={20} onClick={() => caricaBusinessUnits()} />
            <p>{t('ModuloFattoriEsg.FormFattoreBilancioBusinessUnit.ricarica')}</p>
          </div>
        </div>
        :
        <div className="flex items-center justify-center w-full bg-lightgray-d dark:bg-darkgray-d p-4">
          <Spinner colore="black" />
        </div>
      }


      {canvas_bilanci_business_units.businessUnitsAttive.size > 0 &&
        <div className="my-3 font-bold flex gap-2 flex-col md:flex-row">
          <div className="w-full">
            <p className="mb-2">{t('ModuloFattoriEsg.FormFattoreBilancioBusinessUnit.businessUnitSelezionate')}:</p>
            <div className="flex flex-col gap-1">
              {Array.from(canvas_bilanci_business_units.businessUnitsAttive).map((businessUnit) => {
                return (
                  <div className="bg-white dark:bg-darkgray-d p-2 shadow-lg">
                    {businessUnit}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="w-full">
            <p className="mb-2">{t('ModuloFattoriEsg.FormFattoreBilancioBusinessUnit.businessUnitPadriSelezionate')}:</p>
            <div className="flex flex-col gap-1">
              {Array.from(canvas_bilanci_business_units.businessUnitsPadriAttive).map((businessUnit) => {
                return (
                  <div className="bg-white dark:bg-darkgray-d p-2 shadow-lg">
                    {businessUnit}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      }

      <div className="form pt-4">
        {
          bilanci.collegandoFattoreBilancioBusinessUnit ?
            <SpinnerButton />
            :
            <input
              onClick={() => handleCollegaFAttoreBilancioBusinessUnit()}
              type="submit" value={`${t('ModuloFattoriEsg.FormFattoreBilancioBusinessUnit.attribuisci')} ${canvas_bilanci_business_units.businessUnitsAttive.size} ${t('ModuloFattoriEsg.FormFattoreBilancioBusinessUnit.businessUnitQestoFattore')}`} />
        }

        {bilanci.collegaFattoreBilancioBusinessUnitError &&
          <div className="my-2">
            <ErrorBox errore={bilanci.collegaFattoreBilancioBusinessUnitError} />
          </div>
        }

        {bilanci.collegaFattoreBilancioBusinessUnitSuccess &&
          <div className="my-2">
            <InfoBox messaggio={bilanci.collegaFattoreBilancioBusinessUnitSuccess} />
          </div>
        }

      </div>

    </div>
  )
})

export default FattoreBilancioBusinessUnitForm;
