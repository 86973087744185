import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { observer } from 'mobx-react-lite'
import { useModuloRendicontazioneBilanciStores } from '../../pagineBilanci/UiModuloRendicontazioneBilanci/context/useModuloRendicontazioneBilanciStores'

const StatoAvanzamentoCompletamentoIndicatori: React.FC<{
    indicatori_totali: number,
    indicatori_rendicontati: number,
    indicatori_validati: number,
    minificato?: boolean
}> =
    observer(({
        indicatori_totali,
        indicatori_rendicontati,
        indicatori_validati,
        minificato
    }) => {

        const { t } = useTranslation();
        const { rendicontazione_bilanci } = useModuloRendicontazioneBilanciStores()
        const [percentualeIndicatoriRendicontati, setPercentualeIndicatoriCompletati] = useState<number>(0)
        const [percentualeIndicatoriValidati, setPercentualeIndicatoriValidati] = useState<number>(0)

        useEffect(() => {

            setPercentualeIndicatoriCompletati(Math.round((indicatori_rendicontati / indicatori_totali) * 100))
            setPercentualeIndicatoriValidati(Math.round((indicatori_validati / indicatori_totali) * 100))

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            rendicontazione_bilanci.businessUnitAttiva,
            rendicontazione_bilanci.checkAggiornamentoIndicatore
        ])

        return (
            <div className={`${!minificato && 'my-3'}`} >
                {indicatori_totali > 0 ?
                    <>
                        <div>
                            <div className="flex justify-center items-center gap-2 mb-2">
                                <span className="font-bold text-xs md:text-md text-right">{indicatori_totali - indicatori_rendicontati} {t('ModuloFattoriEsg.statoAvanzamentoCompletamentoIndicatori.indicatoriRendicontare')}</span>
                                <span>-</span>
                                <span className="font-bold text-xs md:text-md text-right">{indicatori_totali - indicatori_validati} {t('ModuloFattoriEsg.statoAvanzamentoCompletamentoIndicatori.indicatoriDiValidare')}</span>
                            </div>

                            <div className="relative bg-lightgray dark:bg-darkgray-dd rounded-full overflow-hidden mt-2 h-[40px] flex flex-col border border-lightgray-dd dark:border-darkgray">

                                {percentualeIndicatoriRendicontati > 0 ?
                                    <div
                                        className={`duration-200 absolute min-w-[30px] opacity-70 bg-accent h-full dark:bg-dark-accent rounded-full flex items-center justify-end ${percentualeIndicatoriRendicontati === 0 && 'bg-red dark:bg-dark-red'}`}
                                        style={{ width: percentualeIndicatoriRendicontati ? percentualeIndicatoriRendicontati + '%' : '30px' }}>
                                    </div>
                                    :
                                    <p className="h-full flex items-center justify-center text-sm">
                                        {t('ModuloFattoriEsg.statoAvanzamentoCompletamentoIndicatori.nonSonoAncoraRendicontatre')}
                                    </p>
                                }
                                {percentualeIndicatoriValidati > 0 &&
                                    <div
                                        className={`duration-200  absolute min-w-[30px] bg-accent h-full dark:bg-dark-accent rounded-full flex items-center justify-end ${percentualeIndicatoriValidati === 0 && 'bg-red dark:bg-dark-red'}`}
                                        style={{ width: percentualeIndicatoriValidati ? percentualeIndicatoriValidati + '%' : '30px' }}>
                                    </div>
                                }

                                {percentualeIndicatoriRendicontati > 0 &&

                                    <div
                                        className={`duration-200 absolute min-w-[30px]  h-full p-[3px] rounded-full flex items-start justify-end`}
                                        style={{ width: percentualeIndicatoriRendicontati ? percentualeIndicatoriRendicontati + '%' : '30px' }}>
                                        <span className="text-white text-xs mr-2 font-bold">{indicatori_rendicontati} {t('ModuloFattoriEsg.statoAvanzamentoCompletamentoIndicatori.rendicontati')} ({percentualeIndicatoriRendicontati}%)</span>
                                    </div>
                                }

                                {percentualeIndicatoriValidati > 0 &&
                                    <div
                                        className={`duration-200 absolute min-w-[30px] h-full p-[3px] rounded-full flex items-end justify-end`}
                                        style={{ width: percentualeIndicatoriValidati ? percentualeIndicatoriValidati + '%' : '30px' }}>
                                        <span className="text-white text-xs mr-2 font-bold">{indicatori_validati} {t('ModuloFattoriEsg.statoAvanzamentoCompletamentoIndicatori.validati')} ({percentualeIndicatoriValidati}%)</span>
                                    </div>
                                }

                            </div>

                        </div>
                    </>
                    :
                    <p className={`${minificato ? 'text-xs' : 'text-md'}`}>{t('ModuloFattoriEsg.statoAvanzamentoCompletamentoIndicatori.nonSonoPresenti')}</p>
                }
            </div >
        )
    })

export default StatoAvanzamentoCompletamentoIndicatori;
