import { observable, action, makeAutoObservable, runInAction } from 'mobx'

import {
  getLinguaLista,
  creaLingua,
  modificaLingua,
  eliminaLingua
} from '../rest/gestioneLingue'

import { ILingua } from '../../../types'

export class GestioneLingueStore {

  lingue: ILingua[] = []

  creatingLingua: boolean = false
  creatingLinguaError: string = ''
  linguaCreata: boolean = false

  editingLingua: boolean = false
  modificaLinguaError: string = ''
  linguaModificata: boolean = false

  deletingLingua: boolean = false
  eliminaLinguaError: string = ''
  linguaEliminata: boolean = false


  constructor() {
    makeAutoObservable(this, {

      lingue: observable,
      getLinguaLista: action,

      //Crea lingua
      creaLingua: action,
      creatingLingua: observable,
      creatingLinguaError: observable,
      linguaCreata: observable,

      //Modifica lingua
      modificaLingua: action,
      editingLingua: observable,
      modificaLinguaError: observable,
      linguaModificata: observable,

      //Elimina lingua
      eliminaLingua: action,
      deletingLingua: observable,
      eliminaLinguaError: observable,
      linguaEliminata: observable,

    })
  }

  async getLinguaLista() {
    await getLinguaLista().then(res => {

      runInAction(() => this.lingue = res.data.data)

    }).catch(err => {

      console.log(err)

    })
  }

  //Crea un lingua
  async creaLingua(lingua: ILingua) {

    this.creatingLingua = true

    await creaLingua(lingua).then(res => {

      runInAction(() => this.creatingLinguaError = '')

    }).catch(err => {

      runInAction(() => this.creatingLinguaError = err.response.data.message ? err.response.data.message : 'Errore generico')

    }).finally(() => {

      runInAction(() => this.creatingLingua = false)
      runInAction(() => this.linguaCreata = !this.linguaCreata)

    })
  }


  async modificaLingua(lingua: ILingua,) {

    this.editingLingua = true

    await modificaLingua(lingua).then(res => {

      runInAction(() => this.modificaLinguaError = '')

    }).catch(err => {

      runInAction(() => this.modificaLinguaError = err.response.data.message ? err.response.data.message : 'Errore generico')

    }).finally(() => {

      runInAction(() => this.editingLingua = false)
      runInAction(() => this.linguaModificata = !this.linguaModificata)

    })
  }

  async eliminaLingua(codice: string) {

    this.deletingLingua = true

    await eliminaLingua(codice).then(res => {

      runInAction(() => this.eliminaLinguaError = '')

    }).catch(err => {

      runInAction(() => this.eliminaLinguaError = err.response.data.message ? err.response.data.message : 'Errore generico')

      console.log(this.eliminaLinguaError)

    }).finally(() => {

      runInAction(() => this.deletingLingua = false)
      runInAction(() => this.linguaEliminata = !this.linguaEliminata)

    }
    )
  }



}