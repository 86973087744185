import { useEffect } from 'react'
import { useStores } from './useStores'

const useSettaTema = () => {

  const { theme } = useStores()

  useEffect(() => {
    if (localStorage.getItem("theme")) {
      theme.changeTheme(localStorage.getItem('theme') as string)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

}

export default useSettaTema;