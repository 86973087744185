import { observable, makeAutoObservable, action, runInAction } from 'mobx'
import { IBilancioRendicontabile, IBusinessUnitRendicontabile, IFattoreRendicontabile, IIndicatore } from '../../../types'
import { getBilancioBusinessUnitRendicontazione, getBilancioRendicontazione } from '../rest/rendicontazione_per_business_unit'

export class RendicontazioneBilanciStore {

    bilancio: IBilancioRendicontabile | null = null
    gettingBilancio: boolean = false
    gettingBilancioError: string = ''

    aggiornaPagina = false
    businessUnitAttiva: IBusinessUnitRendicontabile | null = null
    gettingBusinessUnit: boolean = false
    gettingBusinessUnitError: string = ''

    fattoriVisibili: IFattoreRendicontabile[] = []
    categorieVisibili: IFattoreRendicontabile[] = []

    filtriRicerca: string = ''
    filtriStato: 'rendicontati' | 'nonRendicontati' | 'validati' | '' = ''
    filtriCategorie: Set<string> = new Set()
    filtriTipo: 'fattore' | 'tabella' | '' = ''

    checkAggiornamentoIndicatore: boolean = false

    constructor() {
        makeAutoObservable(this, {
            bilancio: observable,
            gettingBilancio: observable,
            gettingBilancioError: observable,
            settaBilancio: action,

            businessUnitAttiva: observable,
            gettingBusinessUnit: observable,
            gettingBusinessUnitError: observable,

            fattoriVisibili: observable,
            categorieVisibili: observable,

            applicaFiltriRicerca: action,
            filtriRicerca: observable,

            applicaFiltriStato: action,
            filtriStato: observable,

            applicaFiltriCategorie: action,
            filtriCategorie: observable,

            applicaFiltriTipo: action,
            filtriTipo: observable,

            settaFattoriVisibili: action,
            settaCategorieVisibili: action,

            checkAggiornamentoIndicatore: observable,
            applicaAggiornamentoIndicatore: action,
        })
    }

    //Setta il bilancio da rendicontare
    settaBilancio = (bilancio_anno: string) => {
        this.gettingBilancio = true

        getBilancioRendicontazione(bilancio_anno).then((res) => {

            runInAction(() => this.bilancio = res.data.data)
            this.gettingBilancioError = ''

        }).catch((err) => {

            this.gettingBilancioError = err.response.data.message ? err.response.data.message : 'Errore generico'

        }).finally(() => {
            this.gettingBilancio = false
        })
    }

    //Setta il bilancio da rendicontare
    settaBusinessUnitAttiva = (bilancio_anno: string, business_unit_nome: string) => {
        this.gettingBusinessUnit = true

        getBilancioBusinessUnitRendicontazione(bilancio_anno, business_unit_nome).then((res) => {
            let businessUnit = res.data.data
            let listaFattoriRendicontabili = businessUnit.fattori_rendicontabili

            let righeColonne = listaFattoriRendicontabili.filter((fatt: IFattoreRendicontabile) => (
                fatt.tipo === 'riga' || fatt.tipo === 'colonna'
            ))

            righeColonne.forEach((rigaColonna: IFattoreRendicontabile) => {
                listaFattoriRendicontabili.find((tabella: IFattoreRendicontabile) => tabella.codice === rigaColonna.codice_padre)
                    ?.sotto_fattori.push(rigaColonna)
            })

            businessUnit.fattori_rendicontabili = listaFattoriRendicontabili.filter((fattore: IFattoreRendicontabile) => (
                fattore.tipo === 'fattore' ||
                fattore.tipo === 'tabella' ||
                fattore.tipo === 'categoria'
            ))


            runInAction(() => {
                this.businessUnitAttiva = businessUnit
            })


            this.gettingBusinessUnitError = ''

        }).catch((err) => {

            this.gettingBusinessUnitError = err.response.data.message ? err.response.data.message : 'Errore generico'

        }).finally(() => {
            this.gettingBusinessUnit = false

            this.settaFattoriVisibili()
            this.settaCategorieVisibili()
        })
    }

    applicaFiltriRicerca = (ricerca: string) => {
        this.filtriRicerca = ricerca

        this.settaFattoriVisibili()
    }

    //Setta un filtro di stato
    applicaFiltriStato = (stato: 'rendicontati' | 'nonRendicontati' | 'validati' | '') => {
        this.filtriStato = stato

        this.settaFattoriVisibili()
    }

    inserisciCodiceCategoria = (categoria: IFattoreRendicontabile) => {

        this.filtriCategorie.add(categoria.codice)

        if (categoria.sotto_fattori.length > 0) {
            categoria.sotto_fattori.forEach(sottoCategoria => {
                this.applicaFiltriCategorie(sottoCategoria)
            })
        }
    }

    rimuoviCodiceCategoria = (categoria: string) => {

        this.filtriCategorie.delete(categoria)

        this.settaFattoriVisibili()

    }

    //Setta i filtri per categoria
    applicaFiltriCategorie = (categoria?: IFattoreRendicontabile) => {


        if (categoria) {

            this.inserisciCodiceCategoria(categoria)

            if (categoria.sotto_fattori.length > 0) {
                categoria.sotto_fattori.forEach((sottoCategoria) => {
                    this.inserisciCodiceCategoria(sottoCategoria)
                })
            }

        } else {
            this.filtriCategorie.clear()
        }

        this.settaFattoriVisibili()
    }

    //Setta i filtri per tipo
    applicaFiltriTipo = (tipo: 'fattore' | 'tabella' | '') => {
        this.filtriTipo = tipo

        this.settaFattoriVisibili()
    }

    //Setta un filtro sui fattori visualizzati
    settaFattoriVisibili = () => {

        let listaFattori = this.businessUnitAttiva?.fattori_rendicontabili ? this.businessUnitAttiva?.fattori_rendicontabili : []

        listaFattori = listaFattori.filter(f => f.tipo === 'fattore' || f.tipo === 'tabella')

        if (this.filtriRicerca) {

            listaFattori = listaFattori.filter(f => JSON.stringify(f).toLowerCase().includes(this.filtriRicerca.toLowerCase()))

        }

        //Filtra per stato
        if (this.filtriStato) {

            switch (this.filtriStato) {
                case ('nonRendicontati'):
                    listaFattori = listaFattori.filter(f => f.indicatori.length === 0)
                    break;
                case ('rendicontati'):
                    listaFattori = listaFattori.filter(f => f.indicatori.length > 0 && !f.indicatori[0].validato)
                    break;
                case ('validati'):
                    listaFattori = listaFattori.filter(f => f.indicatori.length > 0 && f.indicatori[0].validato)
                    break;

            }


        }

        //Filtra per categoria
        if (this.filtriCategorie.size > 0) {
            listaFattori = listaFattori.filter(f => this.filtriCategorie.has(f.codice_padre as string))
        }

        //Filtra per tipo
        if (this.filtriTipo) {
            if (this.filtriTipo === 'fattore') {
                listaFattori = listaFattori.filter(f => f.tipo === 'fattore')
            }
            if (this.filtriTipo === 'tabella') {
                listaFattori = listaFattori.filter(f => f.tipo === 'tabella')
            }
        }

        this.fattoriVisibili = listaFattori
    }

    //Setta le categorie visibili
    settaCategorieVisibili = () => {

        //Filtra le categorie e gli standard
        let listaCategorie = this.businessUnitAttiva?.fattori_rendicontabili ? this.businessUnitAttiva?.fattori_rendicontabili.filter(f => f.tipo === 'categoria' || f.tipo === 'standard') : []

        //Elimina sotto fattori originali
        listaCategorie.map(c => c.sotto_fattori = [])

        //Ricostruisce alberatura
        listaCategorie.map(c =>
            c.sotto_fattori = listaCategorie.filter(sc => sc.codice_padre === c.codice)
        )

        //Crea una lista di tutti i codici
        let listaCodiciCategorie: string[] = [];

        listaCategorie.forEach(c => {
            c.codice && listaCodiciCategorie.push(c.codice)
        })

        //Mantiene solo il primo livello
        listaCategorie = listaCategorie.filter(c => !listaCodiciCategorie.includes(c.codice_padre as string))

        //Setta le categorie nello stato
        this.categorieVisibili = listaCategorie
    }

    //Aggiorna dinamicamente un indicatore durante la rendicontazione
    applicaAggiornamentoIndicatore = (fattoriCodici: string[], indicatore?: IIndicatore, tabellaCodice?: string, eliminazione: boolean = false) => {

        const rimpiazzaIndicatoreTabella = (fattoreCodice: string) => {

            if (indicatore) {


                //elimina indicatore
                let indicatori = nuovaBu?.fattori_rendicontabili
                    .find(f => f.codice === tabellaCodice)?.sotto_fattori
                    .find(sf => sf.codice === fattoreCodice)?.indicatori

                if (indicatori) {

                    for (let i = 0; i < indicatori.length; i++) {
                        if (indicatori[i].id === indicatore.id) {

                            nuovaBu?.fattori_rendicontabili
                                .find(f => f.codice === tabellaCodice)?.sotto_fattori
                                .find(sf => sf.codice === fattoreCodice)?.indicatori
                                .splice(i, 1);

                            break;
                        }
                    }

                }


                if (!eliminazione) {

                    //Inserisce nuovo indicatore
                    nuovaBu?.fattori_rendicontabili
                        .find(f => f.codice === tabellaCodice)?.sotto_fattori
                        .find(sf => sf.codice === fattoreCodice)?.indicatori
                        .push(indicatore)

                }

            }

        }

        let nuovaBu = this.businessUnitAttiva;

        //Agisce per il fattore singolo

        if (fattoriCodici.length === 1) {
            //Elimina vecchio indicatore
            nuovaBu?.fattori_rendicontabili
                .find(f => f.codice === fattoriCodici[0])?.indicatori.pop()

            //Inserisce il nuovo indicatore nella struttura del bilancio
            if (indicatore && !eliminazione) {
                nuovaBu?.fattori_rendicontabili
                    .find(f => f.codice === fattoriCodici[0])?.indicatori.push(indicatore)
            }

        }

        //Agisce per le tabelle
        if (fattoriCodici.length === 2) {

            rimpiazzaIndicatoreTabella(fattoriCodici[0])
            rimpiazzaIndicatoreTabella(fattoriCodici[1])

        }

        //Rimpiazza il bilancio
        this.businessUnitAttiva = nuovaBu

        //Trigger aggiornamenti dell'indicatore
        this.checkAggiornamentoIndicatore = !this.checkAggiornamentoIndicatore


        this.settaFattoriVisibili()
    }


}