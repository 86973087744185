export const en_business_unit = {
    ModuloBusinessUnitGruppi: {
        ruolo: 'Role:',
        nessunoGruppoCollegato: 'No group connected to this business unit',
    },

    FormBusinessUnitGruppo: {
        attribuisciBusinessUnit: 'Assign roles to this business unit',
        testBusinessUnit: 'You can decide which groups will be connected to the business unit.',
        selezioneGruppoAggiungere: 'Select a group and add it to the business unit',
        selezioneGruppo: 'Select group',
        AttivoRuolo: 'Enable role',
        ruolii: 'Roles',
        salvaRuoli: 'Save roles',
    },

    FormBusinessUnit: {
        verificatoErrore: 'An error occurred during the request, you can still save by manually entering the address data, but the business unit will not appear in the maps.',
        modificaBusinessUnit: 'Edit business unit',
        creaBusinessUnit: 'Create new business unit',
        figliaDi: 'child of',
        placeholderBusinessUnit: 'business unit name',
        placeHolderDescrizione: 'business unit description',
        via: 'Street',
        civico: 'Number',
        cap: 'CAP',
        citta: 'City',
        paese: 'Country',
        cercandoIndrizzio: 'Searching address',
        geolocalizza: 'Geo-locate address',
        long: 'Long:',
        lat: 'Lat:',
        nessunoIndrizoTrovato: 'No address found',
        anullaIndrizzo: 'Cancel address',
        aggiungiIndrizzo: 'Add an address',
        businessunitCreatoSucesso: 'Business Unit successfully created',
        creaBusinessUnitButtone: 'Create Business Unit',
    },

    TileGruppoBusinessUnit: {
        ruoloStatoEridato: 'This role has been inherited from',
    },

    SchedaBusinessUnit: {
        tornaAllaStratura: 'Go back to the full organizational structure',
        indrizzo: 'Address',
        coordinate: 'Coordinates',
        attiviPerQuestoBussinessUnit: 'active for this business unit',
        alberaturaSottoBusinessUnit: 'organizational structure under this Business unit',
    },

    BusinessUnitContextMenu: {
        eliminatoBusinessUnitSucesso: 'Business unit successfully deleted',
        attribuisciRuoli: 'Assign roles',
        modificaBusinessUnit: 'Edit Business Unit',
        eliminaBusinessUnit: 'Are you sure you want to delete',
        eliminaBusinessUnitTesto: 'All its member groups will lose access to associated sustainability reports.',
        eliminaLaBusinessUnit: 'Delete business unit',
    },

    TileBusinessUnit: {
        geoLocalizzataIn: 'This business unit is geo-located in',
        ruoloAttivo: 'Active role',
        ruoliAttivi: 'Active roles'
    },

    CanvasBusinessUnitHeader: {
        default: 'DEFAULT',
        manificata: 'MINIFIED',
        mappa: 'MAP',
        blocchi: 'BLOCKS',
        modVista: 'Display mode',
    },

    CanvasBusinessUnit: {
        nessunaBusinessUnitTrovatra: 'No business unit found',
    },

    MappaBusinessUnit: {
        vaiAllaBusinessUnit: 'Go to the business Unit',
        businessUnitContengono: 'business units contain an address and are thus displayed in the map.',
        su: 'on',
    },

    ModuloBusinessUnitCategorieContextMenu: {
        modificaCategoria: 'Edit category',
        categorieEliminatoSucesso: 'Category successfully deleted',
        eliminaCategoria: 'Delete category',
        eliminaCategoriaMessagio: 'Are you sure you want to delete this category?',
    },

    ArchivioBusinessUnitCategorie: {
        nessunaCategoriaPresente: 'No category available',
        aggiungiNuovaCategoria: 'Add new category',
    },

    FormBusinessUnitCategoria: {
        modificaCategoria: 'Edit category',
        creaNuovaCategoria: 'Create new category.',
        modificaDatiDellaCategoria: 'Edit category data',
        categoriaCreatoSuccesso: 'Category Successfully created',
        creaCategoriaButtone: 'Create category',
    },

    BusinessUnitRuoloContextMenu: {
        ruoloEliminatoSucesso: 'Role successfully deleted',
        eliminaRuolo: 'Are you sure you want to delete the role',
        eliminaIlRuolo: 'Delete the role',
        modificaRuolo: 'Edit role'
    },

    BusinessUnitRuoloForm: {
        ModificaRuolo: 'Edit role',
        creaRuolo: 'Create new role',
        modificaDatiDellaRuoli: 'Edit role data',
        messaggioRuoli: 'Associates groups to allow users in them to access features',
        placeholderNome: 'Visualization, editing, elimination',
        ruoloCreatoSucesso: 'Role successfully created',
        creaRuoloButtone: 'Create role',
    },

    ArchivioBusinessUnitRuoli: {
        nessunoRuoloPresente: 'No role available',
        aggiungiRuolo: 'Add new role',
    },

    ArchivioBussinessUnit: {
        testoStrutturaOrganizzativa: 'Company organizational structure',
        testoIntegrando: 'By interacting with the tree, it\'s possible to implement the organizational structure of the company.',
        testoEPossibile: 'Continents, countries, headquarters and business units can be entered. Each of the units created can then be assigned to user groups and have a role defined.',
        testoLeBusinessUnit: 'Business units are meant to be used to establish access privileges in various other modules of the application, for example, allowing only certain groups of users to interact with sustailabilty reports, defining reporting responsibilities among different stakeholders and operators.',
        chiudi: 'Close',
        leggiPiu: 'Read more',
        creaNuovaBusinessUnit: 'Create new business unit',
    },
}