import React from 'react'

//Data
import { ITemaMaterialita } from '../../../../types'
import useOttieniPrivilegioModulo from '../../../../../../hooks/useOttieniPrivilegioModulo'
import { useStores } from '../../../../../../hooks/useStores'

//Components
import TemaMaterialitaContextMenu from './TemaMaterialitaContextMenu'
import { FaEllipsisV } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const TemaMaterialitaTile: React.FC<{ tema: ITemaMaterialita }> = ({ tema }) => {

  const { moduli } = useStores()
  const privilegio = useOttieniPrivilegioModulo()
  const [contextMenu, setContextMenu] = React.useState(false)


  const toggleContextMenu = () => {
    //Il timeout permette di far comparire il context menu dopo che l'utente ha cliccato
    setTimeout(() => {
      setContextMenu(!contextMenu)
    }, 1)
  }

  document.addEventListener('click', () => {
    if (contextMenu) {
      setContextMenu(false)
    }
  }, { once: true })


  return (
    <div
      className="overflow-hidden bg-white dark:bg-darkgray-dd shadow-xl rounded-sm border-l-4 p-5"
      style={{ borderColor: tema.colore ? tema.colore : '#333333' }}
    >
      <div className="flex items-start justify-between">
        <div>
          <Link to={`/app/modulo/${moduli.moduloAttivo?.codice}/${tema.codice}`} className="flex items-center gap-2">
            <h2 className="font-bold hover:underline whitespace-pre-wrap text-xl">{tema.nome}</h2>
          </Link>
        </div>


        {
          privilegio >= 3 &&
          <div className="cursor-pointer relative p-1" onClick={() => toggleContextMenu()}>
            <FaEllipsisV className="hover:opacity-70" />

            {contextMenu && <TemaMaterialitaContextMenu tema={tema} />}

          </div>
        }
      </div>

      <hr className="my-2 border border-lightgray-d" />
      <p>{tema.descrizione}</p>
    </div>
  )
}

export default TemaMaterialitaTile;
