import { useEffect, useState } from 'react'
import { useStores } from './useStores'

const useOttieniPrivilegioModulo = () => {
    
    const { moduli } = useStores()

    const [privilegio, setPrivilegio] = useState(0)
    
    useEffect(() => {

        setPrivilegio(moduli.moduloAttivo?.privilegio as number);

    }, [moduli.moduloAttivo]) 

    return privilegio;
    
}

export default useOttieniPrivilegioModulo;