import React, { useEffect, useState } from 'react'

//Data
import { useModuloESGStores } from '../context/useModuloESGStores'
import { IFattoreESG } from '../types'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { useStores } from '../../../hooks/useStores'

//Components
import { FaPencilAlt } from 'react-icons/fa'
import CanvasFattoriESG from '../components/CanvasFattoriBilancio/CanvasFattoriESG'
import InformativaFattoreESG from '../components/InformativaFattoreESG'
import FattoriESGForm from '../components/fattoriESG/FattoreESGForm'
import IndicatoriSchedaFattoriESG from '../components/fattoriESG/IndicatoriSchedaFattoriESG'
import IndicatoriSchedaTabellaESG from '../components/fattoriESG/IndicatoriSchedaTabellaESG'
import CreaListaFlatFattoriEsg from '../script/creaListaFlatFattoriEsg'
import CanvasApplicaObiettiviAgenda2030Fattori from './UiModuloFattoriEsgAgenda2030/components/CanvasApplicaObiettiviAgenda2030Fattori/CanvasApplicaObiettiviAgenda2030Fattori'

const SchedaFattoreESG = observer(() => {

  const { ui, moduli } = useStores()
  const { fattori_esg } = useModuloESGStores()

  const { fattore_codice } = useParams()

  const [listaFlat, setListaFlat] = useState<IFattoreESG[]>([])

  useEffect(() => {

    if (fattori_esg.fattoreESGAttivo?.sotto_fattori && fattori_esg.fattoreESGAttivo?.sotto_fattori.length > 0) {
      setListaFlat(CreaListaFlatFattoriEsg(fattori_esg.fattoreESGAttivo?.sotto_fattori))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fattori_esg.fattoreESGAttivo])

  useEffect(() => {

    fattori_esg.getFattoreESG(fattore_codice as string)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fattore_codice,
    fattori_esg.fattoreESGModificato,
    fattori_esg.fattoreESGObiettivi2030Collegati,
    fattori_esg.fattoreESGObiettivi2030Scollegati
  ])

  return (
    <div>

      <div className="flex justify-end">
        <FaPencilAlt className="cursor-pointer hover:text-accent" onClick={() => ui.mountAsideModal(<FattoriESGForm codice_padre={fattori_esg.fattoreESGAttivo?.codice_padre as string} fattore={fattori_esg.fattoreESGAttivo as IFattoreESG} />)} />
      </div>

      <div className="my-2">
        <InformativaFattoreESG loader="fattori_esg" />
      </div>

      {
        (
          fattori_esg.fattoreESGAttivo?.tipo === 'categoria' ||
          fattori_esg.fattoreESGAttivo?.tipo === 'standard'
        ) &&
        <>
          {
            moduli.moduloAttivo?.codice === 'fattori_esg_agenda_2030' ?
              <CanvasApplicaObiettiviAgenda2030Fattori
                fattori={fattori_esg.fattoreESGAttivo?.sotto_fattori as IFattoreESG[]}
                fattori_flat={listaFlat}
              />
              :
              <CanvasFattoriESG
                fattori={fattori_esg.fattoreESGAttivo?.sotto_fattori as IFattoreESG[]}
                fattori_flat={listaFlat}
                loader="fattori_esg"
              />
          }
        </>
      }

      {fattori_esg.fattoreESGAttivo?.tipo === 'fattore' &&
        <IndicatoriSchedaFattoriESG fattore={fattori_esg.fattoreESGAttivo} />
      }

      {fattori_esg.fattoreESGAttivo?.tipo === 'tabella' &&
        <IndicatoriSchedaTabellaESG tabella={fattori_esg.fattoreESGAttivo} />
      }

    </div>
  )
})

export default SchedaFattoreESG;