import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
//Data
import { observer } from 'mobx-react-lite'
import { useStores } from '../../hooks/useStores'
import useOttieniPrivilegioModulo from '../../hooks/useOttieniPrivilegioModulo'

//Components
import { FaInfoCircle, FaKey, FaSort } from 'react-icons/fa'
import { IModulo } from '../../types'
import { Link } from 'react-router-dom'
import ModuloTile from './ModuloTile'
import ModuloForm from './ModuloForm'
import AggiungiButton from '../../components/common/AggiungiButton'
import OrdinaModuli from './OrdinaModuli'

const GestioneModuli = observer(() => {
  const { t } = useTranslation();

  const privilegio = useOttieniPrivilegioModulo();
  const { ui, gestioneModuli, gestionePrivilegi } = useStores();

  useEffect(() => {
    gestioneModuli.getModuli();
    gestionePrivilegi.getPrivilegi();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gestioneModuli.creatingModulo,
    gestioneModuli.moduloEliminato,
    gestioneModuli.moduloModificato,
    gestioneModuli.moduliOrdinati,
    gestionePrivilegi.privilegioAttribuito
  ]
  )

  return (
    <div>
      {
        gestioneModuli.moduli.length === 0 &&
        <h3 className="flex items-center justify-center gap-2 text-2xl my-8 text-text-light">
          <FaInfoCircle /> {t('ModuloGestioneModuli.Archivio.nessunoModulo')}
        </h3>
      }

      {
        gestioneModuli.moduli.length > 0 &&
        <>

          {privilegio >= 2 &&
            <div className="my-4" onClick={() => ui.mountAsideModal(<ModuloForm />)}>
              <AggiungiButton testo={t('ModuloGestioneModuli.Archivio.nuovoModulo')} />
            </div>
          }

          <div className="flex flex-col gap-4">
            <div className="flex items-center justify-between my-2">
              <div></div>

              <div className="flex gap-2">
                {privilegio >= 3 &&
                  <button
                    className="cursor-pointer text-white bg-accent dark:bg-dark-accent p-2 rounded-sm flex gap-2 items-center"
                    onClick={() => ui.mountAsideModal(<OrdinaModuli />)}
                  >
                    <FaSort />
                    {t('ModuloGestioneModuli.Archivio.riordinaModuli')} 
                  </button>
                }
                <Link to='/app/modulo/gestione_privilegi' className="cursor-pointer text-white bg-accent dark:bg-dark-accent p-2 rounded-sm flex gap-2 items-center dark:text-white">
                  <FaKey />
                  {t('ModuloGestioneModuli.Archivio.passaGestionePrivilegi')} 
                </Link>
              </div>
            </div>
            {
              gestioneModuli.moduli && gestioneModuli.moduli.map((modulo: IModulo) => (
                <div key={modulo.codice}>
                  <ModuloTile modulo={modulo} modifica={true} privilegi={gestionePrivilegi.privilegi} />
                </div>
              ))
            }

          </div>
        </>
      }

      {privilegio >= 2 &&
        <div className="my-4" onClick={() => ui.mountAsideModal(<ModuloForm />)}>
          <AggiungiButton testo={t('ModuloGestioneModuli.Archivio.nuovoModulo')} />
        </div>
      }
    </div>
  )
})

export default GestioneModuli;