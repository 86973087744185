import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../../../hooks/useStores'
import { ITemaMaterialitaAnno } from '../../types'
import { observer } from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../../../hooks/useOttieniPrivilegioModulo'

//Components
import Dialogue from '../../../../components/common/Dialogue'
import TemaMaterialitaBilancioForm from './TemaMaterialitaBilancioForm'
import { useModuloESGStores } from '../../context/useModuloESGStores'

const TemaMaterialitaBilancioContextMenu: React.FC<{ tema: ITemaMaterialitaAnno }> = observer(({ tema }) => {

  const privilegio = useOttieniPrivilegioModulo()
  const { t } = useTranslation();
  const { ui } = useStores()
  const { bilanci } = useModuloESGStores()

  const handleScollegaTemaMaterialita = () => {
    bilanci.scollegaTemaMaterialitaBilancio(bilanci.bilancioAttivo?.anno as number, tema.codice).then(() => {

      if (bilanci.scollegaTemaMaterialitaBilancioError) {

        ui.mountDialogue(<Dialogue testo={bilanci.scollegaTemaMaterialitaBilancioError} />)

      } else {

        ui.mountDialogue(<Dialogue testo={ t('ModuloFattoriEsg.TemaMaterialitaBilancioContextMenu.scollegatoSucesso') } />)

      }

    });
  }

  return (
    <div className="absolute shadow-lg border border-lightgray-dd bg-white dark:bg-black p-3 right-0 dark:border-0">
      <ul className="whitespace-nowrap flex flex-col gap-2">
        {privilegio >= 3 && <li onClick={() => ui.mountAsideModal(<TemaMaterialitaBilancioForm tema={tema} />)}>{ t('ModuloFattoriEsg.TemaMaterialitaBilancioContextMenu.modificaParametri') }</li>}
        {privilegio >= 4 &&
          <li
            className="text-red dark:text-dark-red"
            onClick={() => ui.mountDialogue(
              <Dialogue
                testo={`${t('ModuloFattoriEsg.TemaMaterialitaBilancioContextMenu.scollegaTemiMaterialita') } ${tema.nome} ${t('ModuloFattoriEsg.TemaMaterialitaBilancioContextMenu.daQuestoBilancio') }`}
                primaryCta={`${t('ModuloFattoriEsg.TemaMaterialitaBilancioContextMenu.scollegaIlTemaMaterialita') } ${tema.nome}`}
                primaryCtaColor="bg-red dark:bg-dark-red"
                secondaryCta={t('common.annulla') }
                secondaryCtaColor="bg-white dark:bg-darkgray-dd !text-text dark:!text-dark-text"
                onConfirm={() => handleScollegaTemaMaterialita()}
              />)}
          >
            {t('ModuloFattoriEsg.TemaMaterialitaBilancioContextMenu.scollegaDalBilancio') }
          </li>
        }
      </ul>
    </div>
  )
})

export default TemaMaterialitaBilancioContextMenu;
