import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { observer } from 'mobx-react-lite'
import { useModuloESGStores } from '../../context/useModuloESGStores'
import { IFattoreESG } from '../../types'
import { runInAction } from 'mobx'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { getFattoreESG } from '../../rest/fattori_esg'
import { getUnitaMisuraLista } from '../../../gestioneUnitaMisura/rest/gestioneUnitaMisura'

//Components
import InputField from '../../../../components/common/form/InputField'
import ErrorBox from '../../../../components/common/ErrorBox'
import InfoBox from '../../../../components/common/InfoBox'
import SpinnerButton from '../../../../components/common/SpinnerButton'
import Select from 'react-select'
import TestoErrore from '../../../../components/common/TestoErrore'
import { FaPlus, FaTrash } from 'react-icons/fa'
import TraduzioniForm from '../../../../components/common/form/TraduzioniForm'
import { getLinguaLista } from '../../../gestioneLingue/rest/gestioneLingue'
import { ILingua } from '../../../../types'
import { useStores } from '../../../../hooks/useStores'

const FattoreESGForm: React.FC<{ fattore?: IFattoreESG, codice_padre?: string, tipo_fattore?: string, scegli_tipo?: boolean }> = observer(({ fattore, codice_padre, tipo_fattore, scegli_tipo }) => {

  const { t } = useTranslation();

  const { register, handleSubmit, watch, control, setValue, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      codice: fattore ? fattore.codice : '',
      codice_originale: fattore ? fattore.codice : '',
      nome: fattore ? fattore.nome : '',
      nome_esteso: fattore ? fattore.nome_esteso : '',
      descrizione: fattore ? fattore.descrizione : '',
      richiesto: fattore ? fattore.richiesto : false,
      tipo: fattore ? fattore.tipo : tipo_fattore ? tipo_fattore : '',
      tipo_indicatore: fattore ? fattore.tipo_indicatore : '',
      unita_misura: fattore ? fattore.unita_misura : '',
      risposte_multiple: fattore ? fattore.risposte_multiple : '',
      num_risposte_multiple: fattore ? fattore.num_risposte_multiple : null,
      codice_padre: codice_padre ? codice_padre : null,
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "risposte_multiple",
  });

  const [listaUnitaMisura, setListaUnitaMisura] = useState<any[]>([])

  //Setta le unità di misura
  useEffect(() => {
    getUnitaMisuraLista().then((res) => {
      setListaUnitaMisura([...res.data.data, {
        label: 'Nessuna',
        value: null
      }])
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Ristruttura dati per label MultiSelect
  listaUnitaMisura.filter((um) => !um.label).map((unitaMisura: any) => {
    unitaMisura.value = unitaMisura.codice
    unitaMisura.label = unitaMisura.codice
  })
  const { auth } = useStores();
  const [fattoreAttivo, setFattoreAttivo] = useState<IFattoreESG | null>(
    fattore ? fattore : null
  );
  const { fattori_esg } = useModuloESGStores();

  const onSubmit = () => {
    fattore
      ?
      fattori_esg.modificaFattoreESG(watch() as IFattoreESG).then(() => {
        setValue('codice_originale', watch('codice'))
      })
      :
      fattori_esg.creaFattoreESG(watch() as IFattoreESG)
  }

  useEffect(() => {
    runInAction(() => fattori_esg.creatingFattoreESGError = '')
    runInAction(() => fattori_esg.modificaFattoreESGError = '')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Tipi disponibili
  let tipiDefault = [
    { value: '', label: 'Scegli un tipo' },
    { value: 'fattore', label: 'Fattore' },
    { value: 'categoria', label: 'Categoria' },
    { value: 'tabella', label: 'Tabella' },
  ];

  const [tipoPadre, setTipoPadre] = useState<string | null>(null)
  const [tipi, setTipi] = useState(tipiDefault)

  //Ottiene il tipo del fattore padre
  useEffect(() => {

    if (codice_padre) {
      getFattoreESG(codice_padre as string).then((res) => setTipoPadre(res.data.data.tipo))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Riduce i tipi disponibili se il fattore padre è una tabella
  useEffect(() => {

    if (tipoPadre === 'tabella') {
      setTipi([
        { value: '', label: 'Scegli un tipo' },
        { value: 'riga', label: 'Riga' },
        { value: 'colonna', label: 'Colonna' },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoPadre])

  const [scegliTipo, setScegliTipo] = useState(scegli_tipo)

  //in caso il form sia in modalita modifica abilita la possibilita di scegliere un tipo
  useEffect(() => {
    if (scegli_tipo !== false && fattore && fattore.tipo !== 'standard') {
      setScegliTipo(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [listaLingue, setListaLingue] = useState<ILingua[]>();
  const [linguaAttiva, setLinguaAttiva] = useState<ILingua>();
  const [visibilitaListaLingue, setVisibilitaListaLingue] =
    useState<boolean>(false);

  useEffect(() => {
    getLinguaLista().then((res) => {
      setListaLingue(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (auth.utente && listaLingue && listaLingue.length > 0) {
      setLinguaAttiva(
        listaLingue.find(
          (lingua: ILingua) => lingua.codice === auth.utente?.lingua
        )
      );
    }
  }, [listaLingue]);

  useEffect(() => {
    if (fattore) {
      getFattoreESG(fattore.codice, linguaAttiva?.codice).then((res) => {
        setFattoreAttivo(res.data.data);
      });
    }
  }, [linguaAttiva]);

  useEffect(() => {
    if (fattoreAttivo) {
      setValue("nome", fattoreAttivo.nome);
      setValue("nome_esteso", fattoreAttivo.nome_esteso);
      setValue("descrizione", fattoreAttivo.descrizione);
    }
  }, [fattoreAttivo]);

  const handleLinguaAttiva = (lingua: ILingua) => {
    setLinguaAttiva(lingua);
    setVisibilitaListaLingue(false);
  };

  // Ricostruisci risposte multiple se presenti
  useEffect(() => {


    if (fattore?.risposte_multiple) {

      fields.forEach(() => {
        remove(0)
      })

      fattore.risposte_multiple.forEach((risposta: any) => {
        append({
          risposte_multiple: 'risposta',
          value: risposta
        })
      })

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div className="mb-4">
        <h2 className="text-2xl mb-2 capitalize">
          {fattore ?
            `${t('common.modifica')} ${tipo_fattore ? tipo_fattore : t('ModuloFattoriEsg.formFattorEsg.ilFattore')} ${fattore.nome_esteso}`
            :
            `${t('common.crea')} ${tipo_fattore ? tipo_fattore : t('common.fattore')}`
          }
        </h2>
        <p className="text-text-light">
          {fattore ?
            `${t('ModuloFattoriEsg.formFattorEsg.modificaIDati')}  ${tipo_fattore ? tipo_fattore : t('ModuloFattoriEsg.formFattorEsg.delFattore')} ${fattore.nome_esteso}`
            :
            `${t('ModuloFattoriEsg.formFattorEsg.stabilisciCodice')} ${tipo_fattore ? tipo_fattore : t('common.fattore')}`
          }
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>

        <InputField
          label={t('common.codice')}
          type="text"
          error={errors.codice}
          form={register("codice", { required: t('ModuloFattoriEsg.formFattorEsg.fornisciUnCodice') })}
          placeholder={t('ModuloFattoriEsg.formFattorEsg.placeholderFattore')}
        />
        <TraduzioniForm>
          {fattore && listaLingue && listaLingue?.length > 1 && (
            <div className="my-2">
              {visibilitaListaLingue ? (
                <div className="flex items-center">
                  {listaLingue?.map((lingua) => (
                    <div
                      className={`cursor-pointer p-2 bg-lighhtgray-d ${linguaAttiva?.codice === lingua.codice &&
                        "pointer-events-none bg-accent"
                        }`}
                      onClick={() => handleLinguaAttiva(lingua)}
                    >
                      <span className={`fi fi-${lingua.bandiera}`} />
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <p>
                    {t("ModuloFattoriEsg.formFattorEsg.modificandoLinguaIn")}{" "}
                    {linguaAttiva?.nome}
                  </p>
                  {!visibilitaListaLingue && (
                    <div
                      className="cursor-pointer text-accent dark:text-dark-accent"
                      onClick={() =>
                        setVisibilitaListaLingue(!visibilitaListaLingue)
                      }
                    >
                      {t("common.cambiaLingua")}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          <InputField
            label={t('common.nome')}
            type="text"
            error={errors.nome}
            form={register("nome", { required: t('ModuloFattoriEsg.formFattorEsg.fornisciUnNome') })}
            placeholder={t('ModuloFattoriEsg.formFattorEsg.placeholderFattore')}
          />

          <InputField
            label={t('common.nomeEsteso')}
            type="text"
            error={errors.nome_esteso}
            form={register("nome_esteso")}
            placeholder={t('ModuloFattoriEsg.formFattorEsg.placeHolderNomeEsteso')}
          />

          <InputField
            label={t('common.descrizione')}
            type="textarea"
            error={errors.descrizione}
            form={register("descrizione")}
            placeholder={t('ModuloFattoriEsg.formFattorEsg.placeholderDescrizionefattore')}
          />
        </TraduzioniForm>


        {scegliTipo &&
          <div className="form">
            <label>{t('common.tipo')}</label>
            <Controller
              name="tipo"
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  options={tipi}
                  defaultValue={tipi.find(t => t.value === fattore?.tipo)}
                  onChange={(e: any) => onChange(e.value)}
                />
              )}
            />
          </div>
        }

        {(watch('tipo') === 'fattore' || watch('tipo') === 'tabella' || watch('tipo') === 'riga' || watch('tipo') === 'colonna') &&
          <div className="my-4">
            <label className="block">{t('common.tipo_indicatore')}</label>
            <p className="opacity-70 mb-2">{t('ModuloFattoriEsg.formFattorEsg.specificaTipoIndicatore')}</p>
            <div className="form">
              <Controller
                name="tipo_indicatore"
                control={control}
                render={({ field: { onChange } }) => (
                  <Select
                    options={[
                      {
                        label: 'Nessuna',
                        value: null
                      },
                      {
                        label: 'Numerico',
                        value: 'numerico'
                      },
                      {
                        label: 'Testuale',
                        value: 'testuale'
                      },
                      {
                        label: 'Risposta multipla',
                        value: 'risposta_multipla'
                      },
                      {
                        label: 'Vero / falso',
                        value: 'booleano'
                      },
                    ]}
                    onChange={(e: any) => onChange(e.value)}
                  />
                )}
              />
            </div>

            {watch('tipo_indicatore') === 'risposta_multipla' &&
              <div>
                <p className="my-2 opacity-70">
                  {t('ModuloFattoriEsg.formFattorEsg.aggiungiRispostePossibili')}
                </p>
                {fields.map((field, index) => (
                  <div className="p-2 bg-lightgray-d dark:bg-darkgray-d flex items-stretch gap-2 mb-2 form">
                    <input
                      key={field.id}
                      {...register(`risposte_multiple.${index}.value`, { required: "Scrivi una risposta" })}
                      type="text"
                      placeholder="Contenuto della risposta"
                    />

                    <div
                      className="cursor-pointer bg-red dark:bg-dark-red flex items-center justify-center w-[40px] text-white rounded-sm"
                      onClick={() => remove(index)}>
                      <FaTrash />
                    </div>
                  </div>
                ))}

                <div
                  onClick={() => append({ risposte_multiple: 'risposta' })}
                  className="hover:opacity-70 w-full p-2 cursor-pointer border border-accent dark:border-dark-accent flex items-center justify-center text-center gap-2"
                >
                  <span>{t('ModuloFattoriEsg.formFattorEsg.aggiungiRisposta')}</span>
                  <FaPlus />
                </div>

                <div className="form">
                  <label className="mt-2" htmlFor="">
                    {t('ModuloFattoriEsg.formFattorEsg.stabilisciRispostePossibili')}
                  </label>
                  <input
                    {...register("num_risposte_multiple")}
                    type="number"
                    min="1"
                    max={watch('risposte_multiple').length}
                  />
                </div>

              </div>
            }

            {watch('tipo_indicatore') === 'numerico' &&
              <div className="form w-full">
                <label htmlFor=""> {t('ModuloFattoriEsg.formFattorEsg.defaultUM')}</label>
                <p className="opacity-70 mb-2">{t('ModuloFattoriEsg.formFattorEsg.specificaSelezioneUM')}</p>
                <Controller
                  name="unita_misura"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Select
                      options={listaUnitaMisura}
                      onChange={(e: any) => onChange(e.value)}
                      placeholder={t('ModuloFattoriEsg.FormIndicatore.placeholderUm')}
                    />
                  )}
                />
              </div>
            }
          </div>
        }

        {
          (watch('tipo') === 'categoria' || watch('tipo') === 'tabella' || watch('tipo') === 'fattore') &&
          <div className="p-3 bg-lightgray-d dark:bg-black">
            <div className="mb-3 flex items-center">
              <input className="mr-2" {...register("richiesto")} type="checkbox" step="any" />
              <label htmlFor="">
                {t('ModuloFattoriEsg.formFattorEsg.richiesto')}
              </label>
            </div>

            <p className="text-sm opacity-70">
              {t('ModuloFattoriEsg.formFattorEsg.determinaSeQuesto')}
            </p>
          </div>
        }

        {errors.tipo && <TestoErrore errore={errors.tipo.message} />}

        {/* Submit modifica fattore */}
        {fattore &&

          <>
            <div className="form pt-4">
              {!fattori_esg.editingFattoreESG ? <input type="submit" value={t('common.salvaModifica')} /> : <SpinnerButton />}
            </div>

            {
              isSubmitSuccessful && !fattori_esg.editingFattoreESG && (

                fattori_esg.modificaFattoreESGError
                  ?
                  <div className="my-2">
                    <ErrorBox errore={fattori_esg.modificaFattoreESGError} />
                  </div>
                  :
                  <div className="my-2">
                    <InfoBox messaggio={t('common.modificaSalvato')} />
                  </div>

              )
            }

          </>
        }

        {/* Submit creazione fattore */}
        {!fattore &&

          <>
            <div className="form pt-4">
              {!fattori_esg.creatingFattoreESG ? <input type="submit" value={`${t('common.crea')} ${watch('tipo')}`} id="crea-Fattore-submit" /> : <SpinnerButton />}
            </div>

            {
              isSubmitSuccessful && !fattori_esg.creatingFattoreESG && (
                fattori_esg.creatingFattoreESGError ?
                  <div className="mb-2">
                    <ErrorBox errore={fattori_esg.creatingFattoreESGError.toString()} />
                  </div>
                  :
                  <div className="mb-2">
                    <InfoBox messaggio={`${watch('tipo')} ${t('ModuloFattoriEsg.formFattorEsg.FattorecreatoConSucesso')}`} id={"crea-Fattore-success"} />
                  </div>
              )
            }

          </>
        }
      </form>
    </div>
  )
})

export default FattoreESGForm;