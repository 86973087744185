import React from 'react'

//Data
import { IBusinessUnit } from '../../types';
import { useStores } from '../../../../hooks/useStores';
import { observer } from 'mobx-react';

//Components
import { FaPlus } from 'react-icons/fa';
import BusinessUnitsForm from './../BusinessUnitsForm';

const BottoneAggiungiBusinessUnit: React.FC<{ businessUnit: IBusinessUnit }> = observer(({ businessUnit }) => {

  const { ui } = useStores()

  return (
    <div className="min-w-[250px] flex justify-center relative">
      <div
        onClick={() => ui.mountAsideModal(<BusinessUnitsForm nomePadre={businessUnit.nome} />)}
        className="bg-white dark:bg-darkgray-dd p-4 flex items-start justify-between w-auto rounded-full cursor-pointer hover:bg-accent hover:text-white"
      >
        {/* Header */}

        <div className="pointer-events-none bg-lightgray-d dark:bg-darkgray-d w-[calc(50%)] h-[15px] border-accent absolute top-[-20px] right-0" />

        <p><FaPlus /></p>

        <div className="bg-accent w-[2px] h-[12px] absolute top-[-12px] mx-auto left-[calc(50%-1px)]"></div>
      </div>
    </div>
  )
})


export default BottoneAggiungiBusinessUnit;
