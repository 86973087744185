import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { observer } from 'mobx-react-lite'

//Components
import { UseModuloBusinessUnitsStore } from '../../context/UseModuloBusinessUnitsStore'

const CanvasBusinessUnitHeader = observer(() => {

    const { canvas_business_units } = UseModuloBusinessUnitsStore();
    const { t } = useTranslation();
    const styles = {
        bottone: "cursor-pointer hover:bg-accent dark:hover:bg-dark-accent hover:text-white duration-200 min-w-[36px] h-[36px] flex items-center justify-center",
        bottoneAttivo: "text-accent bg-lightgray-dd dark:bg-darkgray-dd dark:text-dark-accent dark:hover:text-white"
    }

    return (
        <div>
            <div className="flex gap-2 justify-end my-2">
                <div>
                    <p className="text-[10px] mb-1 text-text opacity-50">Mod. vista</p>
                    <div className="flex items-center bg-lightgray-d dark:bg-darkgray-d rounded-sm border border-lightgray-dd dark:border-darkgray-dd overflow-hidden">
                        {/* bottone modalita standard */}
                        <div className={`${styles.bottone} ${canvas_business_units.modalitaVista === 'default' && styles.bottoneAttivo}`} onClick={() => canvas_business_units.settaModalitaVista('default')}>
                            <span className="text-[8px] font-bold px-1">{t('ModuloBusinessUnit.CanvasBusinessUnitHeader.default')}</span>
                        </div>

                        {/* bottone modalita minificata */}
                        <div className={`${styles.bottone} ${canvas_business_units.modalitaVista === 'minificata' && styles.bottoneAttivo}`} onClick={() => canvas_business_units.settaModalitaVista('minificata')}>
                            <span className="text-[8px] font-bold px-1">{t('ModuloBusinessUnit.CanvasBusinessUnitHeader.manificata')}</span>
                        </div>

                        {/* bottone modalita mappa */}
                        <div className={`${styles.bottone} ${canvas_business_units.modalitaVista === 'mappa' && styles.bottoneAttivo}`} onClick={() => canvas_business_units.settaModalitaVista('mappa')}>
                            <span className="text-[8px] font-bold px-1">{t('ModuloBusinessUnit.CanvasBusinessUnitHeader.mappa')}</span>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
})

export default CanvasBusinessUnitHeader;