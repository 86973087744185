import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from "../../hooks/useStores"
import dayjs from "dayjs"
import { IUtente } from "../../types"
import useOttieniPrivilegioModulo from "../../hooks/useOttieniPrivilegioModulo"
import SpinnerButton from '../../components/common/SpinnerButton'
import UtenteGruppi from '../gestioneUtenti/UtenteGruppi';

//Components
import { CgClose, CgCheck } from "react-icons/cg"
import { FaEllipsisV, FaLock, FaUserTie } from "react-icons/fa"
import UtenteContextMenu from './UtenteContextMenu'
import { BsGoogle, } from "react-icons/bs"
import { SiMicrosoftazure } from "react-icons/si"
import { useForm } from "react-hook-form"
import { observer } from "mobx-react"
import ErrorBox from "../../components/common/ErrorBox"
import { use } from "i18next"




const UtenteTile: React.FC<{ utente: IUtente, boleanoMostrare: boolean }> = observer(({ utente, boleanoMostrare }) => {
  const { t } = useTranslation();
  const { gestioneUtenti, gestioneLingue, auth, ui } = useStores();
  
  const [inviandoEmail,setInviandoEmail] = useState<boolean>(false)
  
  //Gestisce submit
 
  const reinviaEmailVerifica = ()  => {
    setInviandoEmail(true)
    gestioneUtenti.reinviareEmailVerifica(utente.id)
    setTimeout(() => {
      setInviandoEmail(false)
    }, 800)
  
  }

  const privilegio = useOttieniPrivilegioModulo();
  const [contextMenu, setContextMenu] = useState(false)
  
  const toggleContextMenu = () => {
    //Il timeout permette di far comparire il context menu dopo che l'utente ha cliccato
    setTimeout(() => {
      setContextMenu(!contextMenu)
    }, 1)
  }

  document.addEventListener('click', () => {
    if (contextMenu) {
      setContextMenu(false)
    }
  }, { once: true })

  const styles = {
    wrapper: `
        bg-white dark:bg-darkgray-dd p-2 px-4 rounded-sm shadow-xl flex flex-col overflow-hidden
        ${utente.disabilitato && "border border-red dark:border-accent-red"}
        ${utente.id === auth.utente?.id && "opacity-70 pointer-events-none"}
      `,
  }

  const getBandiera = (codice_lingua: string) => {
    let lingua = gestioneLingue.lingue.filter(lingua => lingua.codice === codice_lingua).pop()

    return lingua?.bandiera
  }

  const getNomeLingua = (codice_lingua: string) => {
    let lingua = gestioneLingue.lingue.filter(lingua => lingua.codice === codice_lingua).pop()

    return lingua?.nome
  }

  return (
    <div className={styles.wrapper}>
      <div>

        {utente.disabilitato && <p className="text-xs text-red dark:text-dark-red mb-2">{t('ModuloGestioneUtenti.TileUtente.utenteDisabilitato')}</p>}

        <div className="flex justify-end">


          {privilegio >= 3 && boleanoMostrare &&
            <div className="cursor-pointer relative p-1" onClick={() => toggleContextMenu()}>
              {utente.id === auth.utente?.id
                ?
                <FaLock />
                :
                <FaEllipsisV className="hover:opacity-70" />
              }

              {contextMenu && <UtenteContextMenu utente={utente} />}
            </div>
          }
        </div>

        <div className="text-center text-accent dark:text-dark-accent">

          {utente.oauth_tipo_utente === 'google' &&
            <p className="flex justify-center mb-1" > <BsGoogle size={20}
              onMouseEnter={() => ui.mountFollowMouse(<p className="text-xs">{t('ModuloGestioneUtenti.TileUtente.utenteGoogle')}</p>)}
              onMouseLeave={() => ui.unmountFollowMouse()} /></p>
          }
          {utente.oauth_tipo_utente === 'azure' &&
            <p className="flex justify-center mb-1" ><SiMicrosoftazure size={20}
              onMouseEnter={() => ui.mountFollowMouse(<p className="text-xs">{t('ModuloGestioneUtenti.TileUtente.utenteAzureAdd')}</p>)}
              onMouseLeave={() => ui.unmountFollowMouse()} /></p>
          }
          {utente.oauth_tipo_utente === null &&
            <p className="flex justify-center mb-1" > <FaUserTie size={20}
              onMouseEnter={() => ui.mountFollowMouse(<p className="text-xs">{t('ModuloGestioneUtenti.TileUtente.utenteIpe')}</p>)}
              onMouseLeave={() => ui.unmountFollowMouse()}
            /></p>
          }

          <h4 className="font-bold text-xl text-accent dark:text-dark-accent">{utente.nome} {utente.cognome}</h4>

          <span className={`mb-1 fi fi-${getBandiera(utente.lingua)} rounded-full scale-[1]`} onMouseEnter={() => ui.mountFollowMouse(<p className="text-xs">{getNomeLingua(utente.lingua)}</p>)}
            onMouseLeave={() => ui.unmountFollowMouse()} ></span>

          <p className="font-bold">{t('ModuloGestioneUtenti.TileUtente.registroIl')} {dayjs(utente.created_at).locale('it').format('DD MMMM YYYY')} </p>
          <p className="font-bold">{utente.email}</p>
          <p className="font-bold">{utente.telefono}</p>
          <p className="font-bold">{utente.azienda}</p>

          <div className={`font-bold flex justify-center gap-1 ${utente.is_verified ? 'text-accent dark:text-dark-accent' : 'text-red dark:text-dark-red'}`}>
            {utente.is_verified ? <CgCheck size={20} /> : <CgClose className="self-center" />}
            <p>{utente.is_verified ? t('ModuloGestioneUtenti.TileUtente.emailVerificata') : t('ModuloGestioneUtenti.TileUtente.emailNonVerificat')}</p>
          </div>

          <p className={`font-bold mb-1 flex justify-center gap-1 ${utente.attivo ? 'text-accent dark:text-dark-accent' : 'text-red dark:text-dark-red'}`}>
            {utente.attivo ? <CgCheck size={20} /> : <CgClose className="self-center" />}
            {utente.attivo ? t('ModuloGestioneUtenti.TileUtente.utenteAttivo') : t('ModuloGestioneUtenti.TileUtente.utenteNonAttivo')}
          </p>

          <div className="bg-lightgray border font-bold dark:bg-darkgray-dd p-1 rounded text-xs hover:bg-lightgray-d "
            onMouseEnter={() => ui.mountFollowMouse(<UtenteGruppi utente={utente} />)}
            onMouseLeave={() => ui.unmountFollowMouse()}
          >
            {utente.gruppi?.length + " "}
            {utente.gruppi?.length && utente.gruppi?.length != 1 ? t('common.gruppi')
              : t('common.gruppo')}
          </div>
       
          {!utente.attivo && boleanoMostrare  &&
   
              <button className="block text-sm w-full py-1 rounded my-1 text-white" onClick={() => reinviaEmailVerifica()}>
                { !gestioneUtenti.reinviareEmailError && gestioneUtenti.reinviandoEmail && inviandoEmail ?
                  <SpinnerButton />
                  : t('ModuloGestioneUtenti.TileUtente.reinviaEmail')
                }
                
              </button>
          }  {!utente.attivo && gestioneUtenti.reinviareEmailError && (
            <div className="my-2">
              <ErrorBox errore={gestioneUtenti.reinviareEmailError} />
            </div>
          )
          }


        </div>
      </div>
    </div>
  )
})

export default UtenteTile;