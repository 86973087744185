import React from 'react'
import CanvasFattoriESG from '../../components/CanvasFattoriBilancio/CanvasFattoriESG'
import { useModuloESGStores } from '../../context/useModuloESGStores'
import { IFattoreESG } from '../../types'
import { observer } from 'mobx-react-lite'

const SchedaBilancioFattoriESG = observer(() => {

  const { bilanci } = useModuloESGStores()

  return (
    <div>
      <CanvasFattoriESG
        fattori={bilanci.bilancioAttivo?.alberatura_fattori_esg as IFattoreESG[]}
        fattori_flat={bilanci.bilancioAttivo?.fattori_esg as IFattoreESG[]}
        loader={'bilanci'}
      />
    </div>
  )
})

export default SchedaBilancioFattoriESG;