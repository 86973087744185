import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../../hooks/useOttieniPrivilegioModulo'

//Components
import { IUnitaMisura } from '../../../types'
import UnitaMisuraForm from '../UnitaMisuraForm'
import Dialogue from '../../../components/common/Dialogue'

const UnitaMisuraContextMenu: React.FC<{ unitaMisura: IUnitaMisura }> = observer(({ unitaMisura }) => {
  const { t } = useTranslation();

  const privilegio = useOttieniPrivilegioModulo();
  const { ui, gestioneUnitaMisura } = useStores();

  const handleEliminaUnitaMisura = () => {
    gestioneUnitaMisura.eliminaUnitaMisura(unitaMisura.codice).then(() => {

      ui.mountDialogue(<Dialogue testo={t('ModuloGestioneUnitaMisura.ContextMenu.unitaMisuraEliminata')} />)

    }).catch(() => {
      ui.mountDialogue(<Dialogue testo={gestioneUnitaMisura.eliminaUnitaMisuraError} />)
    })
  }


  return (
    <div className="absolute shadow-lg border border-lightgray-dd bg-white dark:bg-black p-3 right-0 dark:border-0 z-10">
      <ul className="whitespace-nowrap flex flex-col gap-2">
        {privilegio >= 3 && <li onClick={() => ui.mountAsideModal(<UnitaMisuraForm unitaMisura={unitaMisura} />)}>{t('ModuloGestioneUnitaMisura.ContextMenu.modificaUnitaMisura')}</li>}
        {privilegio >= 4 &&
          <li onClick={() => ui.mountDialogue(
            <Dialogue
              testo={t('ModuloGestioneUnitaMisura.ContextMenu.messagioElimina')}
              primaryCta={`${t('common.elimina')} ${unitaMisura.codice}`}
              secondaryCta={t('common.annulla')}
              onConfirm={() => handleEliminaUnitaMisura()}
              primaryCtaColor="bg-red dark:bg-dark-red"
            />
          )} className="text-red dark:text-dark-red">
            {t('ModuloGestioneUnitaMisura.ContextMenu.eliminaUnitaMisura')}
          </li>
        }
      </ul>
    </div>
  )
})

export default UnitaMisuraContextMenu;