import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { getGruppi } from '../gestioneUtenti/rest/gestioneGruppi'
import { getPrivilegi } from './rest/gestionePrivilegi'
import { useForm, useFieldArray } from 'react-hook-form'
import { useStores } from '../../hooks/useStores'
import { IModulo, IGruppo, IPrivilegio } from '../../types'
import { observer } from 'mobx-react-lite'
import { getPrivilegiModulo } from './rest/gestionePrivilegi'

//Components
import InfoBox from '../../components/common/InfoBox'
import ErrorBox from '../../components/common/ErrorBox'
import SpinnerButton from '../../components/common/SpinnerButton'
import { FaPlus } from 'react-icons/fa'

const ModuloPrivilegiForm: React.FC<{ modulo?: IModulo }> = observer(({ modulo }) => {
  const { t } = useTranslation();

  const { gestionePrivilegi } = useStores();

  //Istanzia Hook Form
  const { watch, register, setValue, handleSubmit, control, formState: { isSubmitSuccessful } } = useForm()

  useEffect(() => {
    if (modulo) {
      setValue('modulo_codice', modulo.codice)
    }
  }, [modulo, setValue])

  const { fields, append, remove } = useFieldArray({
    control,
    name: "privilegi",
  });

  //Lista gruppi preselezionati
  const [gruppiPreselezionati, setGruppiPreselezionati] = useState([])

  //Ottiene lista dei privilegi già presenti
  useEffect(() => {
    modulo &&
      getPrivilegiModulo(modulo.codice as string).then((res) => {
        setGruppiPreselezionati(res.data.data)
      })
  }, [modulo])

  //Popola il form con i privilegi già esistenti
  useEffect(() => {
    remove()

    gruppiPreselezionati &&
      gruppiPreselezionati.forEach((gruppo: any) => {
        append({
          'gruppo': gruppo.gruppo_codice,
          'privilegio': gruppo.privilegio_valore,
          'attivo': gruppo.attivo
        })
      })


  }, [gruppiPreselezionati, append, remove])

  //Gruppo corrente selezionato nel dropdown
  const [gruppo, setGruppo] = useState<IGruppo>()

  //Listadi tutti i gruppi
  const [listaGruppi, setListaGruppi] = useState<IGruppo[]>([])

  //Lista di tutti i gruppi accessibili per il dropdown
  const [listaGruppiSelezionabili, setListaGruppiSelezionabili] = useState<IGruppo[]>(listaGruppi)

  //Lista di tutti i privilegi disponibilie
  const [listaPrivilegi, setListaPrivilegi] = useState<IPrivilegio[]>([])

  //Setta la lista dei gruppi e dei privilegi
  useEffect(() => {
    getGruppi().then((res) => {
      setListaGruppi(res.data.data)
    })

    getPrivilegi().then((res) => {
      setListaPrivilegi(res.data.data)
    })
  }, [])

  //Funzione per aggiungere un field dedicato al gruppo
  const handleAggiungiGruppo = (gruppo: any) => {
    if (gruppo) {
      append({ gruppo, privilegio: listaPrivilegi[0].valore, attivo: true })
    }
  }

  //Funzione per rimuovere un field dedicato al gruppo
  const handleRimuoviGruppo = (index: number) => {
    remove(index)
  }

  //Modifica la lista dei gruppi selezionabili nel dropdown
  useEffect(() => {


    if (fields) {
      let nuovaListaGruppiSelezionabili: any = []

      listaGruppi.forEach((gruppo: any) => {

        let found = false

        fields.forEach((field: any) => {
          if (gruppo.codice === field.gruppo) {
            found = true
          }
        })

        if (!found) {
          nuovaListaGruppiSelezionabili.push(gruppo)
        }

      })

      setListaGruppiSelezionabili(nuovaListaGruppiSelezionabili)
      setGruppo(undefined)
    }

  }, [listaGruppi, fields])

  //Submit del form
  const onSubmit = () => {
    gestionePrivilegi.attribuisciPrivilegiModulo(watch('modulo_codice'), watch('privilegi'))
  }


  return (
    <div>
      <div className="mb-4">
        <h2 className="text-2xl mb-2">{t('ModuloGestioneModuli.FormPrivilegi.testoAttribuisci')}{modulo?.nome}</h2>
        <p className="text-text-light">{t('ModuloGestioneModuli.FormPrivilegi.testoDescrizione')}</p>
      </div>

      {/*@ form aggiunta gruppo */}
      <div className="p-4 bg-white dark:bg-darkgray-d shadow-lg form rounded-sm border border-lightgray-dd dark:border-black">
        <form>
          {listaGruppiSelezionabili.length === 0 ?
            <div>
              < label className="mb-2">{t('ModuloGestioneModuli.FormPrivilegi.tuttiGruppi')}</label>
            </div>
            :
            <div>
              <label className="mb-2">{t('ModuloGestioneModuli.FormPrivilegi.unoGruppo')}</label>
            </div>
          }
          <div className={`${listaGruppiSelezionabili.length === 0 && 'opacity-70 pointer-events-none'}`}>
            {/*@ts-ignore*/}
            <select className="mb-2" onChange={(event) => setGruppo(event.target.value)}>
              <option value="">{t('ModuloGestioneModuli.FormPrivilegi.selezionaGruppo')}</option>
              {listaGruppiSelezionabili.map((gruppo: IGruppo) => {
                return (
                  <option key={gruppo.codice} value={gruppo.codice}>{gruppo.nome}</option>
                )
              })};
            </select>

            {/*@ts-ignore*/}
            <div onClick={() => handleAggiungiGruppo(gruppo)}
              className="w-full p-2 text-accent flex items-center justify-center gap-2 border border-accent dark:border-darl-accent rounded -sm cursor-pointer hover:opacity-80"
            >
              <FaPlus /> {t('ModuloGestioneModuli.FormPrivilegi.aggiungiGruppi')} {gruppo}
            </div>
          </div>
        </form>
      </div>

      {/*@ form permessi */}
      <form onSubmit={handleSubmit(onSubmit)} action="">


        <div className="flex flex-col gap-1">
          {fields.map((gruppo: any, index: number) => {
            return (
              <div className="bg-white dark:bg-darkgray-d p-2 justify-between form shadow-lg border border-lightgray-dd dark:border-black" key={gruppo.id}>
                <div className="flex justify-between mb-3">
                  <span>{t('ModuloGestioneModuli.FormPrivilegi.privilegi')} {gruppo.gruppo}</span>
                  <FaPlus className="rotate-45 cursor-pointer hover:opacity-70" onClick={() => handleRimuoviGruppo(index)} />
                </div>

                <div className="form checkbox">
                  <label htmlFor="">{t('ModuloGestioneModuli.FormPrivilegi.attivaPrivilegi')}</label>
                  <input {...register(`privilegi.${index}.attivo`)} type="checkbox" />
                </div>

                <div className={`form ${!watch(`privilegi.${index}.attivo`) && 'opacity-70 pointer-events-none'}`}>
                  {
                    <>
                      <select {...register(`privilegi.${index}.privilegio`)}>
                        {listaPrivilegi.filter((privilegio) => privilegio.valore === gruppo.privilegio).map((privilegio: IPrivilegio) => {
                          return (
                            <option key={privilegio.valore} value={privilegio.valore}>{privilegio.nome}</option>
                          )
                        })}
                        {listaPrivilegi.filter((privilegio) => privilegio.valore !== gruppo.privilegio).map((privilegio: IPrivilegio) => {
                          return (
                            <option key={privilegio.valore} value={privilegio.valore}>{privilegio.nome}</option>
                          )
                        })};
                      </select>
                    </>
                  }
                </div>

              </div>
            )
          })}
        </div>



        {/* Submit modifica privilegio */}
        <>
          <div className="form">
            <div>
              {!gestionePrivilegi.doingAttribuisciPrivilegio ? <input type="submit" value={t('ModuloGestioneModuli.FormPrivilegi.salavaPrivilegi')} /> : <SpinnerButton />}
            </div>
          </div>

          {
            isSubmitSuccessful && !gestionePrivilegi.doingAttribuisciPrivilegio && (

              gestionePrivilegi.attribuisciPrivilegioError
                ?
                <div className="my-2">
                  <ErrorBox errore={gestionePrivilegi.attribuisciPrivilegioError} />
                </div>
                :
                <div className="my-2">
                  <InfoBox messaggio={t('common.modificaSalvato')} />
                </div>

            )
          }
        </>


      </form>
    </div>
  )
})


export default ModuloPrivilegiForm;