import React from 'react'

//Data
import { observer } from 'mobx-react';
import { useModuloESGStores } from '../../context/useModuloESGStores';
import { ITemaMaterialitaAnno } from '../../types';

//Components
import TextLoader from '../../../../components/common/TextLoader';
import TemaMaterialitaBilancioTile from './TemaMaterialitaBilancioTile';


const ListaTemiMaterialitaBilancio: React.FC<{ temiMaterialita: ITemaMaterialitaAnno[] }> = observer(({ temiMaterialita }) => {

    const { bilanci } = useModuloESGStores()

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
            {bilanci.gettingBilancioAttivo
                ?
                <>
                    <div className="text-4xl"><TextLoader /> </div>
                    <div className="text-4xl"><TextLoader /> </div>
                    <div className="text-4xl"><TextLoader /> </div>
                </>
                :
                <>
                    {
                        temiMaterialita.map(tema => <TemaMaterialitaBilancioTile tema={tema} />)
                    }
                </>
            }
        </div>
    )
})

export default ListaTemiMaterialitaBilancio;
