import React, { useEffect } from 'react'

//Data
import { useStores } from '../../hooks/useStores';
import { useForm } from 'react-hook-form';

//Components
import { FaSearch } from 'react-icons/fa'
import { CgClose } from 'react-icons/cg'
import { runInAction } from 'mobx';
import { useTranslation } from 'react-i18next';

export default function BarraRicerca() {

    const { t } = useTranslation()
    const { ui } = useStores();

    const { watch, register } = useForm()

    useEffect(() => {

        if (watch('ricerca')) {
            runInAction(() => { ui.ricerca = watch('ricerca') as string })
        } else {
            runInAction(() => { ui.ricerca = '' })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('ricerca')])

    const styles = {
        searchbox: `bg-lightgray-d dark:bg-darkgray-d px-3 py-1 rounded-full gap-2 items-center md:flex ${ui.searchBarMobile ? 'flex w-full md_w-auto' : ' hidden'}`,
        searchbar: "focus:outline-none bg-[rgba(0,0,0,0)] text-text-light dark:text-dark-text-light w-full md_w-auto",
    }

    return (
        <form className={styles.searchbox}>

            <input {...register("ricerca")} placeholder={t('common.cerca')} className={styles.searchbar} type="search" />

            <button className="dark:bg-darkgray-d" type="submit">
                <FaSearch opacity={0.5} className="cursor-pointer" />
            </button>

            <CgClose opacity={0.5} className="cursor-pointer block md:hidden" size={18} onClick={() => ui.toggleSearchBarMobile()} />

        </form>
    )
}
