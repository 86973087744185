import { observable, action, makeAutoObservable, runInAction } from 'mobx'
import { ITemaMaterialita } from '../../../types'
import {
    getTemaMaterialita,
    creaTemaMaterialita,
    modificaTemaMaterialita,
    eliminaTemaMaterialita,
    getListaTemaMaterialita
} from '../rest/temamaterialita'

export class TemaMaterialitaStore {

    temiMaterialita : ITemaMaterialita[] = []

    gettingTemaMaterialitaAttivo: boolean = false
    TemaMaterialitaAttivo: ITemaMaterialita | null = null

    creatingTemaMaterialita = false
    creatingTemaMaterialitaError: string = ''
    TemaMaterialitaCreato = false

    editingTemaMaterilita = false
    modificaTemaMaterialitaError: string = ''
    TemaMaterialitaModificato = false

    deletingTemaMaterialita: boolean = false
    eliminaTemaMaterialitaError: string = ''
    TemaMaterialitaEliminato: boolean = false

    constructor() {
        makeAutoObservable(this, {
            
            temiMaterialita: observable,

            gettingTemaMaterialitaAttivo: observable,
            TemaMaterialitaAttivo: observable,

            creatingTemaMaterialita: observable,
            creatingTemaMaterialitaError: observable,
            TemaMaterialitaCreato: observable,

            creaTemaMaterialita: action,
            editingTemaMaterilita: observable,
            modificaTemaMaterialitaError: observable,
            TemaMaterialitaModificato: observable,
            modificaTemaMaterialita: action,

            deletingTemaMaterialita: observable,
            eliminaTemaMaterialitaError: observable,
            TemaMaterialitaEliminato: observable,
            eliminaTemaMaterialita: action
          
        })
    }
    //get lista dei materialia
    getListaTemaMaterialita = () => {

        this.gettingTemaMaterialitaAttivo = true;

        getListaTemaMaterialita().then((res) => {
            runInAction(() => { this.temiMaterialita = res.data.data })
        }).catch((err) => {
            console.log(err.data.data)
        }).finally(() => {
            this.gettingTemaMaterialitaAttivo = false;
        })
    }

    //get singolo materialita
    getTemaMaterialita = (codice: string) => {

        this.gettingTemaMaterialitaAttivo = true;

        getTemaMaterialita(codice).then((res) => {

            runInAction(() => { this.TemaMaterialitaAttivo = res.data.data })

        }).catch((err) => {

            console.log(err.data.data)

        }).finally(() => {

            this.gettingTemaMaterialitaAttivo = false;

        })
    }
    
    //crea creaTemaMaterialita
    creaTemaMaterialita = async (materialita: ITemaMaterialita) => {

        runInAction(() => this.creatingTemaMaterialita = true)

        await creaTemaMaterialita(materialita).then(() => {

            runInAction(() => this.creatingTemaMaterialitaError = '')

            this.getTemaMaterialita(this.TemaMaterialitaAttivo?.codice as string)

        }).catch((err) => {

            runInAction(() => this.creatingTemaMaterialitaError = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {
            runInAction(() => this.creatingTemaMaterialita = false)
            runInAction(() => this.TemaMaterialitaCreato = !this.TemaMaterialitaCreato)
        })
    }
    

    //modifica TemaMaterialita
    modificaTemaMaterialita = async (materialita: ITemaMaterialita) => {

        runInAction(() => this.editingTemaMaterilita = true)

        await modificaTemaMaterialita(materialita).then(() => {

            runInAction(() => this.modificaTemaMaterialitaError = '')
            this.getTemaMaterialita(this.TemaMaterialitaAttivo?.codice as string)

        }).catch((err) => {
            runInAction(() => this.modificaTemaMaterialitaError = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {
            runInAction(() => this.editingTemaMaterilita = false)
            runInAction(() => this.TemaMaterialitaModificato = !this.TemaMaterialitaModificato)
        })
    }

    //elimina TemaMaterialita
    eliminaTemaMaterialita = async (codice: string) => {

        runInAction(() => this.deletingTemaMaterialita = true)

        await eliminaTemaMaterialita(codice).then(() => {

            runInAction(() => this.eliminaTemaMaterialitaError = '')

        }).catch((err) => {

            console.log(err.data)
            runInAction(() => this.eliminaTemaMaterialitaError = err.data)

        }).finally(() => {
            runInAction(() => this.deletingTemaMaterialita = false)
            runInAction(() => this.TemaMaterialitaEliminato = !this.TemaMaterialitaEliminato)
        })
    }


    

}