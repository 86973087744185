import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../../../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../../../../hooks/useOttieniPrivilegioModulo'
import { useModuloAgenda2030Stores } from '../context/useModuloAgenda2030Stores'

//Components
import ObiettivoAgenda2030Form from './ObiettivoAgenda2030Form'
import Dialogue from '../../../../../components/common/Dialogue'
import { IObiettivoAgenda2030 } from '../../../types'

const ObiettivoAgenda2030ContextMenu: React.FC<{ obiettivo: IObiettivoAgenda2030 }> = observer(({ obiettivo }) => {

  const { agenda_2030 } = useModuloAgenda2030Stores()

  const privilegio = useOttieniPrivilegioModulo()
  const { t } = useTranslation();
  const { ui } = useStores()


  const handleEliminaObiettivo = () => {
    agenda_2030.eliminaObiettivoAgenda2030(obiettivo.id).then(() => {

      if (agenda_2030.eliminaObiettivoAgenda2030Error) {

        ui.mountDialogue(<Dialogue testo={agenda_2030.eliminaObiettivoAgenda2030Error} />)

      } else {

        ui.mountDialogue(<Dialogue testo={t('ModuloFattoriEsg.ObiettivoAgenda2030ContextMenu.obiettivoEliminatoSucesso') } />)

      }

    });
  }

  return (
    <div className="absolute shadow-lg border border-lightgray-dd bg-white dark:bg-black p-3 right-0 dark:border-0">
      <ul className="whitespace-nowrap flex flex-col gap-2">
        {privilegio >= 3 && <li onClick={() => ui.mountAsideModal(<ObiettivoAgenda2030Form obiettivo={obiettivo} />)}>{t('ModuloFattoriEsg.ObiettivoAgenda2030ContextMenu.modificaObiettivo') } </li>}
        {privilegio >= 4 &&
          <li
            className="text-red dark:text-dark-red"
            onClick={() => ui.mountDialogue(
              <Dialogue
                testo={`${t('ModuloFattoriEsg.ObiettivoAgenda2030ContextMenu.eliminaObiettivo') } ${obiettivo.nome}? ${t('ModuloFattoriEsg.ObiettivoAgenda2030ContextMenu.QuestaIrreversibile') }`}
                primaryCta={`${t('ModuloFattoriEsg.ObiettivoAgenda2030ContextMenu.eliminaObiettivoTesto') } ${obiettivo.nome}`}
                primaryCtaColor="bg-red dark:bg-dark-red"
                secondaryCta={t('common.annulla') }
                secondaryCtaColor="bg-white dark:bg-darkgray-dd !text-text dark:!text-dark-text"
                onConfirm={() => handleEliminaObiettivo()}
              />)}
          >
            {t('ModuloFattoriEsg.ObiettivoAgenda2030ContextMenu.eliminaObiettivoTesto') }
          </li>
        }
      </ul>
    </div>
  )
})

export default ObiettivoAgenda2030ContextMenu;
