import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { observer } from 'mobx-react-lite'
import { useModuloAgenda2030Stores } from '../context/useModuloAgenda2030Stores'
import { IObiettivoAgenda2030 } from '../../../types'
import { useForm } from 'react-hook-form'

//Components
import InputField from '../../../../../components/common/form/InputField'
import ErrorBox from '../../../../../components/common//ErrorBox'
import InfoBox from '../../../../../components/common//InfoBox'
import SpinnerButton from '../../../../../components/common//SpinnerButton'
import { CompactPicker } from 'react-color';

const ObiettivoAgenda2030Form: React.FC<{ obiettivo?: IObiettivoAgenda2030, codice?: string, nome?: string }> = observer(({ obiettivo, codice, nome }) => {

  const { register, handleSubmit, watch, setValue, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      id: obiettivo ? obiettivo.id : '',
      id_originale: obiettivo ? obiettivo.id : '',
      nome: obiettivo ? obiettivo.nome : '',
      nome_esteso: obiettivo ? obiettivo.nome_esteso : '',
      descrizione: obiettivo ? obiettivo.descrizione : '',
      colore: obiettivo ? obiettivo.colore : null,
      immagine: obiettivo ? obiettivo.immagine : '',
    }
  });
  const { t } = useTranslation();
  const { agenda_2030 } = useModuloAgenda2030Stores();

  const onSubmit = () => {
    obiettivo
      ?
      agenda_2030.modificaObiettivoAgenda2030(watch() as IObiettivoAgenda2030).then(() => {
        setValue('id_originale', watch('id'))
      })
      :
      agenda_2030.creaObiettivoAgenda2030(watch() as IObiettivoAgenda2030)
  }

  const handleColorPickerChange = (colore: any) => {
    setValue('colore', colore.hex)
  }

  return (
    <div>
      <div className="mb-4">
        <h2 className="text-2xl mb-2">
          {obiettivo ?
            `${t('ModuloFattoriEsg.FormObiettivoAgenda2030.ModificaObiettivo') } ${obiettivo.nome}`
            :
            `${t('ModuloFattoriEsg.FormObiettivoAgenda2030.creaObiettivo') }`
          }
        </h2>
        <p className="text-text-light">
          {obiettivo ?
            `${t('ModuloFattoriEsg.FormObiettivoAgenda2030.modifcaIDati') } ${codice ? codice : t('ModuloFattoriEsg.FormObiettivoAgenda2030.dellObietivo') } '} ${obiettivo.nome}`
            :
            `${t('ModuloFattoriEsg.FormObiettivoAgenda2030.StabilisciUnCodice') }`
          }
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>

        <InputField
          label={t('ModuloFattoriEsg.FormObiettivoAgenda2030.id') }
          type="number"
          error={errors.id}
          form={register("id", { required: t('ModuloFattoriEsg.FormObiettivoAgenda2030.fornisciObiettivo')  })}
          placeholder={t('ModuloFattoriEsg.FormObiettivoAgenda2030.placeholderObiettivo') }
        />

        <InputField
          label={t('common.nome') }
          type="text"
          error={errors.nome}
          form={register("nome", { required: t('ModuloFattoriEsg.FormObiettivoAgenda2030.fornisciNomePerObiettivo') })}
          placeholder={t('ModuloFattoriEsg.FormObiettivoAgenda2030.placeholderNomeObiettivo')}
        />

        <InputField
          label={t('common.nomeEsteso') }
          type="text"
          error={errors.nome_esteso}
          form={register("nome_esteso")}
          placeholder={t('ModuloFattoriEsg.FormObiettivoAgenda2030.placeholderNomeestesoObiettivo')}
        />

        <InputField
          label={t('common.descrizione') }
          type="textarea"
          error={errors.descrizione}
          form={register("descrizione")}
          placeholder={t('ModuloFattoriEsg.formFattorEsg.placeholderDescrizionefattore')}

        />

        <InputField
          label={t('ModuloFattoriEsg.FormObiettivoAgenda2030.nomeImagine')}
          type="text"
          error={errors.immagine}
          form={register("immagine")}
        />


        <div>
          <label className="text-center block w-full mt-4">{t('ModuloFattoriEsg.FormObiettivoAgenda2030.colorEtichetta')}</label>
          <div className="mt-2 flex items-center justify-center" >
            <CompactPicker
              color={watch('colore') ? watch('colore') as string : '#68BC00'}
              onChangeComplete={color => handleColorPickerChange(color)}
            />
          </div>
        </div>

        {/* Submit modifica fattore */}
        {obiettivo &&

          <>
            <div className="form pt-4">
              {!agenda_2030.editingObiettivoAgenda2030 ? <input type="submit" value={t('common.salvaModifica')} /> : <SpinnerButton />}
            </div>

            {
              isSubmitSuccessful && !agenda_2030.editingObiettivoAgenda2030 && (

                agenda_2030.modificaObiettivoAgenda2030Error
                  ?
                  <div className="my-2">
                    <ErrorBox errore={agenda_2030.modificaObiettivoAgenda2030Error} />
                  </div>
                  :
                  <div className="my-2">
                    <InfoBox messaggio={t('common.modificaSalvato')} />
                  </div>

              )
            }

          </>
        }

        {/* Submit creazione fattore */}
        {!obiettivo &&

          <>
            <div className="form pt-4">
              {!agenda_2030.creatingObiettivoAgenda2030 ? <input type="submit" value={`Crea ${watch('nome')}`} id="crea-tema-submit" /> : <SpinnerButton />}
            </div>

            {
              isSubmitSuccessful && !agenda_2030.creatingObiettivoAgenda2030 && (
                agenda_2030.creatingObiettivoAgenda2030Error ?
                  <div className="mb-2">
                    <ErrorBox errore={agenda_2030.creatingObiettivoAgenda2030Error.toString()} />
                  </div>
                  :
                  <div className="mb-2">
                    <InfoBox messaggio={`${watch('nome')} ${t('ModuloFattoriEsg.formFattorEsg.FattorecreatoConSucesso')}`} id={"crea-tema-success"} />
                  </div>
              )
            }

          </>
        }
      </form>
    </div>
  )
})

export default ObiettivoAgenda2030Form;