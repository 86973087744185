import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { observer } from 'mobx-react-lite'
import { useModuloESGStores } from '../../context/useModuloESGStores'
import { IFattoreESG } from '../../types'
import CreaListaFlatFattoriEsg from '../../script/creaListaFlatFattoriEsg'

//Components
import CanvasFattoriESG from '../../components/CanvasFattoriBilancio/CanvasFattoriESG'
import TileIndicatore from '../../components/indicatori/IndicatoreTile'

const SchedaCategoria = observer(() => {

    const { bilanci } = useModuloESGStores()
    const { t } = useTranslation();

    const [listaFlat, setListaFlat] = useState<IFattoreESG[]>([])

    useEffect(() => {

        if (bilanci.fattoreBilancioAttivo?.sotto_fattori && bilanci.fattoreBilancioAttivo?.sotto_fattori.length > 0) {
            setListaFlat(CreaListaFlatFattoriEsg(bilanci.fattoreBilancioAttivo?.sotto_fattori))
        }

    }, [bilanci.fattoreBilancioAttivo])

    return (
        <div>

            {bilanci.fattoreBilancioAttivo && bilanci.fattoreBilancioAttivo?.sotto_fattori.length > 0 &&
                <CanvasFattoriESG
                    fattori={bilanci.fattoreBilancioAttivo?.sotto_fattori as IFattoreESG[]}
                    fattori_flat={listaFlat}
                    loader="bilanci"
                />
            }

            { //Lista tabelle 
                bilanci.fattoreBilancioAttivo &&
                bilanci.fattoreBilancioAttivo.sotto_fattori.filter((fattore) => fattore.tipo === 'tabella').length > 0 &&
                <>
                    <div className="my-4">
                        <h2 className="font-bold text-xl">
                        { t('ModuloFattoriEsg.SchedaCategoria.TabellePresenti') }
                        </h2>
                        <p> { t('ModuloFattoriEsg.SchedaCategoria.listaTuttiTabelle') }</p>
                    </div>
                    <div className="flex flex-col gap-2">
                        {
                            bilanci.fattoreBilancioAttivo.sotto_fattori
                                .filter((fattore) => fattore.tipo === 'tabella')
                                .map((fattore: IFattoreESG) => <TileIndicatore fattore={fattore} />)
                        }
                    </div>
                </>
            }
        </div>
    )
})

export default SchedaCategoria;