import React from 'react'
import { IGruppoBusinessUnit, IBusinessUnitRuolo } from '../types';
import { FaAngleDoubleDown } from 'react-icons/fa'
import { useStores } from '../../../hooks/useStores';
import { useTranslation } from 'react-i18next'

const GruppoBusinessUnitTile: React.FC<{gruppo: IGruppoBusinessUnit, ruoli: IBusinessUnitRuolo[], nomeBu: string}> = ({gruppo, ruoli, nomeBu}) => {

    const { t } = useTranslation();
    const { ui } = useStores()

    return (
        <div className="bg-lightgray p-4 rounded-sm cursor-default hover:bg-lightgray-dd relative">
            {nomeBu !== gruppo.business_unit_nome &&
                <div className="absolute right-2 top-[-10px]"
                onMouseEnter={() => ui.mountFollowMouse(<div>{t('ModuloBusinessUnit.TileGruppoBusinessUnit.ruoloStatoEridato')} {gruppo.business_unit_nome}</div>)}
                onMouseLeave={() => ui.unmountFollowMouse()}
                >
                    <FaAngleDoubleDown size={20} />
                </div>
            }
            <p><b>Gruppo:</b> <br /> {gruppo.nome}</p> <br />
            <p>
                <b>Ruolo:</b> <br />
                <div className="flex gap-1 items-center">
                    {ruoli?.filter(ruolo => ruolo.valore === gruppo.ruolo_valore)[0].nome}
                </div>
            </p>
        </div>
    )
}

export default GruppoBusinessUnitTile;