import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../../../hooks/useOttieniPrivilegioModulo'
import { useModuloAgenda2030Stores } from './context/useModuloAgenda2030Stores'

//Components
import { FaInfoCircle, FaList } from 'react-icons/fa'
import AggiungiButton from '../../../../components/common/AggiungiButton'
import ObiettivoAgenda2030Form from './components/ObiettivoAgenda2030Form'
import ListaObiettiviAgenda2030 from './components/ListaObiettiviAgenda2030'
import RuotaObiettiviAgenda2030 from './components/RuotaObiettiviAgenda2030'

const ArchivioObiettiviAgenda2030 = observer(() => {
    const { t } = useTranslation();

    const [modalistaVista, setModalitaVista] = useState<'lista' | 'ruota'>('ruota')
    const privilegio = useOttieniPrivilegioModulo()

    const { agenda_2030 } = useModuloAgenda2030Stores()
    const { ui } = useStores();

    useEffect(() => {

        agenda_2030.getListaObiettiviAgenda2030()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        agenda_2030.obiettivoAgenda2030Creato,
        agenda_2030.obiettivoAgenda2030Eliminato,
        agenda_2030.obiettivoAgenda2030Modificato,
    ])


    return (
        <div>
            <div className="mt-2">
                {
                    agenda_2030.obiettiviAgenda2030 && agenda_2030.obiettiviAgenda2030.length > 0 ?
                        <div>
                            <div className="flex flex-col items-end gap-1">
                                <div>
                                {t('ModuloBusinessUnit.CanvasBusinessUnitHeader.modVista')}
                                </div>
                                <div className="text-white flex justify-end w-full mb-2">
                                    <button
                                        className={`p-2 flex items-center gap-1 ${modalistaVista === 'lista' && 'pointer-events-none opacity-70'}`}
                                        onClick={() => setModalitaVista('lista')}
                                    >
                                        <FaList />
                                        <span>{t('ModuloFattoriEsg.ArchivioObiettiviAgenda2030.lista')}</span>
                                    </button>
                                    <button
                                        className={`p-2 flex items-center gap-1 ${modalistaVista === 'ruota' && 'pointer-events-none opacity-70'}`}
                                        onClick={() => setModalitaVista('ruota')}
                                    >
                                        <div className="border-2 border-white w-[12px] h-[12px] rounded-full" />
                                        <span>{t('ModuloFattoriEsg.ArchivioObiettiviAgenda2030.ruota')}</span>
                                    </button>
                                </div>
                            </div>
                            {modalistaVista === 'lista' ?
                                <ListaObiettiviAgenda2030 />
                                :
                                <RuotaObiettiviAgenda2030 obiettivi={agenda_2030.obiettiviAgenda2030} />
                            }
                        </div>
                        :
                        <h3 className="flex items-center justify-center gap-2 text-2xl my-8 text-text-light">
                            <FaInfoCircle /> {t('ModuloFattoriEsg.ArchivioObiettiviAgenda2030.nessunObittivioPresente')}
                        </h3>
                }
            </div>

            {privilegio >= 2 &&
                <div className="w-full mt-2" onClick={() => ui.mountAsideModal(<ObiettivoAgenda2030Form />)} >
                    <AggiungiButton testo={t('ModuloFattoriEsg.ArchivioObiettiviAgenda2030.creaNuovoObiettivo')} />
                </div>
            }
        </div>
    )
})

export default ArchivioObiettiviAgenda2030;


