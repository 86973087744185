import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../../../../hooks/useStores'
import { useModuloESGStores } from '../../../context/useModuloESGStores'
import { observer } from 'mobx-react-lite'
import { IFattoreESG } from '../../../types'
import { eliminaFattoreESG } from '../../../rest/fattori_esg'

//Components
import Dialogue from '../../../../../components/common/Dialogue'
import FattoreESGForm from '../../fattoriESG/FattoreESGForm'
import useOttieniPrivilegioModulo from '../../../../../hooks/useOttieniPrivilegioModulo'

const BloccoFattoreContextMenu: React.FC<{ fattore: IFattoreESG }> = observer(({ fattore }) => {

    const privilegio = useOttieniPrivilegioModulo()
    const { t } = useTranslation();
    const { fattori_esg } = useModuloESGStores()

    const { ui, moduli } = useStores()

    const handleEliminaFattore = () => {

        eliminaFattoreESG(fattore.codice as string).then(() => {

            ui.mountDialogue(<Dialogue testo={t('ModuloFattoriEsg.bloccoFattoreContextMenu.fattoreEliminatoSucesso')}  />)

            fattori_esg.getFattoreESG(fattori_esg.fattoreESGAttivo?.codice as string)

        }).catch((err) => {

            ui.mountDialogue(<Dialogue testo={err.response.data.message ? err.response.data.message : 'Errore generico'} />)

        })

    }
    return (
        <div className="absolute shadow-lg border border-lightgray-dd bg-white dark:bg-black p-3 dark:border-0 z-10" onMouseEnter={() => ui.unmountFollowMouse()}>
            <ul className="whitespace-nowrap flex flex-col gap-2">
                {
                    moduli.moduloAttivo?.codice === 'fattori_esg' &&
                    <>
                        {privilegio >= 3 && <li onClick={() => ui.mountAsideModal(<FattoreESGForm codice_padre={fattore.codice_padre as string} fattore={fattore} />)}>{t('common.modifica')} {fattore.tipo}</li>}
                        {privilegio >= 4 &&
                            <li
                                className="text-red dark:text-dark-red"
                                onClick={() => ui.mountDialogue(
                                    <Dialogue
                                        testo={`${t('ModuloFattoriEsg.bloccoFattoreContextMenu.eliminaFattore')} ${fattore.nome}? ${t('ModuloFattoriEsg.bloccoFattoreContextMenu.eliminaFattoreTesto')}`}
                                        primaryCta={`${t('ModuloFattoriEsg.bloccoFattoreContextMenu.eliminaIlFattore')} ${fattore.nome}`}
                                        primaryCtaColor="bg-red dark:bg-dark-red"
                                        secondaryCta={t('common.annulla')}
                                        secondaryCtaColor="bg-white dark:bg-darkgray-dd !text-text dark:!text-dark-text"
                                        onConfirm={() => handleEliminaFattore()}
                                    />)}
                            >
                                {t('common.elimina')} {fattore.tipo}
                            </li>
                        }
                    </>
                }
            </ul>
        </div>
    )
})

export default BloccoFattoreContextMenu;