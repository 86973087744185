import React from 'react'
import { useTranslation } from 'react-i18next'
//Data
import { IBilancio, IBilancioRendicontabile } from '../../../../types'

//Components
import { FaLink } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useStores } from '../../../../../../hooks/useStores'
import { useTemaMaterialitaStores } from '../../context/useTemaMaterialitaStores'

const TemaMaterialitaBilancioTile: React.FC<{ bilancio: IBilancio | IBilancioRendicontabile, modifica?: boolean }> = ({ bilancio, modifica = true }) => {
  
  const { materialita } = useTemaMaterialitaStores()
  const { moduli } = useStores()
  const { t } = useTranslation()

  return (
    <div className="bg-white dark:bg-darkgray-dd shadow-xl p-5 rounded-sm">
      <div className="flex items-center justify-between">
        <Link to={modifica ? `/app/modulo/${moduli.moduloAttivo?.codice}/${materialita.TemaMaterialitaAttivo?.codice}/bilancio/${bilancio.anno}` : `/app/modulo/bilanci/${bilancio.anno}`} className="flex items-center gap-2">
          <FaLink />
          <h2 className="font-bold hover:underline">
            <span className="text-xl">{t('ModuloFattoriEsg.BilancioTile.bilancioDiSostenibilita')} </span>
            <span className="text-accent dark:text-dark-accent text-xl">
              {bilancio.anno}
            </span>
          </h2>
        </Link>
      </div>
      <hr className="my-2 border border-lightgray-d" />
      <p>{bilancio.descrizione}</p>
    </div>
  )
}

export default TemaMaterialitaBilancioTile;
