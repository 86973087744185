import React from "react";
import { useTranslation } from 'react-i18next'

//Data
import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import { IGruppo } from "../../../types";
import useOttieniPrivilegioModulo from "../../../hooks/useOttieniPrivilegioModulo";

//Components
import Dialogue from "../../../components/common/Dialogue";
import ModificaGruppoForm from "./ModificaGruppoForm";

const GruppoContextMenu: React.FC<{ gruppo: IGruppo }> = observer(({ gruppo }) => {
    const { t } = useTranslation();

    const privilegio = useOttieniPrivilegioModulo();
    const { ui, gestioneUtenti } = useStores();

    const handleEliminaGruppo = () => {

        gestioneUtenti.eliminaGruppo(gruppo.codice).then(() => {

            if (gestioneUtenti.eliminaGruppoError) {

                ui.mountDialogue(<Dialogue testo={gestioneUtenti.eliminaGruppoError} />)

            } else {

                ui.mountDialogue(<Dialogue testo={t('ModuloGestioneUtenti.GruppoContextMenu.gruppoEliminatoSucesso')} />)

            }

        });

    }

    return (
        <div className="absolute shadow-lg border border-lightgray-dd bg-white dark:bg-black p-3 right-0 dark:border-0">
            <ul className="whitespace-nowrap flex flex-col gap-2">
                {privilegio >= 3 &&
                    <li className="cursor-pointer" onClick={() => ui.mountAsideModal(<ModificaGruppoForm gruppo={gruppo} />)}>
                       {t('ModuloGestioneUtenti.GruppoContextMenu.modificaGruppo')}
                    </li>
                }
                {privilegio >= 4 && 
                    <li
                        className="text-red dark:text-dark-red cursor-pointer"
                        onClick={() => ui.mountDialogue(
                            <Dialogue
                                testo={`${t('ModuloGestioneUtenti.GruppoContextMenu.mesaggioElimina')} ${gruppo.nome}? ${t('ModuloGestioneUtenti.GruppoContextMenu.messaggioCompleto')}`}
                                primaryCta={`${t('ModuloGestioneUtenti.GruppoContextMenu.testoEliminaButtone')} ${gruppo.nome}`}
                                primaryCtaColor="bg-red dark:bg-dark-red"
                                secondaryCta={t('common.annulla')}
                                secondaryCtaColor="bg-white dark:bg-darkgray-dd !text-text dark:!text-dark-text"
                                onConfirm={() => handleEliminaGruppo()}
                            />
                        )}
                    >
                        {t('ModuloGestioneUtenti.GruppoContextMenu.eliminaGruppo')}
                    </li>
                }
            </ul>
        </div>
    )
})

export default GruppoContextMenu;