import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { observer } from 'mobx-react-lite'
import { useStores } from '../../hooks/useStores'
import { IPrivilegio } from '../../types'
import useOttieniPrivilegioModulo from '../../hooks/useOttieniPrivilegioModulo'

//Components
import { Link } from 'react-router-dom'
import { FaAngleDoubleDown, FaInfoCircle, FaList } from 'react-icons/fa'
import AggiungiButton from '../../components/common/AggiungiButton'
import PrivilegioTile from './PrivilegioTIle'
import PrivilegiForm from './PrivilegiForm'

const GestionePrivilegi = observer(() => {
  const { t } = useTranslation();
  const privilegio = useOttieniPrivilegioModulo();
  const { ui, gestionePrivilegi } = useStores();

  useEffect(() => {
    gestionePrivilegi.getPrivilegi();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gestionePrivilegi.creatingPrivilegio,
    gestionePrivilegi.privilegioEliminato,
    gestionePrivilegi.privilegioModificato,
  ]
  )

  return (
    <div>
      <div className="flex items-center justify-between my-2">
        <div></div>

        <Link to='/app/modulo/gestione_moduli' className="cursor-pointer text-white bg-accent dark:bg-dark-accent p-2 rounded-sm flex gap-2 items-center dark:text-white">
          <FaList />
          {t('ModuloGestionePrivilegi.Archivio.passaGestioneModuli')}
        </Link>
      </div>

      {
        gestionePrivilegi.privilegi && gestionePrivilegi.privilegi.length === 0 &&
        <h3 className="flex items-center justify-center gap-2 text-2xl my-8 text-text-light">
          <FaInfoCircle /> {t('ModuloGestionePrivilegi.Archivio.nessunoPrivilegio')} 
        </h3>
      }

      {
        gestionePrivilegi.privilegi && gestionePrivilegi.privilegi.length > 0 &&
        <>

          {privilegio >= 2 &&
            <div className="my-4" onClick={() => ui.mountAsideModal(<PrivilegiForm />)}>
              <AggiungiButton testo={t('ModuloGestionePrivilegi.Archivio.aggiungiNuovoPrivilegio')} />
            </div>
          }

          <div className="flex flex-col gap-4">
            {
              gestionePrivilegi.privilegi && gestionePrivilegi.privilegi.map((privilegioTile: IPrivilegio, i: number) => (
                <div className="relative">
                  <PrivilegioTile privilegioTile={privilegioTile} key={privilegioTile.valore} />
                  {
                    (gestionePrivilegi.privilegi.length - 1) > i &&
                    <div className="absolute w-full flex items-center justify-center text-accent z-1 bottom-[-15px] pointer-events-none dark:text-dark-accent">
                      <FaAngleDoubleDown size={45} />
                    </div>
                  }
                </div>
              ))
            }

          </div>
        </>
      }

      {privilegio >= 2 &&
        <div className="my-4" onClick={() => ui.mountAsideModal(<PrivilegiForm />)}>
          <AggiungiButton testo={t('ModuloGestionePrivilegi.Archivio.aggiungiNuovoPrivilegio')} />
        </div>
      }
    </div>
  )
})

export default GestionePrivilegi;