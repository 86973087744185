import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../hooks/useStores';
import { useModuloESGStores } from '../../context/useModuloESGStores';
import { IFattoreESG } from '../../types';
import { eliminaIndicatore, invalidaIndicatore, validaIndicatore } from '../../rest/bilanci';
import useOttieniPrivilegioModulo from '../../../../hooks/useOttieniPrivilegioModulo';

//Components
import { FaCheck, FaEye, FaEyeSlash, FaPen, FaPlus, FaTrash } from 'react-icons/fa';
import IndicatoreForm from './IndicatoreForm';
import Dialogue from '../../../../components/common/Dialogue';

const IndicatoreTabellaForm: React.FC<{ tabella: IFattoreESG, business_unit_nome: string, modifica?: boolean }> = observer(({ tabella, business_unit_nome, modifica }) => {


    const privilegio = useOttieniPrivilegioModulo()
    const { ui } = useStores()
    const { t } = useTranslation();
    const { bilanci } = useModuloESGStores()

    const [statistiche, setStatistiche] = useState(false)

    const styles = {
        container: `min-w-full max-w-[calc(100vw-20px)] md:max-w-[calc(100vw-300px)] canvas-body max-h-[1800px]
        ${ui.asideModal && 'md:max-w-[calc(100vw-700px)]'}`,
    }

    const handleEliminaIndicatore = (bilancio_anno: number, business_unit_nome: string, fattore_codice1: string, fattore_codice2: string) => {
        eliminaIndicatore(bilancio_anno as number, business_unit_nome, fattore_codice1, fattore_codice2).then(() => {

            ui.mountDialogue(<Dialogue testo={ t('ModuloFattoriEsg.FormIndicatoreTabella.indicatoreEliminatoSucesso') } />)
            bilanci.getFattoreBilancio(bilancio_anno as number, tabella.codice as string)

        }).catch((err) => {
            ui.mountDialogue(<Dialogue testo={err.response.data.message ? err.response.data.message : "Errore generico"} />)
        })
    }

    const handleValidaIndicatore = (bilancio_anno: number, business_unit_nome: string, fattore_codice1: string, fattore_codice2: string) => {
        validaIndicatore(bilancio_anno as number, business_unit_nome, fattore_codice1, fattore_codice2).then(() => {

            ui.mountDialogue(<Dialogue testo={ t('ModuloFattoriEsg.FormIndicatoreTabella.indicatoreValidatoSucesso') } />)
            bilanci.getFattoreBilancio(bilancio_anno as number, tabella.codice as string)

        }).catch((err) => {
            ui.mountDialogue(<Dialogue testo={err.response.data.message ? err.response.data.message : "Errore generico"} />)
        })
    }

    const handleinvalidaIndicatore = (bilancio_anno: number, business_unit_nome: string, fattore_codice1: string, fattore_codice2: string) => {
        invalidaIndicatore(bilancio_anno as number, business_unit_nome, fattore_codice1, fattore_codice2).then(() => {

            ui.mountDialogue(<Dialogue testo={ t('ModuloFattoriEsg.FormIndicatoreTabella.indicatoreValidatoSucesso') } />)
            bilanci.getFattoreBilancio(bilancio_anno as number, tabella.codice as string)

        }).catch((err) => {
            ui.mountDialogue(<Dialogue testo={err.response.data.message ? err.response.data.message : "Errore generico"} />)
        })
    }

    return (
        <div className="bg-white dark:bg-darkgray-dd p-4 rounded-sm shadow-xl max-w-full">
            <div className="mb-4 flex items-start justify-between">
                <div>
                    <h2 className="text-xl">
                    { t('ModuloFattoriEsg.FormIndicatoreTabella.Datistrutturati') } "<span className="font-bold text-xl">{tabella.nome}</span>"
                        {t('ModuloFattoriEsg.FormIndicatore.perLaBusinessUnit')} "<span className="font-bold text-xl">{business_unit_nome}</span>"
                    </h2>
                    <p className="mt-2">{tabella.descrizione}</p>
                </div>
                <div>
                    <button className="text-white p-2 rounded-sm flex items-center gap-1" onClick={() => setStatistiche(!statistiche)}>
                        {statistiche ? <FaEyeSlash /> : <FaEye />}
                        <span>{statistiche ? 'Nascondi' : 'Mostra'}  {t('ModuloFattoriEsg.FormIndicatoreTabella.statistiche')}</span>
                    </button>
                </div>
            </div>

            <div className={`${styles.container} tabella-generica`}>
                <table className="min-w-full">

                    <thead>
                        <tr>
                            <th rowSpan={2} colSpan={2}></th>
                            {tabella.sotto_fattori.filter(sotto_fattore => sotto_fattore.tipo === 'colonna').map((colonna, index) => (
                                <th rowSpan={2} key={index}>{colonna.nome}</th>
                            ))}
                            {statistiche &&
                                <th colSpan={4} >
                                    {t('ModuloFattoriEsg.FormIndicatoreTabella.statistichePerRiga')}
                                </th>
                            }
                        </tr>
                        {statistiche &&
                            <tr className="border-t">
                                <th className="w-4">{t('ModuloFattoriEsg.FormIndicatoreTabella.minimo')}</th>
                                <th className="w-4">{t('ModuloFattoriEsg.FormIndicatoreTabella.media')}</th>
                                <th className="w-4">{t('ModuloFattoriEsg.FormIndicatoreTabella.massimo')}</th>
                                <th className="w-4">{t('ModuloFattoriEsg.FormIndicatoreTabella.somma')}</th>
                            </tr>
                        }
                    </thead>

                    <tbody>
                        {tabella.sotto_fattori.filter(sotto_fattore => sotto_fattore.tipo === 'riga').map((riga, index) => (
                            <tr key={index}>
                                <th colSpan={2}>{riga.nome}</th>
                                {tabella.sotto_fattori.filter(sotto_fattore => sotto_fattore.tipo === 'colonna').map((colonna, index) => (
                                    <th>
                                        {
                                            colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.find(indicatore => indicatore.collegamento.riga === riga.codice) ?
                                                colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.filter(indicatore => indicatore.collegamento.riga === riga.codice).map((indicatore, index) => (
                                                    <div className="flex gap-2 items-center justify-between" key={index}>
                                                        {indicatore.validato ?
                                                            <p
                                                                className="bg-green dark:bg-dark-green py-1 px-2 rounded-sm flex gap-2 items-center"
                                                                onMouseEnter={() => ui.mountFollowMouse(<p>{t('ModuloFattoriEsg.FormIndicatoreTabella.indicatoreValidato')}</p>)}
                                                                onMouseLeave={() => ui.unmountFollowMouse()}
                                                            >
                                                                <span className="text-white">{indicatore.valore_numerico && indicatore.valore_numerico as number * 1} {indicatore.unita_misura} {indicatore.valore_testuale}</span>
                                                                <FaCheck color='white' size={10} />
                                                            </p>
                                                            :
                                                            <span>{indicatore.valore_numerico && indicatore.valore_numerico as number * 1} {indicatore.unita_misura} {indicatore.valore_testuale}</span>
                                                        }
                                                        <div className="flex flex-col md:flex-row gap-1 items-center">
                                                            {(privilegio >= 3 && !indicatore.validato) &&
                                                                <FaPen
                                                                    className="hover:opacity-70 cursor-pointer text-accent dark:text-dark-accent"
                                                                    onMouseEnter={() => ui.mountFollowMouse(<p>{t('ModuloFattoriEsg.FormIndicatoreTabella.modificaIndicatore')}</p>)}
                                                                    onMouseLeave={() => ui.unmountFollowMouse()}
                                                                    size={10} onClick={() => ui.mountAsideModal(
                                                                        <IndicatoreForm
                                                                            indicatore={indicatore}
                                                                            bilancio_anno={riga.bilancio as number}
                                                                            business_unit={business_unit_nome}
                                                                            fattore_codice1={riga.codice}
                                                                            fattore_codice2={colonna.codice}
                                                                        />, true
                                                                    )}
                                                                />
                                                            }
                                                            {(privilegio >= 4 && !indicatore.validato) &&
                                                                <>
                                                                    <FaCheck
                                                                        className="hover:opacity-70 cursor-pointer text-green dark:text-dark-green"
                                                                        onMouseEnter={() => ui.mountFollowMouse(<p>{t('ModuloFattoriEsg.FormIndicatoreTabella.validaIndicatore')}</p>)}
                                                                        onMouseLeave={() => ui.unmountFollowMouse()}
                                                                        size={10} onClick={() => handleValidaIndicatore(
                                                                            riga.bilancio as number,
                                                                            business_unit_nome,
                                                                            riga.codice,
                                                                            colonna.codice
                                                                        )}
                                                                    />
                                                                    <FaTrash
                                                                        className="hover:opacity-70 cursor-pointer text-red dark:text-dark-red"
                                                                        onMouseEnter={() => ui.mountFollowMouse(<p>{t('ModuloFattoriEsg.FormIndicatoreTabella.eliminaIndicatore')}</p>)}
                                                                        onMouseLeave={() => ui.unmountFollowMouse()}
                                                                        size={10} onClick={() => handleEliminaIndicatore(
                                                                            riga.bilancio as number,
                                                                            business_unit_nome,
                                                                            riga.codice,
                                                                            colonna.codice
                                                                        )}
                                                                    />
                                                                </>
                                                            }
                                                            {(privilegio >= 5 && indicatore.validato) &&
                                                                <FaPlus
                                                                    className="hover:opacity-70 cursor-pointer text-red dark:text-dark-red rotate-45"
                                                                    onMouseEnter={() => ui.mountFollowMouse(<p>{t('ModuloFattoriEsg.FormIndicatoreTabella.rimouviValidazione')}</p>)}
                                                                    onMouseLeave={() => ui.unmountFollowMouse()}
                                                                    size={10} onClick={() => handleinvalidaIndicatore(
                                                                        riga.bilancio as number,
                                                                        business_unit_nome,
                                                                        riga.codice,
                                                                        colonna.codice
                                                                    )}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                privilegio >= 2 &&
                                                <div key={index}
                                                    className="cursor-pointer hover:opacity-70 w-full h-full text-accent dark:text-dark-accent flex items-center justify-center"
                                                    onClick={() => ui.mountAsideModal(
                                                        <IndicatoreForm
                                                            bilancio_anno={riga.bilancio as number}
                                                            business_unit={business_unit_nome}
                                                            fattore_codice1={riga.codice}
                                                            fattore_codice2={colonna.codice}
                                                        />, true
                                                    )}>
                                                    <FaPlus />
                                                </div>
                                        }
                                    </th>
                                ))}
                                {
                                    statistiche && (

                                        riga.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.every(indicatore => indicatore.unita_misura === riga.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori[0].unita_misura) ?
                                            <>
                                                <th>

                                                    {
                                                        riga.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.length > 0 &&
                                                        Math.min(...riga.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.map(indicatore => indicatore.valore_numerico as number))
                                                    }

                                                    {riga.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.map((indicatore => indicatore.unita_misura))[0]}

                                                </th>
                                                <th>
                                                    {
                                                        riga.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.length > 0 &&
                                                        riga.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori
                                                            .map(indicatore => indicatore.valore_numerico as number)
                                                            .reduce((sum, valore_numerico) => sum + Number(valore_numerico), 0) / riga.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.length
                                                    }

                                                    {riga.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.map((indicatore => indicatore.unita_misura))[0]}

                                                </th>
                                                <th>
                                                    {
                                                        riga.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.length > 0 &&
                                                        Math.max(...riga.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.map(indicatore => indicatore.valore_numerico as number))
                                                    }

                                                    {riga.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.map((indicatore => indicatore.unita_misura))[0]}

                                                </th>
                                                <th>
                                                    {
                                                        riga.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.length > 0 &&
                                                        riga.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori
                                                            .map(indicatore => indicatore.valore_numerico as number)
                                                            .reduce((sum, valore_numerico) => sum + Number(valore_numerico), 0)
                                                    }

                                                    {riga.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.map((indicatore => indicatore.unita_misura))[0]}

                                                </th>
                                            </>
                                            :
                                            <th colSpan={4} className="text-red dark:text-accent-red">
                                                {t('ModuloFattoriEsg.GraficoBarreTabellaBilanci.unitaDimisuraInconsistente')}
                                            </th>
                                    )
                                }
                            </tr>
                        ))}
                        {
                            statistiche &&
                            <>
                                <tr>
                                    <th rowSpan={4}>{t('ModuloFattoriEsg.FormIndicatoreTabella.statistiche')} <br /> {t('ModuloFattoriEsg.FormIndicatoreTabella.perCollona')}</th>
                                    <th>{t('ModuloFattoriEsg.FormIndicatoreTabella.minimo')}</th>
                                    {
                                        tabella.sotto_fattori.filter(sotto_fattore => sotto_fattore.tipo === 'colonna').map((colonna) => (
                                            colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.every(indicatore => indicatore.unita_misura === colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori[0].unita_misura) ?
                                                <th>
                                                    {
                                                        colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.length > 0 &&
                                                        Math.min(...colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.map(indicatore => indicatore.valore_numerico as number))
                                                    }

                                                    {colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.map((indicatore => indicatore.unita_misura))[0]}
                                                </th>
                                                :
                                                <th rowSpan={4} className="text-red dark:text-accent-red">{t('ModuloFattoriEsg.FormIndicatoreTabella.unitaDiMisura')} <br /> {t('ModuloFattoriEsg.FormIndicatoreTabella.Inconsistenti')} </th>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <th>{t('ModuloFattoriEsg.FormIndicatoreTabella.media')}</th>
                                    {
                                        tabella.sotto_fattori.filter(sotto_fattore => sotto_fattore.tipo === 'colonna').map((colonna) => (
                                            colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.every(indicatore => indicatore.unita_misura === colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori[0].unita_misura) &&
                                            <th>
                                                {
                                                    colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.length > 0 &&
                                                    colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori
                                                        .map(indicatore => indicatore.valore_numerico as number)
                                                        .reduce((sum, valore_numerico) => sum + Number(valore_numerico), 0) / colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.length
                                                }

                                                {colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.map((indicatore => indicatore.unita_misura))[0]}
                                            </th>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <th>{t('ModuloFattoriEsg.FormIndicatoreTabella.massimo')}</th>
                                    {
                                        tabella.sotto_fattori.filter(sotto_fattore => sotto_fattore.tipo === 'colonna').map((colonna) => (
                                            colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.every(indicatore => indicatore.unita_misura === colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori[0].unita_misura) &&
                                            <th>
                                                {
                                                    colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.length > 0 &&
                                                    Math.max(...colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.map(indicatore => indicatore.valore_numerico as number))
                                                }

                                                {colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.map((indicatore => indicatore.unita_misura))[0]}
                                            </th>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <th>{t('ModuloFattoriEsg.FormIndicatoreTabella.somma')}</th>
                                    {
                                        tabella.sotto_fattori.filter(sotto_fattore => sotto_fattore.tipo === 'colonna').map((colonna) => (
                                            colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.every(indicatore => indicatore.unita_misura === colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori[0].unita_misura) &&
                                            <th>
                                                {
                                                    colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.length > 0 &&
                                                    colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori
                                                        .map(indicatore => indicatore.valore_numerico as number)
                                                        .reduce((sum, valore_numerico) => sum + Number(valore_numerico), 0)
                                                }

                                                {colonna.business_units.filter(bu => bu.nome === business_unit_nome)[0].indicatori.map((indicatore => indicatore.unita_misura))[0]}
                                            </th>
                                        ))
                                    }
                                </tr>
                            </>
                        }
                    </tbody>

                </table>
            </div>
        </div>
    )
})

export default IndicatoreTabellaForm;
