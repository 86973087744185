import React, { useEffect, useState } from 'react'
import { getUtentiGruppo } from '../../../moduli/gestioneUtenti/rest/gestioneGruppi'
import { IUtente } from '../../../types'

const ListaUtentiGruppo: React.FC<{ codice: string }> = ({ codice }) => {

  const [utenti, setUtenti] = useState([])

  useEffect(() => {

    getUtentiGruppo(codice).then(res => {
      setUtenti(res.data.data)
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex flex-col gap-1 text-text dark:text-dark-text">
      {utenti.length > 0 ?
        utenti.slice(0, 5).map((utente: IUtente) => {
          return (
            <div className="py-1 px-2 bg-lightgray rounded-md text-xs dark:bg-black">
              {utente.nome} {utente.cognome}
            </div>
          )
        })
        :
        <div>Nessun utente nel gruppo</div>
      }
      {utenti.length > 5 &&
        <p className="text-xs">+ altri {utenti.length - 5} utenti</p>
      }
    </div>
  )
}

export default ListaUtentiGruppo;