import React from 'react'
import TestoErrore from '../TestoErrore'
import { IInputField } from '../../../types'

const InputField: React.FC<IInputField> = ({ label, classi, form, placeholder, type, error, description }) => {

    return (
        <div className="form">

            {/* Label */}
            {
                label &&
                <label>{label}</label>
            }

            {
                description && 
                <p className="mb-2 opacity-70">{description}</p>
            }

            {/* Input Field */}
            {type === 'textarea' ?
                <textarea
                    className={classi}
                    {...form}
                    placeholder={placeholder}
                />
            :
                <input
                    className={classi}
                    {...form}
                    placeholder={placeholder}
                    type={type}
                    step="any"
                />
            }

            {/* Errore field */}
            {error && <TestoErrore errore={error.message} />}

        </div>
    )
}

export default InputField;