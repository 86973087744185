import React, { useState, useEffect } from 'react'
import { useModuloRendicontazioneBilanciStores } from '../../context/useModuloRendicontazioneBilanciStores';
import { useTranslation } from 'react-i18next';
import { FaDotCircle } from 'react-icons/fa';
import { observer } from 'mobx-react-lite';

const FiltroTipo = observer(() => {

    const { rendicontazione_bilanci } = useModuloRendicontazioneBilanciStores()
    const { t } = useTranslation()

    const styles = {
        bottone: 'border-l-4 border border-lightgray-dd p-2 w-full text-center cursor-pointer hover:brightness-[1.02] duration-100 flex items-center justify-between',
    }

    const [fattori, setFattori] = useState<number>(0)
    const [tabelle, setTabelle] = useState<number>(0)

    useEffect(() => {

        setFattori(
            rendicontazione_bilanci.businessUnitAttiva?.fattori_rendicontabili
                .filter(f => f.tipo === 'fattore').length as number
        )

        setTabelle(
            rendicontazione_bilanci.businessUnitAttiva?.fattori_rendicontabili
                .filter(f => f.tipo === 'tabella').length as number
        )
            
    }, [rendicontazione_bilanci.businessUnitAttiva, rendicontazione_bilanci.checkAggiornamentoIndicatore])

    return (
        <div className="flex flex-col gap-2">

            <div
                className={`${styles.bottone} border-accent dark:border-dark-accent`}
                onClick={() => rendicontazione_bilanci.applicaFiltriTipo('tabella')}>
                <p>{t('common.tabella')} ({tabelle})</p>
                <div className="rounded-full border border-lightgray-dd w-3 h-3 flex items-center justify-center">
                    {rendicontazione_bilanci.filtriTipo === 'tabella' &&
                        <FaDotCircle size={10} />
                    }
                </div>
            </div>

            <div
                className={`${styles.bottone} border-accent dark:border-dark-accent`}
                onClick={() => rendicontazione_bilanci.applicaFiltriTipo('fattore')}>
                <p>{t('common.fattore')} ({fattori})</p>
                <div className="rounded-full border border-lightgray-dd w-3 h-3 flex items-center justify-center">
                    {rendicontazione_bilanci.filtriTipo === 'fattore' &&
                        <FaDotCircle size={10} />
                    }
                </div>
            </div>

        </div>
    )
})

export default FiltroTipo;