import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../hooks/useStores'
import { ILingua } from '../../types'

//Components
import { FaInfoCircle } from 'react-icons/fa'
import LinguaForm from './LinguaForm'
import LinguaTile from './components/LinguaTile'
import AggiungiButton from '../../components/common/AggiungiButton'
import useOttieniPrivilegioModulo from '../../hooks/useOttieniPrivilegioModulo'

const GestioneLingue = observer(() => {

    const { t } = useTranslation();

    const privilegio = useOttieniPrivilegioModulo();
    const { ui, gestioneLingue } = useStores();

    useEffect(() => {
        gestioneLingue.getLinguaLista();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        gestioneLingue.linguaCreata,
        gestioneLingue.linguaEliminata,
        gestioneLingue.linguaModificata,
    ]
    )

    return (
        <>
            {
                gestioneLingue.lingue && gestioneLingue.lingue.length === 0 &&
                <h3 className="flex items-center justify-center gap-2 text-2xl my-8 text-text-light">
                    <FaInfoCircle /> {t('ModuloGestioneLingue.Archivio.nessunaLingua')}
                </h3>
            }

            <div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
                    {
                        gestioneLingue.lingue && gestioneLingue.lingue.map((lingua: ILingua, i: number) => (
                            <LinguaTile key={lingua.codice} lingua={lingua} />
                        ))
                    }
                </div>
            </div>


            {privilegio >= 2 &&
                <div className="my-4" onClick={() => ui.mountAsideModal(<LinguaForm />)}>
                    <AggiungiButton testo={t('ModuloGestioneLingue.Archivio.nuovaLingua')} />
                </div>
            }
        </>
    )
})

export default GestioneLingue;