import React, { useState } from 'react'
import { IFattoreESG } from '../../types'
import ReactEcharts from "echarts-for-react"
import { FaCalendar, FaSuitcase } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

const GraficoBarreFattoreBilanci: React.FC<({ fattore: IFattoreESG, UM: string })> = ({ fattore, UM }) => {

    const [modalita, setModalita] = useState<'anno' | 'businessUnit'>('anno')
    const { t } = useTranslation()

    let chart = {}

    if (fattore.indicatori_numerici) {

        //ottiene lista degli anni presenti
        let listaAnni: Set<number> = new Set(fattore.indicatori_numerici
            .filter(indicatore =>
                indicatore.unita_misura === UM
            )
            .map((indicatore) => indicatore.bilancio_anno))

        //ottiene la lista delle business unit presenti
        let listaBusinessUnits: Set<string> = new Set(fattore.indicatori_numerici
            .map((indicatore) => indicatore.business_unit_nome))

        //definisce le serie di dati segmentandole per business unit
        let listaSeriesBusinessUnits: any[] = []
        let listaSeriesAnni: any[] = []

        //Crea lista degli indicatori data una business unit
        const creaListaIndicatoriBusinessUnit = (businessUnit: string) => {
            if (fattore.indicatori_numerici) {

                const indicatoriBusinessUnit = fattore.indicatori_numerici.filter(indicatore =>
                    indicatore.business_unit_nome === businessUnit &&
                    indicatore.unita_misura === UM
                )

                let arrayIndicatori: any[] = []

                const listaAnniOrdinata = Array.from(listaAnni).sort((a, b) => a > b ? 1 : -1)

                listaAnniOrdinata.forEach(anno => {
                    const indicatoreBusinessUnitAnno = indicatoriBusinessUnit.find(indicatore =>
                        indicatore.bilancio_anno === anno
                    )

                    if (indicatoreBusinessUnitAnno && indicatoreBusinessUnitAnno.valore_numerico) {
                        arrayIndicatori.push(indicatoreBusinessUnitAnno.valore_numerico)
                    } else {
                        arrayIndicatori.push(null)
                    }
                })

                return arrayIndicatori

            }
        }

        //Crea lista degli indicatori dato un anno
        const creaListaIndicatoriAnno = (anno: number) => {
            if (fattore.indicatori_numerici) {

                const indicatoriBusinessUnit = fattore.indicatori_numerici.filter(indicatore =>
                    indicatore.bilancio_anno === anno &&
                    indicatore.unita_misura === UM
                )

                let arrayIndicatori: any[] = []

                const listaBusinessUnitsOrdinata = Array.from(listaBusinessUnits)

                listaBusinessUnitsOrdinata.forEach(bu => {
                    const indicatoreBusinessUnitAnno = indicatoriBusinessUnit.find(indicatore =>
                        indicatore.business_unit_nome === bu
                    )

                    if (indicatoreBusinessUnitAnno && indicatoreBusinessUnitAnno.valore_numerico) {
                        arrayIndicatori.push(indicatoreBusinessUnitAnno.valore_numerico)
                    } else {
                        arrayIndicatori.push(null)
                    }
                })

                return arrayIndicatori

            }
        }


        //Compose serie per business unit
        listaBusinessUnits.forEach(businessUnit => {
            listaSeriesBusinessUnits.push(
                {
                    name: businessUnit,
                    data: creaListaIndicatoriBusinessUnit(businessUnit),
                    type: 'bar',
                    stack: 'x',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: '{c}' + UM + ` ${businessUnit}`
                    },
                    markLine: {
                        data: [{ type: 'average', name: 'Avg' }],
                        label: {
                            formatter: '{c}' + UM,
                        }
                    }
                }
            )
        })

        //Compone serie per anno
        listaAnni.forEach(anno => {
            listaSeriesAnni.push(
                {
                    name: anno,
                    data: creaListaIndicatoriAnno(anno),
                    type: 'bar',
                    stack: 'x',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: '{c}' + UM + ` ${anno}`
                    },
                    markLine: {
                        data: [{ type: 'average', name: 'Avg' }],
                        label: {
                            formatter: '{c}' + UM,
                        }
                    }
                }
            )
        })

        //definisce i parametri del grafico
        chart = {
            grid: {
                top: 100,
                left: 50,
            },
            title: {
                text: `${fattore.nome} ${modalita === 'anno' ? t('common.perAnno') : t('common.perBusinessUnit')} ${t('conUnitaMisura')} ${UM}`,
            },
            legend: {
                data: modalita === 'anno' ? Array.from(listaBusinessUnits) : Array.from(listaAnni) 
            },
            tooltip: {},
            toolbox: {
                show: true,
                orient: 'vertical',
                feature: {
                    dataZoom: {
                        yAxisIndex: 'none'
                    },
                    dataView: {
                        readOnly: false,
                        title: t('ModuloFattoriEsg.GraficoBarreFattoreBilancio.datiGrezzi'),
                    },
                    magicType: {
                        type: ['line', 'bar'],
                        title: {
                            line: "Grafico lineare",
                            bar: "Grafico a barre",
                        }
                    },
                    saveAsImage: {
                        name: `${fattore.nome}_in_${UM}_per_anno`,
                        title: 'Salva come immagine'
                    }
                }
            },
            xAxis: {
                type: 'category',
                data:
                    modalita === 'anno' ?
                        Array.from(listaAnni).sort((a, b) => a > b ? 1 : -1)
                        :
                        Array.from(listaBusinessUnits)
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: '{value}' + UM
                }
            },
            feature: {
                dataZoom: {
                    show: true,
                    readOnly: false,
                },

            },
            series: modalita === 'anno' ? listaSeriesBusinessUnits: listaSeriesAnni 
        }
    }

    return (
        <div className="bg-white dark:bg-darkgray-dd rounded-sm shadow-lg p-2 md:p-6">
            <div className="relative">
                <div className="flex items-center mb-6 rounded-sm overflow-hidden font-bold">
                    <button
                        className={`grow flex items-center justify-center gap-2 p-2 ${modalita === 'businessUnit' ? 'bg-accent text-white' : 'bg-lightgray dark:bg-darkgray-d dark:text-white text-accent'}`}
                        onClick={() => setModalita('businessUnit')}
                    >
                        <FaSuitcase /><span>Per Business Unit</span>
                    </button>
                    <button
                        className={`grow flex items-center justify-center gap-2 p-2 ${modalita === 'anno' ? 'bg-accent text-white' : 'bg-lightgray dark:bg-darkgray-d dark:text-white text-accent'}`}
                        onClick={() => setModalita('anno')}
                    >
                        <FaCalendar /><span>Per anno</span>
                    </button>
                </div>
                <ReactEcharts className="md:min-h-[400px]" option={chart} />
            </div>
        </div>
    )
}

export default GraficoBarreFattoreBilanci;