import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../../hooks/useStores';
import { IFattoreRendicontabile } from '../../../types';

//Components
import BottoneCreaIndicatoreRendicontazioneIndicatoreTabellaForm from './BottoneCreaIndicatoreRendicontazioneIndicatoreTabellaForm';
import CellaRendicontazioneIndicatoreTabellaForm from './CellaRendicontazioneIndicatoreTabellaForm';

const RendicontazioneIndicatoreTabellaForm: React.FC<{
    tabella: IFattoreRendicontabile,
    business_unit_nome: string,
    bilancio_anno: number,
    ruolo: number
}> = observer(({
    tabella,
    business_unit_nome,
    bilancio_anno,
    ruolo
}) => {

    const { ui } = useStores()
    const { t } = useTranslation();

    const styles = {
        container: `min-w-full max-w-[calc(100vw-20px)] md:max-w-[calc(100vw-300px)] canvas-body max-h-[1800px]
        ${ui.asideModal && 'md:max-w-[calc(100vw-700px)]'}`,
    }

    const [righe, setRighe] = useState<IFattoreRendicontabile[]>([])
    const [colonne, setColonne] = useState<IFattoreRendicontabile[]>([])

    useEffect(() => {
        if (tabella.sotto_fattori) {
            setRighe(tabella.sotto_fattori.filter(sf => sf.tipo === 'riga').sort((a, b) => (a.codice > b.codice) ? 1 : ((b.codice > a.codice) ? -1 : 0)))
            setColonne(tabella.sotto_fattori.filter(sf => sf.tipo === 'colonna').sort((a, b) => (a.codice > b.codice) ? 1 : ((b.codice > a.codice) ? -1 : 0)))
        }
    }, [])

    return (
        <div className="mt-4">
            {
                tabella.tipo_indicatore &&
                <p className="my-1">È obbligatorio rendicontare questa tabella con indicatori di tipo: {tabella.tipo_indicatore}</p>
            }

            <div className={`${styles.container} tabella-generica`}>
                <table className="min-w-full">

                    {/* Head tabella */}
                    <thead>
                        <tr>
                            <th rowSpan={2} colSpan={2}></th>
                            {colonne.map((colonna, index) => (
                                <th
                                    onMouseEnter={() => colonna.tipo_indicatore && ui.mountFollowMouse(<>Richiede un indicatore di tipo: {colonna.tipo_indicatore}</>)}
                                    onMouseLeave={() => ui.unmountFollowMouse()}
                                    rowSpan={2}
                                    key={index}
                                >
                                    {colonna.nome} {colonna.unita_misura && `(in ${colonna.unita_misura})`}
                                </th>
                            ))}
                        </tr>
                    </thead>

                    {/* Body tabella */}
                    <tbody>
                        {righe.map((riga, index) => (
                            <tr key={riga.codice + index}>
                                <th
                                    onMouseEnter={() => riga.tipo_indicatore && ui.mountFollowMouse(<>Richiede un indicatore di tipo: {riga.tipo_indicatore}</>)}
                                    onMouseLeave={() => ui.unmountFollowMouse()}
                                    colSpan={2}
                                >
                                    {riga.nome} {riga.unita_misura && `(in ${riga.unita_misura})`}
                                </th>
                                {colonne.map((colonna, index) => (
                                    <th>
                                        {
                                            colonna.indicatori.find(indicatore => indicatore.collegamento.riga === riga.codice) ?
                                                colonna.indicatori.filter(indicatore => indicatore.collegamento.riga === riga.codice).map((indicatore, index) => (
                                                    <CellaRendicontazioneIndicatoreTabellaForm
                                                        key={riga.codice + colonna.codice + index}
                                                        indicatore={indicatore}
                                                        bilancio_anno={bilancio_anno}
                                                        business_unit_nome={business_unit_nome}
                                                        fattore1={riga}
                                                        fattore2={colonna}
                                                        tabella={tabella}
                                                        ruolo={ruolo}
                                                    />
                                                ))
                                                :
                                                ruolo >= 1 &&
                                                <BottoneCreaIndicatoreRendicontazioneIndicatoreTabellaForm
                                                    key={riga.codice + colonna.codice + index}
                                                    bilancio_anno={bilancio_anno}
                                                    business_unit_nome={business_unit_nome}
                                                    fattore1={riga}
                                                    fattore2={colonna}
                                                    tabella={tabella}
                                                    ruolo={ruolo}
                                                />
                                        }
                                    </th>
                                ))
                                }
                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>
        </div>
    )
})

export default RendicontazioneIndicatoreTabellaForm;
