import React, { useState } from 'react'

//Data
import useOttieniPrivilegioModulo from '../../../../hooks/useOttieniPrivilegioModulo';
import { FaEllipsisV } from 'react-icons/fa';
import { IBusinessUnitCategoria } from '../../types';

//Components
import BusinessUnitCategoriaContextMenu from './BusinessUnitCategoriaContextMenu';


const BusinessUnitCategoriaTile: React.FC<{ categoria: IBusinessUnitCategoria }> = ({ categoria }) => {

    const privilegio = useOttieniPrivilegioModulo();
    const [contextMenu, setContextMenu] = useState<boolean>(false);

    const toggleContextMenu = () => {
        //Il timeout permette di far comparire il context menu dopo che l'utente ha cliccato
        setTimeout(() => {
            setContextMenu(!contextMenu)
        }, 1)
    }

    document.addEventListener('click', () => {
        if (contextMenu) {
            setContextMenu(false)
        }
    }, { once: true })

    return (
        <div className="bg-white dark:bg-black p-4 rounded-sm shadow-xl">
            <div className="flex items-center justify-between">
                <h2 className="text-lg font-bold">
                    {categoria.nome}
                </h2>

               {privilegio >= 3 &&
                    <div className="cursor-pointer hover:bg-lightgray p-1 relative" onClick={() => toggleContextMenu()}>
                        <FaEllipsisV />

                        {contextMenu &&
                            <BusinessUnitCategoriaContextMenu categoria={categoria} />
                        }
                    </div>
               }

            </div>
            <hr className="my-2 border-lightgray-dd" />
            <p>
                {categoria.descrizione}
            </p>
        </div>
    )
}

export default BusinessUnitCategoriaTile;