import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { IIndicatore } from '../../types'
import { useStores } from '../../../../hooks/useStores'

//Components
import { FaInfoCircle } from 'react-icons/fa'
import ReactEcharts from "echarts-for-react"

const GraficoBarreTabellaBilanci: React.FC<({ indicatori: IIndicatore[], nome_riga: string, nome_colonna: string })> = ({ indicatori, nome_riga, nome_colonna }) => {
    const { t } = useTranslation();
    const { ui } = useStores()

    let chart = {}

    const [unitaMisuraInconsistente, setUnitaMisuraInconsistente] = useState(false)

    //ottiene lista degli anni presenti
    let listaAnni: Set<number> = new Set(indicatori
        .map((indicatore) => indicatore.bilancio_anno))

    //ottiene la lista delle business unit presenti
    let listaBusinessUnits: Set<string> = new Set(indicatori
        .map((indicatore) => indicatore.business_unit_nome))


    let listaUnitaMisura = indicatori
        .map((indicatore) => indicatore.unita_misura)

    const creaListaIndicatoriBusinessUnit = (businessUnit: string) => {
        if (indicatori) {

            const indicatoriBusinessUnit = indicatori.filter(indicatore =>
                indicatore.business_unit_nome === businessUnit
            )

            let arrayIndicatori: any[] = []

            const listaAnniOrdinata = Array.from(listaAnni).sort((a, b) => a > b ? 1 : -1)

            listaAnniOrdinata.forEach(anno => {
                const indicatoreBusinessUnitAnno = indicatoriBusinessUnit.find(indicatore =>
                    indicatore.bilancio_anno === anno
                )

                if (indicatoreBusinessUnitAnno && indicatoreBusinessUnitAnno.valore_numerico) {
                    arrayIndicatori.push(indicatoreBusinessUnitAnno.valore_numerico)
                } else {
                    arrayIndicatori.push(null)
                }
            })


            return arrayIndicatori

        }
    }

    //definisce le serie di dati segmentandole per business unit
    let listaSeries: any[] = []

    let i = 0;

    listaBusinessUnits.forEach((businessUnit) => {
        listaSeries.push(
            {
                data: creaListaIndicatoriBusinessUnit(businessUnit),
                type: 'bar',
                stack: 'x',
                label: {
                    show: true,
                    position: 'top',
                    formatter: '{c}' + listaUnitaMisura[i] + ` (${businessUnit.slice(0, 8)}${businessUnit.length > 8 ? '...' : ''})`
                }
            }
        )
        i++;
    })

    //definisce i parametri del grafico
    chart = {
        grid: {
            top: 100,
            left: 50,
        },
        title: {
            text: `${nome_colonna}, ${nome_riga} per anno`,
        },
        legend: {
            data: Array.from(listaBusinessUnits)
        },
        toolbox: {
            show: true,
            orient: 'vertical',
            feature: {
                dataZoom: {
                    yAxisIndex: 'none'
                },
                dataView: {
                    readOnly: false,
                    title: t('ModuloFattoriEsg.GraficoBarreFattoreBilancio.datiGrezzi'),
                },
                magicType: {
                    type: ['line', 'bar'],
                    title: {
                        line: t('ModuloFattoriEsg.GraficoBarreFattoreBilancio.graficoLineare'),
                        bar: t('ModuloFattoriEsg.GraficoBarreFattoreBilancio.graficoBarre'),
                    }
                },
                saveAsImage: {
                    name: `${nome_colonna}_${nome_riga}_per_anno`,
                    title: t('ModuloFattoriEsg.GraficoBarreFattoreBilancio.salvaComeImagine')
                }
            }
        },
        xAxis: {
            type: 'category',
            data: Array.from(listaAnni).sort((a, b) => a > b ? 1 : -1),
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value}' + (unitaMisuraInconsistente ? '' : listaUnitaMisura[0])
            }
        },
        feature: {
            dataZoom: {
                show: true,
                readOnly: false,
            }
        },
        series: listaSeries
    }

    useEffect(() => {
        //Verifica se tutte le unità di misura sono uguali
        const allEqual = (arr: any) => arr.every((v: string) => v === arr[0])

        if (!allEqual(listaUnitaMisura)) {
            setUnitaMisuraInconsistente(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            {indicatori.length > 0 ? (
                <>
                    <div className="relative">
                        <ReactEcharts option={chart} />
                    </div>
                    {
                        unitaMisuraInconsistente &&
                        <div className="bg-red dark:bg-dark-red mb-4 p-2 rounded-sm text-white flex items-center gap-2" onMouseEnter={() => ui.mountFollowMouse(
                            <div className="max-w-[200px]">
                                {t('ModuloFattoriEsg.GraficoBarreTabellaBilanci.laUnitaDiMisura') }
                            </div>
                        )} onMouseLeave={() => ui.unmountFollowMouse()}>
                            <FaInfoCircle />
                            <span> {t('ModuloFattoriEsg.GraficoBarreTabellaBilanci.unitaDimisuraInconsistente') }</span>
                        </div>
                    }
                </>
            )
                :
                <div className="flex items-center justify-center grow text-center">
                    <p>
                    {t('ModuloFattoriEsg.GraficoBarreTabellaBilanci.nessunDatoRendicontazione') } <span className="font-bold">{nome_colonna}</span> {t('ModuloFattoriEsg.GraficoBarreTabellaBilanci.nellaRiga') } <span className="font-bold">{nome_riga}</span>
                    </p>
                </div>
            }
        </div>
    )
}

export default GraficoBarreTabellaBilanci;