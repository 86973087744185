import React from 'react'
import ReactEcharts from "echarts-for-react"


const GraficoAttivita: React.FC<{ attivita : any[] }> = ({ attivita }) => {

  let num_attivita =(attivita.map(item => item.n_attivita))
  let dati_attivita =(attivita.map(item => item.date))
  
  const options = {
    grid: { top: 8, right: 8, bottom: 24, left: 36 },
    xAxis : { 
      type: 'category',
      data : dati_attivita ,       
    } ,
    yAxis: {
      type: 'value',
    } ,
    series: [
      {
        data: num_attivita,
        type: 'bar',
        smooth: true,
      } ,
    ] ,
    tooltip: {
      trigger: 'axis',
    } ,
  } ;

  return <ReactEcharts  option={options} />;
  
}

export default GraficoAttivita;