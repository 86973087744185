export const en_dashboard = {
    Archivio: {
        nessunBilancioPresente: 'No sustainability report available',
    },

    Welcome: {
        benvenutiSuIpe: 'Welcome to Ipè!',
        testoIntroduczione: 'The cloud platform developed by IMQ eAmbiente dedicated to sustainability reports. With Ipè you can create a sustainability report starting from an ESG international standard, edit it as needed, enter values for each topic, analyze and compare data. ',
        scopriPiuButtone: 'Discover the modules',
    },

    widgetAttivita: {
        attivitaRecenti: 'Recent activities',
        vediTuttiAttivita: 'See all of the activities',
        nessunAttivita: 'No acitivty found for your account',
    },

    Dashboard: {
        vaiAlBilancio: 'Go to sustainability reports',
        accediDiretamente: 'Directly access the sustainability reports in the platform.',
        suDiNoi: 'About su',
        ipeTitolo: 'Ipè',
        ipeDescrizione: 'Learn more about the potential of Ipè and contact us for more information.',
        imqeAmbienteTitolo: 'IMQ eAmbiente',
        imqeAmbienteDescrizione: 'Reducing and managing environmental impact is our mission, working alongside our partners and clients to find technical and management solutions that are environmentally, socially and economically sustainable.',
        innovationLabTitolo: 'Innovation Lab',
        innovationLabDescrizione: 'We provide software and cloud applications to represent in detail the environmental status of territories and companies.',
    },
}