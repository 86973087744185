import { IAttivita } from '../../types';
import { creaStringStandardPerAttivita } from '../../hooks/attivita/creaStringStandardPerAttivita';

export default function factoryAttivitaAgenda2030(attivita: IAttivita) {
    

    let codiceInterpretato = '';

    switch (attivita.route) {

        case 'Agenda2030.crea':
            attivita.user_name?
            codiceInterpretato = `${attivita.user_name} ha <span class="azione bg-accent dark:bg-dark-accent">creato</span> l'obiettivo <a target="_blank" href="/app/modulo/agenda_2030/${JSON.parse(attivita.request_corpo)?.id}">${JSON.parse(attivita.request_corpo)?.nome}</a> dell'agenda 2030`
            :
            codiceInterpretato = `Hai <span class="azione bg-accent dark:bg-dark-accent">creato</span> l'obiettivo <a target="_blank" href="/app/modulo/agenda_2030/${JSON.parse(attivita.request_corpo)?.id}">${JSON.parse(attivita.request_corpo)?.nome}</a> dell'agenda 2030`
            break;

        case 'Agenda2030.modifica':
            attivita.user_name?
            codiceInterpretato = `${attivita.user_name} ha <span class="azione bg-orange dark:bg-dark-orange">modificato</span> l'obiettivo <a target="_blank" href="/app/modulo/agenda_2030/${attivita.uri.split('/')[attivita.uri.split('/').length - 1]}">${JSON.parse(attivita.request_corpo)?.nome}</a> dell'agenda 2030`
            :
            codiceInterpretato = `Hai <span class="azione bg-orange dark:bg-dark-orange">modificato</span> l'obiettivo <a target="_blank" href="/app/modulo/agenda_2030/${attivita.uri.split('/')[attivita.uri.split('/').length - 1]}">${JSON.parse(attivita.request_corpo)?.nome}</a> dell'agenda 2030`
            break;

        case 'Agenda2030.elimina':
            attivita.user_name?
            codiceInterpretato = `${attivita.user_name} ha <span class="azione bg-red dark:bg-dark-red">eliminato</span> l'obiettivo ${attivita.uri.split('/')[attivita.uri.split('/').length - 1]} dell'agenda 2030`
            :
            codiceInterpretato = `Hai <span class="azione bg-red dark:bg-dark-red">eliminato</span> l'obiettivo ${attivita.uri.split('/')[attivita.uri.split('/').length - 1]} dell'agenda 2030`
            break;


        default:
            codiceInterpretato = creaStringStandardPerAttivita(attivita)

    }

    return codiceInterpretato;

}