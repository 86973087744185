import React, { useEffect, useState } from 'react'

//Data
import { IBusinessUnit } from '../../../UiModuloBusinessUnit/types';
import { observer } from 'mobx-react';

//Components
import { useModuloESGStores } from '../../context/useModuloESGStores';

const BusinessUnitBilancioTile: React.FC<{ businessUnit: IBusinessUnit, primo?: boolean, ultimo?: boolean }> = observer(({ businessUnit, primo, ultimo = false }) => {

  const [selezionata, setSelezionata] = useState(false);
  const [selezionataPadre, setSelezionataPadre] = useState(false);
  const [numSottoFattori, setNumSottoFattori] = useState(0);

  const { canvas_bilanci_business_units } = useModuloESGStores()

  const handleToggleBusinessUnit = () => {

    if (canvas_bilanci_business_units.businessUnitsAttive.has(businessUnit.nome)) {
      canvas_bilanci_business_units.rimuoviBusinessUnitAttive(businessUnit.nome)
      setSelezionata(false)
    } else {
      canvas_bilanci_business_units.aggiungiBusinessUnitAttive(businessUnit.nome)
      setSelezionata(true)
    }

  }

  useEffect(() => {

    if (canvas_bilanci_business_units.businessUnitsAttive.has(businessUnit.nome)) {
      setSelezionata(true)
    }

    if (canvas_bilanci_business_units.businessUnitsPadriAttive.has(businessUnit.nome)) {
      setSelezionataPadre(true)
    } else {
      setSelezionataPadre(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    canvas_bilanci_business_units.businessUnitsAttive,
    canvas_bilanci_business_units.businessUnitsPadriAttive
  ])

  useEffect(() => {
    businessUnit.sotto_business_unit && setNumSottoFattori(businessUnit.sotto_business_unit.length)
    canvas_bilanci_business_units.creaAlberaturaBusinessUnitsPadre()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex flex-col items-center gap-[24px] relative left-0">

      {primo && <div className="bg-lightgray-d dark:bg-darkgray-d absolute left-0 w-[calc(50%-1px)] top-[-20px] h-[15px]" />}
      {ultimo && <div className="bg-lightgray-d dark:bg-darkgray-d absolute right-0 w-[calc(50%-1px)] top-[-20px] h-[15px]" />}
      <div
        onClick={() => handleToggleBusinessUnit()}
        className={`cursor-pointer bg-white dark:bg-darkgray-dd flex items-center
        justify-center w-[150px] h-[60px] relative border border-white
        dark:border-bg-darkgray-dd hover:border-accent
        ${selezionataPadre && 'duration-400 pointer-events-none opacity-70'}
        `}>
        <div className={`${(selezionata || selezionataPadre) && 'duration-200	 bg-accent dark:bg-dark-accent text-white w-full h-full flex items-center justify-center'}`}>
          <h2 className="dark:text-dark-text font-bold">{businessUnit?.nome.substring(0, 14)}{businessUnit?.nome.length > 14 && '...'}</h2>
          {
            businessUnit.sotto_business_unit && businessUnit.sotto_business_unit.length > 0 &&
            <div className="bg-accent dark:bg-dark-accent w-[2px] h-[12px] absolute bottom-[-12px] mx-auto left-[calc(50%-1px)]" />
          }
        </div>

      </div>

      {
        businessUnit.sotto_business_unit && businessUnit.sotto_business_unit.length > 0 &&
        <div className="bg-accent dark:bg-dark-accent w-full h-[2px] absolute top-[72px] mx-auto "></div>
      }


      <div className="flex items-start gap-2">
        {/* Se nella business unit sono presenti altre unita queste vengono caricate */}
        {
          businessUnit.sotto_business_unit && businessUnit.sotto_business_unit.length > 0 &&

          businessUnit.sotto_business_unit.map((sottoBusinessUnit: IBusinessUnit, i: number) => (
            <SottBusinessUnitBilancioTile
              key={sottoBusinessUnit.nome}
              businessUnit={sottoBusinessUnit}
              primo={i === 0 ? true : false}
              ultimo={i === (numSottoFattori - 1) ? true : false}
            />
          ))


        }
      </div>

    </div>
  )
})

const SottBusinessUnitBilancioTile: React.FC<{ businessUnit: IBusinessUnit, primo?: boolean, ultimo?: boolean }> = ({ businessUnit, primo, ultimo }) => {
  return (
    <div className="w-full flex items-stretch justify-center relative">
      <div className="bg-accent dark:bg-dark-accent w-[2px] h-[12px] absolute top-[-12px]"></div>
      <BusinessUnitBilancioTile businessUnit={businessUnit} primo={primo} ultimo={ultimo} />
    </div>
  )
}

export default BusinessUnitBilancioTile;