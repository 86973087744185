import { observable, action, makeAutoObservable } from 'mobx'

export class ThemeStore {
  theme: string

  constructor() {
    makeAutoObservable(this, {
      theme: observable,
      changeTheme: action
    })
    this.theme = 'light'
  }

  changeTheme(newTheme: string) {
    localStorage.theme = newTheme;
    this.theme = newTheme;
  }
}