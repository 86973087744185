import React from 'react'
import { useTranslation } from 'react-i18next'

const AvanzamentoRendicontazioneFattore: React.FC<{ stato: 'daRendicontare' | 'rendicontato' | 'validato' }> = ({ stato }) => {

    const { t } = useTranslation()

    return (
        <div className="w-full">
            <div className="w-full grid grid-cols-3">
                {/* Non rendicontato */}
                <div className="grow p-2 text-center">
                    <span className={`whitespace-nowrap text-xs ${stato === 'daRendicontare' && 'font-bold'}`}>{t('ModuloFattoriEsg.TileRendicontazioneFattore.nonRendicontato')}</span>
                </div>

                {/* rendicontato */}
                <div className="grow p-2 text-center">
                    <span className={`whitespace-nowrap text-xs ${stato === 'rendicontato' && 'font-bold'}`}>{t('ModuloFattoriEsg.TileRendicontazioneFattore.rendicontato')}</span>
                </div>

                {/* validato */}
                <div className="grow p-2 text-center">
                    <span className={`whitespace-nowrap text-xs ${stato === 'validato' && 'font-bold'}`}>{t('ModuloFattoriEsg.TileRendicontazioneFattore.validato')}</span>
                </div>
            </div>

            <div className="bg-lightgray dark:bg-darkgray relative flex items-center">
                <div
                    className={`
                     h-[3px] relative duration-1000	left-0
          ${stato === 'daRendicontare' && 'w-1/3 bg-orange dark:bg-dark-orange'}
          ${stato === 'rendicontato' && 'w-2/3 bg-accent dark:bg-dark-accent'}
          ${stato === 'validato' && 'w-full bg-green dark:bg-dark-green'}
        `}
                />

                <div className={`
                absolute left-1/3 w-1 h-1 rounded-full
                ${stato === 'daRendicontare' && 'bg-orange dark:bg-dark-orange'}
                ${stato === 'rendicontato' && 'bg-accent dark:bg-dark-accent'}
                ${stato === 'validato' && 'bg-green dark:bg-dark-green'}
                        `} />
                <div className={`
                        absolute left-2/3 w-1 h-1 rounded-full
                        ${stato === 'daRendicontare' && 'bg-lightgray dark:bg-darkgray'}
                ${stato === 'rendicontato' && 'bg-accent dark:bg-dark-accent'}
                ${stato === 'validato' && 'bg-green dark:bg-dark-green'}
                `} />
            </div>
        </div>
    )
}

export default AvanzamentoRendicontazioneFattore;
