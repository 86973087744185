import React, { useEffect } from 'react'

//Data
import { useStores } from '../../../../../hooks/useStores';
import { IFattoreRendicontabile, IIndicatore } from '../../../types';
import { useTranslation } from 'react-i18next';
import { eliminaIndicatore, invalidaIndicatore, validaIndicatore } from '../rest/rendicontazione_indicatori';
import { prioritaFattoreRendicontabile } from '../../../../../hooks/prioritaFattoreRendicontabile';

//Components
import { FaCheck, FaPen, FaPlus, FaTrash } from 'react-icons/fa';
import RendicontazioneIndicatoreForm from './RendicontazioneIndicatoreForm';
import Dialogue from '../../../../../components/common/Dialogue';
import { useModuloRendicontazioneBilanciStores } from '../context/useModuloRendicontazioneBilanciStores';
import { observer } from 'mobx-react-lite';

const CellaRendicontazioneIndicatoreTabellaForm: React.FC<{
    indicatore: IIndicatore,
    bilancio_anno: number,
    business_unit_nome: string,
    fattore1: IFattoreRendicontabile,
    fattore2: IFattoreRendicontabile,
    tabella?: IFattoreRendicontabile,
    ruolo: number,
}> = observer(({
    indicatore,
    bilancio_anno,
    business_unit_nome,
    fattore1,
    fattore2,
    tabella,
    ruolo,
}) => {

    const { t } = useTranslation()
    const { ui } = useStores()
    const { rendicontazione_bilanci } = useModuloRendicontazioneBilanciStores()

    //Gestisce eliminazione indicatore
    const handleEliminaIndicatore = (bilancio_anno: number, business_unit_nome: string, fattore_codice1: string, fattore_codice2: string) => {

        eliminaIndicatore(bilancio_anno as number, business_unit_nome, fattore_codice1, fattore_codice2).then((res) => {

            //Monta messaggio
            ui.mountToast({
                messaggio: t('ModuloFattoriEsg.FormIndicatoreTabella.indicatoreEliminatoSucesso'),
                colore: 'green'
            })

            rendicontazione_bilanci.applicaAggiornamentoIndicatore([res.data.fattore_codice_1, res.data.fattore_codice_2], indicatore, res.data.tabella_codice, true)

        }).catch((err) => {

            //Monta messaggio
            ui.mountToast({
                messaggio: err.response.data.message ? err.response.data.message : "Errore generico",
                colore: 'red'
            })

        })

    }

    //Gestisce validazione indicatore
    const handleValidaIndicatore = (bilancio_anno: number, business_unit_nome: string, fattore_codice1: string, fattore_codice2: string) => {

        validaIndicatore(bilancio_anno as number, business_unit_nome, fattore_codice1, fattore_codice2).then((res) => {

            //Monta messaggio
            ui.mountToast({
                messaggio: t('ModuloFattoriEsg.FormIndicatoreTabella.indicatoreValidatoSucesso'),
                colore: 'green'
            })

            rendicontazione_bilanci.applicaAggiornamentoIndicatore([res.data.fattore_codice_1, res.data.fattore_codice_2], res.data.indicatore, res.data.tabella_codice)

        }).catch((err) => {

            //Monta messaggio
            ui.mountToast({
                messaggio: err.response.data.message ? err.response.data.message : "Errore generico",
                colore: 'red'
            })

        })

    }

    //Gestisce rimozione validazione indicatore
    const handleinvalidaIndicatore = (bilancio_anno: number, business_unit_nome: string, fattore_codice1: string, fattore_codice2: string) => {

        invalidaIndicatore(bilancio_anno as number, business_unit_nome, fattore_codice1, fattore_codice2).then((res) => {

            //Monta messaggio
            ui.mountToast({
                messaggio: t('ModuloFattoriEsg.FormRendicontazioneIndicatori.validazionrRomiso'),
                colore: 'green'
            })

            rendicontazione_bilanci.applicaAggiornamentoIndicatore([res.data.fattore_codice_1, res.data.fattore_codice_2], res.data.indicatore, res.data.tabella_codice)

        }).catch((err) => {

            //Monta messaggio
            ui.mountToast({
                messaggio: err.response.data.message ? err.response.data.message : "Errore generico",
                colore: 'red'
            })

        })

    }

    return (
        <div className="flex gap-2 items-center justify-between">
            {indicatore.validato ?
                <p
                    className="bg-green dark:bg-dark-green py-1 px-2 rounded-sm flex gap-2 items-center"
                    onMouseEnter={() => ui.mountFollowMouse(<p>{t('ModuloFattoriEsg.FormIndicatoreTabella.indicatoreValidato')}</p>)}
                    onMouseLeave={() => ui.unmountFollowMouse()}
                >
                    <span className="text-white">
                        {indicatore.valore_numerico && `${indicatore.valore_numerico as number * 1} ${indicatore.unita_misura}`}
                        {indicatore.valore_testuale && indicatore.valore_testuale}
                        {typeof indicatore.valore_booleano === 'boolean' &&
                            <>
                                {
                                    indicatore.valore_booleano ? t('common.si') : t('common.no')
                                }
                            </>
                        }
                    </span>
                    <FaCheck color='white' size={10} />
                </p>
                :
                <span>
                    {indicatore.valore_numerico && `${indicatore.valore_numerico as number * 1} ${indicatore.unita_misura}`}
                    {indicatore.valore_testuale && indicatore.valore_testuale}
                    {typeof indicatore.valore_booleano === 'boolean' &&
                        <>
                            {
                                indicatore.valore_booleano ? t('common.si') : t('common.no')
                            }
                        </>
                    }
                </span>
            }
            <div className="flex flex-col md:flex-row gap-1 items-center">

                {(ruolo >= 1 && !indicatore.validato) &&
                    <FaPen
                        className="hover:opacity-70 cursor-pointer text-accent dark:text-dark-accent"
                        onMouseEnter={() => ui.mountFollowMouse(<p>{t('ModuloFattoriEsg.FormIndicatoreTabella.modificaIndicatore')}</p>)}
                        onMouseLeave={() => ui.unmountFollowMouse()}
                        size={10} onClick={() => ui.mountFollowMouseModal(
                            <RendicontazioneIndicatoreForm
                                indicatore={indicatore}
                                bilancio_anno={bilancio_anno}
                                business_unit={business_unit_nome}
                                fattore1={fattore1}
                                fattore2={fattore2}
                                ruolo={ruolo}
                                fattore_tipo_indicatore={prioritaFattoreRendicontabile([fattore1, fattore2, tabella as IFattoreRendicontabile])?.tipo_indicatore}
                                unita_misura_predefinita={prioritaFattoreRendicontabile([fattore1, fattore2, tabella as IFattoreRendicontabile])?.unita_misura}
                                risposte_multiple_predefinite={prioritaFattoreRendicontabile([fattore1, fattore2, tabella as IFattoreRendicontabile])?.risposte_multiple}
                                numero_risposte_multiple_possibili={prioritaFattoreRendicontabile([fattore1, fattore2, tabella as IFattoreRendicontabile])?.num_risposte_multiple}
                            />)}
                    />
                }

                {(ruolo >= 2 && !indicatore.validato) &&
                    <FaCheck
                        className="hover:opacity-70 cursor-pointer text-green dark:text-dark-green"
                        onMouseEnter={() => ui.mountFollowMouse(<p>{t('ModuloFattoriEsg.FormIndicatoreTabella.validaIndicatore')}</p>)}
                        onMouseLeave={() => ui.unmountFollowMouse()}
                        size={10} onClick={() => handleValidaIndicatore(
                            bilancio_anno,
                            business_unit_nome,
                            fattore1.codice,
                            fattore2.codice
                        )}
                    />
                }

                {(ruolo >= 3 && !indicatore.validato) &&
                    <FaTrash
                        className="hover:opacity-70 cursor-pointer text-red dark:text-dark-red"
                        onMouseEnter={() => ui.mountFollowMouse(<p>{t('ModuloFattoriEsg.FormIndicatoreTabella.eliminaIndicatore')}</p>)}
                        onMouseLeave={() => ui.unmountFollowMouse()}
                        size={10} onClick={() => handleEliminaIndicatore(
                            bilancio_anno,
                            business_unit_nome,
                            fattore1.codice,
                            fattore2.codice
                        )}
                    />
                }

                {(ruolo >= 3 && indicatore.validato) &&
                    <FaPlus
                        className="hover:opacity-70 cursor-pointer text-red dark:text-dark-red rotate-45"
                        onMouseEnter={() => ui.mountFollowMouse(<p>{t('ModuloFattoriEsg.FormIndicatoreTabella.rimouviValidazione')}</p>)}
                        onMouseLeave={() => ui.unmountFollowMouse()}
                        size={10} onClick={() => handleinvalidaIndicatore(
                            bilancio_anno,
                            business_unit_nome,
                            fattore1.codice,
                            fattore2.codice
                        )}
                    />
                }

            </div>
        </div>
    )
})

export default CellaRendicontazioneIndicatoreTabellaForm;