import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../../hooks/useOttieniPrivilegioModulo'

//Components
import { ILingua } from '../../../types'
import LinguaForm from '../LinguaForm'
import Dialogue from '../../../components/common/Dialogue'

const LinguaContextMenu: React.FC<{ lingua: ILingua }> = observer(({ lingua }) => {
  const { t } = useTranslation();

  const privilegio = useOttieniPrivilegioModulo();
  const { ui, gestioneLingue } = useStores();

  const handleEliminaLingua = () => {
    gestioneLingue.eliminaLingua(lingua.codice).then(() => {

      ui.mountDialogue(<Dialogue testo={t('ModuloGestioneLingue.ContextMenu.linguaEliminata')} />)

    }).catch(() => {
      ui.mountDialogue(<Dialogue testo={gestioneLingue.eliminaLinguaError} />)
    })
  }


  return (
    <div className="absolute shadow-lg border border-lightgray-dd bg-white dark:bg-black p-3 right-0 dark:border-0 z-10">
      <ul className="whitespace-nowrap flex flex-col gap-2">
        {privilegio >= 3 && <li onClick={() => ui.mountAsideModal(<LinguaForm lingua={lingua} />)}>{t('ModuloGestioneLingue.ContextMenu.modificaLingua')}</li>}
        {privilegio >= 4 &&
          <li onClick={() => ui.mountDialogue(
            <Dialogue
              testo={t('ModuloGestioneLingue.ContextMenu.messagioElimina')}
              primaryCta={`${t('common.elimina')} ${lingua.codice}`}
              secondaryCta={t('common.annulla')}
              onConfirm={() => handleEliminaLingua()}
              primaryCtaColor="bg-red dark:bg-dark-red"
            />
          )} className="text-red dark:text-dark-red">
            {t('ModuloGestioneLingue.ContextMenu.eliminaLingua')}
          </li>
        }
      </ul>
    </div>
  )
})

export default LinguaContextMenu;