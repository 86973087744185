import axios from "axios"
import { config } from "../../../config"
import { IBusinessUnit, IBusinessUnitRuolo } from "../types"

//Riceve lista business units
export const getListaBusinessUnits = () => {
    return axios.get(`${config.API_URI}business_unit`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceve dettagli business unit
export const getBusinessUnit = (nome: string) => {
    return axios.get(`${config.API_URI}business_unit/${nome}`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Crea business unit
export const creaBusinessUnit = (businessUnit: IBusinessUnit) => {
    console.log(businessUnit)

    return axios.post(`${config.API_URI}business_unit`, {
        'nome': businessUnit.nome,
        'descrizione': businessUnit.descrizione,
        'civico': businessUnit.civico,
        'via': businessUnit.via,
        'cap': businessUnit.cap,
        'citta': businessUnit.citta,
        'paese': businessUnit.paese,
        'latitudine': businessUnit.latitudine,
        'longitudine': businessUnit.longitudine,
        'categoria': businessUnit.categoria ?? '',
        'nome_padre': businessUnit.nome_padre,
    }, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Modifica business unit
export const modificaBusinessUnit = (nomeOriginale: string, businessUnit: IBusinessUnit) => {
    return axios.put(`${config.API_URI}business_unit/${nomeOriginale}`, {
        'nome_originale': nomeOriginale,
        'nome': businessUnit.nome,
        'descrizione': businessUnit.descrizione,
        'civico': businessUnit.civico,
        'via': businessUnit.via,
        'cap': businessUnit.cap,
        'citta': businessUnit.citta,
        'paese': businessUnit.paese,
        'latitudine': businessUnit.latitudine,
        'longitudine': businessUnit.longitudine,
        'categoria': businessUnit.categoria,
        'nome_padre': businessUnit.nome_padre,
    }, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Elimina business unit
export const eliminaBusinessUnit = (nome: string) => {
    return axios.delete(`${config.API_URI}business_unit/${nome}`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceve dettagli business unit
export const getBusinessUnitRuoli = (nome: string) => {
    return axios.get(`${config.API_URI}business_unit`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}


//Attribuisce un ruolo alla business unit
export const attribuisciBusinessUnitRuolo = (nome: string, ruoli: IBusinessUnitRuolo[]) => {
    return axios.put(`${config.API_URI}business_unit/${nome}/attribuisci`,
        { ruoli: ruoli },
        { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

export const decodificaIndirizzo = (civico: string, via: string, cap: string, citta: string, paese: string) => {
    return axios.post(`${config.API_URI}business_unit/geocode`, {
        'civico': civico,
        'via': via,
        'cap': cap,
        'citta': citta,
        'paese': paese
    }, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}