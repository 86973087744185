import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { observer } from 'mobx-react-lite'
import { IPrivilegio } from '../../types'
import useOttieniPrivilegioModulo from '../../hooks/useOttieniPrivilegioModulo'

//Components
import { FaEllipsisV } from 'react-icons/fa'
import PrivilegioContextMenu from './PrivilegioContextMenu'

const PrivilegioTile: React.FC<{ privilegioTile: IPrivilegio }> = observer(({ privilegioTile }) => {
    const { t } = useTranslation();

    const privilegio = useOttieniPrivilegioModulo();
    const [contextMenu, setContextMenu] = useState(false);

    const styles = {
        'wrapper': `bg-white dark:bg-darkgray-d p-4 md:p-6 shadow-xl w-full border-b border-lightgray-dd ${privilegioTile?.valore === 0 && 'opacity-70 pointer-events-none'}`,
    }

    const toggleContextMenu = () => {
        //Il timeout permette di far comparire il context menu dopo che l'utente ha cliccato
        setTimeout(() => {
            setContextMenu(!contextMenu)
        }, 1)
    }

    document.addEventListener('click', () => {
        if (contextMenu) {
            setContextMenu(false)
        }
    }, { once: true })

    return (
        <div className="w-full">

            <div className={styles.wrapper}>

                {/* Header */}
                <div className="flex md:flex-row items-center justify-between">

                    <div className="flex md:gap-2 md:items-end flex-col md:flex-row">
                        <div>
                            <p>{t('ModuloGestionePrivilegi.Form.Priorita')}{privilegioTile?.valore} </p>
                            <h2 className="font-bold text-lg">{privilegioTile?.nome}</h2>
                        </div>
                    </div>

                    <div className="flex gap-2 md:gap-4 items-center">
                        <div className="cursor-pointer relative p-1" onClick={() => toggleContextMenu()}>
                            {
                                privilegio >= 3 &&
                                privilegioTile?.valore !== 0 &&
                                <>
                                    <FaEllipsisV className="hover:opacity-70" />

                                    {contextMenu && <PrivilegioContextMenu privilegioTile={privilegioTile as IPrivilegio} />}
                                </>
                            }
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}
)

export default PrivilegioTile;