import React, { useEffect } from 'react'

//Data
import { useModuloESGStores } from '../context/useModuloESGStores'
import { observer } from 'mobx-react-lite'
import { Navigate, useParams } from 'react-router-dom'
import { ITemaMaterialitaAnno } from '../types'

//Components
import { Link } from 'react-router-dom'
import TextLoader from '../../../components/common/TextLoader'
import InformativaTemaMaterialita from '../pagineFattoriESG/UiModuloTemaMaterialita/InformativaTemaMaterialita'
import { FaPaperclip } from 'react-icons/fa'
import BackButton from '../../../components/common/BackButton'
import FattoreESGTile from '../components/FattoreESGTile'
import { useStores } from '../../../hooks/useStores'

const DettagliTemaMaterialitaNelBilancio = observer(() => {

  const { moduli } = useStores()
  const { bilanci } = useModuloESGStores()

  const { bilancio_anno, tema_codice } = useParams()

  useEffect(() => {
    bilancio_anno &&
      bilanci.getTemaBilancio(parseInt(bilancio_anno), tema_codice as string).catch(() => {
        return <Navigate to="app/modulo/bilanci"></Navigate>
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tema_codice, bilancio_anno])

  return (
    <div>
      <div className="flex flex-col md:flex-row items-stretch justify-between gap-2 my-2">
        <BackButton
          testo="Torna all'archivio dei temi"
          destinazione={`/app/modulo/${moduli.moduloAttivo?.codice}/${bilanci.bilancioAttivo?.anno}/temi_materialita`}
        />
        <Link
          to={`/app/modulo/materialita/${bilanci.temaBilancioAttivo?.codice}`}
          className="p-2 flex items-center gap-2 font-bold font-bold hover:underline"
        >
          <FaPaperclip />
          <span>Vai alla scheda generica del tema</span>
        </Link>
      </div>

      {bilanci.gettingFattoreBilancioAttivo ?
        <div className="text-2xl">
          <TextLoader />
        </div>
        :
        <div>

          <InformativaTemaMaterialita temaMaterialita={bilanci.temaBilancioAttivo as ITemaMaterialitaAnno} />

          <div className="flex gap-2 flex-col md:flex-row my-2">
            <div className="w-full bg-white p-5 grow flex flex-col items-start gap-2 shadow-xl">
              <h4
                style={{ color: bilanci.temaBilancioAttivo?.colore }}
                className="text-4xl md:text-6xl font-bold">
                {bilanci.temaBilancioAttivo?.rilevanza_stakeholder_anno}
              </h4>
              <p className="text-lg">Importanza per gli stakeholder</p>
            </div>
            <div className="w-full bg-white p-5 grow flex flex-col items-start gap-2 shadow-xl">
              <h4
                style={{ color: bilanci.temaBilancioAttivo?.colore }}
                className="text-4xl md:text-6xl font-bold">
                {bilanci.temaBilancioAttivo?.rilevanza_azienda_anno}
              </h4>
              <p className="text-lg">Importanza per l'azienda</p>
            </div>
          </div>

          <div className="bg-white p-5 grow flex flex-col items-start gap-2 shadow-xl mb-2 shadow-xl">
            <p className="font-bold">Descrizione del tema per questo bilancio:</p>
            <p>{bilanci.temaBilancioAttivo?.descrizione_anno}</p>
          </div>

          {bilanci.temaBilancioAttivo?.fattori && bilanci.temaBilancioAttivo?.fattori.length > 0 ?
            <div className="mt-4">
              <h2 className="text-xl my-4">
                {bilanci.temaBilancioAttivo?.fattori.length} fattori collegati a questo tema nel bilancio del {bilanci.bilancioAttivo?.anno}
              </h2>
              <div className="mt-4 flex flex-col gap-2">
                {
                  bilanci.temaBilancioAttivo?.fattori.map((fattore) => {
                    return (
                      <FattoreESGTile
                        fattore={fattore}
                        link={`/app/modulo/${moduli.moduloAttivo?.codice}/${bilanci.bilancioAttivo?.anno}/fattori_esg/${fattore.codice}`}
                      />
                    )
                  })
                }
              </div>
            </div>
            :
            <div className="mt-4">
              <p>Nessun fattore ESG associato a questo tema di materialità per il bilancio del {bilanci.bilancioAttivo?.anno}.</p>
              <br />
              <p>Per associare dei fattori a questo tema di materialità:</p>
              <ul className="list-decimal pl-3">
                <li>
                  Collega uno standard a un bilancio dal modulo <Link target="_blank" to={`/app/modulo/bilanci_fattori/${bilanci.bilancioAttivo?.anno}`}>Attribuzione fattori a bilanci</Link>
                </li>
                <li>
                  Utilizza il menù contestuale presente nell'alberatura dei fattori ESG dal modulo <Link target="_blank" to={`/app/modulo/bilanci_materialita/${bilanci.bilancioAttivo?.anno}`}>Attribuzione temi di materialità a bilanci</Link>, premendo sulla voce "collega temi di materialità"
                </li>
                <li>
                  Seleziona dal form tutti i temi che vuoi collegare al fattore
                </li>
              </ul>
            </div>
          }
        </div>
      }

    </div>
  )
})

export default DettagliTemaMaterialitaNelBilancio;