import React from 'react'

const ErrorBox: React.FC<{ errore: string }> = ({ errore }) => {

  const styles = {
    box: "border border-red text-center p-2 rounded-sm text-red border shadow-lg text-sm dark:border-dark-red dark:text-white"
  }

  return (
    <div className={styles.box}>
      {errore}
    </div>
  )
}

export default ErrorBox;