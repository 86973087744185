import { observable, action, makeAutoObservable, runInAction } from 'mobx'
import { getUnitaMisuraLista } from '../moduli/gestioneUnitaMisura/rest/gestioneUnitaMisura'
import { IUnitaMisura } from '../types'

export class UnitaMisuraStore {
    listaUnitaMisura: IUnitaMisura[] = []

    constructor() {
        makeAutoObservable(this, {
            listaUnitaMisura: observable,
            settaListaUnitaMisura: action
        })
    }
   
    settaListaUnitaMisura = async () => {
        await getUnitaMisuraLista().then((res) => runInAction(() => this.listaUnitaMisura = res.data.data))
    }

}