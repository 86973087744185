import React from 'react'
import Spinner from './Spinner'

export default function SpinnerButton() {

  const styles = {
    box: "bg-accent p-sm w-full flex items-center justify-center rounded-sm h-[42px]"
  }

  return (
    <div className={styles.box}>
      <Spinner />
    </div>
  )
}