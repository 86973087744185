import React, { useState, useEffect, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { observer } from 'mobx-react-lite'
import { IBilancio, IFattoreESG } from '../../types'
import { Controller, useForm } from 'react-hook-form'
import { useModuloESGStores } from '../../context/useModuloESGStores'

//Components
import ErrorBox from '../../../../components/common//ErrorBox'
import InfoBox from '../../../../components/common//InfoBox'
import SpinnerButton from '../../../../components/common//SpinnerButton'
import { MultiSelect } from 'react-multi-select-component'

const TemaMaterialitaFattoreBilancioForm: React.FC<{ bilancio: IBilancio, fattore: IFattoreESG }> = observer(({ bilancio, fattore }) => {

  const { bilanci } = useModuloESGStores();
  const { t } = useTranslation();

  const { handleSubmit, watch, control, formState: { isSubmitSuccessful } } = useForm();

  const onSubmit = () => {
    bilanci.collegaTemaMaterialitaFattoreBilancio(bilancio.anno as number, fattore.codice, watch('temi'))
  }

  //Lista di tutti i temi
  const [temiMaterialita, setTemiMaterialita] = useState<any>([])

  //Lista dei temi selezionati
  const [temiMaterialitaSelezionati, setTemiMaterialitaSelezionati] = useState<any>([]);

  //Ottiene lista temi
  useEffect(() => {
    setTemiMaterialita(bilancio.temi_materialita)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Ristruttura dati per label MultiSelect
  temiMaterialita.forEach((tema: any) => {
    tema.value = tema.codice
    tema.label = `${tema.nome}`
  })


  return (
    <div>
      <div className="mb-4">
        <h2 className="text-2xl mb-2">{t('ModuloFattoriEsg.FormtemaMaterialitaFattoreBilancio.applicaTemaMaterilita') }</h2>
        <p>{t('ModuloFattoriEsg.FormtemaMaterialitaFattoreBilancio.selezioneUnoOPiuTemi') } {fattore.nome} {t('ModuloFattoriEsg.FormFattoreBilancioBusinessUnit.perIlBilancio') } {bilancio.anno}.</p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="form">
          <label htmlFor="">{t('ModuloFattoriEsg.FormtemaMaterialitaFattoreBilancio.temi') }</label>
          <Controller
            control={control}
            name="temi"
            render={({
              field: { onChange },
            }) => (
              <MultiSelect
                className="dark:text-black"
                options={temiMaterialita}
                value={temiMaterialitaSelezionati}
                onChange={(e: SetStateAction<never[]>) => { setTemiMaterialitaSelezionati(e); onChange(e) }}
                labelledBy={t('ModuloFattoriEsg.FormtemaMaterialitaFattoreBilancio.temi') }
                overrideStrings={{
                  selectSomeItems: "Seleziona temi",
                  allItemsAreSelected:t('ModuloFattoriEsg.FormtemaMaterialitaFattoreBilancio.tuttiIselezionati') ,
                  selectAll: t('ModuloFattoriEsg.FormtemaMaterialitaFattoreBilancio.selezionaTutti') ,
                  search: t('common.cerca'),
                }}
              />
            )}
          />
        </div>

        {/* Submit collegamento temi a fattore */}
        <div className="form pt-4">
          {bilanci.collegandoTemiMaterialitaFattoreBilancio ?
            <SpinnerButton />
            :
            <input type="submit" value={t('ModuloFattoriEsg.FormtemaMaterialitaFattoreBilancio.collegaTemiAFattore') } />}
        </div>

        {isSubmitSuccessful && !bilanci.collegandoTemiMaterialitaFattoreBilancio &&
          <>
            {
              bilanci.collegaTemiMaterialitaFattoreBilancioError ?
                <ErrorBox errore={bilanci.collegaTemiMaterialitaFattoreBilancioError} />
                :

                <InfoBox messaggio={t('ModuloFattoriEsg.FormtemaMaterialitaFattoreBilancio.temiCollegatiSucesso') } />
            }
          </>
        }
      </form>
    </div>
  )
})

export default TemaMaterialitaFattoreBilancioForm;