import { useEffect, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { useStores } from './useStores';

const CambioPagina: React.FC<{ children: any }> = ({ children }) => {

  const location = useLocation();

  const { ui } = useStores();

  //Resetta UI
  useEffect(() => {
    ui.headerModal && ui.toggleHeaderModal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  //Scrolla on top ad ogni cambio di route
  useLayoutEffect(() => {
    const pagescroll = document.getElementById('pagescroll')
    pagescroll && pagescroll.scrollTo(0, 0);
  }, [location.pathname]);

  return children
}

export default CambioPagina;