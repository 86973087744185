import React, { useEffect, useState } from "react"
import { IGruppo, IUtente } from "../../../types"
import { useTranslation } from 'react-i18next'


const PanoramicaGestioneGruppiLingue: React.FC<{ utente : IUtente[] }> = ({ utente }) => {
    const { t } = useTranslation();

    return (
        <ul className='ml-3'>
        <li className='font-extrabold text-sm lg:text-lg  mb-0.5 uppercase'>{t('ModuloGestioneUtenti.PannelloUtentiSenzaGruppo.utentiSenzaGruppo')}</li>
        <li className='font-bold text-sm lg:text-lg'>{utente.filter((utenti) => utenti.gruppi.length === 0 && utenti.is_verified === false).length}
            <span className='mx-1 text-xs lg:text-base'>{t('ModuloGestioneUtenti.PannelloUtentiSenzaGruppo.nonVerificati')}</span>
        </li>
        <li className='font-bold text-sm lg:text-lg'>{utente.filter((utenti) => utenti.gruppi.length === 0 && utenti.is_verified === true).length}
            <span className='mx-1 text-xs lg:text-base'>{t('ModuloGestioneUtenti.PannelloUtentiSenzaGruppo.verificati')}</span>      
        </li>
    </ul>
    )
}

export default PanoramicaGestioneGruppiLingue;
