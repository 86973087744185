import axios from 'axios'
import { config } from '../../../config'
import { IRichiesta } from '../../../types'


//Riceve lista richieste
export const getRichieste = () => {
    return axios.get(`${config.API_URI}richieste_accesso`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Crea una richiesta
export const creaRichiesta = (richiesta: IRichiesta) => {
    return axios.post(`${config.API_URI}richiesta_accesso`, {
        nome: richiesta.nome,
        cognome: richiesta.cognome,
        email: richiesta.email,
        telefono: richiesta.telefono,
        azienda: richiesta.azienda,
        lingua: richiesta.lingua,
    }, {
        headers: {
            lingua: richiesta.lingua
        }
    })
}

//Modifica una richiesta
export const gestisciRichiesta = (id: string, messaggio: string, accettata: boolean) => {
    return axios.put(`${config.API_URI}richiesta_accesso/${id}`, {
        accettata: accettata,
        messaggio: messaggio
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}