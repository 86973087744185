import React from 'react'

//Data
import { IBusinessUnit } from '../../types'
import { useStores } from '../../../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../../../hooks/useOttieniPrivilegioModulo'
import { UseModuloBusinessUnitsStore } from '../../context/UseModuloBusinessUnitsStore'
import { useTranslation } from 'react-i18next'

//Components
import Dialogue from '../../../../components/common/Dialogue'
import BusinessUnitsForm from './../BusinessUnitsForm'
import BusinessUnitGruppoRuoloForm from './../BusinessUnitGruppoRuoloForm'

const BusinessUnitContextMenu: React.FC<{ businessUnit: IBusinessUnit }> = observer(({ businessUnit }) => {

    const privilegio = useOttieniPrivilegioModulo()
    const { t } = useTranslation();

    const { business_units } = UseModuloBusinessUnitsStore()
    const { ui } = useStores();


    const handleEliminabusinessUnit = () => {

        business_units.eliminaBusinessUnit(businessUnit.nome as string).then(() => {

            if (business_units.eliminaBusinessUnitError) {

                ui.mountDialogue(<Dialogue testo={business_units.eliminaBusinessUnitError} />)

            } else {

                ui.mountDialogue(<Dialogue testo={t('ModuloBusinessUnit.BusinessUnitContextMenu.eliminatoBusinessUnitSucesso')} />)

            }

        });

    }

    return (
        <div className="absolute shadow-lg border border-lightgray-dd bg-white dark:bg-black p-3 right-0 dark:border-0 z-10">
            <ul className="whitespace-nowrap flex flex-col gap-2">
                {privilegio >= 3 &&
                    <>
                        <li onClick={() => ui.mountAsideModal(<BusinessUnitGruppoRuoloForm businessUnit={businessUnit} />)}>{t('ModuloBusinessUnit.BusinessUnitContextMenu.attribuisciRuoli')}</li>
                        <li onClick={() => ui.mountAsideModal(<BusinessUnitsForm businessUnit={businessUnit} />)}>{t('ModuloBusinessUnit.BusinessUnitContextMenu.modificaBusinessUnit')}</li>
                    </>
                }
                {
                    privilegio >= 4 && <li
                        className="text-red dark:text-dark-red"
                        onClick={() => ui.mountDialogue(
                            <Dialogue
                                testo={`${t('ModuloBusinessUnit.BusinessUnitContextMenu.eliminaBusinessUnit')} ${businessUnit.nome}? ${t('ModuloBusinessUnit.BusinessUnitContextMenu.eliminaBusinessUnitTesto')}`}
                                primaryCta={`${t('ModuloBusinessUnit.BusinessUnitContextMenu.eliminaLaBusinessUnit')} ${businessUnit.nome}`}
                                primaryCtaColor="bg-red dark:bg-dark-red"
                                secondaryCta={t('common.annulla')}
                                secondaryCtaColor="bg-white dark:bg-darkgray-dd !text-text dark:!text-dark-text"
                                onConfirm={() => handleEliminabusinessUnit()}
                            />)}
                    >
                        {t('ModuloBusinessUnit.BusinessUnitContextMenu.eliminaLaBusinessUnit')}
                    </li>
                }
            </ul>
        </div>
    )
})

export default BusinessUnitContextMenu;