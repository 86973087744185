import React, { useEffect, useState } from 'react'

//Data
import { useStores } from '../../hooks/useStores'
import { IModulo } from '../../types'

//Components
import { Link } from 'react-router-dom'
import IconaDinamica from './IconaDinamica'

const Ricerca = () => {


  const styles = {
    'wrapper': 'p-2 md:p-4 w-full bg-lightgray dark:bg-darkgray top-[70px] max-h-[400px] border-b border-lightgray-dd dark:border-darkgray-dd '
  }

  const { ui, auth } = useStores();

  const [risultati, setRisultati] = useState<IModulo[]>([]);

  useEffect(() => {

    setRisultati(auth.moduliUtente.filter((modulo: IModulo) => modulo.nome.toLowerCase().includes(ui.ricerca.toLowerCase())));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ui.ricerca])

  return (
    <div className={styles.wrapper}>
      <div className="max-h-[400px] overflow-y-auto">
        <p className="font-bold mb-4">{risultati.length} risultati per "{ui.ricerca}"</p>

        <div className="flex flex-col gap-1">
          {
            risultati.length > 0
              ?
              risultati.map((modulo: IModulo) => (
                <ItemRicerca key={modulo.codice} modulo={modulo} testo={ui.ricerca} />
              ))
              :
              <p>Nessun risultato trovato</p>
          }
        </div>

      </div>
    </div>
  )
}

const ItemRicerca: React.FC<{ modulo: IModulo, testo: string }> = ({ modulo, testo }) => {

  return (
    <Link to={`/app/modulo/${modulo.codice}`} className="w-full bg-lightgray-d hover:bg-white p-2 rounded-md flex gap-2 items-center">
      <IconaDinamica nome={modulo.icona} />
      <span
        dangerouslySetInnerHTML={{
          __html:
            modulo.nome.replace(testo, `<span class="bg-accent text-white">${testo}</span>`)
        }}
      ></span>
    </Link>
  )
}
export default Ricerca;
