import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { getGruppi } from '../../gestioneUtenti/rest/gestioneGruppi'
import { getRuoli } from '../UiModuloBusinessUnitRuoli/rest/BusinessUnitRuoli'
import { useForm, useFieldArray } from 'react-hook-form'
import { IGruppo } from '../../../types'
import { IBusinessUnit, IGruppoBusinessUnit, IBusinessUnitRuolo } from '../types'
import { observer } from 'mobx-react-lite'
import { getBusinessUnit } from '../rest/business_units'
import { UseModuloBusinessUnitsStore } from '../context/UseModuloBusinessUnitsStore'

//Components
import InfoBox from '../../../components/common/InfoBox'
import ErrorBox from '../../../components/common/ErrorBox'
import SpinnerButton from '../../../components/common/SpinnerButton'
import { FaPlus } from 'react-icons/fa'

const BusinessUnitGruppoRuoloForm: React.FC<{ businessUnit?: IBusinessUnit }> = observer(({ businessUnit }) => {
    const { t } = useTranslation();

    const { business_units } = UseModuloBusinessUnitsStore();

    //Istanzia Hook Form
    const { watch, register, setValue, handleSubmit, control, formState: { isSubmitSuccessful } } = useForm()

    useEffect(() => {
        if (businessUnit) {
            setValue('business_unit_nome', businessUnit.nome)
        }
    }, [businessUnit, setValue])

    const { fields, append, remove } = useFieldArray({
        control,
        name: "ruoli",
    });

    //Lista gruppi preselezionati
    const [gruppiPreselezionati, setGruppiPreselezionati] = useState([])

    //Ottiene lista dei ruoli già presenti
    useEffect(() => {
        businessUnit &&
            getBusinessUnit(businessUnit.nome as string).then((res) => {
                setGruppiPreselezionati(res.data.data.gruppi.filter((gruppo: IGruppoBusinessUnit) => gruppo.business_unit_nome === businessUnit.nome))
            })
    }, [businessUnit])

    //Popola il form con i ruoli già esistenti
    useEffect(() => {
        remove()

        gruppiPreselezionati &&
            gruppiPreselezionati.forEach((gruppo: IGruppoBusinessUnit) => {
                append({
                    'gruppo': gruppo.codice,
                    'ruolo': gruppo.ruolo_valore,
                    'attivo': gruppo.attivo
                })
            })


    }, [gruppiPreselezionati, append, remove])

    //Gruppo corrente selezionato nel dropdown
    const [gruppo, setGruppo] = useState<IGruppo>()

    //Listadi tutti i gruppi
    const [listaGruppi, setListaGruppi] = useState<IGruppo[]>([])

    //Lista di tutti i gruppi accessibili per il dropdown
    const [listaGruppiSelezionabili, setListaGruppiSelezionabili] = useState<IGruppo[]>(listaGruppi)

    //Lista di tutti i ruoli disponibilii
    const [listaRuoli, setListaRuoli] = useState<IBusinessUnitRuolo[]>([])

    //Setta la lista dei gruppi e dei ruoli
    useEffect(() => {
        getGruppi().then((res) => {
            setListaGruppi(res.data.data)
        })

        getRuoli().then((res) => {
            setListaRuoli(res.data.data)
        })
    }, [])

    //Funzione per aggiungere un field dedicato al gruppo
    const handleAggiungiGruppo = (gruppo: any) => {
        if (gruppo) {
            append({ gruppo, ruolo: listaRuoli[0].valore, attivo: true })
        }
    }

    //Funzione per rimuovere un field dedicato al gruppo
    const handleRimuoviGruppo = (index: number) => {
        remove(index)
    }

    //Modifica la lista dei gruppi selezionabili nel dropdown
    useEffect(() => {


        if (fields) {
            let nuovaListaGruppiSelezionabili: any = []

            listaGruppi.forEach((gruppo: any) => {

                let found = false

                fields.forEach((field: any) => {
                    if (gruppo.codice === field.gruppo) {
                        found = true
                    }
                })

                if (!found) {
                    nuovaListaGruppiSelezionabili.push(gruppo)
                }

            })

            setListaGruppiSelezionabili(nuovaListaGruppiSelezionabili)
            setGruppo(undefined)
        }

    }, [listaGruppi, fields])

    //Submit del form
    const onSubmit = () => {
        business_units.attribuisciBusinessUnitRuolo(watch('business_unit_nome'), watch('ruoli'))
    }

    return (
        <div>
            <div className="mb-4">
                <h2 className="text-2xl mb-2">{t('ModuloBusinessUnit.FormBusinessUnitGruppo.attribuisciBusinessUnit')} {businessUnit?.nome}</h2>
                <p className="text-text-light">{t('ModuloBusinessUnit.FormBusinessUnitGruppo.testBusinessUnit')}</p>
            </div>

            {/*@ form aggiunta gruppo */}
            <div className="p-4 bg-white dark:bg-darkgray-d shadow-lg form rounded-sm border border-lightgray-dd dark:border-black">
                <form>
                    {listaGruppiSelezionabili.length === 0 ?
                        <div>
                            < label className="mb-2">{t('ModuloGestioneUtenti.FormCreaUtenti.tuttiGruppiSelezionati')}</label>
                        </div>
                        :
                        <div>
                            <label className="mb-2">{t('ModuloBusinessUnit.FormBusinessUnitGruppo.selezioneGruppoAggiungere')}</label>
                        </div>
                    }
                    <div className={`${listaGruppiSelezionabili.length === 0 && 'opacity-70 pointer-events-none'}`}>
                        {/*@ts-ignore*/}
                        <select className="mb-2" onChange={(event) => setGruppo(event.target.value)}>
                            <option value="">{t('ModuloBusinessUnit.FormBusinessUnitGruppo.selezioneGruppo')}</option>
                            {listaGruppiSelezionabili.map((gruppo: IGruppo) => {
                                return (
                                    <option key={gruppo.codice} value={gruppo.codice}>{gruppo.nome}</option>
                                )
                            })};
                        </select>

                        {/*@ts-ignore*/}
                        <div onClick={() => handleAggiungiGruppo(gruppo)}
                            className="w-full p-2 text-accent flex items-center justify-center gap-2 border border-accent dark:border-darl-accent rounded -sm cursor-pointer hover:opacity-80"
                        >
                            <FaPlus /> {t('ModuloGestioneModuli.FormPrivilegi.aggiungiGruppi')} {gruppo}
                        </div>
                    </div>
                </form>
            </div>

            {/*@ form permessi */}
            <form onSubmit={handleSubmit(onSubmit)} action="">


                <div className="flex flex-col gap-1">
                    {fields.map((gruppo: any, index: number) => {
                        return (
                            <div className="bg-white dark:bg-darkgray-d p-2 justify-between form shadow-lg border border-lightgray-dd dark:border-black" key={gruppo.id}>
                                <div className="flex justify-between mb-3">
                                    <span>{t('ModuloBusinessUnit.FormBusinessUnitGruppo.ruolii')} {gruppo.gruppo}</span>
                                    <FaPlus className="rotate-45 cursor-pointer hover:opacity-70" onClick={() => handleRimuoviGruppo(index)} />
                                </div>

                                <div className="form checkbox">
                                    <label htmlFor="">{t('ModuloBusinessUnit.FormBusinessUnitGruppo.AttivoRuolo')}</label>
                                    <input {...register(`ruoli.${index}.attivo`)} type="checkbox" />
                                </div>

                                <div className={`form ${!watch(`ruoli.${index}.attivo`) && 'opacity-70 pointer-events-none'}`}>
                                    {
                                        <>
                                            <select {...register(`ruoli.${index}.ruolo`)}>
                                                {listaRuoli.filter((ruolo) => ruolo.valore === gruppo.ruolo).map((ruolo: IBusinessUnitRuolo) => {
                                                    return (
                                                        <option key={ruolo.valore} value={ruolo.valore}>{ruolo.nome}</option>
                                                    )
                                                })}
                                                {listaRuoli.filter((ruolo) => ruolo.valore !== gruppo.ruolo).map((ruolo: IBusinessUnitRuolo) => {
                                                    return (
                                                        <option key={ruolo.valore} value={ruolo.valore}>{ruolo.nome}</option>
                                                    )
                                                })}
                                            </select>
                                        </>
                                    }
                                </div>

                            </div>
                        )
                    })}
                </div>



                {/* Submit modifica ruolo */}
                <>
                    <div className="form">
                        <div>
                            {!business_units.doingAttribuisciRuolo ? <input type="submit" value={t('ModuloBusinessUnit.FormBusinessUnitGruppo.salvaRuoli')} /> : <SpinnerButton />}
                        </div>
                    </div>

                    {
                        isSubmitSuccessful && !business_units.doingAttribuisciRuolo && (

                            business_units.attribuisciRuoloError
                                ?
                                <div className="my-2">
                                    <ErrorBox errore={business_units.attribuisciRuoloError} />
                                </div>
                                :
                                <div className="my-2">
                                    <InfoBox messaggio={t('common.modificaSalvato')} />
                                </div>

                        )
                    }
                </>


            </form>
        </div>
    )
})


export default BusinessUnitGruppoRuoloForm;