import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
//Data
import { useForm } from 'react-hook-form'
import { config } from '../../config'
import { useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

//Components
import TestoErrore from '../common/TestoErrore'
import ErrorBox from '../common/ErrorBox'
import InfoBox from '../common/InfoBox'
import { useStores } from '../../hooks/useStores'
import { Link } from 'react-router-dom'


export const ImpostaNuovaPasswordForm = observer(() => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const { auth } = useStores();

  //Effettua redirect su login se non sono presenti i parametri query string necessari
  useEffect(() => {
    if (!searchParams.get('token')) {
      document.location.href = '/'
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { register, handleSubmit, watch, setError, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      password: '',
      repeatPassword: '',
    }
  });

  const onSubmit = () => {
    //Controlla RegEx Password
    if (!watch('password').match(config.PASSWORD_REGEX)) {

      setError('password', { type: "custom", message: t('common.passwordRegex') })

      //Controlla matching password
    } else if (watch('password') !== watch('repeatPassword')) {

      setError('repeatPassword', { type: "custom", message:t('FormCompleteAccount.passwordNOnCoincidono') })

    } else {

      auth.settaNuovaPassword(searchParams.get('token') as string, watch('password'))

    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form">
          <label htmlFor="">{t('common.nuovaPassword')} </label>
          <input  {...register("password", { required: t('FormCompleteAccount.fornisciPassword')})} placeholder={t('common.password')} type="password" />

          {errors.password && <TestoErrore errore={errors.password.message} />}
        </div>

        <div className="form">
          <label htmlFor="">{t('FormCompleteAccount.ripetiLaPassword')}</label>
          <input  {...register("repeatPassword", { required: t('FormCompleteAccount.ripetiLaPassword') })} placeholder={t('common.ripetiLaPassword')} type="password" />

          {errors.repeatPassword && <TestoErrore errore={errors.repeatPassword.message} />}
        </div>

        <div className="form pt-4">

          {
            auth.nuovaPasswordSettata ?
              <InfoBox messaggio= {t('FormImpostaNuovaPassword.messaggioSucesso')}/>
              :
              <input type="submit" value={t('PaginaImpostaPassword.impostaNuovaPassword')} />
          }

        </div>

      </form>

      {isSubmitSuccessful && (
        auth.nuovaPasswordSettata ? (
          <Link className="text-white bg-accent text-center w-full p-2 rounded-sm block" to="/">
            {t('FormCompleteAccount.vailalLogin')}
          </Link>
        ) : (
          <ErrorBox errore={auth.nuovaPasswordSettataError} />
        )
      )}
    </>
  )
})