import { useEffect } from 'react'
import { useStores } from './useStores';

const useSettaUtente = () => {

  const { auth, gestioneModuli, gestionePrivilegi } = useStores();

  useEffect(() => {

    if (localStorage.getItem('utente')) {

      //Richiede aggiornamenti
      auth.userUpdate();

      //Popola lo stato
      auth.userFromStore();

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gestioneModuli.moduloModificato,
    gestioneModuli.moduloEliminato,
    gestioneModuli.creatingModulo,
    gestionePrivilegi.creatingPrivilegio,
    gestionePrivilegi.privilegioEliminato,
    gestionePrivilegi.privilegioModificato,
    gestionePrivilegi.privilegioAttribuito
  ])

}

export default useSettaUtente;