import React, { useState } from 'react'
import { useStores } from '../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import { FaAngleLeft, FaPlus } from 'react-icons/fa';

const DebugModal = observer(() => {

  const [show, setShow] = useState(true);

  const styles = {
    modal: `
        fixed bg-accent dark:bg-dark-accent shadow-xl text-white rounded-md p-4 bottom-2 p-2 z-[100] duration-200 w-[200px]
        ${show ? 'right-2' : 'right-[-180px]'}
        `,
    icona: "border border-accent bg-white shadow-lg rounded-full text-black p-1 cursor-pointer duration-200 w-5 h-5 flex items-center justify-center hover:bg-lightgray-d"
  }

  const { moduli, auth, ui } = useStores();

  return (
    <div className={styles.modal} >
      <div className="relative">

        {moduli.moduloAttivo ?
          <>
            <p className="font-bold mb-1">Modulo corrente:</p>
            <p>Nome: {moduli.moduloAttivo?.nome}</p>
            <p>Codice: {moduli.moduloAttivo?.codice}</p>
            <p>Privilegio: {moduli.moduloAttivo?.privilegio}</p>
          </>
          :
          <p>Nessun modulo trovato in questa pagina</p>
        }

        <hr className="my-2" />

        <p className="font-bold mb-1">Moduli attivi per l'utente corrente:</p>

        {
          auth.codiciModuli.length > 0 ?
            <ul>
              {auth.codiciModuli.map((codice: string) => (
                <li key={codice}>{codice}</li>
              ))}
            </ul>
            :
            <p>Nessun modulo attivo</p>
        }

        <div className="flex flex-col gap-1 absolute left-[-40px] top-[-40px]">
          <div
            onClick={() => setShow(!show)}
            className={styles.icona}
          >

            <FaAngleLeft className={`${show && 'rotate-180'}`} />

          </div>

          <div
            onClick={() => ui.toggleDebug()}
            className={styles.icona}
          >

            <FaPlus size={12} className="rotate-45" />

          </div>
        </div>
      </div>
    </div>
  )
})

export default DebugModal;