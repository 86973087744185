import React, { ReactNode } from 'react'
import Footer from './Footer'
import { useLocation } from 'react-router-dom'
import { FaAngleRight } from 'react-icons/fa'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../hooks/useStores'

const Pagina: React.FC<{ titolo?: string, descrizione?: string, children: ReactNode }> = observer(({ titolo, descrizione, children }) => {

  const styles = {
    page: "relative flex flex-col min-h-full",
    pageHeaderWrapper: "py-6 sticky top-0 bg-lightgray-d dark:bg-darkgray-d w-inherit justify-center flex flex-col box-content",
    pageHeaderContent: "p-2 md:p-4 flex flex-col",
    titolo: "text-4xl font-bold mb-2",
    descrizione: "text-text-light dark:text-dark-text-light text-xl",
    pageContent: "bg-lightgray dark:bg-darkgray p-2 md:p-4 relative ipe-shad grow border-t border-lightgray-dd dark:border-darkgray-dd"
  }

  const { pathname } = useLocation()

  const breadcrumbs = pathname.split('/')

  const { ui } = useStores()

  return (
    <div className={styles.page} onMouseEnter={() => ui.unmountFollowMouse()}>
      <div className={styles.pageHeaderWrapper}>
        <div className={styles.pageHeaderContent}>
          <div className="flex gap-1 mb-1 items-center">
            {breadcrumbs.filter(item => item !== 'app' && item !== '').map((item: string, index) => (
              <>
                <span key={index}>{item}</span>
                {breadcrumbs.length > (index + 3) && <FaAngleRight size={8} />}
              </>
            ))}
          </div>
          <h1 className={styles.titolo}>{titolo}</h1>
          <p className={styles.descrizione}>{descrizione}</p>
        </div>
      </div>

      <div className={styles.pageContent}>
        {/* Carica children pagina*/}
        {children}
      </div>

      <Footer />
    </div>
  )
})

export default Pagina;