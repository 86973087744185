import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaInfoCircle } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import AttivitaTile from '../../../components/attivita/AttivitaTile'
import { getAttivitaUtente } from '../../../rest/attivita'
import { IAttivita } from '../../../types'

export default function WidgetAttivitaDashboard() {
    const { t } = useTranslation();

    const [listaAttivita, setListaAttivita] = useState<IAttivita[]>([])

    useEffect(() => {
        getAttivitaUtente(0, 10).then((res) => (
            setListaAttivita(res.data.data)
        ))
    }, [])

    return (
        <div>
            <h2 className="font-bold text-xl mb-4 mt-6">{t('ModuloDashboard.widgetAttivita.attivitaRecenti')}</h2>
            {listaAttivita.length > 0 ?
                <>
                    <div className="max-h-[400px] overflow-y-auto shadow-xl">
                        {listaAttivita.map((attivita) => (
                            <AttivitaTile key={attivita.id} attivita={attivita} />
                        ))}
                    </div>
                    <Link className="w-full py-4 text-center block" to="/app/attivita">{t('ModuloDashboard.widgetAttivita.vediTuttiAttivita')}</Link>
                </> :
                <div className="text-lg opacity-70 flex items-center gap-2">
                    <FaInfoCircle />
                    <span>{t('ModuloDashboard.widgetAttivita.nessunAttivita')}</span>
                </div>
            }
        </div>
    )
}
