import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../../../../../hooks/useStores'
import { ITemaMaterialita } from '../../../../types'
import { observer } from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../../../../../hooks/useOttieniPrivilegioModulo'
import { useTemaMaterialitaStores } from '../../context/useTemaMaterialitaStores'

//Components
import TemaMaterilaitaForm from './TemaMaterialitaForm'
import Dialogue from '../../../../../../components/common/Dialogue'

const TemaMaterialitaContextMenu: React.FC<{ tema: ITemaMaterialita }> = observer(({ tema }) => {

  const { materialita } = useTemaMaterialitaStores()

  const privilegio = useOttieniPrivilegioModulo()
  const { ui } = useStores()
  const { t } = useTranslation()


  const handleEliminaTemaMaterialita = () => {
    materialita.eliminaTemaMaterialita(tema.codice).then(() => {

      if (materialita.eliminaTemaMaterialitaError) {

        ui.mountDialogue(<Dialogue testo={materialita.eliminaTemaMaterialitaError} />)

      } else {

        ui.mountDialogue(<Dialogue testo={t('ModuloFattoriEsg.TemaMaterialitaContextMenu.TemaEliminatoSucesso')} />)

      }

    });
  }

  return (
    <div className="absolute shadow-lg border border-lightgray-dd bg-white dark:bg-black p-3 right-0 dark:border-0">
      <ul className="whitespace-nowrap flex flex-col gap-2">
        {privilegio >= 3 && <li onClick={() => ui.mountAsideModal(<TemaMaterilaitaForm tema={tema} />)}>{t('ModuloFattoriEsg.FormTemaMaterialitaBilancio.modifciatemaDiMaterialita')}</li>}
        {privilegio >= 4 &&
          <li
            className="text-red dark:text-dark-red"
            onClick={() => ui.mountDialogue(
              <Dialogue
                testo={`${t('ModuloFattoriEsg.TemaMaterialitaContextMenu.eliminaTema')} ${tema.nome}? ${t('ModuloFattoriEsg.ObiettivoAgenda2030ContextMenu.QuestaIrreversibile')}`}
                primaryCta={`${t('ModuloFattoriEsg.TemaMaterialitaContextMenu.eliminaTemaMaterialita')} ${tema.nome}`}
                primaryCtaColor="bg-red dark:bg-dark-red"
                secondaryCta="Annulla"
                secondaryCtaColor="bg-white dark:bg-darkgray-dd !text-text dark:!text-dark-text"
                onConfirm={() => handleEliminaTemaMaterialita()}
              />)}
          >
            {t('ModuloFattoriEsg.TemaMaterialitaContextMenu.eliminaTemaMaterialita')}
          </li>
        }
      </ul>
    </div>
  )
})

export default TemaMaterialitaContextMenu;
