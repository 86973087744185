import { observable, action, makeAutoObservable } from 'mobx'

export class UiCanvasStore {

    fullscreen: boolean = false

    ricerca: boolean = false
    stringa_ricerca: string = ''

    modalitaVista: 'default' | 'minificata' | 'blocchi' = 'default'

    constructor() {
        makeAutoObservable(this, {
            fullscreen: observable,
            toggleFullscreen: action,

            ricerca: observable,
            stringa_ricerca: observable,
            toggleRicerca: action,
            attribuiStringaRicerca: action,

            modalitaVista: observable,
            settaModalitaVista: action
        })
    }

    toggleFullscreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            this.fullscreen = true


        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                this.fullscreen = false
            }
        }
    }

    attribuiStringaRicerca = (stringa_ricerca: string) => {
        this.stringa_ricerca = stringa_ricerca
    }

    toggleRicerca = () => {
        this.ricerca = !this.ricerca
    }

    settaModalitaVista = (modalita: 'default' | 'minificata' | 'blocchi') => {
        this.modalitaVista = modalita
    }

}