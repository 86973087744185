import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../hooks/useStores'
import { IModulo } from '../../types'
import { observer } from 'mobx-react-lite'
import { getModuli } from './rest/gestioneModuli'
import { useForm } from 'react-hook-form'

//Components
import { FaAngleUp, FaAngleDown } from 'react-icons/fa'
import { BsArrowDownCircleFill, BsArrowUpCircleFill } from 'react-icons/bs'
import IconaDinamica from '../../components/common/IconaDinamica'
import InfoBox from '../../components/common/InfoBox'
import ErrorBox from '../../components/common/ErrorBox'

const OrdinaModuli = observer(() => {

  const { ui, gestioneModuli } = useStores()

  const [listaModuli, setListaModuli] = useState<IModulo[]>([])

  const styles = {
    'tile': 'w-full p-3 bg-white shadow-lg rounded-md flex justify-between items-center hover:bg-lightgray-d dark:bg-darkgray-d dark:text-white'
  }

  const { handleSubmit, formState: { isSubmitSuccessful } } = useForm()
  const { t } = useTranslation();
  //Ordinamento può essere null.
  //Setta un valore di default di ordinamento per ogni modulo
  useEffect(() => {
    getModuli().then((res) => {
      let moduli = res.data.data;
      let moduliordinati: IModulo[] = aggiornaOrdinamentoListaModuli(moduli)

      setListaModuli(moduliordinati)
    })
  }, [])

  const aggiornaOrdinamentoListaModuli = (moduli: IModulo[]) => {
    let moduliOrdinati: IModulo[] = []

    let i = 0;

    moduli.forEach((modulo: IModulo) => {
      modulo.ordinamento = i;
      moduliOrdinati.push(modulo)
      i++;
    })

    return moduliOrdinati
  }

  const sortListaModuli = (moduli: IModulo[]) => {
    const moduliDaOrdinare: IModulo[] = [
      ...moduli
      .sort((a, b) => (a.visibile_navbar === b.visibile_navbar) ? 0 : a.visibile_navbar? -1: 1)
      .sort((a, b) => (a.visibile_header === b.visibile_header) ? 0 : a.visibile_header? -1: 1)
      .sort((a, b) => a.ordinamento - b.ordinamento)
    ]

    const moduliOrdinati = aggiornaOrdinamentoListaModuli(moduliDaOrdinare)

    setListaModuli(moduliOrdinati)
  }

  const riordinaModulo = (codice: string, spostamento: number) => {
    const modulo = listaModuli.find((modulo: IModulo) => modulo.codice === codice)
    
    if (!modulo || modulo.ordinamento <= 0) {
      return null
    }
    
    const nuovoOrdinamento = modulo.ordinamento + spostamento
    let moduloPrecedente = listaModuli.find((modulo: IModulo) => modulo.ordinamento === nuovoOrdinamento)

    if (moduloPrecedente) {
      moduloPrecedente.ordinamento = modulo.ordinamento
    }
    modulo.ordinamento = nuovoOrdinamento

    sortListaModuli(listaModuli)
  }

  //Funzione per spostare il modulo nella navbar
  const spostaModulo = (codice: string, visibile_navbar: boolean, visibile_header: boolean) => {
    if (codice) {
      let moduli = listaModuli;
      let modulo = moduli.find((modulo: IModulo) => modulo.codice === codice)

      if (modulo) {
        modulo.visibile_navbar = visibile_navbar;
        modulo.visibile_header = visibile_header;
        sortListaModuli(moduli)
      }
    }
  }

  const filtraModuli = (visibile_navbar: boolean, visibile_header: boolean) => {
    let moduli = listaModuli.filter(modulo => modulo.visibile_navbar)

    if (visibile_header) {
      moduli = listaModuli.filter(modulo => modulo.visibile_header)
    }

    if (!visibile_navbar && !visibile_header) {
      moduli = listaModuli.filter(modulo => !modulo.visibile_navbar && !modulo.visibile_header)
    }

    return moduli
  }

  const checkPrimoModulo = (modulo: IModulo, visibile_navbar: boolean, visibile_header: boolean) => {
    let moduli = filtraModuli(visibile_navbar, visibile_header)

    return modulo.ordinamento <= Math.min(...moduli.map(modulo => modulo.ordinamento))
  }

  const checkUltimoModulo = (modulo: IModulo, visibile_navbar: boolean, visibile_header: boolean) => {
    let moduli = filtraModuli(visibile_navbar, visibile_header)

    return modulo.ordinamento >= Math.max(...moduli.map(modulo => modulo.ordinamento))
  }

  const onSubmit = () => {
    gestioneModuli.ordinaModuli(listaModuli)
  }

  return (
    <div>
      <div className="mb-4">
        <h2 className="text-2xl mb-2">{t('ModuloGestioneModuli.Ordinamento.riordinaVisualizzazioni')}</h2>
      </div>

      <div className="mb-2">
        <h3 className="text-xl mb-2">{t('ModuloGestioneModuli.Ordinamento.riordinaNavbar')}</h3>
        <p className="text-text-light">
        {t('ModuloGestioneModuli.Ordinamento.testoNavBar')}
        </p>
      </div>

      <div className="flex flex-col gap-2 my-4">
        {
          listaModuli && filtraModuli(true, false).map((modulo: IModulo) => {
            return (
              <div key={modulo.codice} className={styles.tile}>
                <p className="flex gap-2 items-center"><IconaDinamica nome={modulo.icona} />{modulo.nome}</p>
                <div className="flex items-center gap-2 justify-end">
                  <BsArrowDownCircleFill
                    className="cursor-pointer"
                    onMouseEnter={() => ui.mountFollowMouse(<p className="text-xs">{t('ModuloGestioneModuli.Ordinamento.spostaNelMenuUtente')}</p>)}
                    onMouseLeave={() => ui.unmountFollowMouse()}
                    size={15}
                    onClick={() => spostaModulo(modulo.codice, false, true)}
                  />
                  <div className="flex flex-col items-center jufity-between gap-1">
                    {
                      !checkPrimoModulo(modulo, true, false) &&
                      <FaAngleUp className="cursor-pointer" size={15} onClick={() => riordinaModulo(modulo.codice, -1)} />
                    }

                    {
                      !checkUltimoModulo(modulo, true, false) &&
                      <FaAngleDown className="cursor-pointer" size={15} onClick={() => riordinaModulo(modulo.codice, 1)} />
                    }
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>

      <div className="mb-2">
        <h3 className="text-xl mb-2">{t('ModuloGestioneModuli.Ordinamento.riordinaNelMenuUtente')}</h3>
        <p className="text-text-light">
        {t('ModuloGestioneModuli.Ordinamento.testoMenuUtente')}
        </p>
      </div>

      <div className="flex flex-col gap-2 my-4">
        {
          listaModuli && filtraModuli(false, true).map((modulo: IModulo) => {
            return (
              <div key={modulo.codice} className={styles.tile}>
                <p className="flex gap-2 items-center"><IconaDinamica nome={modulo.icona} />{modulo.nome}</p>
                <div className="flex items-center gap-2 justify-end">
                  <BsArrowUpCircleFill
                    className="cursor-pointer"
                    onMouseEnter={() => ui.mountFollowMouse(<p className="text-xs">{t('ModuloGestioneModuli.Ordinamento.spostaNelNavBar')}</p>)}
                    onMouseLeave={() => ui.unmountFollowMouse()}
                    size={15}
                    onClick={() => spostaModulo(modulo.codice, true, false)}
                    />
                  <BsArrowDownCircleFill
                    className="cursor-pointer"
                    onMouseEnter={() => ui.mountFollowMouse(<p className="text-xs">{t('ModuloGestioneModuli.Ordinamento.spostaNeiModuliNonVisibile')}</p>)}
                    onMouseLeave={() => ui.unmountFollowMouse()}
                    size={15}
                    onClick={() => spostaModulo(modulo.codice, false, false)}
                    />
                  <div className="flex flex-col items-center justify-between gap-1">
                    {
                      !checkPrimoModulo(modulo, false, true) &&
                      <FaAngleUp className="cursor-pointer" size={15} onClick={() => riordinaModulo(modulo.codice, -1)} />
                    }

                    {
                      !checkUltimoModulo(modulo, false, true) &&
                      <FaAngleDown className="cursor-pointer" size={15} onClick={() => riordinaModulo(modulo.codice, 1)} />
                    }
                </div>
                </div>
              </div>
            )
          })
        }
      </div>

      <div className="mb-2">
        <h3 className="text-xl mb-2">{t('ModuloGestioneModuli.Ordinamento.moduliNonVisibili')}</h3>
        <p className="text-text-light">
        {t('ModuloGestioneModuli.Ordinamento.testModuliNonVisibile')}
        </p>
      </div>

      <div className="flex flex-col gap-2 my-4">
        {
          listaModuli && filtraModuli(false, false).map((modulo: IModulo) => {
            return (
              <div key={modulo.codice} className={styles.tile}>
                <p className="flex gap-2 items-center"><IconaDinamica nome={modulo.icona} />{modulo.nome}</p>
                <div className="flex flex-col items-center jufity-between gap-1">
                  <BsArrowUpCircleFill
                    className="cursor-pointer"
                    onMouseEnter={() => ui.mountFollowMouse(<p className="text-xs">{t('ModuloGestioneModuli.Ordinamento.spostaNelMenuUtente')}</p>)}
                    onMouseLeave={() => ui.unmountFollowMouse()}
                    size={15}
                    onClick={() => spostaModulo(modulo.codice, false, true)}
                  />
                </div>
              </div>
            )
          })
        }
      </div>

        <form onSubmit={handleSubmit(onSubmit)} className="form mt-4">
          <input type="submit" className="button" value="Aggiorna lista moduli" />
        </form>

        {isSubmitSuccessful && !gestioneModuli.orderingModuli && (
          gestioneModuli.orderingModuliError
            ?
            <ErrorBox errore={gestioneModuli.orderingModuliError} />
            :
            <InfoBox messaggio="Ordine moduli modificato con successo" />
        )
        }

    </div>
  )
})

export default OrdinaModuli;