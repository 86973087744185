import React, { ReactNode } from 'react'

const BottoneCanvasGenerico: React.FC<{ children: ReactNode, className?: string, effettoHover?: boolean }> = ({ children, className, effettoHover = true }) => {

    
    const styles = {
        wrapper: `flex items-center justify-center relative left-2 ml-2
                  duration-200 w-[250px] p-4 rounded-sm border border-accent text-accent
                  dark:text-dark-accent dark:border-dark-accent cursor-default
                  ${effettoHover && 'hover:bg-accent hover:text-white dark:hover:bg-dark-accent dark:hover:text-white cursor-pointer'}
                  `,
    }

  return (
    <div className="flex items-stretch justify grow relative">
        <div className="w-2 h-[2px] bg-accent absolute top-1/2 left-2" />
        <div className={`${styles.wrapper} ${className}`}>
            {children}
        </div>
    </div>
  )
}

export default BottoneCanvasGenerico;