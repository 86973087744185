import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { observer } from 'mobx-react-lite'

//Components
import { FaInfoCircle, FaPlus, FaSlidersH } from 'react-icons/fa'
import ReactPaginate from 'react-paginate'

import { useStores } from '../../../../hooks/useStores'
import TileRichiestaUtente from '../../RichiestaUtenteTile'
import BarraRicercaGenerale from '../../../../components/common/BarraRicercaGenerale'
import { IRichiesta } from '../../../../types'


export const PannelloRichiesteAccettate : React.FC<{richiesta : IRichiesta[]}> = observer(({richiesta}) => {
    const { gestioneUtenti } = useStores();
    const { t } = useTranslation();

    const styles = {
        cardsWrapper: "grid gap-3 xl:gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
        requestsButton: "cursor-pointer hover:text-accent rounded-sm p-2 hover:bg-lightgray-d flex gap-4 items-center",
        headerPannello: "flex items-center justify-between flex-col md:flex-row my-5",
        buttonRichiesteGestite: `relative flex md:block justify-center  ${richiesta.filter(richiesta => richiesta.gestita === true).length < 1 && 'opacity-70 pointer-events-none'}`,
        btnFiltri: "text-white opacity-70 p-1 rounded-lg w-[120px] lg:w-[200px]",
    }
    const [arrayFiltri, setFiltri] = useState<boolean>(false)

    const chiudFiltri = () => {
        setFiltri(false)
        gestioneUtenti.getRichieste()
        setClickedButton("")
    }

    //Dati per paginazione richieste rifiutate
    const [stringaDiRicercaRifiutate, setStringaDiRicercaRifiutate] = useState<string>('');
    const dataRichiesteRifiutate = richiesta.filter(richiesta => richiesta.accettata === false);
    const richiesteRifiutateFiltrate = dataRichiesteRifiutate.filter((richiesta) => richiesta.nome.toLocaleLowerCase().includes(stringaDiRicercaRifiutate.toLocaleLowerCase()) || richiesta.email.toLocaleLowerCase().includes(stringaDiRicercaRifiutate.toLocaleLowerCase()));
    const [numeroPagineRifiutate, setNumeroPagineRifiutate] = useState(0);
    const richiestePerPaginaRifiutate = 4;
    const pagineRifiutateVisitate = numeroPagineRifiutate * richiestePerPaginaRifiutate;
    const conteggioPaginaRifiutate = Math.ceil(richiesteRifiutateFiltrate.length / richiestePerPaginaRifiutate);
    const cambioPaginaRifiutate = ({ selected }: { selected: number }): void => {
        setNumeroPagineRifiutate(selected)
    };
    useEffect(() => {
        setNumeroPagineRifiutate(0);
    }, [stringaDiRicercaRifiutate])
    const [clickedButton, setClickedButton] = useState("");

    const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>): any => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;
        setClickedButton(button.name);
    }

    useEffect(() => {
        if(clickedButton === "resetta") {
            gestioneUtenti.getRichieste()
        }
    },[clickedButton])

    const richiesteAccettateOrdinate = (testo: string) => {

        let richieste = richiesteRifiutateFiltrate

        if (testo === "az") {
            richieste = richieste
                .sort((a: IRichiesta, b: IRichiesta) => b.nome > a.nome ? 1 : -1)
        } else if (testo === "za") {
            richieste = richieste
                .sort((a: IRichiesta, b: IRichiesta) => b.nome > a.nome ? -1 : 1)
        } 
        return richieste.slice(pagineRifiutateVisitate, pagineRifiutateVisitate + richiestePerPaginaRifiutate)
            ;
    }

    useEffect(() => {
        gestioneUtenti.getRichieste();

    }, [gestioneUtenti.editingRichiesta])


    return (
        <div className='shadow-xl px-4 py-2'>
        

            <div className="lg:flex text-center justify-between mb-3 text-accent dark:text-dark-accent mt-6 border-[lightgray] border-b-2 py-2 mt-6">
                <span className="text-lg lg:text-2xl font-bold uppercase text-accent dark:text-dark-accent"> {richiesta.filter((richiesta) => richiesta.accettata === false).length}
                    <span className="text-lg lg:text-2xl font-bold uppercase text-accent dark:text-dark-accent mx-1">
                        {richiesta.filter((richiesta) => richiesta.accettata === false).length === 1 ?
                            `${t('ModuloGestioneUtenti.PannelloRichieste.richiestaRifiutata')}`
                            :
                            `${t('ModuloGestioneUtenti.PannelloRichieste.richiesteRifiutate')}`
                        }
                    </span>
                </span>
                <BarraRicercaGenerale 
                    setStringaDiRicerca={setStringaDiRicercaRifiutate} 
                    numeroRisultati={richiesteRifiutateFiltrate.length} 
                    mostraRisultati={true}/>
            </div>

            <div className="flex items-center justify-end my-2">

                {arrayFiltri ?
                    <div className="cursor-pointer p-1 hover:bg-white rounded-sm rotate-45" onClick={() => chiudFiltri()}>
                        <FaPlus />
                    </div>
                    :
                    <div className="cursor-pointer p-1 hover:bg-white rounded-sm" onClick={() => setFiltri(true)} >
                        <FaSlidersH />
                    </div>
                }
            </div>

            {arrayFiltri &&
                <div className='grid grid-cols-3 gap-2 px-4 my-4 m-auto mostraFiltri'>
                    <div className='flex justify-center'>
                        <button onClick={buttonHandler} className={`${styles.btnFiltri} ${clickedButton === "az" ? "opacity-100" : ""}`} name="az"> Ordina per nome desc </button>
                    </div>
                    <div className='flex justify-center'>
                        <button onClick={buttonHandler} className={`${styles.btnFiltri} ${clickedButton === "za" ? "opacity-100" : ""}`} name="za"> Ordina per nome asc </button>
                    </div>
                    <div className='flex justify-center'>
                        <button onClick={buttonHandler} className={`${styles.btnFiltri}`} name="resetta"> Resetta filtro </button>
                    </div>
                </div>
            }
            {

                //Card Richieste Neutre
                richiesteRifiutateFiltrate.length > 0 ?
                    (
                        <><div className={`${styles.cardsWrapper} mb-4`}>
                            {richiesteAccettateOrdinate(clickedButton)
                                .map((richiesta, i) => { return <TileRichiestaUtente 
                                    bShow={false}
                                    key={i} 
                                    richiesta={richiesta} 
                                    rifiutata={true}
                                    accettata={false} /> 
                                })
                            }
                        </div>
                            {richiesteRifiutateFiltrate.length >= 4 &&
                            <div className="mb-2">
                                <ReactPaginate
                                    pageCount={conteggioPaginaRifiutate}
                                    nextLabel={'Next'}
                                    onPageChange={cambioPaginaRifiutate}
                                    forcePage={numeroPagineRifiutate}
                                    containerClassName={"paginationBttns"}
                                    previousLinkClassName={"previousBttn"}
                                    nextLinkClassName={"nextBttn"}
                                    disabledClassName={"paginationDisabled"}
                                    activeClassName={"paginationActive"} />
                            </div>
                            }
                        </>

                    )
                    :
                    (
                        <>
                            <p className="flex items-center gap-2 opacity-70 justify-center">
                                <FaInfoCircle /> <span className="text-lg">{t('ModuloGestioneUtenti.PannelloRichieste.nessunaRichiestaRifiutata')}</span>
                            </p>
                        </>

                    )}
        </div>

    )
}
)
export default PannelloRichiesteAccettate