import { observable, action, makeAutoObservable, runInAction } from 'mobx'
import { IObiettivoAgenda2030 } from '../../../types'
import {
    getObiettivoAgenda2030,
    creaObiettivoAgenda2030,
    modificaObiettivoAgenda2030,
    eliminaObiettivoAgenda2030,
    getListaObiettiviAgenda2030
} from '../rest/agenda_2030'

export class Agenda2030Store {

    obiettiviAgenda2030 : IObiettivoAgenda2030[] = []

    gettingObiettivoAgenda2030Attivo: boolean = false
    obiettivoAgenda2030Attivo: IObiettivoAgenda2030 | null = null

    creatingObiettivoAgenda2030 = false
    creatingObiettivoAgenda2030Error: string = ''
    obiettivoAgenda2030Creato = false

    editingObiettivoAgenda2030 = false
    modificaObiettivoAgenda2030Error: string = ''
    obiettivoAgenda2030Modificato = false

    deletingObiettivoAgenda2030: boolean = false
    eliminaObiettivoAgenda2030Error: string = ''
    obiettivoAgenda2030Eliminato: boolean = false

    constructor() {
        makeAutoObservable(this, {
            
            obiettiviAgenda2030: observable,

            gettingObiettivoAgenda2030Attivo: observable,
            obiettivoAgenda2030Attivo: observable,

            creatingObiettivoAgenda2030: observable,
            creatingObiettivoAgenda2030Error: observable,
            obiettivoAgenda2030Creato: observable,
            creaObiettivoAgenda2030: action,

            editingObiettivoAgenda2030: observable,
            modificaObiettivoAgenda2030Error: observable,
            obiettivoAgenda2030Modificato: observable,
            modificaObiettivoAgenda2030: action,

            deletingObiettivoAgenda2030: observable,
            eliminaObiettivoAgenda2030Error: observable,
            obiettivoAgenda2030Eliminato: observable,
            eliminaObiettivoAgenda2030: action
          
        })
    }
    //get lista dei materialia
    getListaObiettiviAgenda2030 = () => {

        this.gettingObiettivoAgenda2030Attivo = true;

        getListaObiettiviAgenda2030().then((res) => {
            runInAction(() => { this.obiettiviAgenda2030 = res.data.data })
        }).catch((err) => {
            console.log(err.data.data)
        }).finally(() => {
            this.gettingObiettivoAgenda2030Attivo = false;
        })
    }

    //get singolo materialita
    getObiettivoAgenda2030 = (id: number) => {

        this.gettingObiettivoAgenda2030Attivo = true;

        getObiettivoAgenda2030(id).then((res) => {

            runInAction(() => { this.obiettivoAgenda2030Attivo = res.data.data })

        }).catch((err) => {

            console.log(err.data.data)

        }).finally(() => {

            this.gettingObiettivoAgenda2030Attivo = false;

        })
    }
    
    //crea creaObiettivoAgenda2030
    creaObiettivoAgenda2030 = async (materialita: IObiettivoAgenda2030) => {

        runInAction(() => this.creatingObiettivoAgenda2030 = true)

        await creaObiettivoAgenda2030(materialita).then(() => {

            runInAction(() => this.creatingObiettivoAgenda2030Error = '')

            this.getObiettivoAgenda2030(this.obiettivoAgenda2030Attivo?.id as number)

        }).catch((err) => {

            runInAction(() => this.creatingObiettivoAgenda2030Error = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {
            runInAction(() => this.creatingObiettivoAgenda2030 = false)
            runInAction(() => this.obiettivoAgenda2030Creato = !this.obiettivoAgenda2030Creato)
        })
    }
    

    //modifica ObiettivoAgenda2030
    modificaObiettivoAgenda2030 = async (materialita: IObiettivoAgenda2030) => {

        runInAction(() => this.editingObiettivoAgenda2030 = true)

        await modificaObiettivoAgenda2030(materialita).then(() => {

            runInAction(() => this.modificaObiettivoAgenda2030Error = '')
            this.getObiettivoAgenda2030(this.obiettivoAgenda2030Attivo?.id as number)

        }).catch((err) => {
            runInAction(() => this.modificaObiettivoAgenda2030Error = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {
            runInAction(() => this.editingObiettivoAgenda2030 = false)
            runInAction(() => this.obiettivoAgenda2030Modificato = !this.obiettivoAgenda2030Modificato)
        })
    }

    //elimina ObiettivoAgenda2030
    eliminaObiettivoAgenda2030 = async (id: number) => {

        runInAction(() => this.deletingObiettivoAgenda2030 = true)

        await eliminaObiettivoAgenda2030(id).then(() => {

            runInAction(() => this.eliminaObiettivoAgenda2030Error = '')

        }).catch((err) => {

            console.log(err.data)
            runInAction(() => this.eliminaObiettivoAgenda2030Error = err.data)

        }).finally(() => {
            runInAction(() => this.deletingObiettivoAgenda2030 = false)
            runInAction(() => this.obiettivoAgenda2030Eliminato = !this.obiettivoAgenda2030Eliminato)
        })
    }


    

}