import React, { useState, useEffect } from 'react'

//Data
import {
    IGruppoBusinessUnit,
    IBusinessUnitRuolo
} from '../../UiModuloBusinessUnit/types'
import { IBusinessUnitNelBilancio } from '../types'
import { getRuoli } from '../../UiModuloBusinessUnit/UiModuloBusinessUnitRuoli/rest/BusinessUnitRuoli'

//Components
import SchedaBusinessUnitMappa from '../../UiModuloBusinessUnit/components/SchedaBusinessUnitMappa'
import GruppoBusinessUnitTile from '../../UiModuloBusinessUnit/components/GruppoBusinessUnitTile'

const InformativaBusinessUnit: React.FC<{ businessUnit: IBusinessUnitNelBilancio }> = ({ businessUnit }) => {

    const [BusinessUnitRuoli, setBusinessUnitRuoli] = useState<IBusinessUnitRuolo[]>()

    useEffect(() => {
        getRuoli().then((res) => {
            setBusinessUnitRuoli(res.data.data)
        })
    }, [])

    return (
        <div className="flex flex-col">
            <div>
                <div className="bg-white dark:darkgray-dd p-4 rounded-sm shadow-xl min-w-[300px]">
                    <div className="flex flex-col-reverse gap-2 md:flex-row items-start justify-between">

                        <div className="flex items-start gap-3 flex-col md:flex-row">
                            {businessUnit.longitudine && businessUnit.latitudine &&
                                <SchedaBusinessUnitMappa businessUnit={businessUnit} />
                            }

                            <div>
                                {businessUnit.categoria && <p className="font-bold bg-accent dark:bg-dark-accent text-white py-[4px] px-[8px] rounded-sm inline-block mb-2">Tipo: {businessUnit.categoria}</p>}
                                <h1 className="text-2xl font-bold mb-2">{businessUnit.nome}</h1>
                                <p><b>Categoria:</b> {businessUnit.categoria ? businessUnit.categoria : '-'}</p>
                                {businessUnit.via &&
                                    <p>
                                        <b>Indirizzo:</b>
                                        <span>
                                            {businessUnit.via && `Via ${businessUnit.via}`} {businessUnit.civico}
                                            {businessUnit.cap && `, ${businessUnit.cap}`}
                                            {businessUnit.citta && `, ${businessUnit.citta}`}
                                            {businessUnit.paese && `, ${businessUnit.paese}`}
                                        </span>
                                    </p>
                                }

                                {businessUnit.longitudine && businessUnit.latitudine &&
                                    <>
                                        <br />

                                        <p>
                                            <b>Coordinate:</b> <br />
                                            <p>Lat: {businessUnit.latitudine}</p>
                                            <p>Long: {businessUnit.longitudine}</p>
                                        </p>
                                    </>
                                }


                                {businessUnit.descrizione &&
                                    <>
                                        <br />
                                        <p>
                                            <b>Descrizione:</b> <br />
                                            {businessUnit.descrizione}
                                        </p>
                                    </>
                                }

                            </div>
                        </div>
                    </div>

                    {businessUnit.gruppi.length > 0 &&
                        <div>
                            <hr className="my-4 border border-lightgray-dd dark:darkgray" />
                            <h2 className="font-bold text-lg mb-2">{businessUnit.gruppi.length} {businessUnit.gruppi.length === 1 ? 'ruolo' : 'ruoli'}  attivi per questa business unit:</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
                                {
                                    businessUnit.gruppi.map((gruppo: IGruppoBusinessUnit) => {
                                        return (
                                            <GruppoBusinessUnitTile gruppo={gruppo} ruoli={BusinessUnitRuoli as IBusinessUnitRuolo[]} nomeBu={businessUnit.nome} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>

        </div>
    )
}

export default InformativaBusinessUnit;