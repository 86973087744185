import { observable, action, makeAutoObservable, runInAction } from 'mobx'

//Interfacce
import {
IPanoramicaUtente, 
IPanoramicaRichiesta,
IPanoramicaGruppo ,
IPanoramicaAttivita
} from '../../../types'

//Metodi
import { getPanoramicaUtenti } from '../rest/panoramicaUtenti'
import { getPanoramicaGruppi } from '../rest/panoramicaGruppi'
import { getPanoramicaRichieste } from '../rest/panoramicaRichieste'
import { getPanoramicaAttivita } from '../rest/panoramicaAttivita'


export class panoramicaAmministrazioneUtentiAccessiStore {

    panoramicaUtenti: IPanoramicaUtente[] = []
    panoramicaGruppi: IPanoramicaGruppo[] = []
    panoramicaRichieste: IPanoramicaRichiesta [] = []
    panoramicaAttivita: IPanoramicaAttivita [] = []

    constructor() {
        makeAutoObservable(this, {
            //Panoramica utenti
            panoramicaUtenti: observable,
            getPanoramicaUtenti: action,
            //Panoramica gruppi
            panoramicaGruppi: observable,
            getPanoramicaGruppi: action,
            //Panoramica richieste
            panoramicaRichieste: observable,
            getPanoramicaRichieste:action,
            //Panoramica attivita
            panoramicaAttivita: observable,
            getPanoramicaAttivita: action
        })
    }
    //Riceve panoramica utenti
    async getPanoramicaUtenti() {
        await getPanoramicaUtenti().then(res => {
            runInAction(() => this.panoramicaUtenti = res.data.data)
        }).catch(err => {
            console.log(err)
        })
    }

    //Riceve panoramica gruppi
    async getPanoramicaGruppi() {
        await getPanoramicaGruppi().then(res => {
            runInAction(() => this.panoramicaGruppi = res.data.data)
        }).catch(err => {
            console.log(err)
        })
    }

    //Riceve panoramica richieste
    async getPanoramicaRichieste() {
        await getPanoramicaRichieste().then(res => {
            runInAction(() => this.panoramicaRichieste = res.data.data)
        }).catch(err => {
            console.log(err)
        })
    }

    //Riceve panoramica attivita
    async getPanoramicaAttivita() {
        await getPanoramicaAttivita().then(res => {
            runInAction(() => this.panoramicaAttivita = res.data.data)
        }).catch(err => {
            console.log(err)
        })
    }    
    
}

