import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import { IGruppo, IModulo } from '../../types'
import dayjs from 'dayjs'
import ModuloTile from '../../moduli/gestioneModuli/ModuloTile'

const SchedaUtente = observer(() => {
    const { t } = useTranslation();

    const { auth } = useStores();

    return (
        <div className="shadow-xl">
            <div className="bg-lightgray-d px-4 py-3 text-sm dark:bg-darkgray-dd">
            {t('ModuloGestioneUtenti.SchedaUtente.schedaUtente')}
            </div>
            <div className="bg-white dark:bg-darkgray-d p-4">
                {auth.utente?.is_admin && <div className="text-accent dark:text-dark-accent font-bold">{t('ModuloGestioneUtenti.SchedaUtente.titoloAdmin')}</div>}
                <h2 className="text-2xl mb-3">{t('common.nome')}: {auth.utente?.nome} {auth.utente?.cognome}</h2>
                <p><strong>{t('common.telefono')}:</strong> {auth.utente?.telefono}</p>
                <p><strong>{t('common.eMail')}:</strong> {auth.utente?.email}</p>
                <p><strong>{t('common.azienda')}:</strong> {auth.utente?.azienda}</p>
                <p><strong>{t('ModuloGestioneUtenti.SchedaUtente.accountCreato')}:</strong> {dayjs(auth.utente?.created_at).locale('it').format('d MMMM YYYY')} </p>

                <hr className="my-4 border-lightgray-dd" />

                <div className="rounded-sm">
                    {auth.utente && auth.utente?.gruppi ?
                        <div>
                            <p className="font-bold mb-2">{t('ModuloGestioneUtenti.SchedaUtente.gruppiUtenti')}:</p>
                            <ul className="flex flex-col gap-2">
                                {auth.utente?.gruppi.map((gruppo: IGruppo) => {
                                    return (
                                        <li key={gruppo.codice} className="shadow-xl p-4 bg-white dark:bg-darkgray-d border-l-0 border-lightgray hover:border-accent hover:border-l-4 duration-100 rounded-sm">
                                            <h6 className="font-bold">{gruppo.nome}</h6>
                                            <p className="mt-2 text-xs">{gruppo.descrizione}</p>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        :
                        <div>
                           {t('ModuloGestioneUtenti.SchedaUtente.nessunoGruppiUtenti')}
                        </div>
                    }
                </div>

                <div className="rounded-sm">
                    {auth.moduliUtente && auth.moduliUtente ?
                        <div>
                            <p className="font-bold mb-2 mt-4">{t('ModuloGestioneUtenti.SchedaUtente.moduliUtenti')}:</p>
                            <ul className="flex flex-col gap-2">
                                {auth.moduliUtente.map((modulo: IModulo) => {
                                    return (
                                        <ModuloTile modulo={modulo} modifica={false} />
                                    )
                                })}
                            </ul>
                        </div>
                        :
                        <div>
                            {t('ModuloGestioneUtenti.SchedaUtente.nessunoModuli')}
                        </div>
                    }
                </div>

            </div>
        </div>
    )
})

export default SchedaUtente;
