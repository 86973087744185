import React from 'react'
import BoxMaterialitaBilancio from '../UiModuloBilanciTemiMaterialita/BoxMaterialitaBilancio'
import { ITemaMaterialitaAnno } from '../../types'
import { useModuloESGStores } from '../../context/useModuloESGStores'
import { observer } from 'mobx-react-lite'

const SchedaBilancioTemi = observer(() => {

  const { bilanci } = useModuloESGStores()

  return (
    <BoxMaterialitaBilancio temiMaterialita={bilanci.bilancioAttivo?.temi_materialita as ITemaMaterialitaAnno[]} />
  )
})

export default SchedaBilancioTemi;