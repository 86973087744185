import axios from 'axios'
import { config } from '../../../config'
import { IFattoreESG } from '../types'

//Riceve lista standard
export const getStandardESG = () => {
    return axios.get(`${config.API_URI}standard_esg`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceve singolo fattoreESG
export const getFattoreESG = (codice: string, lingua?: string) => {
    let headers = {
        authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    if (lingua) {
        //@ts-ignore
        headers.lingua = lingua
    }
    return axios.get(`${config.API_URI}fattore_esg/${codice}`, { headers: headers, })
}

//Crea fattoreESG
export const creaFattoreESG = (fattoreESG: IFattoreESG) => {
    return axios.post(`${config.API_URI}fattore_esg`, {
        codice: fattoreESG.codice,
        nome: fattoreESG.nome,
        nome_esteso: fattoreESG.nome_esteso,
        descrizione: fattoreESG.descrizione,
        codice_padre: fattoreESG.codice_padre,
        richiesto: fattoreESG.richiesto,
        tipo: fattoreESG.tipo,
        tipo_indicatore: fattoreESG.tipo_indicatore,
        unita_misura: fattoreESG.unita_misura,
        risposte_multiple: fattoreESG.risposte_multiple,
        num_risposte_multiple: fattoreESG.num_risposte_multiple,
    },
        {
            headers:
                { authorization: `Bearer ${localStorage.getItem('token')}` }
        })
}

//Modifica fattoreESG
export const modificaFattoreESG = (fattoreESG: IFattoreESG) => {
    return axios.put(`${config.API_URI}fattore_esg/${fattoreESG.codice_originale ? fattoreESG.codice_originale : fattoreESG.codice}`, {
        codice: fattoreESG.codice,
        nome: fattoreESG.nome,
        nome_esteso: fattoreESG.nome_esteso,
        descrizione: fattoreESG.descrizione,
        codice_padre: fattoreESG.codice_padre,
        codice_originale: fattoreESG.codice_originale,
        richiesto: fattoreESG.richiesto,
        tipo: fattoreESG.tipo,
        tipo_indicatore: fattoreESG.tipo_indicatore,
        unita_misura: fattoreESG.unita_misura,
        risposte_multiple: fattoreESG.risposte_multiple,
        num_risposte_multiple: fattoreESG.num_risposte_multiple,
    },
        {
            headers:
                { authorization: `Bearer ${localStorage.getItem('token')}` }
        })
}

//Elimina fattoreESG
export const eliminaFattoreESG = (anno: string) => {
    return axios.delete(`${config.API_URI}fattore_esg/${anno}`, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Lista sottofattori disattivi
export const lista_sottofattori_disattivi = (bilancio_anno: number, fattore_codice: string) => {
    return axios.get(`${config.API_URI}bilancio/${bilancio_anno}/esg/${fattore_codice}/sottofattori_disattivi`, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Collega fattori ESG a obiettivi
export const collegaFattoreESGObiettivi2030 = (fattori: string[], obiettivi: number[]) => {
    return axios.post(`${config.API_URI}fattore_esg/obiettivi_agenda2030/collegamento`, {
        fattori: fattori,
        obiettivi: obiettivi
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Scollega fattori ESG da obiettivi
export const scollegaFattoreESGObiettivi2030 = (fattori: string[], obiettivi: number[]) => {
    return axios.post(`${config.API_URI}fattore_esg/obiettivi_agenda2030/scollegamento`, {
        fattori: fattori,
        obiettivi: obiettivi
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}