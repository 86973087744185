import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'


//Components
import { useTemaMaterialitaStores } from './context/useTemaMaterialitaStores'
import { Link } from 'react-router-dom'
import { FaAngleLeft } from 'react-icons/fa'
import { getBilancioTemaMaterialita } from './rest/temamaterialita'
import { IFattoreESG } from '../../types'
import FattoreBilancioTemaTile from './components/TemaMaterialita/FattoreBilancioTemaTile'


const SchedaBilancioTemaMaterialita = observer(() => {
  const { t } = useTranslation()

  const { materialita } = useTemaMaterialitaStores()

  const { tema_materialita_codice, bilancio_anno } = useParams()

  const [fattoriEsgTema, setFattoriEsgTema] = useState<IFattoreESG[]>()

  useEffect(() => {
    getBilancioTemaMaterialita(tema_materialita_codice as string, bilancio_anno as string).then((res) => {
      setFattoriEsgTema(res.data.data)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Link
        className="bg-accent text-white flex items-center justify-start gap-2 p-2 dark:bg-dark-accent"
        to={`/app/modulo/materialita/${tema_materialita_codice}`}>
        <FaAngleLeft />
        <span>{t('ModuloFattoriEsg.schedaBilancioTemaMaterialita.tornaAlBilancio')}</span>
      </Link>

      <h2 className="p-1 mt-4">
        {t('ModuloFattoriEsg.schedaBilancioTemaMaterialita.listaDeiFattori')} {bilancio_anno} {t('ModuloFattoriEsg.schedaBilancioTemaMaterialita.perTema')}
        <span className="p-1 ml-1 border-l-4 bg-white" style={{ borderColor: materialita.TemaMaterialitaAttivo?.colore }}>{materialita.TemaMaterialitaAttivo?.nome}</span>
      </h2>

      {fattoriEsgTema && fattoriEsgTema?.length > 0 ?
        <div className="flex flex-col gap-2 mt-4">
          {fattoriEsgTema?.map((fattore: IFattoreESG) => <FattoreBilancioTemaTile fattore={fattore} />)}
        </div>
        :
        <div>
         {t('ModuloFattoriEsg.schedaBilancioTemaMaterialita.nonCiSonoFattori')} {bilancio_anno}
        </div>
      }
    </div>
  )
})

export default SchedaBilancioTemaMaterialita;