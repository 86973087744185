import React, { useEffect, useState } from "react"
import { IUtente } from "../../../types"
import { useTranslation } from 'react-i18next'


const PanoramicaGestioneGruppiUtenti: React.FC<{ utente : IUtente[] }> = ({ utente }) => {
    const { t } = useTranslation();

    return (
        <ul className='text-sm lg:font-lg'>
        <li className='font-extrabold text-sm lg:text-lg mb-0.5 uppercase'>
            {t('ModuloGestioneUtenti.PannelloUtenti.utenti')}
        </li>
        <li className='font-bold text-sm lg:text-lg'>
            {
                utente.filter(utente => utente.gruppi.length != 0 && utente.gruppi.length === 1).length
            }
            <span className='mx-1 text-xs lg:text-base'>{t('ModuloGestioneUtenti.PannelloUtentiSenzaGruppo.utentiConUnGruppo')}</span>
        </li>
        <li className='font-bold text-sm lg:text-lg'>
            {
                utente.filter(utente => utente.gruppi.length != 0 && utente.gruppi.length === 2).length
            }
            <span className='mx-1 text-xs lg:text-base'>{t('ModuloGestioneUtenti.PannelloUtentiSenzaGruppo.utentiConDueGruppi')}</span>
        </li>
        <li className='font-bold text-sm lg:text-lg'>
            {
                utente.filter(utente => utente.gruppi.length != 0 && utente.gruppi.length < 3).length
            }
            <span className='mx-1 text-xs lg:text-base'>{t('ModuloGestioneUtenti.PannelloUtentiSenzaGruppo.utentiConMenoDiTreGruppi')}</span>

        </li>
        <li className='font-bold text-sm lg:text-lg'>
            {
                utente.filter(utente => utente.gruppi.length != 0 && utente.gruppi.length < 6).length
            }
            <span className='mx-1 text-xs lg:text-base'>{t('ModuloGestioneUtenti.PannelloUtentiSenzaGruppo.utentiConMenoDiSeiGruppi')}</span>
        </li>
        <li className='font-bold text-sm lg:text-lg'>
            {
                utente.filter(utente => utente.gruppi.length != 0 && utente.gruppi.length > 6).length
            }
            <span className='mx-1 text-xs lg:text-base'>{t('ModuloGestioneUtenti.PannelloUtentiSenzaGruppo.utentiConPiuDiSeiGruppi')}</span>
        </li>
    </ul>
    )
}

export default PanoramicaGestioneGruppiUtenti;
