import React, { useEffect, useState } from 'react'

//Data
import { observer } from 'mobx-react-lite'
import { useStores } from '../../hooks/useStores'
import { INotizia } from '../../types'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

//Components
import { Link } from 'react-router-dom'
import Spinner from '../../components/common/Spinner'
import AggiungiButton from '../../components/common/AggiungiButton'
import NotiziaTile from './NotiziaTile'
import MenuArchivioNotizie from './MenuArchivioNotizie'
import useOttieniPrivilegioModulo from '../../hooks/useOttieniPrivilegioModulo'

const ArchivioNotizie = observer(() => {

    const { t } = useTranslation();

    const [tipologiaLista, setTipologiaLista] = useState<'pubblicato' | 'bozza' | 'archiviata'>('pubblicato')
    const location = useLocation()
    const privilegio = useOttieniPrivilegioModulo();
    const { gestioneNotizie } = useStores()

    useEffect(() => {

        switch (location.pathname) {
            case '/app/modulo/notizie':
                gestioneNotizie.getListaPubblicate()
                setTipologiaLista('pubblicato')
                break;
            case '/app/modulo/notizie/bozze':
                gestioneNotizie.getListaBozze()
                setTipologiaLista('bozza')
                break;
            case '/app/modulo/notizie/archivate':
                gestioneNotizie.getLista_archivate()
                setTipologiaLista('archiviata')
                break;
        }

    }, [
        gestioneNotizie.pubblicata,
        gestioneNotizie.ripristinata,
        gestioneNotizie.archiviata,
        gestioneNotizie.eliminata,
        location.pathname,
    ])

    return (
        <div>
            <MenuArchivioNotizie />

            {   
                privilegio >= 2 &&
                <div className="mb-4">
                    <Link to={`/app/modulo/notizie/crea`}>
                        <AggiungiButton testo={t('ModuloNotizie.Archivio.create')} />
                    </Link>
                </div>
            }

            {
                //Controlla se sta avvenendo il caricamento
                gestioneNotizie.gettingLista &&
                <div className="flex items-center justify-center p-3 gap-2" >
                    <Spinner colore="blue" />
                    <p>{t('ModuloNotizie.Archivio.loading')}</p>
                </div>
            }

            {
                //Controlla se c'è un errore
                gestioneNotizie.listaError &&
                <p> {gestioneNotizie.listaError}</p>
            }

            {
                !gestioneNotizie.gettingLista && !gestioneNotizie.listaError &&
                <div className="flex flex-col gap-2 mb-4">
                    {
                        gestioneNotizie.lista.length > 0 ?
                            gestioneNotizie.lista.map((notizia: INotizia) => (
                                <NotiziaTile stato={tipologiaLista} notizia={notizia} />
                            )) : <h2 className="font-bold text-center text-lg">{t('ModuloNotizie.Archivio.empty')}</h2>
                    }
                </div>
            }
        </div>
    )
})

export default ArchivioNotizie;