import React, { useEffect } from 'react'

//Data
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

//Components
import { FaLock } from 'react-icons/fa'
import { useStores } from '../../hooks/useStores'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import InfoBox from '../common/InfoBox'
import ErrorBox from '../common/ErrorBox'

export const AggiornamentoContattiForm = observer(() => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const { auth } = useStores();

  //Effettua redirect su login se non sono presenti i parametri query string necessari
  useEffect(() => {
    if (!searchParams.get("email") || !searchParams.get('codice')) {
      document.location.href = '/'
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Controlla se il codice è corretto al caricamento della pagina
  useEffect(() => {
    auth.verificaEmail(searchParams.get('codice') as string)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { register, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      email: searchParams.get('email'),
    }
  });

  return (
    <>
      {
        !auth.gettingVerificaEmail &&
        <div className="mb-2">
       
        </div>
      }

      {
        !auth.gettingVerificaEmail &&
        <>
         

            <div className="form relative">
              <label htmlFor="">E-mail</label>
              <input {...register("email", { required: t('common.fornisciEmail') })} placeholder={t('PaginaContatti.testoEmail')} type="email" disabled />
              <div>
                <FaLock className="absolute right-2 top-[39px] opacity-50" />
              </div>
            </div>

            {
              !auth.gettingVerificaEmail &&
              <div className="mb-2">
                {
                  auth.emailVerificata ?
                    <InfoBox messaggio={t('FormCompleteAccount.emailVerificatoConSucesso')} />
                    :
                    <ErrorBox errore={auth.emailVerificataError} />
                }
              </div>
            }

            {
              auth.emailVerificata ? (
                <Link className="text-white bg-accent text-center w-full p-2 rounded-sm block" to="/">
                  {t('FormCompleteAccount.vailalLogin')}
                </Link>
              ) : (
                <div className="mt-3 text-center">
                  <p>{t('paginaSignup.giaAccount')}<Link to="/">{t('paginaSignup.effettuaLogin')}</Link></p>
                </div>
              )
            }

            

        </>
      }

    </>
  )
})