import axios from 'axios'
import { config } from '../../../../../config'
import { ITemaMaterialita } from '../../../types'

//Riceve lista standard
export const getListaTemaMaterialita = () => {
    return axios.get(`${config.API_URI}materialita`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceve singolo Materialita
export const getTemaMaterialita = (codice: string) => {
    return axios.get(`${config.API_URI}materialita/${codice}`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}
//Riceve info bilancio per un tema di Materialita
export const getBilancioTemaMaterialita = (codice: string, bilancio_anno: string) => {
    return axios.get(`${config.API_URI}materialita/${codice}/bilancio/${bilancio_anno}`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Crea Materialita
export const creaTemaMaterialita = (materialita: ITemaMaterialita) => {
    return axios.post(`${config.API_URI}materialita`, {
        codice: materialita.codice,
        nome: materialita.nome,
        descrizione: materialita.descrizione,
        colore:materialita.colore,
        
    },
        {
            headers:
                { authorization: `Bearer ${localStorage.getItem('token')}` }
        })
}


//Modifica Materialita
export const modificaTemaMaterialita = (materialita: ITemaMaterialita) => {
    return axios.put(`${config.API_URI}materialita/${materialita.codice_originale ? materialita.codice_originale : materialita.codice}`, {
        codice: materialita.codice,
        nome: materialita.nome,
        descrizione: materialita.descrizione,
        colore:materialita.colore,
        
    },
        {
            headers:
                { authorization: `Bearer ${localStorage.getItem('token')}` }
        })
}

//Elimina Materialita
export const eliminaTemaMaterialita = (codice: string) => {
    return axios.delete(`${config.API_URI}materialita/${codice}`, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}
