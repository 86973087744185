//Amministrazione utenti e accessi- gestione della panoramica
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
//Data
import { useStores } from '../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import PannelloPanoramicaTimeline from './PannelloPanoramica/PannelloPanoramicaTimeline'
import PannelloPanoramicaViste from './PannelloPanoramica/PannelloPanoramicaViste'
import { Link } from 'react-router-dom'

export const PannelloPanoramicaUtenti = observer(() => {
    const { gestioneUtentiAccessi, gestioneUtenti } = useStores();
    const { t } = useTranslation();
    const [clickedButton, setClickedButton] = useState("");
    const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>): any => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;
        setClickedButton(button.name);
        switch (button.name) {
            case 'richieste':
                gestioneUtentiAccessi.getPanoramicaRichieste()
                break;
            case 'utenti':
                gestioneUtentiAccessi.getPanoramicaUtenti()
                break;
            case 'gruppi':
                gestioneUtentiAccessi.getPanoramicaGruppi()
                gestioneUtentiAccessi.getPanoramicaUtenti()
                break;
            case 'attivita':
                gestioneUtentiAccessi.getPanoramicaAttivita()
                break;
        }
    };
    return (
        <div>
            <div onClick={() => setClickedButton('')} className='text-center border-b panoramicaBg py-4 mb-5 mt-4 panoramicaTesto' >
                <h1 className='text-4xl lg:text-6xl font-black p-4 uppercase'>{t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.panoramica')}</h1>
                <p className='text-l font-black p-2 mb-4'>{t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.panoramicaDidascalia')}</p>
            </div>
            <div className='text-center mt-6 mb-6'>
                <p className='font-gray panoramicaTesto dark:text-dark-text-light'>{t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.cliccaIcona')}</p>
            </div>
            <PannelloPanoramicaTimeline testo={clickedButton} buttonHandler={buttonHandler} />
            <div className='flex justify-center panoramicaTesto dark:text-dark-text-light'>
                <div className={`${clickedButton === "" ? "block" : "hidden"} panoramicaVista dark:bg-dlightgray p-4 w-2/4 text-center mt-6 mb-8`}>
                    <p className="font-black text-xl p-2 mt-4  mb-5">
                        {t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.descrizionePanoramica')}
                    </p>
                    <p className='font-extrabold text-2xl p-2 mb-5'>
                        {t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.cliccaIcona')}
                    </p>
                </div>

                <div className={`${clickedButton === 'richieste' ? 'block' : 'hidden'} py-4 w-2/3 panoramicaVista dark:bg-dlightgray`}>
                    {gestioneUtentiAccessi.panoramicaRichieste.length === 0 ?
                        <div className='relative h-60'>
                            <div className='Panoramicaspinner'></div>
                        </div>
                        :
                        gestioneUtentiAccessi.panoramicaRichieste.map((richiesta) => (

                            <div className='relative'>
                                <p className="absolute text-xs xl:text-base top-[12%] top-[20%] md:top-[20%] lg:top-[25%] xl:top-[28%] right-[25%] left-[25%] text-center">{t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.vistaRichieste')}</p>

                                <div className='text-center mb-5'>
                                    <h2 className='font-bold text-3xl lg:text-5xl '>{richiesta.n_richieste_in_sospeso}</h2>
                                    <h3 className='font-bold text-lg lg:text-2xl uppercase'>{t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.inSospeso')}</h3>
                                </div>
                                <div className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3  gap-4 text-center m-6'>
                                    <div>
                                        <h4 className='font-black text-2xl lg:text-4xl mb-1'>{richiesta.n_richieste_accettate}</h4>
                                        <p className='font-black uppercase'>{richiesta.n_richieste_accettate === "1" ? `${t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.accettata')}` : `${t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.accettate')}`}</p>
                                    </div>
                                    <div>
                                        <h4 className='font-black text-2xl lg:text-4xl mb-1'>{richiesta.n_richieste_rifiutate}</h4>
                                        <p className='font-black uppercase'>{richiesta.n_richieste_rifiutate === "1" ? `${t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.rifiutata')}` : `${t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.rifiutate')}`}</p>
                                    </div>
                                    <div>
                                        <h4 className='font-black text-2xl lg:text-4xl mb-1'>{richiesta.totale_richieste}</h4>
                                        <p className='font-black uppercase'>{richiesta.totale_richieste === "1" ? `${t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.totale')}` : `${t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.totali')}`}</p>
                                    </div>
                                </div><div className='flex justify-center'>
                                    <button className='rounded w-40	h-6'>
                                        <Link to="/app/modulo/controllo_accessi" className='text-white flex flex-col justify-center h-full'>
                                            <p className='text-white uppercase'>{t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.gestisciRichieste')}</p>
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className={`${clickedButton === 'utenti' ? 'block' : 'hidden'} py-4 w-2/3 panoramicaVista dark:bg-dlightgray`}>
                    {gestioneUtentiAccessi.panoramicaUtenti.length === 0 &&
                        <div className='relative h-60'>
                            <div className='Panoramicaspinner'></div>
                        </div>
                    }
                    {gestioneUtentiAccessi.panoramicaUtenti.map((utente) => (
                        <div className='relative'>
                            <p className="absolute text-xs xl:text-base top-[12%] sm:top-[12%] md:top-[12%] lg:top-[15%] xl:top-[25%] right-[25%] left-[25%] text-center">{t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.vistaUtenti')}</p>
                            <div className='text-center mb-5'>
                                <h2 className='font-black text-3xl lg:text-5xl'>{utente.totale_utenti}</h2>
                                <h3 className='font-black text-lg lg:text-2xl uppercase'>{utente.totale_utenti === "1" ? `${t('ModuloGestioneUtenti.PannelloUtenti.utente')}` : `${t('ModuloGestioneUtenti.PannelloUtenti.utenti')}`}</h3>
                            </div>
                            <div className='grid grid-cols-2 md:grid-cols-2 xl:grid-cols-5  gap-4 text-center m-6'>
                                <div>
                                    <h4 className='font-black text-2xl lg:text-4xl mb-1'>{utente.n_utenti_attivi}</h4>
                                    <p className='font-black uppercase'>{utente.n_utenti_attivi === "1" ? `${t('ModuloGestioneUtenti.PannelloUtenti.attivo')}` : `${t('ModuloGestioneUtenti.PannelloUtenti.attivi')}`}</p>
                                </div>
                                <div>
                                    <h4 className='font-black text-2xl lg:text-4xl mb-1'>{utente.n_utenti_disabilitato}</h4>
                                    <p className='font-black uppercase'>{utente.n_utenti_disabilitato === "1" ? `${t('ModuloGestioneUtenti.PannelloUtenti.disattivo')}` : `${t('ModuloGestioneUtenti.PannelloUtenti.disattivi')}`}</p>
                                </div>
                                <div>
                                    <h4 className='font-black text-2xl lg:text-4xl mb-1'>{utente.n_utenti_da_verificare}</h4>
                                    <p className='font-black uppercase'>{t('ModuloGestioneUtenti.PannelloUtenti.daVerificare')}</p>
                                </div>
                                <div>
                                    <h4 className='font-black text-2xl lg:text-4xl mb-1'>{utente.n_utenti_attivi_senza_gruppo}</h4>
                                    <p className='font-black uppercase'>{utente.n_utenti_attivi_senza_gruppo === "1" ? `${t('ModuloGestioneUtenti.PannelloUtenti.attivo')}` : `${t('ModuloGestioneUtenti.PannelloUtenti.attivi')}`}<br />{t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.senzaGruppo')}</p>
                                </div>
                                <div>
                                    <h4 className='font-black text-2xl lg:text-4xl mb-1'>{utente.n_utenti_inattivi_senza_gruppo}</h4>
                                    <p className='font-black uppercase'>{utente.n_utenti_inattivi_senza_gruppo === "1" ? `${t('ModuloGestioneUtenti.PannelloUtenti.inattivo')}` : `${t('ModuloGestioneUtenti.PannelloUtenti.inattivi')}`}<br />{t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.senzaGruppo')}</p>
                                </div>
                            </div>
                            <div className='flex justify-center'>
                                <button className='rounded w-40	h-6 text-white'>
                                    <Link to="/app/modulo/gestione_utenti" className='text-white  flex flex-col justify-center h-full'>
                                        <p className='text-white uppercase'>{t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.gestisciUtenti')}</p>
                                    </Link>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={`${clickedButton === 'gruppi' ? 'block' : 'hidden'} py-4 w-2/3 panoramicaVista dark:bg-dlightgray`}>
                    {gestioneUtentiAccessi.panoramicaGruppi.length === 0 &&
                        <div className='relative h-60'>
                            <div className='Panoramicaspinner'></div>
                        </div>
                    }
                    {gestioneUtentiAccessi.panoramicaGruppi.map((gruppo) => (
                        <div className='relative'>
                            <p className="absolute text-xs xl:text-base top-[22%] md:top-[20%] lg:top-[25%] xl:top-[28%] right-[25%] left-[25%] text-center">{t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.vistaGruppi')}</p>
                            <div className='text-center mb-5'>
                                <h2 className='font-black text-3xl lg:text-5xl'>{gruppo.n_gruppi}</h2>
                                <h3 className='font-black text-lg lg:text-2xl'>{gruppo.n_gruppi === "1" ? `${t('ModuloGestioneUtenti.PannelloUtenti.gruppo')}` : `${t('ModuloGestioneUtenti.PannelloUtenti.gruppi')}`}</h3>
                            </div>
                            <div className='grid grid-cols-2 text-center mb-6'>
                                <div>
                                    <h4 className='font-black text-2xl lg:text-4xl mb-1'>{gruppo.gruppi_senza_utenti}</h4>
                                    <p className='font-black uppercase'>{gruppo.gruppi_senza_utenti === "1" ? `${t('ModuloGestioneUtenti.PannelloUtenti.gruppo')}` : `${t('ModuloGestioneUtenti.PannelloUtenti.gruppi')}`}<br />{t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.senzaUtenti')}</p>
                                </div>
                                {gestioneUtentiAccessi.panoramicaUtenti.map((utente) => (
                                    <div>
                                        <h4 className='font-black text-2xl lg:text-4xl mb-1'>{utente.n_utenti_attivi_senza_gruppo}</h4>
                                        <p className='font-black uppercase'>{utente.n_utenti_attivi_senza_gruppo === "1" ? `${t('ModuloGestioneUtenti.PannelloUtenti.utente')}` : `${t('ModuloGestioneUtenti.PannelloUtenti.utenti')}`}<br />{t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.senzaGruppo')}</p>
                                    </div>
                                ))}
                            </div>
                            <div className='flex justify-center'>
                                <button className='rounded w-40	 h-6'>
                                    <Link to="/app/modulo/gestione_gruppi" className='text-white flex flex-col justify-center h-full'>
                                        <p className='text-white uppercase'>{t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.gestisciGruppi')}</p>
                                    </Link>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={`${clickedButton === 'attivita' ? 'block' : 'hidden'} py-4 w-2/3 panoramicaVista dark:bg-dlightgray`}>
                    {gestioneUtentiAccessi.panoramicaAttivita.length === 0 &&
                        <div className='relative h-60'>
                            <div className='Panoramicaspinner'></div>
                        </div>
                    }
                    {gestioneUtentiAccessi.panoramicaAttivita.map((attivita) => (
                        <div className='relative'>
                            <p className="absolute text-xs xl:text-base top-[22%] md:top-[20%] lg:top-[25%] xl:top-[25%] right-[25%] left-[25%] text-center">{t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.vistaAttivita')}</p>
                            <div className='text-center mb-6'>
                                <h2 className='font-black text-3xl lg:text-5xl'>{attivita.n_attivita}</h2>
                                <h3 className='font-black text-lg lg:text-2xl uppercase'>{t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.attivitaGiorni')}</h3>
                            </div>
                            <div className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 text-center mb-6'>
                                <div>
                                    <h4 className='font-black text-2xl lg:text-4xl mb-1'>{attivita.n_utenti}</h4>
                                    <p className='font-black uppercase'>{attivita.n_utenti === "1" ? `${t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.utenteHa')}` : `${t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.utentiHanno')}`}<br />{t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.fattoAzioni')}</p>
                                </div>
                                <div>
                                    <h4 className='font-black text-2xl lg:text-4xl mb-1'>{attivita.n_moduli}</h4>
                                    {attivita.n_moduli === "1" ? <p className='font-black uppercase'> {t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.modulo')}<br /> {t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.utilizzati')}</p> : <p className='font-black uppercase'> {t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.moduli')}<br /> {t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.utilizzati')}</p>}
                                </div>
                                <div>
                                    <h4 className='font-black text-2xl lg:text-4xl mb-1'>{attivita.n_azione}</h4>
                                    {attivita.n_azione === "1" ? <p className='font-black uppercase'>{t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.azioneFatta')}</p> : <p className='font-black uppercase'>{t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.azioniFatte')}</p>}
                                </div>
                            </div><div className='flex justify-center'>
                                <button className='rounded w-40	 h-6'>
                                    <Link to="/app/modulo/monitoraggio_attivita" className='text-white flex flex-col justify-center h-full'>
                                        <p className='text-white uppercase'>{t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.gestisciAttivita')}</p>
                                    </Link>
                                </button>
                            </div></div>
                    ))}
                </div>
            </div>
        </div>
    )
}
)
export default PannelloPanoramicaUtenti