import React from 'react'

//Data
import { FaInfoCircle } from 'react-icons/fa'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../../hooks/useStores'

//Components
import BusinessUnitTileBilancio from './BusinessUnitTileBilancio'
import { IBusinessUnitNelBilancio } from '../../types'


const CanvasBusinessUnitsBilancio: React.FC<{ businessUnits: IBusinessUnitNelBilancio[] }> = observer(({ businessUnits }) => {

    const { ui } = useStores()

    const styles = {
        canvas: `min-w-full max-w-[calc(100vw-20px)] md:max-w-[calc(100vw-300px)] canvas-body max-h-[1800px]
        flex overflow-x-auto items-center justify-start bg-lightgray-d dark:bg-darkgray-d border
        border-lightgray-dd dark:border-darkgray-dd p-4 ${ui.asideModal && 'md:max-w-[calc(100vw-700px)]'}`,
    }

    return (
        <div>
            <div className={styles.canvas}>
                <div className="flex items-start justify-center grow gap-8">
                    {
                        businessUnits && businessUnits.length > 0 ?
                            businessUnits.map((businessUnit: IBusinessUnitNelBilancio) => (
                                <BusinessUnitTileBilancio key={businessUnit.nome} businessUnit={businessUnit} />
                            ))
                            :
                            <div className="text-center opacity-70 text-xl flex items-center justify-center gap-2">
                                <FaInfoCircle />
                                <span>Nessuna business unit trovata</span>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
})

export default CanvasBusinessUnitsBilancio;