import axios from 'axios'
import { config } from '../config'

//Riceva lista di tutte le attività per un utente
export const getAttivitaUtente = (offset: number, limit: number, filtri?: string) => {

    let url = `${config.API_URI}attivita/utente?offset=${offset}&limit=${limit}`

    if (filtri) {
        url += `${filtri && filtri.length > 0 && filtri}`
    }

    return axios.get(url, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceva lista di tutti i moduli su cui un utente ha eseguito attività
export const getModuliAttivitaUtente = () => {
    return axios.get(`${config.API_URI}attivita/utente/moduli`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}
//Riceva lista di tutti i dati filtrabile per gli utente hanno eseguito attività

export const getModuliAttivitaGenerale = () => {
    return axios.get(`${config.API_URI}attivita/utente/moduli/listi`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}
export const getAttivitaUtenteFiltrabile = (offset: number, limit: number, filtri?: string) => {

    let url = `${config.API_URI}attivita/utente/moduli/filtrabile?offset=${offset}&limit=${limit}`

    if (filtri) {
        url += `${filtri && filtri.length > 0 && filtri}`
    }

    return axios.get(url, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}