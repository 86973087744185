import React from "react";
import * as Icons from "react-icons/fa";

/* Your icon name from database data can now be passed as prop */
const IconaDinamica = (props: { nome: string, dimensione?: number }) => {
    //@ts-ignore
    const IconComponent = Icons[props.nome];

    if (!IconComponent) { // Return a default one
        return <Icons.FaCircle size={props.dimensione} className="text-accent dark:text-dark-accent" />;
    }

    return <IconComponent size={props.dimensione} className="text-accent dark:text-dark-accent" />;
};

export default IconaDinamica;