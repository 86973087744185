export const it_modulo_gestione_utenti = {
    SchedaUtente: {
        titoloAdmin: 'Utente amministratore',
        schedaUtente: 'Scheda utente',
        accountCreato: 'Account creato il',
        gruppiUtenti: 'Gruppi a cui appartieni',
        nessunoGruppiUtenti: ' Non appartieni a nessun gruppo',
        moduliUtenti: 'Moduli a cui hai accesso',
        nessunoModuli: 'Non appartieni a nessun gruppo',
    },

    FormAggiungiUtentiGruppo: {
        aggiungiUtentiGruppo: 'Aggiungi utenti al gruppo',
        testoAggiungiUtenti: 'Un gruppo può contenere più utenti. Ad ogni gruppo possono essere associati i permessi per agire sui diversi moduli dell\'applicazione.',
        utenti: 'Utenti',
        selezioneUtenti: 'Seleziona utenti',
        tuttiSelezionati: 'Tutti gli utenti selezionati',
        selezioneTutti: 'Seleziona tutti',
    },

    FormCreaGruppo: {
        creaNuovoGruppo: 'Crea un nuovo gruppo',
        messaggioNomeLimitizione: 'Non può contenere spazi, lettere maiuscole o caratteri speciali',
        gruppoCreatoSuccesso: 'Gruppo creato con successo',
        creaGruppo: 'Crea gruppo',
    },

    GruppoContextMenu: {
        gruppoEliminatoSucesso: 'Gruppo eliminato con successo',
        modificaGruppo: 'Modifica gruppo',
        eliminaGruppo: 'Elimina gruppo',
        mesaggioElimina: 'Sei sicuro di voler eliminare il gruppo',
        messaggioCompleto: 'Gli utenti associati non verranno eliminati, ma perderanno i privilegi associati al gruppo.',
        testoEliminaButtone: 'Elimina il gruppo',
    },

    GruppoTile: {
        utente: 'utenti',
        collegaUtente: 'Collega un nuovo utente',
    },

    ModificaGruppo: {
        testoModifica: 'Modifica il gruppo',
        placeHolder: 'es: gruppo_1',
    },

    FormCreaUtenti: {
        creaUnUtente: 'Crea un utente ',
        selezioneGruppi: 'Seleziona gruppi',
        tuttiGruppiSelezionati: 'Tutti i gruppi selezionati',
        utenteCreatoSucesso: 'Utente creato con successo',
        creaUtente: 'Crea utente',
    },

    FormModificaUtente: {
        modificaUtente: 'Modifica l\'utente',
        modificaUtenteText: 'Modificando l\'utente',
        testoModifica: 'potrai cambiare i suoi dati personali, i gruppi a cui appartiene e conseguentemente i privilegi che otterrà all\'interno della piattaforma.',
        disabilitaTemporaneamente: 'Disabilita temporaneamente',
    },

    FormModificaContatti: {
        modificaEmail: 'Modifica la e-mail dell\'utente',
        modificaEmailText: 'Modificando la e-mail dell\'utente ',
        testoModificaEmail: '',
        emailNonECorretta:'Le e-mail non coincidono',
    },

    PannelloUtentiSenzaGruppo: {
        gruppiSenzaUtenti: 'Gruppi senza utenti',
        gruppiConUtenti: 'Gruppi con utenti',
        nessunUtenteSenzaGruppo: 'Nessun utente senza gruppo',
        nessunGruppoSenzaUtenti: 'Nessun gruppo senza utenti',
        nessunGruppoConUtenti: 'Nessun gruppo con utenti',
        gestioneGruppi: 'Gestione gruppi',
        utentiConUnGruppo: 'Con un gruppo',
        utentiConDueGruppi: 'Con due gruppi',
        utentiConMenoDiTreGruppi: 'Con meno di tre gruppi',
        utentiConMenoDiSeiGruppi: 'Con meno di sei gruppi',
        utentiConPiuDiSeiGruppi: 'Con più di sei gruppi',
        gruppiConUnSoloUtente: 'Con un solo utente',
        gruppiConMenoDiCinqueUtenti: 'Con meno di cinque utenti',
        gruppiConMenoDiDieciUtenti: 'Con meno di dieci utenti',
        gruppiConOltreDieciUtenti: 'Con oltre dieci utenti',
        nonVerificati: 'Non verificati',
        verificati: 'Verificati',
        utentiSenzaGruppo: 'Utenti senza gruppo',
        descrPannello: 'Gestisci i gruppi e gli utenti che ve ne fanno parte.'
    },

    PannelloPanoramicaUtenti: {
        panoramica: 'Panoramica',
        panoramicaDidascalia: 'Questa panoramica ti offre un modo intuitivo per tenere traccia degli utenti, dei gruppi, delle richieste e delle attività. \nCon un semplice clic su ciascuna voce, puoi approfondire l\'argomento e scoprirne i dettagli',
        pannelloPanoramica: "Pannello panoramica",
        cliccaIcona: 'Clicca su un\'icona per vedere i dettagli della sezione',
        descrizionePanoramica: 'La panoramica offre una visione d\'insieme delle informazioni di base su richieste, utenti, gruppi e attività, fornendo un modo rapido ed efficace per comprendere lo stato e le tendenze in un sistema o contesto specifico.',
        richieste: 'Richieste',
        richiesta: 'Richiesta',
        attivita: 'Attività',
        inSospeso: 'In sospeso',
        accettata: 'Accettata',
        accettate: 'Accettate',
        rifiutata: 'Rifiutata',
        rifiutate: 'Rifiutate',
        totale: 'Totale',
        totali: 'Totali',
        inAttesa: 'In attesa',
        diVerifica: 'Di verifica',
        senzaGruppo: 'Senza gruppo',
        senzaUtenti: 'Senza utenti',
        attivitaGiorni: 'Attività negli ultimi 30 giorni',
        utenteHa: 'Utente ha',
        utentiHanno: 'Utenti hanno',
        fattoAzioni: 'Fatto azioni',
        moduli: 'Moduli',
        modulo: 'Modulo',
        utilizzati: 'Utilizzati',
        utilizzato: 'Utilizzato',
        azioniFatte: 'Azioni Fatte',
        azioneFatta: 'Azione Fatta',
        gestisciRichieste: 'Gestisci richieste',
        gestisciUtenti: 'Gestisci utenti',
        gestisciGruppi: 'Gestisci gruppi',
        gestisciAttivita: 'Gestisci attività',
        vistaRichieste: 'Clicca su Gestisci Richieste per gestire le richieste in sospeso',
        vistaUtenti: 'Clicca su Gestisci Utenti per approfondire la panoramica degli utenti',
        vistaGruppi : 'Clicca su Gestisci Gruppi per approfondire la panoramica dei gruppi',
        vistaAttivita: 'Clicca su Gestisci Attivita per approfondire la panoramica delle attività'

    },

    PannelloRichieste: {
        richiesteDaGestire: 'Richieste da gestire',
        richiestaDaGestire: 'Richiesta da gestire',
        richiesteAccettate: 'Richieste Accettate',
        richiestaAccettata: 'Richiesta Accettata',
        richiesteRifiutate:'Richieste rifiutate',
        richiestaRifiutata: 'Richiesta rifiutata',
        richiesteRicevute: 'Richieste ricevute',
        nessunoRichiesta: 'Nessuna richiesta da gestire',
        nessunaRichiestaAccettata:'Nessuna richiesta accettata',
        nessunaRichiestaRifiutata:'Nessuna richiesta rifiutata',
        richiesteGestite: 'Richieste gestite',
        gestioneRichieste: 'Gestione richieste',
        descrPannello: 'In questa sezione trovi uno storico delle tue richieste fino a 90 giorni.Puoi gestire tutte le tue richieste, monitorare quelle accettate e quelle rifiutate.',

    },

    PannelloUtenti: {
        utente: 'Utente',
        utenti: 'Utenti',
        gruppo: 'Gruppo',
        gruppi: 'Gruppi',
        accessi: 'Accessi',
        trovati: 'Trovati',
        trovate: 'Trovate',
        trovata: 'Trovata',
        permessi: 'Permessi',
        iPiuAttivi: 'I più attivi',
        utentiE : 'Utenti e',
        attivi: 'Attivi',
        attivo: 'Attivo',
        disattivi: 'Disattivi',
        disattivo: 'Disattivo',
        inattivi: 'Inattivi',
        inattivo: 'Inattivo',
        disabilitati: 'Disabilitati',
        disabilitato: 'Disabilitato',
        daAccettare: 'Da accettare',
        daVerificare: 'Da verificare',
        gestioneUtenti: ' Gestione utenti',
        utentiSenzaGruppi: 'Utenti senza gruppo',
        utenteSenzaGruppi: 'Utente senza gruppo',
        utentiInPiuGruppi: 'Utenti in più gruppi',
        utenteInPiuGruppi: 'Utente in più gruppi',
        utentiTrovati: 'utenti trovati',
        nessunoGruppoTrovato: 'Nessun gruppo trovato',
        aggiungiNuovoUtente: 'AGGIUNGI UN NUOVO UTENTE',
        nessunDisabilitato : 'Nessun utente disabilitato trovato',
        nessunUtente : 'Nessun utente trovato',
        nessunSenzaGruppo : 'Nessun utente senza gruppo trovato',
        nessunDaVerificare: 'Nessun utente da verificare',
        descrPannello : 'Monitora e gestisci gli utenti. Assegna loro un gruppo o chiedi di verificarne l\'account.'
    },

    TileRichiestaUtente: {
        richiestaRicevuta: 'Richiesta ricevuta il',
        acettataRichiesta: 'Accetta richiesta',
        riffutataRichiesta: 'Rifiuta richiesta',
    },

    FormRifiutaUtente: {
        rifiutaRichesta: 'Rifiuta richiesta per l\'utente',
        rifiutaQuestaRichesta: 'Rifiuta questa richiesta.',
        ragioneDelRifiuta: 'Ragione del rifiuto',
        messaggioRagione: 'Specifica la ragione del rifiuto',
        statoRifuitato: 'L\'utente è stato rifiutato',
        confirmaRifuitato: 'Conferma rifiuto',
    },

    UtenteContextMenu: {
        utenteEliminatoSucesso: 'Utente eliminato con successo',
        modificaUtente: 'Modifica utente',
        eliminaMessagio: 'Sei sicuro di voler eliminare l\'utente',
        eliminaTestoMessagio: 'Perderà il suo account in modo permanente e non potrà più effettuare il login. Tutti i dati da lui inseriti nella piattaforma non verranno comunque eliminati.',
        eliminaUtente: 'Elimina l\'utente',
        eliminaUtenteButtone: 'Elimina utente',
    },

    TileUtente: {
        utenteDisabilitato: 'Utente temporaneamente disabilitato',
        registroIl: 'Registrato il',
        emailVerificata: 'E-mail verificata',
        emailNonVerificat: 'E-mail non verificata',
        utenteAttivo: 'Utente attivo',
        utenteNonAttivo: 'Utente non attivato',
        utenteGoogle:'Utente Google WorkSpace',
        utenteAzureAdd:'Utente Azure AD',
        utenteIpe:'Utente Ipè',
        reinviaEmail:'Richiedi completamento account',
        LinguaUtilizzata:'Lingua utilizzata:',
        nessunoGruppo:'Nessun gruppo collegato'
    },

}