import { useEffect } from 'react'
import { useStores } from './useStores';

const useSettaDebug = () => {
  const { ui } = useStores();

  useEffect(() => {
    if (localStorage.getItem('debug')) {
      ui.toggleDebug();
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useSettaDebug;