import React from 'react'
import { FaAngleLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function Contatti() {
  const { t } = useTranslation();
  return (
    <div>
      <p>
      {t('PaginaContatti.vegaParco')}<br />
      {t('PaginaContatti.torreHammon')}<br />
      {t('PaginaContatti.indirizzo')}
      </p>
      <hr className="border-lightgray-dd my-2" />
      <p>
        <b>{t('PaginaContatti.testoTel')}</b> {t('PaginaContatti.numero')}
      </p>
      <p>
        <b>{t('PaginaContatti.testoEmail')}</b> {t('PaginaContatti.email')}
      </p>

      <Link to="/" className="block mt-4 flex items-center gap-1">
        <FaAngleLeft />
        <span>{t('common.tornaLogin')}</span>
      </Link>
    </div>
  )
}
