import { observable, makeAutoObservable } from 'mobx'
import { IBusinessUnit } from '../../UiModuloBusinessUnit/types'

export class CanvasBilanciBusinessUnitsStore {

    businessUnits: IBusinessUnit[] = []
    businessUnitsAttive: Set<string> = new Set()
    businessUnitsPadriAttive: Set<string> = new Set()

    constructor() {
        makeAutoObservable(this, {
            settaBusinessUnits: observable,
            
            businessUnitsAttive: observable,
            businessUnitsPadriAttive: observable,
        })
    }

    //Setta business units
    settaBusinessUnits = (businessUnits: IBusinessUnit[]) => {

        let businessUnitsFlat : IBusinessUnit[] = []

        const flattenBusinessUnits = (businessUnits: IBusinessUnit[]) => {
            businessUnits.forEach(businessUnit => {
                businessUnitsFlat.push(businessUnit);

                if (businessUnit.sotto_business_unit && businessUnit.sotto_business_unit.length > 0) {
                    businessUnit.sotto_business_unit.forEach(sottoBusinessUnit => {
                        flattenBusinessUnits([sottoBusinessUnit])
                    })
                }
            })
        }

        flattenBusinessUnits(businessUnits)

        this.businessUnits = businessUnitsFlat;
    }

    //Aggiungi una business unit allo store
    aggiungiBusinessUnitAttive = (nomeBusinessUnit: string) => {
        if (!this.businessUnitsPadriAttive.has(nomeBusinessUnit)){
            this.businessUnitsAttive.add(nomeBusinessUnit);
        }
        
        this.creaAlberaturaBusinessUnitsPadre();
    }

    
    //Rimuovi una business unit dallo store
    rimuoviBusinessUnitAttive = (nomeBusinessUnit: string) => {
        this.businessUnitsAttive.delete(nomeBusinessUnit);
        
        this.creaAlberaturaBusinessUnitsPadre();
    }

    //Crea alberatura bu padre
    creaAlberaturaBusinessUnitsPadre = () => {

        this.businessUnitsPadriAttive = new Set();
        
        this.businessUnitsAttive.forEach(businessUnitAttiva => {
            let businessUnit = this.businessUnits.filter(bu => bu.nome === businessUnitAttiva)[0];
    
            if (businessUnit && businessUnit.nome_padre) {
                let padreBusinessUnit = this.businessUnits.filter(bu => bu.nome === businessUnit.nome_padre)[0];
                this.aggiungiPadriRicorsivamente(padreBusinessUnit)
            }
        })

        this.businessUnitsAttive.forEach(buAttiva => {
            if (this.businessUnitsPadriAttive.has(buAttiva)) {
                this.businessUnitsAttive.delete(buAttiva)
            }
        })
    }
    
    //Aggiungi bu padre ricorsivamente
    aggiungiPadriRicorsivamente(businessUnit: IBusinessUnit) {

        this.businessUnitsPadriAttive.add(businessUnit.nome)
    
        let padreBusinessUnit = this.businessUnits.filter(bu => bu.nome === businessUnit.nome_padre)[0];
    
        if (padreBusinessUnit) {
            this.aggiungiPadriRicorsivamente(padreBusinessUnit)
        }
    }
}