import React, { useEffect } from 'react'

//Data
import { observer } from 'mobx-react-lite'
import { Link, useParams } from 'react-router-dom'
import { useStores } from '../../../hooks/useStores'
import { IFattoreESG } from '../types'
import { useModuloESGStores } from '../context/useModuloESGStores'

//Components
import ObiettivoAgenda2030Tile from '../pagineFattoriESG/UiModuloAgenda2030/components/ObiettivoAgenda2030Tile'
import BackButton from '../../../components/common/BackButton'
import FattoreESGTile from '../components/FattoreESGTile'
import { FaPaperclip } from 'react-icons/fa'

const DettagliObiettivoAgenda2030NelBilancio = observer(() => {

  const { bilancio_anno, obiettivo_id } = useParams()
  const { moduli } = useStores()
  const { bilanci } = useModuloESGStores()

  useEffect(() => {
    bilanci.getObiettivoAgenda2030Bilancio(parseInt(bilancio_anno as string), parseInt(obiettivo_id as string))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>

      <div className="flex flex-col md:flex-row items-stretch justify-between gap-2 my-2">
        <BackButton
          testo="Torna all'archivio degli obiettivi"
          destinazione={`/app/modulo/bilanci/${bilanci.bilancioAttivo?.anno}/obiettivi_agenda2030`}
        />
        <Link
          to={`/app/modulo/agenda_2030/${bilanci.obiettivoAgenda2030BilancioAttivo?.id}`}
          className="p-2 flex items-center gap-2 font-bold font-bold hover:underline"
        >
          <FaPaperclip />
          <span>Vai alla scheda generica dell'obiettivo</span>
        </Link>
      </div>
      {bilanci.obiettivoAgenda2030BilancioAttivo &&
        <div>
          <ObiettivoAgenda2030Tile obiettivo={bilanci.obiettivoAgenda2030BilancioAttivo} modifica={false} />

          <div className="flex flex-col gap-2 mt-4">

            <h2 className="text-xl my-4">
              {bilanci.obiettivoAgenda2030BilancioAttivo?.fattori.length} fattori ESG disponibili per questo obiettivo dell'agenda 2030 nel bilancio del {bilancio_anno}
            </h2>

            {
              bilanci.obiettivoAgenda2030BilancioAttivo?.fattori.map((fattore: IFattoreESG) => (
                <FattoreESGTile link={`/app/modulo/${moduli.moduloAttivo?.codice}/${bilanci.bilancioAttivo?.anno}/fattori_esg/${fattore.codice}`} fattore={fattore} />
              ))
            }
          </div>

        </div>
      }
    </div>
  )
})

export default DettagliObiettivoAgenda2030NelBilancio;