import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { IFattoreESG } from '../../../types';
import { observer } from 'mobx-react-lite';

const HoverFattoriBilancio: React.FC<{ fattore: IFattoreESG }> = observer(({ fattore }) => {
  const { t } = useTranslation();
  return (
    <div className="max-w-[150px]">
      <p className="text-accent dark:text-dark-accent text-xs font-bold">{fattore.nome}</p>
      <p className="dark:text-white">{fattore.nome_esteso}</p>

      <hr className="my-2 border border-lightgray-d" />

      <p className="text-xs text-darkgray dark:text-white">{fattore.descrizione ? fattore.descrizione : t('ModuloFattoriEsg.HoverFattoriBilancio.nessunDescrizione')}</p>
    </div>
  )
})

export default HoverFattoriBilancio;
