import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../hooks/useStores'
import { observer } from 'mobx-react-lite'
//Components
import { Link } from 'react-router-dom'
import { RiGroup2Line } from 'react-icons/ri'
import { MdLanguage } from 'react-icons/md'
import { BiLogInCircle, BiUserCircle } from 'react-icons/bi'
import PanoramicaCentrale from '../amministrazioneUtentiAccessi/Panoramiche/PanoramicaCentrale'
import PanoramicaGestioneUtentiAccessiUtentieGruppi from '../amministrazioneUtentiAccessi/Panoramiche/PanoramicaGestioneUtentiAccessiUtentieGruppi'
import PanoramicaGestioneUtentiLingueUtenti from '../amministrazioneUtentiAccessi/Panoramiche/PanoramicaGestioneUtentiLingueUtenti'
import PannelloUtentiTotali from './Pannelli/PannelliUtenti/PannelloUtentiTotali'
import PannelloUtentiDisabilitati from './Pannelli/PannelliUtenti/PannelloUtentiDisabilitati'
import PannelloUtentiSenzaGruppo from './Pannelli/PannelliUtenti/PannelloUtentiSenzaGruppo'
import PannelloUtentiDaVerificare from './Pannelli/PannelliUtenti/PannelloUtentiDaVerificare'


export const PannelloUtenti = observer(() => {
    const { gestioneUtenti } = useStores();
    const { t } = useTranslation();

    return (
        <div>
            <div className='mb-4 text-center lg:text-start'>
                <button className='rounded w-72	h-6'>
                    <Link to="../amministrazione_utenti_e_accessi" className="text-white dark:text-white flex flex-col justify-center h-full uppercase">
                        {t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.pannelloPanoramica')}
                    </Link>
                </button>
            </div>
            
            <div className='text-center mb-6 p-6 relative'>
                <h1 className='text-accent dark:text-dark-accent font-extrabold text-xl lg:text-4xl uppercase'>{t('ModuloGestioneUtenti.PannelloUtenti.gestioneUtenti')}</h1>
                <p className="absolute text-xs lg:text-base top-[80%] right-[25%] left-[25%] text-center">{t('ModuloGestioneUtenti.PannelloUtenti.descrPannello')}</p>
            </div>

            <PanoramicaCentrale 
                stili={false}
                componenteUno={<PanoramicaGestioneUtentiAccessiUtentieGruppi utente={gestioneUtenti.utenti} />}
                componenteDue={<PanoramicaGestioneUtentiLingueUtenti utente={gestioneUtenti.utenti} />}
                componenteTre={null} 
                Icona1={<BiUserCircle className='h-full w-full text-black dark:text-dark-accent' />} 
                Icona2={<BiLogInCircle className='h-full w-full text-black dark:text-dark-accent' />} 
                Icona3={<MdLanguage className='h-full w-full text-black dark:text-dark-accent' />}
                icona4={<RiGroup2Line className='h-full w-full text-black dark:text-dark-accent' />}/>

                <PannelloUtentiDaVerificare/>
                <PannelloUtentiDisabilitati />
                <PannelloUtentiSenzaGruppo />
                <PannelloUtentiTotali /> 
                
        </div>
    )
})

export default PannelloUtenti