import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useModuloESGStores } from '../context/useModuloESGStores';
import { observer } from 'mobx-react-lite';
import { Link, Navigate, useParams } from 'react-router-dom';

//Components
import TextLoader from '../../../components/common/TextLoader'
import SchedaCategoria from './TipiSchedeFattoreESGBilancio/SchedaCategoria'
import SchedaTabella from './TipiSchedeFattoreESGBilancio/SchedaTabella'
import InformativaFattoreESG from '../components/InformativaFattoreESG';
import TemaMaterialitaBilancioTile from './UiModuloBilanciTemiMaterialita/TemaMaterialitaBilancioTile';
import BackButton from '../../../components/common/BackButton';
import { FaPaperclip } from 'react-icons/fa';
import { useStores } from '../../../hooks/useStores';

const DettagliFattoreESGNelBilancio = observer(() => {

  const { bilanci } = useModuloESGStores()
  const { t } = useTranslation();

  const { moduli } = useStores()
  
  const { bilancio_anno, fattore_codice } = useParams()

  useEffect(() => {
    bilancio_anno &&
      bilanci.getFattoreBilancio(parseInt(bilancio_anno), fattore_codice as string).catch(() => {
        return <Navigate to="app/modulo/bilanci"></Navigate>
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fattore_codice, bilancio_anno])


  return (
    <div>

      <div className="flex flex-col md:flex-row items-stretch justify-between gap-2 my-2">
        <BackButton
          testo="Torna all'indice dei contenuti del bilancio"
          destinazione={`/app/modulo/${moduli.moduloAttivo?.codice}/${bilanci.bilancioAttivo?.anno}/temi_materialita`}
        />
        <Link
          to={`/app/modulo/fattori_esg/${bilanci.fattoreBilancioAttivo?.codice}`}
          className="p-2 flex items-center gap-2 font-bold font-bold hover:underline"
        >
          <FaPaperclip />
          <span>Vai alla scheda generica del fattore</span>
        </Link>
      </div>

      {bilanci.gettingFattoreBilancioAttivo ?
        <div className="text-2xl">
          <TextLoader />
        </div>
        :
        <div>

          {/* informativa generale */}
          <div>
            <InformativaFattoreESG loader="bilanci" />
          </div>

          {/* lista temi collegati */}
          {bilanci.fattoreBilancioAttivo && bilanci.fattoreBilancioAttivo?.temi_materialita.length > 0 ?
            <div className="my-4">
              <h2>{bilanci.fattoreBilancioAttivo?.temi_materialita.length} {t('ModuloFattoriEsg.SchedafattoreEsgBilancio.temiDiMaterialitaCollegati') } {bilanci.bilancioAttivo?.anno}</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mt-4">

                {
                  bilanci.fattoreBilancioAttivo?.temi_materialita.map((tema) => {
                    return (
                      <TemaMaterialitaBilancioTile tema={tema} />
                    )
                  })
                }

              </div>
            </div>
            :
            <div>
              <p className="my-4 text-lg text-center py-4">
                Nessun tema di materialità collegato a questo fattore nel bilancio del {bilanci.bilancioAttivo?.anno}.
              </p>
            </div>
          }

          { //Caso categoria
            (
              bilanci.fattoreBilancioAttivo?.tipo === 'categoria' ||
              bilanci.fattoreBilancioAttivo?.tipo === 'standard'
            ) &&
            <SchedaCategoria />
          }
          
          {
            //Caso tabella
            bilanci.fattoreBilancioAttivo?.tipo === 'tabella' &&
            <SchedaTabella />
          }

          {
            //Caso riga/colonna
            (
              bilanci.fattoreBilancioAttivo?.tipo === 'riga' ||
              bilanci.fattoreBilancioAttivo?.tipo === 'colonna'
            ) &&
            <Navigate to="/app/modulo/bilanci" />
          }
        </div>
      }

    </div>
  )
})

export default DettagliFattoreESGNelBilancio;