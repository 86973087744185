import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import 'mapbox-gl/dist/mapbox-gl.css';
import { IBusinessUnit } from '../../types'
import { useStores } from '../../../../hooks/useStores';
import { config } from '../../../../config';
import { UseModuloBusinessUnitsStore } from '../../context/UseModuloBusinessUnitsStore';

//Components
import Map, { Marker } from "react-map-gl";
import Dialogue from '../../../../components/common/Dialogue';

const MappaBusinessUnits: React.FC<{ businessUnits: IBusinessUnit[] }> = ({ businessUnits }) => {

  const [businessUnitsFlat, setBusinessUnitsFlat] = useState<IBusinessUnit[]>([]);
  const { t } = useTranslation();
  const [businessUnitsConCoordinateFlat, setBusinessUnitsConCoordinateFlat] = useState<IBusinessUnit[]>([]);
  const { ui } = useStores()
  const { canvas_business_units } = UseModuloBusinessUnitsStore()

  useEffect(() => {

    if (businessUnits) {

      const flattenBusinessUnits = (businessUnits: IBusinessUnit[]) => {
        businessUnits.forEach(businessUnit => {
          setBusinessUnitsFlat(oldBu => [...oldBu, businessUnit]);

          if (businessUnit.sotto_business_unit && businessUnit.sotto_business_unit.length > 0) {
            businessUnit.sotto_business_unit.forEach(sottoBusinessUnit => {
              flattenBusinessUnits([sottoBusinessUnit])
            })
          }
        })
      }

      flattenBusinessUnits(businessUnits);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    businessUnitsFlat.forEach((businessUnit: IBusinessUnit) => {
      if (businessUnit.longitudine && businessUnit.latitudine) {
        setBusinessUnitsConCoordinateFlat(old => [...old, businessUnit])
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnitsFlat])

  useEffect(() => {
    canvas_business_units.resettaCoordinate()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="pb-7">

      {
        businessUnitsConCoordinateFlat.length !== businessUnitsFlat.length &&
        <p className="mt-4"><b>{businessUnitsConCoordinateFlat.length}</b> {t('ModuloBusinessUnit.MappaBusinessUnit.su')} <b>{businessUnitsFlat.length}</b> {t('ModuloBusinessUnit.MappaBusinessUnit.businessUnitContengono')}</p>
      }

      <Map
        mapboxAccessToken={config.MAPBOX_API_TOKEN}
        initialViewState={{
          longitude: canvas_business_units.longitudine ? canvas_business_units.longitudine : 12,
          latitude: canvas_business_units.latitudine ? canvas_business_units.latitudine : 45.5,
          zoom: 5
        }}
        style={{ height: 700 }}
        mapStyle="mapbox://styles/mapbox/streets-v11"
      >

        {businessUnitsConCoordinateFlat.map(bu => {
          return (
            <Marker
              longitude={bu.longitudine}
              latitude={bu.latitudine}
              key={bu.nome}
              onClick={() => ui.mountDialogue(<Dialogue
                titolo={bu.nome}
                testo={`Via ${bu.via} ${bu.civico}, ${bu.cap}, ${bu.citta}, ${bu.paese}`}
                primaryCta={t('ModuloBusinessUnit.MappaBusinessUnit.vaiAllaBusinessUnit')}
                onConfirm={() => window.location.replace(`/app/modulo/business_unit/${bu.nome}`)}
              />)}
            />
          )
        })}

      </Map>
    </div>
  )
}

export default MappaBusinessUnits;