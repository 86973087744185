import React from 'react'

import FiltroRendicontazioneBusinessUnit from './FiltroRendicontazioneBusinessUnit';
import FiltroAvanzamento from './FiltroAvanzamento';
import FiltroCategorie from './FiltroCategorie';
import { useModuloRendicontazioneBilanciStores } from '../../context/useModuloRendicontazioneBilanciStores';
import { useTranslation } from 'react-i18next';
import FiltroTipo from './FiltroTipo';
import FiltroRicerca from './FiltroRicerca';
import { observer } from 'mobx-react';

const ListaFiltri = observer(() => {

    const { rendicontazione_bilanci } = useModuloRendicontazioneBilanciStores()
    const { t } = useTranslation()

    return (
        <div className="flex flex-col items-stretch bg-blue dark:bg-dark-accent text-white ">

            <FiltroRendicontazioneBusinessUnit
                apertoDefault={true}
                nome={t('common.cerca')}
                reset={() => rendicontazione_bilanci.applicaFiltriRicerca('')}
                bottoneReset={rendicontazione_bilanci.filtriRicerca ? true : false}
            >
                <FiltroRicerca />
            </FiltroRendicontazioneBusinessUnit>

            <FiltroRendicontazioneBusinessUnit
                nome={t('common.statoRendicontazione')}
                reset={() => rendicontazione_bilanci.applicaFiltriStato('')}
                bottoneReset={rendicontazione_bilanci.filtriStato ? true : false}

            >
                <FiltroAvanzamento />
            </FiltroRendicontazioneBusinessUnit>

            {
                rendicontazione_bilanci.categorieVisibili.length > 0 &&
                <FiltroRendicontazioneBusinessUnit
                    nome={t('common.categorie')}
                    reset={() => rendicontazione_bilanci.applicaFiltriCategorie()}
                    bottoneReset={rendicontazione_bilanci.filtriCategorie.size > 0 ? true : false}

                >
                    <FiltroCategorie />
                </FiltroRendicontazioneBusinessUnit>
            }

            <FiltroRendicontazioneBusinessUnit
                nome={t('common.tipo')}
                reset={() => rendicontazione_bilanci.applicaFiltriTipo('')}
                bottoneReset={rendicontazione_bilanci.filtriTipo ? true : false}

            >
                <FiltroTipo />
            </FiltroRendicontazioneBusinessUnit>

        </div >
    )
})

export default ListaFiltri;