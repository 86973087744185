import React from 'react'

//Data
import { IBusinessUnit } from '../types'
import { useStores } from '../../../hooks/useStores';
import 'mapbox-gl/dist/mapbox-gl.css';
import Map, { Marker } from "react-map-gl";
import { config } from '../../../config';

//Components
import Dialogue from '../../../components/common/Dialogue';

const SchedaBusinessUnitMappa: React.FC<{ businessUnit: IBusinessUnit }> = ({ businessUnit }) => {

    const { ui } = useStores()

    return (
        <div>

            <Map
                mapboxAccessToken={config.MAPBOX_API_TOKEN}
                initialViewState={{
                    longitude: businessUnit.longitudine,
                    latitude: businessUnit.latitudine,
                    zoom: 7.5
                }}
                style={{
                    width: 300,
                    height: 300
                }}
                mapStyle="mapbox://styles/mapbox/streets-v11"
            >
                <Marker
                    longitude={businessUnit.longitudine}
                    latitude={businessUnit.latitudine}
                    key={businessUnit.nome}
                    onClick={() => ui.mountDialogue(<Dialogue
                        titolo={businessUnit.nome}
                        testo={`Via ${businessUnit.via} ${businessUnit.civico}, ${businessUnit.cap}, ${businessUnit.citta}, ${businessUnit.paese}`}
                        primaryCta="Vai alla Business Unit"
                        onConfirm={() => window.location.replace(`/app/modulo/business_unit/${businessUnit.nome}`)}
                    />)}
                />
            </Map>
        </div>
    )
}

export default SchedaBusinessUnitMappa;
