import { observable, action, makeAutoObservable, runInAction } from 'mobx'

import {
    getRuoli,
    creaBusinessUnitRuolo,
    modificaBusinessUnitRuolo,
    eliminaBusinessUnitRuolo
} from '../rest/BusinessUnitRuoli'
import { IBusinessUnitRuolo } from '../../types'

export class businessUnitRuoliStore {

    listaBusinessUnitRuoli: IBusinessUnitRuolo[] = []
    gettingListaBusinessUnitRuoli: boolean = false

    creatingBusinessUnitRuolo: boolean = false
    creaBusinessUnitRuoloError: string = ''
    businessUnitRuoloCreato: boolean = false

    editingBusinessUnitRuolo: boolean = false
    modificaBusinessUnitRuoloError: string = ''
    businessUnitRuoloModificato: boolean = false

    deletingBusinessUnitRuolo: boolean = false
    eliminaBusinessUnitRuoloError: string = ''
    businessUnitRuoloEliminato: boolean = false


    constructor() {
        makeAutoObservable(this, {
            listaBusinessUnitRuoli: observable,
            gettingListaBusinessUnitRuoli: observable,

            getListaBusinessUnitRuoli: action,

            //Crea business unit
            creaBusinessUnitRuolo: action,
            creatingBusinessUnitRuolo: observable,
            creaBusinessUnitRuoloError: observable,
            businessUnitRuoloCreato: observable,

            //Modifica business unit
            modificaBusinessUnitRuolo: action,
            editingBusinessUnitRuolo: observable,
            modificaBusinessUnitRuoloError: observable,
            businessUnitRuoloModificato: observable,

            //Elimina business unit
            eliminaBusinessUnitRuolo: action,
            deletingBusinessUnitRuolo: observable,
            eliminaBusinessUnitRuoloError: observable,
            businessUnitRuoloEliminato: observable,
        })
    }

    getListaBusinessUnitRuoli = async () => {
        this.gettingListaBusinessUnitRuoli = true

        getRuoli().then((res) => {

            this.listaBusinessUnitRuoli = res.data.data

        }).catch((err) => {

            console.log(err)

        }).finally(() => {

            this.gettingListaBusinessUnitRuoli = false

        })
    }

    creaBusinessUnitRuolo = async (BusinessUnitRuolo: IBusinessUnitRuolo) => {
        this.creatingBusinessUnitRuolo = true

        await creaBusinessUnitRuolo(BusinessUnitRuolo).then(res => {

            runInAction(() => this.creaBusinessUnitRuoloError = '')

        }).catch(err => {

            runInAction(() => this.creaBusinessUnitRuoloError = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {

            runInAction(() => this.creatingBusinessUnitRuolo = false)
            runInAction(() => this.businessUnitRuoloCreato = !this.businessUnitRuoloCreato)

        })
    }

    modificaBusinessUnitRuolo = async (BusinessUnitRuolo: IBusinessUnitRuolo) => {
        this.editingBusinessUnitRuolo = true

        await modificaBusinessUnitRuolo(BusinessUnitRuolo).then(res => {

            runInAction(() => this.modificaBusinessUnitRuoloError = '')

        }).catch(err => {

            runInAction(() => this.modificaBusinessUnitRuoloError = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {

            runInAction(() => this.editingBusinessUnitRuolo = false)
            runInAction(() => this.businessUnitRuoloModificato = !this.businessUnitRuoloModificato)

        })
    }

    eliminaBusinessUnitRuolo = async (valore: number) => {
        this.deletingBusinessUnitRuolo = true

        await eliminaBusinessUnitRuolo(valore).then(res => {

            runInAction(() => this.eliminaBusinessUnitRuoloError = '')

        }).catch(err => {

            runInAction(() => this.eliminaBusinessUnitRuoloError = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {
            
            console.log(this.businessUnitRuoloEliminato)
            
            runInAction(() => this.deletingBusinessUnitRuolo = false)
            runInAction(() => this.businessUnitRuoloEliminato = !this.businessUnitRuoloEliminato)
            
            console.log(this.businessUnitRuoloEliminato)
            
        })
    }
}