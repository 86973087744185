import React from 'react'

//Data
import { observer } from 'mobx-react-lite';
import { IFattoreESG } from '../../types';

const IndicatoreTabellaMinificata: React.FC<{ tabella: IFattoreESG }> = observer(({ tabella }) => {

    return (
        <div className="tabella-generica-minificata">
            <table className="min-w-full">
                <thead>
                    <tr>
                        <th></th>
                        {tabella.sotto_fattori.length > 0 &&
                            tabella.sotto_fattori.filter(sotto_fattore => sotto_fattore.tipo === 'colonna').map((colonna, index) => (
                                <th key={index}>{colonna.nome}</th>
                            ))
                        }
                    </tr>
                </thead>

                <tbody>
                    {tabella.sotto_fattori.length > 0 &&
                        tabella.sotto_fattori.filter(sotto_fattore => sotto_fattore.tipo === 'riga').map((riga, index) => (
                        <tr key={index}>
                            <th>{riga.nome}</th>
                            {tabella.sotto_fattori.filter(sotto_fattore => sotto_fattore.tipo === 'colonna').map((colonna, index) => (
                                <th>
                                    {/* {
                                        colonna.indicatori.filter(indicatore => indicatore.collegamento.riga === riga.codice).map((indicatore, index) => (
                                            <div className="flex gap-2 items-center justify-between" key={index}>
                                                <span>{indicatore.valore_numerico && indicatore.valore_numerico as number * 1} {indicatore.unita_misura} {indicatore.valore_testuale}</span>
                                            </div>
                                        ))
                                    } */}
                                </th>
                            ))}
                        </tr>
                    ))}
                </tbody>

            </table>
        </div>
    )
})

export default IndicatoreTabellaMinificata;
