import React from 'react'

const NetworkError = () => {

    const styles = {
        wrapper: "w-full py-2 bg-red text-white text-center flex items-center justify-center z-[1000] top-0 z-0 pointer-events-none px-2",
    }

    return (
        <div className={styles.wrapper}>
            <p className="text-xs">Stiamo riscontrando problemi di rete, prova ad aggiornare la pagina o ad effettuare nuovamente il login. Se persistono contatta l'assistenza.</p>
        </div>
    )
}

export default NetworkError;