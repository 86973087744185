export const en_modulo_gestione_unita_misura = {
    ContextMenu: {
        unitaMisuraEliminata: 'Measurement unit successfully deleted',
        modificaUnitaMisura: 'Edit measurement unit',
        messagioElimina: 'Are you sure you want to delete this measurement unit?',
        eliminaUnitaMisura: 'Delete measurement unit',
    },

    Archivio: {
        nessunaUnitaMisura: 'No measurement unit available',
        nuovaUnitaMisura: 'Add new measurement unit',
    },

    Form: {
        modificaUnitaMisura: 'Edit measurement unit',
        creaUnitaMisura: 'Create new measurement unit.',
        modificaUnitaMisuraTesto: 'Edit measurement unit data',
        modificaUnitaMisuraTestoCompleto: '',
        placeholderCodice: '"€, $, km',
        CreaUnitaMisura: 'Create measurement unit',
        messaggiUnitaMisuraCreatoSucesso: 'Measurement unit successfully created',
    },
}