import React, { useEffect } from 'react'

//Data
import { observer } from 'mobx-react-lite'
import { Navigate, useParams } from 'react-router-dom'
import { useModuloESGStores } from '../context/useModuloESGStores'

//Components
import BackButton from '../../../components/common/BackButton'
import InformativaBusinessUnit from '../components/InformativaBusinessUnit'
import ListaFattoriESG from '../components/ListaFattoriESG'
import TextLoader from '../../../components/common/TextLoader'

const DettagliBusinessUnitNelBilancio = observer(() => {

  const { bilanci } = useModuloESGStores()
  const { bilancio_anno, business_unit_nome } = useParams()

  //Effettua il redirect nella sezione bilanci se non riesce a caricare la busiiness unit
  useEffect(() => {
    bilancio_anno &&
      bilanci.getBusinessUnitBilancio(parseInt(bilancio_anno), business_unit_nome as string).catch(() => {
        return <Navigate to="app/modulo/bilanci" />
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business_unit_nome, bilancio_anno])

  return (
    <div>
      <div className="my-2">
        <BackButton
          testo="Torna all'archivio delle business unit"
          destinazione={`/app/modulo/bilanci/${bilanci.bilancioAttivo?.anno}/business_units`}
        />
      </div>

      {bilanci.businessUnitBilancioAttivo &&
        <div>
          <InformativaBusinessUnit businessUnit={bilanci.businessUnitBilancioAttivo} />
        </div>
      }

      <div className="flex flex-col gap-2">
        <h2 className="text-lg font-bold my-2">Lista dei fattori ESG ai quali la business unit ha accesso:</h2>

        {bilanci.businessUnitBilancioAttivo?.fattori && !bilanci.gettingBusinessUnitBilancioAttivo ?
          <ListaFattoriESG fattoriEreditati={bilanci.businessUnitBilancioAttivo.fattori.filter(f => f.tipo !== 'colonna' && f.tipo !== 'riga')} />
          :
          <div className="flex flex-col gap-2 text-4xl">
            <TextLoader />
            <TextLoader />
            <TextLoader />
          </div>
        }

      </div>
    </div>
  )
})

export default DettagliBusinessUnitNelBilancio;