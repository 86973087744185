import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { observer } from 'mobx-react-lite'

//Components
import { FaInfoCircle, FaPlus, FaSlidersH } from 'react-icons/fa'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'

import { useStores } from '../../../../hooks/useStores'
import TileUtente from '../../UtenteTile'
import BarraRicercaGenerale from '../../../../components/common/BarraRicercaGenerale'
import { IUtente } from '../../../../types'


export const PannelloGruppiUtentiSenzaGruppo = observer(() => {
    const { gestioneUtenti } = useStores();
    const { t } = useTranslation();

    const styles = {
        cardsWrapper: "grid gap-3 xl:gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-6",
        btnFiltri: "text-white opacity-70 p-1 rounded-lg w-[200px]"
    }
    const [ordinati, setOrdinati] = useState("");

    const buttonOrder = (event: React.MouseEvent<HTMLButtonElement>): any => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;
        setOrdinati(button.name);
    }

    const [filtrati, setFiltrati] = useState("");

    const buttonFilter = (event: React.MouseEvent<HTMLButtonElement>): any => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;
        setFiltrati(button.name);
    }

    const [arrayFiltri, setFiltri] = useState<boolean>(false)
    const chiudFiltri = () => {
        setFiltri(false)
        setOrdinati("")
        setFiltrati("")
        gestioneUtenti.getUtentiSenzaGruppo();

    }
    const [utente, setUtente] = useState<IUtente[]>([]);
    const utenti = gestioneUtenti.utenti_senza_gruppo;
    const [stringaDiRicercaUtentiSenzaGruppo, setStringaDiRicercaUtentiSenzaGruppo] = useState<string>('');

    //Dati per paginazione utenti senza gruppo
    // const senzaGruppoFiltrati = gestioneUtenti.utenti_senza_gruppo.filter((utente) => utente.nome.toLocaleLowerCase().includes(stringaDiRicercaUtentiSenzaGruppo.toLocaleLowerCase()) || utente.email.toLocaleLowerCase().includes(stringaDiRicercaUtentiSenzaGruppo.toLocaleLowerCase()));
    const [numeroPaginaSenzaGruppo, setNumeroPaginaSenzaGruppo] = useState(0);
    const utentiSenzaGruppoPerPagina = 4;
    const pagineSenzaGruppoVisitate = numeroPaginaSenzaGruppo * utentiSenzaGruppoPerPagina;

    useEffect(() => {
        setNumeroPaginaSenzaGruppo(0);
    }, [stringaDiRicercaUtentiSenzaGruppo])

    useEffect(() => {
        setUtente(utenti)
    })

    useEffect(() => {
        if (ordinati === "za") {
            setUtente(utenti.sort((a: IUtente, b: IUtente) => b.nome > a.nome ? -1 : 1))
        }
        if (ordinati === "az") {
            setUtente(utenti.sort((a: IUtente, b: IUtente) => b.nome > a.nome ? 1 : -1))
        }
    }, [ordinati])

    useEffect(() => {
        if (filtrati === "resetta") {
            setOrdinati("")
            setFiltrati("")
            gestioneUtenti.getUtenti();
        }
    }, [filtrati])

    const utentiFiltrati = utente
        .filter((utente) => {
            return (
                stringaDiRicercaUtentiSenzaGruppo === "" ||
                (
                    utente.nome.toLocaleLowerCase()
                        .includes(stringaDiRicercaUtentiSenzaGruppo.toLocaleLowerCase()) ||
                    utente.email
                        .includes(stringaDiRicercaUtentiSenzaGruppo.toLocaleLowerCase())
                )
            ) && (
                    filtrati !== "" ?
                        (filtrati === "attivo" && utente.attivo && !utente.disabilitato ||
                            filtrati === "nonAttivo" && !utente.attivo && !utente.disabilitato ||
                            filtrati === "resetta" && gestioneUtenti.utenti)
                        :
                        true
                )
        })

    useEffect(() => {
        if (filtrati === "attivo" || filtrati === "nonAttivo") {
            setNumeroPaginaSenzaGruppo(0);
        }
    }, [filtrati])

    const conteggioPaginaSenzaGruppo = Math.ceil(
        utente.filter((utente) => {
            return (
                stringaDiRicercaUtentiSenzaGruppo === "" ||
                (
                    utente.nome.toLocaleLowerCase()
                        .includes(stringaDiRicercaUtentiSenzaGruppo.toLocaleLowerCase()) ||
                    utente.email
                        .includes(stringaDiRicercaUtentiSenzaGruppo.toLocaleLowerCase())
                )
            ) && (
                    filtrati !== "" ?
                        (filtrati === "attivo" && utente.attivo && !utente.disabilitato ||
                            filtrati === "nonAttivo" && !utente.attivo && !utente.disabilitato ||
                            filtrati === "resetta" && utenti)
                        :
                        true
                )
        }).length / utentiSenzaGruppoPerPagina
    );
    const cambioPaginaSenzaGruppo = ({ selected }: { selected: number }): void => {
        setNumeroPaginaSenzaGruppo(selected)
    };

    useEffect(() => {
        gestioneUtenti.getUtentiSenzaGruppo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        gestioneUtenti.creatingUtente,
        gestioneUtenti.utenteModificato,
        gestioneUtenti.utenteEliminato,
        gestioneUtenti.creatingGruppo,
        gestioneUtenti.gruppoModificato,
        gestioneUtenti.gruppoEliminato
    ])
    return (
        <div className='shadow-xl px-4 py-2'>
        

            <div className='lg:flex text-center justify-between mb-3 text-accent dark:text-dark-accent mt-6 border-[lightgray] border-b-2 py-2'>
                <span className="text-lg lg:text-2xl font-bold uppercase">{t('ModuloGestioneUtenti.PannelloUtenti.utentiSenzaGruppi')}</span>
                <BarraRicercaGenerale 
                    setStringaDiRicerca={setStringaDiRicercaUtentiSenzaGruppo}
                    numeroRisultati={utentiFiltrati.length}
                    mostraRisultati={true} />
            </div>
            <div className="flex items-center justify-end my-2">

                {arrayFiltri ?
                    <div className="cursor-pointer p-1 hover:bg-white rounded-sm rotate-45" onClick={() => chiudFiltri()}>
                        <FaPlus />
                    </div>
                    :
                    <div className="cursor-pointer p-1 hover:bg-white rounded-sm" onClick={() => setFiltri(true)} >
                        <FaSlidersH />
                    </div>
                }

            </div>
            {arrayFiltri &&
                <>
                    <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-2 mb-6 m-auto mostraFiltri'>
                        <div className=''>
                            <button onClick={buttonFilter} className={`${styles.btnFiltri} ${filtrati === "attivo" ? "opacity-100" : ""}`} name="attivo"> Filtra per attivi</button>
                        </div>
                        <div className=''>
                            <button onClick={buttonFilter} className={`${styles.btnFiltri} ${filtrati === "nonAttivo" ? "opacity-100" : ""}`} name="nonAttivo"> Filtra per disattivi </button>
                        </div>
                        <div className=''>
                            <button onClick={buttonOrder} className={`${styles.btnFiltri} ${ordinati === "az" ? "opacity-100" : ""}`} name="az"> Ordina per nome desc </button>
                        </div>
                        <div className=''>
                            <button onClick={buttonOrder} className={`${styles.btnFiltri} ${ordinati === "za" ? "opacity-100" : ""}`} name="za"> Ordina per nome asc </button>
                        </div>
                        <div className=''>
                            <button onClick={buttonFilter} className={`${styles.btnFiltri}`} name="resetta"> Resetta filtro </button>
                        </div>
                    </div>

                </>
            }
            <div>
            </div>
            {utentiFiltrati.length > 0 ?

                (
                    <><div className={styles.cardsWrapper}>
                        {utentiFiltrati
                            .slice(pagineSenzaGruppoVisitate, pagineSenzaGruppoVisitate + utentiSenzaGruppoPerPagina)
                            .map((utente, i) => { return <TileUtente boleanoMostrare={false} key={i} utente={utente} /> })
                        }
                    </div>
                        {gestioneUtenti.utenti_senza_gruppo.length > 4 &&
                            <ReactPaginate
                                pageCount={conteggioPaginaSenzaGruppo}
                                forcePage={numeroPaginaSenzaGruppo}
                                nextLabel={'Next'}
                                onPageChange={cambioPaginaSenzaGruppo}
                                containerClassName={"paginationBttns"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"} />
                        }

                    </>
                )
                :
                (
                    <>
                        <p className="flex items-center gap-2 opacity-70 justify-center">
                            <FaInfoCircle /> <span className="text-lg">{t('ModuloGestioneUtenti.PannelloUtentiSenzaGruppo.nessunUtenteSenzaGruppo')}</span>
                        </p>
                    </>
                )

            }
        </div>

    )
}
)
export default PannelloGruppiUtentiSenzaGruppo