import React from 'react'
import { useTranslation } from 'react-i18next'

import { observer } from 'mobx-react-lite'
import { useForm } from 'react-hook-form'
import { useStores } from '../../hooks/useStores'
import { IUnitaMisura } from '../../types'
import InputField from '../../components/common/form/InputField'
import SpinnerButton from '../../components/common/SpinnerButton'
import ErrorBox from '../../components/common/ErrorBox'
import InfoBox from '../../components/common/InfoBox'


const UnitaMisuraForm: React.FC<{ unitaMisura?: IUnitaMisura }> = observer(({ unitaMisura }) => {

    const { gestioneUnitaMisura } = useStores()
    const { t } = useTranslation();

    //Istanzia Hook Form
    const { watch, register, setValue, handleSubmit, formState: { errors, isSubmitSuccessful } } = useForm({
        defaultValues: {
            codice: unitaMisura ? unitaMisura.codice : '',
            codice_originale: unitaMisura ? unitaMisura.codice : '',
            descrizione: unitaMisura ? unitaMisura.descrizione : ''
        }
    })
    const onSubmit = () => {
        unitaMisura ?

        gestioneUnitaMisura.modificaUnitaMisura(watch() as IUnitaMisura).then(() => {
            setValue('codice_originale', watch('codice') as string)
          })
          :

          gestioneUnitaMisura.creaUnitaMisura(watch() as IUnitaMisura)

    }

    return (
        <div>
            <div className="mb-4">
                <h2 className="text-2xl mb-2">
                    {unitaMisura ?
                        `${t('ModuloGestioneUnitaMisura.Form.modificaUnitaMisura')} ${unitaMisura.codice}`
                        :
                        t('ModuloGestioneUnitaMisura.Form.creaUnitaMisura')
                    }
                </h2>
                <p className="text-text-light">
                    {unitaMisura ?
                        `${t('ModuloGestioneUnitaMisura.Form.modificaUnitaMisura')} ${unitaMisura.descrizione},`
                        :
                        t('ModuloGestioneUnitaMisura.Form.creaUnitaMisura')
                    }
                </p>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>

                <InputField
                    label= {t('common.codice')}
                    classi="codice"
                    placeholder={t('ModuloGestioneUnitaMisura.Form.placeholderCodice')}
                    type="text"
                    error={errors.codice}
                    form={register("codice", { required:t('common.fornisciCodice')} , )}
                />

                <InputField
                    label={t('common.descrizione')}
                    classi="descrizione"
                    placeholder={t('common.descrizione')}
                    type="text"
                    form={register("descrizione")}
                />

                <div className="form">
                    {/* Submit modifica unita_misura */}
                    { unitaMisura &&

                        <>
                            <div className="form">
                                <div>
                                    {!gestioneUnitaMisura.editingUnitaMisura ? <input type="submit" value={t('common.salvaModifica')} /> : <SpinnerButton />}
                                </div>
                            </div>

                            {
                                isSubmitSuccessful && !gestioneUnitaMisura.editingUnitaMisura && (

                                    gestioneUnitaMisura.modificaUnitaMisuraError
                                        ?
                                        <div className="my-2">
                                            <ErrorBox errore={gestioneUnitaMisura.modificaUnitaMisuraError} />
                                        </div>
                                        :
                                        <div className="my-2">
                                            <InfoBox messaggio={t('common.modificaSalvato')} />
                                        </div>

                                )
                            }

                        </>
                }
                   {/* Submit creazione unita_misura */}
          {!unitaMisura &&

<>
  {
    (isSubmitSuccessful && !gestioneUnitaMisura.creatingUnitaMisuraError && !gestioneUnitaMisura.creatingUnitaMisura) ?
      <InfoBox messaggio={t('ModuloGestioneUnitaMisura.Form.messaggiUnitaMisuraCreatoSucesso')} id={"crea-unita_misura-success"} />
      :
      <div className="form pt-4">
        {!gestioneUnitaMisura.creatingUnitaMisura ? <input type="submit" value={t('ModuloGestioneUnitaMisura.Form.CreaUnitaMisura')} id="crea-unita_misura-submit" /> : <SpinnerButton />}
      </div>
  }

  {
    gestioneUnitaMisura.creatingUnitaMisuraError &&
    <div className="mb-2">
      <ErrorBox errore={gestioneUnitaMisura.creatingUnitaMisuraError.toString()} />
    </div>
  }

</>
}
    </div>
            </form>
        </div>
    )
})
export default UnitaMisuraForm;