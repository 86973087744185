import React from 'react'

//Data
import { observer } from 'mobx-react-lite'
import { useTemaMaterialitaStores } from '../../context/useTemaMaterialitaStores'
import { ITemaMaterialita } from '../../../../types'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

//Components
import InputField from '../../../../../../components/common/form/InputField'
import ErrorBox from '../../../../../../components/common//ErrorBox'
import InfoBox from '../../../../../../components/common//InfoBox'
import SpinnerButton from '../../../../../../components/common//SpinnerButton'
import { CompactPicker } from 'react-color';

const TemaMaterialitaForm: React.FC<{ tema?: ITemaMaterialita, codice?: string, nome?: string }> = observer(({ tema, codice, nome }) => {

  const { register, handleSubmit, watch, setValue, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      codice: tema ? tema.codice : '',
      codice_originale: tema ? tema.codice : '',
      nome: tema ? tema.nome : '',
      descrizione: tema ? tema.descrizione : '',
      colore: tema ? tema.colore : null,
    }
  });

  const { materialita } = useTemaMaterialitaStores();
  const { t } = useTranslation()

  const onSubmit = () => {
    tema
      ?
      materialita.modificaTemaMaterialita(watch() as ITemaMaterialita).then(() => {
        setValue('codice_originale', watch('codice'))
      })
      :
      materialita.creaTemaMaterialita(watch() as ITemaMaterialita)
  }

  const handleColorPickerChange = (colore: any) => {
    setValue('colore', colore.hex)
    console.log(watch('colore'))
  }

  return (
    <div>
      <div className="mb-4">
        <h2 className="text-2xl mb-2">
          {tema ?
            `${t('ModuloFattoriEsg.FormTemaMaterialitaBilancio.ModificaMaterilita')} ${tema.nome}`
            :
            `${t('ModuloFattoriEsg.FormTemaMaterialita.creaTemaMaterialita')}`
          }
        </h2>
        <p className="text-text-light">
          {tema ?
            `${t('ModuloFattoriEsg.formFattorEsg.modificaIDati')} ${codice ? codice : t('ModuloFattoriEsg.FormTemaMaterialita.delTema')} ${tema.nome}`
            :
            `${t('ModuloFattoriEsg.formFattorEsg.stabilisciCodice')} ${codice ? codice : 'Materialita'}`
          }
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>

        <InputField
          label={t('common.codice')}
          type="text"
          error={errors.codice}
          form={register("codice", { required: t('ModuloFattoriEsg.FormTemaMaterialita.fornisciCodice') })}
          placeholder={t('ModuloFattoriEsg.FormTemaMaterialita.placeholderTemaMaterilita')}
        />

        <InputField
          label={t('common.nome')}
          type="text"
          error={errors.nome}
          form={register("nome", { required: t('ModuloFattoriEsg.FormTemaMaterialita.fornisciNomeTema') })}
          placeholder={t('ModuloFattoriEsg.FormTemaMaterialita.placeholderTemaMaterilita')}
        />

        <InputField
          label={t('common.descrizione')}
          type="textarea"
          error={errors.descrizione}
          form={register("descrizione")}
          placeholder={t('ModuloFattoriEsg.formFattorEsg.placeholderDescrizionefattore')}

        />


        <div>
          <label className="text-center block w-full mt-4">{t('ModuloFattoriEsg.FormObiettivoAgenda2030.colorEtichetta')}</label>
          <div className="mt-2 flex items-center justify-center" >
            <CompactPicker 
            color={watch('colore') ? watch('colore') as string : '#68BC00'}
            onChangeComplete={color => handleColorPickerChange(color)}
           
          />
          </div>
        </div>

        {/* Submit modifica fattore */}
        {tema &&

          <>
            <div className="form pt-4">
              {!materialita.editingTemaMaterilita ? <input type="submit" value={t('common.salvaModifica')} /> : <SpinnerButton />}
            </div>

            {
              isSubmitSuccessful && !materialita.editingTemaMaterilita && (

                materialita.modificaTemaMaterialitaError
                  ?
                  <div className="my-2">
                    <ErrorBox errore={materialita.modificaTemaMaterialitaError} />
                  </div>
                  :
                  <div className="my-2">
                    <InfoBox messaggio={t('common.modificaSalvato')} />
                  </div>

              )
            }

          </>
        }

        {/* Submit creazione fattore */}
        {!tema &&

          <>
            <div className="form pt-4">
              {!materialita.creatingTemaMaterialita ? <input type="submit" value={`${t('common.crea')} ${watch('nome')}`} id="crea-tema-submit" /> : <SpinnerButton />}
            </div>

            {
              isSubmitSuccessful && !materialita.creatingTemaMaterialita && (
                materialita.creatingTemaMaterialitaError ?
                  <div className="mb-2">
                    <ErrorBox errore={materialita.creatingTemaMaterialitaError.toString()} />
                  </div>
                  :
                  <div className="mb-2">
                    <InfoBox messaggio={`${watch('codice')} ${t('ModuloFattoriEsg.formFattorEsg.FattorecreatoConSucesso')}`} id={"crea-tema-success"} />
                  </div>
              )
            }

          </>
        }
      </form>
    </div>
  )
})

export default TemaMaterialitaForm;