import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../hooks/useStores'

const FollowMouseModal = observer(() => {

  const { ui } = useStores();

  const [mousePos, setMousePos] = useState<{ x: number, y: number }>();

  //Setta posizione del component
  const handleMouseMove = (event: MouseEvent) => {

    if (!mousePos?.x && !mousePos?.y) {

      let x = event.clientX

      if (event.clientX > (window.innerWidth - 300)) {
        x = event.clientX - 300
      }

      setMousePos({ x: x, y: event.clientY });
    }

  };

  return (
    <div
      id="follow-mouse-modal"
      className="top-0 left-0 fixed z-[1000] w-screen h-screen"
    >

      <div className="w-full h-full">
        <div
          onMouseOver={(e: any) => handleMouseMove(e)}
          onClick={() => ui.unmountFollowMouseModal()}
          className="sfondo-dialogue w-full h-full bg-[rgba(0,0,0,0.1)] absolute top-0 left-0 right-0 bottom-0"
        />

        {mousePos?.x && mousePos?.y &&
          <div
            id="follow-mouse-modal-content"
            className="absolute dialogue-animato shadow-2xl p-4 rounded-md min-w-[300px] bg-white dark:bg-darkgray-dd dark:border dark:border-darkgray"
            style={{ left: `${mousePos?.x}px`, top: `${mousePos?.y}px`}}
          >
            {ui.followMouseModalComponent}
          </div>
        }
      </div>

    </div>
  )
})

export default FollowMouseModal;