import React, { useEffect, useState } from 'react'
import { useModuloRendicontazioneBilanciStores } from '../context/useModuloRendicontazioneBilanciStores'
import { observer } from 'mobx-react-lite'
import { IBusinessUnitRendicontabile } from '../../../types'
import { Link, useParams } from 'react-router-dom'
import Spinner from '../../../../../components/common/Spinner'
import ReactPaginate from 'react-paginate'
import BarraRicercaGenerale from '../../../../../components/common/BarraRicercaGenerale'

const ListaBusinessUnitsRendicontabili = observer(() => {

    const { rendicontazione_bilanci } = useModuloRendicontazioneBilanciStores()
    const { business_unit_nome } = useParams()
    const [stringaDiRicercaBU, setStringaDiRicercaBU] = useState<string>('');
    const businessUnit = rendicontazione_bilanci.bilancio?.business_units_rendicontabili;
    const businessUnitFiltrate = businessUnit?.filter((bu) => bu.nome.toLowerCase().includes(stringaDiRicercaBU.toLowerCase()));
    const [numeroPagineBusinessUnit, setNumeroPagineBusinessUnit] = useState(0);
    const businessUnitPerPagina = 4;
    const pagineBusinessUnitVisitate = numeroPagineBusinessUnit * businessUnitPerPagina;
    const conteggioPaginaBusinessUnit = Math.ceil(businessUnitFiltrate!.length / businessUnitPerPagina);
    const cambioPaginaBusinessUnit = ({ selected }: { selected: number }): void => {
        setNumeroPagineBusinessUnit(selected)
    };

    useEffect(() => {
        setNumeroPagineBusinessUnit(0)
    }, [stringaDiRicercaBU])

    return (
        <div>
            <div className='flex justify-end mb-4 mt-2'>
                <BarraRicercaGenerale
                    setStringaDiRicerca={setStringaDiRicercaBU}
                    numeroRisultati={businessUnitFiltrate!.length}
                    mostraRisultati={true} />
            </div>
            {rendicontazione_bilanci.bilancio &&

                <div className="grid gap-3 xl:gap-5 grid-cols-4 mb-6">
                    {businessUnitFiltrate!
                        .slice(pagineBusinessUnitVisitate, pagineBusinessUnitVisitate + businessUnitPerPagina)
                        .map((businessUnit: IBusinessUnitRendicontabile) => {
                            return (
                                <Link
                                    className={`w-[100px] h-[100px] md:w-[120px] md:h-[120px] lg:w-[130px] lg:h-[130px] m-auto rounded-full 
                        p-3 cursor-pointer bg-white dark:bg-darkgray-dd duration-200 relative flex items-center justify-center border-2 border-blue
                        ${rendicontazione_bilanci.gettingBusinessUnit && 'pointer-events-none opacity-50'}
                        ${rendicontazione_bilanci.businessUnitAttiva &&
                                            businessUnit.nome === rendicontazione_bilanci.businessUnitAttiva.nome ?
                                            'pointer-events-none bg-blue dark:bg-dark-accent text-white dark:text-white'
                                            : 'opacity-50'
                                        }`}
                                    key={businessUnit.nome}
                                    to={businessUnit.nome}
                                >
                                    <span className={`block whitespace-nowrap ${business_unit_nome === businessUnit.nome && rendicontazione_bilanci.gettingBusinessUnit && 'opacity-0'}`}>
                                        {businessUnit.nome}
                                    </span>
                                    {
                                        business_unit_nome === businessUnit.nome && rendicontazione_bilanci.gettingBusinessUnit &&
                                        <div className="absolute">
                                            <Spinner colore="blue" />
                                        </div>
                                    }

                                </Link>
                            )
                        })}
                </div>
            }
                    {businessUnitFiltrate!.length >= 4 &&
                        <div className='flex justify-center mb-4'>
                            <div>
                            <ReactPaginate
                                pageCount={conteggioPaginaBusinessUnit}
                                nextLabel={'Next'}
                                onPageChange={cambioPaginaBusinessUnit}
                                forcePage={numeroPagineBusinessUnit}
                                containerClassName={"paginationBttns"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"} />
                            </div>
                        </div>
                    }
        </div>
    )
})

export default ListaBusinessUnitsRendicontabili; 