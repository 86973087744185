import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../../../../../hooks/useStores'
import { useModuloESGStores } from '../../../../context/useModuloESGStores'
import { IFattoreESG } from '../../../../types'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'

//Components
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import Spinner from '../../../../../../components/common/Spinner'
import BloccoCanvasApplicaObiettiviFattori from './BloccoCanvasApplicaObiettiviFattori'
import { useModuloFattoriAgenda2030Stores } from '../../context/useModuloFattoriAgenda2030Stores'
import AggiungiObiettiviAgenda2030FattoriForm from '../AggiungiObiettiviAgenda2030FattoriForm'

const CanvasApplicaObiettiviAgenda2030Fattori: React.FC<{ fattori: IFattoreESG[], fattori_flat: IFattoreESG[] }> = observer(({ fattori, fattori_flat }) => {

    const { ui } = useStores();
    const { t } = useTranslation()
    const { fattori_esg } = useModuloESGStores();
    const { ui_canvas } = useModuloFattoriAgenda2030Stores()

    //lista bilanci filtrati per stringa ricerca
    const [fattoriFiltrati, setfattoriFiltrati] = useState<IFattoreESG[]>([])
    //Stato caricamento
    const [loading, setLoading] = useState<boolean>(true)


    useEffect(() => {
        setLoading(fattori_esg.gettingFattoreESGAttivo)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fattori_esg.gettingFattoreESGAttivo])

    //Caricamento fattori
    useEffect(() => {

        fattori_esg.fattoreESGAttivo && setfattoriFiltrati(toJS(fattori))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fattori_esg.fattoreESGAttivo])

    const styles = {
        canvas: `
        bg-lightgray-d border border-lightgray-dd dark:border-black overflow-scroll flex relative dark:bg-darkgray-dd
        min-w-full max-w-[calc(100vw-20px)] md:max-w-[calc(100vw-300px)] canvas-body max-h-[1800px]
        ${ui.asideModal && 'md:max-w-[calc(100vw-700px)]'}`
    }

    const canvas = useRef<HTMLInputElement>(null)

    const sposta = (direzione: string) => {
        canvas.current && canvas.current.scrollTo({ left: canvas.current.scrollLeft + (direzione === 'dx' ? 180 : -180), behavior: 'smooth' })
    }

    return (
        <div className={`min-w-full`}>


            {Array.from(ui_canvas.fattoriSelezionati).length > 0 ?
                <AggiungiObiettiviAgenda2030FattoriForm />
                :
                <h2 className="text-xl pt-4 font-bold text-center">
                    {t('ModuloFattoriEsg.CanvasApplicaObiettiviAgenda2030.premiFattori')}
                </h2>
            }

            <div className="flex items-center justify-between min-h-[30px]">
                <div className="flex flex-end items-center gap-2 justify-end w-full">
                    <FaAngleLeft size={20} className="cursor-pointer opacity-50 duration-200 hover:opacity-100" onClick={() => sposta('sx')} />
                    <FaAngleRight size={20} className="cursor-pointer opacity-50 duration-200 hover:opacity-100" onClick={() => sposta('dx')} />
                </div>
            </div>

            {/* corpo del canvas */}
            <div className={styles.canvas} ref={canvas}>



                <div className="flex flex-col gap-2 py-4">
                    {
                        //Spinner
                        loading &&
                        <div className="ml-4">
                            <Spinner colore="#002e5a" />
                        </div>
                    }

                    {
                        !loading &&
                        fattoriFiltrati?.length > 0 &&
                        fattoriFiltrati.map((fattore: IFattoreESG) => {
                            return (
                                <BloccoCanvasApplicaObiettiviFattori key={fattore.codice} fattore={fattore} />
                            )
                        })
                    }
                </div>


            </div>
        </div>
    )
})

export default CanvasApplicaObiettiviAgenda2030Fattori;