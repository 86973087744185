import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { IObiettivoAgenda2030 } from '../../../types'
import { getListaObiettiviAgenda2030 } from '../../UiModuloAgenda2030/rest/agenda_2030'
import { config } from '../../../../../config'
import { useModuloFattoriAgenda2030Stores } from '../context/useModuloFattoriAgenda2030Stores'
import { useModuloESGStores } from '../../../context/useModuloESGStores'
import { observer } from 'mobx-react'

//Components
import SpinnerButton from '../../../../../components/common/SpinnerButton'

const AggiungiObiettiviAgenda2030FattoriForm = observer(() => {

    const [obiettiviAgenda2030, setObiettiviAgenda2030] = useState<IObiettivoAgenda2030[]>([])
    const [mostraObiettiviAgenda2030, setMostraObiettiviAgenda2030] = useState<boolean>(false)
    const { t } = useTranslation()
    const { ui_canvas } = useModuloFattoriAgenda2030Stores()
    const { fattori_esg } = useModuloESGStores()

    useEffect(() => {
        getListaObiettiviAgenda2030().then((res) => {
            setObiettiviAgenda2030(res.data.data)
        })
    }, [])

    const handleSettaObiettivi = () => {

        fattori_esg.collegaFattoreESGObiettivi2030(
            Array.from(ui_canvas.fattoriSelezionati),
            Array.from(ui_canvas.obiettiviAgenda2030Selezionati)
        )

        ui_canvas.ripristinaFattoriObiettivi()

    }

    return (
        <div className="mt-4 p-2 bg-white dark:bg-darkgray-dd">

            <p className="mb-2"> {t('ModuloFattoriEsg.FormAggiungiObiettiviAgenda2030Fattori.fattoriSelezionati')}:</p>

            <div className="flex flex-wrap gap-2">
                {Array.from(ui_canvas.fattoriSelezionati).map(fattore => (
                    <div className="min-w-[100px] bg-lightgray-d dark:bg-darkgray-d p-2">
                        {fattore}
                    </div>
                ))}
            </div>

            <div>
                <button
                    onClick={() => setMostraObiettiviAgenda2030(!mostraObiettiviAgenda2030)}
                    className="text-center text-white p-2 w-full mt-2"
                >
                    {mostraObiettiviAgenda2030 ? t('common.nascondi') : t('common.scegli')}  {t('ModuloFattoriEsg.FormAggiungiObiettiviAgenda2030Fattori.gliObbietviDellaAzeinda')} {Array.from(ui_canvas.fattoriSelezionati).length} {t('ModuloFattoriEsg.BloccoFattore.fattori')}
                </button>
            </div>

            {mostraObiettiviAgenda2030 &&
                <div>

                    <div className="grid grid-cols-2 md:grid-cols-4 lg:md:grid-cols-6 mt-4">
                        {obiettiviAgenda2030.map((obiettivo) => (
                            <div
                                onClick={() => ui_canvas.toggleObiettivoSelezionato(obiettivo.id)}
                                className={`cursor-pointer duration-100 ${Array.from(ui_canvas.obiettiviAgenda2030Selezionati).includes(obiettivo.id) && 'border-4 border-accent brightness-[1.2]'}`}
                            >
                                <div
                                    className="text-white p-2 flex items-center gap-2 h-full"
                                    style={{ backgroundColor: obiettivo.colore }}
                                >
                                    <div className="w-[30px]">
                                        <img
                                        src={`${config.BACKEND_URI}icone_obiettivi_2030/${obiettivo.immagine}`}
                                        alt={obiettivo.descrizione}
                                        />
                                    </div>
                                    <p>{obiettivo.nome}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="mt-2">
                        {fattori_esg.collegandoFattoreESGObiettivi2030 ?
                            <SpinnerButton />
                            :
                            <button
                                onClick={() => handleSettaObiettivi()}
                                className="text-center text-white p-2 w-full"
                            >
                                {t('common.aggiungi')} {Array.from(ui_canvas.obiettiviAgenda2030Selezionati).length} {t('ModuloFattoriEsg.FormAggiungiObiettiviAgenda2030Fattori.obiettivi')} {Array.from(ui_canvas.fattoriSelezionati).length} {t('ModuloFattoriEsg.BloccoFattore.fattori')}
                            </button>
                        }
                    </div>

                </div>
            }

        </div>
    )
})

export default AggiungiObiettiviAgenda2030FattoriForm;