import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { IBilancio, IBilancioRendicontabile } from '../../types'

//Components
import { FaEllipsisV, FaLink } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import BilancioContextMenu from './BilancioContextMenu'
import useOttieniPrivilegioModulo from '../../../../hooks/useOttieniPrivilegioModulo'
import { useStores } from '../../../../hooks/useStores'

const BilancioTile: React.FC<{ bilancio: IBilancio | IBilancioRendicontabile, modifica?: boolean }> = ({ bilancio, modifica = true }) => {

  const privilegio = useOttieniPrivilegioModulo()
  const { t } = useTranslation();
  const styles = {
    wrapper: `
        bg-white dark:bg-darkgray-dd p-4 rounded-sm shadow-xl flex flex-col overflow-hidden text-center
      `,
  }
  const [contextMenu, setContextMenu] = React.useState(false)

  const toggleContextMenu = () => {
    //Il timeout permette di far comparire il context menu dopo che l'utente ha cliccato
    setTimeout(() => {
      setContextMenu(!contextMenu)
    }, 1)
  }

  document.addEventListener('click', () => {
    if (contextMenu) {
      setContextMenu(false)
    }
  }, { once: true })

  const { moduli } = useStores()

  return (
    <div className={styles.wrapper}>
      {privilegio >= 3 && modifica &&
        <div className="cursor-pointer relative p-1 self-end" onClick={() => toggleContextMenu()}>
          <FaEllipsisV className="hover:opacity-70" />
          {contextMenu && <BilancioContextMenu bilancio={bilancio as IBilancio} />}
        </div>
      }
      <h4 className="font-bold hover:underline mb-4">
        <span className="text-xl">{t('ModuloFattoriEsg.BilancioTile.bilancioDiSostenibilita')} </span>
        <span className="text-accent dark:text-dark-accent text-xl">
          {bilancio.anno}
        </span>
      </h4>
      <p className='mb-2'>{bilancio.descrizione}</p>
      <Link to={modifica ? `/app/modulo/${moduli.moduloAttivo?.codice}/${bilancio.anno}` : `/app/modulo/bilanci/${bilancio.anno}`} className="flex items-center gap-2 self-center">
        <button className='text-white uppercase p-1 rounded'>{t('ModuloFattoriEsg.BilancioTile.rendiconta')} </button>
      </Link>
    </div>
  )
}

export default BilancioTile;
