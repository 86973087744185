import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { ITemaMaterialitaAnno } from '../../types';
import { useModuloESGStores } from '../../context/useModuloESGStores';
import { observer } from 'mobx-react';
import ReactEcharts from "echarts-for-react"

//Components
import TextLoader from '../../../../components/common/TextLoader';

const GraficoMaterialitaBilancio: React.FC<{ temiMaterialita: ITemaMaterialitaAnno[] }> = observer(({ temiMaterialita }) => {
    const { t } = useTranslation();
    const { bilanci } = useModuloESGStores()

    let chart = {}

    const data = [
        [
            {
                value: [0, 0, 0, ''],
                itemStyle: { color: 'blue' },
                label: {
                    show: false,
                    formatter: ``,
                },
                descrizione: ''
            }
        ]
    ];

    temiMaterialita.forEach(tema => data[0].push(
        {
            value: [
                tema.rilevanza_azienda_anno,
                tema.rilevanza_stakeholder_anno,
                50000000
            ],
            itemStyle: { color: tema.colore },
            label: {
                show: true,
                formatter: `${tema.nome}`,
            },
            descrizione: tema.descrizione_anno
        }
    ))

    //definisce i parametri del grafico
    chart = {
        yAxis: {
            name:  t('ModuloFattoriEsg.GraficoMaterialitaBilancio.valorePerGliStakeholder'),
            nameLocation: 'middle',
            nameGap: 30,
            scale: true
        },
        xAxis: {
            name:  t('ModuloFattoriEsg.GraficoMaterialitaBilancio.valorePerAzienda'),
            nameLocation: 'middle',
            nameGap: 30,
        },
        tooltip: {
            formatter: function (tema: any) {
                return `<div style="max-width: 250px; white-space: pre-wrap;">${tema.data.descrizione}</div>`;
            }
        },
        toolbox: {
            show: true,
            orient: 'horizontal',
            feature: {
                dataView: {
                    readOnly: false,
                    title: 'Dati grezzi',
                },
                saveAsImage: {
                    name: `matrice_materialita_${bilanci.bilancioAttivo?.anno}`,
                    title: 'Salva come immagine'
                }
            }
        },
        series: [
            {
                data: data[0],
                type: 'scatter',
                symbolSize: function (data: any) {
                    return Math.sqrt(data[2]
                    ) / 5e2;
                },
                emphasis: {
                    focus: 'self'
                },
                labelLayout: {
                    y: 20,
                    align: 'center',
                    hideOverlap: true,
                    moveOverlap: 'shiftX'
                },
                labelLine: {
                    show: true,
                    length2: 5,
                    lineStyle: {
                        color: '#bbb'
                    }
                },
                label: {
                    show: true,
                    formatter: function (param: any) {
                        return `${param.data[3]}`
                    },
                    minMargin: 10,
                    position: 'top'
                }
            }
        ]

    };

    return (
        <div>
            {bilanci.gettingBilancioAttivo ?
                <div className="font-6xl">
                    <TextLoader />
                </div>
                :
                <ReactEcharts className="md:min-h-[400px]" option={chart} />
            }
        </div>
    )
})

export default GraficoMaterialitaBilancio;
