import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useModuloESGStores } from '../context/useModuloESGStores';
import { observer } from 'mobx-react-lite'
import { IFattoreESG } from '../types'
import { Link, useLocation } from 'react-router-dom';

//Components
import TextLoader from '../../../components/common/TextLoader';
import { FaBook } from 'react-icons/fa';
import { config } from '../../../config';

const InformativaFattoreESG: React.FC<{ loader: 'bilanci' | 'fattori_esg' }> = observer(({ loader }) => {

  const { fattori_esg, bilanci } = useModuloESGStores();
  const { t } = useTranslation();

  const [fattore, setFattore] = useState<IFattoreESG | null>();

  const location = useLocation()

  useEffect(() => {
    setFattore(null);
  }, [location.pathname])

  useEffect(() => {
    if (loader === 'bilanci') {
      setFattore(bilanci?.fattoreBilancioAttivo as IFattoreESG);
    }
    if (loader === 'fattori_esg') {
      setFattore(fattori_esg?.fattoreESGAttivo as IFattoreESG);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bilanci.fattoreBilancioAttivo, fattori_esg.fattoreESGAttivo])

  return (
    <div className="grow flex flex-col">
      <h2 className="rounded-sm bg-accent dark:bg-dark-accent text-white p-2 flex items-center gap-1">
        <FaBook />
        <span>{t('ModuloFattoriEsg.InformativeFattoriEsg.SchedaInformativa')} <span className="font-bold">"{fattore ? fattore?.nome : <span className="mx-1"><TextLoader width={30} /></span>}"</span></span>
      </h2>
      <div className="p-4 bg-white dark:bg-darkgray-dd shadow-xl grow">
        <div className="flex items-center justify-between gap-2">
          <div>
            <p className="font-bold text-xs text-accent dark:text-dark-accent">{fattore ? fattore.codice : <TextLoader width={100} />}</p>
            <h2 className="font-bold text-xl">{fattore ? `${fattore.nome} ${fattore.nome_esteso && '| ' + fattore.nome_esteso}` : <TextLoader width={200} />}</h2>
          </div>
        </div>

        <hr className="my-2 border border-lightgray-d dark:border-darkgray-d" />

        <p className="whitespace-pre-line">{fattore ? `${fattore.descrizione ? fattore.descrizione : t('ModuloFattoriEsg.InformativeFattoriEsg.nessunaDescrizione')}` : <TextLoader />}</p>

        {fattore && fattore.obiettivi_agenda2030 &&
          fattore.obiettivi_agenda2030.length > 0 &&
          <div>
            <hr className="my-2 border border-lightgray-d dark:border-darkgray-d" />
            <p className="mb-2">{ t('ModuloFattoriEsg.InformativeFattoriEsg.listaObiettivi') } </p>
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6">
              {fattore.obiettivi_agenda2030.map((obiettivo) => (
                <Link
                  to={`/app/modulo/agenda_2030/${obiettivo.id}`}
                  className="text-white p-2 flex items-center gap-2 h-full"
                  style={{ backgroundColor: obiettivo.colore }}
                >
                  <div className="w-[30px]">
                    <img src={`${config.BACKEND_URI}icone_obiettivi_2030/${obiettivo.immagine}`} alt={obiettivo.descrizione} />
                  </div>
                  <p>{obiettivo.nome}</p>
                </Link>
              ))}
            </div>
          </div>
        }
      </div>
    </div>
  )
})

export default InformativaFattoreESG;
