import React from 'react'
import { useTranslation } from 'react-i18next'
//Data
import { IModulo } from '../../types'
import { useStores } from '../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../hooks/useOttieniPrivilegioModulo'

//Components
import Dialogue from '../../components/common/Dialogue'
import ModuloForm from './ModuloForm'
import ModuloPrivilegiForm from '../gestionePrivilegi/ModuloPrivilegiForm'

const ModuloContextMenu: React.FC<{ modulo: IModulo }> = observer(({ modulo }) => {
  const { t } = useTranslation();

  const privilegio = useOttieniPrivilegioModulo();
  const { ui, gestioneModuli } = useStores();

  const handleDisattivaModulo = () => {
    gestioneModuli.toggleAttivazioneModulo(modulo)
  }

  const handleEliminaModulo = () => {

    gestioneModuli.eliminaModulo(modulo.codice as string).then(() => {

      if (gestioneModuli.eliminaModuloError) {

        ui.mountDialogue(<Dialogue testo={gestioneModuli.eliminaModuloError} />)

      } else {

        ui.mountDialogue(<Dialogue testo={"Modulo eliminato con successo"} />)

      }

    });

  }

  return (
    <div className="absolute shadow-lg border border-lightgray-dd bg-white dark:bg-black p-3 right-0 dark:border-0 z-10">
      <ul className="whitespace-nowrap flex flex-col gap-2">
        {privilegio >= 3 && <li onClick={() => ui.mountAsideModal(<ModuloForm modulo={modulo} />)}>{t('ModuloGestioneModuli.ContextMenu.modificaModulo')}</li>}
        {privilegio >= 3 && <li onClick={() => handleDisattivaModulo()}>{modulo.attivo ? t('ModuloGestioneModuli.ContextMenu.disattiva'): t('ModuloGestioneModuli.ContextMenu.attiva')} {t('ModuloGestioneModuli.ContextMenu.modulo')}</li>}
        {privilegio >= 5 && <li onClick={() => ui.mountAsideModal(<ModuloPrivilegiForm modulo={modulo} />)}>{t('ModuloGestioneModuli.ContextMenu.modificaPremessi')}</li>}
        {
          privilegio >= 4 && <li
            className="text-red dark:text-dark-red"
            onClick={() => ui.mountDialogue(
              <Dialogue
                testo={`Sei sicuro di voler eliminare il modulo ${modulo.nome}? Tutti i gruppi utenti che sono assicati al modulo non potranno più utilizzarlo.`}
                primaryCta={`Elimina il modulo ${modulo.nome}`}
                primaryCtaColor="bg-red dark:bg-dark-red"
                secondaryCta="Annulla"
                secondaryCtaColor="bg-white dark:bg-darkgray-dd !text-text dark:!text-dark-text"
                onConfirm={() => handleEliminaModulo()}
              />)}
          >
            {t('ModuloGestioneModuli.ContextMenu.eliminaModulo')}</li>
        }
      </ul>
    </div>
  )
})

export default ModuloContextMenu;