import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { IPrivilegio } from '../../types'
import { useStores } from '../../hooks/useStores'
import { observer } from 'mobx-react-lite'


//Components
import Dialogue from '../../components/common/Dialogue'
import PrivilegiForm from './PrivilegiForm'
import useOttieniPrivilegioModulo from '../../hooks/useOttieniPrivilegioModulo'

const PrivilegioContextMenu: React.FC<{ privilegioTile: IPrivilegio }> = observer(({ privilegioTile }) => {
  const { t } = useTranslation();

  const privilegio = useOttieniPrivilegioModulo();
  const { ui, gestionePrivilegi } = useStores();

  const handleEliminaPrivilegio = () => {

    gestionePrivilegi.eliminaPrivilegio(privilegioTile.valore).then(() => {

      if (gestionePrivilegi.eliminaPrivilegioError) {

        ui.mountDialogue(<Dialogue testo={gestionePrivilegi.eliminaPrivilegioError} />)

      } else {

        ui.mountDialogue(<Dialogue testo={t('ModuloGestionePrivilegi.ContextMenu.privilegioEliminato')} />)

      }

    });

  }

  return (
    <div className="absolute shadow-lg border border-lightgray-dd bg-white dark:bg-black p-3 right-0 dark:border-0">
      <ul className="whitespace-nowrap flex flex-col gap-2">
        {privilegio >= 3 && <li onClick={() => ui.mountAsideModal(<PrivilegiForm privilegio={privilegioTile} />)}>{t('ModuloGestionePrivilegi.ContextMenu.modificaPrivilegio')}</li>}
        {privilegio >= 4 &&
          <li
            className="text-red dark:text-dark-red"
            onClick={() => ui.mountDialogue(
              <Dialogue
                testo={`${t('ModuloGestionePrivilegi.ContextMenu.messaggioConfirmazione')} ${privilegioTile.nome}`}
                primaryCta={`${t('ModuloGestionePrivilegi.ContextMenu.messsagioElinimaPrivilegio')} ${privilegioTile.nome}`}
                primaryCtaColor="bg-red dark:bg-dark-red"
                secondaryCta={t('common.annula')}
                secondaryCtaColor="bg-white dark:bg-darkgray-dd !text-text dark:!text-dark-text"
                onConfirm={() => handleEliminaPrivilegio()}
              />)}
          >
            {t('ModuloGestionePrivilegi.ContextMenu.eliminaPrivilegio')}
          </li>
        }
      </ul>
    </div>
  )
})

export default PrivilegioContextMenu;