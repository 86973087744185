import axios from 'axios'
import { config } from '../../../../config'
import { IBusinessUnitCategoria } from '../../types'

//Riceve lista privilegi senza parent
export const getBusinessUnitCategoriaLista = () => {
    return axios.get(`${config.API_URI}business_unit_categorie`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceve singolo businessUnitCategoria
export const getBusinessUnitCategoria = (nome: string) => {
    return axios.get(`${config.API_URI}business_unit_categorie/${nome}`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Crea un businessUnitCategoria
export const creaBusinessUnitCategoria = (businessUnitCategoria: IBusinessUnitCategoria) => {
    return axios.post(`${config.API_URI}business_unit_categorie`, {
        nome: businessUnitCategoria.nome,
        descrizione: businessUnitCategoria.descrizione,
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Modifica un businessUnitCategoria
export const modificaBusinessUnitCategoria = (businessUnitCategoria: IBusinessUnitCategoria) => {
    return axios.put(`${config.API_URI}business_unit_categorie/${businessUnitCategoria.nome_originale ? businessUnitCategoria.nome_originale : businessUnitCategoria.nome}`, {
        nome: businessUnitCategoria.nome,
        descrizione: businessUnitCategoria.descrizione,
        nome_originale: businessUnitCategoria.nome_originale,
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Elimina un businessUnitCategoria
export const eliminaBusinessUnitCategoria = (nome: string) => {
    return axios.delete(`${config.API_URI}business_unit_categorie/${nome}`, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}


