import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../../../../hooks/useStores'
import { useModuloESGStores } from '../../../context/useModuloESGStores'
import { IFattoreESG } from '../../../types'
import { lista_sottofattori_disattivi } from '../../../rest/fattori_esg'
import { collegaFattore } from '../../../rest/bilanci'

//Components
import Dialogue from '../../../../../components/common/Dialogue'
import BottoneCanvasGenericoAzione from '../common/BottoneCanvasGenericoAzione'
import Spinner from '../../../../../components/common/Spinner'

const ListaFattoriDisattivati: React.FC<{ fattore: IFattoreESG }> = ({ fattore }) => {

    const [gettingListaSottoFattoriAttivabili, setGettingListaSottoFattoriAttivabili] = useState<boolean>(false)
    const [listaSottoFattoriAttivabili, setListaSottoFattoriAttivabili] = useState<IFattoreESG[]>([])

    const { ui } = useStores()
    const { t } = useTranslation();

    const { bilanci } = useModuloESGStores()


    useEffect(() => {
        setGettingListaSottoFattoriAttivabili(true)
        bilanci.bilancioAttivo &&
            lista_sottofattori_disattivi(bilanci.bilancioAttivo.anno, fattore.codice).then((res) => {
                setListaSottoFattoriAttivabili(res.data.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setGettingListaSottoFattoriAttivabili(false)
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handlecollegaFattore = (codice_fattore: string) => {
        collegaFattore(bilanci.bilancioAttivo?.anno as number, codice_fattore).then((res) => {

            ui.mountDialogue(<Dialogue testo={t('ModuloFattoriEsg.ListaFattoriDisattivi.fattoreAttivoSucesso')} />)

            bilanci.getBilancio(bilanci.bilancioAttivo?.anno as number)

        }).catch((err) => {

            ui.mountDialogue(<Dialogue testo={t('ModuloFattoriEsg.ListaFattoriDisattivi.erroreDurante')} />)

        })
    }

    return (
        <div className="flex flex-col gap-2 items-center justify-center w-full">
            {
                gettingListaSottoFattoriAttivabili ?
                    <Spinner colore="#002e5a" />
                    :
                    <>
                        {
                            listaSottoFattoriAttivabili && listaSottoFattoriAttivabili.length > 0 ?

                                listaSottoFattoriAttivabili.map((sottoFattore: IFattoreESG) => (
                                    <div className="relative w-full" onClick={() => handlecollegaFattore(sottoFattore.codice)}>
                                        <BottoneCanvasGenericoAzione tipo="azione" icona="FaPlus" testo={sottoFattore.nome_esteso} />

                                        <div className="absolute right-[-1px] top-[-10px] bg-accent dark:bg-dark-accent text-white text-[10px] p-[5px]"> {sottoFattore.tipo} </div>
                                    </div>
                                ))

                                :

                                <div>{t('ModuloFattoriEsg.ListaFattoriDisattivi.standardUtilizato')}</div>
                        }
                    </>
            }
        </div>
    )
}


export default ListaFattoriDisattivati;