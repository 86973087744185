import axios from 'axios'
import { config } from '../config'


//Pre processa lo uri della risorsa
const processaURI = (percorso: string) => {
    return encodeURI(`${config.API_URI + percorso}`)
}

//Compila l'header della richiesta autenticata
const processaHeader = (headerAggiuntivi?: object) => {
    return {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        ...headerAggiuntivi,
    }
}

//Riceve la lista di tutti i moduli
export const get = (percorso: string, headerAggiuntivi?: object) => {
    return axios.get(processaURI(percorso), {
        headers: processaHeader(headerAggiuntivi)
    })
}

//Crea un modulo
export const crea = (percorso: string, payload?: object, headerAggiuntivi?: object) => {
    return axios.post(processaURI(percorso), payload, {
        headers: processaHeader(headerAggiuntivi)
    })
}

//Modifica un modulo
export const modifica = (percorso: string, payload: object, headerAggiuntivi?: object) => {
    return axios.put(processaURI(percorso), payload, {
        headers: processaHeader(headerAggiuntivi)
    })
}

//Elimina un modulo
export const elimina = (percorso: string, headerAggiuntivi?: object) => {
    return axios.delete(processaURI(percorso), {
        headers: processaHeader(headerAggiuntivi)
    })
}