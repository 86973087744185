import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { IFattoreESG } from '../../../types'

//Components
import { FaPlus } from 'react-icons/fa'
import ListaFattoriDisattivati from './ListaFattoriDisattivati'
import BottoneCanvasGenerico from '../common/BottoneCanvasGenerico'
import BottoneCanvasGenericoAzione from '../common/BottoneCanvasGenericoAzione'

const BottoneAttribuisciFattore: React.FC<{ fattore: IFattoreESG }> = ({ fattore }) => {
    const { t } = useTranslation();

    const [bottoniForm, setBottoniForm] = useState<boolean>(false)


    const handleSetBottoniForm = () => {
        if (!bottoniForm) {
            setBottoniForm(true)
        }
    }


    return (
        <div className="w-[250px]" onClick={() => handleSetBottoniForm()}>

            {
                bottoniForm ?
                    <BottoneCanvasGenerico effettoHover={false}>
                        <div className="flex flex-col gap-2 w-full">

                            {bottoniForm && <ListaFattoriDisattivati fattore={fattore} />}

                            <div onClick={() => setBottoniForm(false)}>
                                <BottoneCanvasGenericoAzione tipo="annulla" testo= {t('common.annulla')} />
                            </div>

                        </div>
                    </BottoneCanvasGenerico>
                    :
                    <BottoneCanvasGenerico>
                        <div className="flex gap-2 items-center">
                            <span>
                                {fattore.numero_sotto_fattori_disattivi}
                                {fattore.numero_sotto_fattori_disattivi === 1 ?  t('ModuloFattoriEsg.BottoneAttribuisciFattore.fattorePuoAggiunto'):  t('ModuloFattoriEsg.BottoneAttribuisciFattore.fattoreNonPuoAggiunto')}
                            </span>
                            <FaPlus />
                        </div>
                    </BottoneCanvasGenerico>
            }
        </div>
    )
}

export default BottoneAttribuisciFattore;
