import React, { useEffect, useState } from 'react'

//Data
import { useStores } from '../../hooks/useStores'
import { observer } from 'mobx-react-lite'

//Components
import { FaInfoCircle, FaPlus, FaSlidersH } from 'react-icons/fa'
import Spinner from '../common/Spinner'
import AttivitaTile from './AttivitaTile'
import FiltriAttivita from './FiltriAttivita'

const ArchivioAttivita: React.FC<{ caricaAltreAttivo?: boolean }> = observer(({ caricaAltreAttivo = true }) => {

  const { attivita } = useStores()

  //Numero di altre attività per pagina
  const attivitaPerPagina = 5

  //Filtri
  const [filtri, setFiltri] = useState<boolean>(false)

  //Carica le prime attività di default
  useEffect(() => {

    attivita.ripristinaListaAttivita()
    aggiungiAttivita(0, attivitaPerPagina)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Carica altre attività
  const aggiungiAttivita = (offset: number, limit: number) => {
    attivita.getListaAttivita(offset, limit, attivita.stringaFiltri)
  }

  const chiudFiltri = () => {
    attivita.ripristinaFiltri()
    attivita.ripristinaListaAttivita()
    attivita.getListaAttivita(attivita.listaAttivita.length, 8)
    setFiltri(false)
  }

  return (
    <div>

      <div className="flex items-center justify-end my-2">

        {filtri ?
          <div className="cursor-pointer p-1 hover:bg-white rounded-sm rotate-45" onClick={() => chiudFiltri()}>
            <FaPlus />
          </div>
          :
          <div className="cursor-pointer p-1 hover:bg-white rounded-sm" onClick={() => setFiltri(true)} >
            <FaSlidersH />
          </div>
        }

      </div>

      {filtri && <FiltriAttivita />}

      <div className="flex flex-col">
        {
          attivita.listaAttivita.length > 0 ?
            <>
              {
                attivita.listaAttivita.map((attivita) => (
                  <AttivitaTile key={attivita.id} attivita={attivita} />
                ))
              }
              {!attivita.limiteRaggiunto &&
                <div className="text-center py-4 cursor-pointer font-bold hover:text-accent" onClick={() => aggiungiAttivita(attivita.listaAttivita.length, attivitaPerPagina)}>
                  <span className="flex w-full items-center justify-center">{attivita.gettingAttivita ? <Spinner colore='accent' /> : 'Carica altre'} </span>
                </div>
              }
            </>
            :
            <p className="text-center font-2xl pt-2 flex items-center justify-center gap-2 opacity-70">
              {attivita.gettingAttivita ?
                <Spinner colore="accent" /> :
                <>
                  <FaInfoCircle />
                  <span>Nessuna attività trovata per il tuo account</span>
                </>
              }
            </p>
        }
      </div>

    </div>
  )
})

export default ArchivioAttivita;