import React from 'react'

import { INotizia } from '../../../types'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const TileNotiziaInEvidenza: React.FC<{ notizia: INotizia, logged: boolean }> = ({ notizia, logged }) => {

    const { t } = useTranslation()

    const modificatoreURL = logged ? '' : '/login'

    const urlDefinitivo = `notizia${modificatoreURL}/${notizia.id}`

    return (
        <div className="bg-white p-5 shadow-2xl">
            <div>
                <p className="font-bold text-accent">{t('ModuloNotizie.State.featured')}</p>
                <h2 className="text-2xl font-bold">{notizia.titolo}</h2>
                
                {notizia.sotto_titolo && <h3 className="text-lg">{notizia.sotto_titolo}</h3>}
                
                {
                    notizia.corpo &&
                    <p dangerouslySetInnerHTML={{
                        __html: notizia.corpo.substring(0, 120)
                    }} />
                }

                <Link to={urlDefinitivo}>
                    {t('ModuloNotizie.Actions.read')}
                </Link>
                
            </div>
        </div>
    )
}

export default TileNotiziaInEvidenza;