import React from 'react'
import IconaDinamica from '../../../../../components/common/IconaDinamica'

const BottoneCanvasGenericoAzione: React.FC<{ testo?: string, icona?: string, tipo: 'azione' | 'annulla' }> = ({ testo, icona, tipo }) => {

    const styles = {
        bottoneAzione: `rounded-sm bg-[rgba(0,0,0,0)] border border-accent flex gap-2
                        items-stretch justify-between dark:bg-[rgba(0,0,0,0)] w-full
                        dark:text-dark-accent dark:border-dark-accent text-left hover:border-l-4`,
        bottoneAnnulla: `border-red text-red dark:border-dark-red dark:text-dark-red`
    }

    return (
        <button className={`${styles.bottoneAzione} ${tipo === 'annulla' && styles.bottoneAnnulla}`}>
            {testo && <span className="p-2 grow text-[12px] leading-5 font-bold">{testo}</span>}
            {
                icona && <div className="p-2 flex items-center justify-center">
                    <IconaDinamica nome={icona} />
                </div>
            }
        </button>
    )
}

export default BottoneCanvasGenericoAzione;