import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../hooks/useOttieniPrivilegioModulo'

//Components
import AggiungiGruppo from './gruppi/AggiungiGruppo'
import { Link } from 'react-router-dom'
import { BiUserCircle } from 'react-icons/bi'

import PanoramicaCentrale from '../amministrazioneUtentiAccessi/Panoramiche/PanoramicaCentrale'
import PanoramicaGestioneGruppiUtenti from '../amministrazioneUtentiAccessi/Panoramiche/PanoramicaGestioneGruppiUtenti'
import PanoramicaGestioneGruppiGruppi from '../amministrazioneUtentiAccessi/Panoramiche/PanoramicaGestioneGruppiGruppi'
import PanoramicaGestioneGruppiLingue from '../amministrazioneUtentiAccessi/Panoramiche/PanoramicaGestioneGruppiLingue'
import { RiGroup2Line } from 'react-icons/ri'
import { TbAlertCircle } from 'react-icons/tb'

import PannelloGruppiUtentiSenzaGruppo from './Pannelli/PannelliGruppi/PannelloGruppiUtentiSenzaGruppo'
import PannelloGruppiSenzaUtenti from './Pannelli/PannelliGruppi/PannelloGruppiSenzaUtenti'
import PannelloGruppiConUtenti from './Pannelli/PannelliGruppi/PannelloGruppiConUtenti'

export const PannelloGruppi = observer(() => {
    const { t } = useTranslation();
    const { gestioneUtenti, gestioneLingue } = useStores();

    //Get users
    useEffect(() => {
        gestioneUtenti.getUtentiSenzaGruppo();
        gestioneUtenti.getUtenti();
        gestioneUtenti.getGruppi();
        gestioneLingue.getLinguaLista()
    }, [])

    return (
        <div>
            <div className='mb-4 text-center lg:text-start'>
                <button className='rounded w-72	 h-6 mb-5'>
                    <Link to="../amministrazione_utenti_e_accessi" className="text-white  dark:text-white flex flex-col justify-center h-full uppercase">
                        {t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.pannelloPanoramica')}
                    </Link>
                </button>
            </div>

            <div className='text-center mb-6 p-6 relative'>
                <h1 className='text-accent dark:text-dark-accent font-extrabold text-xl lg:text-4xl uppercase'>{t('ModuloGestioneUtenti.PannelloUtentiSenzaGruppo.gestioneGruppi')}</h1>
                <p className="absolute text-xs lg:text-base top-[80%] right-[25%] left-[25%] text-center">{t('ModuloGestioneUtenti.PannelloUtentiSenzaGruppo.descrPannello')}</p>
            </div>

            <PanoramicaCentrale stili={true}
                componenteUno={<PanoramicaGestioneGruppiUtenti utente={gestioneUtenti.utenti} />}
                componenteDue={<PanoramicaGestioneGruppiGruppi gruppo={gestioneUtenti.gruppi} />}
                componenteTre={<PanoramicaGestioneGruppiLingue utente={gestioneUtenti.utenti} />}
                Icona1={<RiGroup2Line className='h-full w-full text-black dark:text-dark-accent' />}
                Icona2={<BiUserCircle className='h-full w-full text-black dark:text-dark-accent' />}
                Icona3={<TbAlertCircle className='h-full w-full text-black dark:text-dark-accent' />}
                icona4={null}
            />

            <PannelloGruppiUtentiSenzaGruppo />

            <PannelloGruppiSenzaUtenti gruppo={gestioneUtenti.gruppi} />

            <PannelloGruppiConUtenti gruppo={gestioneUtenti.gruppi} />


        </div>
    )
})
export default PannelloGruppi
