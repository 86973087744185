import axios from 'axios'
import { config } from '../../../../../config'
import { IIndicatore } from '../../../types'

//Collega un indicatore
export const collegaIndicatore = (indicatore: IIndicatore, bilancio_anno: number, fattore_codice1: string, business_unit: string, fattore_codice2?: string,) => {
    
    let url = ''

    if(fattore_codice2) {
        url = `${config.API_URI}rendicontazione/${bilancio_anno}/business_unit/${business_unit}/esg/indicatore/${fattore_codice1}/${fattore_codice2}`
    } else {
        url = `${config.API_URI}rendicontazione/${bilancio_anno}/business_unit/${business_unit}/esg/indicatore/${fattore_codice1}`
    }

    return axios.post(url, {
        valore_testuale: indicatore?.valore_testuale,
        valore_numerico: indicatore?.valore_numerico,
        valore_booleano: indicatore?.valore_booleano,
        valore_risposta_multipla: indicatore?.valore_risposta_multipla,
        unita_misura: indicatore?.unita_misura,
        note: indicatore?.note,
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Modifica un indicatore
export const modificaIndicatore = (indicatore: IIndicatore, bilancio_anno: number, business_unit_nome: string, fattore_codice1: string, fattore_codice2?: string,) => {

    let url = ''

    if (fattore_codice2) {
        url = `${config.API_URI}rendicontazione/${bilancio_anno}/business_unit/${business_unit_nome}/esg/indicatore/${fattore_codice1}/${fattore_codice2}`
    } else {
        url = `${config.API_URI}rendicontazione/${bilancio_anno}/business_unit/${business_unit_nome}/esg/indicatore/${fattore_codice1}`
    }
    
    return axios.put(url, {
        valore_testuale: indicatore?.valore_testuale,
        valore_numerico: indicatore?.valore_numerico,
        valore_booleano: indicatore?.valore_booleano,
        valore_risposta_multipla: indicatore?.valore_risposta_multipla,
        unita_misura: indicatore?.unita_misura,
        note: indicatore?.note,
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Valida un indicatore
export const validaIndicatore = (bilancio_anno: number, business_unit_nome: string, fattore_codice1: string, fattore_codice2?: string,) => {

    let url = ''
    
    if (fattore_codice2) {
        url = `${config.API_URI}rendicontazione/${bilancio_anno}/business_unit/${business_unit_nome}/esg/valida/indicatore/${fattore_codice1}/${fattore_codice2}`
    } else {
        url = `${config.API_URI}rendicontazione/${bilancio_anno}/business_unit/${business_unit_nome}/esg/valida/indicatore/${fattore_codice1}`
    }

    return axios.put(url, null, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Valida un indicatore
export const invalidaIndicatore = (bilancio_anno: number, business_unit_nome: string, fattore_codice1: string, fattore_codice2?: string,) => {

    let url = ''

    if (fattore_codice2) {
        url = `${config.API_URI}rendicontazione/${bilancio_anno}/business_unit/${business_unit_nome}/esg/valida/indicatore/${fattore_codice1}/${fattore_codice2}`
    } else {
        url = `${config.API_URI}rendicontazione/${bilancio_anno}/business_unit/${business_unit_nome}/esg/valida/indicatore/${fattore_codice1}`
    }

    return axios.delete(url, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Scollega un indicatore
export const eliminaIndicatore = (bilancio_anno: number, business_unit_nome: string, fattore_codice1: string, fattore_codice2?: string) => {

    let url = ''

    if (fattore_codice2) {
        url = `${config.API_URI}rendicontazione/${bilancio_anno}/business_unit/${business_unit_nome}/esg/indicatore/${fattore_codice1}/${fattore_codice2}`
    } else {
        url = `${config.API_URI}rendicontazione/${bilancio_anno}/business_unit/${business_unit_nome}/esg/indicatore/${fattore_codice1}`
    }

    return axios.delete(url, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}