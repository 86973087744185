export const en_modulo_gestione_utenti = {
    SchedaUtente: {
        titoloAdmin: 'Admin user',
        schedaUtente: 'User card',
        accountCreato: 'Account created on',
        gruppiUtenti: 'Groups you belong to',
        nessunoGruppiUtenti: ' You don\'t belong to any group',
        moduliUtenti: 'Modules you have access to',
        nessunoModuli: 'You don\'t have acccess to any module',
    },

    FormAggiungiUtentiGruppo: {
        aggiungiUtentiGruppo: 'Add users to the group',
        testoAggiungiUtenti: 'A group can contain multiple users. Each group can be associated with permissions to let users act on different modules of the application.',
        utenti: 'Users',
        selezioneUtenti: 'Select users',
        tuttiSelezionati: 'All users selected',
        selezioneTutti: 'Select all',
    },

    FormCreaGruppo: {
        creaNuovoGruppo: 'Create new group',
        messaggioNomeLimitizione: 'Cannot contain spaces, capital letters or special characters',
        gruppoCreatoSuccesso: 'Group successfully created',
        creaGruppo: 'Create group',
    },

    GruppoContextMenu: {
        gruppoEliminatoSucesso: 'Group successfully deleted',
        modificaGruppo: 'Edit group',
        eliminaGruppo: 'Delete group',
        mesaggioElimina: 'Are you sure you want to delete the group',
        messaggioCompleto: 'The users in this group will not be deleted, but they will lose the privileges associated with the group.',
        testoEliminaButtone: 'Delete the group',
    },

    GruppoTile: {
        utente: 'users',
        collegaUtente: 'Add new user',
    },

    ModificaGruppo: {
        testoModifica: 'Edit the group',
        placeHolder: 'es: group_1',
    },

    FormCreaUtenti: {
        creaUnUtente: 'Create an user ',
        selezioneGruppi: 'Select groups',
        tuttiGruppiSelezionati: 'All the groups selected',
        utenteCreatoSucesso: 'User succesfully created',
        creaUtente: 'Create user',
    },

    FormModificaUtente: {
        modificaUtente: 'Edit user',
        modificaUtenteText: 'Editing user',
        testoModifica: 'you will be able to change his personal data, the groups he belongs to and consequently the privileges he will get within the platform.',
        disabilitaTemporaneamente: 'Temporarily disable',
    },
    
    FormModificaContatti: {
        modificaEmail: 'Modifiy E-mail of User',
        modificaEmailText: 'You are modifiying E-mail of user ',
        testoModificaEmail: '',
        emailNonECorretta:'Emails are not same',
    },

    PannelloUtentiSenzaGruppo: {
        gruppiSenzaUtenti: 'Groups without users',
        gruppiConUtenti: 'Groups with users',
        nessunUtenteSenzaGruppo: 'No user without group',
        nessunGruppoSenzaUtenti: 'No group without user',
        nessunGruppoConUtenti: 'No group with user',
        gestioneGruppi: 'Group management',
        utentiConUnGruppo: 'With one group',
        utentiConDueGruppi: 'With two groups',
        utentiConMenoDiTreGruppi: 'With less than three groups',
        utentiConMenoDiSeiGruppi: 'With less than six groups',
        utentiConPiuDiSeiGruppi: 'With more than six groups',
        gruppiConUnSoloUtente: 'With one user',
        gruppiConMenoDiCinqueUtenti: 'With less than five users',
        gruppiConMenoDiDieciUtenti: 'With less than ten users',
        gruppiConOltreDieciUtenti: 'With more than ten users',
        nonVerificati: 'Unverified',
        verificati: 'Verified',
        utentiSenzaGruppo: 'Users without group',
        descrPannello: 'Manage groups and users who are part of them.'
    },

    PannelloPanoramicaUtenti: {
        titolo: 'Overview',
        panoramicaDidascalia: 'This overview provides an intuitive way to keep track of users, groups, requests, and activities. With a simple click on each item, you can delve into the topic and discover the details.',
        pannelloPanoramica: "Overview panel",
        cliccaIcona: 'Click on an icon to see the section details',
        descrizionePanoramica:'The overview provides an overview of basic information about requests, users, groups, and activities, providing a quick and effective way to understand status and trends in a specific system or context.',
        richieste: 'Requests',
        richiesta: 'Request',
        attivita: 'Activity',
        inSospeso: 'Pending',
        accettata: 'Accepted',
        accettate: 'Accepted',
        rifiutata: 'Rejected',
        rifiutate: 'Rejected',
        totale: 'Total',
        totali: 'Totals',
        inAttesa: 'Awaiting',
        diVerifica: 'Verification',
        senzaGruppo: 'Without group',
        senzaUtenti: 'Without users',
        attivitaGiorni: 'Activity in the last 30 days',
        utenteHa: 'User has',
        utentiHanno: 'Users have',
        fattoAzioni: 'Done actions',
        moduli: 'Forms',
        modulo: 'Form',
        utilizzati: 'Used',
        utilizzato: 'Used',
        azioniFatte: 'Actions done',
        azioneFatta: 'Action done',
        gestisciRichieste: 'Manage request',
        gestisciUtenti: 'Manage users',
        gestisciGruppi: 'Manage groups',
        gestisciAttivita: 'Manage activity',
        vistaRichieste: 'Click Manage Requests to manage pending requests',
        vistaUtenti: 'Click on Manage Users to learn more the user overview',
        vistaGruppi : 'Click on Manage Groups to learn more the groups overview',
        vistaAttivita: 'Click on Manage Activity to learn more the activities overview'
    },

    PannelloRichieste: {
        richiesteDaGestire: 'Requests to manage',
        richiestaDaGestire: 'Request to manage',
        richiesteAccettate: 'Accepted requests',
        richiestaAccettata: 'Accepted request',
        richiesteRifiutate:'Denied requests',
        richiestaRifiutata: 'Denied request',
        richiesteRicevute: 'Receipts requests',
        nessunoRichiesta: 'No pending request',
        nessunaRichiestaRifiutata:'No denied requests',
        richiesteGestite: 'Requests already managed',
        richiesteRifiutate: 'Denied requests',
        gestioneRichieste: 'Requests management',
        descrPannello: 'In this section you will find a history of your requests up to 90 days. You can manage all your requests, monitor those accepted and those rejected.',
    },

    PannelloUtenti: {
        utente: 'User',
        utenti: 'Users',
        gruppi: 'Group',
        gruppi: 'Groups',
        accessi: 'Accesses',
        trovati: 'Found',
        trovate: 'Found',
        trovata: 'Found',
        permessi: 'Permits',
        iPiuAttivi: 'The most active',
        utentiE : 'Users and',
        attivi: 'Active',
        attivo: 'Active',
        disattivi: 'Deactivated',
        disattivo: 'Deactivated',
        inattivi: 'Inactive',
        inattivo: 'Inactive',
        disabilitati: 'Disabled',
        disabilitato: 'Disabled',
        daAccettare: 'To confirm',
        senzaVerifica: 'Without verification',
        gestioneUtenti: 'Users management',
        utentiSenzaGruppi: 'Users without group',
        utenteSenzaGruppi: 'User without group',
        utentiInPiuGruppi: 'Users with more than one groups',
        utenteInPiuGruppi: 'User with more than one groups',
        utentiTrovati: 'users found',
        nessunoGruppoTrovato: 'No group found',
        aggiungiNuovoUtente: 'ADD A NEW USER',
        nessunDisabilitato : 'No disabled users found',
        nessunUtente : 'No users found',
        nessunSenzaGruppo : 'No users with no group found',
        nessunDaVerificare: 'No users to verify',
        descrPannello : 'Monitor and manage users. Assign them a group or ask them to verify their account.'
    },

    TileRichiestaUtente: {
        richiestaRicevuta: 'Request received on',
        acettataRichiesta: 'Accept request',
        riffutataRichiesta: 'Reject request',
    },

    FormRifiutaUtente: {
        rifiutaRichesta: 'Reject request for the user',
        rifiutaQuestaRichesta: 'Reject this request.',
        ragioneDelRifiuta: 'Reason for rejection',
        messaggioRagione: 'Specify the reason for rejection',
        statoRifuitato: 'The user has been rejected',
        confirmaRifuitato: 'Confirm rejection',
    },

    UtenteContextMenu: {
        utenteEliminatoSucesso: 'User successfully deleted',
        modificaUtente: 'Edit user',
        eliminaMessagio: 'Are you sure you want to delete the user',
        eliminaTestoMessagio: 'He will lose his account permanently and will no longer be able to log in. However, all the data he has entered into the platform will not be deleted.',
        eliminaUtente: 'Delete the user',
        eliminaUtenteButtone: 'Delete user',
    },

    TileUtente: {
        utenteDisabilitato: 'User temporarily disabled',
        registroIl: 'Signed up on',
        emailVerificata: 'E-mail verified',
        emailNonVerificat: 'E-mail not verified',
        utenteAttivo: 'Active User',
        utenteNonAttivo: 'Inactive User',
        utenteGoogle:'Google WorkSpace User',
        utenteAzureAdd:'Azure AD User',
        utenteIpe:'Ipè User',
        reinviaEmail:'Require account completion',
        LinguaUtilizzata:'Language Used:',
        nessunoGruppo:'No Group is connected'
    },
}