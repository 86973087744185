import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../../../../hooks/useStores'
import { useModuloESGStores } from '../../../context/useModuloESGStores'
import { IFattoreESG } from '../../../types'
import { eliminaFattoreESG } from '../../../rest/fattori_esg'

//Components
import { FaPen, FaPlus } from 'react-icons/fa'
import FattoreESGForm from '../../fattoriESG/FattoreESGForm'
import Dialogue from '../../../../../components/common/Dialogue'
import useOttieniPrivilegioModulo from '../../../../../hooks/useOttieniPrivilegioModulo'

const BloccoFattoreDefaultTabella: React.FC<{ righe: IFattoreESG[], colonne: IFattoreESG[], fattore: IFattoreESG }> = ({ righe, colonne, fattore }) => {


    const styles = {
        bottoneAggiungi: "bg-white dark:bg-black cursor-pointer border border-accent dark:border-dark-accent text-accent dark:text-dark-accent flex items-center justify-center p-1 hover:bg-accent dark:hover:bg-dark-accent hover:text-white duration-100",
    }

    const { ui } = useStores()
    const { t } = useTranslation();
    const { fattori_esg } = useModuloESGStores()
    const privilegi = useOttieniPrivilegioModulo()

    const handleEliminaFattore = (codice: string) => {
        eliminaFattoreESG(codice as string).then(() => {

            ui.mountDialogue(<Dialogue testo={t('ModuloFattoriEsg.BloccoFattoreDefaultTabella.eliminatoSucessoTesto')} />)

            fattori_esg.getFattoreESG(fattori_esg.fattoreESGAttivo?.codice as string)

        }).catch((err) => {

            ui.mountDialogue(<Dialogue testo={err.response.data.message ? err.response.data.message : 'Errore generico'} />)

        })
    }

    if (colonne.length === 0 && righe.length === 0) {
        return (
            <>
                {privilegi >= 2 &&
                    <div className="flex flex-col gap-2">
                        <button
                            className={`${styles.bottoneAggiungi} gap-2 justify-between`}
                            onClick={() => ui.mountAsideModal(<FattoreESGForm codice_padre={fattore.codice as string} tipo_fattore="colonna" />)}>
                            <span>{t('ModuloFattoriEsg.BloccoFattoreDefaultTabella.PrimaCollona')}</span> <FaPlus />
                        </button>
                        <button
                            className={`${styles.bottoneAggiungi} gap-2 justify-between`}
                            onClick={() => ui.mountAsideModal(<FattoreESGForm codice_padre={fattore.codice as string} tipo_fattore="riga" />)}>
                            <span>{t('ModuloFattoriEsg.BloccoFattoreDefaultTabella.primaRiga')}</span> <FaPlus />
                        </button>
                    </div>
                }
                <div className="mt-2">
                    {t('ModuloFattoriEsg.BloccoFattoreDefaultTabella.tabellaEVuoto')}
                </div>
            </>
        )
    }

    return (
        <div className="flex items-stretch tabella-generica-minificata ">
            <table>
                <thead>
                    <th>
                    </th>
                    {
                        colonne.length > 0 ?
                            colonne.map((colonna: IFattoreESG) => (
                                <th
                                    onMouseEnter={() => colonna.tipo_indicatore && ui.mountFollowMouse(<p>Prevede una rendicontazione di tipo {colonna.tipo_indicatore}</p>)}
                                    onMouseLeave={() => colonna.tipo_indicatore && ui.unmountFollowMouse()}
                                >
                                    <div className="flex items-center gap-2">
                                        <span className="text-xs">{colonna.nome} {colonna.unita_misura && `(${colonna.unita_misura})`}</span>
                                        <div className="flex items-center gap-1">
                                            {privilegi >= 3 &&
                                                <div className="ml-1 cursor-pointer hover:text-accent" onClick={() => ui.mountAsideModal(<FattoreESGForm scegli_tipo={false} tipo_fattore="colonna" fattore={colonna} codice_padre={colonna.codice_padre as string} />)}>
                                                    <FaPen size={8} />
                                                </div>
                                            }

                                            {privilegi >= 4 &&
                                                <div
                                                    className="rotate-45 text-red dark:text-accent-red cursor-pointer"
                                                    onClick={() => ui.mountDialogue(
                                                        <Dialogue
                                                            testo={`${t('ModuloFattoriEsg.BloccoFattoreDefaultTabella.eliminaCollona')} ${colonna.nome} ${t('ModuloFattoriEsg.BloccoFattoreDefaultTabella.dellaTabella')} ${fattore.nome}?`}
                                                            primaryCta={t('common.elimina')}
                                                            secondaryCtaColor="bg-red"
                                                            onConfirm={() => handleEliminaFattore(colonna.codice as string)}
                                                        />)}
                                                >
                                                    <FaPlus size={8} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </th>
                            ))
                            :
                            <th>
                                ${t('ModuloFattoriEsg.BloccoFattoreDefaultTabella.nessunaCollonaInserita')}
                            </th>
                    }

                    {privilegi >= 2 &&
                        <div className={styles.bottoneAggiungi} onClick={() => ui.mountAsideModal(<FattoreESGForm codice_padre={fattore.codice as string} tipo_fattore="colonna" />)}>
                            <FaPlus size={10} />
                        </div>
                    }
                </thead>
                <tbody>
                    {
                        righe.length > 0 ?
                            righe.map((riga: IFattoreESG) => (
                                <tr
                                    onMouseEnter={() => riga.tipo_indicatore && ui.mountFollowMouse(<p>Prevede una rendicontazione di tipo {riga.tipo_indicatore}</p>)}
                                    onMouseLeave={() => riga.tipo_indicatore && ui.unmountFollowMouse()}
                                >
                                    <td className="flex items-center justify-between gap-1">
                                        <span className="text-xs">{riga.nome} {riga.unita_misura && `(${riga.unita_misura})`}</span>

                                        <div className="flex items-center gap-1">
                                            {privilegi >= 3 &&
                                                <div className="cursor-pointer hover:text-accent" onClick={() => ui.mountAsideModal(<FattoreESGForm scegli_tipo={false} tipo_fattore="riga" fattore={riga} codice_padre={riga.codice_padre as string} />)}>
                                                    <FaPen size={8} />
                                                </div>
                                            }
                                            {privilegi >= 4 &&
                                                <div
                                                    className="rotate-45 text-red dark:text-accent-red cursor-pointer"
                                                    onClick={() => ui.mountDialogue(
                                                        <Dialogue
                                                            testo={`${t('ModuloFattoriEsg.BloccoFattoreDefaultTabella.eliminaRiga')} ${riga.nome} ${t('ModuloFattoriEsg.BloccoFattoreDefaultTabella.dellaTabella')} ${fattore.nome}?`}
                                                            primaryCta={t('common.elimina')}
                                                            secondaryCtaColor="bg-red"
                                                            onConfirm={() => handleEliminaFattore(riga.codice as string)}
                                                        />)}
                                                >
                                                    <FaPlus size={8} />
                                                </div>
                                            }
                                        </div>
                                    </td>
                                    {colonne.length > 0 ?
                                        colonne.map(() => (
                                            <th></th>
                                        ))
                                        :
                                        <th></th>
                                    }
                                </tr>
                            ))
                            :
                            <tr>{t('ModuloFattoriEsg.BloccoFattoreDefaultTabella.nessunaRigaInserita')}</tr>
                    }
                </tbody>

                {privilegi >= 2 &&
                    <div className={styles.bottoneAggiungi} onClick={() => ui.mountAsideModal(<FattoreESGForm codice_padre={fattore.codice as string} tipo_fattore="riga" />)}>
                        <FaPlus size={10} />
                    </div>
                }
            </table>
        </div>
    )
}

export default BloccoFattoreDefaultTabella;