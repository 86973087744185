import React from 'react'
import { BilanciStore } from './BilanciStore'
import { CanvasBilanciBusinessUnitsStore } from './CanvasBilanciBusinessUnitsStore'
import { FattoriESGStore } from './FattoriESGStore'
import { UiCanvasStore } from './UiCanvasStore'

export const ModuloESGStoresContext = React.createContext({
    bilanci: new BilanciStore(),
    canvas_bilanci_business_units: new CanvasBilanciBusinessUnitsStore(),
    fattori_esg: new FattoriESGStore(),
    ui_canvas: new UiCanvasStore(),
})