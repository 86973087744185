import React, { useState, useEffect } from 'react'
import { useModuloRendicontazioneBilanciStores } from '../../context/useModuloRendicontazioneBilanciStores';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaDotCircle, FaPlus } from 'react-icons/fa';
import { observer } from 'mobx-react-lite';

const FiltroAvanzamento = observer(() => {

    const { rendicontazione_bilanci } = useModuloRendicontazioneBilanciStores()
    const { t } = useTranslation()

    const styles = {
        bottone: 'border-l-4 border border-lightgray-dd p-2 w-full text-center cursor-pointer hover:brightness-[1.02] duration-100 flex items-center justify-between',
    }

    const [nonRendicontati, setNonRendicontati] = useState<number>(0)
    const [rendicontati, setRendicontati] = useState<number>(0)
    const [validati, setValidati] = useState<number>(0)

    useEffect(() => {
        setNonRendicontati(
            rendicontazione_bilanci.businessUnitAttiva?.fattori_rendicontabili
                .filter(f => f.tipo === 'fattore')
                .filter(f => f.indicatori.length === 0).length as number)
        setRendicontati(
            rendicontazione_bilanci.businessUnitAttiva?.fattori_rendicontabili
                .filter(f => f.tipo === 'fattore')
                .filter(f => f.indicatori.length > 0 && !f.indicatori[0].validato).length as number)
        setValidati(
            rendicontazione_bilanci.businessUnitAttiva?.fattori_rendicontabili
                .filter(f => f.tipo === 'fattore')
                .filter(f => f.indicatori.length > 0 && f.indicatori[0].validato).length as number)
    }, [rendicontazione_bilanci.businessUnitAttiva, rendicontazione_bilanci.checkAggiornamentoIndicatore])

    return (
        <div className="flex flex-col gap-2">

            <div
                className={`${styles.bottone} border-orange dark:border-dark-orange`}
                onClick={() => rendicontazione_bilanci.applicaFiltriStato('nonRendicontati')}>
                <p>{t('common.nonRendicontati')} ({nonRendicontati})</p>
                <div className="rounded-full border border-lightgray-dd w-3 h-3 flex items-center justify-center">
                    {rendicontazione_bilanci.filtriStato === 'nonRendicontati' &&
                        <FaDotCircle size={10} />
                    }
                </div>
            </div>

            <div
                className={`${styles.bottone} border-accent dark:border-dark-accent`}
                onClick={() => rendicontazione_bilanci.applicaFiltriStato('rendicontati')}>
                <p>{t('common.rendicontati')} ({rendicontati})</p>
                <div className="rounded-full border border-lightgray-dd w-3 h-3 flex items-center justify-center">
                    {rendicontazione_bilanci.filtriStato === 'rendicontati' &&
                        <FaDotCircle size={10} />
                    }
                </div>
            </div>

            <div
                className={`${styles.bottone} border-green dark:border-dark-green`}
                onClick={() => rendicontazione_bilanci.applicaFiltriStato('validati')}>
                <p>{t('common.validati')} ({validati})</p>
                <div className="rounded-full border border-lightgray-dd w-3 h-3 flex items-center justify-center">
                    {rendicontazione_bilanci.filtriStato === 'validati' &&
                        <FaDotCircle size={10} />
                    }
                </div>
            </div>
        </div>
    )
})

export default FiltroAvanzamento;