import { IAttivita } from '../../types';

import { creaStringStandardPerAttivita } from '../../hooks/attivita/creaStringStandardPerAttivita';

export default function factoryAttivitaBilancio(attivita: IAttivita) {

    let codiceInterpretato = '';

    switch (attivita.route) {

        case 'Bilanci.crea':
            attivita.user_name?
            codiceInterpretato = `${attivita.user_name} ha <span class="azione bg-accent dark:bg-dark-accent">creato</span> un <a target="_blank" href="/app/modulo/bilanci/${JSON.parse(attivita.request_corpo)?.anno}">bilancio per l'anno ${JSON.parse(attivita.request_corpo)?.anno}</a>`
            :
            codiceInterpretato = `Hai <span class="azione bg-accent dark:bg-dark-accent">creato</span> un <a target="_blank" href="/app/modulo/bilanci/${JSON.parse(attivita.request_corpo)?.anno}">bilancio per l'anno ${JSON.parse(attivita.request_corpo)?.anno}</a>`
            break;

        case 'Bilanci.modifica':
            attivita.user_name?
            codiceInterpretato = `${attivita.user_name} ha <span class="azione bg-orange dark:bg-dark-orange">modificato</span> il <a target="_blank" href="/app/modulo/bilanci/${attivita.uri.split('/')[attivita.uri.split('/').length - 1]}"> bilancio del ${attivita.uri.split('/')[attivita.uri.split('/').length - 1]}</a>`
            :
            codiceInterpretato = `Hai <span class="azione bg-orange dark:bg-dark-orange">modificato</span> il <a target="_blank" href="/app/modulo/bilanci/${attivita.uri.split('/')[attivita.uri.split('/').length - 1]}"> bilancio del ${attivita.uri.split('/')[attivita.uri.split('/').length - 1]}</a>`
            break;

        case 'Bilanci.elimina':
            attivita.user_name?
            codiceInterpretato = `${attivita.user_name} ha <span class="azione bg-red dark:bg-dark-red">eliminato</span> il bilancio del ${attivita.uri.split('/')[attivita.uri.split('/').length - 1]}`
            :
            codiceInterpretato = `Hai <span class="azione bg-red dark:bg-dark-red">eliminato</span> il bilancio del ${attivita.uri.split('/')[attivita.uri.split('/').length - 1]}`
            break;

        case 'FattoriBilanci.collega_fattore_a_bilancio':
            codiceInterpretato = collega_fattore_a_bilancio(attivita)
            break;

        case 'FattoriBilanciBusinessUnits.collega_fattore_bilancio_business_unit':
            codiceInterpretato = collega_fattore_bilancio_business_unit(attivita)
            break;

        case 'FattoriBilanci.scollega_fattore_da_bilancio':
            codiceInterpretato = scollega_fattore_da_bilancio(attivita)
            break;

        case 'FattoriBilanciIndicatori.inserisce_indicatore':
            codiceInterpretato = attribuisci_indicatore(attivita)
            break;

        case 'BilanciMaterialita.collega_materialita_a_bilancio':
            codiceInterpretato = collega_materialita_a_bilancio(attivita)
            break;

        case 'BilanciMaterialita.scollega_materialita_da_bilancio':
            codiceInterpretato = scollega_materialita_da_bilancio(attivita)
            break;

        case 'FattoriBilanciMaterialita.collega_temi_materialita_a_fattore_bilancio':
            codiceInterpretato = collega_materialita_a_fattore_bilancio(attivita)
            break;


        default:
            codiceInterpretato = creaStringStandardPerAttivita(attivita)

    }

    return codiceInterpretato;

}

const collega_fattore_a_bilancio = (attivita: IAttivita) => {

    let stringa = `Hai `
    stringa += `<span class="azione bg-accent dark:bg-dark-accent">collegato</span> `
    stringa += `il fattore <a href="/app/modulo/fattori_esg/${attivita.uri.split('/')[attivita.uri.split('/').length - 2]}" target="_blank">${attivita.uri.split('/')[attivita.uri.split('/').length - 2]}</a> `
    stringa += `al <a href="/app/modulo/bilanci/${attivita.uri.split('/')[attivita.uri.split('/').length - 4]}" target="_blank">bilancio del ${attivita.uri.split('/')[attivita.uri.split('/').length - 4]}</a>`

    return stringa;
}

const scollega_fattore_da_bilancio = (attivita: IAttivita) => {

    let stringa = `Hai `
    stringa += `<span class="azione bg-red dark:bg-dark-red">scollegato</span> `
    stringa += `il fattore <a href="/app/modulo/fattori_esg/${attivita.uri.split('/')[attivita.uri.split('/').length - 2]}" target="_blank">${attivita.uri.split('/')[attivita.uri.split('/').length - 2]}</a> `
    stringa += `dal <a href="/app/modulo/bilanci/${attivita.uri.split('/')[attivita.uri.split('/').length - 4]}" target="_blank">bilancio del ${attivita.uri.split('/')[attivita.uri.split('/').length - 4]}</a>`

    return stringa;
}

const collega_fattore_bilancio_business_unit = (attivita: IAttivita) => {

    let stringa = `Hai `
    stringa += `<span class="azione bg-orange dark:bg-dark-orange">modificato</span> `
    stringa += `delle business units nel fattore <a href="/app/modulo/fattori_esg/${attivita.uri.split('/')[attivita.uri.split('/').length - 2]}" target="_blank">${attivita.uri.split('/')[attivita.uri.split('/').length - 2]}</a> `
    stringa += `nel <a href="/app/modulo/bilanci/${attivita.uri.split('/')[attivita.uri.split('/').length - 4]}" target="_blank">bilancio del ${attivita.uri.split('/')[attivita.uri.split('/').length - 4]}</a>`

    return stringa;
}

const attribuisci_indicatore = (attivita: IAttivita) => {

    let stringa = 'Hai '
    stringa += `<span class="azione bg-accent dark:bg-accent-red">attribuito</span> `
    stringa += `un indicatore al fattore <a href="/app/modulo/bilanci/${attivita.uri.split('/')[5]}/fattori_es/${attivita.uri.split('/')[attivita.uri.split('/').length - 1]}" target="_blank">${attivita.uri.split('/')[attivita.uri.split('/').length - 1]}</a> `
    stringa += `nel <a href="/app/modulo/bilanci/${attivita.uri.split('/')[5]}" target="_blank">bilancio del ${attivita.uri.split('/')[5]}</a>`
    return stringa;
}


const collega_materialita_a_bilancio = (attivita: IAttivita) => {

    let anno = attivita.uri.split('/')[5];
    let uriAnno = `/app/modulo/bilanci/${anno}`
    
    let tema = attivita.uri.split('/')[7];
    let uriTema = `/app/modulo/materialita/${tema}`

    let stringa = 'Hai '
    stringa += `<span class="azione bg-accent dark:bg-dark-accent">collegato</span> `
    stringa += `il tema di materialità <a href="${uriTema}" target="_blank">${tema}</a> `
    stringa += `al <a href="${uriAnno}" target="_blank">bilancio del ${anno}</a>`
    return stringa;
}

const scollega_materialita_da_bilancio = (attivita: IAttivita) => {

    let anno = attivita.uri.split('/')[5];
    let uriAnno = `/app/modulo/bilanci/${anno}`

    let tema = attivita.uri.split('/')[7];
    let uriTema = `/app/modulo/materialita/${tema}`
    
    let stringa = 'Hai '
    stringa += `<span class="azione bg-red dark:bg-dark-red">scollegato</span> `
    stringa += `il tema di materialità <a href="${uriTema}" target="_blank">${tema}</a> `
    stringa += `dal <a href="${uriAnno}" target="_blank">bilancio del ${anno}</a>`
    return stringa;
}

const collega_materialita_a_fattore_bilancio = (attivita: IAttivita) => {

    let anno = attivita.uri.split('/')[5];
    let uriAnno = `/app/modulo/bilanci/${anno}`

    let numTemi = JSON.parse(attivita.request_corpo).temi_materialita.length;

    let fattore = attivita.uri.split('/')[8];
    let uriFattore = `/app/modulo/fattori_esg/${fattore}`

    let stringa = 'Hai '
    stringa += `<span class="azione bg-accent dark:bg-dark-accent">collegato</span> `
    stringa += `${numTemi} temi di materialità al fattore <a href="${uriFattore}" target="_blank">${fattore}</a> `
    stringa += `nel <a href="${uriAnno}" target="_blank">bilancio del ${anno}</a>`
    return stringa;
}