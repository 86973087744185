import { observable, action, makeAutoObservable, runInAction } from 'mobx'

import {
  getUnitaMisuraLista,
  getUnitaMIsura,
  creaUnitaMisura,
  modificaUnitaMisura,
  eliminaUnitaMisura,
  
} from '../rest/gestioneUnitaMisura'

import { IUnitaMisura } from '../../../types'

export class GestioneUnitaMisuraStore {

  unitaMisura: IUnitaMisura[] = []

  creatingUnitaMisura: boolean = false
  creatingUnitaMisuraError: string = ''

  editingUnitaMisura: boolean = false
  modificaUnitaMisuraError: string = ''
  UnitaMisuraModificato: boolean = false

  deletingUnitaMisura: boolean = false
  eliminaUnitaMisuraError: string = ''
  UnitaMisuraEliminato: boolean = false


  constructor() {
    makeAutoObservable(this, {

      unitaMisura: observable,
      getUnitaMisuraLista: action,

      //Crea unitaMisura
      creaUnitaMisura: action,
      creatingUnitaMisura: observable,
      creatingUnitaMisuraError: observable,

      //Modifica unitaMisura
      modificaUnitaMisura: action,
      editingUnitaMisura: observable,
      modificaUnitaMisuraError: observable,
      UnitaMisuraModificato: observable,

      //Elimina unitaMisura
      eliminaUnitaMisura: action,
      deletingUnitaMisura: observable,
      eliminaUnitaMisuraError: observable,
      UnitaMisuraEliminato: observable,

    })
  }

  async getUnitaMisuraLista() {
    await getUnitaMisuraLista().then(res => {

      runInAction(() => this.unitaMisura = res.data.data)


    }).catch(err => {

      console.log(err)

    })
  }

  async getUnitaMIsura(codice: string) {
    await getUnitaMIsura(codice).then(res => {

      runInAction(() => this.unitaMisura = res.data.data)

    }).catch(err => {

      console.log(err)

    })
  }

  //Crea un unitaMisura
  async creaUnitaMisura(unitaMisura: IUnitaMisura) {

    this.creatingUnitaMisura = true

    await creaUnitaMisura(unitaMisura).then(res => {

      runInAction(() => this.creatingUnitaMisuraError = '')

    }).catch(err => {

      runInAction(() => this.creatingUnitaMisuraError = err.response.data.message ? err.response.data.message : 'Errore generico')

    }).finally(() => {

      runInAction(() => this.creatingUnitaMisura = false)

    })
  }


  async modificaUnitaMisura(unitaMisura: IUnitaMisura,) {

    this.editingUnitaMisura = true

    await modificaUnitaMisura(unitaMisura).then(res => {

      runInAction(() => this.modificaUnitaMisuraError = '')

    }).catch(err => {

      runInAction(() => this.modificaUnitaMisuraError = err.response.data.message ? err.response.data.message : 'Errore generico')

    }).finally(() => {

      runInAction(() => this.editingUnitaMisura = false)

      runInAction(() => this.UnitaMisuraModificato = !this.UnitaMisuraModificato)

    })
  }

  async toggleAttivazioneunitaMisura(unitaMisura: IUnitaMisura) {

    this.editingUnitaMisura = true

    await modificaUnitaMisura(unitaMisura).then(res => {

      runInAction(() => this.modificaUnitaMisuraError = '')

    }).catch(err => {

      runInAction(() => this.modificaUnitaMisuraError = err.response.data.message ? err.response.data.message : 'Errore generico')

    }).finally(() => {

      runInAction(() => this.editingUnitaMisura = false)

      runInAction(() => this.UnitaMisuraModificato = !this.UnitaMisuraModificato)

    })
  }

  async eliminaUnitaMisura(codice: string) {

    this.deletingUnitaMisura = true

    await eliminaUnitaMisura(codice).then(res => {

      runInAction(() => this.eliminaUnitaMisuraError = '')

    }).catch(err => {

      runInAction(() => this.eliminaUnitaMisuraError = err.response.data.message ? err.response.data.message : 'Errore generico')

      console.log(this.eliminaUnitaMisuraError)

    }).finally(() => {

      runInAction(() => this.deletingUnitaMisura = false)

      runInAction(() => this.UnitaMisuraEliminato = !this.UnitaMisuraEliminato)
    }
    )
  }

  

}