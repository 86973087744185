import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import { IGruppo, IPrivilegio } from '../../types'
import { IModulo } from '../../types'
import useOttieniPrivilegioModulo from '../../hooks/useOttieniPrivilegioModulo'

//Components
import { Link } from 'react-router-dom'
import { FaAngleDoubleDown, FaEllipsisV } from 'react-icons/fa'
import ModuloContextMenu from './ModuloContextMenu'
import IconaDinamica from '../../components/common/IconaDinamica'
import ListaUtentiGruppo from '../../components/common/followMouse/ListaUtentiGruppo'

const ModuloTile: React.FC<{ modulo: IModulo, modifica: boolean, privilegi?: IPrivilegio[] }> = observer(({ modulo, modifica, privilegi }) => {

    const privilegio = useOttieniPrivilegioModulo();
    const { ui } = useStores();
    const { t } = useTranslation();
    const [contextMenu, setContextMenu] = useState(false);

    const styles = {
        'wrapper': 'bg-white dark:bg-darkgray-d p-4 md:p-6 shadow-xl border-b border-lightgray-dd grow',
        'boxGruppo': 'relative cursor-pointer z-0 flex items-center gap-1 py-1 px-3 bg-lightgray-d rounded-full hover:bg-lightgray-dd whitespace-nowrap dark:bg-darkgray-dd'
    }

    const toggleContextMenu = () => {
        //Il timeout permette di far comparire il context menu dopo che l'utente ha cliccato
        setTimeout(() => {
            setContextMenu(!contextMenu)
        }, 1)
    }

    document.addEventListener('click', () => {
        if (contextMenu) {
            setContextMenu(false)
        }
    }, { once: true })

    const nomePrivilegio = (privilegio_valore: number) => {
        const privilegio = privilegi?.find(privilegio => privilegio.valore === privilegio_valore)

        return privilegio?.nome
    }

    return (
        <div className="w-full">

            <div className={styles.wrapper}>

                {/* Header */}
                <div className="flex md:flex-row items-center justify-between">

                    <div className="flex md:gap-2 md:items-end flex-col md:flex-row">
                        <div className="flex items-center gap-2">
                            <span className="hidden sm:block">
                                <IconaDinamica dimensione={20} nome={modulo?.icona} />
                            </span>
                            <Link to={`/app/modulo/${modulo.codice}`}>
                                <h2 className="dark:text-dark-text hover:underline font-bold text-xl">
                                    {modulo?.nome}
                                </h2>
                            </Link>
                        </div>
                    </div>

                    <div className="flex gap-2 md:gap-4 items-center">

                        {
                            modifica &&
                            <p className="flex gap-2 items-center">
                                <span className="font-bold hidden md:block">{modulo.attivo ? t('common.attivo') : t('common.disattivo')}</span>
                                <span className={`w-1 h-1 rounded-full ${modulo.attivo ? 'bg-accent dark:bg-dark-accent' : 'bg-red dark:bg-dark-red'}`}></span>
                            </p>
                        }

                        {privilegio >= 3 &&
                            <div className="cursor-pointer relative p-1" onClick={() => toggleContextMenu()}>
                                {modifica && <FaEllipsisV className="hover:opacity-70" />}

                                {contextMenu && modifica && <ModuloContextMenu modulo={modulo as IModulo} />}
                            </div>
                        }
                    </div>

                </div>

                {/* Gruppi */}
                {modulo.gruppi && (
                    <div className="flex gap-2 my-4 flex-wrap">
                        {
                            modulo.gruppi.map((gruppo: IGruppo) => (
                                <div onMouseEnter={() => ui.mountFollowMouse(<ListaUtentiGruppo codice={gruppo.codice} />)} onMouseLeave={() => ui.unmountFollowMouse()} key={gruppo.nome} className={styles.boxGruppo}>

                                    {
                                        gruppo.ereditato &&
                                        <FaAngleDoubleDown
                                            onMouseEnter={() => ui.mountFollowMouse(<p className="text-xs">{t('ModuloGestioneModuli.Tile.privileiGenitore')}</p>)}
                                            onMouseLeave={() => ui.unmountFollowMouse()}
                                            size={12}
                                            className="absolute right-[5px] top-[-3px] text-accent dark:text-dark-accent"
                                        />
                                    }

                                    <span className={`text-xs flex items-center gap-1 ${!gruppo.attivo && 'opacity-70'}`}>

                                        {
                                            !gruppo.attivo &&
                                            <div className="w-[5px] h-[5px] rounded-full bg-red"
                                                onMouseEnter={() => ui.mountFollowMouse(<p className="text-xs">{t('ModuloGestioneModuli.Tile.privilegiDisattivi')}</p>)}
                                                onMouseLeave={() => ui.unmountFollowMouse()}
                                            ></div>
                                        }
                                        {gruppo.nome} | {nomePrivilegio(gruppo.privilegio_valore)}
                                    </span>

                                </div>
                            ))
                        }
                    </div>
                )}

                {/* Descrizione */}
                <p className="text-text-light mt-4 dark:text-dark-text-light">
                    {modulo.descrizione}
                </p>
            </div>

            {/* Se nel modulo sono presenti dei sotto-moduli questi vengono caricati */}
            {
                modulo.sotto_moduli && modulo.sotto_moduli.length > 0 &&
                <div className={`${!modulo.attivo && 'pointer-events-none opacity-70'}`}>

                    {
                        modulo.sotto_moduli.map((modulo: IModulo) => (
                            <SottoModuloTile key={modulo.codice} modulo={modulo} modifica={modifica} privilegi={privilegi} />
                        ))
                    }

                </div>

            }
        </div>
    )
}
)

const SottoModuloTile: React.FC<{ modulo: IModulo, modifica: boolean, privilegi?: IPrivilegio[] }> = observer(({ modulo, modifica, privilegi }) => {
    return (
        <div className="w-full flex items-stretch justify-center">
            <div className="w-[20px] relative grow flex left-[10px]">
                <div className="w-[2px] bg-accent"></div>
                <div className="w-[10px] h-[2px] bg-accent top-[20px] absolute"></div>
            </div>
            <ModuloTile modulo={modulo} modifica={modifica} privilegi={privilegi} />
        </div>
    )
}
)

export default ModuloTile;