import React from 'react'
import { useModuloESGStores } from '../../context/useModuloESGStores'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'

const SchedaBilancioGruppi = observer(() => {

  const { bilanci } = useModuloESGStores()

  return (
    <div className="flex flex-col gap-4">
      {
        bilanci.bilancioAttivo &&
        <>
          {
            bilanci.bilancioAttivo?.gruppi.length > 0 ?
              bilanci.bilancioAttivo?.gruppi.map((gruppo) => (
                <div className="bg-white p-4 shadow-xl">
                  <Link to={gruppo.codice}><h2 className="font-bold text-lg">{gruppo.nome}</h2></Link>
                </div>
              ))
              :
              <div className="flex items-center justify-center my-4">
                <p className="text-lg">Nessun gruppo collegato a questo bilancio</p>
              </div>
          }
        </>
      }
    </div>
  )
})

export default SchedaBilancioGruppi;