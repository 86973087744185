import React, { useState } from 'react'

//Data
import { ILingua } from '../../../types';
import useOttieniPrivilegioModulo from '../../../hooks/useOttieniPrivilegioModulo';

//Components
import { FaEllipsisV } from 'react-icons/fa';
import LinguaContextMenu from './LinguaContextMenu';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../hooks/useStores';
import { observer } from 'mobx-react-lite';

const LinguaTile: React.FC<{ lingua: ILingua }> = observer(({ lingua }) => {

    const { ui } = useStores()
    const privilegio = useOttieniPrivilegioModulo();
    const [contextMenu, setContextMenu] = useState<boolean>(false);

    const toggleContextMenu = () => {
        //Il timeout permette di far comparire il context menu dopo che l'utente ha cliccato
        setTimeout(() => {
            setContextMenu(!contextMenu)
        }, 1)
    }

    document.addEventListener('click', () => {
        if (contextMenu) {
            setContextMenu(false)
        }
    }, { once: true })

    const { t } = useTranslation()
    return (
        
        <div className={`bg-white dark:bg-black p-6 rounded-sm shadow-xl relative ${lingua.default_lingua && 'border-4 border-accent dark:border-dark-accent' }`}>
            {
                lingua.default_lingua &&
                <div className="py-1 px-2 text-white bg-accent dark:bg-dark-accent absolute top-0 right-0">
                    <p className="text-sm"
                        onMouseEnter={() => ui.mountFollowMouse(<div>
                            {t("ModuloGestioneLingue.Form.quandoUtenteRiciede")} {lingua.nome}
                        </div>)}
                        onMouseLeave={() => ui.unmountFollowMouse()}
                    >{t("ModuloGestioneLingue.Form.linguaDiDefault")}
                    </p>
                </div>
            }
            <div className="flex items-center justify-between">
                <h2 className="text-lg font-bold flex gap-2 items-center">
                    <span className={`fi fi-${lingua.bandiera}`} />
                    {lingua.nome}
                </h2>

               {privilegio >= 3 &&
                    <div className="cursor-pointer hover:bg-lightgray p-1 relative" onClick={() => toggleContextMenu()}>
                        <FaEllipsisV />

                        {contextMenu &&
                            <LinguaContextMenu lingua={lingua} />
                        }
                    </div>
               }

            </div>
        </div>
    )
})

export default LinguaTile;