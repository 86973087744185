import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useModuloESGStores } from '../../../context/useModuloESGStores'
import { observer } from 'mobx-react-lite'
import { useForm } from 'react-hook-form'
import { FaPlus } from 'react-icons/fa'


const CercaFattoreESG = observer(() => {

    const { ui_canvas } = useModuloESGStores();
    const { t } = useTranslation();

    const { watch, register } = useForm()

    useEffect(() => {
        ui_canvas.attribuiStringaRicerca(watch('cerca_fattori'))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch()])

    return (
        <div className={`form relative  ${ui_canvas.fullscreen ? 'p-4 pt-8' : 'bg-lightgray-d border border-lightgray-dd rounded-sm p-4 dark:bg-darkgray-dd dark:border-black'}`}>
            <FaPlus className="rotate-45 absolute right-2 top-2 opacity-50 hover:opacity-80 duration-100 cursor-pointer" onClick={() => ui_canvas.toggleRicerca()} />
            <label>{t('common.filtraFattori')}</label>
            <input {...register('cerca_fattori')} type="text" placeholder={t('common.cerca')} className="w-full border border-lightgray-dd rounded-sm p-2" />
        </div>
    )
})

export default CercaFattoreESG;