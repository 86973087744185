import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { ITemaMaterialitaAnno } from '../../types'
import useOttieniPrivilegioModulo from '../../../../hooks/useOttieniPrivilegioModulo'

//Components
import { FaEllipsisV } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import TemaMaterialitaBilancioContextMenu from '../../pagineBilanci/UiModuloBilanciTemiMaterialita/TemaMaterialitaBilancioContextMenu'
import { useStores } from '../../../../hooks/useStores'
import { useModuloESGStores } from '../../context/useModuloESGStores'

const TemaMaterialitaBilancioTile: React.FC<{ tema: ITemaMaterialitaAnno }> = ({ tema }) => {

  const { t } = useTranslation();
  const { moduli } = useStores()
  const { bilanci } = useModuloESGStores()
  const privilegio = useOttieniPrivilegioModulo()
  const [contextMenu, setContextMenu] = React.useState(false)

  const toggleContextMenu = () => {
    //Il timeout permette di far comparire il context menu dopo che l'utente ha cliccato
    setTimeout(() => {
      setContextMenu(!contextMenu)
    }, 1)
  }

  document.addEventListener('click', () => {
    if (contextMenu) {
      setContextMenu(false)
    }
  }, { once: true })


  return (
    <div
      className="overflow-hidden bg-white dark:bg-black shadow-xl rounded-sm border-l-4 p-2"
      style={{ borderColor: tema.colore ? tema.colore : '#333333' }}
    >
      <div className="flex items-start justify-between">
        <div>
          <p>{t('ModuloFattoriEsg.TileTemaMaterialitaBilancio.RilevanzaAzienda') }: {tema.rilevanza_azienda_anno}</p>
          <p>{t('ModuloFattoriEsg.TileTemaMaterialitaBilancio.RilevanzaStakeholder') }: {tema.rilevanza_stakeholder_anno}</p>
          <Link to={`/app/modulo/${moduli.moduloAttivo?.codice}/${bilanci.bilancioAttivo?.anno}/temi_materialita/${tema.codice}`} className="flex items-center gap-2">
            <h2 className="font-bold hover:underline whitespace-pre-wrap	text-lg">{tema.nome}</h2>
          </Link>
        </div>


        {
          (privilegio >= 3 && moduli.moduloAttivo && moduli.moduloAttivo.codice === 'bilanci_materialita') &&
          <div className="cursor-pointer relative p-1" onClick={() => toggleContextMenu()}>
            <FaEllipsisV className="hover:opacity-70" />

            {contextMenu && <TemaMaterialitaBilancioContextMenu tema={tema} />}

          </div>
        }
      </div>

      <hr className="my-2 border border-lightgray-d" />
      <p>{tema.descrizione_anno}</p>
    </div>
  )
}

export default TemaMaterialitaBilancioTile;
