import React from 'react'

//Data
import { useModuloRendicontazioneBilanciStores } from '../context/useModuloRendicontazioneBilanciStores'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

//Components
import StatoAvanzamentoCompletamentoIndicatori from '../../../components/bilanci/StatoAvanzamentoCompletamentoIndicatori'
import TextLoader from '../../../../../components/common/TextLoader'

const HeaderRendicontazioneBilancio = observer(() => {

    const { rendicontazione_bilanci } = useModuloRendicontazioneBilanciStores()

    const { t } = useTranslation()

    return (
        <div className="p-5 bg-white dark:bg-darkgray-d mb-4 shadow-xl mb-24 min-h-[240px]">

            {rendicontazione_bilanci.businessUnitAttiva ?
                <><h2 className="lg:text-xl">{t('ModuloFattoriEsg.SchedaRendicontazioneBilancio.fattoriDisponibile')} <span className="font-bold lg:text-lg">{rendicontazione_bilanci.businessUnitAttiva!.nome}</span></h2><p className="text-lg mt-2">{t('ModuloFattoriEsg.SchedaRendicontazioneBilancio.puoiRendicontare')}
                    <span className="font-bold lg:text-lg"> {rendicontazione_bilanci.businessUnitAttiva!.fattori_rendicontabili.filter((f) => f.tipo === 'fattore').length} {t('common.fattori')} &</span>
                    <span className="font-bold lg:text-lg"> {rendicontazione_bilanci.businessUnitAttiva!.fattori_rendicontabili.filter((f) => f.tipo === 'tabella').length} {t('common.tabelle')} </span>
                    {t('ModuloFattoriEsg.SchedaRendicontazioneBilancio.fattoriInQuestoBusinessUnit')}
                    <span className="font-bold lg:text-lg">
                        {rendicontazione_bilanci.businessUnitAttiva!.ruolo === 0 && t('ModuloFattoriEsg.SchedaRendicontazioneBilancio.Consultazione')}
                        {rendicontazione_bilanci.businessUnitAttiva!.ruolo === 1 && t('ModuloFattoriEsg.SchedaRendicontazioneBilancio.Raccolta')}
                        {rendicontazione_bilanci.businessUnitAttiva!.ruolo === 2 && t('ModuloFattoriEsg.SchedaRendicontazioneBilancio.Validazione')}
                        {rendicontazione_bilanci.businessUnitAttiva!.ruolo === 3 && t('ModuloFattoriEsg.SchedaRendicontazioneBilancio.Gestione')}
                    </span>
                </p><StatoAvanzamentoCompletamentoIndicatori
                        indicatori_totali={rendicontazione_bilanci.businessUnitAttiva!.fattori_rendicontabili.filter(f => f.tipo === 'fattore').length}
                        indicatori_rendicontati={rendicontazione_bilanci.businessUnitAttiva!.fattori_rendicontabili.filter(f => f.tipo === 'fattore').filter((f) => f.indicatori.length > 0).length}
                        indicatori_validati={rendicontazione_bilanci.businessUnitAttiva!.fattori_rendicontabili.filter(f => f.tipo === 'fattore').filter((f) => f.indicatori.length > 0 && f.indicatori[0].validato).length} /></>
                :

                <ul>
                    <li><TextLoader /></li>
                    <li><TextLoader /></li>
                    <li><TextLoader /></li>
                    <li><TextLoader /></li>
                </ul>
            }

        </div>
    )
})

export default HeaderRendicontazioneBilancio;

