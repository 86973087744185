import React from 'react'
import { FaLink } from 'react-icons/fa';

const LinkDashboard: React.FC<{ titolo: string, descrizione: string, link: string, immagine: string }> = ({titolo, descrizione, link, immagine}) => {
    return (
        <a target="_blank" rel="noreferrer" href={link} className="overflow-hidden flex flex-col rounded-sm grow shadow-xl flex-1 text-text dark:text-dark-text">
            <div className="max-h-[200px] overflow-hidden flex items-center justify-center">
                <img src={immagine} alt={titolo} />
            </div>
            <div className="bg-white dark:bg-darkgray-dd p-5 grow">
                <div className="flex items-enter justify-between">
                    <h3 className="font-bold text-lg mb-2 text-accent dark:text-dark-accent">{titolo}</h3>
                    <FaLink />
                </div>
                <p>{descrizione}</p>
            </div>
        </a>
    )
}

export default LinkDashboard;