import { IModulo } from '../types'

const estraiModuliPadre = (moduliFlat: IModulo[]) => {
    const listaCodici = moduliFlat.map(modulo => modulo.codice)
    
    const moduliPadre: IModulo[] = moduliFlat
        .filter(modulo => modulo.codice_padre === null || !listaCodici.includes(modulo.codice_padre))

    return moduliPadre
}

const estraiSottomoduli = (moduliFlat: IModulo[], moduloCorrente: IModulo) => {
    moduloCorrente.sotto_moduli = moduliFlat
            .filter(sottomodulo => sottomodulo.codice_padre === moduloCorrente.codice)
            .map(sottomodulo => estraiSottomoduli(moduliFlat, sottomodulo))

    return moduloCorrente
}

export default function creaAlberoModuli (moduliFlat: IModulo[]): IModulo[] {
    const moduliPadreConSottomoduli = estraiModuliPadre(moduliFlat)
        .map(modulo => estraiSottomoduli(
            moduliFlat, modulo))
    
    return moduliPadreConSottomoduli
}
