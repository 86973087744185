import React, { useEffect, useState } from 'react'

//Data
import { get } from '../../../rest/crud_generica'
import { INotizia } from '../../../types'
import { useTranslation } from 'react-i18next'
import { useStores } from '../../../hooks/useStores'
import { observer } from 'mobx-react'
//Components
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import TileNotiziaInEvidenza from './TileNotiziaInEvidenza'
import TileNotizia from './TileNotizia'

const ArchivioNotizieUnlogged = observer(() => {

    const [notizie, setNotizie] = useState<INotizia[]>([])
    const [visibile, setVisibile] = useState<boolean>(true)
    const { t, i18n } = useTranslation()
    const { ui } = useStores()

    useEffect(() => {

        get(`notizie/login`, {
            lingua: i18n.language
        }).then((res) => {
            setNotizie(res.data.data)
        })

    }, [ui.lingua])

    return (
        <>
            {notizie && notizie.length > 0 &&
                <div className="absolute z-100 flex flex-col w-[90%] md:w-[70%] gap-2">
                    <div className="flex justify-end">
                        <div className="bg-white p-2 cursor-pointer text-center flex items-center gap-1" onClick={() => setVisibile(!visibile)}>
                            {visibile ? <FaEyeSlash /> : <FaEye />}
                            <span>{visibile ? t('common.nascondi') : t('common.mostra')}</span>
                        </div>
                    </div>

                    <div className={`flex flex-col gap-2 duration-500 ${visibile ? `opacity-100` : 'opacity-25 pointer-events-none'}`}>
                        <div>
                            {notizie.filter(n => n.in_evidenza).map((notizia) => (
                                <TileNotiziaInEvidenza notizia={notizia} logged={false}/>
                            ))}
                        </div>
                        
                        <div className="flex gap-2 items-stretch max-w-[full] overflow-x-auto">
                            {
                                notizie.filter(n => !n.in_evidenza).map((notizia) => (
                                    <TileNotizia notizia={notizia} logged={false} larghezzaFissa={notizie.filter(n => !n.in_evidenza).length > 2 && true} />
                                ))
                            }
                        </div>
                    </div>

                </div>
            }
        </>
    )
})

export default ArchivioNotizieUnlogged;