import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { observer } from 'mobx-react-lite'

//Components
import { FaInfoCircle, FaPlus, FaSlidersH } from 'react-icons/fa'
import ReactPaginate from 'react-paginate'
import TileUtente from '../../UtenteTile'

import { useStores } from '../../../../hooks/useStores'
import BarraRicercaGenerale from '../../../../components/common/BarraRicercaGenerale'
import useOttieniPrivilegioModulo from '../../../../hooks/useOttieniPrivilegioModulo'
import { IUtente } from '../../../../types'
import CreaUtenteForm from '../../CreaUtenteForm'
import AggiungiButton from '../../../../components/common/AggiungiButton'


export const PannelloUtentiTotali = observer(() => {
    const { t } = useTranslation();
    const privilegio = useOttieniPrivilegioModulo();
    const { gestioneUtenti, ui } = useStores();
    useEffect(() => {
        gestioneUtenti.getUtenti();
        gestioneUtenti.getUtentiSenzaGruppo();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        gestioneUtenti.creatingUtente,
        gestioneUtenti.utenteModificato,
        gestioneUtenti.utenteEliminato,
        gestioneUtenti.creatingGruppo,
        gestioneUtenti.gruppoModificato,
        gestioneUtenti.gruppoEliminato,
    ])

    const styles = {
        cardsWrapper: "grid gap-3 xl:gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
        requestsButton: "cursor-pointer hover:text-accent rounded-sm p-2 hover:bg-lightgray-d flex gap-4 items-center",
        headerPannello: "flex items-center justify-between flex-col md:flex-row my-5",
        buttonRichiesteGestite: `relative flex md:block justify-center  ${gestioneUtenti.richieste.filter(richiesta => richiesta.gestita === true).length < 1 && 'opacity-70 pointer-events-none'}`,
        btnFiltri: "text-white opacity-70 p-1 rounded-lg w-[200px]",
    }
    const [ordinati, setOrdinati] = useState("");

    const buttonOrder = (event: React.MouseEvent<HTMLButtonElement>): any => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;
        setOrdinati(button.name);
    }

    const [filtrati, setFiltrati] = useState("");

    const buttonFilter = (event: React.MouseEvent<HTMLButtonElement>): any => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;
        setFiltrati(button.name);
    }

    const [arrayFiltri, setFiltri] = useState<boolean>(false)
    const chiudFiltri = () => {
        setFiltri(false)
        setOrdinati("")
        setFiltrati("")
        gestioneUtenti.getUtenti();

    }
    const [utente, setUtente] = useState<IUtente[]>([]);
    
    const utenti = gestioneUtenti.utenti;

    const [stringaDiRicercaUtenti, setStringaDiRicercaUtenti] = useState<string>('');
    // Dati per paginazione utenti totali
    const [numeroPagina, setNumeroPagina] = useState(0);
    const utentiPerPagina = 3;
    const pagineVisitate = numeroPagina * utentiPerPagina;

    useEffect(() => {
        setNumeroPagina(0);
    }, [stringaDiRicercaUtenti])

    useEffect(() => {
        setUtente(utenti)
    })

    useEffect(() => {
        if(ordinati === "za"){
            setUtente(utenti.sort((a: IUtente, b: IUtente) => b.nome > a.nome ? -1 : 1))
        }
        if(ordinati === "az"){
            setUtente(utenti.sort((a: IUtente, b: IUtente) => b.nome > a.nome ? 1 : -1))
        }
    }, [ordinati])

    useEffect(() =>{
        if(filtrati === "resetta"){
            setOrdinati("")
            setFiltrati("")
            gestioneUtenti.getUtenti();
        }
    },[filtrati])
    
    const utentiFiltrati = utente
        .filter((utente) => {
            return (
                stringaDiRicercaUtenti === "" ||
                (
                    utente.nome.toLocaleLowerCase()
                        .includes(stringaDiRicercaUtenti.toLocaleLowerCase()) ||
                    utente.email
                        .includes(stringaDiRicercaUtenti.toLocaleLowerCase())
                )
            ) && (
                filtrati !== "" ?
                        (filtrati === "attivo" && utente.attivo && !utente.disabilitato ||
                        filtrati === "nonAttivo" && !utente.attivo && !utente.disabilitato ||
                        filtrati === "resetta" && gestioneUtenti.utenti)
                            :
                        true
                )
        })

    useEffect(() => {
        if (filtrati === "attivo" || filtrati === "nonAttivo") {
            setNumeroPagina(0);
        }
    }, [filtrati])


    const conteggioPagina = Math.ceil(
        utente.filter((utente) => {
            return (
                stringaDiRicercaUtenti === "" ||
                (
                    utente.nome.toLocaleLowerCase()
                        .includes(stringaDiRicercaUtenti.toLocaleLowerCase()) ||
                    utente.email
                        .includes(stringaDiRicercaUtenti.toLocaleLowerCase())
                )
            ) && (
                filtrati !== "" ?
                        (filtrati === "attivo" && utente.attivo && !utente.disabilitato ||
                        filtrati === "nonAttivo" && !utente.attivo && !utente.disabilitato ||
                        filtrati === "resetta" && utenti )
                             :
                        true
                )
        }).length / utentiPerPagina
    );

    const cambioPagina = ({ selected }: { selected: number }): void => {
        setNumeroPagina(selected)
    }


    return (
        <div className='shadow-xl px-4 py-2'>
        
            <div className="lg:flex text-center justify-between mb-3 text-accent dark:text-dark-accent mt-6 border-[lightgray] border-b-2 py-2">

                <span className="text-lg lg:text-2xl font-bold uppercase">{gestioneUtenti.utenti.length} {t('common.utenti')}</span>

                <BarraRicercaGenerale 
                    setStringaDiRicerca={setStringaDiRicercaUtenti} 
                    numeroRisultati={utentiFiltrati.length}
                    mostraRisultati={true} />
            </div>
            <div className="flex items-center justify-end my-2">

                {arrayFiltri ?
                    <div className="cursor-pointer p-1 hover:bg-white rounded-sm rotate-45" onClick={() => chiudFiltri()}>
                        <FaPlus />
                    </div>
                    :
                    <div className="cursor-pointer p-1 hover:bg-white rounded-sm" onClick={() => setFiltri(true)} >
                        <FaSlidersH />
                    </div>
                }

            </div>
            {arrayFiltri &&
                <>
                    <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-2 mb-6 m-auto mostraFiltri'>
                        <div className=''>
                            <button onClick={buttonFilter} className={`${styles.btnFiltri} ${filtrati === "attivo" ? "opacity-100" : ""}`} name="attivo"> Filtra per attivi</button>
                        </div>
                        <div className=''>
                            <button onClick={buttonFilter} className={`${styles.btnFiltri} ${filtrati === "nonAttivo" ? "opacity-100" : ""}`} name="nonAttivo"> Filtra per disattivi </button>
                        </div>
                        <div className=''>
                            <button onClick={buttonOrder} className={`${styles.btnFiltri} ${ordinati === "az" ? "opacity-100" : ""}`} name="az"> Ordina per nome desc </button>
                        </div>
                        <div className=''>
                            <button onClick={buttonOrder} className={`${styles.btnFiltri} ${ordinati === "za" ? "opacity-100" : ""}`} name="za"> Ordina per nome asc </button>
                        </div>
                        <div className=''>
                            <button onClick={buttonFilter} className={`${styles.btnFiltri}`} name="resetta"> Resetta filtro </button>
                        </div>
                    </div>

                </>
            }
            <div>

                {!ui.ricercaUtenti &&

                    utentiFiltrati.length > 0 ?
                    <><div className={`${styles.cardsWrapper} mb-5`}>
                        {privilegio >= 2 &&
                            <div onClick={() => ui.mountAsideModal(<CreaUtenteForm />)}>
                                <AggiungiButton testo={t('ModuloGestioneUtenti.PannelloUtenti.aggiungiNuovoUtente')} />
                            </div>}
                        {
                            utentiFiltrati
                                .slice(pagineVisitate, pagineVisitate + utentiPerPagina)
                                .map((utente, i) => {
                                    return (
                                        <>
                                            <TileUtente boleanoMostrare={true} key={i} utente={utente} />
                                        </>
                                    )
                                })
                        }
                    </div>
                        {utentiFiltrati.length >= 4 &&
                        <div className='mb-2'>
                        <ReactPaginate
                        pageCount={conteggioPagina}
                        forcePage={numeroPagina}
                        nextLabel={'Next'}
                        onPageChange={cambioPagina}
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"} />   
                        </div>                    
                        }

                    </> 
                    :

                    <>
                        <p className="flex items-center gap-2 opacity-70 justify-center">
                            <FaInfoCircle /> <span className="text-lg">{t('ModuloGestioneUtenti.PannelloUtenti.nessunUtente')}</span>
                        </p>
                    </>

                }

            </div>
        </div>

    )
}
)
export default PannelloUtentiTotali