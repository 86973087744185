import React from 'react'

//Components
import Pagina from '../components/common/Pagina'
import ArchivioAttivita from '../components/attivita/ArchivioAttivita'
import { useTranslation } from 'react-i18next'

const Attivita = () => {
  
  const { t } = useTranslation()

  return (
    <Pagina titolo={t('PaginaAttivita.titolo')} descrizione={t('PaginaAttivita.descrizione')}>
      <ArchivioAttivita />
    </Pagina>
  )
}

export default Attivita;