import React, {useState, useEffect} from 'react'

//Data
import { useForm } from 'react-hook-form'
import { useStores } from '../../../hooks/useStores'
import { useModuloESGStores } from '../context/useModuloESGStores'
import { IFattoreESG } from '../types'

//Components
import FattoreESGTile from './FattoreESGTile'

const ListaFattoriESG: React.FC<{ fattoriEreditati: IFattoreESG[] }> = ({ fattoriEreditati }) => {

    const { moduli } = useStores()
    const { bilanci } = useModuloESGStores()

    const { watch, register } = useForm()

    const [fattori, setFattori] = useState<IFattoreESG[]>()

    //Numero di fattori presenti per pagina
    const [fattoriPerPagina, setFattoriPerPagina] = useState<number>(20)

    //Numero di pagine disponibili
    const [numPagine, setNumPagine] = useState<number>(0)

    //Pagina attualmente selezionata
    const [paginaAttiva, setPaginaAttiva] = useState<number>(0)

    //Setta la lista dei fattori
    useEffect(() => {

        setFattori(fattoriEreditati)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fattoriEreditati])

    //Preseleziona la pagina uno al caricamento del component
    useEffect(() => {
        setPaginaAttiva(0)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //Calcola il numero di pagine necessarie
    useEffect(() => {
        if (fattori) {
            setNumPagine(fattori.length / fattoriPerPagina)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fattori])

    //Setta i fattori filtrati sulla base della ricerca effettuata
    useEffect(() => {
        setFattori(fattoriEreditati?.filter(f =>
            f.codice.includes(watch('ricerca')) ||
            f.nome.includes(watch('ricerca')) ||
            (f.nome_esteso && f.nome_esteso.includes(watch('ricerca')))
        ))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('ricerca')])

    //Rimuove paginazione se viene effettuata una ricerca
    useEffect(() => {

        if (watch('ricerca') === '') {
            setFattoriPerPagina(20)
        } else {
            if (fattori) {
                setFattoriPerPagina(fattori?.length)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('ricerca')])

    return (
        <div className="flex flex-col gap-4">
            <form className="form">
                <input type="text" placeholder="Filtra fattori" {...register("ricerca")} />
            </form>

            {!watch('ricerca') &&
                <div>
                    <p>
                        <span>Fattori da {paginaAttiva * fattoriPerPagina} a </span>{
                            fattori &&
                            <>
                                {
                                    fattori.length < fattoriPerPagina ?
                                        fattori.length
                                        :
                                        (paginaAttiva * fattoriPerPagina) + fattoriPerPagina
                                }
                            </>
                        }
                        <span> visualizzati su {fattori && fattori.length}</span>
                    </p>
                </div>
            }

            {watch('ricerca') && fattori && fattori.length === 0 &&
                <p>Nessun fattore trovato per il termine <span className="font-bold">"{watch('ricerca')}"</span></p>
            }
            {
                fattori && fattori.slice(paginaAttiva * fattoriPerPagina, (paginaAttiva * fattoriPerPagina) + fattoriPerPagina).map((fattore) => (
                    <FattoreESGTile
                        link={`/app/modulo/${moduli.moduloAttivo?.codice}/${bilanci.bilancioAttivo?.anno}/fattori_esg/${fattore.codice}`}
                        fattore={fattore}
                    />
                ))
            }

            {numPagine && numPagine > 0 && !watch('ricerca') &&
                <div className="flex items-center justify-center gap-2 mt-4">
                    {(() => {
                        let td = [];
                        for (let i = 0; i <= numPagine; i++) {
                            td.push(
                                <div
                                    key={i}
                                    onClick={() => setPaginaAttiva(i)}
                                    className={`cursor-pointer bg-white w-[30px] h-[30px] flex items-center justify-center ${i === paginaAttiva && 'border border-accent font-bold'}`}
                                >
                                    {i + 1}
                                </div>
                            );
                        }
                        return td;
                    })()}
                </div>
            }
        </div>
    )
}

export default ListaFattoriESG;