import React, { useEffect, useState } from 'react'
import { useStores } from '../../hooks/useStores'
import { getModuliAttivitaUtente } from '../../rest/attivita'
import { observer } from 'mobx-react-lite'

const FiltriAttivita = observer(() => {

    const { attivita } = useStores()

    //Lista moduli attività utente
    const [listaModuli, setListaModuli] = useState<[]>([])
    const [listaMetodi, setListaMetodi] = useState<[]>([])

    //Popola lista moduli e metodi
    useEffect(() => {
        getModuliAttivitaUtente().then((res) => {
            setListaModuli(res.data.moduli)
            setListaMetodi(res.data.metodi)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {

        if (attivita.stringaFiltri) {
            attivita.ripristinaListaAttivita()
            attivita.getListaAttivita(attivita.listaAttivita.length, 8, attivita.stringaFiltri)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attivita.stringaFiltri])

    return (
        <div className="bg-lightgray-d p-4 rounded-sm my-2 flex gap-2 justify-between flex-col md:flex-row">
            {listaMetodi.length > 0 &&
                <div className="grow">
                    <h4 className="mb-2 font-bold">Filtra per attività: </h4>
                    <div className="flex flex-wrap gap-1">
                        {listaMetodi.map((metodo) => (
                            <span
                                key={metodo}
                                onClick={() => attivita.attribuisciFiltri('metodo', metodo)}
                                className={`cursor-pointer bg-accent opacity-70 text-white rounded-sm p-1 duration-100 ${attivita.filtri.metodi.includes(metodo) && 'opacity-100'}`} >
                                {metodo === 'POST' && 'Creazione'}
                                {metodo === 'DELETE' && 'Eliminazione'}
                                {metodo === 'PUT' && 'Modifica'}
                            </span>
                        ))}
                    </div>
                </div>
            }
            {listaModuli.length > 0 &&
                <div className="grow">
                    <h4 className="mb-2 font-bold">Filtra per modulo: </h4>
                    <div className="flex flex-wrap gap-1">
                        {listaModuli.map((modulo) => (
                            <span
                                key={modulo}
                                onClick={() => attivita.attribuisciFiltri('modulo', modulo)}
                                className={`cursor-pointer bg-accent opacity-70 text-white rounded-sm p-1 duration-100 ${attivita.filtri.moduli.includes(modulo) && 'opacity-100'}`} >
                                {modulo}
                            </span>
                        ))}
                    </div>
                </div>
            }
        </div>
    )
})

export default FiltriAttivita;
