import React from 'react'

//Data
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

//Components
import { RichiediResetPasswordForm } from '../components/auth/RichiediResetPasswordForm'

export default function RichiediResetPassword() {
  const { t } = useTranslation();
  return (
    <div className="w-full">

      <h1 className="text-4xl text-text">{t('PaginaResetPassword.richiediPassword')}</h1>
      <p className="text-text-light mt-3 mb-5">
      {t('PaginaResetPassword.mesaggioTesto')}
      </p>

      <RichiediResetPasswordForm />

      <div className="mt-3 text-center">
        <Link className="block mt-5" to="/">{t('common.tornaLogin')}</Link>
      </div>

    </div>
  )
}