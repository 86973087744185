import React from 'react'

const InfoBox: React.FC<{ messaggio: string, id?: string }> = ({ messaggio, id }) => {

  const styles = {
    box: "border border-blue text-center p-2 rounded-sm text-blue shborderadow-lg text-sm dark:text-white dark:bg-blue text-white"
  }

  return (
    <div className={styles.box} id={id}>
      {messaggio}
    </div>
  )
}

export default InfoBox;