import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../hooks/useStores'
import { observer } from 'mobx-react-lite'

//Components
import { Link } from 'react-router-dom'
import PanoramicaRichiesteRicevute from '../amministrazioneUtentiAccessi/Panoramiche/PanoramicaRichiesteRicevute'
import PanoramicaRichiesteGestite from '../amministrazioneUtentiAccessi/Panoramiche/PanoramicaRichiesteGestite'
import PanoramicaCentrale from '../amministrazioneUtentiAccessi/Panoramiche/PanoramicaCentrale'
import PannelloRichiesteGestite from './Pannelli/PannelliRichieste/PannelloRichiesteGestite'

import { BiLogInCircle } from 'react-icons/bi'
import { MdOutlineAddCircleOutline,MdRemoveCircleOutline } from 'react-icons/md'
import PannelloRichiesteAccettate from './Pannelli/PannelliRichieste/PannelloRichiesteAccettate'
import PannelloRichiesteRifiutate from './Pannelli/PannelliRichieste/PannelloRichiesteRifiutate'
export const PannelloRichieste = observer(() => {
    const { t } = useTranslation();
    const { gestioneUtenti } = useStores();

    useEffect(() => {
        gestioneUtenti.getRichieste();

    }, [gestioneUtenti.editingRichiesta])

    return (
        <div>
            <div className='mb-4 text-center lg:text-start'>
            <button className='rounded w-72	 h-6'>
                <Link to="../amministrazione_utenti_e_accessi" className="text-white dark:text-white flex flex-col justify-center h-full uppercase">
                    {t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.pannelloPanoramica')}
                </Link>
            </button>
            </div>
                         
            <div className='text-center mb-6 p-6 relative'>
                <h1 className='text-accent dark:text-dark-accent font-extrabold text-xl lg:text-4xl uppercase'>{t('ModuloGestioneUtenti.PannelloRichieste.gestioneRichieste')}</h1>
                <p className="absolute text-xs lg:text-base top-[80%] right-[25%] left-[25%] text-center">{t('ModuloGestioneUtenti.PannelloRichieste.descrPannello')}</p>
            </div>
            <PanoramicaCentrale
                stili={true}
                componenteUno={<PanoramicaRichiesteRicevute richiesta={gestioneUtenti.richieste} />}
                componenteDue={<PanoramicaRichiesteGestite richiesta={gestioneUtenti.richieste} tipo={false} testo={'richiesteRifiutate'} />}
                componenteTre={<PanoramicaRichiesteGestite richiesta={gestioneUtenti.richieste} tipo={true} testo={'richiesteAccettate'} />}
                Icona1={<MdRemoveCircleOutline className='h-full w-full text-black dark:text-dark-accent' />}
                Icona2={<BiLogInCircle className='h-full w-full text-black dark:text-dark-accent' />}
                Icona3={<MdOutlineAddCircleOutline className='h-full w-full text-black dark:text-dark-accent' />} 
                icona4={null}/>

            <PannelloRichiesteGestite richiesta={gestioneUtenti.richieste}/>

            <PannelloRichiesteAccettate richiesta={gestioneUtenti.richieste}/>

            <PannelloRichiesteRifiutate richiesta={gestioneUtenti.richieste}/>
        </div>
    )
}
)
export default PannelloRichieste