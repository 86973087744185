import React from 'react'
import { IToast } from '../../types';

const Toast: React.FC<{ data: IToast }> = ({ data }) => {


  return (
    <div
      id="toast"
      className={`
      w-[200px] z-[100] fixed
      bg-white dark:bg-black dark:text-white shadow-2xl p-2 border-l-4
      border-${data.colore ? data.colore : 'accent'}
      ${data.posizioneX === 'sinistra' ? 'left-2' : 'right-2' }
      ${data.posizioneY === 'basso' ? 'bottom-2' : 'top-2' }
      `}
    >
      {data.messaggio}
    </div>
  )
}

export default Toast;
