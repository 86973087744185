import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react-lite'
import { useModuloESGStores } from '../../context/useModuloESGStores'
import { IBilancio } from '../../types'

//Components
import InputField from '../../../../components/common/form/InputField'
import ErrorBox from '../../../../components/common/ErrorBox'
import InfoBox from '../../../../components/common/InfoBox'
import SpinnerButton from '../../../../components/common/SpinnerButton'

const BilanciForm: React.FC<{ bilancio?: IBilancio }> = observer(({ bilancio }) => {
  const { t } = useTranslation();

  const { register, handleSubmit, watch, setValue, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      anno: bilancio ? bilancio.anno : '',
      anno_originale: bilancio ? bilancio.anno : '',
      descrizione: bilancio ? bilancio.descrizione : '',
    }
  });

  const { bilanci } = useModuloESGStores();

  const onSubmit = () => {
    bilancio
      ?
      bilanci.modificaBilancio(watch() as IBilancio).then(() => {
        setValue('anno_originale', watch('anno'))
      })
      :
      bilanci.creaBilancio(watch() as IBilancio)
  }

  const [anni, setAnni] = useState<string[]>([])

  useEffect(() => {
    for (let i = 2030; i >= 2010; i--) {
      setAnni((anni: string[]) => [...anni, i.toString()])
    }
  }, [])



  return (
    <div>

      <div className="mb-4">
        <h2 className="text-2xl mb-2">
          {bilancio ?
            `${t('ModuloFattoriEsg.FormFattoreEsgBilanci.modificaBilancioPerAnno')} ${bilancio.anno}`
            :
            `${t('ModuloFattoriEsg.FormFattoreEsgBilanci.creaUnNuovaBilancio')}`
          }
        </h2>
        <p className="text-text-light">
          {bilancio ?
            `${t('ModuloFattoriEsg.FormFattoreEsgBilanci.modificaDatiIBilanci')} ${bilancio.anno}`
            :
            `${t('ModuloFattoriEsg.FormFattoreEsgBilanci.testoModifica')}`
          }
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>


        <div className="form">
          <select id="" {...register('anno', { required: t('ModuloFattoriEsg.FormFattoreEsgBilanci.selezionaAnno') })}>
            <option value="">{t('ModuloFattoriEsg.FormFattoreEsgBilanci.selezionaAnno')}</option>
            {
              anni && anni.map((anno: string) => {
                return <option value={anno}>{anno}</option>
              })
            }
          </select>
        </div>


        <InputField
          label={t('common.descrizione')}
          type="text"
          error={errors.descrizione}
          form={register("descrizione", { required: t('ModuloFattoriEsg.FormFattoreEsgBilanci.fornisciBilancio') })}
          placeholder={t('ModuloFattoriEsg.FormFattoreEsgBilanci.placeholderBusinessunit')}
        />

        {/* Submit modifica bilancio */}
        {bilancio &&

          <>
            <div className="form">
              <div>
                {!bilanci.editingBilancio ? <input type="submit" value={t('common.salvaModifica')} /> : <SpinnerButton />}
              </div>
            </div>

            {
              isSubmitSuccessful && !bilanci.editingBilancio && (

                bilanci.modificaBilancioError
                  ?
                  <div className="my-2">
                    <ErrorBox errore={bilanci.modificaBilancioError} />
                  </div>
                  :
                  <div className="my-2">
                    <InfoBox messaggio={t('common.modificaSalvato')} />
                  </div>

              )
            }

          </>
        }

        {/* Submit creazione modulo */}
        {!bilancio &&

          <>
            {
              (isSubmitSuccessful && !bilanci.creatingBilancioError && !bilanci.creatingBilancio) ?
                <InfoBox messaggio="Bilancio creato con successo" id={"crea-Bilancio-success"} />
                :
                <div className="form pt-4">
                  {!bilanci.creatingBilancio ? <input type="submit" value={t('ModuloFattoriEsg.FormFattoreEsgBilanci.creaBilancio')} id="crea-Bilancio-submit" /> : <SpinnerButton />}
                </div>
            }

            {
              bilanci.creatingBilancioError &&
              <div className="mb-2">
                <ErrorBox errore={bilanci.creatingBilancioError.toString()} />
              </div>
            }

          </>
        }
      </form>
    </div>
  )
})

export default BilanciForm;