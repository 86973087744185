import React, { useEffect, useState } from 'react'

//Data
import { observer } from 'mobx-react-lite'
import { useStores } from '../../hooks/useStores'
import { useTranslation } from 'react-i18next'
import { getLinguaLista } from '../../moduli/gestioneLingue/rest/gestioneLingue'

//Components
import ErrorBox from '../common/ErrorBox'
import { MdLanguage } from 'react-icons/md'
import { ILingua } from '../../types'

const ModificaLinguaForm = observer(() => {

  const { t } = useTranslation();
  const { auth } = useStores()

  const [listaLingue, setListaLingue] = useState<ILingua[]>([])
  const [linguaAttiva, setLinguaAttiva] = useState<string>('')

  // Setta la lista delle lingue
  useEffect(() => {
    getLinguaLista().then((res) => {
      setListaLingue(res.data.data)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Setta la lingua attiva in caso non esista una preferenza
  // da parte dell'utente
  useEffect(() => {

    if (listaLingue && listaLingue.length > 0 && !auth.utente?.lingua) {
      setLinguaAttiva(listaLingue[0].codice)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listaLingue])

  //Setta la lingua preferita dall'utente
  useEffect(() => {

    if (auth.utente?.lingua) {
      setLinguaAttiva(auth.utente?.lingua)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.utente?.lingua])

  const onSubmit = () => {

    auth.settaLingua(linguaAttiva).finally(() => {

      setTimeout(() => {
        window.location.reload()
      }, 2000)

    })

  }

  return (
    <div className={listaLingue.length === 1 ? 'hidden' : 'block'}>
      <h6 className="text-xl mb-2 flex items-center gap-2">
        <MdLanguage size={24} />{t('FormModificaLingua.titolo')}
      </h6>
      <div className="w-full">
        <div className="flex gap-2">
          {listaLingue.map((lingua: ILingua) => (
            <div
              key={lingua.codice}
              onClick={() => setLinguaAttiva(lingua.codice)}
              className={`grow text-center p-2 duration-100 cursor-pointer font-bold flex gap-2 items-center ${lingua.codice === linguaAttiva ? 'border border-accent text-accent dark:bg-dark-accent dark:text-white' : 'bg-lightgray-dd dark:bg-darkgray-dd opacity-70'}`}
            >
              <span className={`fi fi-${lingua.bandiera}`} />
              <span>{lingua.nome}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="form">

        {auth.settingLingua ?
          <input type="submit" value="Aggiornando lingua" disabled />
          :
          <input onClick={() => onSubmit()} type="submit" value={t('FormModificaLingua.bottone')} />

        }
        {auth.settingLinguaError &&
          <div className="mt-2">
            <ErrorBox errore={auth.settingLinguaError} />
          </div>
        }

      </div>
    </div>
  )
})

export default ModificaLinguaForm;