import React, { useEffect } from 'react'
import { INotizia } from '../../types'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { useStores } from '../../hooks/useStores'
import { useTranslation } from 'react-i18next'
import Dialogue from '../../components/common/Dialogue'
import useOttieniPrivilegioModulo from '../../hooks/useOttieniPrivilegioModulo'

const NotiziaTile: React.FC<{ notizia: INotizia, stato: 'pubblicato' | 'bozza' | 'archiviata' }> = ({ notizia, stato }) => {

    const { t, i18n } = useTranslation();

    const { gestioneNotizie, ui } = useStores();
    const privilegio = useOttieniPrivilegioModulo();

    let formatoData = t('common.dateFormat')

    return (
        <div className="bg-white dark:bg-darkgrary-dd p-4 md:p-5 rounded-md shadow-xl">
            <div className="font-bold text-accent">
                {
                    stato === 'pubblicato' &&
                    <p>{t('ModuloNotizie.State.published')}: {dayjs(notizia.published_at).locale(i18n.language).format(formatoData)}</p>
                }

                {
                    stato === 'bozza' &&
                    <p>{t('ModuloNotizie.State.created')}: {dayjs(notizia.created_at).locale(i18n.language).format(formatoData)}</p>
                }

                {
                    stato === 'archiviata' &&
                    <p>{t('ModuloNotizie.State.archived')}: {dayjs(notizia.deleted_at).locale(i18n.language).format(formatoData)}</p>
                }

                <p>{notizia.created_at !== notizia.updated_at && `${t('ModuloNotizie.State.modified')}: ${dayjs(notizia.updated_at).locale(i18n.language).format(formatoData)}`}</p>
            </div>
            <h2 className="text-3xl mt-2">{notizia.titolo.substring(0, 30)}{notizia.titolo.length > 30 && '...'}</h2>
            <div className="flex gap-2 mt-4 font-bold">
                {
                    stato === 'pubblicato' &&
                    <>  
                        {
                            <div
                                className="text-accent dark:text-dark-accent cursor-pointer"
                                onClick={() => gestioneNotizie.passaInBozza(notizia.id.toString())} >
                                {t('ModuloNotizie.Actions.draft')}
                            </div>
                        }
                        {
                            privilegio >= 3 &&
                            <Link to={`/app/modulo/notizie/crea/${notizia.id.toString()}`} className="cursor-pointer">
                                {t('ModuloNotizie.Actions.modify')}
                            </Link>
                        }
                        {
                            privilegio >= 3 &&
                            <div className="cursor-pointer text-red dark:text-dark-red" onClick={() =>
                                ui.mountDialogue(

                                    <Dialogue
                                        testo={`${t('ModuloNotizie.Actions.archiveConfirm')} "${notizia.titolo}"?`}
                                        primaryCta={`${t('ModuloNotizie.Actions.archive')} "${notizia.titolo}"`}
                                        primaryCtaColor="bg-red dark:bg-dark-red"
                                        secondaryCta={t('ModuloNotizie.Actions.cancel')}
                                        secondaryCtaColor="bg-white dark:bg-darkgray-dd !text-text dark:!text-dark-text"
                                        onConfirm={() => gestioneNotizie.archivia(notizia.id.toString())}
                                    />

                                )}  >
                                {t('ModuloNotizie.Actions.archive')}
                            </div>
                        }
                    </>


                }
                {
                    stato === 'bozza' &&
                    <>
                        {/* <div className="cursor-pointer">
                            Visualizza
                        </div> */}
                        {privilegio >= 3 &&
                            <div
                                className="text-accent dark:text-dark-accent cursor-pointer"
                                onClick={() => gestioneNotizie.pubblica(notizia.id.toString())} >
                                {t('ModuloNotizie.Actions.publish')}
                            </div>
                        }
                        {privilegio >= 3 &&
                            <Link to={`/app/modulo/notizie/crea/${notizia.id.toString()}`} className="cursor-pointer">
                                {t('ModuloNotizie.Actions.modify')}
                            </Link>
                        }
                        {privilegio >= 3 &&
                            <div
                                className="cursor-pointer text-red dark:text-dark-red"
                                onClick={() =>
                                    ui.mountDialogue(

                                        <Dialogue
                                            testo={`${t('ModuloNotizie.Actions.archiveConfirm')} "${notizia.titolo}"?`}
                                            primaryCta={`${t('ModuloNotizie.Actions.archive')} "${notizia.titolo}"`}
                                            primaryCtaColor="bg-red dark:bg-dark-red"
                                            secondaryCta={t('ModuloNotizie.Actions.cancel')}
                                            secondaryCtaColor="bg-white dark:bg-darkgray-dd !text-text dark:!text-dark-text"
                                            onConfirm={() => gestioneNotizie.archivia(notizia.id.toString())}
                                        />

                                    )} >
                                {t('ModuloNotizie.Actions.archive')}
                            </div>
                        }
                    </>

                }
                {
                    stato === 'archiviata' &&
                    <>
                        {privilegio >= 3 &&
                            <div className="cursor-pointer text-accent dark:text-dark-accent" onClick={() =>
                                gestioneNotizie.ripristina(notizia.id.toString())} >
                                {t('ModuloNotizie.Actions.restore')}
                            </div>
                        }
                        {privilegio >= 4 &&
                            <div className="cursor-pointer text-red dark:text-dark-red" onClick={() =>
                                ui.mountDialogue(
                                    <Dialogue
                                        testo={`${t('ModuloNotizie.Actions.deleteConfirm')} ${notizia.titolo}? `}
                                        primaryCta={`${t('ModuloNotizie.Actions.delete')} ${notizia.titolo}`}
                                        primaryCtaColor="bg-red dark:bg-dark-red"
                                        secondaryCta="annula"
                                        secondaryCtaColor="bg-white dark:bg-darkgray-dd !text-text dark:!text-dark-text"
                                        onConfirm={() => gestioneNotizie.elimina(notizia.id.toString())}
                                    />


                                )} >

                                {t('ModuloNotizie.Actions.delete')}
                            </div>
                        }
                    </>

                }
            </div>
        </div>
    )
}

export default NotiziaTile;