import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import IpeLogo from '../components/common/IpeLogo'
import { useStores } from '../hooks/useStores'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { config } from '../config'
import { runInAction } from 'mobx'

export default function AzureAutenticazione(this: any) {

  const { t, i18n } = useTranslation()

  const queryParameters = new URLSearchParams(window.location.search)
  const token = queryParameters.get("token")
  const lang = queryParameters.get("lang")
  const [message, setMessage] = useState();
  const { ui } = useStores()

  i18n.changeLanguage(lang?.toString())
  useEffect(() => {

    axios.get(`${config.API_URI}oauth/authentication/${token}`,).then(res => {

      //Setta un item nel local storage
      localStorage.setItem("utente", JSON.stringify(res.data.utente));

      //Setta cookie jwt
      localStorage.setItem("token", res.data.token);

      //redirect in app
      window.location.href = '/app'
    }).catch(err => {
      //Setta errore nello stato
      setMessage(err.response.data.message);
      this.setState({ errorMessage: err.response.data.message });
      runInAction(() => this.getUtenteError = err.response.data.message ? err.response.data.message : 'Errore generico')

      //Ripristina stato get user
      runInAction(() => this.gettingUtente = false)
    });

  });

  const styles = {
    wrapper: "h-screen w-full flex flex-col items-center justify-center gap-2 text-center",
  }

  const navigate = useNavigate()

  const { auth } = useStores()

  return (
    <div className={styles.wrapper} >

      <div className="mb-5 flex flex-col items-center gap-3">

        <IpeLogo width={40} />

        {message &&
          <div className="text-5xl font-bold">{message}
          </div>}
        {!message &&
          <div>
            <h1 className="text-5xl font-bold"> {t('PaginaAzureAuthenticazione.verificazione')}</h1>
          </div>
        }
      </div>

      <div className="flex flex-col gap-2">

        {auth.utente &&
          <button onClick={() => navigate(-1)} className="bg-accent text-white p-2 rounded-sm">
            {t('Pagina404.tornaIndietro')}
          </button>
        }

        <Link to="/" className="bg-accent text-white p-2 rounded-sm">
          {t('Pagina404.tornaPaginaPricipale')}
        </Link>
      </div>

    </div>
  )
}
