import React, { useEffect, useState } from 'react'

//Data
import { observer } from 'mobx-react-lite'
import { Link, useParams } from 'react-router-dom'
import { useStores } from '../../../hooks/useStores'
import { UseModuloBusinessUnitsStore } from '../context/UseModuloBusinessUnitsStore'
import { getBusinessUnit } from '../rest/business_units'
import { IBusinessUnit, IGruppoBusinessUnit, IBusinessUnitRuolo } from '../types'
import { getRuoli } from '../UiModuloBusinessUnitRuoli/rest/BusinessUnitRuoli'
import { useTranslation } from 'react-i18next'

//Components
import BusinessUnitsForm from './BusinessUnitsForm'
import CanvasBusinessUnits from './canvasBusinessUnit/CanvasBusinessUnits'
import { FaAngleDoubleLeft, FaPen } from 'react-icons/fa'
import GruppoBusinessUnitTile from './GruppoBusinessUnitTile'
import SchedaBusinessUnitMappa from './SchedaBusinessUnitMappa'

const SchedaBusinessUnit = observer(() => {

    const { t } = useTranslation();
    const [businessUnit, setBusinessUnit] = useState<IBusinessUnit>()
    const [BusinessUnitRuoli, setBusinessUnitRuoli] = useState<IBusinessUnitRuolo[]>()
    const { business_unit_nome } = useParams()
    const { ui, moduli } = useStores()
    const { business_units } = UseModuloBusinessUnitsStore()

    useEffect(() => {

        getBusinessUnit(business_unit_nome as string).then((res) => {
            setBusinessUnit(res.data.data)
        })

    }, [
        business_units.businessUnitCreata,
        business_units.businessUnitModificata,
        business_units.businessUnitEliminata,
        business_units.ruoloAttribuito,
        business_unit_nome
    ])


    useEffect(() => {
        getRuoli().then((res) => {
            setBusinessUnitRuoli(res.data.data)
        })
    }, [])

    return (
        <div>
            <Link to="/app/modulo/business_unit" className="bg-accent p-1 text-white dark:bg-dark-accent mb-4 flex items-center gap-2">
                <FaAngleDoubleLeft />
                <span>{t('ModuloBusinessUnit.SchedaBusinessUnit.tornaAllaStratura')}</span>
            </Link>
            <div className="flex flex-col">
                <div>
                    {businessUnit &&
                        <div className="bg-white dark:darkgray-dd p-4 rounded-sm shadow-xl min-w-[300px]">
                            <div className="flex flex-col-reverse gap-2 md:flex-row items-start justify-between">

                                <div className="flex items-start gap-3 flex-col md:flex-row">
                                    {businessUnit.longitudine && businessUnit.latitudine &&
                                        <SchedaBusinessUnitMappa businessUnit={businessUnit} />
                                    }

                                    <div>
                                        {businessUnit.categoria && <p className="font-bold bg-accent dark:bg-dark-accent text-white py-[4px] px-[8px] rounded-sm inline-block mb-2">Tipo: {businessUnit.categoria}</p>}
                                        <h1 className="text-2xl font-bold mb-2">{businessUnit.nome}</h1>
                                        <p><b>{t('common.categoria')}:</b> {businessUnit.categoria ? businessUnit.categoria : '-'}</p>
                                        {businessUnit.via &&
                                            <p>
                                                <b>{t('ModuloBusinessUnit.SchedaBusinessUnit.indrizzo')}:</b>
                                                <span>
                                                    {businessUnit.via && `Via ${businessUnit.via}`} {businessUnit.civico}
                                                    {businessUnit.cap && `, ${businessUnit.cap}`}
                                                    {businessUnit.citta && `, ${businessUnit.citta}`}
                                                    {businessUnit.paese && `, ${businessUnit.paese}`}
                                                </span>
                                            </p>
                                        }

                                        {businessUnit.longitudine && businessUnit.latitudine &&
                                            <>
                                                <br />

                                                <p>
                                                    <b>{t('ModuloBusinessUnit.SchedaBusinessUnit.coordinate')}:</b> <br />
                                                    <p>{t('ModuloBusinessUnit.FormBusinessUnit.lat')}: {businessUnit.latitudine}</p>
                                                    <p>{t('ModuloBusinessUnit.FormBusinessUnit.long')}: {businessUnit.longitudine}</p>
                                                </p>
                                            </>
                                        }


                                        {businessUnit.descrizione &&
                                            <>
                                                <br />
                                                <p>
                                                    <b>{t('common.descrizione')}:</b> <br />
                                                    {businessUnit.descrizione}
                                                </p>
                                            </>
                                        }

                                    </div>
                                </div>
                                <FaPen className="cursor-pointer hover:opacity-80" onClick={() => ui.mountAsideModal(<BusinessUnitsForm businessUnit={businessUnit} />)} />
                            </div>

                            {businessUnit.gruppi.length > 0 &&
                                <div>
                                    <hr className="my-4 border border-lightgray-dd dark:darkgray" />
                                    <h2 className="font-bold text-lg mb-2">{businessUnit.gruppi.length} {businessUnit.gruppi.length === 1 ? 'ruolo' : 'ruoli'}  {t('ModuloBusinessUnit.SchedaBusinessUnit.attiviPerQuestoBussinessUnit')}:</h2>
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
                                        {
                                            businessUnit.gruppi.map((gruppo: IGruppoBusinessUnit) => {
                                                return (
                                                    <GruppoBusinessUnitTile gruppo={gruppo} ruoli={BusinessUnitRuoli as IBusinessUnitRuolo[]} nomeBu={businessUnit.nome} />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>

                {businessUnit?.sotto_business_unit && businessUnit?.sotto_business_unit.length > 0 &&
                    <>
                        <h2 className="font-bold text-2xl my-6">{t('ModuloBusinessUnit.SchedaBusinessUnit.alberaturaSottoBusinessUnit')}</h2>
                        <CanvasBusinessUnits
                            businessUnits={businessUnit?.sotto_business_unit}
                            sfondo={false}
                            link={`/app/modulo/${moduli.moduloAttivo?.codice}/`}
                        />
                    </>
                }

            </div>
        </div>
    )
})

export default SchedaBusinessUnit;