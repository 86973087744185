import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { observer } from 'mobx-react-lite'
import { IModulo } from '../../types'
import { getModuliUtente } from '../gestioneModuli/rest/gestioneModuli'

//Components
import { Link, useSearchParams } from 'react-router-dom'
import TextLoader from '../../components/common/TextLoader'
import IconaDinamica from '../../components/common/IconaDinamica'

const ListaModuli = observer(() => {

  const [searchParams] = useSearchParams()
  const { t } = useTranslation();

  const [moduli, setModuli] = useState<IModulo[]>([]);
  const [moduloAttivo, setModuloAttivo] = useState<IModulo | null>();

  useEffect(() => {
    getModuliUtente().then((res) => setModuli(res.data.data))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {

    if (searchParams.get('modulo')) {

      let moduloPrecaricato = moduli.find(modulo => modulo.codice === searchParams.get('modulo'))
      setModuloAttivo(moduloPrecaricato);

    } else {

      setModuloAttivo(moduli[0]);

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduli])

  const styles = {
    bottoneModulo: "p-4 hover:text-accent dark:hover:text-white cursor-pointer duration-100 flex items-center gap-2 bg-white dark:bg-darkgray-dd"
  }

  return (
    <div>
      <h2 className="text-xl font-bold">{t('ListaModuli.listaDeiModuli')}</h2>

      <div className="flex gap-4 flex-col md:flex-row mt-4 items-start">
        <div className="max-h-[400px] bg-lightgray-d dark:bg-darkgray-d rounded-sm overflow-y-scroll grid grid-cols-2 md:grid-cols-1 w-full md:max-w-[250px] gap-1">
          {moduli.map(modulo => (
            <div onClick={() => setModuloAttivo(modulo)} className={`${styles.bottoneModulo} ${moduloAttivo?.codice === modulo.codice && 'border-l-[5px] border-accent dark:border-dark-accent'}`}>
              <IconaDinamica nome={modulo.icona} />
              <span>{modulo.nome}</span>
            </div>
          )
          )}
        </div>

        <div className="bg-white dark:bg-darkgray-dd p-4 w-full rounded-sm shadow-xl">
          {moduloAttivo ?
            <div className="flex items-center justify-between">
              <div className="flex gap-2 items-center">
                <IconaDinamica nome={moduloAttivo.icona} />
                <h3 className="font-bold">{moduloAttivo?.nome}</h3>
              </div>

              <Link to={`/app/modulo/${moduloAttivo?.codice}`} >
                /{moduloAttivo?.codice}
              </Link>
            </div>
            :
            <TextLoader />
          }
          <hr className="my-2 border-lightgray-dd dark:border-darkgray" />
          {moduloAttivo ?
            <p dangerouslySetInnerHTML={
              { __html: moduloAttivo?.descrizione_estesa as string }
            } />
            :
            <TextLoader />}
        </div>

      </div>

    </div>
  )
})

export default ListaModuli;