import React from 'react'
import { useTranslation } from 'react-i18next'

import { useStores } from '../../../hooks/useStores'

import { FaPlus } from 'react-icons/fa'
import CreaGruppoForm from './CreaGruppoForm'



export default function AggiungiGruppo() {
  const { t } = useTranslation();

  const { ui } = useStores()

  const styles = {
    wrapper: "bg-blue text-white font-bold py-2 px-4 flex flex-col items-center gap-2 mt-5 p-10 hover:opacity-70 dark:bg-darkgray-dd"
  }

  return (
    <div className={styles.wrapper} onClick={() => ui.mountAsideModal(<CreaGruppoForm />)}>
      <FaPlus size={30} />
      {t('common.aggiungiGruppo')} 
    </div>
  )
}