import React, { useEffect } from 'react'
import { useStores } from '../../hooks/useStores'
import { config } from '../../config'
import { useTranslation } from 'react-i18next'

//Data
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react-lite'
import { IRichiesta } from '../../types'
import { Link } from 'react-router-dom'

//Components
import SpinnerButton from '../common/SpinnerButton'
import InfoBox from '../common/InfoBox'
import ErrorBox from '../common/ErrorBox'
import TestoErrore from '../common/TestoErrore'

const IscrizioneForm = observer(() => {

  const { t, i18n } = useTranslation();

  const { gestioneUtenti } = useStores()

  const { register, handleSubmit, watch, setValue, formState: { errors, isSubmitSuccessful } } = useForm();

  const onSubmit = () => {
    gestioneUtenti.creaRichiesta(watch() as IRichiesta)
  }

  //Setta parametro lingua
  useEffect(() => {
    setValue('lingua', i18n.language)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full flex gap-1">
        <div className="form">
          <label htmlFor="">{t('common.nome')}</label>
          <input {...register("nome", { required: t('common.fornisciNome') })} placeholder={t('common.nome')} type="text" />

          {errors.nome && <TestoErrore errore={errors.nome.message} />}
        </div>

        <div className="form">
          <label htmlFor="">{t('common.cognome')}</label>
          <input {...register("cognome", { required: t('common.fornisciCognome') })} placeholder={t('common.cognome')} type="text" />

          {errors.cognome && <TestoErrore errore={errors.cognome.message} />}
        </div>
      </div>

      <div className="form">
        <label htmlFor="">{t('common.azienda')}</label>
        <input {...register("azienda", { required: t('common.specificaAzienda') })} placeholder={t('common.azienda')} type="text" />

        {errors.azienda && <TestoErrore errore={errors.azienda.message} />}
      </div>

      <div className="w-full flex gap-1">
        <div className="form">
          <label htmlFor="">{t('common.eMail')}</label>
          <input {...register("email", { required: t('common.fornisciEmail') })} placeholder={t('common.eMail')} type="email" />

          {errors.email && <TestoErrore errore={errors.email.message} />}
        </div>

        <div className="form">
          <label htmlFor="">{t('common.telefono')}</label>
          <input {...register("telefono", {  pattern: { value: config.REGEX_TELEFONO, message: 'Dev\'essere un numero di telefono valido' } })} placeholder={t('common.telefono')} type="text" />

          {errors.telefono && <TestoErrore errore={errors.telefono.message} />}
        </div>
      </div>

      <div className="form flex gap-2 bg-lightgray p-3 mt-2 rounded-sm">
        <input {...register("trattamento_dati", { required: t('FormIscrizione.deviAcconsentire'), pattern: { value: config.REGEX_TELEFONO, message: 'Dev\'essere un numero di telefono valido' } })} placeholder="Telefono" type="checkbox" />
        <label htmlFor="">
          {t('FormIscrizione.messaggioDati')}
          <Link to="/informativa-dati-termini-uso">
            {t('FormIscrizione.testoLink')}
          </Link>
        </label>
      </div>

      {errors.trattamento_dati && <TestoErrore errore={errors.trattamento_dati.message} />}

      <div className="form">

        {
          !gestioneUtenti.richiestaCreata &&
            <>
              {
                !gestioneUtenti.creatingRichiesta ? <input type="submit" value={t('common.richiediAccesso')} /> : <SpinnerButton />
              }
            </>
        }

        {
          isSubmitSuccessful && !gestioneUtenti.creatingRichiesta &&
          <div className="mt-2">
            {
              gestioneUtenti.creatingRichiestaError ?
                <ErrorBox errore={gestioneUtenti.creatingRichiestaError} />
                :
                <InfoBox messaggio={t('FormIscrizione.messaggioSuccesso')} />
            }
          </div>
        }
      </div>
    </form>
  )
})

export default IscrizioneForm;