import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getListaBusinessUnits } from './rest/business_units'

//Data
import { observer } from 'mobx-react-lite'
import { useStores } from '../../hooks/useStores'

//Components
import AggiungiButton from '../../components/common/AggiungiButton'
import BusinessUnitsForm from './components/BusinessUnitsForm'
import CanvasBusinessUnits from './components/canvasBusinessUnit/CanvasBusinessUnits'
import { UseModuloBusinessUnitsStore } from './context/UseModuloBusinessUnitsStore'
import { IBusinessUnit } from './types'
import useOttieniPrivilegioModulo from '../../hooks/useOttieniPrivilegioModulo'

const ArchivioBusinessUnits = observer(() => {

    const { ui } = useStores()
    const { t } = useTranslation();
    const privilegio = useOttieniPrivilegioModulo();
    const { business_units } = UseModuloBusinessUnitsStore();
    const [businessUnits, setBusinessUnits] = useState<IBusinessUnit[]>();
    const [infoBusinessUnits, setInfoBusinessUnits] = useState(false);

    useEffect(() => {

        getListaBusinessUnits().then((res) => {
            setBusinessUnits(res.data.data)
        })

    }, [
        business_units.businessUnitCreata,
        business_units.businessUnitModificata,
        business_units.businessUnitEliminata,
        business_units.ruoloAttribuito
    ])

    return (
        <div>
            <div className="my-4">
                <h2 className="text-2xl font-bold mb-3">{t('ModuloBusinessUnit.ArchivioBussinessUnit.testoStrutturaOrganizzativa')}</h2>
                {privilegio >= 2 &&
                    <p>
                        {t('ModuloBusinessUnit.ArchivioBussinessUnit.testoIntegrando')} <br />
                        {infoBusinessUnits &&
                            <p>
                                {t('ModuloBusinessUnit.ArchivioBussinessUnit.testoEPossibile')}
                                <br />
                                <br />
                                {t('ModuloBusinessUnit.ArchivioBussinessUnit.testoLeBusinessUnit')}

                            </p>
                        }
                        <p className="text-accent dark:text-dark-accent cursor-pointer" onClick={() => setInfoBusinessUnits(!infoBusinessUnits)}>
                            {infoBusinessUnits ? t('ModuloBusinessUnit.ArchivioBussinessUnit.chiudi') : t('ModuloBusinessUnit.ArchivioBussinessUnit.leggiPiu')}
                        </p>
                    </p>
                }
            </div>

            <CanvasBusinessUnits businessUnits={businessUnits as IBusinessUnit[]} />

            <div className="my-4" onClick={() => ui.mountAsideModal(<BusinessUnitsForm />)}>
                <AggiungiButton testo={t('ModuloBusinessUnit.ArchivioBussinessUnit.creaNuovaBusinessUnit')} />
            </div>
        </div>
    )
})

export default ArchivioBusinessUnits;