import { action, makeAutoObservable, observable } from 'mobx'
export class UiCanvasBusinessUnitsStore {

    modalitaVista: 'default' | 'minificata' | 'mappa' = 'default'
    latitudine: number | null = null
    longitudine: number | null = null

    constructor() {
        makeAutoObservable(this, {
            modalitaVista: observable,
            settaModalitaVista: action,

            latitudine: observable,
            longitudine: observable,
            resettaCoordinate: action
        })
    }

    settaModalitaVista = (modalita: 'default' | 'minificata' | 'mappa', latitudine?: number, longitudine?: number) => {
        this.modalitaVista = modalita

        if(latitudine) {
            this.latitudine = latitudine
        }

        if (longitudine) {
            this.longitudine = longitudine
        }
    }

    resettaCoordinate = () => {
        this.latitudine = null
        this.longitudine = null
    }

}