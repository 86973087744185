import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
//Data
import { IObiettivoAgenda2030 } from '../../types'
import { observer } from 'mobx-react-lite'
import { Link, useParams } from 'react-router-dom'

//Components
import { FaAngleLeft } from 'react-icons/fa'
import { useModuloAgenda2030Stores } from './context/useModuloAgenda2030Stores'
import ObiettivoAgenda2030Square from './components/ObiettivoAgenda2030Square'

const SchedaObiettivoAgenda2030 = observer(() => {
  const { t } = useTranslation();

  const { agenda_2030 } = useModuloAgenda2030Stores()

  const { obiettivo_id } = useParams()

  useEffect(() => {
    agenda_2030.getObiettivoAgenda2030(Number(obiettivo_id))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    agenda_2030.obiettivoAgenda2030Modificato
  ])

  return (
    <div>

      <div className="flex items-stretch gap-2">
        <Link
          className="grow bg-accent text-white flex items-center justify-start gap-2 p-2 dark:bg-dark-accent"
          to={`/app/modulo/agenda_2030`}>
          <FaAngleLeft />
          <span>{t('ModuloFattoriEsg.SchedaObiettivoAgenda2030.TornaAllaLista')}</span>
        </Link>
      </div>


      {
        agenda_2030.obiettivoAgenda2030Attivo &&
        <div className="my-2 flex flex-col sm:flex-row items-stretch gap-4">
          <div>
            <ObiettivoAgenda2030Square
              obiettivo={agenda_2030.obiettivoAgenda2030Attivo as IObiettivoAgenda2030}
              altezza={300}
            />
          </div>
          <div>
            {
              agenda_2030.obiettivoAgenda2030Attivo.descrizione ?
                <div>
                  <p className="font-bold">{t('common.descrizione')}:</p>
                  {agenda_2030.obiettivoAgenda2030Attivo.descrizione}
                </div>
                :
                <p>{t('ModuloFattoriEsg.SchedaObiettivoAgenda2030.NonePossibileDescrizione')}</p>
            }
          </div>
        </div>
      }



    </div>
  )
})

export default SchedaObiettivoAgenda2030;