import React, { useEffect, useState } from 'react'
import { IGruppo, IUtente } from "../../types";
import { FaAngleDoubleDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'

const UtenteGruppi: React.FC<{ utente: IUtente }> = ({ utente }) => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col gap-2">
            {utente.gruppi.length > 0 ?
                utente.gruppi.map((gruppo: IGruppo) => {
                    {
                        return (
                                <div className="bg-lightgray p-2 rounded-sm">
                                    <span>{gruppo.nome}</span>
                                </div>
                        )
                    }
                })
                :
                <div> {t('ModuloGestioneUtenti.TileUtente.nessunoGruppo')}
                </div>

            }
        </div>
    )
}
export default UtenteGruppi;
