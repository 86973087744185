import React, { SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useForm, Controller } from 'react-hook-form'
import { useStores } from '../../hooks/useStores'
import ErrorBox from '../../components/common/ErrorBox'
import InfoBox from '../../components/common/InfoBox'
import { observer } from 'mobx-react-lite'
import { IGruppoForm, IUtente } from '../../types'
import { config } from '../../config'

//Components
import TestoErrore from '../../components/common/TestoErrore'
import SpinnerButton from '../../components/common/SpinnerButton'


const AggiornamentiContattiForm: React.FC<{ utente: IUtente }> = observer(({ utente }) => {
  const { t } = useTranslation();

  const { gestioneUtenti } = useStores();

  //Istanzia Hook Form
  const { watch, register, handleSubmit, control, setError, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      'id': utente.id,
      'email': utente.email,
      'telefono': utente.telefono,
      'emailmodify':'',
      'emailModifyRepeat':''
    }
  })

  //Gestisce submit
  const onSubmit = () => {
    if (watch('emailmodify') !== watch('emailModifyRepeat')) {

      setError('emailModifyRepeat', { type: "custom", message: t('ModuloGestioneUtenti.FormModificaContatti.emailNonECorretta') })

    }else{
      gestioneUtenti.aggiornaContatti(watch() )
    }
  }

  return (
    <div>

      <div className="mb-4">
        <h2 className="text-2xl mb-2">{t('ModuloGestioneUtenti.FormModificaContatti.modificaEmail')} {utente.nome}</h2>
        <p className="text-text-light">{t('ModuloGestioneUtenti.FormModificaContatti.modificaEmailText')} {utente.nome} {t('ModuloGestioneUtenti.FormModificaContatti.testoModificaEmail')}</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div >
          <div className="form opacity-50">
            <label htmlFor="">{t('common.eMail')}</label>
            <input disabled  {...register("email", { required: t('common.fornisciEmail') })} placeholder={t('common.eMail')} type="email" />

            {errors.email && <TestoErrore errore={errors.email.message} />}
          </div>
          <div className="form w-full">
            <label htmlFor="">{t('common.nuovaEmail')}</label>
            <input  {...register("emailmodify", { required: t('common.fornisciEmail') })} placeholder={t('common.eMail')} type="email" />

            {errors.emailmodify && <TestoErrore errore={errors.emailmodify.message} />}
          </div>
          <div className="form w-full">
            <label htmlFor="">{t('common.ripetiNuovaEmail')}</label>
            <input  {...register("emailModifyRepeat", { required: t('common.fornisciEmail') })} placeholder={t('common.eMail')} type="email" />

            {errors.emailModifyRepeat && <TestoErrore errore={errors.emailModifyRepeat.message} />}
          </div>
          <div className="form w-full opacity-50">
            <label htmlFor="">{t('common.telefono')}</label>
            <input disabled {...register("telefono", {  pattern: { value: config.REGEX_TELEFONO, message: t('common.telefonoMessagio') } })} placeholder={t('common.telefono')} type="text" />

            {errors.telefono && <TestoErrore errore={errors.telefono.message} />}
          </div>
        </div>

        <div className="form">
          {!gestioneUtenti.modificandoContatti ?

            <input type="submit" value= {t('common.salvaModifica')} />
            :
            <SpinnerButton />
          }
        </div>

        {gestioneUtenti.modificaContattiError && (
      
          <div className="my-2">
            <ErrorBox errore={gestioneUtenti.modificaContattiError} />
          </div>
        )
        }
        {isSubmitSuccessful && !gestioneUtenti.modificaContattiError && !gestioneUtenti.modificandoContatti && (
          <div className="my-2">
            <InfoBox messaggio={t('common.modificaSalvato')} />
          </div>
        )
        }

      </form>
    </div>
  )
})
export default AggiornamentiContattiForm;