import { IAttivita } from '../../types';

import { creaStringStandardPerAttivita } from '../../hooks/attivita/creaStringStandardPerAttivita';

export default function factoryAttivitaTemiMaterialita(attivita: IAttivita) {

    let codiceInterpretato = '';

    switch (attivita.route) {

        case 'TemaMaterialita.crea':
            attivita.user_name?
            codiceInterpretato = `${attivita.user_name} ha <span class="azione bg-accent dark:bg-dark-accent">creato</span> il tema di materialità <a target="_blank" href="/app/modulo/materialita/${JSON.parse(attivita.request_corpo)?.codice}">${JSON.parse(attivita.request_corpo)?.nome}</a>`
            :
            codiceInterpretato = `Hai <span class="azione bg-accent dark:bg-dark-accent">creato</span> il tema di materialità <a target="_blank" href="/app/modulo/materialita/${JSON.parse(attivita.request_corpo)?.codice}">${JSON.parse(attivita.request_corpo)?.nome}</a>`
            break;

        case 'TemaMaterialita.modifica':
            attivita.user_name?
            codiceInterpretato = `${attivita.user_name} ha <span class="azione bg-orange dark:bg-dark-orange">modificato</span> il tema di materialità <a target="_blank" href="/app/modulo/materialita/${ attivita.uri.split('/')[attivita.uri.split('/').length - 1] }">${ attivita.uri.split('/')[attivita.uri.split('/').length - 1] }</a>`
            :
            codiceInterpretato = `Hai <span class="azione bg-orange dark:bg-dark-orange">modificato</span> il tema di materialità <a target="_blank" href="/app/modulo/materialita/${ attivita.uri.split('/')[attivita.uri.split('/').length - 1] }">${ attivita.uri.split('/')[attivita.uri.split('/').length - 1] }</a>`
            break;

        case 'TemaMaterialita.elimina':
            attivita.user_name?
            codiceInterpretato = `${attivita.user_name} ha <span class="azione bg-red dark:bg-dark-red">eliminato</span> un tema di materialità`
            :
            codiceInterpretato = `Hai <span class="azione bg-red dark:bg-dark-red">eliminato</span> un tema di materialità`
            break;

        default:
            codiceInterpretato = creaStringStandardPerAttivita(attivita)

    }

    return codiceInterpretato;

}