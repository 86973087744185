import { t } from 'i18next'
import React, { ReactNode } from 'react'

//Data
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useModuloRendicontazioneBilanciStores } from '../../context/useModuloRendicontazioneBilanciStores'

//Components
import { FaPlus } from 'react-icons/fa'

const ListaTagFiltri = observer(() => {
    const { t } = useTranslation()

    const { rendicontazione_bilanci } = useModuloRendicontazioneBilanciStores()


    return (
        <>
            {rendicontazione_bilanci.filtriRicerca &&
                <BloccoTagFiltri nome={t('common.cerca')}>
                    <Tag
                        etichetta={`"${rendicontazione_bilanci.filtriRicerca}"`}
                        azioneRimozione={() => rendicontazione_bilanci.applicaFiltriRicerca('')}
                    />
                </BloccoTagFiltri>
            }

            {rendicontazione_bilanci.filtriStato &&
                <BloccoTagFiltri nome={t('common.statoRendicontazione')}>
                    <Tag
                        etichetta={rendicontazione_bilanci.filtriStato}
                        azioneRimozione={() => rendicontazione_bilanci.applicaFiltriStato('')}
                    />
                </BloccoTagFiltri>
            }

            {rendicontazione_bilanci.filtriCategorie.size > 0 &&
                <BloccoTagFiltri nome={t('common.categorie')}>
                    {Array.from(rendicontazione_bilanci.filtriCategorie).map((cat) => (
                        <Tag
                            etichetta={cat}
                            azioneRimozione={() => rendicontazione_bilanci.rimuoviCodiceCategoria(cat)}
                        />
                    ))}
                </BloccoTagFiltri>

            }

            {rendicontazione_bilanci.filtriTipo &&
                <BloccoTagFiltri nome={t('common.tipo')}>
                    <Tag
                        etichetta={rendicontazione_bilanci.filtriTipo}
                        azioneRimozione={() => rendicontazione_bilanci.applicaFiltriTipo('')}
                    />
                </BloccoTagFiltri>
            }
        </>
    )
})


const Tag: React.FC<{ etichetta: string, azioneRimozione: () => void }> = ({ etichetta, azioneRimozione }) => {
    return (
        <div onClick={() => azioneRimozione()} className="cursor-pointer bg-lightgray-d dark:bg-darkgray-dd rounded-full py-2 px-3 flex items-center gap-1">
            <p className="text-xs">
                {etichetta}
            </p>
            <FaPlus className="rotate-45" size={8} />
        </div>
    )
}

const BloccoTagFiltri: React.FC<{ nome: string, children: ReactNode }> = ({ nome, children }) => {
    return (
        <div className="mb-2">
            <h4 className="font-bold">{nome}</h4>
            <div className="flex items-center gap-2 flex-wrap mt-1">
                {children}
            </div>
        </div>
    )
}

export default ListaTagFiltri;
