import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../../../../hooks/useStores'
import { useModuloESGStores } from '../../../context/useModuloESGStores'
import { IBilancio, IFattoreESG } from '../../../types'
import { observer } from 'mobx-react-lite'
import { scollegaFattore } from '../../../rest/bilanci'

//Components
import Dialogue from '../../../../../components/common/Dialogue'
import FattoreBilancioBusinessUnitForm from '../../bilanci/FattoreBilancioBusinessUnitForm'
import TemaMaterialitaFattoreBilancioForm from '../../../pagineBilanci/UiModuloBilanciTemiMaterialita/TemaMaterialitaFattoreBilancioForm'

const BloccoFattoreBilancioContextMenu: React.FC<{ fattore: IFattoreESG }> = observer(({ fattore }) => {

    const { bilanci } = useModuloESGStores()
    const { t } = useTranslation();

    const { ui, moduli } = useStores()

    const handleScollegaFattore = () => {
        scollegaFattore(bilanci.bilancioAttivo?.anno as number, fattore.codice as string).then(() => {

            ui.mountDialogue(<Dialogue testo={t('ModuloFattoriEsg.bloccoFattoreBilancioContextMenu.fattoreDisattivatoSucesso')}  />)

            bilanci.getBilancio(bilanci.bilancioAttivo?.anno as number)

        }).catch((err) => {
            ui.mountDialogue(<Dialogue testo={err.response.data.message ? err.response.data.message : 'Errore generico'} />)
        })
    }

    const handleCollegaTemaMaterialita = () => {
        if (bilanci.bilancioAttivo && bilanci.bilancioAttivo?.temi_materialita.length > 0) {

            ui.mountAsideModal(<TemaMaterialitaFattoreBilancioForm
                fattore={fattore}
                bilancio={bilanci.bilancioAttivo as IBilancio}
            />)

        } else {

            ui.mountDialogue(<Dialogue testo="Devi collegare almeno un tema a questo bilancio prima di poterlo collegare ad un fattore." />)

        }
    }

    return (
        <div
            className="absolute shadow-lg border border-lightgray-dd bg-white dark:bg-black p-3 dark:border-0 z-10"
            onMouseEnter={() => ui.unmountFollowMouse()}>
            <ul className="whitespace-nowrap flex flex-col gap-2">

                {moduli.moduloAttivo?.codice === 'bilanci_fattori_business_units' &&
                    <li onClick={() => ui.mountBigScreenModal(
                        <FattoreBilancioBusinessUnitForm
                            fattore={fattore}
                            bilancio={bilanci.bilancioAttivo?.anno as number}
                        />)}>{t('ModuloFattoriEsg.bloccoFattoreBilancioContextMenu.attribuisciBusinessUnitFattore')}</li>
                }

                {moduli.moduloAttivo?.codice === 'bilanci_fattori' &&
                    <li onClick={() => handleScollegaFattore()}>{t('ModuloFattoriEsg.bloccoFattoreBilancioContextMenu.disattivaFattore')}</li>
                }

                {moduli.moduloAttivo?.codice === 'bilanci_materialita' && bilanci.bilancioAttivo &&
                    <li onClick={() => handleCollegaTemaMaterialita()}>{t('ModuloFattoriEsg.bloccoFattoreBilancioContextMenu.collegaTemiDiMaterialita')}</li>
                }

            </ul>
        </div>
    )
})

export default BloccoFattoreBilancioContextMenu;