import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { useModuloRendicontazioneBilanciStores } from '../../context/useModuloRendicontazioneBilanciStores';
import { IFattoreRendicontabile } from '../../../../types';
import { FaAngleDown, FaCheck } from 'react-icons/fa';
import BarraRicercaGenerale from '../../../../../../components/common/BarraRicercaGenerale';

const FiltroCategorie = observer(() => {

    const { rendicontazione_bilanci } = useModuloRendicontazioneBilanciStores()
    const [ricercaCategoria, setRicercaCategoria] = useState("");
    const categorie = rendicontazione_bilanci.categorieVisibili.filter((categoria)=> categoria.nome.toLowerCase().includes(ricercaCategoria.toLowerCase()));

    return (
        <div className="flex flex-col p-1">
            <div className='mb-2'>
            <BarraRicercaGenerale 
                setStringaDiRicerca={setRicercaCategoria} 
                numeroRisultati={categorie.length}
                mostraRisultati={false}/>  
            </div>
            <div className='h-[300px] overflow-y-auto'>
            {categorie.map((categoria) => (
                <Categoria categoria={categoria} />
            ))}
            </div>
        </div>
    )
})

const Categoria: React.FC<{ categoria: IFattoreRendicontabile, sottoCategoria?: boolean }> = observer(({ categoria, sottoCategoria = false }) => {

    const { rendicontazione_bilanci } = useModuloRendicontazioneBilanciStores()
    const [aperto, setAperto] = useState(false)

    return (
        <div>
            <div
                className={`p-2 border-lightgray-dd flex items-center justify-between ${sottoCategoria ? 'border-b border-l border-r' : 'border'}`}
                key={categoria.codice}
            >
                <div className='flex items-center gap-1'>
                    {categoria.sotto_fattori.length > 0 &&
                        <div
                            onClick={() => setAperto(!aperto)}
                            className="w-3 h-3 flex items-center justify-center cursor-pointer"
                        >
                            <FaAngleDown className={`duration-200 ${aperto && 'rotate-180'}`} size={10} />
                        </div>
                    }
                    <span className="font-bold">{categoria.nome}</span>
                </div>

                <div
                    onClick={() => rendicontazione_bilanci.filtriCategorie.has(categoria.codice) ? rendicontazione_bilanci.rimuoviCodiceCategoria(categoria.codice) : rendicontazione_bilanci.applicaFiltriCategorie(categoria)}
                    className="border border-lightgray-dd w-3 h-3 flex items-center justify-center cursor-pointer"
                >
                    {rendicontazione_bilanci.filtriCategorie.has(categoria.codice) &&
                        <FaCheck size={10} />
                    }
                </div>

            </div>

            {categoria.sotto_fattori.length > 0 && aperto &&
                <SottoCategorie categorie={categoria.sotto_fattori} />
            }
        </div>
    )
})

const SottoCategorie: React.FC<{ categorie: IFattoreRendicontabile[] }> = ({ categorie }) => {
    return (
        <div className="pl-1">
            {categorie.map((categoria) => (
                <Categoria categoria={categoria} sottoCategoria={true} />
            ))}
        </div>
    )
}

export default FiltroCategorie;
