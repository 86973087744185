import React, { useEffect, useState } from 'react'

//Data
import { Link, Navigate, useLocation } from 'react-router-dom'
import { useModuloESGStores } from '../../context/useModuloESGStores';
import { useStores } from '../../../../hooks/useStores';
import { useTranslation } from 'react-i18next';

//Components
import IconaDinamica from '../../../../components/common/IconaDinamica';

export default function NavbarSchedaBilancio() {

    const { t } = useTranslation()
    const { moduli } = useStores()
    const { bilanci } = useModuloESGStores()
    const { pathname } = useLocation()

    let pathnameArray = pathname.split('/')

    //Effettua il redirect su /dashboard se in pagina bilancio
    if (bilanci.bilancioAttivo) {
        if (pathnameArray[pathnameArray.length - 1] === bilanci.bilancioAttivo.anno.toString()) {
            if (moduli.moduloAttivo?.codice === 'bilanci') {
                return <Navigate to="dashboard" />
            } else {
                return <Navigate to="fattori_esg" />
            }
        }
    }

    return (
        <div>
            <ul className="rounded-md shadow-xl overflow-hidden bg-lightgray-d gap-[1px] flex flex-wrap items-stretch flex-col md:flex-row">
                <LinkNavBarSchedaBilancio
                    etichetta="Dashboard"
                    codice="dashboard"
                    pathname={pathnameArray[pathnameArray.length - 1]}
                    key="fattori_esg"
                    icona="FaChartArea"
                    cliccabile={(
                        moduli.moduloAttivo?.codice === 'bilanci'
                    ) && true
                    }

                />
                <LinkNavBarSchedaBilancio
                    etichetta={t('ModuloFattoriEsg.NavbarSchedaBilancio.IndiceContenuti')}
                    codice="fattori_esg"
                    pathname={pathnameArray[pathnameArray.length - 1]}
                    key="fattori_esg"
                    icona="FaPoll"
                    cliccabile={(
                        moduli.moduloAttivo?.codice === 'bilanci' ||
                        moduli.moduloAttivo?.codice === 'bilanci_fattori' ||
                        moduli.moduloAttivo?.codice === 'bilanci_fattori_business_units' ||
                        moduli.moduloAttivo?.codice === 'bilanci_materialita'
                    ) && true
                    }
                />
                <LinkNavBarSchedaBilancio
                    etichetta={t('ModuloFattoriEsg.NavbarSchedaBilancio.TemiMaterialita')}
                    codice="temi_materialita"
                    pathname={pathnameArray[pathnameArray.length - 1]}
                    key="materialita"
                    icona="FaPollH"
                    cliccabile={
                        (
                            moduli.moduloAttivo?.codice === 'bilanci' ||
                            moduli.moduloAttivo?.codice === 'bilanci_materialita'
                        ) && true
                    }
                />
                <LinkNavBarSchedaBilancio
                    etichetta={t('ModuloFattoriEsg.NavbarSchedaBilancio.ObiettiviAgenda2030')}
                    codice="obiettivi_agenda2030"
                    pathname={pathnameArray[pathnameArray.length - 1]}
                    key="obiettivi_agenda2030"
                    icona="FaLeaf"
                    cliccabile={moduli.moduloAttivo?.codice === 'bilanci' && true}

                />
                <LinkNavBarSchedaBilancio
                    etichetta="Business Units"
                    codice="business_units"
                    pathname={pathnameArray[pathnameArray.length - 1]}
                    key="business_units"
                    icona="FaSuitcase"
                    cliccabile={moduli.moduloAttivo?.codice === 'bilanci' && true}

                />
                <LinkNavBarSchedaBilancio
                    etichetta={t('ModuloFattoriEsg.NavbarSchedaBilancio.Gruppi')}
                    codice="gruppi"
                    pathname={pathnameArray[pathnameArray.length - 1]}
                    key="gruppi"
                    icona="FaUsers"
                    cliccabile={moduli.moduloAttivo?.codice === 'bilanci' && true}

                />
            </ul>
        </div>
    )
}

const LinkNavBarSchedaBilancio: React.FC<{ etichetta: string, codice: string, pathname: string, icona?: string, cliccabile?: boolean }> = ({ etichetta, codice, pathname, icona, cliccabile = false }) => {

    const [attivo, setAttivo] = useState<boolean>(false)

    useEffect(() => {
        if (pathname === codice) {
            setAttivo(true);
        } else {
            setAttivo(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    return (
        <Link
            className={`grow btn-navigazione-modulo ${attivo && 'btn-navigazione-modulo-attivo'} ${!cliccabile && 'hidden'}`}
            to={codice}
        >
            {icona &&
                <div className="bg-white rounded-full p-[5px]">
                    <IconaDinamica nome={icona} dimensione={14} />
                </div>
            }
            <span className="whitespace-nowrap">{etichetta}</span>
        </Link>
    )
}