import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useModuloESGStores } from '../../../context/useModuloESGStores'
import { observer } from 'mobx-react-lite'

//Components
import { FaSearch } from 'react-icons/fa'
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md'

const CanvasHeader: React.FC<{ loader: "bilanci" | "fattori_esg" }> = observer(({ loader }) => {

    const { ui_canvas } = useModuloESGStores()
    const { t } = useTranslation();

    const styles = {
        bottone: "cursor-pointer hover:bg-accent dark:hover:bg-dark-accent hover:text-white duration-200 min-w-[36px] h-[36px] flex items-center justify-center",
        bottoneAttivo: "text-accent bg-lightgray-dd dark:bg-darkgray-dd dark:text-dark-accent dark:hover:text-white"
    }

    return (
        <div className={`flex gap-2 flex-col md:flex-row items-center justify-between mb-4 ${ui_canvas.fullscreen ? 'px-4 py-2 fixed top-0 w-screen bg-white dark:bg-black z-[100]' : 'mt-4'}`}>
            <h4 className="text-center md:text-left text-xl font-bold">
                {t('ModuloFattoriEsg.NavbarSchedaBilancio.IndiceContenuti')}
            </h4>

            <div className="flex gap-2 items-end">
                <div className="flex items-center bg-lightgray-d dark:bg-darkgray-d rounded-sm border border-lightgray-dd dark:border-darkgray-dd overflow-hidden">
                    {/* bottone ricerca */}
                    <div className={`${styles.bottone} ${ui_canvas.ricerca && styles.bottoneAttivo}`} onClick={() => ui_canvas.toggleRicerca()} >
                        <FaSearch size={12} />
                    </div>

                    {/* bottone fullscreen */}
                    <div className={`${styles.bottone} ${ui_canvas.fullscreen && styles.bottoneAttivo}`} onClick={() => ui_canvas.toggleFullscreen()}>
                        {ui_canvas.fullscreen ? <MdFullscreenExit size={20} /> : <MdFullscreen size={20} />}
                    </div>
                </div>

                <div>
                    <p className="text-[10px] mb-1 text-text opacity-50">{t('ModuloBusinessUnit.CanvasBusinessUnitHeader.modVista')}</p>
                    <div className="flex items-center bg-lightgray-d dark:bg-darkgray-d rounded-sm border border-lightgray-dd dark:border-darkgray-dd overflow-hidden">
                        {/* bottone modalita standard */}
                        <div className={`${styles.bottone} ${ui_canvas.modalitaVista === 'default' && styles.bottoneAttivo}`} onClick={() => ui_canvas.settaModalitaVista('default')}>
                            <span className="text-[8px] font-bold px-1">{t('ModuloBusinessUnit.CanvasBusinessUnitHeader.default')}</span>
                        </div>

                        {/* bottone modalita monificata */}
                        <div className={`${styles.bottone} ${ui_canvas.modalitaVista === 'minificata' && styles.bottoneAttivo}`} onClick={() => ui_canvas.settaModalitaVista('minificata')}>
                            <span className="text-[8px] font-bold px-1">{t('ModuloBusinessUnit.CanvasBusinessUnitHeader.manificata')}</span>
                        </div>

                        {/* bottone modalita monificata */}
                        <div className={`${styles.bottone} ${ui_canvas.modalitaVista === 'blocchi' && styles.bottoneAttivo}`} onClick={() => ui_canvas.settaModalitaVista('blocchi')}>
                            <span className="text-[8px] font-bold px-1">{t('ModuloBusinessUnit.CanvasBusinessUnitHeader.blocchi')}</span>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
})

export default CanvasHeader;