export const it_business_unit = {

    ModuloBusinessUnitGruppi: {
        ruolo: 'Ruolo:',
        nessunoGruppoCollegato: 'Nessun gruppo collegato a questa business unit',
    },

    FormBusinessUnitGruppo: {
        business_unit: 'business_unit_nome',
        ruoli: 'ruoli',
        attribuisciBusinessUnit: 'Attribuisci dei ruoli alla business unit',
        testBusinessUnit: 'Puoi stabilire quali gruppi saranno associati alla business unit e in quale misura.',
        selezioneGruppoAggiungere: 'Seleziona un gruppo e aggiungilo alla business unit',
        selezioneGruppo: 'Seleziona gruppo',
        AttivoRuolo: 'Attiva ruolo',
        ruolii: 'Ruoli',
        salvaRuoli: 'Salva ruoli',
    },

    FormBusinessUnit: {
        verificatoErrore: 'Si è verificato un errore durante la richiesta, puoi comunque salvare inserendo manualmente i dati dell\'indirizzo, ma la business unit non apparirà nelle mappe.',
        modificaBusinessUnit: 'Modifica la business unit',
        creaBusinessUnit: 'Crea una nuova business unit',
        figliaDi: 'figlia di',
        placeholderBusinessUnit: 'nome business unit',
        placeHolderDescrizione: 'descrizione business unit',
        via: 'Via"',
        civico: 'Civico',
        cap: 'CAP',
        citta: 'Città',
        paese: 'Paese',
        cercandoIndrizzio: 'Cercando indirizzo',
        geolocalizza: 'Geolocalizza indirizzo',
        long: 'Long:',
        lat: 'Lat:',
        nessunoIndrizoTrovato: 'Nessun indirizzo trovato',
        anullaIndrizzo: 'Annulla indirizzo',
        aggiungiIndrizzo: 'Aggiungi un indirizzo',
        businessunitCreatoSucesso: 'Business Unit creata con successo',
        creaBusinessUnitButtone: 'Crea Business Unit',
    },

    TileGruppoBusinessUnit: {
        ruoloStatoEridato: 'Questo ruolo è stato ereditato da',
    },

    SchedaBusinessUnit: {
        tornaAllaStratura: 'Torna alla struttura organizzativa',
        indrizzo: 'Indirizzo',
        coordinate: 'Coordinate',
        attiviPerQuestoBussinessUnit: 'attivi per questa business unit',
        alberaturaSottoBusinessUnit: 'Alberatura sotto Business unit',
    },

    BusinessUnitContextMenu: {
        eliminatoBusinessUnitSucesso: 'Business unit eliminata con successo',
        attribuisciRuoli: 'Attribuisci ruoli',
        modificaBusinessUnit: 'Modifica Business Unit',
        eliminaBusinessUnit: 'Sei sicuro di voler eliminare',
        eliminaBusinessUnitTesto: 'Tutti i gruppi che ne fanno parte perderanno l\'accesso ai bilanci assegnati.',
        eliminaLaBusinessUnit: 'Elimina la business unit',
    },

    TileBusinessUnit: {
        geoLocalizzataIn: 'Questa business unit è geolocalizzata in',
        ruoloAttivo: 'Ruolo attivo',
        ruoliAttivi: 'Ruoli attivi'
    },

    CanvasBusinessUnitHeader: {
        default: 'DEFAULT',
        manificata: 'MINIFICATA',
        mappa: 'MAPPA',
        blocchi: 'BLOCCHI',
        modVista: 'Mod. vista',
    },

    CanvasBusinessUnit: {
        nessunaBusinessUnitTrovatra: 'Nessuna business unit trovata',
    },

    MappaBusinessUnit: {
        vaiAllaBusinessUnit: 'Vai alla Business Unit',
        businessUnitContengono: 'business units contengono un indirizzo e vengono quindi visualizzate nella mappa.',
        su: 'su',
    },

    ModuloBusinessUnitCategorieContextMenu: {
        modificaCategoria: 'Modifica categoria',
        categorieEliminatoSucesso: 'Categoria eliminata con succeso',
        eliminaCategoria: 'Elimina categoria',
        eliminaCategoriaMessagio: 'Sei sicuro di voler eliminare questa categoria?',
    },

    ArchivioBusinessUnitCategorie: {
        nessunaCategoriaPresente: 'Nessuna categoria presente',
        aggiungiNuovaCategoria: 'Aggiungi nuova categoria',
    },

    FormBusinessUnitCategoria: {
        modificaCategoria: 'Modifica la categoria ',
        creaNuovaCategoria: 'Crea una nuova categoria.',
        modificaDatiDellaCategoria: 'Modifica i dati della categoria',
        categoriaCreatoSuccesso: 'Categoria creata con successo',
        creaCategoriaButtone: 'Crea categoria',
    },

    BusinessUnitRuoloContextMenu: {
        ruoloEliminatoSucesso: 'Ruolo eliminato con successo',
        eliminaRuolo: 'Sei sicuro di voler eliminare il ruolo',
        eliminaIlRuolo: 'Elimina il ruolo',
        modificaRuolo: 'Modifica Ruolo'
    },

    BusinessUnitRuoloForm: {
        ModificaRuolo: 'Modifica il ruolo',
        creaRuolo: 'Crea un nuovo ruolo',
        modificaDatiDellaRuoli: 'Modifica i dati del ruolo',
        messaggioRuoli: 'associa dei gruppi per consentire agli utenti che ne fanno parte di accedere alle funzionalità.',
        placeholderNome: 'es. visualizzazione, modifica, eliminazione',
        ruoloCreatoSucesso: 'Ruolo creato con successo',
        creaRuoloButtone: 'Crea ruolo',
    },

    ArchivioBusinessUnitRuoli: {
        nessunoRuoloPresente: 'Nessun ruolo presente',
        aggiungiRuolo: 'Aggiungi nuovo ruolo',
    },

    ArchivioBussinessUnit: {
        testoStrutturaOrganizzativa: 'Struttura organizzativa dell\'azienda',
        testoIntegrando: ' Interagendo con l\'alberatura è possibile implementare la struttura organizzativa dell\'azienda.',
        testoEPossibile: ' È possibile inserire continenti, paesi, singole sedi e unità operative all\'interno delle sedi. Ad ognuna delle unità create è quindi possibile assegnare gruppi di utenti e definire un ruolo.',
        testoLeBusinessUnit: 'Le business units hanno il fine di essere utilizzate per stabilire i privilegi di accesso in diversi altri moduli dell\'applicazione, permettendo ad esempio solo a determinati gruppi di utenti di interagire e rendicontare i bilanci, segmentando le responsabilità della rendicontazione tra diversi stakeholder e operatori.',
        chiudi: 'Chiudi',
        leggiPiu: 'Leggi di più',
        creaNuovaBusinessUnit: 'Crea una nuova business unit',
    },
}