import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import { get } from '../../rest/crud_generica'
import { INotizia } from '../../types'
//Components
import Welcome from './components/Welcome'
import LinkDashboard from './components/LinkDashboard'
import WidgetAttivitaDashboard from './components/WidgetAttivitaDashboard'
import ListaBilanciDashboard from './components/ListaBilanciDashboard'
import TileNotiziaDashboard from './components/TileNotiziaDashboard'

const Dashboard = observer(() => {

  const { t } = useTranslation();
  const { auth } = useStores();

  const [notizie, setNotizie] = useState<INotizia[]>()

  useEffect(() => {
    get('notizie/dashboard').then((res) => {
      setNotizie(res.data.data)
    })
  }, [])

  return (
    <div>
      {auth.utente?.benvenuto &&
        <Welcome />
      }

      <WidgetAttivitaDashboard />

      {auth.moduliUtente.filter(modulo => modulo.codice === 'bilanci').length > 0 &&
        <div className="mt-6">
          <h2 className="font-bold text-xl">{t('ModuloDashboard.Dashboard.vaiAlBilancio')}</h2>
          <p>{t('ModuloDashboard.Dashboard.accediDiretamente')}</p>

          <ListaBilanciDashboard />
        </div>
      }


      <h2 className="py-4 font-bold text-xl">
        {t('ModuloDashboard.Dashboard.suDiNoi')}
      </h2>
      <div className="flex gap-2 flex-col md:flex-row">

        <LinkDashboard
          titolo={t('ModuloDashboard.Dashboard.ipeTitolo')}
          descrizione={t('ModuloDashboard.Dashboard.ipeDescrizione')}
          link="https://ipe.eambiente.it/"
          immagine="/imgs/dashboard/ipe.jpg"
        />

        <LinkDashboard
          titolo={t('ModuloDashboard.Dashboard.imqeAmbienteTitolo')}
          descrizione={t('ModuloDashboard.Dashboard.imqeAmbienteDescrizione')}
          link="https://www.eambientegroup.com/"
          immagine="/imgs/dashboard/eambiente.jpg"
        />

        <LinkDashboard
          titolo={t('ModuloDashboard.Dashboard.innovationLabTitolo')}
          descrizione={t('ModuloDashboard.Dashboard.innovationLabDescrizione')}
          link="https://www.eambientegroup.com/it/servizi/innovation-lab"
          immagine="/imgs/dashboard/innovation_lab.jpg"
        />

      </div>

      {notizie && notizie.length > 0 &&
        <>
          <h2 className="py-4 font-bold text-xl">
            Notizie
          </h2>
          <div className="flex gap-2 flex-col md:flex-row">

            {
              notizie.map((notizia) => (
                <TileNotiziaDashboard notizia={notizia} />
              ))
            }

          </div>
        </>
      }

    </div>
  )
})

export default Dashboard;