import React, { useEffect, useState } from 'react'

//Data
import { useStores } from '../../../hooks/useStores'
import { observer } from 'mobx-react-lite'

//Components
import { FaInfoCircle, FaPlus, FaSlidersH } from 'react-icons/fa'
import Spinner from '../../../components/common/Spinner'
import AttivitaTilePanello from '../../amministrazioneUtentiAccessi/attivita/AttivitaTilePanello'
import FiltriAttivitaPanello from '../../amministrazioneUtentiAccessi/attivita/FiltriAttivitaPanello'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import GraficoAttivita from '../../amministrazioneUtentiAccessi/attivita/GraficoAttivita'
import { get } from '../../../rest/crud_generica'
import ReactPaginate from 'react-paginate'
import { IAttivita } from '../../../types'

const ArchivioAttivitaPanello: React.FC<{ caricaAltreAttivo?: boolean }> = observer(({ caricaAltreAttivo = true }) => {
  //Carica le prime attività di default
  useEffect(() => {
    attivita.ripristinaListaAttivitaPanello()
    aggiungiAttivita(0, offsetAttivita)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { attivita } = useStores()
  const { t } = useTranslation();
  const offsetAttivita = 2;
  const [dateDal, setDateDal] = useState<Date>()
  const [dateAl, setDateAl] = useState<Date>()

  //Numero di altre attività per pagina
  const data = attivita.listaAttivitaPanello;
  const attivitaPerPagina = 1
  const [numeroPaginaAttivita, setNumeroPaginaAttivita] = useState(0);
  const numeroPaginaAttivitaVisitate = numeroPaginaAttivita * attivitaPerPagina
  const conteggioPaginaAttivita = Math.ceil(data.length / attivitaPerPagina);

  const cambioPaginaAttivita = ({ selected }: { selected: number }): void => {
    setNumeroPaginaAttivita(selected)
  };
  //Filtri
  const clickEvent = ({ isNext, nextSelectedPage, selected }: { isNext: boolean, nextSelectedPage: any, selected: any }): any => {
    if (isNext || nextSelectedPage > selected) {
      aggiungiAttivita(attivita.listaAttivitaPanello.length, offsetAttivita)
    }
  }
  const [arrayFiltri, setFiltri] = useState<boolean>(false)

  const [forzaPagina, setForzaPagina] = useState<string>("")
  //Carica altre attività
  const aggiungiAttivita = (offset: number, limit: number) => {
    attivita.getListaAttivitaPanello(offset, limit, attivita.arrayFiltri)
  }

  const chiudFiltri = () => {
    // attivita.ripristinaFiltriPanello()
    // attivita.ripristinaListaAttivitaPanello()
    // attivita.getListaAttivitaPanello(attivita.listaAttivitaPanello.length, 2)
    setFiltri(false)
  }

  useEffect(() => {
    if (forzaPagina === "filtroNonAttivo") {
      setNumeroPaginaAttivita(0)
    } else if (forzaPagina === "filtroAttivo") {
      attivita.ripristinaFiltriPanello()
      attivita.ripristinaListaAttivitaPanello()
      attivita.getListaAttivitaPanello(attivita.listaAttivitaPanello.length, 2)
      setNumeroPaginaAttivita(0)
    }
  }, [forzaPagina])

  return (
    <div>
      <div className='mb-4 text-center lg:text-start'>
        <button className='rounded w-72	 h-6 mb-5'>
          <Link to="../amministrazione_utenti_e_accessi" className="text-white dark:text-white flex flex-col justify-center h-full uppercase">
            {t('ModuloGestioneUtenti.PannelloPanoramicaUtenti.pannelloPanoramica')}
          </Link>
        </button>
      </div>
      <div className='shadow-xl bg-white dark:bg-darkgray-dd w-[99%] m-auto mb-4'>
        <div className="py-6 text-center">
          <h1 className='text-3xl font-bold mb-2'>Andamento delle Attivita</h1>
          {dateDal ?
            <span>Dal: {dateDal?.toLocaleDateString()}</span>
            :
            <span></span>
          }
          {dateAl ?
            <span className='mx-4'>Al: {dateAl?.toLocaleDateString()}</span>
            :
            <span></span>
          }

          {/* <p className="text-lg">Storico dell'andamento della rendicontazione negli ultimi 30 giorni</p> */}
        </div>
        {<GraficoAttivita attivita={attivita.listaCountAttivitaPanello} />}
      </div>
      <div className="flex items-center justify-end my-2">
        {arrayFiltri ?
          <div className="cursor-pointer p-1 hover:bg-white rounded-sm rotate-45" onClick={() => chiudFiltri()}>
            <FaPlus />
          </div>
          :
          <div className="cursor-pointer p-1 hover:bg-white rounded-sm" onClick={() => setFiltri(true)} >
            <FaSlidersH />
          </div>
        }
      </div>

      {arrayFiltri && <FiltriAttivitaPanello
        setDateDal={setDateDal}
        forzaPagina={setForzaPagina}
        setDateAl={setDateAl} />
      }

      <div className="shadow-xl flex flex-col w-[99%] m-auto py-2">
        <div className='mb-4'>
          {
            attivita.listaAttivitaPanello.length > 0 ?
              <>
                {
                  data
                    .slice(numeroPaginaAttivitaVisitate, numeroPaginaAttivitaVisitate + attivitaPerPagina)
                    .map((attivita) => (
                      <AttivitaTilePanello key={attivita.id} attivita={attivita} />))
                }
              </>
              :
              <p className="text-center font-2xl pt-2 flex items-center justify-center gap-2 opacity-70">
                {attivita.gettingAttivitaPanello ?
                  <Spinner colore="accent" /> :
                  <>
                    <FaInfoCircle />
                    <span>Nessuna attività trovata per il tuo account</span>
                  </>
                }
              </p>
          }
        </div>
        {
          data.length >= 0 &&
          <div className='mb-2'>
            <ReactPaginate
              pageCount={conteggioPaginaAttivita}
              forcePage={numeroPaginaAttivita}
              onClick={clickEvent}
              nextLabel={'Next'}
              onPageChange={cambioPaginaAttivita}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"} />
          </div>
        }
      </div>
    </div>
  )
})

export default ArchivioAttivitaPanello;