import React from 'react'
import { useTranslation } from 'react-i18next';
import { useStores } from '../../hooks/useStores'

export default function ConfermaChiusuraAsideModal() {

    const { t } = useTranslation()
    const { ui } = useStores();

    const styles = {
        asideSureWrapper: "absolute flex items-center justify-center top-0 left-0 right-0 bottom-0 backdrop-blur-sm",
        asideSureButton: "capitalize w-full text-center p-2 text-xs cursor-pointer rounded-sm bg-red dark:bg-dark-red text-white",
        asideSureSecondaryButton: "capitalize w-full text-center p-2 text-xs cursor-pointer rounded-sm text-white"
    }

    return (
        <div className={styles.asideSureWrapper}>
            <div className="bg-white dark:bg-darkgray-d rounded-sm p-4 w-2/3 shadow-xl border border-lightgray-dd">
                <p className="text-sm">{t('common.disclaimerModificheNonSalvate')}</p>

                <div className="gap-2 flex mt-2 flex-col lg:flex-row">

                    <button
                        autoFocus
                        className={styles.asideSureButton}
                        onClick={() => ui.unmountAsideModal()}>
                        {t('common.chiudi')}
                    </button>

                    <button
                        className={styles.asideSureSecondaryButton}
                        onClick={() => ui.unmountConfermaChiusuraAsideModal()}>
                        {t('common.rimani')}
                    </button>

                </div>

            </div>
        </div>
    )
}
