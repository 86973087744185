import React from 'react'

//components
import { FaPlus } from 'react-icons/fa';

export default function AggiungiButton(props: { testo: string }) {

  const styles = {
    wrapper: "bg-white dark:bg-darkgray-dd rounded-sm  w-full h-full flex items-center gap-2 cursor-pointer justify-center text-accent dark:text-dark-accent dark:bg-darkgray-dd hover:opacity-70",
  }

  return (
    <div id="aggiungi-utente" className={styles.wrapper}>
      <div className="py-5 flex flex-col items-center gap-2">
        <FaPlus size={30} />
        <p className="text-center">{props.testo}</p>
      </div>
    </div>
  )
}
