import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../../../hooks/useStores'
import { useModuloESGStores } from '../../context/useModuloESGStores'
import { IFattoreESG } from '../../types'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import useOttieniPrivilegioModulo from '../../../../hooks/useOttieniPrivilegioModulo'

//Components
import { Link } from 'react-router-dom'
import { FaAngleDoubleUp, FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import BloccoFattoreDefault from './BloccoFattore/BloccoFattoreDefault'
import BloccoFattoreMinificato from './BloccoFattore/BloccoFattoreMinificato'
import BottoneAttribuisciStandard from './bottoni/BottoneAttribuisciStandard'
import BottoneAggiungiFattore from './bottoni/BottoneAggiungiFattore'
import Spinner from '../../../../components/common/Spinner'
import CercaFattoreESG from './common/CercaFattoreESG'
import CanvasHeader from './common/CanvasHeader'
import BloccoFattoreBlocchi from './BloccoFattore/BloccoFattoreBlocchi'
import BottoneCanvasGenerico from './common/BottoneCanvasGenerico'

const CanvasFattoriESG: React.FC<{ fattori: IFattoreESG[], fattori_flat: IFattoreESG[], loader: "bilanci" | "fattori_esg" }> = observer(({ fattori, fattori_flat, loader }) => {

    const privilegio = useOttieniPrivilegioModulo();
    const { bilanci, fattori_esg, ui_canvas } = useModuloESGStores();
    const { t } = useTranslation();

    const { ui, moduli } = useStores();

    //lista bilanci filtrati per stringa ricerca
    const [fattoriFiltrati, setfattoriFiltrati] = useState<IFattoreESG[]>([])

    //Stato caricamento
    const [loading, setLoading] = useState<boolean>(true)

    switch (loader) {

        case 'fattori_esg':
            useEffect(() => {
                setLoading(fattori_esg.gettingFattoreESGAttivo)
            }, [fattori_esg.gettingFattoreESGAttivo])
            break;

        case 'bilanci':
            useEffect(() => {
                setLoading(bilanci.gettingBilancioAttivo)
            }, [bilanci.gettingBilancioAttivo])
            break;
    }

    //Caricamento fattori
    useEffect(() => {

        (bilanci.bilancioAttivo || bilanci.fattoreBilancioAttivo || fattori_esg.fattoreESGAttivo) &&
            setfattoriFiltrati(toJS(fattori))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bilanci.bilancioAttivo, bilanci.fattoreBilancioAttivo, fattori_esg.fattoreESGAttivo])


    //Filtra i risultati della lista flat sulla base della ricerca
    useEffect(() => {

        if (ui_canvas.stringa_ricerca !== '') {

            setfattoriFiltrati(
                toJS(fattori_flat)
                    .filter((fattore: IFattoreESG) => (
                        fattore.nome && fattore.nome_esteso &&
                        (fattore.nome.toLowerCase() + ' ' + fattore.nome_esteso.toLowerCase()).includes(ui_canvas.stringa_ricerca.toLowerCase())
                    ))
            )

        } else {
            setfattoriFiltrati(toJS(fattori_flat))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui_canvas.stringa_ricerca])

    const styles = {
        canvas: `
        bg-lightgray-d border border-lightgray-dd dark:border-black overflow-scroll flex relative dark:bg-darkgray-dd
        min-w-full max-w-[calc(100vw-20px)] md:max-w-[calc(100vw-300px)] canvas-body max-h-[1800px]
        ${ui.asideModal && 'md:max-w-[calc(100vw-700px)]'}
        ${ui_canvas.fullscreen && 'pt-8 w-screen h-screen'}`
    }

    const canvas = useRef<HTMLInputElement>(null)

    const sposta = (direzione: string) => {
        canvas.current && canvas.current.scrollTo({ left: canvas.current.scrollLeft + (direzione === 'dx' ? 180 : -180), behavior: 'smooth' })
    }

    return (
        <div className={`min-w-full ${ui_canvas.fullscreen && 'fixed top-0 left-0 right-0 bottom-0 z-[1000] w-screen h-screen overflow-scroll bg-white'}`}>

            {/* header del canvas*/}
            <CanvasHeader loader={loader} />

            {/* barra di ricerca */}
            {ui_canvas.ricerca && <CercaFattoreESG />}

            <div className="flex items-center justify-between min-h-[30px]">

                <div>
                    {/* bottone per passare alla categoria nei fattori esg */}
                    {loader === 'fattori_esg' && fattori_esg.fattoreESGAttivo?.tipo !== 'standard' && 
                        <Link
                            to={`/app/modulo/fattori_esg/${fattori_esg.fattoreESGAttivo?.codice_padre}`}
                            className="bg-accent dark:bg-dark-accent p-2 text-white flex items-center gap-2 whitespace-nowrap"
                        >
                            {fattori.length > 0 ?
                            <span className="text-xs">{t('ModuloFattoriEsg.canvasFattoriEsg.vaiAllaCategoriaPadre')} ({fattori_esg.fattoreESGAttivo?.codice_padre})</span>
                            :
                            <span className="text-xs">{t('ModuloFattoriEsg.canvasFattoriEsg.tornaAgliStandard')}</span>
                            }
                            <FaAngleDoubleUp />
                        </Link>
                    }

                    {/* bottone per passare alla categoria nei fattori bilanci */}
                    {loader === 'bilanci' && bilanci.fattoreBilancioAttivo && bilanci.fattoreBilancioAttivo?.tipo !== 'standard' &&
                        <Link
                            to={`/app/modulo/${moduli.moduloAttivo?.codice}/${bilanci.fattoreBilancioAttivo.bilancio}/fattori_esg/${bilanci.fattoreBilancioAttivo?.codice_padre}`}
                            className="bg-accent dark:bg-dark-accent p-2 text-white flex items-center gap-2 whitespace-nowrap"
                        >
                            <span className="text-xs">{t('ModuloFattoriEsg.canvasFattoriEsg.vaiAllaCategoriaPadre')} ({bilanci.fattoreBilancioAttivo?.codice_padre})</span>
                            <FaAngleDoubleUp />
                        </Link>
                    }
                </div>

                <div className="flex flex-end items-center gap-2 justify-end w-full">
                    <FaAngleLeft size={20} className="cursor-pointer opacity-50 duration-200 hover:opacity-100" onClick={() => sposta('sx')} />
                    <FaAngleRight size={20} className="cursor-pointer opacity-50 duration-200 hover:opacity-100" onClick={() => sposta('dx')} />
                </div>
            </div>

            {/* corpo del canvas */}
            <div className={styles.canvas} ref={canvas}>

                {
                    ui_canvas.modalitaVista !== 'blocchi' &&
                    <div className="w-[2px] h-full bg-accent absolute left-2" />
                }

                <div className={`flex flex-col gap-2 pr-5 ${ui_canvas.modalitaVista !== 'blocchi' && 'py-4'}`}>

                    {
                        //Spinner
                        loading &&
                        <div className="ml-4">
                            <Spinner colore="#002e5a" />
                        </div>
                    }

                    {
                        //Lista fattori annidati di default
                        ui_canvas.stringa_ricerca === '' && !loading &&

                            fattori?.length > 0 ?
                            <>
                                {(privilegio >= 2 && loader === 'bilanci' && fattori[0].tipo === 'standard' && ui_canvas.modalitaVista === 'default') &&
                                    <>
                                        {
                                            moduli.moduloAttivo?.codice === 'bilanci_fattori' ?
                                                <BottoneAttribuisciStandard testo={t('ModuloFattoriEsg.canvasFattoriEsg.attribuisciUnoStandardSulBilancio')} />
                                                :
                                                <BottoneCanvasGenerico>
                                                    <Link className="hover:text-white" to={`/app/modulo/bilanci_fattori/${bilanci.bilancioAttivo?.anno}/fattori_esg`}>
                                                        {t('ModuloFattoriEsg.canvasFattoriEsg.vaiAlModuloAttribuzione')}
                                                    </Link>
                                                </BottoneCanvasGenerico>
                                        }
                                    </>
                                }

                                {/* permette di creare nuovi item in uno standard */}
                                {
                                    privilegio >= 2 &&
                                    loader === 'fattori_esg' && ui_canvas.modalitaVista === 'default' &&
                                    <BottoneAggiungiFattore codice_padre={fattori_esg.fattoreESGAttivo?.codice} />
                                }

                                {
                                    fattori.map((fattore: IFattoreESG) => (
                                        <>
                                            {ui_canvas.modalitaVista === 'default' && <BloccoFattoreDefault key={fattore.codice} fattore={fattore} loader={loader} />}
                                            {ui_canvas.modalitaVista === 'minificata' && <BloccoFattoreMinificato key={fattore.codice} fattore={fattore} loader={loader} />}
                                            {ui_canvas.modalitaVista === 'blocchi' && <BloccoFattoreBlocchi first={true} key={fattore.codice} fattore={fattore} loader={loader} />}
                                        </>
                                    ))
                                }
                            </>
                            :
                            !loading && ui_canvas.stringa_ricerca === '' &&
                            <>
                                {(loader === 'bilanci' && privilegio >= 2) &&
                                    <>
                                        {
                                            moduli.moduloAttivo?.codice === 'bilanci_fattori' ?
                                                <BottoneAttribuisciStandard testo={t('ModuloFattoriEsg.canvasFattoriEsg.attribuisciUnoStandardSulBilancio')} />
                                                :
                                                <BottoneCanvasGenerico>
                                                    <Link className="hover:text-white" to={`/app/modulo/bilanci_fattori/${bilanci.bilancioAttivo?.anno}/fattori_esg`}>
                                                        {t('ModuloFattoriEsg.canvasFattoriEsg.vaiAlModuloAttribuzione')}
                                                    </Link>
                                                </BottoneCanvasGenerico>
                                        }
                                    </>
                                }
                                {loader === 'fattori_esg' && privilegio >= 2 && <BottoneAggiungiFattore codice_padre={fattori_esg.fattoreESGAttivo?.codice} />}
                            </>

                    }

                    {
                        //Lista flat per la ricerca
                        ui_canvas.stringa_ricerca !== '' &&
                            fattoriFiltrati?.length > 0 ?
                            fattoriFiltrati.map((fattore: IFattoreESG) => (
                                <>
                                    {ui_canvas.modalitaVista === 'default' && <BloccoFattoreDefault key={fattore.codice} fattore={fattore} loader={loader} />}
                                    {ui_canvas.modalitaVista === 'minificata' && <BloccoFattoreMinificato key={fattore.codice} fattore={fattore} loader={loader} />}
                                    {ui_canvas.modalitaVista === 'blocchi' && <BloccoFattoreBlocchi first={true} key={fattore.codice} fattore={fattore} loader={loader} />}
                                </>
                            ))
                            :
                            !loading && ui_canvas.stringa_ricerca !== '' &&
                            <p className="ml-6">{t('common.nessunFattoreTrovato')} "{ui_canvas.stringa_ricerca}"</p>
                    }
                </div>


            </div>
        </div>
    )
})

export default CanvasFattoriESG;