import React, { useEffect, useState } from 'react'

//Data
// import { useStores } from '../../hooks/useStores';
import { useForm } from 'react-hook-form';
import { useStores } from '../../hooks/useStores';

//Components
import { FaSearch } from 'react-icons/fa'
import { CgClose } from 'react-icons/cg'
import { runInAction } from 'mobx';
import { ReactI18NextChild, useTranslation } from 'react-i18next';

const BarraRicercaGenerale: React.FC<{
    setStringaDiRicerca: React.Dispatch<React.SetStateAction<string>>,
    numeroRisultati: number,
    mostraRisultati: boolean;
    
}> = ({setStringaDiRicerca, numeroRisultati, mostraRisultati}) => {

    const { t } = useTranslation()
    const { ui } = useStores();

    const { watch, register } = useForm();
    const [testoInput, setTestoInput] = useState<any>(0);

    useEffect(() => {
        if (watch('ricercaUtenti')) {
            runInAction(() => {setStringaDiRicerca( watch('ricercaUtenti') as string )})
        } else {
            runInAction(() => { setStringaDiRicerca( '' ) })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('ricercaUtenti')])

    const styles = {
        searchbox: `bg-lightgray-d dark:bg-darkgray-d px-3 py-1 rounded-full gap-2 items-center flex justify-between`,
        searchbar: "focus:outline-none bg-[rgba(0,0,0,0)] text-text-light dark:text-dark-text-light ",
        searchInput : `cursor-pointer mx-2 ${testoInput.length > 0 ? "lenteBarradiRicerca" : ""}`,
    }

    return (
        <form  className={styles.searchbox}>
            <input onInput={(event: React.ChangeEvent<HTMLInputElement>) => setTestoInput(event.target.value) }  {...register("ricercaUtenti")} placeholder={t('common.cerca')} className={styles.searchbar} type="search" />
            <button className="dark:bg-darkgray-d flex" type="submit">
                <FaSearch opacity={0.5} className={styles.searchInput} />

                {testoInput.length > 0 && mostraRisultati?
                    
                    <>
                    <span className="font-bold mx-1">{numeroRisultati === 1 ? `${numeroRisultati} ${t('risultato')}` : `${numeroRisultati} ${t('risultati')}`}</span></>
                    :
                    <p></p>
                                      
                }  
            </button>
            {/* <CgClose opacity={0.5} className="cursor-pointer block md:hidden" size={18} /> */}
        </form>
        
    )
}
export default BarraRicercaGenerale;