import axios from 'axios'
import { config } from '../../../config'
import { IUtente } from '../../../types'

//Riceve lista utenti
export const getUtenti = () => {
    return axios.get(`${config.API_URI}users`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceve lista utenti senza gruppo
export const getUtentiSenzaGruppo = () => {
    return axios.get(`${config.API_URI}users?senza_gruppo=t`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Crea un utente
export const creaUtente = (utente: IUtente, codici_gruppi: string[]) => {
    return axios.post(`${config.API_URI}user`, {
        nome: utente.nome,
        cognome: utente.cognome,
        azienda: utente.azienda,
        email: utente.email,
        telefono: utente.telefono,
        lingua: utente.lingua,
        gruppi: codici_gruppi
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Modifica un utente
export const modificaUtente = (utente: IUtente, codici_gruppi: string[]) => {
    return axios.put(`${config.API_URI}user/${utente.id}`, {
        nome: utente.nome,
        cognome: utente.cognome,
        email: utente.email,
        telefono: utente.telefono,
        azienda: utente.azienda,
        disabilitato: utente.disabilitato,
        gruppi: codici_gruppi,
        lingua: utente.lingua
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Elimina un utente
export const eliminaUtente = (id: string) => {
    return axios.delete(`${config.API_URI}user/${id}`, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    
}

//Reinviare Email Verifica
export const reinviareEmailVerifica = (id: string) => {
    return axios.put(`${config.API_URI}user/${id}/reinvia_email`, null, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}
//modifica Email 
export const aggiornaContatti = (id: string,emailmodify:string) => {
    return axios.put(`${config.API_URI}user/${id}/aggiorna_contatti`,{
        modified_email:emailmodify,
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}
