import React from "react"

//Data
import { BiDotsVerticalRounded } from "react-icons/bi";

const PanoramicaCentrale: React.FC<{
    stili: boolean, componenteUno: JSX.Element,
    componenteDue: JSX.Element, componenteTre: JSX.Element | null, Icona1: JSX.Element, Icona2: JSX.Element, Icona3: JSX.Element, icona4: JSX.Element | null
}> =
    ({ componenteUno, componenteDue, componenteTre, stili, Icona1, Icona2, Icona3, icona4 }) => {

        const styles = {
            panoramicaSopra: `${stili ? "grid grid-cols-1 w-[26rem] lg:w-[40rem] m-auto" : "grid grid-cols-2 w-[26rem] lg:w-[40rem] m-auto mb-2"}`,
            panoramicaSotto: `grid grid-cols-2 w-[26rem] lg:w-[40rem] m-auto`,
            contenitore: `${stili ? "grid grid-cols-3 m-auto w-[20rem] h-[10rem] lg:w-[27rem] lg:h-[13rem]" : "grid grid-cols-4 m-auto w-[20rem] h-[8rem] lg:w-[32rem] lg:h-[12rem]"}`,
            bordi: `rounded-full InfoCircle border-[10px] lg:border-[12px] relative`,
            collegamenti: `${stili ? "w-[34px] h-[18px]  bg-lightPanoramica" : "w-[30px] lg:w-[34px] h-[15px] lg:h-[18px] bg-lightPanoramica"}`,
            dots : 'w-[30px] h-[40px] m-auto text-black'
        }

        return (

            <div className='text-accent dark:text-dark-accent mb-8'>
                <div className={`${styles.panoramicaSopra}`}>
                    {stili ?

                        <div className={`mb-2 flex justify-center`}>
                            {componenteUno}
                        </div>

                        :

                        <>
                            {componenteUno}
                        </>
                    }
                </div>
                <div className={`${styles.contenitore}`}>
                    <div className='flex flex-col'>
                        <span className={`${styles.bordi}`}>
                            {Icona1}
                            <div className={`${styles.collegamenti} firstInfoConn`}></div>
                        </span>
                        <div>
                            <BiDotsVerticalRounded className={`${styles.dots}`}/>
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <div>
                            <BiDotsVerticalRounded className={`${styles.dots}`}/>
                        </div>
                        <span className={`${styles.bordi}`}>
                            {Icona2}
                            <div className={`${styles.collegamenti} secondInfoConn`}></div>
                        </span>
                    </div>
                    <div className='flex flex-col'>
                        <span className={`${styles.bordi}`}>
                            {Icona3}
                            <div className={`${stili ? "bg-lightPanoramica" : "firstInfoConn w-[30px] lg:w-[34px] h-[15px] lg:h-[18px] bg-lightPanoramica"}`}></div>
                        </span>
                        <div>
                            <BiDotsVerticalRounded className={`${styles.dots}`} />
                        </div>
                    </div>
                    {!stili &&
                        <div className='flex flex-col'>
                            <div>
                                <BiDotsVerticalRounded className={`${styles.dots}`} />
                            </div>
                            <span className='rounded-full InfoCircle border-[8px] lg:border-[12px] relative'>
                                {icona4}
                                <div className='bg-lightPanoramica'></div>
                            </span>
                        </div>
                    }
                </div>
                <div className={`${styles.panoramicaSotto}`}>
                    {stili ?

                        <>
                            <div className={`${stili ? "flex justify-center'" : ""}`}>
                                {componenteDue}
                            </div>
                            <div className={`${stili ? "flex justify-center'" : ""}`}>
                                {componenteTre}
                            </div>
                        </>

                        :

                        <>
                            {componenteDue}
                        </>
                    }

                </div>
            </div>
        )
    }

export default PanoramicaCentrale;