import React from 'react'

//Data
import { useForm } from 'react-hook-form'
import { useStores } from '../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

//Components
import InfoBox from '../common/InfoBox'
import TestoErrore from '../common/TestoErrore'
import ErrorBox from '../common/ErrorBox'
import SpinnerButton from '../common/SpinnerButton'

export const RichiediResetPasswordForm = observer(() => {
  const { t, i18n } = useTranslation();

  const { register, watch, handleSubmit, formState: { errors } } = useForm();

  const { auth } = useStores();


  const onSubmit = () => {
    auth.richiediNuovaPassword(watch('email'), i18n.language)
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <div className="form">
        <label htmlFor="">{t('common.eMail')}</label>

        <input  {...register("email", { required: t('common.fornisciEmail') })} placeholder={t('common.eMail')} type="email" />

        {errors.email && <TestoErrore errore={errors.email.message} />}
      </div>

      <div className="form">
        {auth.passwordRichiesta ?
          <InfoBox messaggio={t('FormResetPassword.messaggioInviaEmail')} />
          :
          <>
            {
              auth.settingPasswordRichiesta ?
                <SpinnerButton />
                :
                <input type="submit" value={t('FormResetPassword.recuperaPassword')} />
            }
          </>
        }
      </div>

      {auth.passwordRichiestaError && <ErrorBox errore={auth.passwordRichiestaError} />}
    </form>
  )
})