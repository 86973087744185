import React, { useEffect, useState } from 'react'

//Data
import { observer } from 'mobx-react-lite'
import { useModuloRendicontazioneBilanciStores } from '../context/useModuloRendicontazioneBilanciStores'
import { useTranslation } from 'react-i18next'

//Components
import { FaInfoCircle } from 'react-icons/fa'
import TileRendicontazioneFattore from './TileRendicontazioneFattore'
import { IFattoreRendicontabile } from '../../../types'
import ReactPaginate from 'react-paginate'

const ListaFattoriRendicontabili = observer(() => {

    const { rendicontazione_bilanci } = useModuloRendicontazioneBilanciStores()
    const { t } = useTranslation()

    const rendicontazioneBilanci = rendicontazione_bilanci.fattoriVisibili;
    const [numeroPagineRendicontazioneBilanci, setNumeroPagineRendicontazioneBilanci] = useState(0);
    const rendicontazioneBilanciPerPagina = 3;
    const pagineRendicontazioneBilanciVisitate = numeroPagineRendicontazioneBilanci * rendicontazioneBilanciPerPagina;
    const conteggioPaginaBusinessUnit = Math.ceil(rendicontazioneBilanci!.length / rendicontazioneBilanciPerPagina);
    const cambioPaginaRendicontazioneBilanci = ({ selected }: { selected: number }): void => {
        setNumeroPagineRendicontazioneBilanci(selected)
    };

    useEffect(() => {
        setNumeroPagineRendicontazioneBilanci(0)
    }, [rendicontazioneBilanci.length])

    return (
        <>
            {
                rendicontazione_bilanci.fattoriVisibili && rendicontazione_bilanci.fattoriVisibili?.length > 0 ?
                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 grow w-full mb-4">
                        {
                            rendicontazioneBilanci
                                .slice(pagineRendicontazioneBilanciVisitate, pagineRendicontazioneBilanciVisitate + rendicontazioneBilanciPerPagina)
                                .map((fattore: IFattoreRendicontabile) => {
                                    return (
                                        <div
                                            key={fattore.codice + (Math.random() + 1).toString(36).substring(7)}
                                            className={`bg-white dark:bg-darkgray-d shadow-xl dark:border-dark-accent ${fattore.tipo === 'tabella' && 'xl:col-span-2'}`}
                                        >
                                            {rendicontazione_bilanci.bilancio && rendicontazione_bilanci.businessUnitAttiva &&
                                                <TileRendicontazioneFattore
                                                    fattore={fattore}
                                                    ruolo={rendicontazione_bilanci.businessUnitAttiva.ruolo}
                                                    bilancioAnno={rendicontazione_bilanci.bilancio.anno}
                                                    businessUnitNome={rendicontazione_bilanci.businessUnitAttiva.nome}
                                                />
                                            }
                                        </div>
                                    )
                                })}
                    </div>
                    :
                    <div className="text-center flex flex-col items-center gap-2 opacity-70 w-full">
                        <FaInfoCircle size={24} />
                        <span className="text-lg ">{t('common.nessunFattoreTrovato')}</span>
                    </div>
            }
            {
                rendicontazioneBilanci.length >= 4 && 
                <ReactPaginate
                pageCount={conteggioPaginaBusinessUnit}
                nextLabel={'Next'}
                onPageChange={cambioPaginaRendicontazioneBilanci}
                forcePage={numeroPagineRendicontazioneBilanci}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"} />
            }
        </>
    )
})

export default ListaFattoriRendicontabili; 