import React, { SetStateAction, useEffect, useRef, useState } from 'react'
import { useStores } from '../../../hooks/useStores'
import { getModuliAttivitaGenerale } from '../../../rest/attivita'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { MultiSelect } from 'react-multi-select-component'
import { getUtenti } from '../../gestioneUtenti/rest/gestioneUtenti'
import { IGruppoForm, IUtente, IUtenteForm } from '../../../types'
import { getGruppi } from '../../gestioneUtenti/rest/gestioneGruppi'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AttivitaStore } from '../../../context/AttivitaStore'

const FiltriAttivitaPanello: React.FC<{ forzaPagina: React.Dispatch<React.SetStateAction<string>>,
   setDateDal: React.Dispatch<Date>,setDateAl: React.Dispatch<Date>}>
  = observer(({ forzaPagina,setDateDal,setDateAl }) => {

    const { attivita, ui } = useStores()
    const { t } = useTranslation();
    const { control, setValue, } = useForm();
    //Lista moduli attività utente
    const [listaModuli, setListaModuli] = useState<[]>([])
    const [listaMetodi, setListaMetodi] = useState<[]>([])
    const [listaRoute, setListaRoute] = useState<[]>([])
    const [utentiFiltri, setUtentiFiltri] = useState<any>([])
    const [gruppiFiltri, setGruppiFiltri] = useState<any>([])
    const [utentiSelezionatiFiltri, setUtentiSelezionati] = useState<IUtenteForm[]>([])
    const [idUtentiSelezionati, setIdUtentiSelezionati] = useState<any>([])
    const [utentiDefaultFiltri, setUtentiDefaultFiltri] = useState<any>([])
    const [gruppiDefaultFiltri, setGruppiDefaultFiltri] = useState<any>([])
    const [gruppiSelezionatiFiltri, setGruppiSelezionatiFiltri] = useState<IGruppoForm[]>([])
    const [codiceGruppiSelezionati, setCodiceGruppiSelezionati] = useState<any>([])
    const [apertoModuli, setApertoModuli] = useState(false)
    const [apertoRoutes, setApertoRoutes] = useState(false)
    const [selectedStartDate, setSelectedStartDate] = useState<Date | any>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<Date | any>(null);

    const resettaFiltri = () => {
      setSelectedStartDate(null)
      setSelectedEndDate(null)
      setUtentiSelezionati([])
      setGruppiSelezionatiFiltri([])
      forzaPagina("filtroNonAttivo")
    }

    useEffect(() => {
      if (attivita.arrayFiltri != "") {
        forzaPagina("filtroNonAttivo")
      } else {
        forzaPagina("filtroAttivo")
      }
      setTimeout(() => {
        forzaPagina("")
      }, 1000)
    }, [attivita.arrayFiltri])

    useEffect(()=>{
      setDateDal(selectedStartDate)
    },[selectedStartDate])

    useEffect(()=>{
      setDateAl(selectedEndDate)
    },[selectedEndDate])

    useEffect(() => {
      if (utentiSelezionatiFiltri.length > 0
        || gruppiSelezionatiFiltri.length > 0
        || selectedStartDate > 0
        || selectedEndDate > 0) {
        forzaPagina("filtroNonAttivo")
      } else if (utentiSelezionatiFiltri.length === 0
        || gruppiSelezionatiFiltri.length > 0
        || selectedStartDate === 0
        || selectedEndDate === 0) {
        forzaPagina("filtroAttivo")
      }
      setTimeout(() => {
        forzaPagina("")
      }, 1000)
    }, [utentiSelezionatiFiltri, gruppiSelezionatiFiltri, selectedStartDate, selectedEndDate])

    //Popola lista moduli e metodi
    useEffect(() => {
      getModuliAttivitaGenerale().then((res) => {
        setListaModuli(res.data.moduli)
        setListaMetodi(res.data.metodi)
        setListaRoute(res.data.route)
      })
      getUtenti().then(res => {
        setUtentiFiltri(res.data.data)
      })
      getGruppi().then(res => {
        setGruppiFiltri(res.data.data)
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {

      if (attivita.arrayFiltri) {
        attivita.ripristinaListaAttivitaPanello()
        attivita.getListaAttivitaPanello(attivita.listaAttivitaPanello.length, 8, attivita.arrayFiltri)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attivita.arrayFiltri])

    useEffect(() => {

      //Setta MultiSelect
      setUtentiSelezionati(utentiDefaultFiltri)
      //Setta Hook Form
      setValue('utenti', utentiDefaultFiltri)

    }, [utentiDefaultFiltri.id, setValue])


    //Ristruttura dati per label MultiSelect
    utentiFiltri.forEach((utente: IUtenteForm) => {
      utente.value = utente.id
      utente.label = `${utente.nome} ${utente.cognome}`
    })

    utentiDefaultFiltri.forEach((utente: IUtenteForm) => {
      utente.value = utente.id
      utente.label = `${utente.nome} ${utente.cognome}`
    })
    useEffect(() => {

      //Setta MultiSelect
      setGruppiSelezionatiFiltri(gruppiDefaultFiltri)

      //Setta Hook Form
      setValue('gruppi', gruppiDefaultFiltri)

    }, [gruppiDefaultFiltri, setValue])

    //Ristruttura dati per label MultiSelect
    gruppiFiltri.forEach((gruppo: IGruppoForm) => {
      gruppo.value = gruppo.codice
      gruppo.label = `${gruppo.nome}`
    })

    gruppiDefaultFiltri.forEach((gruppo: IGruppoForm) => {
      gruppo.value = gruppo.codice
      gruppo.label = `${gruppo.nome}`
    })

    useEffect(() => {
      if (utentiSelezionatiFiltri.length > 0) {
        const utentiSel = utentiSelezionatiFiltri.map(utente => utente.id);
        setIdUtentiSelezionati(utentiSel)
      }
      else {
        attivita.filtriPanello.utenti = []
      }
    }, [utentiSelezionatiFiltri])

    useEffect(() => {
      if (gruppiSelezionatiFiltri.length > 0) {
        const gruppiSel = gruppiSelezionatiFiltri.map(gruppo => gruppo.codice)
        setCodiceGruppiSelezionati(gruppiSel)
      }
      else {

        attivita.filtriPanello.gruppi = []
      }
    }, [gruppiSelezionatiFiltri])

    useEffect(() => {
      if (utentiSelezionatiFiltri.length > 0) {
        attivita.attribuisciFiltriPanello('utente', idUtentiSelezionati)
      }
    }, [idUtentiSelezionati])

    useEffect(() => {
      if (gruppiSelezionatiFiltri.length > 0) {
        attivita.attribuisciFiltriPanello('gruppo', codiceGruppiSelezionati)
      }
    }, [codiceGruppiSelezionati])

    useEffect(() => {
      {
        if (selectedStartDate) {
          attivita.attribuisciFiltriPanello('startDate', selectedStartDate)
        }
        else {
          attivita.filtriPanello.startDate = ""
        }
      }
    }, [selectedStartDate])

    useEffect(() => {
      {
        if (selectedEndDate) {
          attivita.attribuisciFiltriPanello('endDate', selectedEndDate)
        }
        else {
          attivita.filtriPanello.endDate = ""
        }
      }
    }, [selectedEndDate])

    return (
      <div className="bg-lightgray-d dark:bg-darkgray-dd p-4 rounded-sm my-2 flex gap-2 justify-between flex-col w-[99%] m-auto ">
        {listaMetodi.length > 0 &&
          <div className="grow">
            <div className='flex justify-between'>
              <h4 className="mb-2 font-bold">Filtra per attività: </h4>
              <button className='text-white w-[150px] p-2' onClick={resettaFiltri}>Resetta filtri</button>
            </div>
            <div className="flex flex-wrap gap-1">
              {listaMetodi.map((metodo) => (
                <span
                  key={metodo}
                  onClick={() => attivita.attribuisciFiltriPanello('metodo', metodo)}
                  className={`cursor-pointer bg-accent dark:bg-dark-accent opacity-70 text-white rounded-sm p-1 duration-100 ${attivita.filtriPanello.metodi.includes(metodo) && 'opacity-100'}`} >
                  {metodo === 'POST' && 'Creazione'}
                  {metodo === 'DELETE' && 'Eliminazione'}
                  {metodo === 'PUT' && 'Modifica'}
                </span>
              ))}
            </div>
          </div>
        }

        {listaModuli.length > 0 && !apertoModuli &&
          <div className="grow md:flex-row">
            <h4 className="mb-2 font-bold">Filtra per modulo: </h4>
            <div className="flex flex-wrap gap-1 md:flex-row">
              {listaModuli.slice(0, 4).map((modulo) => (
                <span
                  key={modulo}
                  onClick={() => attivita.attribuisciFiltriPanello('modulo', modulo)}
                  className={`cursor-pointer bg-accent dark:bg-dark-accent opacity-70 text-white rounded-sm p-1 duration-100 ${attivita.filtriPanello.moduli.includes(modulo) && 'opacity-100'}`} >
                  {modulo}
                </span>
              ))}
              {listaModuli.length > 4 &&
                <span onClick={() => setApertoModuli(!apertoModuli)} className="hover:bg-lightgray dark:hover:bg-darkgray dark:text-dark-accent rounded-md cursor-pointer flex items-center justify-center w-5">
                  {apertoModuli ? <FaAngleLeft size={25} /> : <FaAngleRight size={25} />}
                </span>}
            </div>
          </div>
        }
        {listaModuli.length > 4 && apertoModuli &&
          <div className="grow md:flex-row">
            <h4 className="mb-2 font-bold">Filtra per modulo: </h4>
            <div className="flex flex-wrap gap-1 md:flex-row">
              {listaModuli.map((modulo) => (
                <span
                  key={modulo}
                  onClick={() => attivita.attribuisciFiltriPanello('modulo', modulo)}
                  className={`cursor-pointer bg-accent dark:bg-dark-accent opacity-70 text-white rounded-sm p-1 duration-100 ${attivita.filtriPanello.moduli.includes(modulo) && 'opacity-100'}`} >
                  {modulo}
                </span>
              ))}
              <span onClick={() => setApertoModuli(!apertoModuli)} className="hover:bg-lightgray dark:hover:bg-darkgray dark:text-dark-accent rounded-md cursor-pointer flex items-center justify-center w-5">
                {apertoModuli ? <FaAngleLeft size={25} /> : <FaAngleRight size={25} />}
              </span>
            </div>
          </div>
        }

        {listaRoute.length > 0 && !apertoRoutes &&
          <div className="grow md:flex-row">
            <h4 className="mb-2 font-bold">Filtra per routes: </h4>
            <div className="flex flex-wrap gap-1 md:flex-row">
              {listaRoute.slice(0, 4).map((route) => (
                <span
                  key={route}
                  onClick={() => attivita.attribuisciFiltriPanello('route', route)}
                  className={`cursor-pointer bg-accent dark:bg-dark-accent opacity-70 text-white rounded-sm p-1 duration-100 ${attivita.filtriPanello.routes.includes(route) && 'opacity-100'}`} >
                  {route}
                </span>
              ))}
              {listaRoute.length >= 4 &&
                <span onClick={() => setApertoRoutes(!apertoRoutes)} className="hover:bg-lightgray dark:hover:bg-darkgray dark:text-dark-accent rounded-md cursor-pointer flex items-center justify-center w-5">
                  {apertoRoutes ? <FaAngleLeft size={25} /> : <FaAngleRight size={25} />}
                </span>
              }
            </div>
          </div>
        }
        {listaRoute.length > 4 && apertoRoutes &&
          <div className="grow md:flex-row">
            <h4 className="mb-2 font-bold">Filtra per routes: </h4>
            <div className="flex flex-wrap gap-1 md:flex-row">
              {listaRoute.map((route) => (
                <span
                  key={route}
                  onClick={() => attivita.attribuisciFiltriPanello('route', route)}
                  className={`cursor-pointer bg-accent dark:bg-dark-accent opacity-70 text-white rounded-sm p-1 duration-100 ${attivita.filtriPanello.routes.includes(route) && 'opacity-100'}`} >
                  {route}
                </span>
              ))}
              <span onClick={() => setApertoRoutes(!apertoRoutes)} className="hover:bg-lightgray dark:hover:bg-darkgray dark:text-dark-accent rounded-md cursor-pointer flex items-center justify-center w-5">
                {apertoRoutes ? <FaAngleLeft size={25} /> : <FaAngleRight size={25} />}
              </span>
            </div>
          </div>
        }
        {utentiFiltri.length > 0 &&
          <>
            <div className='grid md:grid-cols-2 gap-2'>
              <div className="form md:flex-row">
                <label htmlFor="">{t('ModuloGestioneUtenti.FormAggiungiUtentiGruppo.utenti')}</label>
                <Controller
                  control={control}
                  name="utenti"
                  render={({
                    field: { onChange },

                  }) => (
                    <MultiSelect
                      className="dark:text-black"
                      options={utentiFiltri}
                      value={utentiSelezionatiFiltri}
                      onChange={setUtentiSelezionati}
                      labelledBy={t('ModuloGestioneUtenti.FormAggiungiUtentiGruppo.selezioneUtenti')}
                      overrideStrings={{
                        selectSomeItems: t('ModuloGestioneUtenti.FormAggiungiUtentiGruppo.selezioneUtenti'),
                        allItemsAreSelected: t('ModuloGestioneUtenti.FormAggiungiUtentiGruppo.tuttiSelezionati'),
                        selectAll: t('ModuloGestioneUtenti.FormAggiungiUtentiGruppo.selezioneTutti'),
                        search: t('common.cerca'),

                      }}
                    />
                  )}

                />
              </div>
              {gruppiFiltri.length > 0 &&
                <div className="md:flex-row">
                  <div className="form md:flex-row ">
                    <label htmlFor="">{t('common.gruppi')}</label>
                    <Controller
                      control={control}
                      name="gruppi"
                      render={({
                        field: { onChange },
                      }) => (
                        <MultiSelect
                          className="dark:text-black"
                          options={gruppiFiltri}
                          value={gruppiSelezionatiFiltri}
                          onChange={setGruppiSelezionatiFiltri}
                          labelledBy={t('ModuloGestioneUtenti.FormCreaUtenti.selezioneGruppi')}
                          overrideStrings={{
                            selectSomeItems: t('ModuloGestioneUtenti.FormCreaUtenti.selezioneGruppi'),
                            allItemsAreSelected: t('ModuloGestioneUtenti.FormCreaUtenti.tuttiGruppiSelezionati'),
                            selectAll: t('ModuloGestioneUtenti.FormAggiungiUtentiGruppo.selezioneTutti'),
                            search: t('common.cerca'),

                          }}

                        />
                      )}
                    />
                  </div>
                </div>
              }
            </div>
            {<div className="">
              <h4 className="mb-2 font-bold ">Filtra per Date: </h4>
              <div className='form flex'>
                <div>
                  <DatePicker
                    selected={selectedStartDate}
                    onChange={date => setSelectedStartDate(date)}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select Start date"

                    isClearable={true}
                  />
                </div>
                <div className='mx-2'>
                  <DatePicker
                    selected={selectedEndDate}
                    onChange={date => setSelectedEndDate(date)}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select End date"
                    isClearable={true}
                  />
                </div>
              </div>

            </div>
            }
          </>
        }
      </div>
    )
  })

export default FiltriAttivitaPanello;
