import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../hooks/useStores'
import { IUnitaMisura } from '../../types'

//Components
import { FaInfoCircle } from 'react-icons/fa'
import UnitaMisuraForm from './UnitaMisuraForm'
import UnitaMisuraTile from './components/UnitaMisuraTile'
import AggiungiButton from '../../components/common/AggiungiButton'
import useOttieniPrivilegioModulo from '../../hooks/useOttieniPrivilegioModulo'

const GestioneUnitaMisura = observer(() => {

  const { t } = useTranslation();

  const privilegio = useOttieniPrivilegioModulo();
  const { ui, gestioneUnitaMisura } = useStores();

  useEffect(() => {
    gestioneUnitaMisura.getUnitaMisuraLista();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gestioneUnitaMisura.creatingUnitaMisura,
    gestioneUnitaMisura.UnitaMisuraEliminato,
    gestioneUnitaMisura.UnitaMisuraModificato,
  ]
  )

  return (
    <>
      {
        gestioneUnitaMisura.unitaMisura && gestioneUnitaMisura.unitaMisura.length === 0 &&
        <h3 className="flex items-center justify-center gap-2 text-2xl my-8 text-text-light">
          <FaInfoCircle /> {t('ModuloGestioneUnitaMisura.Archivio.nessunaUnitaMisura')}
        </h3>
      }

      {privilegio >= 2 &&
        <div className="my-4" onClick={() => ui.mountAsideModal(<UnitaMisuraForm />)}>
          <AggiungiButton testo={t('ModuloGestioneUnitaMisura.Archivio.nuovaUnitaMisura')} />
        </div>
      }

      <div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
          {
            gestioneUnitaMisura.unitaMisura && gestioneUnitaMisura.unitaMisura.map((unitaMisura: IUnitaMisura, i: number) => (
              <UnitaMisuraTile unitaMisura={unitaMisura} />
            ))
          }
        </div>
      </div>


      {privilegio >= 2 &&
        <div className="my-4" onClick={() => ui.mountAsideModal(<UnitaMisuraForm />)}>
          <AggiungiButton testo={t('ModuloGestioneUnitaMisura.Archivio.nuovaUnitaMisura')}/>
        </div>
      }
    </>
  )
})
export default GestioneUnitaMisura;


