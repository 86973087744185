import React from 'react'
import { ThemeStore } from './ThemeStore'
import { AuthStore } from './AuthStore'
import { UiStore } from './UiStore'
import { GestioneUtentiStore } from '../moduli/gestioneUtenti/store/GestioneUtentiStore'
import { GestioneModuliStore } from '../moduli/gestioneModuli/store/GestioneModuliStore'
import { GestionePrivilegiStore } from '../moduli/gestionePrivilegi/store/GestionePrivilegiStore'
import { GestioneUnitaMisuraStore } from '../moduli/gestioneUnitaMisura/store/GestioneUnitaMisuraStore'
import { GestioneLingueStore } from '../moduli/gestioneLingue/store/GestioneLingueStore'
import { ModuliStore } from './ModuliStore'
import { AttivitaStore } from './AttivitaStore'
import { UnitaMisuraStore } from './UnitaMisuraStore'
import { GestioneNotizieStore } from './GestioneNotizieStore'
import { panoramicaAmministrazioneUtentiAccessiStore } from '../moduli/amministrazioneUtentiAccessi/store/panoramicaAmministrazioneUtentiAccessiStore'

export const StoresContext = React.createContext({
  theme: new ThemeStore(),
  auth: new AuthStore(),
  ui: new UiStore(),
  gestioneUtenti: new GestioneUtentiStore(),
  gestioneModuli: new GestioneModuliStore(),
  gestionePrivilegi: new GestionePrivilegiStore(),
  gestioneUnitaMisura: new GestioneUnitaMisuraStore(),
  gestioneLingue: new GestioneLingueStore(),
  gestioneNotizie: new GestioneNotizieStore(),
  gestioneUtentiAccessi: new panoramicaAmministrazioneUtentiAccessiStore(),
  moduli: new ModuliStore(),
  attivita: new AttivitaStore(),
  unitaMisura: new UnitaMisuraStore(),
})