import React from 'react'
import { CgClose } from 'react-icons/cg'
import { Link } from 'react-router-dom';
import { useStores } from '../../../hooks/useStores'
import { useTranslation } from 'react-i18next'

export default function Welcome() {
    const { t } = useTranslation();

    const { auth } = useStores();

    const styles = {
        wrapper: "bg-accent dark:bg-dark-accent w-full rounded-md text-white text-center flex flex-col justify-center items-center shadow-xl relative mb-4",
        wrapperContent: "relative w-full h-full overflow-hidden flex items-center justify-center rounded-md",
        image: "absolute min-w-full min-h-full object-cover opacity-30 mix-blend-multiply",
        shadow: "w-10 h-10 rounded-full absolute bg-white mix-blend-overlay blur-3xl opacity-50",
        content: "flex flex-col items-center relative px-3 py-7 md:p-7",
        close: "w-[40px] h-[40px] bg-white absolute bottom-[-20px] rounded-full flex items-center justify-center cursor-pointer shadow-lg hover:bg-lightgray-d"
    }

    const chiudiWelcomeBox = () => {
        auth.toggleBoxBenvenuto();
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.wrapperContent}>
                <img src="/imgs/welcome.jpg" className={styles.image} alt="" />

                <div className={styles.shadow}></div>

                <div className={styles.content}>
                    <h2 className="text-2xl md:text-5xl font-bold">
                    {t('ModuloDashboard.Welcome.benvenutiSuIpe')}
                    </h2>
                    <p className="my-5 max-w-[600px] text-md md:text-lg">
                    {t('ModuloDashboard.Welcome.testoIntroduczione')}
                    </p>

                    <div className="flex gap-2 flex-col-reverse md:flex-row">
                        <Link to="lista-moduli" className="bg-orange text-white py-3 px-6 text-center bg-none rounded-sm font-bold dark:text-white">
                        {t('ModuloDashboard.Welcome.scopriPiuButtone')}
                        </Link>
                    </div>
                </div>
            </div>

            <div className={styles.close} onClick={() => chiudiWelcomeBox()}>
                <CgClose color={'black'} />
            </div>
        </div>
    )
}