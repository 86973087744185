import React from 'react'
import { useTranslation } from 'react-i18next'
//Components
import IscrizioneForm from '../components/auth/IscrizioneForm'
import { Link } from 'react-router-dom'

export default function Signup() {
  const { t } = useTranslation();
  return (
    <div className="w-full">

      <h1 className="text-4xl text-text">{t('common.richiediAccesso')}</h1>
      <p className="text-text-light mt-3 mb-5">
      {t('paginaSignup.messaggioTesta')}
      </p>

      <IscrizioneForm />

      <div className="mt-3 text-center">
        <p>{t('paginaSignup.giaAccount')}<Link to="/">{t('paginaSignup.effettuaLogin')}</Link></p>
      </div>

    </div>
  )
}
