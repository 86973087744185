import React from 'react'

//Data
import { useModuloESGStores } from '../../context/useModuloESGStores'
import { observer } from 'mobx-react-lite'
import { IObiettivoAgenda2030 } from '../../types'
import { useStores } from '../../../../hooks/useStores'
import { config } from '../../../../config'

//Components
import { Link } from 'react-router-dom'
import TextLoader from '../../../../components/common/TextLoader'
import { useTranslation } from 'react-i18next'

const StatisticheBilancio = observer(() => {

    const { t } = useTranslation()
    const { bilanci } = useModuloESGStores()

    //Statistiche fattori
    const numFattori = bilanci.bilancioAttivo?.fattori_esg.length
    const numFattoriRendicontabili = bilanci.bilancioAttivo?.fattori_esg.filter((f) => f.tipo === 'fattore').length
    const numTabelleRendicontabili = bilanci.bilancioAttivo?.fattori_esg.filter((f) => f.tipo === 'tabella' && f.sotto_fattori.length > 0).length
    const numStandard = bilanci.bilancioAttivo?.fattori_esg.filter((f) => f.tipo === 'standard').length

    //Statistiche temi di materialità
    const numTemiMaterialita = bilanci.bilancioAttivo?.temi_materialita.length

    //Statistiche obiettivi agenda2030
    const numObiettiviAgenda2030 = bilanci.bilancioAttivo?.obiettivi_agenda2030.length

    //Statistiche business units
    const numBusinessUnits = bilanci.bilancioAttivo?.business_units.length

    //Statistiche gruppi
    const numGruppi = bilanci.bilancioAttivo?.gruppi.length

    return (
        <div>
            <p className="font-bold text-lg mb-4">{t('common.statistiche')}:</p>
            <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-[2px] bg-grey flex-wrap">
                <BloccoStatisticheBilancio
                    testo={t('ModuloFattoriEsg.DashboardSchedaBilancio.StandardApplicati')}
                    valore={numStandard as number}
                />
                <BloccoStatisticheBilancio
                    testo={t('ModuloFattoriEsg.DashboardSchedaBilancio.Fattori')}
                    valore={numFattori as number}
                />
                <BloccoStatisticheBilancio
                    testo={t('ModuloFattoriEsg.DashboardSchedaBilancio.FattoriRendicontabili')}
                    valore={numFattoriRendicontabili as number}
                />
                <BloccoStatisticheBilancio
                    testo={t('ModuloFattoriEsg.DashboardSchedaBilancio.TabelleRendicontabili')}
                    valore={numTabelleRendicontabili as number}
                />
                <BloccoStatisticheBilancio
                    testo={t('ModuloFattoriEsg.DashboardSchedaBilancio.TemiMaterialita')}
                    valore={numTemiMaterialita as number}
                />
                <div className="md:col-span-3">
                    <BloccoStatisticheObiettiviAgenda2030
                        testo={t('ModuloFattoriEsg.DashboardSchedaBilancio.Obiettivi2030')}
                        valore={`${numObiettiviAgenda2030} su 17`}
                        obiettivi={bilanci.bilancioAttivo?.obiettivi_agenda2030}
                    />
                </div>
                <div className="md:col-span-2">
                    <BloccoStatisticheBilancio
                        testo={t('ModuloFattoriEsg.DashboardSchedaBilancio.BusinessUnits')}
                        valore={numBusinessUnits as number}
                    />
                </div>
                <div className="md:col-span-2">
                    <BloccoStatisticheBilancio
                        testo={t('ModuloFattoriEsg.DashboardSchedaBilancio.Gruppi')}
                        valore={numGruppi as number}
                    />
                </div>
            </div>
        </div>
    )
})

export default StatisticheBilancio;

const BloccoStatisticheBilancio: React.FC<{ valore: string | number, testo: string }> = observer(({ valore, testo }) => {

    const { bilanci } = useModuloESGStores()

    return (
        <div className="flex flex-col justify-center items-center p-6 hover:shadow-xl hover:relative duration-300 h-full md:items-start text-center md:text-left overflow-hidden bg-white dark:bg-darkgray-dd">
            <h4 className="text-4xl font-bold text-accent dark:text-dark-accent">
                {
                    bilanci.gettingBilancioAttivo ?
                        <TextLoader width={70} />
                        :
                        valore
                }
            </h4>
            <p className="text-lg mt-2">{testo}</p>
        </div>
    )
})

const BloccoStatisticheObiettiviAgenda2030: React.FC<{ valore: string | number, testo: string, obiettivi?: IObiettivoAgenda2030[] }> = observer(({ valore, testo, obiettivi }) => {

    const { bilanci } = useModuloESGStores()
    const { ui, moduli } = useStores()

    return (
        <div className="p-6 relative flex flex-col justify-center items-stretch hover:shadow-xl duration-300 h-full md:items-start text-center md:text-left overflow-hidden bg-accent dark:bg-dark-accent">
            <div className="absolute right-0 top-0 bottom-0 overflow-hidden p-2 flex items-center justify-center">
                <div className="grid grid-cols-4 gap-1">
                    {obiettivi?.map((obiettivo) => (
                        <Link
                            to={`/app/modulo/${moduli.moduloAttivo?.codice}/${bilanci.bilancioAttivo?.anno}/obiettivi_agenda2030/${obiettivo.id}`}
                            className="flex items-center justify-center w-[30px] h-[30px]"
                            onMouseEnter={() => ui.mountFollowMouse(<div>{obiettivo.nome}</div>)}
                            onMouseLeave={() => ui.unmountFollowMouse()}
                            style={{ backgroundColor: obiettivo.colore }}
                        >
                            <img
                                src={`${config.BACKEND_URI}icone_obiettivi_2030/${obiettivo.immagine}`}
                                alt={obiettivo.descrizione}
                                className="w-1/2"
                            />
                        </Link>
                    ))}
                </div>
            </div>
            <div>
                <h4 className="text-4xl font-bold text-white dark:text-dark-white">
                    {
                        bilanci.gettingBilancioAttivo ?
                            <TextLoader width={80} />
                            :
                            valore
                    }
                </h4>
                <p className="text-lg mt-2 text-white opacity-70 max-w-2/3">{testo}</p>
            </div>
        </div>
    )
})