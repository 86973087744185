import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../../../hooks/useOttieniPrivilegioModulo'
import { IBusinessUnitCategoria } from '../../types'

//Components
import Dialogue from '../../../../components/common/Dialogue'
import BusinessUnitCategoriaForm from '../BusinessUnitCategoriaForm'
import { UseModuloBusinessUnitCategorieStore } from '../context/UseModuloBusinessUnitCategorieStore'

const BusinessUnitCategoriaContextMenu: React.FC<{ categoria: IBusinessUnitCategoria }> = observer(({ categoria }) => {

  const privilegio = useOttieniPrivilegioModulo();
  const { t } = useTranslation();
  const { business_unit_categoria } = UseModuloBusinessUnitCategorieStore();
  const { ui } = useStores();

  const handleEliminaBusinessUnitCategoria = () => {
    business_unit_categoria.eliminaBusinessUnitCategoria(categoria.nome).then(() => {

      ui.mountDialogue(<Dialogue testo={t('ModuloBusinessUnit.ModuloBusinessUnitCategorieContextMenu.categorieEliminatoSucesso')}/>)

    }).catch(() => {
      ui.mountDialogue(<Dialogue testo={business_unit_categoria.eliminaBusinessUnitCategoriaError} />)
    })
  }

  return (
    <div className="absolute shadow-lg border border-lightgray-dd bg-white dark:bg-black p-3 right-0 dark:border-0 z-10">
      <ul className="whitespace-nowrap flex flex-col gap-2">
        {privilegio >= 3 && <li onClick={() => ui.mountAsideModal(<BusinessUnitCategoriaForm categoria={categoria} />)}>{t('ModuloBusinessUnit.ModuloBusinessUnitCategorieContextMenu.modificaCategoria')} </li>}
        {privilegio >= 4 &&
          <li onClick={() => ui.mountDialogue(
            <Dialogue
              testo={t('ModuloBusinessUnit.ModuloBusinessUnitCategorieContextMenu.eliminaCategoriaMessagio')}
              primaryCta={`${t('common.elimina')} ${categoria.nome}`}
              secondaryCta={t('common.annulla')}
              onConfirm={() => handleEliminaBusinessUnitCategoria()}
              primaryCtaColor="bg-red dark:bg-dark-red"
            />
          )} className="text-red dark:text-dark-red">
            {t('ModuloBusinessUnit.ModuloBusinessUnitCategorieContextMenu.eliminaCategoria')}
          </li>
        }
      </ul>
    </div>
  )
})

export default BusinessUnitCategoriaContextMenu;