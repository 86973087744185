import React, { useEffect, useState } from 'react'

//Data
import { observer } from 'mobx-react'
import { IObiettivoAgenda2030 } from '../../../types'
import { config } from '../../../../../config'
import { useStores } from '../../../../../hooks/useStores'
import '../stile/ruota-sdg.css'

//Components
import { Link } from 'react-router-dom'

const RuotaObiettiviAgenda2030: React.FC<{ obiettivi: IObiettivoAgenda2030[], obiettiviAttivi?: IObiettivoAgenda2030[] }> = observer(({ obiettivi, obiettiviAttivi }) => {

    const { ui } = useStores()

    const [idObiettiviAttivi, setIdObiettiviAttivi] = useState<number[]>()

    useEffect(() => {

        if (obiettiviAttivi) {
            setIdObiettiviAttivi(obiettiviAttivi.map(o => o.id))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className="flex items-center justify-center relative h-[500px] md:h-[1000px] scale-[0.45] md:scale-100">

            <div className="ruota-sdg w-[1200px] h-[1200px] rounded-full">

                {
                    obiettivi.map((obiettivo: IObiettivoAgenda2030) => (
                        <Link
                            key={obiettivo.id}
                            to={obiettivo.id.toString()}
                            style={{
                                backgroundImage: `
                                    radial-gradient(
                                        circle at 0% 100%, transparent,
                                        transparent 29.7%, 
                                        ${obiettivo.colore} 30%,
                                        ${obiettivo.colore} 50%,
                                        transparent 50.3%,
                                        transparent
                                    )
                                `}}
                            className={`arc ${idObiettiviAttivi && !idObiettiviAttivi.includes(obiettivo.id) && 'pointer-events-none saturate-0'}`}
                            onMouseEnter={() => ui.mountFollowMouse(<div>{obiettivo.nome}</div>)}
                            onMouseLeave={() => ui.unmountFollowMouse()}
                        >
                            <img
                                src={`${config.BACKEND_URI}icone_obiettivi_2030/${obiettivo.immagine}`}
                                alt={obiettivo.descrizione}
                            />
                        </Link>
                    ))
                }
            </div>

            <div className='bg-white dark:bg-lightgray-dd rounded-full overflow-hidden p-5 absolute w-10 h-10 blur-[50px]' />

            <div className='p-5 absolute' >
                <img
                    className="w-9 ratio-square relative top-[-5px]"
                    src={`${config.BACKEND_URI}icone_obiettivi_2030/obiettivi_sviluppo_sostenibile_intro.png`}
                    alt="obiettivi Agenda 2030"
                />
            </div>
        </div>
    )
})

export default RuotaObiettiviAgenda2030;
