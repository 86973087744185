import React from 'react'

//Data
import { observer } from 'mobx-react-lite'
import { IFattoreESG } from '../types'
import { Link } from 'react-router-dom';

//Components
import TextLoader from '../../../components/common/TextLoader';
import { config } from '../../../config';
import { useTranslation } from 'react-i18next';

const FattoreESGTile: React.FC<{ fattore: IFattoreESG, link?: string }> = observer(({ fattore, link }) => {
  
  const { t } = useTranslation()

  return (
    <div className="grow flex flex-col">
      <div className="p-4 bg-white dark:bg-darkgray-dd shadow-xl grow">
        <div className="flex items-center justify-between gap-2">
          <div>
            <p className="font-bold text-xs text-accent dark:text-dark-accent">{fattore ? fattore.codice : <TextLoader width={100} />}</p>
            <Link
              to={link ? link : `/app/modulo/fattori_esg/${fattore.codice}`}
            >
              <h2 className="font-bold text-xl">{fattore ? `${fattore.nome} ${fattore.nome_esteso && '| ' + fattore.nome_esteso}` : <TextLoader width={200} />}</h2>
            </Link>
          </div>
        </div>

        <hr className="my-2 border border-lightgray-d dark:border-darkgray-d" />

        <p className="whitespace-pre-line">{fattore ? `${fattore.descrizione ? fattore.descrizione : t('ModuloFattoriEsg.InformativeFattoriEsg.nessunaDescrizione')}` : <TextLoader />}</p>

        {fattore && fattore.obiettivi_agenda2030 &&
          fattore.obiettivi_agenda2030.length > 0 &&
          <div>
            <hr className="my-2 border border-lightgray-d dark:border-darkgray-d" />
            <p className="mb-2">Lista Obiettivi dell'Agenda 2030 collegati a questo fattore:</p>
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6">
              {fattore.obiettivi_agenda2030.map((obiettivo) => (
                <Link
                  to={`/app/modulo/agenda_2030/${obiettivo.id}`}
                  className="text-white p-2 flex items-center gap-2 h-full"
                  style={{ backgroundColor: obiettivo.colore }}
                >
                  <div className="w-[30px]">
                    <img
                      src={`${config.BACKEND_URI}icone_obiettivi_2030/${obiettivo.immagine}`}
                      alt={obiettivo.descrizione}
                    />
                  </div>
                  <p>{obiettivo.nome}</p>
                </Link>
              ))}
            </div>
          </div>
        }
      </div>
    </div>
  )
})

export default FattoreESGTile;
