import React, { useState, useEffect } from 'react'

//Data
import { useStores } from '../../../../../hooks/useStores'
import { useModuloESGStores } from '../../../context/useModuloESGStores'
import { IFattoreESG } from '../../../types'
import { observer } from 'mobx-react-lite'

//Components
import HoverFattoriBilancio from './HoverFattoriBilancio'
import { Link } from 'react-router-dom'

const BloccoFattoreBlocchi: React.FC<{ fattore: IFattoreESG, first: boolean, loader: "fattori_esg" | "bilanci" }> = observer(({ fattore, first, loader }) => {

  const { ui, moduli } = useStores();

  const { ui_canvas } = useModuloESGStores();

  const styles = {
    wrapper: "flex items-stretch relative p-2",
    tileFattore: `h-full w-[250px] hover:bg-ligthgray duration-100 p-4 hover:bg-[rgba(255,255,255,0.5)] dark:hover:bg-[rgba(255,255,255,0.2)]`,
    sotto_fattori: "w-3 h-3 flex items-center justify-center absolute right-[-8px] bg-white z-10 rounded-full border border-accent hover:text-white cursor-pointer text-accent dark:text-dark-accent hover:bg-accent"
  }

  const [link, setLink] = useState<string>('')

  useEffect(() => {
    if (loader === 'fattori_esg') {
      setLink(`/app/modulo/fattori_esg/${fattore.codice}`)
    } else if (loader === 'bilanci') {
      setLink(`/app/modulo/${moduli.moduloAttivo?.codice}/${fattore.bilancio}/fattori_esg/${fattore.codice}`)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`${styles.wrapper}`}>
      <Link to={link}
        className={styles.tileFattore}
        onMouseEnter={() => ui.mountFollowMouse(<HoverFattoriBilancio fattore={fattore} />)}
        onMouseLeave={() => ui.unmountFollowMouse()}
      >

        {/* Metadati fattore */}
        <div className="flex justify-between items-start w-full">

          <div>
            {
              fattore.nome &&
              <p className="text-xs font-bold text-accent dark:text-dark-accent" dangerouslySetInnerHTML={
                { __html: fattore.nome.replace(ui_canvas.stringa_ricerca, `<span class="text-xs bg-accent dark:bg-dark-accent text-white">${ui_canvas.stringa_ricerca}</span>`) }
              } />
            }

            {
              fattore.nome_esteso !== fattore.nome && fattore.nome_esteso &&
              <h5 className="text-text dark:text-white" dangerouslySetInnerHTML={
                { __html: fattore.nome_esteso.replace(ui_canvas.stringa_ricerca, `<span class="bg-accent dark:bg-dark-accent text-white">${ui_canvas.stringa_ricerca}</span>`) }
              } />
            }
          </div>

        </div>
      </Link>

      <div className={`bg-[rgba(0,0,0,0.1)] dark:bg-[rgba(255,255,255,0.03)] flex grow ${first ? 'flex-col' : 'flex-row'}`}>

        {
          fattore.sotto_fattori && fattore.sotto_fattori.map((sotto_fattore: IFattoreESG) => (
            <SottoBloccoFattoreBlocchi key={sotto_fattore.codice} fattore={sotto_fattore} first={false} loader={loader} />
          ))
        }

      </div>
    </div>
  )
})

const SottoBloccoFattoreBlocchi: React.FC<{ fattore: IFattoreESG, first: boolean, loader: "fattori_esg" | "bilanci" }> = ({ fattore, first, loader }) => {
  return (
    <BloccoFattoreBlocchi fattore={fattore} first={first} loader={loader} />
  )
}

export default BloccoFattoreBlocchi;