import React from 'react'

//Data
import { observer } from 'mobx-react-lite'
import { useStores } from '../hooks/useStores'
import { useTranslation } from 'react-i18next'


//Components
import Pagina from '../components/common/Pagina'
import ModificaLinguaForm from '../components/profilo/ModificaLinguaForm'
import CambiaEmailForm from '../components/profilo/CambiaEmailForm'
import CambiaPasswordForm from '../components/profilo/CambiaPasswordForm'
import SchedaUtente from '../components/profilo/SchedaUtente'

const Profilo = observer(() => {

  const { t } = useTranslation()
  const { ui, auth } = useStores()

  const toggleWelcomeBox = () => {
    auth.toggleBoxBenvenuto();
  }

  return (
    <Pagina titolo={t('PaginaProfilo.titolo')} descrizione={t('PaginaProfilo.descrizione')} >

      <div className="flex gap-4 lg:gap-8 flex-col md:flex-row justify-between xl:pt-4 items-start">
        <div className="w-full md:max-w-[600px]">
          <div className="relative">
            <SchedaUtente />
          </div>

          <div className={`form ${!auth.utente?.is_admin && 'relative top-[-55px] z-0'}`}>
            <button className={`p-2 w-full mt-2 text-white rounded-sm ${!auth.utente?.is_admin && 'cursor-default'}`} onClick={() => ui.toggleDebug()}>
              {ui.debug ? t('PaginaProfilo.disabilitaDebug') : t('PaginaProfilo.abilitaDebug') }
            </button>
          </div>

          <div className="form">
            <button className="p-2 w-full mt-2 text-white rounded-sm" onClick={() => toggleWelcomeBox()}>
              {auth.utente?.benvenuto ? t('PaginaProfilo.disabilitaBenvenuto') : t('PaginaProfilo.abilitaBenvenuto')}
            </button>
          </div>

        </div>

        <div className="w-full max-w-[800px] flex flex-col gap-6">
          <ModificaLinguaForm />
          <CambiaEmailForm />
          <CambiaPasswordForm />
        </div>

      </div>

    </Pagina>
  )
})

export default Profilo;