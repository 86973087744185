import React from 'react'

//Data
import { IFattoreESG } from '../../types'
import useOttieniPrivilegioModulo from '../../../../hooks/useOttieniPrivilegioModulo'

//Components
import { FaEllipsisV } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import StandardESGContextMenu from './StandardESGContextMenu'

const StandardESGTile: React.FC<{ fattore: IFattoreESG }> = ({ fattore }) => {

  const privilegio = useOttieniPrivilegioModulo()
  const [contextMenu, setContextMenu] = React.useState(false)

  const toggleContextMenu = () => {
    //Il timeout permette di far comparire il context menu dopo che l'utente ha cliccato
    setTimeout(() => {
      setContextMenu(!contextMenu)
    }, 1)
  }

  document.addEventListener('click', () => {
    if (contextMenu) {
      setContextMenu(false)
    }
  }, { once: true })

  return (
    <div className="bg-white dark:bg-darkgray-dd shadow-xl p-5 rounded-sm">
      <div className="flex items-center justify-between">
        <Link to={`${fattore.codice}`} className="flex items-center gap-2">
          <h2 className="font-bold hover:underline">
            <p className="text-xs">{fattore.codice}</p>
            <p className="text-xl">{fattore.nome}</p>
          </h2>
        </Link>


        {privilegio >= 3 &&
          <div className="cursor-pointer relative p-1" onClick={() => toggleContextMenu()}>
            <FaEllipsisV className="hover:opacity-70" />

            {contextMenu && <StandardESGContextMenu fattore={fattore} />}

          </div>
        }
      </div>
      <hr className="my-2 border border-lightgray-d" />
      <p>{fattore.descrizione}</p>
    </div>
  )
}

export default StandardESGTile;
