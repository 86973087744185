import React, { useEffect } from 'react'

//Data
import { observer } from 'mobx-react-lite'
import { Navigate, useParams } from 'react-router-dom'
import { useModuloESGStores } from '../context/useModuloESGStores'

//Components
import BackButton from '../../../components/common/BackButton'
import TextLoader from '../../../components/common/TextLoader'
import ListaFattoriESG from '../components/ListaFattoriESG'

const DettagliGruppoNelBilancio = observer(() => {

  const { bilanci } = useModuloESGStores()
  const { bilancio_anno, gruppo_codice } = useParams()

  //Effettua il redirect nella sezione bilanci se non riesce a caricare il gruppo
  useEffect(() => {
    bilancio_anno &&
      bilanci.getGruppoBilancioAttivo(parseInt(bilancio_anno), gruppo_codice as string).catch(() => {
        return <Navigate to="app/modulo/bilanci" />
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gruppo_codice, bilancio_anno])

  return (
    <div>
      <div className="my-2">
        <BackButton
          testo="Torna all'archivio dei gruppi"
          destinazione={`/app/modulo/bilanci/${bilanci.bilancioAttivo?.anno}/gruppi`}
        />
      </div>

      <div className="flex flex-col gap-2">
        <h2 className="text-lg font-bold my-2">Lista dei fattori ESG ai quali il gruppo ha accesso:</h2>


        {bilanci.gruppoBilancioAttivo?.fattori_esg && !bilanci.gettingGruppoBilancioAttivo ?
          <ListaFattoriESG fattoriEreditati={bilanci.gruppoBilancioAttivo.fattori_esg.filter(f => f.tipo !== 'colonna' && f.tipo !== 'riga' )} />
          :
          <div className="flex flex-col gap-2 text-4xl">
            <TextLoader />
            <TextLoader />
            <TextLoader />
          </div>
        }

      </div>
    </div>
  )
})

export default DettagliGruppoNelBilancio;