import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { FaInfoCircle } from 'react-icons/fa'
import { observer } from 'mobx-react-lite'
import { IBusinessUnit } from '../../../UiModuloBusinessUnit/types'

//Components
import BusinessUnitBilancioTile from './BusinessUnitBilancioTile'
import { Link } from 'react-router-dom'

const CanvasAttribuisciBusinessUnits: React.FC<{ businessUnits: IBusinessUnit[] }> = observer(({ businessUnits }) => {
    const { t } = useTranslation();
    return (
        <div className="bg-lightgray-d dark:bg-darkgray-d p-2 overflow-scroll flex items-center gap-5 max-h-[600px]">
            {
                businessUnits && businessUnits.length > 0 ?
                    businessUnits.map((businessUnit: IBusinessUnit) => (
                        <div>
                            <BusinessUnitBilancioTile
                                key={businessUnit.nome}
                                businessUnit={businessUnit}
                            />
                        </div>
                    ))
                    :
                    <div className="w-full flex flex-col gap-2 items-center justify-center my-3">
                        <div className="flex items-center gap-2">
                            <FaInfoCircle />
                            <span>{t('ModuloFattoriEsg.CanvasAttribuisciBusinessUnit.nessunBusinessUnitTrovata')}</span>
                        </div>
                        <p className="font-bold">{t('ModuloFattoriEsg.CanvasAttribuisciBusinessUnit.utilizzaIl')} <Link target="_blank" to="modulo/business_unit"> {t('ModuloFattoriEsg.CanvasAttribuisciBusinessUnit.moduloBusinessUnit')} </Link>{t('ModuloFattoriEsg.CanvasAttribuisciBusinessUnit.perCrearneDiNuovo')}</p>
                    </div>
            }
        </div>
    )
})

export default CanvasAttribuisciBusinessUnits;