import React from 'react'
import { CompletaAccountForm } from '../components/auth/CompletaAccountForm'
import { useTranslation } from 'react-i18next'
export default function CompletaAccount() {
  const { t } = useTranslation();
  return (
    <div className="w-full">

      <h1 className="text-4xl text-text">{t('PaginaCompleteAccount.completeAccount')}</h1>
      <p className="text-text-light mt-3 mb-5">
      {t('PaginaCompleteAccount.richiestaAcettata')}
      </p>

      <CompletaAccountForm />

    </div>
  )
}