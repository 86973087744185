import { IFattoreESG } from "../types";

const creaAlberaturaFattoriEsg = (fattori_esg: IFattoreESG[], standard: boolean = false) => {


    fattori_esg.map(fattore_padre => {

        fattore_padre.sotto_fattori.push(...fattori_esg.filter(fa => fa.codice_padre === fattore_padre.codice))

    })

    if (standard) {

        return fattori_esg.filter(fa_esg => fa_esg.tipo === 'standard');

    } else {

        return fattori_esg;

    }

}

export default creaAlberaturaFattoriEsg;