import React from 'react'
import { FaSpinner } from 'react-icons/fa'

const Spinner: React.FC<{ colore?: string }> = ({ colore }) => {
  return (
    <div>
      <FaSpinner className="spinner" fill={colore ? colore : 'white'} />
    </div>
  )
}

export default Spinner;